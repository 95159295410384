import React from 'react'

export default class DotAuctionLogo extends React.PureComponent {
  render() {
    const width = this.props.width || '16px'
    const height = this.props.height || '16px'

    return (
      <svg id="reversecolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.27 48.15" width={width} height={height} style={{...this.props.style}}>
        <g>
          <polygon fill='#f04c24' points=".41 47.45 6.84 47.45 6.84 44.96 3.34 44.96 3.34 43.01 6.65 43.01 6.65 40.52 3.34 40.52 3.34 38.63 6.84 38.63 6.84 36.14 .41 36.14 .41 47.45"/>
          <path fill='#f04c24' d="M23.81,42.99h.98v2.24c-.31,.13-.68,.2-1.1,.2-1.76,0-3.02-1.47-3.02-3.56s1.25-3.54,2.93-3.54c.77,0,1.43,.24,1.94,.77l1.85-2.08c-.94-.84-2.14-1.24-3.65-1.24-3.14,0-5.41,1.79-5.97,4.61-.64-2.4-2.86-4.25-5.38-4.25h-4.34v11.3h4.34c2.51,0,4.73-1.85,5.37-4.25,.55,2.8,2.78,4.65,5.87,4.65,1.56,0,2.86-.46,3.85-1.34v-6.01h-3.68v2.49Zm-12.15,1.96h-.68v-6.32h.68c1.95,0,3.22,1.26,3.22,3.15s-1.27,3.17-3.22,3.17Z"/>
          <polygon fill='#f04c24' points="35.14 38.63 35.14 36.14 28.72 36.14 28.72 47.45 35.14 47.45 35.14 44.96 31.65 44.96 31.65 43.01 34.96 43.01 34.96 40.52 31.65 40.52 31.65 38.63 35.14 38.63"/>
        </g>
        <g>
          <polygon fill='#f04c24' points="17.77 6.82 25.09 9.45 33.85 6.29 17.77 .51 17.77 6.82"/>
          <polygon fill='#231f20' points="17.77 35.78 35.14 29.53 35.14 23.23 17.77 29.48 17.77 35.78"/>
          <polygon fill='#231f20' points="17.77 27.57 35.14 21.33 35.14 15.02 17.77 21.27 17.77 27.57"/>
          <polygon fill='#231f20' points="17.77 13.06 17.77 19.37 35.14 13.12 35.14 6.82 17.77 13.06"/>
          <polygon fill='#f04c24' points="17.77 19.37 17.77 13.06 .41 6.82 .41 29.53 17.77 35.78 17.77 29.48 7.52 25.78 7.52 23.88 17.77 27.57 17.77 21.27 7.52 17.58 7.52 15.68 17.77 19.37"/>
          <polygon fill='#231f20' points="10.47 9.45 17.77 6.82 17.77 .51 1.7 6.29 10.47 9.45"/>
        </g>
        <g>
          <path fill='#231f20' d="M83.11,36.15h-17.04l-4.8,11.28h-4.92L74.77,5.49l17.94,41.94h-4.92l-4.68-11.28Zm-1.74-4.2l-6.72-16.02-6.84,16.02h13.56Z"/>
          <path fill='#231f20' d="M99.47,37.05c0,1.74,0,3.78,1.2,5.34,.66,.84,1.98,1.92,4.38,1.92s3.72-1.08,4.38-1.92c1.2-1.56,1.2-3.6,1.2-5.34v-14.52h4.32v15c0,3.06-.3,5.34-2.28,7.5-2.22,2.34-5.16,3.12-7.62,3.12s-5.4-.78-7.62-3.12c-1.98-2.16-2.28-4.44-2.28-7.5v-15h4.32v14.52Z"/>
          <path fill='#231f20' d="M138.44,29.49c-2.28-2.88-5.58-3.72-7.8-3.72-4.62,0-8.82,3.24-8.82,9.3s4.32,9.24,8.76,9.24c2.58,0,5.76-1.14,8.04-3.9v5.16c-2.7,1.98-5.58,2.58-7.92,2.58-7.74,0-13.32-5.52-13.32-13.02s5.64-13.2,13.32-13.2c3.96,0,6.54,1.56,7.74,2.4v5.16Z"/>
          <path fill='#231f20' d="M148.77,26.49v20.94h-4.32V26.49h-3.39v-3.96h3.39V13.53h4.32v9h3.57v3.96h-3.57Z"/>
          <path fill='#231f20' d="M159.1,22.53v24.9h-4.32V22.53h4.32Z"/>
          <path fill='#231f20' d="M161.54,35.07c0-7.56,5.58-13.14,13.2-13.14s13.2,5.58,13.2,13.14-5.64,13.08-13.2,13.08-13.2-5.52-13.2-13.08Zm4.44,0c0,6.12,4.32,9.24,8.76,9.24s8.76-3.18,8.76-9.24-4.2-9.3-8.76-9.3-8.76,3.24-8.76,9.3Z"/>
          <path fill='#231f20' d="M190.38,22.53h4.32v2.88c.78-1.02,3-3.48,7.14-3.48,2.94,0,5.64,1.2,7.14,3.42,1.26,1.86,1.44,3.9,1.44,6.66v15.42h-4.32v-15.3c0-1.5-.12-3.24-1.14-4.56-.84-1.08-2.16-1.8-4.08-1.8-1.5,0-3.12,.42-4.38,1.92-1.68,1.98-1.8,4.92-1.8,6.72v13.02h-4.32V22.53Z"/>
          <path fill='#231f20' d="M50.29,41.31c1.68,0,3.06,1.38,3.06,3.06s-1.38,3.06-3.06,3.06-3.06-1.38-3.06-3.06,1.38-3.06,3.06-3.06Z"/>
          <path fill='#231f20' d="M214.14,47.45h-.45v-3.16h-.85v-.43h2.17v.43h-.87v3.16Zm1.82-3.82l1.35,2.98,1.39-2.98,.57,3.82h-.44l-.32-2.44-1.2,2.62-1.18-2.62-.35,2.44h-.44l.61-3.82Z"/>
        </g>
      </svg>
    )
  }

}




