import React from 'react'

export default class TargetIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 16
    const height = this.props.height || 16
    const color = this.props.color || '#111'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 198 198" style={{...this.props.style}}>
        <path fillRule="evenodd" d="M99.000,-0.000 C153.676,-0.000 198.000,44.324 198.000,99.000 C198.000,153.676 153.676,198.000 99.000,198.000 C44.324,198.000 -0.000,153.676 -0.000,99.000 C-0.000,44.324 44.324,-0.000 99.000,-0.000 ZM99.000,20.157 C142.544,20.157 177.843,55.456 177.843,99.000 C177.843,142.544 142.544,177.843 99.000,177.843 C55.456,177.843 20.157,142.544 20.157,99.000 C20.157,55.456 55.456,20.157 99.000,20.157 ZM99.000,45.849 C128.355,45.849 152.151,69.645 152.151,99.000 C152.151,128.355 128.355,152.151 99.000,152.151 C69.645,152.151 45.849,128.355 45.849,99.000 C45.849,69.645 69.645,45.849 99.000,45.849 ZM99.000,68.874 C115.638,68.874 129.126,82.362 129.126,99.000 C129.126,115.638 115.638,129.126 99.000,129.126 C82.362,129.126 68.874,115.638 68.874,99.000 C68.874,82.362 82.362,68.874 99.000,68.874 Z"/>
      </svg>

    )
  }
}
