import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import AuctionStore from '../../api/stores/AuctionStore'
import ValuationRowNotesPopover from '../valuations/ValuationRowNotesPopover'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'
import Avatar from '../user/Avatar'
import TargetIcon from '../common/icons/TargetIcon'
import WatchlistIcon from '../common/icons/WatchlistIcon'
import AuctionEdgeBidding from '../bidding/AuctionEdgeBidding'
import SessionStore from '../../api/stores/SessionStore'

export default class AuctionsListingRow extends React.Component {
  state = {
    shouldBounce: false,
    watchlistingIsDisabled: false
  }

  onClick = (event, listing) => {
    if (event.target.className === 'watchlist-icon') {
      return
    }

    if (this.props.onClick) {
      this.props.onClick(event, listing.id)
    }

    // This allows us to intercept and pass context for prev / next
    if (this.props.onListingShow) {
      event.preventDefault()
      this.props.onListingShow(listing)
    }
  }

  toggleWatchlist = () => {
    if (this.state.watchlistingIsDisabled) { return }
    const listing = this.props.listing
    this.setState({
      shouldBounce: listing.watchlist !== true,
      watchlistingIsDisabled: true
    })

    AuctionStore.toggleListingWatchlist(listing, false)
    if (this.props.onWatchlistToggle) { this.props.onWatchlistToggle(listing) }

    // Disable watchlist for a bit to prevent double clicks
    setTimeout(() => {
      this.setState({watchlistingIsDisabled: false})
    }, 1000)
  }

  render() {
    const listing = this.props.listing

    const showRunNumber = this.props.showRunNumber === true
    const showLaneNumber = this.props.showLaneNumber === true
    const showChannel = this.props.showChannel === true
    const showWatchlistToggle = this.props.showWatchlistToggle !== false

    const auctionDate = listing.start_time ? moment(listing.start_time).format('M/D -') : ''

    var channelDisplay = ''

    const showValues = SessionStore.hasAuctionsPlus()

    if (showChannel && listing.auction && AppConstants.auctionOnlineSourceNames[listing.auction.code]) {
      if (listing.auction.code === 'EDGE_DOT_AUCTION' && listing.city) {
        channelDisplay = `${listing.city}, ${listing.state}`
      } else {
        channelDisplay = AppConstants.auctionOnlineSourceNames[listing.auction.code]
      }
    } else if (showChannel) {
      channelDisplay = auctionDate + ' ' + listing.auction.name
    }

    const hasBidding = this.props.hasLinkedAuctionEdge && listing.auction.code === 'EDGE_DOT_AUCTION'

    return (

      <div className={hasBidding ? ' auction-listing-row-bidding-c' : ''}>
        <div className="card-list-row auction-listing-row" style={{...this.props.style}}>
          <Link to={`/auctions/listing/${listing.id}`} onClick={(event) => { this.onClick(event, listing) }} className={`auction-listing-body ${hasBidding ? 'auction-listing-body-with-bidding' : ''}`}>
            <div className="thumbnail">
              <img src={listing.thumbnail ? listing.thumbnail : '/images/missing-photo-placeholder.png'} alt="Listing" loading="lazy" /> :

              {
                showRunNumber && listing['auction_run_number'] !== null &&
                <div className='overlay run-number'>Run {listing['auction_run_number']}</div>
              }

              {
                showLaneNumber && listing['auction_lane_number'] !== null &&
                <div className='overlay lane-number'>Lane {listing['auction_lane_number']}</div>
              }


              {
                listing.team_watches && listing.team_watches.length > 0 &&
                <div className="team-watchers">
                  {
                    listing.team_watches.map((user, index) => {
                      return (
                        <Avatar user={user} size={AppConstants.avatarSizes.xsmall} key={`watcher-${index}`} />
                      )
                    })
                  }
                </div>
              }
            </div>
            <div className="listing-specs">
              <h3 className='year'>{listing.year} {listing.make}
              {
                this.props.showRelisting === true && listing.original_listing !== true &&
                <span style={{backgroundColor: 'darkred', color: '#fff', padding: '2px 6px', borderRadius: '4px', marginLeft: '20px', fontSize: '1.1rem'}}>Re-listed</span>
              }
              
              </h3>
              <h3>{listing.model}</h3>
              <div className="listing-details">
                <div>
                  {
                    listing.trim &&
                    <div className="secondary-text">{listing.trim}</div>
                  }
                  <div className="mileage">{listing.mileage > 0 ? `${Formatters.formatThousands(listing.mileage)} ${hasBidding ? 'mi.' : 'miles'}` : ''}</div>
                </div>

                <div className="cr-location">
                  {
                    listing.condition_grade !== null &&
                    <div className="condition" title="Condition">CR {listing.condition_grade}</div>
                  }
                  {
                    showChannel &&
                    <div className="channel secondary-text">{channelDisplay}</div>
                  }
                </div>

                {
                  showValues &&
                  <div className="estimates">
                    {
                      listing.wholesale_value &&
                      <div>
                        <div>Wholesale est.</div>
                        <div>{Formatters.formatCurrency(listing.wholesale_value)}</div>
                      </div>
                    }
                    {
                      listing.retail_value &&
                      <div>
                        <div>Retail est.</div>
                        <div>{Formatters.formatCurrency(listing.retail_value)}</div>
                      </div>
                    }
                    {
                      Boolean(listing.profit_value !== null) &&
                      <div style={{borderTop: '1px solid #ddd'}}>
                        <div>Profit est.</div>
                        <div style={{color: listing.profit_value >= 0 ? 'green' : 'red'}}>{Formatters.formatCurrency(listing.profit_value)}</div>
                      </div>
                    }
                  </div>
                }

                <div className="target-price" title="Target price">
                  {
                    listing.vehicle?.structured_data?.target_bid_price &&
                      <div>
                      <TargetIcon />
                      {Formatters.formatCurrency(listing.vehicle.structured_data.target_bid_price)}
                      </div>
                  }
                </div>
              </div>
            </div>
          </Link>

          <div className='end-controls'>
            {
              showWatchlistToggle === true &&
              <a href="#watchlist" className={`watchlist${listing.watchlist === true ? ' watchlist-active' : ''}${this.state.shouldBounce ? ' watchlist-bounce' : ''}`} onClick={(e) => {
                e.preventDefault()
                this.toggleWatchlist()
              }}>
                <WatchlistIcon />
              </a>
            }

            <div className="notes">
              {
                listing.vehicle && listing.vehicle.has_vehicle_notes &&
                <ValuationRowNotesPopover valuation={listing.vehicle} />
              }
            </div>
          </div>



        {
          hasBidding &&
          <div className='inline-bidding'>
            <AuctionEdgeBidding listingId={listing.source_id} />
          </div>
        }
        </div>
      </div>
    )
  }

}
