import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import Spinner from './Spinner'
import AddUserModal from '../user/AddUserModal'

export default class SessionExclusion extends React.Component {

  state = {
    userHasSessionControl: true,
    isReclaiming: false,
    isShowingAddUserModal: false
  }

  componentDidMount() {
    SessionStore.on('user_session_control_changed', this.userSessionControlChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_session_control_changed', this.userSessionControlChanged)
  }

  userSessionControlChanged = () => {
    this.setState({
      userHasSessionControl: SessionStore.userHasSessionControl,
      isReclaiming: false
    })
  }

  reclaimControl = () => {
    this.setState({
      isReclaiming: true
    })
    SessionActionCreators.reclaimControl()
  }

  render() {
    const { userHasSessionControl, isReclaiming, isShowingAddUserModal } = this.state
    const isSuspended = SessionStore.userSessionControlIsSuspended === true

    if (userHasSessionControl === true && isSuspended === false) {
      return null
    }

    const user = SessionStore.user
    const canAddUsers = SessionStore.hasAccountOfferings() && !SessionStore.needsPaymentMethod()

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'>
        <ModalBody>
          <h2>{SessionStore.userSessionControlTitle ? SessionStore.userSessionControlTitle : 'Account In Use'}</h2>
          <p>
          {
            SessionStore.userSessionControlMessage ? SessionStore.userSessionControlMessage :
            "This account is currently in use by another device. If you would like to continue using Carbly, you can reclaim control from the other device."
          }
          </p>
          {
            isReclaiming &&
            <Spinner />
          }

          {
            !isReclaiming && canAddUsers && !isSuspended &&
            <div style={{fontWeight: 'bold', textAlign: 'center', borderTop: '1px solid #ddd', marginTop: '10px', paddingTop: '10px'}}>Need to add a user to your account?</div>
          }

          {
            user.account?.owner === true && !isReclaiming && canAddUsers &&
            <div style={{textAlign: 'center'}}>
              <div style={{marginBottom: 0, textAlign: 'center'}}>Add additional users to take advantage of Carbly for Teams features.</div>
              <Button color="primary" onClick={() => this.setState({ isShowingAddUserModal: true })} style={{marginTop: '5px', minWidth: '320px'}}><img src="/images/teams-logo.png" style={{width: '44px', height: '25px', marginRight: '5px'}} alt="Teams" /> Add a user to your team</Button>
            </div>
          }

          {
            user.account?.owner !== true && !isReclaiming &&
            <div style={{marginBottom: 0, textAlign: 'center'}}>Contact the account owner for your Carbly plan, and he or she can add users via the Settings Screen.</div>
          }
        </ModalBody>
        {
          !isReclaiming && !isSuspended &&
          <ModalFooter>
            <Button onClick={this.reclaimControl} className="highlight-button" style={{width: '100%', marginTop: '10px'}}>Reclaim Control</Button>
          </ModalFooter>
        }

        <div>
          <a href="#logout" style={{display: 'block', marginTop: '10px', marginBottom: '10px', textAlign: 'center'}} onClick={(e) => {
            e.preventDefault()
            SessionActionCreators.logout()
          }}>Logout of the current account</a>
        </div>

        {
          isShowingAddUserModal &&
          <AddUserModal onDone={() => { this.setState({ isShowingAddUserModal: false })}} />
        }
      </Modal>
    )
  }
}
