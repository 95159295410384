import React from 'react'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import ExtensionIcon from '../components/common/icons/ExtensionIcon'

export default class ExtensionNoAccessPage extends React.Component {
  render() {
    return (
      <div style={{height: '100%'}}>

        <div className="extension-no-access-body-c">
          <div className="content-header">
            <ExtensionIcon />
            <h1>Carbly Extension</h1>
          </div>
          <div>
            <p>Your account does not currently have access to the Carbly Extension.</p>
            
            <p style={{marginTop: '20px'}}><a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout()}}>Logout</a></p>
            <p><a href="https://getcarbly.com">Visit GetCarbly.com</a></p>
          </div>
        </div>
      </div>
    )
  }
}
