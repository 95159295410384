import React from 'react'
import { useHistory } from "react-router-dom"
import { DropdownItem } from 'reactstrap'

function AccountButton() {
  let history = useHistory();

  return (
    <DropdownItem onClick={() => {
      history.push('/settings/account')
    }}>
    <div className="icon account-icon">
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-credit-card-2-back-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2z"/>
        <path d="M0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
      </svg>
    </div>
      Account settings
    </DropdownItem>
  );
}


export default AccountButton;
