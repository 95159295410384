import React, { memo, useMemo } from 'react'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'

const DEFAULT_SIZE = AppConstants.avatarSizes.small
const DEFAULT_AVATAR_COLOR = '#666'

const Avatar = ({ user, firstName, lastName, image, className, size = DEFAULT_SIZE }) => {
  const _className = useMemo(() => {
    return [
      'user-avatar',
      className,
    ].filter(Boolean).join(' ')
  }, [className])

  const initials = useMemo(() => {
    const fn = firstName || user.first_name
    const ln = lastName || user.last_name
    const _initials = `${fn?.substring(0,1) || ''}${ln?.substring(0,1) || ''}`
    return _initials.toUpperCase()
  }, [user, firstName, lastName])

  var avatarImage = null
  if (image !== undefined) {  // Null is passed in intentionally when removing photo from profile, so only undefined is checked
    avatarImage = image
  } else if (user.avatar_url) {
    avatarImage = `${platformFunctions.photoURLBase()}${user.avatar_url}`
  }

  var backgroundColor = (!avatarImage && user.avatar_color_hex) ? user.avatar_color_hex : DEFAULT_AVATAR_COLOR

  return (
    <div
      className={_className}
      style={{ width: size, height: size, backgroundColor: backgroundColor }}
    >
      {
        avatarImage ?
        <div
          className='user-avatar__image'
          style={{ backgroundImage: `url(${avatarImage})` }}
        /> :
        <div
          className='user-avatar__initials no-select'
          style={{ fontSize: size * 0.45 }}
        >
          {initials}
        </div>
      }
    </div>
  )
}

export default memo(Avatar)
