import React from 'react'

export default class ClockIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '16px'
    const height = this.props.height || '16px'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-plus-circle" fill={color} style={{...this.props.style}} xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
      </svg>
    )
  }

}