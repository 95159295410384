import React from 'react'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import ValuationStore from '../../api/stores/ValuationStore'

export default class DataTable extends React.Component {
  state = {
    currentSort: this.props.defaultSort,
    currentSortDirection: 'ASC',
    isShowingAllData: false
  }

  setSort = (accessor) => {
    const { currentSort, currentSortDirection } = this.state
    var newSortDirection = null
    var newSort = currentSort

    if (currentSort === accessor) {
      newSortDirection = currentSortDirection === 'ASC' ? 'DESC' : 'ASC'
    } else {
      newSort = accessor
      newSortDirection = 'ASC'
    }

    this.setState({
      currentSort: newSort,
      currentSortDirection: newSortDirection
    })
  }


  render() {
    const { data, columns, providerKey, valuationMileage, icon, paginationCount } = this.props

    if (!data || !columns) { return null }

    const { currentSort, currentSortDirection, isShowingAllData } = this.state

    var sortedData = null
    if (currentSortDirection === 'ASC') {
      sortedData = data.sort((a,b) => {
        if (a[currentSort]['raw_value'] === null) { return 1 }
        if (b[currentSort]['raw_value'] === null) { return -1 }
      
        return (a[currentSort]['raw_value'] > b[currentSort]['raw_value']) ? 1 : ((a[currentSort]['raw_value'] < b[currentSort]['raw_value']) ? -1 : 0)
      })
    } else {
      sortedData = data.sort((a,b) => (a[currentSort]['raw_value'] < b[currentSort]['raw_value']) ? 1 : ((a[currentSort]['raw_value'] > b[currentSort]['raw_value']) ? -1 : 0))
    }

    const isMileageSort = currentSort === 'mileage'
    return (
      <div>
        <table className="data-table">
          <thead>
            <tr>
              {
                columns.map((col, index) => {
                  if (col['display'] !== false) {
                    var sortDisplay = ''
                    if (col['accessor'] === currentSort) {
                      let sortString = currentSortDirection === 'ASC' ? ' ▲' : ' ▼'
                      sortDisplay = (<span className='sort-indicator'>{sortString}</span>)
                    }
                    return (
                      <th key={`dt-th-${providerKey}-${index}`} className={`header-${providerKey}-${col['accessor']}`}><a href="#sort" onClick={(e) => {
                        e.preventDefault();
                        if (col['sortable'] !== false) {
                          this.setSort(col['accessor'])
                        }
                      }}>
                        {
                          col['showHeader'] !== false &&
                          <div>{col['title']} {sortDisplay}</div>
                        }
                      </a></th>
                    )
                  }
                })
              }
              {
                Boolean(icon) &&
                <th></th>
              }
            </tr>
          </thead>
          <tbody>
            {
              sortedData.map((row, index) => {
                if (paginationCount && !isShowingAllData && index > paginationCount) { return null }
                var rowClass = ''
                if (valuationMileage && row['mileage']) {
                  if (ValuationFunctions.isMileageInNarrowMatchRange(row['mileage']['raw_value'], valuationMileage)) {
                    rowClass = 'narrow-range-match'
                  } else if (ValuationFunctions.isMileageInBroadMatchRange(row['mileage']['raw_value'], valuationMileage)) {
                    rowClass = 'broad-range-match'
                  }
                }

                // This is the actual vehicle we're looking at
                if (this.props.highlightVIN && this.props.currentVIN && row.vin?.raw_value === this.props.currentVIN) {
                  rowClass = `${rowClass} highlighted-match`
                }

                return (
                  <tr key={`dt-tr-${providerKey}-${index}`} className={`data-row ${rowClass}`} onClick={this.props.onClick ? () => {
                      if (this.props.isClickable !== false) {
                        this.props.onClick(row[this.props.onClickDataKey])
                      }
                      
                    } : null } style={{cursor: this.props.onClick && this.props.isClickable !== false ? 'pointer' : 'default'}}>
                    {
                      columns.map((col) => {
                        if (col.display === false) { return null }
                        var style = col.width ? {width: col.width, maxWidth: col.width} : {}
                        if (col.reduced_size === true) {
                          style.fontSize = '0.8rem'
                        }
                        if (col.hide_overflow === true) {
                          style['textOverflow'] = 'ellipsis'
                          style['whiteSpace'] = 'nowrap'
                          style.overflow = 'hidden'
                        }

                        return (
                          <td key={`dt-td-${providerKey}-${col.accessor}`} style={style} className={`col-${providerKey}-${col['accessor']}`}>
                            {
                              col.accessor === 'color' &&
                              row[col.accessor]['values'].map((c, index) => {

                                if (!Boolean(c['display_value'])) { return null}
                                const normalizedColor = ValuationFunctions.normalizedColor(c['display_value'])
                                if (!normalizedColor) { return null }
                                return (
                                  <div key={`color-${index}`}>
                                    {
                                      Boolean(c['label']) &&
                                      <div style={{marginLeft: '15px', marginTop: index > 0 ? '3px' : 0, opacity: 0.4, marginBottom: '-3px'}}>{c['label']}</div>
                                    }
                                    <div style={{width: '12px', height: '12px', borderRadius: '6px', float: 'left', marginTop: '5px', marginRight: '5px', backgroundColor: normalizedColor.colorClass ? normalizedColor.colorClass : '', border: normalizedColor.colorClass === 'white' ? '1px solid #ddd' : 'none'}}></div>
                                    {
                                      !global.isExtensionMode &&
                                      <span className="color-name">{normalizedColor.colorName}</span>
                                    }
                                  </div>
                                )
                              })

                            }
                            {
                              col.accessor !== 'photo' && col.accessor !== 'color' &&
                              row[col.accessor]['display_value']
                            }
                            {
                              col.accessor === 'photo' && row[col.accessor]['display_value'] &&
                              <img src={row[col.accessor]['display_value']} alt="Vehicle" style={{width: '100%'}} />
                            }
                          </td>
                        )
                      })
                    }
                    {
                      Boolean(icon) &&
                      <td style={{width: '38px', textAlign: 'right', paddingRight: '8px'}}>{icon}</td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          Boolean((paginationCount && !isShowingAllData && sortedData.length > paginationCount) || isShowingAllData) &&
          <div style={{textAlign: 'center', marginTop: '10px'}}>
            <a href="#toggle" onClick={(e) => {
              e.preventDefault()
              this.setState({ isShowingAllData: !isShowingAllData })
            }}>Show {isShowingAllData ? 'fewer' : `all ${sortedData.length}`} rows</a>
          </div>
        }

        {

        }
      </div>
    )
  }
}
