import React from 'react'
import moment from 'moment'

const updateInterval = 30000

export default class TimeAgo extends React.Component {
  state: {
    timer: null
  }

  componentDidMount() {
    this.createTimer();
  }

  componentWillUnmount() {
    if (this.state?.timer) {
      clearTimeout(this.state.timer);
    }
  }

  update = () => {
    this.forceUpdate()
    this.createTimer()
  };

  createTimer = () => {
  this.setState({
    timer: setTimeout(() => {
      this.update()
    }, updateInterval)
  });
};

  render() {
    const { time, tenseArray, shortMode } = this.props
    let preString = ''
    if (tenseArray) {
      const nowDate = new Date()
      preString = time < nowDate.toISOString() ? tenseArray[1] : tenseArray[0]
    }

    let timeString = moment(time).fromNow()
    if (shortMode === true && moment(time).isSame(new Date(), 'd')) {
      timeString = 'Today'
    }

    return (
      <span>{preString}{timeString}</span>
    )
  }
}
