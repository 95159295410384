import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

export default {
  updateAutoCheckCredentials: function(carfaxUsername) {
    TFDispatcher.dispatchAction(ActionTypes.USER_UPDATE_AUTOCHECK_CREDENTIALS, () => {WebAPIUtils.updateAutoCheckCredentials(carfaxUsername)} )
  },

  removeAutoCheckCredentials: function() {
    TFDispatcher.dispatchAction(ActionTypes.USER_REMOVE_AUTOCHECK_CREDENTIALS, () => {WebAPIUtils.removeAutoCheckCredentials()} )
  },

  changeTab: function(tabName) {
    TFDispatcher.dispatchAction(ActionTypes.TAB_SELECTION_CHANGED, null, {lastSelectedTab: tabName} )
  },

  updateUser: function(params) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_USER, () => {WebAPIUtils.updateUser(params)} )
  },

  updateUserAuctionFilters: function(params) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_USER_AUCTION_FILTERS, () => {WebAPIUtils.updateUserAuctionFilters(params)} )
  },

  fetchCarfaxAuthorizeUrl: function(redirectUrl) {
    TFDispatcher.dispatchAction(ActionTypes.FETCH_CARFAX_AUTHORIZE_URL, () => {WebAPIUtils.fetchCarfaxAuthorizeUrl(redirectUrl)} )
  },

  logoutCarfaxConnect: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOGOUT_CARFAX_CONNECT, () => {WebAPIUtils.logoutCarfaxConnect()} )
  },

  addUser: function(email, userType) {
    TFDispatcher.dispatchAction(ActionTypes.ADD_USER, () => {WebAPIUtils.addUser(email, userType)} )
  },

  loadAdditionalUserPricing: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_ADDITIONAL_USER_PRICING, () => {WebAPIUtils.loadAdditionalUserPricing()} )
  },

  loadAccountDetails: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_ACCOUNT_DETAILS, () => {WebAPIUtils.loadAccountDetails()} )
  },

  loadAvailableOfferings: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AVAILABLE_OFFERINGS, () => {WebAPIUtils.loadAvailableOfferings()} )
  },

  loadPricingForOfferings: function(offerings, billingInterval) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_PRICING_FOR_OFFERINGS, () => {WebAPIUtils.loadPricingForOfferings(offerings, billingInterval)} )
  },

  addOfferings: function(offerings) {
    TFDispatcher.dispatchAction(ActionTypes.ADD_OFFERINGS, () => {WebAPIUtils.addOfferings(offerings)} )
  },

  loadOfferingsSlate: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_OFFERINGS_SLATE, () => {WebAPIUtils.loadOfferingsSlate()} )
  },

  reactivateAccount: function(offeringSKUs, billingInterval) {
    TFDispatcher.dispatchAction(ActionTypes.REACTIVATE_ACCOUNT, () => {WebAPIUtils.reactivateAccount(offeringSKUs, billingInterval)} )
  },

  updateBillingInterval: function(billingInterval) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_BILLING_INTERVAL, () => {WebAPIUtils.updateBillingInterval(billingInterval)} )
  },

  updateCC: function(srcID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_CC, () => {WebAPIUtils.updateCC(srcID)} )
  },

  retryCurrentCC: function() {
    TFDispatcher.dispatchAction(ActionTypes.RETRY_CC, () => {WebAPIUtils.retryCurrentCC()} )
  },

  toggleUserGlobalAuctionFilters: function(enabled) {
    TFDispatcher.dispatchAction(ActionTypes.TOGGLE_USER_GLOBAL_AUCTION_FILTERS, () => {WebAPIUtils.toggleUserGlobalAuctionFilters(enabled)} )
  },

  updatePermissions: function(permissions) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_PERMISSIONS, () => {WebAPIUtils.updatePermissions(permissions)} )
  },

  loadEdgeAuthorizeURL: function(redirectURL, parametersString = null) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_EDGE_AUTHORIZE_URL, () => {WebAPIUtils.loadEdgeAuthorizeUrl(redirectURL, parametersString)} )
  },

  loadAuctionListingBids: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_LISTING_BIDS, () => {WebAPIUtils.loadAuctionListingBids()} )
  },

  logoutAuctionEdge: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOGOUT_AUCTION_EDGE, () => {WebAPIUtils.logoutAuctionEdge()} )
  },

  submitListingBidAudit: function(params) {
    TFDispatcher.dispatchAction(ActionTypes.SUBMIT_LISTING_BID_AUDIT, () => {WebAPIUtils.submitListingBidAudit(params)} )
  },

  createMetric: function(key, value = null, metadata = null) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_METRIC, () => {WebAPIUtils.createMetric(key, value, metadata)} )
  },

  updateRooftop: function(params) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_ROOFTOP, () => {WebAPIUtils.updateRooftop(params)} )
  }
}
