import { useState, useMemo, useCallback } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import RetailModelStats from '../../components/retail/ModelStats'
import useModelStatsProvider from './util/useModelStatsProvider'
import ListingsModal from '../../components/retail/ListingsModal'

const ModelStatsModal = ({ onClose, regionParams, selectedModelStat }) => {
  const {
    make: initialMake,
    model: initialModel,
  } = selectedModelStat

  const [
    {
      isShowingListingsModal,
      selectedModelStatForListingsModal,
    },
    setState,
  ] = useState({
    isShowingListingsModal: false,
    selectedModelStatForListingsModal: null,
  })

  const regionalListingsParams = useMemo(() => {
    if (!selectedModelStatForListingsModal) return null
    const { year, make, model, trim } = selectedModelStatForListingsModal
    const { zip, radius } = regionParams
    return {
      zip,
      radius,
      year,
      make,
      model,
      trim,
    }
  }, [selectedModelStatForListingsModal, regionParams])

  const {
    onSelectedMakeModelChange,
    onSelectedYearChange,
    onSelectedTrimChange,
    isLoadingDrilldownStats,
    drilldownStats,
    selectedMakeModelOption,
    selectedYearOption,
    selectedTrimOption,
  } = useModelStatsProvider({ regionParams, selectedMakeModelOption: selectedModelStat })

  const onListingsShowClick = useCallback((item, e) => {
    e.preventDefault()
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_listings_show'])
    setState(prev => ({ ...prev, isShowingListingsModal: true, selectedModelStatForListingsModal: item }))
  }, [])

  const onListingsModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingListingsModal: false, selectedModelStatForListingsModal: null }))
  }, [])

  const makeModelStats = useMemo(() => [selectedModelStat], [selectedModelStat])

  return (
    <>
      <Modal isOpen={true} zIndex={10000} backdrop={true} toggle={onClose} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '840px'}}>
        <ModalHeader className='modal-header split-modal-header'>
          {initialMake} {initialModel}
        </ModalHeader>
        <ModalBody>
          <RetailModelStats
            makeModelStats={makeModelStats}
            drilldownStats={drilldownStats}
            selectedTrimOption={selectedTrimOption}
            selectedYearOption={selectedYearOption}
            selectedMakeModelOption={selectedMakeModelOption}
            regionParams={regionParams}
            onListingsShowClick={onListingsShowClick}
            onSelectedMakeModelChange={onSelectedMakeModelChange}
            onSelectedYearChange={onSelectedYearChange}
            onSelectedTrimChange={onSelectedTrimChange}
            isLoadingDrilldownStats={isLoadingDrilldownStats}
            isDisabled
          />
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
      {
        isShowingListingsModal &&
        <ListingsModal
          onClose={onListingsModalCloseClick}
          regionalListingsParams={regionalListingsParams}
        />
      }
    </>
  );
}

export default ModelStatsModal
