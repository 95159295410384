import queryString from 'query-string'

const QueryParamsFunctions = {
  toObject: (uri = window.location.search) => {
    const params = queryString.parse(uri) || {}
    return Object.keys(params).reduce((accum, key) => {
      return {
        ...accum,
        [key]: decodeURIComponent(params[key]),
      }
    }, {})
  },

  toQueryParams: (obj) => {
    if (!obj) return null
    return Object.keys(obj).reduce((accum, key) => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        accum.push(`${key}=${encodeURIComponent(obj[key])}`)
      }
      return accum
    }, [])
    .join('&')
  },

  delete: (key) => {
    const obj = QueryParamsFunctions.toObject()
    delete obj[key]
    return QueryParamsFunctions.toQueryParams(obj)
  },

  append: (key, value) => {
    const obj = QueryParamsFunctions.toObject()
    obj[key] = value
    return QueryParamsFunctions.toQueryParams(obj)
  },
}

export default QueryParamsFunctions
