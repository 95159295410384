import { useCallback, useState, useEffect } from 'react'
import SessionStore from '../../stores/SessionStore'

export default () => {
 const [hasLinkedAuctionEdge, setHasLinkedAuctionEdge] = useState(SessionStore.hasLinkedAuctionEdge())

  const userDidChange = useCallback(() => {
    setHasLinkedAuctionEdge(SessionStore.hasLinkedAuctionEdge())
  }, [])

  useEffect(() => {
    SessionStore.on('user_data_changed', userDidChange)
    SessionStore.on('auth_state_changed', userDidChange)
    return () => {
      SessionStore.removeListener('user_data_changed', userDidChange)
      SessionStore.removeListener('auth_state_changed', userDidChange)
    }
  }, [userDidChange])

  return hasLinkedAuctionEdge
}
