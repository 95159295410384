import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import ValuationStore from '../../api/stores/ValuationStore'
import SettingsButton from './SettingsButton'
import AccountButton from './AccountButton'
import LogoutButton from './LogoutButton'
import UserProfile from './Profile'
import AppConstants from '../../api/constants/AppConstants'


export default class AccountBug extends React.PureComponent {
  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }
  state = {
    user: SessionStore.user,
    isShowingAccountDropdown: false
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.userWasRefreshed)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.userWasRefreshed)
  }

  userWasRefreshed = () => {
    if (this.refs.mainRef) {
      this.setState({
        user: SessionStore.user
      })
    }
  }

  render() {
    if (this.isShared || global.isExtensionMode || global.isIFrameMode) { return null }
    const { user, isShowingAccountDropdown } = this.state

    return (
      <div className="account-bug-c" ref='mainRef'>
        <img src="/images/carbly-nav-logo-dark.png" alt="Carbly" className="account-bug-logo" />
        <div className="account-bug-c">
          <Dropdown isOpen={isShowingAccountDropdown} toggle={() => this.setState({ isShowingAccountDropdown: !isShowingAccountDropdown })} tag="div">
            <DropdownToggle tag="div">
              <div className="account-bug cursor-pointer">
                <UserProfile user={user} className='account-bug__user-profile' />
                <div>
                  <svg width="14px" height="1em" viewBox="0 0 16 16" fill="#888" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu right className="account-bug-dropdown">
              <DropdownItem header>
                <div>
                  <div className='account-dropdown-name text-ellipsis'>{user.full_name}</div>
                  <div>{user.rooftop.name}</div>
                  <div style={{marginTop: '5px'}}>{user.phone_number}</div>
                  <div>{user.email}</div>
                </div>
              </DropdownItem>

              <DropdownItem divider/>

              <SettingsButton />
              <AccountButton />

              <DropdownItem divider/>

              <LogoutButton />

              <DropdownItem divider/>

              <DropdownItem header>Need help? Give us a call at {AppConstants.phoneNumber}</DropdownItem>

            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    )
  }

}

// <Dropdown isOpen={isShowingNoticationAlertDropdown} toggle={() => this.setState({ isShowingNoticationAlertDropdown: !isShowingNoticationAlertDropdown })} tag="div">
//   <DropdownToggle tag="div">
//   <svg className="bi bi-bell-fill notification-alert" width="20px" height="20px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
//     <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
//   </svg>
//   </DropdownToggle>
//   <DropdownMenu right className="account-bug-dropdown">
//     <DropdownItem header>
//       <div>
//         <div className='account-dropdown-name'>{user.full_name}</div>
//         <div>{user.rooftop.name}</div>
//         <div style={{marginTop: '5px'}}>{user.phone_number}</div>
//         <div>{user.email}</div>
//       </div>
//     </DropdownItem>
//
//   </DropdownMenu>
// </Dropdown>
