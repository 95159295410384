import React from 'react'
import { Link } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup, Circle, LayerGroup} from 'react-leaflet'
import L from 'leaflet'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import GearIcon from '../common/icons/GearIcon'
import PlusIcon from '../common/icons/PlusIcon'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import XIcon from '../common/icons/XIcon'
import platformFunctions from '../../platformFunctions'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const MAP_HEIGHT = '320px'
const MAP_OVERLAY_WIDTH = 380


export default class AuctionsMap extends React.Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
    this.distanceCircleRef = React.createRef()
  }

  state = {
    user: SessionStore.user,
    isLoadingAllAuctions: true,
    hasDoneInitialZoom: false,
    visibleMorePopoverCode: null
  }

  componentDidMount() {
    AuctionStore.on('auctions_by_state_change', this.auctionsByStateDidChange)
    SessionStore.on("user_data_changed", this.zoomToFitMarkers)
    AuctionsActionCreators.loadAuctionsByState()

    // Map might not be initialized when auctions are loaded, safety attempt
    setTimeout(() => {
      this.initialZoom()
    }, 2000)
  }

  componentWillUnmount() {
    AuctionStore.removeListener('auctions_by_state_change', this.auctionsByStateDidChange)
    SessionStore.removeListener('user_data_changed', this.zoomToFitMarkers)
  }

  componentDidUpdate(prevProps) {
    if ((this.props.scopeType !== prevProps.scopeType) || (this.props.distanceRadiusMiles !== prevProps.distanceRadiusMiles) || (this.props.centerForDistanceScopeType?.latitude !== prevProps.centerForDistanceScopeType?.latitude) || (this.props.centerForDistanceScopeType?.longitude !== prevProps.centerForDistanceScopeType?.longitude)) {
      setTimeout(() => {
        this.zoomToFitMarkers()
      }, 100)

    }
  }

  auctionsByStateDidChange = () => {
    this.setState({
      isLoadingAllAuctions: false
    })

    this.initialZoom()
  }

  initialZoom = () => {
    if (!this.mapRef.current || !AuctionStore.allAuctions || this.state.hasDoneInitialZoom) {
      return
    }

    this.zoomToFitMarkers()
    this.setState({ hasDoneInitialZoom: true })
  }

  zoomToFitMarkers = () => {
    const { distanceRadiusMiles, maxInitialZoom } = this.props
    const allAuctions = AuctionStore.allAuctions

    const globalAuctionCodes = AuctionStore.savedSearches?.global?.physical_codes || []
    if (((!this.distanceCircleRef || !this.distanceCircleRef.current) && distanceRadiusMiles) || !this.mapRef || !this.mapRef.current) {
      return
    }

    const { scopeType } = this.props
    const map = this.mapRef.current;
    var displayBounds = null
    var paddingOptions = null

    if ((!allAuctions || !globalAuctionCodes || globalAuctionCodes.length === 0)) {
      const user = SessionStore.user
      if (user?.rooftop?.longitude && user?.rooftop?.latitude) {
        const rooftopLatitude = user.rooftop.latitude
        const rooftopLongitude = user.rooftop.longitude

        displayBounds = [[parseFloat(rooftopLatitude), parseFloat(rooftopLongitude)], [parseFloat(rooftopLatitude), parseFloat(rooftopLongitude)]]
        paddingOptions = {paddingBottomRight: [MAP_OVERLAY_WIDTH, 50], paddingTopLeft: [0, 50], maxZoom: 7}

      } else {
        return
      }
    }

    if (scopeType === 'distance') {
      displayBounds = this.distanceCircleRef?.current?.getBounds()
      paddingOptions = this.props.hideAuctions === true ? null : {paddingBottomRight: [MAP_OVERLAY_WIDTH, 10], paddingTopLeft: [0, 10], maxZoom: maxInitialZoom || 9}
    } else {
      if (allAuctions && globalAuctionCodes && globalAuctionCodes.length > 0) {
        var maxZoom = globalAuctionCodes.length > 1 ? 9 : 7
        maxZoom = maxInitialZoom || maxZoom
        var minLongitude, maxLongitude, minLatitude, maxLatitude
        for (let globalAuctionCode of globalAuctionCodes) {
          const matchLocation = AuctionStore.auctionDetailsForLocationCode(globalAuctionCode)
          if (matchLocation) {
            minLongitude = !minLongitude || matchLocation.longitude < minLongitude ? matchLocation.longitude : minLongitude
            maxLongitude = !maxLongitude || matchLocation.longitude > maxLongitude ? matchLocation.longitude : maxLongitude
            minLatitude = !minLatitude || matchLocation.latitude < minLatitude ? matchLocation.latitude : minLatitude
            maxLatitude = !maxLatitude || matchLocation.latitude > maxLatitude ? matchLocation.latitude : maxLatitude
          }
        }

        displayBounds = [[minLatitude, minLongitude], [maxLatitude, maxLongitude]]
        paddingOptions = {paddingBottomRight: [MAP_OVERLAY_WIDTH, 50], paddingTopLeft: [0, 50], maxZoom: maxZoom}
      }
    }
    if (displayBounds) {
      map.fitBounds(displayBounds, paddingOptions)
    }
  }

  distanceForCicleDisplay = () => {
    const { distanceRadiusMiles } = this.props
    if (distanceRadiusMiles >= AppConstants.auctionSearchMaxDistanceRadius) {
      return 4000
    }

    return distanceRadiusMiles
  }


  render() {
    const { isLoadingAllAuctions, visibleMorePopoverCode } = this.state
    const { scopeType, hideAuctions = false, width, height, showAddAuction, centerForDistanceScopeType } = this.props

    const allAuctions = AuctionStore.allAuctions
    const globalAuctionCodes = AuctionStore.savedSearches?.global?.physical_codes || []

    var center = centerForDistanceScopeType ? [centerForDistanceScopeType.latitude, centerForDistanceScopeType.longitude] : [40.1, -82.0]
    var fullUserAuctions = []
    if (!hideAuctions && allAuctions && globalAuctionCodes) {
      for (let globalAuctionCode of globalAuctionCodes) {
        const matchLocation = AuctionStore.auctionDetailsForLocationCode(globalAuctionCode)
        if (matchLocation) {
          fullUserAuctions.push(matchLocation)
        }
      }
    }

    return (
      <div className="home-auctions-map-c" style={{height: height || MAP_HEIGHT}}>
        <MapContainer ref={this.mapRef} attributionControl={false} bounds={null} zoom={3.4} zoomSnap={0.25} center={center} scrollWheelZoom={true} style={{width: width || '100%', height: height || MAP_HEIGHT, borderRadius: '10px 0 0 10px', flexGrow: 1, flexShrink: 1, zIndex: 100}}>
          <TileLayer attribution='' url="https://tileserver.getcarbly.com/osm/{z}/{x}/{y}.png" />
          {
            !isLoadingAllAuctions && !hideAuctions &&
            allAuctions.map((auction) => {
              if (auction.latitude && auction.longitude) {
                const userHasAuction = Boolean(globalAuctionCodes?.includes(auction.code))
                return (
                  <Marker position={[auction.latitude, auction.longitude]} icon={ userHasAuction ? primaryMarker : secondaryMarker } key={`m-${auction.code}`}>
                    <Popup ref={`pop-${auction.code}`}>
                      <div className="auction-map-popup">
                        <h2>{auction.name}</h2>
                        <div className="split-row" style={{marginTop: '10px', paddingTop: '10px', borderTop: '1px solid #ddd'}}>
                          {/* <Link to={`/auctions/locations/${auction.id}`}>More details</Link> */}
                          <a href="#modify"
                            className="split-row split-row-left"
                            onClick={(e) => {
                              e.preventDefault()
                              if (userHasAuction && this.props.onRemoveAuction) {
                                platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location - Map View")
                                UserActionCreators.createMetric(AppConstants.metricNames['auctions_removed_auction'], 'map')
                                this.props.onRemoveAuction(auction.code)
                              } else if (!userHasAuction && this.props.onAddAuction) {
                                platformFunctions.logAnalyticsEvent("Auctions", "Added Auction Location - Map View")
                                UserActionCreators.createMetric(AppConstants.metricNames['auctions_added_auction'], 'map')
                                this.props.onAddAuction(auction.code)
                              }
                              if (this.mapRef.current) {
                                this.mapRef.current.closePopup();
                              }
                            }}
                            >
                            {
                              !userHasAuction &&
                              <PlusIcon style={{marginRight: '5px'}} />
                            }
                            {userHasAuction ? 'Remove' : 'Add'} Auction</a>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                )
              }
            })
          }

          {
            this.props.distanceRadiusMiles &&
            <LayerGroup>
              <Circle center={center} radius={this.distanceForCicleDisplay() * 1609.34} pathOptions={{ color: 'green', fillColor: 'green', opacity: scopeType === 'distance' ? 0.2 : 0, fillOpacity: scopeType === 'distance' ? 0.1 : 0 }} ref={this.distanceCircleRef} />
            </LayerGroup>
          }
        </MapContainer>

          {
            !isLoadingAllAuctions && !hideAuctions &&
            <div style={{width: `${MAP_OVERLAY_WIDTH}px`}} className="auctions-home-my-auctions-c">
            {
              fullUserAuctions && fullUserAuctions.length > 0 &&
              <div className="auctions-home-my-auctions">
                <h3 style={{color: 'rgba(0, 0, 0, 0.4)', textTransform: 'uppercase', fontWeight: 'bold'}}>Your Auctions</h3>
                {
                  fullUserAuctions.map((auction) => {
                    return (
                      <div key={`uaf-${auction.code}`} className="auction">
                        <div style={{fontWeight: 'bold'}}>
                          {auction.name}
                        </div>
                        <span onClick={(e) => e.preventDefault()} id={`more_popover_${auction.code}`} style={{cursor: 'hand'}}>
                          <GearIcon width={16} height={16} style={{cursor: 'hand'}}/></span>

                        <Popover placement="left" isOpen={visibleMorePopoverCode === auction.code} trigger="legacy" target={`more_popover_${auction.code}`} toggle={() => this.setState({ visibleMorePopoverCode: visibleMorePopoverCode === auction.code ? null : auction.code })}>
                          <PopoverHeader>Auction Actions</PopoverHeader>
                          <PopoverBody className='popover-med vehicle-row-actions' style={{maxHeight: '400px'}}>
                            {/* <Link to={`/auctions/locations/${auction.code}`} className='split-row split-row-left' href="#details"><DisclosureIcon width='22px' height='22px' style={{marginRight: '5px'}} /> Auction details</Link> */}
                            <a
                              href="#remove"
                              onClick={(e) => {
                                e.preventDefault()
                                if (this.props.onRemoveAuction) {
                                  platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location - Map View")
                                  UserActionCreators.createMetric(AppConstants.metricNames['auctions_removed_auction'], 'map')
                                  // Close the popover first
                                  this.setState({ visibleMorePopoverCode: null })
                                  setTimeout(() => {
                                    this.props.onRemoveAuction(auction.code)
                                  }, 50);
                                  
                                }
                              }}
                              className='split-row split-row-left'
                              ><XIcon color='currentColor' width='30px' height='30px' /> Remove auction</a>
                          </PopoverBody>
                        </Popover>
                      </div>
                    )
                  })
                }

                {
                  showAddAuction &&
                  <Link to='/auctions/settings/' style={{display: 'flex', alignItems: 'center', padding: '5px 0', color: '#516970'}}><PlusIcon style={{marginRight: '5px'}} /> Add an auction</Link>
                }
              </div>
            }
            {
              Boolean(!fullUserAuctions || fullUserAuctions.length === 0) &&
              <div>
                <h3 style={{color: 'rgba(0, 0, 0, 0.4)', textTransform: 'uppercase', fontWeight: 'bold'}}>Choose Some Auctions</h3>
                <p>You currently don't have any home auctions selected. Use the map to the left to add an auction, or
                  {
                    this.props.isEmbedded ?
                    <Link to='/auctions/settings/'> manage your auctions</Link> : ' select from the list below'
                  }.</p>
              </div>
            }
          </div>
          }

      </div>



    )
  }
}



const primaryMarker = new L.Icon({
  iconUrl: '/images/map-marker-primary.svg',
  iconSize: [48,48],
  iconAnchor: [24, 48],
  shadowUrl: '/images/map-marker-shadow.png',
  shadowSize: [39, 46],
  shadowAnchor: [12, 35]
});

const secondaryMarker = new L.Icon({
  iconUrl: '/images/map-marker-secondary.svg',
  iconSize: [16,16],
  iconAnchor: [8, 16],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});
