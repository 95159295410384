import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Spinner from '../common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import AppConstants from '../../api/constants/AppConstants'


export default class AddUserModal extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    isLoadingAdditionalUserPricing: true,
    isAddingUser: false,
    accountDetails: null,
    pricingData: null,
    selectedUserType: null,
    isValidEmailAddress: false,
    errors: null,
    email: '',
    userAdded: false
  }

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("additional_user_pricing_received", this.userPricingDataChanged)
    SessionStore.on("user_added", this.newUserAdded)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAdditionalUserPricing()
    platformFunctions.logAnalyticsEvent("User", "Show Add User")
    UserActionCreators.createMetric(AppConstants.metricNames['add_user_show'])
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("additional_user_pricing_received", this.userPricingDataChanged)
    SessionStore.removeListener("user_added", this.newUserAdded)
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  userPricingDataChanged = () => {
    const pricingData = SessionStore.upgradePricing

    this.setState({
      isLoadingAdditionalUserPricing: false,
      pricingData: pricingData
    })
  }

  emailChanged = (text) => {
    var sanitizedEmail = text.trim()
    const isValidEmailAddress = CommonFunctions.isValidEmailAddress(sanitizedEmail)
    this.setState({
      email: sanitizedEmail,
      isValidEmailAddress: isValidEmailAddress
    })
  }

  newUserAdded = () => {
    const errors = SessionStore.addUserErrors
    if (errors) {
      this.setState({
        errors: errors,
        isAddingUser: false
      })
    } else {
      this.setState({
        errors: null,
        isAddingUser: false,
        userAdded: true
      })
      setTimeout(() => {
        this.props.onDone()
      }, 4000)
    }
  }

  submitUser = () => {
    this.setState({ isAddingUser: true })
    UserActionCreators.addUser(this.state.email, this.state.selectedUserType)
  }

  render() {
    const { isLoadingAccountDetails, isLoadingAdditionalUserPricing, accountDetails, isAddingUser, pricingData, selectedUserType, email, isValidEmailAddress, userAdded, errors } = this.state

    const user = SessionStore.user
    const isSubmittable = selectedUserType !== null && isValidEmailAddress === true
    const isLoading = isLoadingAccountDetails || isLoadingAdditionalUserPricing || isAddingUser
    const isBillingIntervalYearly = accountDetails?.billing_interval === 'year'
    const isTrialing = SessionStore.isTrialing()
    const isCustomBilling = accountDetails?.custom_billing === true

    var prorationAmount = null
    if (selectedUserType === 'inherit') {
      prorationAmount = pricingData?.proration_amount?.inherit
    } else if (selectedUserType === 'aux') {
      prorationAmount = pricingData?.proration_amount?.aux
    }

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'>
        <ModalHeader className='modal-header'>
          Add a User
        </ModalHeader>
        <ModalBody>
          {
            (isLoading || isAddingUser) &&
            <Spinner />
          }
          {
            userAdded &&
            <div style={{textAlign: 'center', marginVertical: '20px'}}>User successfully added.  He or she will be emailed instructions on how to get started.</div>
          }
          {
            !isLoading && !userAdded && !isCustomBilling &&
            <>
              <h2 style={{marginBottom: '10px'}}>Select a User Type</h2>
              <Button className="settings-add-user-type-button" style={{opacity: selectedUserType === 'aux' ? 0.3 : 1.0}} onClick={() => this.setState({ selectedUserType: 'inherit' })}>
                      
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
                  <div style={{fontWeight: 'bold', fontSize: '1.4rem'}}>Full User</div>
                  <div style={{flexGrow: 1, flexShrink: 1, textAlign: 'right', fontWeight: 'bold'}}>{pricingData && pricingData['additional_users']['inherit'] ? `+ ${Formatters.formatCurrencyCents(pricingData['additional_users']['inherit'])} per ${isBillingIntervalYearly ? 'year' : 'month'}` : ''}</div>
                </div>
                {
                  selectedUserType !== 'aux' &&
                  <div>Has access to all of the same features and offerings as you.</div>
                }
              </Button>

              <Button className="settings-add-user-type-button" style={{opacity: selectedUserType === 'inherit' ? 0.3 : 1.0}} onClick={() => this.setState({ selectedUserType: 'aux' })}>
                      
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
                  <div style={{fontWeight: 'bold', fontSize: '1.4rem'}}>Non-Appraisal User</div>
                  <div style={{flexGrow: 1, flexShrink: 1, textAlign: 'right', fontWeight: 'bold'}}>{pricingData && pricingData['additional_users']['aux'] ? `+ ${Formatters.formatCurrencyCents(pricingData['additional_users']['aux'])} per ${isBillingIntervalYearly ? 'year' : 'month'}` : ''}</div>
                </div>
                {
                  selectedUserType !== 'inherit' &&
                  <div>No access to appraisal books and features, but can scan VINs and add vehicles, notes and photos.</div>
                }
              </Button>

              {
                selectedUserType !== null &&
                <Input type="email" className="field" name="email" id="email" style={{marginTop: '10px'}} onChange={e => this.emailChanged(e.target.value)} value={email} placeholder="User's email address" autoFocus={true} />
              }

              {
                errors !== null &&
                <div className="account-error">{errors.join(' ')}</div>
              }
              {
                isTrialing &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>You will not be charged until your free trial ends</div>
                }
              {
                !isTrialing && !prorationAmount && selectedUserType &&
                <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>Your next bill will include a prorated charge</div>
              }

              {
                !isTrialing && prorationAmount &&
                <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px', color: 'red', fontWeight: 'bold'}}>
                You will be immediately charged {Formatters.formatCurrencyCents(prorationAmount)} for the prorated amount of your current billing cycle</div>
              }
            </>
          }

          {
            isCustomBilling &&
            <div>
              Your account has custom billing.  If you'd like to add a user, please give us a call at {AppConstants.phoneNumber} and we'd be happy to help.
            </div>
          }


          
        </ModalBody>
        {
          !isLoading && !isAddingUser && !userAdded &&
          <ModalFooter>
            <Button color='secondary' onClick={this.props.onDone}>Cancel</Button>
            {
              !isCustomBilling &&
              <Button color='primary' disabled={!isSubmittable} onClick={this.submitUser}>Add User</Button>
            }
          </ModalFooter>
        }
      </Modal>
    )
  }
}