import React from 'react'
import { Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import CommonFunctions from '../../api/constants/CommonFunctions'
import DoneAnimation from '../common/animations/DoneAnimation'


export default class TargetPrice extends React.Component {
  state = {
    isSavingTargetPrice: false,
    modifiedTargetPrice: this.props.targetPrice ? `${this.props.targetPrice}` : '',
    isShowingTargetDoneAnimation: false
  }

  componentDidMount() {
    ValuationStore.on(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
  }

  valuationDidChange = () => {
    if (this.state.isSavingTargetPrice) {
      this.setState({
        isSavingTargetPrice: false,
        isShowingTargetDoneAnimation: true
      })
      setTimeout(() => {
        if (this.refs.targetPriceRef) {
          this.refs.targetPriceRef.blur()
        }
      })
      setTimeout(() => {
        if (this.refs.mainRef) {
          this.setState({
            isShowingTargetDoneAnimation: false
          })
        }
      }, 2200)
    }
  }

  saveTargetPrice = () => {
    const {modifiedTargetPrice} = this.state
    this.setState({isSavingTargetPrice: true })

    if (modifiedTargetPrice === '0') {
      setTimeout(() => {
        this.setState({modifiedTargetPrice: '' })
      }, 100)
    }

    ValuationActionCreators.updateValuation(this.props.vehicleUUID, {target_bid_price: parseInt(modifiedTargetPrice)}, this.props.clientID)
    if (this.props.onSetTargetPrice) {
      this.props.onSetTargetPrice()
    }
  }


  render() {
    const { modifiedTargetPrice, isSavingTargetPrice, isShowingTargetDoneAnimation } = this.state
    const displayedTargetPrice = modifiedTargetPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    var targetPriceIsSubmittable = !isSavingTargetPrice && modifiedTargetPrice ? modifiedTargetPrice.trim().length > 0 : false
    if (targetPriceIsSubmittable && this.props.targetPrice && this.props.targetPrice === parseInt(modifiedTargetPrice)) {
      targetPriceIsSubmittable = false
    }

    return (
      <div className="valuation-target-price-c" ref='mainRef'>
        <form style={{display: 'flex', flexDirection: 'row'}} onSubmit={(e) => {e.preventDefault(); if (targetPriceIsSubmittable) {this.saveTargetPrice()} }}>
        
          <div style={{flexGrow: 1, flexShink: 1, marginTop: '2px', position: 'relative'}}>
            <input
              ref='targetPriceRef'
              type="text"
              className="field"
              name="zip"
              onChange={(e) => {
                const formattedTarget = CommonFunctions.stripNonNumericNoDecimal(e.target.value)
                this.setState({modifiedTargetPrice: formattedTarget})
              }}
              value={displayedTargetPrice}
              placeholder="Set a target bid"
              maxLength="7"
              autoComplete="off" />
              <i>$</i>
          </div>

          {
            (targetPriceIsSubmittable || isSavingTargetPrice || isShowingTargetDoneAnimation) &&
            <Button color="primary" onClick={this.saveTargetPrice} style={{flexGrow: 0, flexShrink: 0, marginLeft: '5px', width: '80px'}} disabled={isSavingTargetPrice}>
              {
                isShowingTargetDoneAnimation &&
                  <DoneAnimation style={{ width: 32, height: 32, margin: '0 auto'}} />
              }
              {
                !isShowingTargetDoneAnimation &&
                <span>Set</span>
              }
            </Button>
          }
        </form>
      </div>
      

    )
  }

}
