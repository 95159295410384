import ValuationFunctions from './ValuationFunctions'

export default {

  initializeChoices: function(valuationPreview) {
    var choices = valuationPreview?.preview_valuations_premapped

    if (choices) {
      // Auto select if only 1 choice
      for (let providerKey of Object.keys(choices)) {
        if (choices[providerKey].length === 1) {
          choices[providerKey]['selectedChoiceUID'] = choices[providerKey][0].description.uid
        }
      }
    }

    return choices
  },

  areChoicesComplete: function(choices) {
    if (choices) {
      for (let providerKey of Object.keys(choices)) {
        if (!choices[providerKey]['selectedChoiceUID']) {
          return false
        }
      }
    }
    return true
  },

  showChoicesAsEmbedded: function(choices) {
    // We don't want to show the top choices section if LLM is the only one on embedded valuations
    for (let providerKey of Object.keys(choices)) {
      if (providerKey !== 'universe' && !choices[providerKey].selectedChoiceUID) {
        return true
      }
    }
    return false
  },

  formattedChoicesForServer: function(choices) {
    var serverChoices = {}
    for (let providerKey of Object.keys(choices)) {
      serverChoices[providerKey] = choices[providerKey]['selectedChoiceUID']
    }
    return serverChoices
  },

  resetChoices: function(choices) {
    var newChoices = Object.assign(choices)
    for (let providerKey of Object.keys(newChoices)) {
      if (newChoices[providerKey].length > 1 && newChoices[providerKey]['selectedChoiceUID']) {
        delete newChoices[providerKey]['selectedChoiceUID']
      }
    }
    return newChoices
  },

  handleChoiceSelection: function(oldChoices, providerKey, selectedChoiceUID) {
    var newChoices = Object.assign(oldChoices)
    var selectedChoice = ValuationFunctions.valuationForChoice(newChoices[providerKey], selectedChoiceUID)

    if (!selectedChoice) {
      // The on-card choices for auctions always show choices, even if there's a grouped match
      // In this case, we have to traverse up and find a parent that has this choice mapped, and use it as the selected choice
      var foundChild = false
      for (let key of Object.keys(newChoices)) {
        let providerChoices = newChoices[key]
        for (let choice of providerChoices) {
          if (choice.mappings && choice.mappings[providerKey] && choice.mappings[providerKey].length === 1 && choice.mappings[providerKey][0] === selectedChoiceUID) {
            newChoices[key]['selectedChoiceUID'] = choice.description.uid
            selectedChoice = choice
            foundChild = true
            break
          }
        }
        if (foundChild === true) { break }
      }
    }

    if (!selectedChoice) {
      // Something has gone wrong with the child choice lookup
      return
    }

    var mappings = selectedChoice['mappings']

    if (newChoices[providerKey]) {
      // May have been a child node on-card, which will be set later via mappings
      newChoices[providerKey]['selectedChoiceUID'] = selectedChoiceUID
    }

    if (mappings) {
      for (let mappedProviderKey of Object.keys(mappings)) {
        let possibleChoicesForOtherProvider = newChoices[mappedProviderKey]
        if (mappings[mappedProviderKey].length === 1) {
          // Selected provider tells us we have a direct match for another provider for this choice. Discard everything else for the other provider
          if (!newChoices[mappedProviderKey]) {
            newChoices[mappedProviderKey] = {'selectedChoiceUID': mappings[mappedProviderKey][0]}
          } else {
            newChoices[mappedProviderKey]['selectedChoiceUID'] = mappings[mappedProviderKey][0]
          }

        } else if (possibleChoicesForOtherProvider && !possibleChoicesForOtherProvider['selectedChoiceUID']) {
          // Loop through all existing choices for the other provider, if a choice isn't in the mappings for the selected choice, remove it
          // Doing this while loop so that we can safely delete items from the array as we go
          var i = possibleChoicesForOtherProvider.length
          while (i--) {
            let otherProviderChoice = possibleChoicesForOtherProvider[i]
            if (!mappings[mappedProviderKey].includes(otherProviderChoice.description.uid)) {
              possibleChoicesForOtherProvider.splice(i, 1);
            }
          }
        }
      }
    }
    return newChoices
  }
}
