import React from 'react'
import { Button } from 'reactstrap'
import ChoicesFunctions from '../../api/constants/ChoicesFunctions'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'


export default class ValuationChoices extends React.Component {

  state = {
    hasMadeAChoice: false,
    choices: null
  }

  componentDidMount() {
    this.initializeChoices()
  }

  initializeChoices = () => {
    const choices = this.props.valuationPreviewChoices
    this.setState({
      choices: choices,
      hasMadeAChoice: false
    })

    if (ChoicesFunctions.areChoicesComplete(choices)) {
      this.props.onChoicesComplete(choices)
    }
  }

  resetChoices = (e) => {
    const newChoices = ChoicesFunctions.resetChoices(this.state.choices)
    this.setState({
      choices: newChoices,
      hasMadeAChoice: false
    })
    setTimeout(() => {
      this.initializeChoices()
    }, 50)
  }

  onChoice = (providerKey, selectedChoiceUID) => {
    const newChoices = ChoicesFunctions.handleChoiceSelection(this.state.choices, providerKey, selectedChoiceUID)

    if (ChoicesFunctions.areChoicesComplete(newChoices)) {
      this.props.onChoicesComplete(newChoices)
    } else {
      this.setState({
        choices: newChoices,
        hasMadeAChoice: true
      })
    }
  }

  renderChoiceSections = () => {
    const { choices } = this.state
    var existingChoiceResults = []
    var neededChoiceResults = []

    // Loop through and show already made choices
    for (let providerKey of Object.keys(choices)) {
      const provider = choices[providerKey]
      const title = AppConstants.providerNames[providerKey]

      if (provider.selectedChoiceUID && provider.length > 0) {
        const selectedChoice = ValuationFunctions.valuationForChoice(choices[providerKey], provider.selectedChoiceUID)
        existingChoiceResults.push(
          <span key={`existing-choice-${providerKey}`}>
            <span style={{fontWeight: 'bold', align: 'center'}}>{title}</span>&nbsp;
            {selectedChoice.description.display_choice}&nbsp;&nbsp;
          </span>
        )
      } else if (provider.length > 0 && !provider.error) {
        neededChoiceResults.push(
          <div className="choices-provider-c" key={`listing-choices-container-${providerKey}`} >
            <div className="choices-provider-name">{title}</div>
            <div className="choices-provider-c-choices">
              { this.renderChoices(provider, providerKey) }
            </div>
          </div>
        )
      }
    }

    var existing = []
    if (existingChoiceResults.length > 0 && this.props.showMinimal !== true) {
      existing.push((
        <div className="existing-choices" key="existing-choices">
          { existingChoiceResults }
        </div>
      ))
    }

    const needed = [(
      <div key="needed-choices" className="choices">
        { neededChoiceResults }
      </div>
    )]

    return existing.concat(needed);
  }


  renderChoices(provider, providerKey) {
    var resultsNodes = []

    for (let choice of provider) {
      const desc = choice['description']
      const uid = desc.uid
      const displayString = desc.display_choice

      resultsNodes.push(
        <Button color="secondary" onClick={(e) => {this.onChoice(providerKey, uid)}} key={`${providerKey}-choice-${uid}`}>
          {displayString}
        </Button>
      )
    }
    return resultsNodes
  }


  render() {
    const valuationPreview = this.props.valuationPreview
    const { choices, hasMadeAChoice } = this.state
    const showMinimal = this.props.showMinimal === true

    if (!choices) { return null }

    let previewVehicleDetails = []

    if (valuationPreview) {
      if (valuationPreview.body_class && valuationPreview.body_class.length > 0) {
        previewVehicleDetails.push(valuationPreview.body_class)
      }

      if (valuationPreview.drive_type && valuationPreview.drive_type.length > 0) {
        previewVehicleDetails.push(valuationPreview.drive_type)
      }

      if (valuationPreview.fuel_type && valuationPreview.fuel_type.length > 0) {
        previewVehicleDetails.push(valuationPreview.fuel_type)
      }

      if (valuationPreview.engine_cylinders && valuationPreview.engine_cylinders.length > 0) {
        previewVehicleDetails.push(`${valuationPreview.engine_cylinders} cylinders`)
      }

      if (valuationPreview.engine_displacement && valuationPreview.engine_displacement.length > 0) {
        previewVehicleDetails.push(`${valuationPreview.engine_displacement}L`)
      }
    }

    return (
      <div className='valuation-choices-c'>
        {
          valuationPreview && previewVehicleDetails.length > 0 && !showMinimal &&
          <div style={{}}>
            <h1 style={{color: '#333'}}>{valuationPreview.year} {valuationPreview.make} {valuationPreview.model ? valuationPreview.model : ''}</h1>
            {
              valuationPreview && (valuationPreview.series || valuationPreview.style) &&
              <div style={{fontWeight: 'bold', align: 'center'}}>{Formatters.seriesStyleString(valuationPreview.series, valuationPreview.style)}</div>
            }
            {
              previewVehicleDetails.length > 0 &&
              <div style={{align: 'center', fontSize: 13}}>{previewVehicleDetails.join(', ')}</div>
            }
          </div>
        }


        {this.renderChoiceSections()}


        {
          hasMadeAChoice &&
          <a href="#reset" onClick={(e) => {e.preventDefault(); this.resetChoices()}}>
            <div style={{align: 'center'}}>Start over</div>
          </a>
        }
      </div>

    )
  }
}
