import { useCallback, useEffect, useState, useMemo } from 'react'
import AuctionStore from '../../stores/AuctionStore'
import useGetDotAuctionListings from './useGetListings'

const defaultState = {
  items: null,
  isLoading: false,
  isRefetching: false,
  isFetchingPage: false,
  page: 1,
  hasLastPage: false,
}

const pageSize = 20

export default (hasLinkedAuctionEdge, storeDataSourceFunc, dataStore, emittedListener, actionCreatorFunction) => {
  // Fetch contained listings from carbly
  // Fetch dotAuctionListings for all listings (from source_id) from bidding api
  // Return data (listings from carbly) after both sources are fetched

  // Example structure
  // [
  //   arbitrary_meta_fields...
  //   listings: [
  //     channel 'DOT_AUCTION',
  //     id: '631a16195778a300045522d8',
  //     source_id: '7292926c-d9be-4e63-99c7-3de223a22d1a',
  //     ...
  //   ]
  //   ...
  // ]

  const [
    fetchDotAuctionListings,
    {
      data: dotAuctionListings,
      refetch: refetchDotAuctionListings,
      fetchPage: fetchDotAuctionListingsPage,
      error: dotAuctionListingsError,
    },
  ] = useGetDotAuctionListings()

  const [{
    items,
    isLoading,
    isFetchingPage,
    isRefetching,
    page,
    hasLastPage,
  }, setState] = useState(defaultState)

  const isEmptyData = useMemo(() => {
    return Boolean(items && items.length === 0)
  }, [items])

  const data = useMemo(() => {
    if (isEmptyData) return []
    if (!(dotAuctionListings && items)) return null
    return items
  }, [items, dotAuctionListings, isEmptyData])

  const onItemsChange = useCallback(async () => {
    const storeData = storeDataSourceFunc()
    const { collection, lastPage } = storeData
    const nextItems = collection?.filter(v => v.listings.length)
    const ids = [...new Set(lastPage?.map(v => v.listings).flat().map(v => v.source_id))].sort()

    if (ids.length) {
      if (isRefetching) {
        await refetchDotAuctionListings(ids)
      } else if (isFetchingPage) {
        await fetchDotAuctionListingsPage(ids)
      } else if (isLoading) {
        await fetchDotAuctionListings(ids)
      }
    }

    setState(prev => ({
      ...prev,
      items: nextItems,
      data,
      isLoading: false,
      isRefetching: false,
      isFetchingPage: false,
      hasLastPage: storeData.hasLastPage(),
    }))
  }, [
    fetchDotAuctionListings,
    isLoading,
    isRefetching,
    fetchDotAuctionListingsPage,
    isFetchingPage,
    refetchDotAuctionListings,
    storeDataSourceFunc,
  ])

  const getItemsFromStore = useCallback(() => {
    const { collection } = storeDataSourceFunc()
    const nextItems = collection?.filter(v => v.listings.length)

    setState(prev => ({
      ...prev,
      items: nextItems,
    }))
  }, [storeDataSourceFunc])

  const fetchItems = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: true }))
    actionCreatorFunction(1, pageSize)
  }, [actionCreatorFunction])

  const refetchItems = useCallback(() => {
    setState(prev => ({ ...prev, isRefetching: true, page: 1 }))
    actionCreatorFunction(1, pageSize)
  }, [actionCreatorFunction])

  const fetchNextPage = useCallback(() => {
    const nextPage = page + 1
    setState(prev => ({ ...prev, isFetchingPage: true, page: nextPage }))
    actionCreatorFunction(nextPage, pageSize)
  }, [actionCreatorFunction, page])

  useEffect(() => {
    dataStore.on(emittedListener, onItemsChange)
    AuctionStore.on('global_auction_listing_update', getItemsFromStore)
    return () => {
      dataStore.removeListener(emittedListener, onItemsChange)
      AuctionStore.removeListener('global_auction_listing_update', getItemsFromStore)
    }
  }, [onItemsChange, dataStore, emittedListener, getItemsFromStore])

  useEffect(() => {
    if (hasLinkedAuctionEdge) {
      fetchItems()
    } else {
      setState(defaultState)
    }
  }, [fetchItems, hasLinkedAuctionEdge])

  return {
    data,
    isLoading,
    isRefetching,
    isEmptyData,
    fetchNextPage,
    isFetchingPage,
    hasLastPage,
    refetch: refetchItems,
  }
}
