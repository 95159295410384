import React from 'react'
import { Link } from 'react-router-dom'
import SessionStore from '../../api/stores/SessionStore'
import platformFunctions from '../../platformFunctions'

export default class NewsItem extends React.Component {
  render() {
    const { newsItem } = this.props
    const isNotification = this.props.notification === true

    if (!newsItem) {
      return null
    }
    
    return (
      <div className={`${isNotification ? 'body-with-nav ' : ''}news-notification`}>
        <Link to='/settings/news/' onClick={() => {
          if (isNotification) {
            platformFunctions.logAnalyticsEvent("User", "Opened News Notification")
          }
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 16" className="news-icon">
            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
            <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          </svg>
          <div className="news-body">
            <div className="news-label">NEWS</div>
            <div>{newsItem.headline}</div>
          </div>
        </Link>
        {
          isNotification &&
          <a href="#close" className="close-button" onClick={(e) => {
            e.preventDefault()
            SessionStore.newsViewed()
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </a>
        }
      </div>
    )
    
  }
    

}