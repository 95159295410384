import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import DraggableValuation from './DraggableValuation'
import ValuationRow from './ValuationRow'
import NewFolderModal from './NewFolderModal'
import DeleteIcon from '../common/icons/DeleteIcon'
import AccountBug from '../user/AccountBug'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import CalculatorBug from '../common/CalculatorBug'


export default class FolderShow extends React.Component {
  state = {
    isLoading: true,
    listErrors: false,
    loadingMore: false,
    cutoff: null,
    recentValuationsEndOfListReached: false,
    isShowingFolderRenameModal: false,
    folderInfo: null
  }

  componentDidMount() {
    if (!CommonFunctions.hasValidIDMatchProp(this.props)) {return}
    const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)
    this.clientID = this.composeClientID(folderID)
    this.folderID = folderID

    this.addListeners()

    this.loadFolderInfo()
    ValuationActionCreators.loadRecentValuations(null, folderID, this.clientID)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!CommonFunctions.hasValidIDMatchProp(this.props)) {return}

    // We have to jump through hoops since this component is reused without a remount
    const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)
    const newClientID = this.composeClientID(folderID)

    if (folderID && (this.clientID === undefined || this.clientID !== newClientID)) {
      this.removeListeners()
      this.clientID = newClientID
      this.folderID = folderID
      this.addListeners()

      this.setState({
        isLoading: true,
        loadingMore: false,
        recentValuationsEndOfListReached: false,
        isShowingFolderRenameModal: false,
        folderID: null,
        folderInfo: null,
        recentValuations: null
      })

      this.loadFolderInfo()
      ValuationActionCreators.loadRecentValuations(null, folderID, this.clientID)
    }

  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners = () => {
    ValuationStore.on('recent_valuations_change', this.recentValuationsDidChange)
    ValuationStore.on('valuation_folder_updated', this.reload)
    ValuationStore.on('folder_deleted', this.folderDeleted)
    ValuationStore.on('folder_updated', this.loadFolderInfo)
    ValuationStore.on(`valuation_folder_info_change_${this.clientID}`, this.folderInfoDidChange)
  }

  removeListeners = () => {
    ValuationStore.removeListener('recent_valuations_change', this.recentValuationsDidChange)
    ValuationStore.removeListener('valuation_folder_updated', this.reload)
    ValuationStore.removeListener('folder_deleted', this.folderDeleted)
    ValuationStore.removeListener('folder_updated', this.loadFolderInfo)
    ValuationStore.removeListener(`valuation_folder_info_change_${this.clientID}`, this.folderInfoDidChange)
  }

  loadFolderInfo = () => {
    const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)
    ValuationActionCreators.loadFolderInfo(folderID, this.clientID)
  }

  composeClientID = (id) => {
    return `folder-${id}`
  }

  folderInfoDidChange = () => {
    this.setState({
      folderInfo: ValuationStore.folderInfo.dataWithID(this.clientID)
    })
  }

  folderDeleted = () => {
    this.props.history.goBack()
  }

  deleteFolder = () => {
    const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)
    this.setState({ isSubmitting: true })
    ValuationActionCreators.deleteFolder(folderID)
  }

  reload = () => {
    if (this.folderID && this.clientID) {
      ValuationActionCreators.loadRecentValuations(null, this.folderID, this.clientID)
    }
  }

  loadMore = () => {
    if (this.state.recentValuationsEndOfListReached) {
      return
    }
    if (!this.state.loadingMore) {
      this.setState({loadingMore: true})
      const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)
      ValuationActionCreators.loadRecentValuations(this.state.cutoff, folderID, this.clientID)
    }
  }

  recentValuationsDidChange = () => {
    const data = ValuationStore.recentValuations.dataWithID(this.clientID)
    this.setState({
      isLoading: false,
      recentValuations: data.collection,
      loadingMore: false,
      recentValuationsEndOfListReached: data.hasLastPage(),
      cutoff: data.getCutoff(),
      listErrors: data?.errors ? data.errors : null
    })
  }

  renderBody = () => {
    if (this.state.isLoading) {
      return ( <Spinner className="spinner-page" /> )
    }

    const { accountStatusRestricted } = this.props
    const { recentValuationsEndOfListReached, recentValuations, folderInfo, isShowingFolderRenameModal } = this.state
    const folderID = CommonFunctions.folderIDFromParam(this.props.navMatch.params.id)

    return (
      <div className="body-content" style={{overflow: 'auto'}}>
        <div className="content-header">
          <h1>{folderInfo ? folderInfo.name : ''}</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          accountStatusRestricted !== true &&
          <div className="content-context-actions">
            <a href="#new-vehicle" onClick={(e) => {e.preventDefault(); ValuationStore.valuationNew(folderInfo)}}>
              <svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
              </svg>
              Add Vehicle to Folder
            </a> |

            <a href="#rename-folder" onClick={(e) => {e.preventDefault(); this.setState({isShowingFolderRenameModal: true})}}>
              <svg className="bi bi-pencil-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
              Rename Folder
            </a> |

            <a href="#delete-folder" onClick={(e) => { e.preventDefault(); if (window.confirm('Are you sure you want to delete this folder?  Vehicles will be removed from the folder, but can be found by viewing your vehicle history.')) this.deleteFolder() } }>
              <DeleteIcon />
              Delete Folder
            </a>
          </div>
        }
        


        <div className="card">
          <div className="card-list">
            {
              recentValuations && recentValuations.length > 0 &&
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMore}
                useWindow={false}
                hasMore={!recentValuationsEndOfListReached}
                loader={<Spinner key="pagination-spinner" />} >
                {
                  recentValuations.map((valuation) => {
                    return (
                      <DraggableValuation key={`fs-valrow-${valuation.uuid}`} valuation={valuation} existingFolderID={valuation.vehicle_folder ? valuation.vehicle_folder.id : null} clientID={this.clientID} onFolderChange={(valuation, existingFolderID, targetFolder) => ValuationStore.changeFolderValuationsFolder(valuation, existingFolderID, targetFolder, this.clientID)}>
                        <ValuationRow
                          accountStatusRestricted={accountStatusRestricted}
                          key={`valrow-${valuation.uuid}`}
                          valuation={valuation}
                          clientID={this.clientID}
                          onDeleteValuation={(valuation) => {
                            ValuationStore.deleteValuation(valuation.uuid, this.clientID)
                          }}
                        />
                      </DraggableValuation>
                    )
                  })
                }
              </InfiniteScroll>
            }
          </div>
        </div>

        {
          isShowingFolderRenameModal &&
          <NewFolderModal currentFolderID={folderID} onCancel={() => this.setState({ isShowingFolderRenameModal: false })}/>
        }
      </div>
    )
  }

  render() {
    return (
      this.renderBody()
    )
  }
}
