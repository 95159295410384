import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'

const AuctionEdgeAuth = ({ title }) => {
  const [isFetching, setIsFetching] = useState(false)

  const onClick = useCallback(() => {
    platformFunctions.logAnalyticsEvent("User", "Opened Auction Edge Link")
    setIsFetching(true)
    UserActionCreators.loadEdgeAuthorizeURL(window.location.href)
  }, [])

  const onAuthorizeUrlChanged = useCallback(() => {
    setIsFetching(false)
    const url = SessionStore.edgeAuthorizeURL?.edge_authorize_url
    if (url) {
      if (global.isExtensionMode || global.isIFrameMode) {
        window.open(url)
      } else {
        window.location.replace(url)
      }
    }
  }, [])

  useEffect(() => {
    SessionStore.on("edge_authorize_url_changed", onAuthorizeUrlChanged)
    return () => {
      SessionStore.removeListener("edge_authorize_url_changed", onAuthorizeUrlChanged)
    }
  }, [onAuthorizeUrlChanged])

  return (
    <div>
      <Button
        color="primary"
        onClick={ onClick }
        disabled={ isFetching }
        style={ {width: 'auto'} }
      >
        {title || `Link ${AppConstants.edgePipelineName} Account`}
      </Button>
    </div>
  )
}

export default AuctionEdgeAuth
