import React from 'react'
import { Router, Route, Redirect, Switch, NavLink } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { detect } from 'detect-browser'
import { ToastContainer, Slide, toast } from 'react-toastify'
import DashboardPage from './DashboardPage'
import VehiclesPage from './VehiclesPage'
import AuctionsPage from './AuctionsPage'
import LoginPage from './LoginPage'
import AccountCannotBeReinstated from './AccountCannotBeReinstated'
import IncompatibleBrowserPage from './IncompatibleBrowserPage'
import SettingsPage from './SettingsPage'
import ExtensionPage from './ExtensionPage'
import ExtensionIcon from '../components/common/icons/ExtensionIcon'
import MobileDownloadPage from './MobileDownloadPage'
import VehicleActivitiesPage from './VehicleActivitiesPage'
import PaymentFormModal from '../components/settings/PaymentFormModal'
import SessionExclusion from '../components/common/SessionExclusion'
import UserVerificationModal from '../components/common/UserVerificationModal'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import SessionStore from '../api/stores/SessionStore'
import VersionChecker from '../components/common/VersionChecker'
import AutoCheckReport from '../components/valuations/AutoCheckReport'
import AppConstants from '../api/constants/AppConstants'
import QueryParamsFunctions from '../api/constants/QueryParamsFunctions'
import NewsItem from '../components/user/NewsItem'
import withAuctionEdgeSubscription from '../api/bidding/hoc/withSubscription'
import platformFunctions from '../platformFunctions'
import HelpIcon from '../components/common/icons/HelpIcon'
import RegionalReportsPage from './regionalReports/Page'
import RegionalReportsIcon from '../components/common/icons/RegionalReportsIcon'
import PlanManagement from '../components/settings/PlanManagement'
import PlusIcon from '../components/common/icons/PlusIcon'

const browser = detect()
const dashEnabled = false

class Layout extends React.Component {
  state = {
    windowIsTooSmall: false,
    incompatibleBrowser: false,
    shouldShowNewsNotification: SessionStore.shouldShowNewsNotification()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize)
    SessionStore.on('news_notification_change', this.newsNotificationDidChange)

    this.updateWindowSize()
    if (browser && browser.name && browser.name === 'ie' && browser.version) {
      if (parseFloat(browser.version) < 11) {
        this.setState({
          incompatibleBrowser: true
        })
      }
    }
    this.notifyCarfaxConnectAuthStatus()
    setTimeout(() => {
      this.checkForNews()
    }, AppConstants.initialNewsCheckDelay)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize)
    SessionStore.removeListener('news_notification_change', this.newsNotificationDidChange)
  }

  checkForNews = () => {
    SessionActionCreators.checkForNews()
    setTimeout(() => {
      this.checkForNews()
    }, AppConstants.newsCheckPeriod)
  }

  newsNotificationDidChange = () => {
    const shouldShow = SessionStore.shouldShowNewsNotification()
    this.setState({ shouldShowNewsNotification: shouldShow})
  }

  notifyCarfaxConnectAuthStatus = () => {
    const paramsObj = QueryParamsFunctions.toObject()
    const authStatus = paramsObj.carfax_connect_authorize_status
    if (authStatus) {
      const messageBase = `${AppConstants.providerNames['carfax']} authorization`
      if (authStatus === '1') {
        toast.success(`${messageBase} success`)
      } else if (authStatus === '0') {
        toast.error(`${messageBase} failed`)
      }
      const queryString = QueryParamsFunctions.delete('carfax_connect_authorize_status')
      this.props.history.replace({ ...this.props.history, search: `?${queryString}` })
    }
  }

  updateWindowSize = () => {
    const isMobile = window.innerWidth < 800
    if (isMobile !== this.state.windowIsTooSmall) {
      this.setState({
        windowIsTooSmall: isMobile
      })
    }
  }

  renderActiveIcon = () => (<svg height="24" width="10" className="active-indicator"><polygon points="0,0 10,12 0,24" /></svg>)

  render() {
    const { history, shouldShowSettingsBadge, isLoadingUser, isAuthenticated, accountIsActive, canBeReinstated, needsCarblySubscription, accountStatusRestricted, accountStateMessage, accountUpdateStatus, isAccountOwner, forcePaymentUpdate } = this.props
    const { incompatibleBrowser, windowIsTooSmall, shouldShowNewsNotification } = this.state

    if (windowIsTooSmall && isAuthenticated === true) {
      return (
        <MobileDownloadPage accountStateMessage={accountStateMessage} accountUpdateStatus={accountUpdateStatus} forcePaymentUpdate={forcePaymentUpdate} isAccountOwner={isAccountOwner} />
      )
    }

    if (isLoadingUser) {
      return ( null )
    }

    if (isAuthenticated === false) {
      return (
        <LoginPage />
      )
    }

    const loc = window.location.href
    if (loc.indexOf('/autocheck_report/') > -1) {
      return (
        <AutoCheckReport />
      )
    }

    if (incompatibleBrowser === true) {
      return <IncompatibleBrowserPage />
    }

    if (accountIsActive === false && canBeReinstated === false) {
      return <AccountCannotBeReinstated message={accountStateMessage}/>
    }

    // Reactivation is handled at the page level to allow auctions to be accessible

    return (
      <Router history={history}>
        <DndProvider backend={HTML5Backend}>
          <div className="page-c">
            {
              shouldShowNewsNotification &&
              <NewsItem newsItem={SessionStore.newsNotification} notification />
            }

            <nav className="primary-nav">
              <img src="/images/carbly-nav-logo.png" alt="Carbly" className="nav-logo" />
              <div className="primary-nav-items">
                {
                  dashEnabled &&
                  <NavLink to="/" exact activeClassName="active">
                    <img src="/images/nav-logo.png" alt="Home" style={{width: '34px', height: '34px'}} />
                    <div className="label">Home</div>
                    { this.renderActiveIcon() }
                  </NavLink>
                }

                <NavLink to="/vehicles" activeClassName="active">
                  <img src="/images/vehicles-icon.png" alt="Vehicles" style={{width: '32px', height: '22px'}} />
                  <div className="label">Vehicles</div>
                  { this.renderActiveIcon() }
                </NavLink>

                {
                  SessionStore.hasMyLotAccess() &&
                  <NavLink to="/my-lot" activeClassName="active">
                    <div style={{position: 'absolute', backgroundColor: 'red', color: '#fff', top: '8px', right: '8px', borderRadius: '20px', padding: '2px 4px', fontSize: '0.8rem'}}>22</div>
                    <img src="/images/my-lot-icon.png" alt="Vehicles" style={{width: '44px', height: '28px'}} />
                    <div className="label">My Lot</div>
                    { this.renderActiveIcon() }
                  </NavLink>
                }

                <NavLink to="/auctions" activeClassName="active">
                  <div>
                    <div className="icon">
                      <img src="/images/auctions-icon.png" alt="Vehicles" style={{width: '32px', height: '26px'}} />
                    </div>
                    <div className="label">Auctions</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>

                <NavLink to="/market-tracker" activeClassName="active">
                  <div>
                    <div className="icon regional-reports-icon">
                      <RegionalReportsIcon />
                    </div>
                    <div className="label regional-reports-label">Market Tracker</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>


                {
                isAccountOwner &&
                <div style={{borderTop: '2px solid #1f1f1f'}}>
                <NavLink to="/plan" activeClassName="active">
                  <div>
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362s.96-1.932.362-2.531c-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532"/>
                        <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9 9 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a10 10 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093q.1.026.16.045c.184.06.279.13.351.295l.029.073a3.5 3.5 0 0 1 .157.721c.055.485.051 1.178-.159 2.065m-4.828 7.475.04-.04-.107 1.081a1.54 1.54 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a9 9 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006M5.205 5c-.625.626-.94 1.351-1.004 2.09a9 9 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a3 3 0 0 0-.045-.283 3 3 0 0 0-.3-.041Z"/>
                        <path d="M7.009 12.139a7.6 7.6 0 0 1-1.804-1.352A7.6 7.6 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
                      </svg>
                    </div>
                    <div className="label">Add More</div>
                    {
                      shouldShowSettingsBadge === true &&
                      <div className="badge"><div>!</div></div>
                    }
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>
                </div>
              }
              </div>

              {
                SessionStore.shouldShowExtensionPrompt() &&
                <NavLink to="/extension" activeClassName="active">
                  <div>
                    <div className="icon extension-icon" style={{color: '#fff'}}>
                      <ExtensionIcon />
                    </div>
                    <div className="label">Extension</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>
              }

              <NavLink to="/settings" activeClassName="active">
                <div>
                  <div className="icon">
                    <img src="/images/settings-icon.png" alt="Settings" style={{width: '28px', height: '28px'}} />
                  </div>
                  <div className="label">Settings</div>
                  {
                    shouldShowSettingsBadge === true &&
                    <div className="badge"><div>!</div></div>
                  }
                  { this.renderActiveIcon() }
                </div>
              </NavLink>

              <a href="#help" onClick={(e) => {
                e.preventDefault()
                window.Intercom('show') }}>
                <div className="icon"><HelpIcon /></div>
                <div className="label">Help</div>
              </a>
            </nav>

            <VersionChecker />

            <Switch>
            {
              dashEnabled &&
              <Route
                path='/'
                exact
                children={({ match }) => (
                  <DashboardPage navMatch={match} history={history} />
                )} />
            }

            <Route
              path='/vehicles'
              children={({ match }) => (
                <VehiclesPage navMatch={match} history={history} canBeReinstated={canBeReinstated} needsCarblySubscription={needsCarblySubscription} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/auctions'
              children={({ match }) => (
                <AuctionsPage navMatch={match} history={history} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/market-tracker'
              children={({ match }) => (
                <RegionalReportsPage navMatch={match} history={history} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/plan'
              children={({ match }) => (
                <PlanManagement navMatch={match} history={history} canBeReinstated={canBeReinstated} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/settings'
              children={({ match }) => (
                <SettingsPage navMatch={match} history={history} canBeReinstated={canBeReinstated} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/extension'
              children={({ match }) => (
                <ExtensionPage navMatch={match} history={history} />
              )} />

            <Route
              path='/activity'
              component={ VehicleActivitiesPage }
            />
            </Switch>

            {
              !dashEnabled &&
              <Switch>
                <Route exact strict path="/" render={() => <Redirect to="/vehicles" />} />
              </Switch>
            }

            {
              forcePaymentUpdate &&
              <PaymentFormModal message={accountStateMessage} isAccountOwner={isAccountOwner} />
            }

            {
              !forcePaymentUpdate &&
              <SessionExclusion />
            }

            <UserVerificationModal />


            <ToastContainer
              autoClose={2000}
              draggable={false}
              transition={Slide}
              toastClassName='toast-c'
              progressClassName='toast-progress'
              />
          </div>
        </DndProvider>
      </Router>
    )
  }
}

const subscriptionOptions = {
  onError: (err) => {
    const message = err?.errors?.[0]?.message
    platformFunctions.logAnalyticsEvent("AuctionEdgeSubscriptionError", message)
  }
}

export default withAuctionEdgeSubscription(Layout, subscriptionOptions)
