import React from 'react'

export default class IncompatibleBrowserPage extends React.Component {


  render() {
    return (
      <div className="login-c">
        <div style={{textAlign: 'center', color: '#fff', margin: '0 20px'}}>
          <a href="https://getcarbly.com"><img src="/images/login-logo.png" className="login-logo" alt="Carbly" /></a>
          <h1>Incompatible Web Browser</h1>

          <div>Unfortunately, the browser you're using is not incompatible with Carbly.  We suggest downloading <a href="https://www.google.com/chrome/" taget="_blank" rel="noopener noreferrer">Google Chrome</a>. Or using the mobile app for iOS or Android.</div>
          <div style={{paddingTop: '20px'}}>
            <a href="https://itunes.apple.com/us/app/carbly/id1326227629?ls=1&mt=8"><img src="/images/download-app-store.png" alt="App Store" style={{width: '150px', height: '50px', boxShadow: '0px 10px 20px rgba(0,0,0,0.3)'}} /></a>
          </div>

          <div style={{paddingTop: '20px'}}>
            <a href="https://play.google.com/store/apps/details?id=com.ambientautomotive.carbly"><img src="/images/download-google-play.png" alt="Google Play Store" style={{width: '167px', height: '50px', boxShadow: '0px 10px 20px rgba(0,0,0,0.3)'}} /></a>
          </div>

          <div style={{paddingTop: '40px'}}>Return to <a href="https://getcarbly.com">GetCarbly.com</a></div>

        </div>
      </div>
    )
  }


}
