import React from 'react'
import {  Modal, ModalBody } from 'reactstrap'
import AuctionsPlusUpsell from './AuctionsPlusUpsell'
import moment from 'moment'

export default class AuctionsPlusUpsellModal extends React.Component {
  render() {
    return(
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '800px'}}>
        <ModalBody>
          <AuctionsPlusUpsell
            onCancel={this.props.onClose}
            onDone={this.props.onDone}
            metricsValue={this.props.metricsValue} />
        </ModalBody>
      </Modal>
    )
  }
}