import React from 'react'
import SessionStore from '../api/stores/SessionStore'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import PaymentForm from '../components/settings/PaymentForm'
import AppConstants from '../api/constants/AppConstants'

export default class MobileDownloadPage extends React.Component {
  state = {
    showPaymentMethod: false
  }

  renderFooter = () => {
    return (
      <div>
        <div><a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout()}}>Logout</a></div>
        <div style={{paddingTop: '20px'}}>Return to <a href="https://getcarbly.com">GetCarbly.com</a></div>
      </div>
    )
  }

  render() {
    const { forcePaymentUpdate, accountUpdateStatus } = this.props
    const { showPaymentMethod } = this.state
    const user = SessionStore.user
    const isAccountOwner = user?.account?.owner === true

    return (
      <div className="login-c">
        <div style={{textAlign: 'center', color: '#fff', margin: '0 20px'}}>
          <a href="https://getcarbly.com"><img src="/images/login-logo.png" className="login-logo" alt="Carbly" /></a>
          {
            forcePaymentUpdate !== true && showPaymentMethod !== true && accountUpdateStatus === null &&
            <div>
              <div>Carbly Desktop is only available for devices with a larger screen.</div>

              <div>For mobile devices, please download the app from the App Store or the Google Play Store.</div>
              <div style={{paddingTop: '20px'}}>
                <a href="https://itunes.apple.com/us/app/carbly/id1326227629?ls=1&mt=8"><img src="/images/download-app-store.png" alt="App Store" style={{width: '150px', height: '50px', boxShadow: '0px 10px 20px rgba(0,0,0,0.3)'}} /></a>
              </div>

              <div style={{paddingTop: '20px', marginBottom: '20px'}}>
                <a href="https://play.google.com/store/apps/details?id=com.ambientautomotive.carbly"><img src="/images/download-google-play.png" alt="Google Play Store" style={{width: '167px', height: '50px', boxShadow: '0px 10px 20px rgba(0,0,0,0.3)'}} /></a>
              </div>

              {
                isAccountOwner &&
                <div style={{marginBottom: '10px'}}>
                  <a href="#account" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ showPaymentMethod: true })
                  }}>Manage account payment</a>
                </div>
              }

              { this.renderFooter() }
            </div>
          }
        </div>


        {
          ((forcePaymentUpdate === true || showPaymentMethod === true) && accountUpdateStatus !== 200) &&
          <div>

            <div className="mobile-payment-form-c">
              {
                this.props.isAccountOwner === true &&
                <PaymentForm message={this.props.accountStateMessage} />
              }
              {
                this.props.isAccountOwner !== true &&
                <div>{this.props.accountStateMessage}</div>
              }
            </div>
            {
              showPaymentMethod === true &&
              <div style={{textAlign: 'center'}}>
                <a href="#cancel" onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showPaymentMethod: false })
                }}>Cancel</a>
              </div>
            }
            <div className="mobile-payment-form-contact">
              {AppConstants.phoneNumber}
            </div>
          </div>
        }

        {
          accountUpdateStatus === 200 &&
          <div style={{textAlign: 'center', fontWeight: 'bold', color: '#fff'}}>
            <div style={{marginBottom: '2rem'}}>Card successfully updated. You can now return to the Carbly mobile app.</div>
            { this.renderFooter() }
          </div>
        }
      </div>
    )
  }


}
