import React from 'react'
import { Button } from 'reactstrap'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import Valuation from '../valuations/Valuation'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'
import CommonFunctions from '../../api/constants/CommonFunctions'
import ChoicesFunctions from '../../api/constants/ChoicesFunctions'
import validZipCodes from '../../api/constants/ZipCodes'

const TRK_KEY = 'settings_partner_trk'
const TRK_MAX = 5
const TRK_X_MAX = 15
const ZIP_KEY = 'settings_preview_zip'
const TRK_EMAIL_KEY = 'settings_partner_email'
const IS_STATIC_LANDER = true

const ActiveStates = {
	active: 0,
	maxExceeded: 10,
	extended: 20,
	xMaxExceeded: 30
}


export default class EdgePipelineLander extends React.Component {
  state = {
    hasAnalyzedUser: false,
    isLoading: true,
    valuation: null,
    valuationError: null,
    starVINDetected: false,
    starVIN: null,
    mobileIsShowingMore: false,
    needsZip: false,
    manualZipCode: '',
    manualZipCodeInvalid: false,
    derivedChoices: null,
    activeState: null,
    manualEmail: '',
    edgeAuthorizeURL: SessionStore.edgeAuthorizeURL,
    isAuthenticatingWithEdge: false
  }

  componentDidMount() {
    SessionStore.on('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)

    window._dcq = window._dcq || []
    window._dcs = window._dcs || {}
    window._dcs.account = '3319107'

    var dc = document.createElement('script')
    dc.type = 'text/javascript'
    dc.async = true
    dc.src = '//tag.getdrip.com/3319107.js'

    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(dc, s)

    ValuationStore.on('public_valuation_received', this.getValuation)
    this.hasRunValuation = false

    if (!IS_STATIC_LANDER) {
      platformFunctions.getLocalStorageValue(TRK_KEY).then((prtTrk) => {
        platformFunctions.getLocalStorageValue(TRK_EMAIL_KEY).then((prtEmail) => {
          this.prtTrk = prtTrk
          this.prtEmail = prtEmail
          
          if (this.prtTrk === null || this.prtTrk === 0) {
            this.prtTrk = 0
          }
  
          this.setState({ hasAnalyzedUser: true })
  
          var activeState = null
          if (this.prtTrk < TRK_MAX) {
            activeState = ActiveStates.active
          } else {
            if (this.prtTrk < TRK_X_MAX && this.prtEmail) {
              activeState = ActiveStates.extended
            } else if (this.prtTrk < TRK_X_MAX) {
              activeState = ActiveStates.maxExceeded
              platformFunctions.logAnalyticsEvent("Partner", "Preview Max Exceeded")
            } else {
              activeState = ActiveStates.xMaxExceeded
              platformFunctions.logAnalyticsEvent("Partner", "Preview Extended Max Exceeded")
              if (this.prtEmail) {
                window._dcq.push(["identify", {
                  email: this.prtEmail,
                  tags: ["edge-over-30"]
                }]);
              }
            }
          }
  
          if (activeState === ActiveStates.active || activeState === ActiveStates.extended) {
            this.setState({ activeState: activeState })
            this.handleActive()
          } else {
            this.setState({
              activeState: activeState,
              isLoading: false
            })
          }
          
        })
      })
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)
  }

  edgeAuthorizeURLChanged = () => {
    this.setState({
      edgeAuthorizeURL: SessionStore.edgeAuthorizeURL
    })
  }

  handleActive = () => {
    console.log("___HHHH");
    const qs = this.getQueryParams()
    const vin = qs.vin
    const mileage = qs.mileage

    if (!vin || !mileage) {
      console.log("ERROR: VIN and mileage are required to view pricing data");
      this.setState({
        isLoading: false,
        valuationError: 'VIN and mileage are required to view pricing data'
      })
      platformFunctions.logAnalyticsEvent("Partner", "Preview Invalid Params")
    } else if (this.isStarVIN(vin)) {
      this.handleStarVIN(vin)
    } else {
      var zip = qs.zip
      if (zip && zip.length > 5) {
        const zipSplits = zip.split('-')
        if (zipSplits.length === 2 && zipSplits[0].length === 5) {
          zip = zipSplits[0]
        }
      }

      const isValidZip = Boolean(zip && validZipCodes.zipCodes.includes(parseInt(zip)))

      if (zip && isValidZip) {
        this.zip = zip
        this.fetchValuation(vin, qs.mileage, this.zip)
      } else {
        // No zip, see if we stored it locally previously
        platformFunctions.getLocalStorageValue(ZIP_KEY).then((localZip) => {
          if (localZip) {
            this.zip = localZip
            this.fetchValuation(qs.vin, qs.mileage, this.zip)
          } else {
            this.setState({
              needsZip: true,
              isLoading: false
            })

            platformFunctions.logAnalyticsEvent("Partner", "Preview Needs ZIP")
          }
        })
      }

    }
  }

  isStarVIN = (vin) => {
    return vin.slice(vin.length - 5) === '*****'
  }

  handleStarVIN = (vin) => {
    this.setState({
      isLoading: false,
      starVINDetected: true,
      starVIN: vin
    })
    platformFunctions.logAnalyticsEvent("Partner", "Invalid preview ***** VIN")
  }

  componentWillUnmount() {
    ValuationStore.removeListener('public_valuation_received', this.getValuation)
  }

  fetchValuation = (vin, mileage, zip, choices) => {
    ValuationActionCreators.publicVINValuation(vin, mileage, zip, choices)
    platformFunctions.logAnalyticsEvent("Partner", "Preview")
  }

  getValuation = () => {
    var errors = ValuationStore.publicValuationErrors
    const valuation = ValuationStore.publicValuation
    if (!errors && valuation && !valuation['full_valuations']) {
      errors = "Pricing data is not available for this vehicle"
    } else if (!this.hasRunValuation) {
      // Don't towards quota if already run in session
      this.prtTrk++
      this.hasRunValuation = true
      platformFunctions.setLocalStorageValue(TRK_KEY, this.prtTrk)
    }

    const derivedChoices = ChoicesFunctions.initializeChoices(valuation)

    this.setState({
      isLoading: false,
      valuation: valuation,
      derivedChoices: derivedChoices,
      valuationError: errors,
      starVINDetected: false,
      starVIN: null
    })
  }

  onChoice = (providerKey, selectedChoiceUID) => {
    const newChoices = ChoicesFunctions.handleChoiceSelection(this.state.derivedChoices, providerKey, selectedChoiceUID)
    const formattedChoicesForServer = ChoicesFunctions.formattedChoicesForServer(newChoices)

    this.setState({
      isLoading: true,
      Valuation: null
    })

    const qs = this.getQueryParams()
    this.fetchValuation(qs.vin, qs.mileage, this.zip, formattedChoicesForServer)
  }

  getQueryParams = () => {
    const queryString = require('query-string')
    return queryString.parse(window.location.search)
  }

  zipCodeChanged = (text) => {
    const sanitizedZipCode = CommonFunctions.stripNonNumericNoDecimal(text)
    var manualZipCodeInvalid = false
    if (sanitizedZipCode.length === 5 && !validZipCodes.zipCodes.includes(parseInt(sanitizedZipCode))) {
      manualZipCodeInvalid = true
    }

    this.setState({
      manualZipCode: sanitizedZipCode,
      manualZipCodeInvalid: manualZipCodeInvalid
    })
  }

  saveZip = () => {
    const qs = this.getQueryParams()
    const { manualZipCode } = this.state
    this.setState({
      isLoading: true,
      needsZip: false
    })
    this.zip = manualZipCode
    this.fetchValuation(qs.vin, qs.mileage, this.zip)
    platformFunctions.setLocalStorageValue(ZIP_KEY, manualZipCode)
  }

  manualEmailChanged = (value) => {
    const sanitizedEmail = value
    this.setState({
      manualEmail: sanitizedEmail
    })
  }

  submitEmail = () => {
    const { manualEmail } = this.state
    platformFunctions.setLocalStorageValue(TRK_EMAIL_KEY, manualEmail)
    this.prtEmail = manualEmail
    this.setState({
      activeState: ActiveStates.extended,
      isLoading: true
    })

    this.handleActive()

    platformFunctions.logAnalyticsEvent("Partner", "Preview Max Email Entered")

    window._dcq.push(["identify", {
      email: manualEmail,
      tags: ["edge-over-15"]
    }]);
  }


  render() {
    const { hasAnalyzedUser, isLoading, valuation, valuationError, starVINDetected, starVIN, mobileIsShowingMore, activeState, needsZip, manualZipCode, manualEmail, manualZipCodeInvalid, edgeAuthorizeURL, isAuthenticatingWithEdge } = this.state
    const manualZipSubmitEnabled = needsZip && manualZipCode && manualZipCode.length === 5 && !manualZipCodeInvalid
    const todayDate = new Date()

    const isManualEmailValid = CommonFunctions.isValidEmailAddress(manualEmail)

    if (!hasAnalyzedUser && !IS_STATIC_LANDER) {
      return null
    }

    return (
      <div className="partner-lander-c">
        <div className="partner-header-bg" />
        <div className="partner-lander">
          {
            IS_STATIC_LANDER &&
            <div className="partner-lander-static-screen">
              <a href="/images/web-sample-screen-full.jpg" target="_blank" rel="noreferrer"><img src="/images/web-sample-screen-full.jpg" alt="Sample Carbly screen" /></a>
            </div>
          }
          {
            !IS_STATIC_LANDER &&
              <div className="partner-lander-valuation">
              {
                isLoading &&
                <div style={{height: '100%', textAlign: 'center', color: '#888', fontSize: '0.9rem'}}>
                  <div><Spinner className="partner-spinner" /></div>
                  <div>Loading vehicle pricing data</div>
                </div>
              }

              {
                activeState === ActiveStates.maxExceeded &&
                <div className="partner-lander-exceeded">
                  <h2>Need some more time with Carbly?</h2>

                  <p>You've reached your limit of previews with Carbly.  If you'd like another {TRK_MAX} vehicles in your preview, just enter your email below.</p>

                  <form onSubmit={(e) => {e.preventDefault(); this.submitEmail()}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <input type="text" value={manualEmail} onChange={e => this.manualEmailChanged(e.target.value)} placeholder='Email address' />
                      {
                        isManualEmailValid &&
                        <Button type="submit" color="primary" style={{height: '45px', marginLeft: '10px'}}>Submit</Button>
                      }
                    </div>
                  </form>


                  <p style={{marginTop: '40px', paddingTop: '40px', borderTop: '2px solid #ddd'}}>Or if you'd like to start your unlimited 14-day free trial with access to full data and both the desktop and mobile apps:</p>

                  <div className="free-trial">
                    <a href="https://getcarbly.com/register/?r=edge" target="_blank">Continue With Unlimited Free Trial</a>
                  </div>
                </div>
              }

              {
                activeState === ActiveStates.xMaxExceeded &&
                <div className="partner-lander-exceeded">
                  <h2>Your free preview has ended</h2>
                  <p>You've reached your limit of previews with Carbly.  Don't worry though, it's free to sign up and try out the full version.  You'll have access to unlimited data, plus the full desktop and mobile apps to manage, share and appraise your vehicles.</p>

                  <p>You also get all {AppConstants.edgePipelineName} and Manheim auction runlists, with appraisal data shown directly on the listing.</p>

                  <div className="free-trial">
                    <a href="https://getcarbly.com/register/?r=edge" target="_blank">Continue With Unlimited Free Trial</a>
                  </div>
                </div>
              }

              {
                valuationError && !isLoading &&
                <div style={{textAlign: 'center', margin: '40px 0'}}>{valuationError}</div>
              }

              {
                starVINDetected && !isLoading &&
                <div className="star-vin-message">
                  <h2>Invalid VIN from Pipeline</h2>
                  <div style={{margin: '20px 0'}}>The VIN number sent from {AppConstants.edgePipelineName} is not valid, and data for this vehicle cannot be shown.</div>
                  <div style={{margin: '20px 0'}}>Try clicking the Carbly button again from a Pipeline auction vehicle with a complete VIN number to see vehicle pricing data.</div>
                  {
                    starVIN &&
                    <div style={{fontSize: '1.3rem'}}><span style={{fontWeight: 700}}>VIN:</span> {starVIN}</div>
                  }
                </div>
              }

              {
                valuation && !isLoading && !valuationError &&
                <Valuation
                  isPartnerPreview
                  valuationData={valuation}
                  clientID={'pub'}
                  hideAuctionMatch={true}
                  hideShare
                  hideNotes
                  hidePhotos
                  onChoice={this.onChoice}
                  isEmbedded={false} />
              }

              {
                needsZip &&
                <div style={{padding: '20px'}}>
                  <h2>ZIP Code Needed</h2>
                  <p>In order to show local pricing data for your region, please enter your ZIP code</p>
                  <div className="manual-zip-entry-row">
                    <input ref='zipRef' type="text" className="field" name="zip" onChange={e => this.zipCodeChanged(e.target.value)} value={manualZipCode} placeholder='5 digit ZIP code' maxLength="5" autoComplete="off" style={{}} />

                    {
                      manualZipSubmitEnabled &&
                      <Button
                      color="primary"
                      onClick={this.saveZip}
                      style={ {width: 'auto'} }
                      >
                      Submit
                    </Button>
                    }
                  </div>
                  {
                    manualZipCodeInvalid && !IS_STATIC_LANDER &&
                    <div style={{color: 'red'}}>Sorry, this is not a valid 5-digit zip code</div>
                  }
                </div>
              }
            </div>
          }

          <div className="partner-lander-overview">
            <div className="intro">
              <div className="header-logos">
                <img src="/images/login-logo.png" className="carbly-logo" alt="Carbly" />

                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="rgba(255,255,255, 0.3)" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>

                <img src="/images/edge-pipeline.png" className="edge-logo" alt={AppConstants.edgePipelineName} />
              </div>

              <p>Our partnership allows you to view Carbly vehicle appraisal data directly from {AppConstants.edgePipelineName} listings. Check market values and set ceiling prices quickly and easily.</p>
            </div>

            <div className="partner-body">
              {
                !mobileIsShowingMore &&
                <a href="#show-more" id="mobile-show-more" onClick={(e) => {e.preventDefault(); this.setState({ mobileIsShowingMore: true})}}>Learn more</a>
              }

              <div id="mobile-more" className={mobileIsShowingMore ? 'mobile-showing-more' : ''}>
                <p><a href="https://getcarbly.com/?r=edge" target="_blank">Carbly</a> is a modern vehicle appraisal and VIN Scanner that combines multiple data sources to give you the wholesale, retail, local market, and vehicle history information you need to make more money buying and selling cars.</p>


                <div className="provider-logos">
                  <img src="/images/provider-black_book.png" alt="Black Book" />
                  <img src="/images/provider-manheim.png" alt="Manheim Market Report" />
                  <img src="/images/provider-real_retail.png" alt="Real Retail" className="real-retail" />
                  <img src="/images/provider-nada.png" alt="NADA" />
                  <img src="/images/provider-jd-power.png" alt="J.D. Power" className="jd-power" />
                  <img src="/images/provider-carfax.png" alt="CARFAX" className="carfax" />
                  <img src="/images/provider-kbb.png" alt="Kelley Blue Book" />
                  <img src="/images/provider-live_local_market.png" alt="Live Local Market" className="live-local-market" />
                  <img src="/images/auctions-plus-logo.png" alt="Auctions Plus" className="live-local-market" />
                  <img src="/images/regional-reports-plus-logo.png" style={{width: '162px', height: '30px'}} />
                </div>



                <ul>
                  <li>Scan VINs to see <a href="https://getcarbly.com/appraisals/?r=edge" target="_blank">appraisal data</a> instantly</li>
                  <li>Automatic trim and options selection</li>
                  <li>Best-in-class <a href="https://getcarbly.com/vin-scanner/?r=edge" target="_blank">VIN scanner</a> reads barcodes, text, QR codes and handwriting</li>
                  <li>Free integration of existing {AppConstants.providerNames['carfax']} and {AppConstants.providerNames['autocheck']} accounts</li>
                  <li>Add <a href="https://getcarbly.com/teams/?r=edge" target="_blank">team members to collaborate</a> with vehicle watchlists, notes, folders and photos</li>
                  <li>Available for iPhone, Android, desktops and laptops</li>
                  <li>Free no-risk 14 day trial</li>
                </ul>
              </div>

              <div style={{textAlign: 'center'}}>Have questions?  Give us a call at {AppConstants.phoneNumber}</div>

              <div className="free-trial">
                {
                  edgeAuthorizeURL?.edge_authorize_url && !isAuthenticatingWithEdge &&
                  <a href={edgeAuthorizeURL.edge_authorize_url} onClick={(e) => {
                    this.setState({ isAuthenticatingWithEdge: true })
                  }} style={{display: 'block', marginTop: "20px"}}>Log into Carbly with your EDGE Pipeline account for free</a>
                }
                {
                  edgeAuthorizeURL?.edge_authorize_url && isAuthenticatingWithEdge &&
                  <Spinner className='spinner-medium' style={{margin: '10px auto 30px auto'}} />
                }
                {
                  !edgeAuthorizeURL?.edge_authorize_url &&
                  <a href="https://getcarbly.com/register/?r=edge" target="_blank">Click here to create an account</a>
                }
                
              </div>

              <div className="login">
                Already have a Carbly account? <a href="#login" onClick={(e) => { e.preventDefault(); this.props.onClose();}}>Login</a>
              </div>



              {/* <div className="screenshots">
                <a href="https://getcarbly.com/?r=edge" target="_blank"><img src="/images/carbly-valuation-screenshots.png" alt="Carbly mobile" /></a>
              </div> */}

              <div className="video-thumbnail">
                <a href="https://www.youtube.com/watch?v=H2p6ivpsJtE" target="_blank" onClick={(e) => {
                  e.preventDefault();
                  platformFunctions.logAnalyticsEvent("Partner", "Preview Opened Video")
                  window.open('https://www.youtube.com/watch?v=H2p6ivpsJtE', '_blank');
                }}>
                  <img src="/images/lander-video-thumbnail.png" alt="Play video" />
                  <div>See Carbly in Action</div>
                </a>
              </div>
              

            </div>
          </div>

        </div>

        <div className="partner-footer-c">
          <div className="partner-footer">
            <div className="partner-footer-body">
              <img src="/images/carbly-logo-yellow.png" alt="Carbly" />
              <div>
                <h3>Contact us</h3>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  {AppConstants.phoneNumber}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                  </svg>
                  {AppConstants.supportEmailAddress}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                  </svg>
                  <a href="https://getcarbly.com/?r=edge" target="_blank">getcarbly.com</a>
                </div>

              </div>
            </div>
            <div className="partner-legal">
              <div>Carbly&reg; is a registered trademark of Ambient Automotive, Inc</div>
              <div>Copyright &copy; {todayDate.getFullYear()} Ambient Automotive, Inc</div>
            </div>

          </div>
        </div>

        {
          platformFunctions.isProduction() &&
          <iframe src="https://getcarbly.com/landers/edge-iframe/?utm_source=edge&utm_medium=referral&utm_campaign=Edge+Embed&r=edge" width={1} height={1} title="shell" />
        }
      </div>
    )
  }



}
