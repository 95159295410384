import React from 'react'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'
import pluralize from 'pluralize'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationProviderCard from './ValuationProviderCard'
import DataTable from './DataTable'
import Spinner from '../common/Spinner'
import ExpandIcon from '../common/icons/ExpandIcon'
import ContractIcon from '../common/icons/ContractIcon'
import AuctionListing from '../auctions/AuctionListing'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import SessionStore from '../../api/stores/SessionStore'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import platformFunctions from '../../platformFunctions'

const title = AppConstants.providerNames.pmr
const sku = AppConstants.skuMappings['pmr']
const graphSamplesThreshold = 5

const sampleColumnsDetailed = [
    { title: 'Photo', accessor: 'photo', width: '110px', sortable: false, showHeader: false}
  ]

const sampleColumns = [
    { title: 'Sale Price', accessor: 'price', width: '110px'},
    { title: 'Mileage', accessor: 'mileage', width: '112px'},
    { title: 'Date', accessor: 'purchase_date', width: '88px'},
    { title: 'Grade', accessor: 'grade', width: '80px'},
    { title: 'Color', accessor: 'color', width: '130px', reduced_size: true, hide_overflow: true},
    { title: 'Auction', accessor: 'location', reduced_size: true},
    { title: 'UID', accessor: 'uid', width: '0', display: false}
  ]

export default class ValuationPipeline extends React.Component {

  state = {
    isLoadingPRMSampleDetail: false,
    isShowingPRMSampleDetail: false,
    pmrSampleDetail: null,
    isLoadingAllSamples: false,
    isShowingAllSamples: false,
    allSamples: null,
    isShowingSamplesDetailed: SessionStore.showPipelineDetailedSamples,
    areSamplesHidden: SessionStore.hiddenPipelineSamples
  }

  componentDidMount() {
    ValuationStore.on(`pmr_sample_received_${this.props.clientID}`, this.pmrSampleReceived)
    ValuationStore.on(`pmr_all_samples_received_${this.props.clientID}`, this.pmrAllSamplesReceived)
    SessionStore.on('user_data_changed', this.userUpdated)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`pmr_sample_received_${this.props.clientID}`, this.pmrSampleReceived)
    ValuationStore.removeListener(`pmr_all_samples_received_${this.props.clientID}`, this.pmrAllSamplesReceived)
    SessionStore.removeListener('user_data_changed', this.userUpdated)
  }

  userUpdated = () => {
    // After a purchase of PMR, we need to update
    this.setState({
      user: SessionStore.user
    })
  }

  loadPMRSampleDetail = (uid) => {
    ValuationStore.hideHUD()
    this.setState({
      isLoadingPRMSampleDetail: true,
      isShowingPRMSampleDetail: false,
      pmrSampleDetail: null
    })
    if (uid && uid.raw_value) {
      platformFunctions.logAnalyticsEvent("Valuations", 'PMR Open Sample')
      ValuationActionCreators.loadPMRSampleDetail(uid.raw_value, this.props.clientID)
    }
  }

  closePMRSampleDetail = () => {
    ValuationStore.showHUD()
    this.setState({ isShowingPRMSampleDetail: false })
  }

  pmrSampleReceived = () => {
    const sampleDetail = ValuationStore.pmrSampleDetailWithClientID(this.props.clientID)
    this.setState({
      isLoadingPRMSampleDetail: false,
      isShowingPRMSampleDetail: true,
      pmrSampleDetail: sampleDetail
    })
  }

  loadAllSamples = (uid) => {
    this.setState({
      isLoadingAllSamples: true
    })
    platformFunctions.logAnalyticsEvent("Valuations", 'PMR Load All Samples')
    ValuationActionCreators.loadAllPMRSamples(uid, this.props.clientID)
  }

  pmrAllSamplesReceived = () => {
    const allSamples = ValuationStore.pmrAllSamplesWithClientID(this.props.clientID)
    this.setState({
      isLoadingAllSamples: false,
      isShowingAllSamples: true,
      allSamples: allSamples?.samples ? allSamples.samples : null
    })
  }

  renderSamples = (samples) => {
    if (this.state.isLoadingAllSamples) {
      return <Spinner className="spinner-page" />
    }
    
    const { isShowingSamplesDetailed, areSamplesHidden } = this.state

    var samplesData = samples?.data
    if (!samplesData || samplesData.length === 0) {
      return (<div style={{textAlign: 'center', padding: '10px 0'}}>No comps available</div>)
    }

    var isPreviewLimited = false
    if (global.isPartnerPreview && samplesData.length > 10) {
      samplesData = samplesData.slice(0, 10)
      isPreviewLimited = true
    }

    var formattedSamples = samplesData.map((sample) => {
      var ret = {
        price: {
          display_value: Formatters.formatCurrencyRoundedCents(sample.price),
          raw_value: sample.price
        },
        location: {
          display_value: sample.location.replace("America's Auto Auction", "AAA").replace("Dealers Auto Auction", "DAA"),
          raw_value: sample.location
        },
        mileage: {
          display_value: sample.mileage !== null && sample.mileage > 0 ? Formatters.formatThousands(sample.mileage) : '',
          raw_value: sample.mileage
        },
      }

      if (isShowingSamplesDetailed) {
        ret['color'] = {
          raw_value: sample.color,
          values: [
            {
              display_value: sample.color,
              label: 'Exterior'
            },
            {
              display_value: sample.interior_color,
              label: 'Interior'
            }
          ]
        }
      } else {
        ret['color'] = {
          raw_value: sample.color,
          values: [
            {
              display_value: sample.color
            }
          ]
        }
      }
      
      ret.grade = {
        display_value: sample.grade && sample.grade > 0 ? sample.grade : '',
        raw_value: sample.grade
      }
      
      ret.purchase_date = {
        display_value: moment(sample.purchase_date).format('M/D/YY'),
        raw_value: moment(sample.purchase_date).format('YYYYMMDD'),
      }

      ret.uid = {
        display_value: sample.uid,
        raw_value: sample.uid,
        display: false
      }
      

      if (isShowingSamplesDetailed) {
        ret['photo'] = {
          display_value: sample.thumbnail
        }
      }

      return ret
    })


    return (
      <div className="valuation-subsection">
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <h3 className="valuation-subsection-title" ref='samplesTop' style={{marginRight: '20px'}}>Recent Pipeline Auction sales</h3>
          {
            !areSamplesHidden && !ValuationStore.isSharedValuation &&
            <a href="#toggle-details" onClick={(e) => {
              e.preventDefault()
              platformFunctions.logAnalyticsEvent("Valuations", `PMR Show ${isShowingSamplesDetailed ? 'Simple' : 'Detailed'}`)
              SessionStore.setShowPipelineDetailedSamples(!isShowingSamplesDetailed)
              this.setState({ isShowingSamplesDetailed: !isShowingSamplesDetailed })
            }}>Show {isShowingSamplesDetailed ? 'simple view' : 'detailed view'}</a>
          }

          <a href="#toggle" style={{flexGrow: 1, flexShrink: 1, textAlign: 'right'}} onClick={(e) => {
            e.preventDefault()
            platformFunctions.logAnalyticsEvent("Valuations", `PMR ${areSamplesHidden ? 'Show' : 'Hide'}`)
            SessionStore.setHiddenPipelineSamples(!areSamplesHidden)
            this.setState({ areSamplesHidden: !areSamplesHidden })
          }}>
            {
              areSamplesHidden ?
              <ExpandIcon width={28} height={28} color='#aaa' /> :
              <ContractIcon width={28} height={28} color='#aaa' />
            }
          </a>
        </div>
        
        {
          !areSamplesHidden &&
          <>
            <DataTable
              columns={isShowingSamplesDetailed ? sampleColumnsDetailed.concat(sampleColumns) : sampleColumns}
              data={formattedSamples}
              providerKey='pmr'
              defaultSort='mileage'
              onClick={this.loadPMRSampleDetail}
              isClickable={!ValuationStore.isSharedValuation}
              valuationMileage={this.props.valuationMileage}
              onClickDataKey={'uid'}
              icon={<MagnifyingGlassIcon color='#888' />} />
            {
              global.isPartnerPreview && isPreviewLimited &&
              <div className="valuation-subsection partner-preview-access-message" style={{marginTop: '5px'}}>
                This list is limited to 10 sale transactions.  Create a Carbly account to view all available comps.
              </div>
            }
          </>
        }
      </div>
    )
  }


  render() {
    const pmrData = this.props.providerData
    if (!pmrData) { return null }
    const description = pmrData.description
    const appraisal = pmrData.appraisal
    const error = pmrData.error
    const systemData = this.props.systemData
    const overallCondition = this.props.overallCondition
    const { isLoadingPRMSampleDetail, isShowingPRMSampleDetail, pmrSampleDetail, allSamples, isLoadingAllSamples, isShowingAllSamples, areSamplesHidden } = this.state
    const samples = pmrData.samples
    const allPrices = pmrData.all_prices

    var headerValuesCount = 0
    if (!error && description && description.mileage_adjusted_price) { headerValuesCount++ }
    if (!error && description && description.avg_grade) { headerValuesCount++ }
    if (!error && description && description.avg_mileage) { headerValuesCount++ }

    var avgSaleGraphData = null
    var avgSaleMin = null
    var avgSaleMax = null

    var hasPricesGraphData = false
    if (allPrices && allPrices.length > graphSamplesThreshold) {
      hasPricesGraphData = true
      var formattedAvgSaleSamplesData = allPrices.map((s) => {
        return {
          "x": s,
          "y": 1,
          "isIndicator": false,
          "formattedPrice": `Sale price: ${Formatters.formatCurrencyRoundedCents(s)}`
        }
      })
      formattedAvgSaleSamplesData.push({
        "x": description.avg_price,
        "y": 1,
        "isIndicator": true,
        "formattedPrice": `Average sale price: ${Formatters.formatCurrencyRoundedCents(description.avg_price)}`,
      })
      avgSaleGraphData = [ { "id": "sale_price", "data": formattedAvgSaleSamplesData } ]
      avgSaleMin = allPrices[0]
      avgSaleMax = allPrices[allPrices.length - 1]
    }


    var formattedTrendData = []
    var formattedPoints = []
    const timeline = pmrData.historical_data
    var lowestVal = 999999
    if (timeline && timeline.length > 1) {
      for (let pointGroup of timeline) {
        const convertedPrice = Math.round(pointGroup.avg_price / 100)
        if (convertedPrice < lowestVal) { lowestVal = convertedPrice}
        var label
        if (global.isExtensionMode || global.isIFrameMode) {
          label = `-${pointGroup.months_ago} mo`
        } else {
          label = `${pluralize('month', pointGroup.months_ago, true)} ago`
        }

        formattedPoints.push({
          x: pointGroup.months_ago > 0 ? label : 'Today',
          y: convertedPrice
        })      
      }

      formattedTrendData.push({
        "id": "Auction Sales",
        "color": "#505d62",
        "data": formattedPoints
      })
    }

  

    const isRegionLimited = description?.adjustments && description.adjustments.includes('region')
    const isMileageLimited = description?.adjustments && description.adjustments.includes('mileage')
    const limitedArray = []
    if (isRegionLimited) { limitedArray.push('your region') }
    if (isMileageLimited) { limitedArray.push('vehicles with similar mileage') }
    const limitedString = limitedArray.length > 0 ? limitedArray.join(' and ') : null

    const adjustedRough = appraisal?.rough?.wholesale?.adjusted
    const adjustedAverage = appraisal?.average?.wholesale?.adjusted
    const adjustedClean = appraisal?.clean?.wholesale?.adjusted


    return (
      <ValuationProviderCard
          providerData={pmrData}
          vehicleUUID={this.props.vehicleUUID}
          clientID={this.props.clientID}
          providerKey={this.props.providerKey}
          systemData={systemData}
          fullChoices={this.props.fullChoices}
          currentChoiceUID={pmrData.description && pmrData.description.uid ? pmrData.description.uid : null}
          title={title}
          subtitle={error || !description ? null : description.display_choice}
          hasError={error ? true : false}
          valuationMileage={this.props.valuationMileage}
          providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
          showAdjustmentMessage={false}
          messages={pmrData.messages}
          >

          {
            !error && description &&
            <div className="pipeline-c">
              {
                Boolean(description.avg_price || description.avg_grade || description.avg_mileage) &&
                <div className="valuation-subsection header-values">
                  {
                    Boolean(appraisal[overallCondition] && appraisal[overallCondition]['wholesale'] && appraisal[overallCondition]['wholesale']['adjusted']) &&
                    <div className="header-value-c pipeline-header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                      <div className="header-value-value">{Formatters.formatCurrencyRoundedCents(appraisal[overallCondition]['wholesale']['adjusted'])}</div>
                      <div className="header-value-label">Mileage adjusted sale price</div>
                      {
                        !global.isPartnerPreview &&
                          <div className='adjusted-condition-c'>
                          {
                            Boolean(adjustedRough) &&
                            <div className={overallCondition === 'rough' ? 'adjusted-condition-current-value' : ''}>
                              <div className="adjusted-condition-value">{Formatters.formatCurrencyRoundedCents(adjustedRough)}</div>
                              <div className="adjusted-condition-label">Rough</div>
                            </div>
                          }
                          {
                            Boolean(adjustedRough) && Boolean(adjustedAverage) &&
                            <div className="adjusted-condition-separator" />
                          } 
                          {
                            Boolean(adjustedAverage) &&
                            <div className={overallCondition === 'average' ? 'adjusted-condition-current-value' : ''}>
                              <div className="adjusted-condition-value">{Formatters.formatCurrencyRoundedCents(adjustedAverage)}</div>
                              <div className="adjusted-condition-label">Average</div>
                            </div>
                          }
                          {
                            Boolean(adjustedAverage) && Boolean(adjustedClean) &&
                            <div className="adjusted-condition-separator" />
                          } 
                          {
                            Boolean(adjustedClean) &&
                            <div className={overallCondition === 'clean' ? 'adjusted-condition-current-value' : ''}>
                              <div className="adjusted-condition-value">{Formatters.formatCurrencyRoundedCents(adjustedClean)}</div>
                              <div className="adjusted-condition-label">Clean</div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                  {
                    Boolean(description.avg_grade) &&
                    <div className="header-value-c pipeline-header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                      <div className="header-value-value">{description.avg_grade}</div>
                      <div className="header-value-label">Average condition grade</div>
                      {
                        this.props.conditionGrade && this.props.conditionGrade < description.avg_grade &&
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'red'}} title='Current vehicle lower than average'>▼</div>
                      }
                      {
                        this.props.conditionGrade && this.props.conditionGrade > description.avg_grade &&
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'lightgreen'}} title='Current vehicle above average'>▲</div>
                      }
                    </div>
                  }
                  {
                    Boolean(description.avg_mileage) &&
                    <div className="header-value-c pipeline-header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                      <div className="header-value-value">{Formatters.formatThousands(description.avg_mileage)}</div>
                      <div className="header-value-label">Average mileage</div>
                      {
                        this.props.valuationMileage && this.props.valuationMileage < description.avg_mileage - (description.avg_mileage * 0.2) &&
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'lightgreen'}} title='Current vehicle lower than average'>▼</div>
                      }
                      {
                        this.props.valuationMileage && this.props.valuationMileage > description.avg_mileage + (description.avg_mileage * 0.2) &&
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'red'}} title='Current vehicle above average'>▲</div>
                      }
                    </div>
                  }
                </div>
              }
              <div className="valuation-subsection">
                <div className="distributions-c">
                  {
                    Boolean(description.avg_price) &&
                    <div className="distribution-col">
                      <div className="distribution-header">
                        <span className="distribution-header-value">{Formatters.formatCurrencyRoundedCents(description.avg_price)}</span> average sale price
                      </div>
                      {
                        avgSaleGraphData !== null && hasPricesGraphData &&
                        <div style={{flexGrow: 1, flexShrink: 1, height: '46px'}}>
                          <ResponsiveScatterPlot
                            height={46}
                            data={avgSaleGraphData}
                            margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                            xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            blendMode="multiply"
                            colors='rgba(0, 92, 178, 0.4)'
                            nodeSize={(node) => {
                              if (node.isIndicator === true) {
                                return 26
                              } else {
                                return 16
                              }
                            }}
                            enableGridX={false}
                            enableGridY={true}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            tooltip={(el) => {
                              return (
                                <div className="graph-tooltip">
                                  <div>{el.node.data.formattedPrice}</div>
                                </div>
                              )
                            }}
                            legends={[]}
                            renderNode={CustomNode}
                            />
                          <div className="graph-x-axis">
                            <div>{Formatters.formatCurrencyRoundedCents(avgSaleMin)}</div>
                            <div>{Formatters.formatCurrencyRoundedCents(avgSaleMax)}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>

              </div>


              {
                Boolean(formattedTrendData && formattedTrendData.length > 0) &&
                <div className="valuation-subsection">
                  <h3 className="valuation-subsection-title">Price Trend</h3>
                  <div className="trend-graph">
                    <ResponsiveLine
                      data={formattedTrendData}
                      margin={{ top: 30, right: 40, bottom: 26, left: 40 }}
                      xScale={{ type: 'point' }}
                      yScale={{ type: 'linear', stacked: false, min: lowestVal - (lowestVal * 0.05), max: 'auto' }}
                      yFormat={(node) => {
                        return Formatters.formatCurrency(node)
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                          orient: 'bottom',
                          tickSize: 2,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: null,
                          legendOffset: 36,
                          legendPosition: 'middle'
                      }}
                      axisLeft={null}
                      enableGridY={false}
                      colors={(node => {
                        return node.color;
                      })}
                      lineWidth={5}
                      pointSize={12}

                      pointBorderWidth={3}
                      pointBorderColor={{ from: 'serieColor' }}
                      enablePointLabel={true}
                      pointLabel="yFormatted"
                      pointLabelYOffset={-12}
                      areaBaselineValue={130}
                      useMesh={true}
                      tooltip={(el) => {
                        return (
                          <div className="graph-tooltip">
                            <div>{el.point.data.xFormatted}</div>
                            <div>{el.point.data.yFormatted}</div>
                          </div>
                        )
                      }}

                    />
                  </div>
                </div>
              }


              

              {this.renderSamples(allSamples && isShowingAllSamples ? allSamples : samples, description.avg_price)}

              {
                Boolean(!global.isPartnerPreview && !areSamplesHidden && !isLoadingAllSamples && description.uid && description.total_transaction_count && description.total_transaction_count > 0 && description.total_transaction_count > description.transaction_count) &&
                <div style={{marginTop: '10px'}}>
                  {
                    limitedString && !isShowingAllSamples &&
                    <div>
                      <div>The above auction sales are limited to {limitedString}.</div>
                      <div>Showing {description.transaction_count} of {description.total_transaction_count} total sales. <a href="#show-all" onClick={(e) => {e.preventDefault(); this.loadAllSamples(description.uid)}}> Show all sales</a></div>
                    </div>
                  }
                  
                  
                  {
                    isShowingAllSamples &&
                    <div>
                      <div>Showing all {Formatters.formatThousands(description.total_transaction_count)} sales</div>
                      <a href="#show-limited" onClick={(e) => {
                        e.preventDefault()
                        this.setState({ allSamples: null, isShowingAllSamples: false })
                        if (this.refs.samplesTop) {
                          this.refs.samplesTop.scrollIntoView() 
                        }
                        }}> Show limited sales</a>
                    </div>
                  }
                </div>
              }
            </div>
          }
          {
            (isLoadingPRMSampleDetail || isShowingPRMSampleDetail) &&
            <Modal toggle={this.closePMRSampleDetail} size="xl" centered={true} isOpen={true} contentClassName='modal-c'>
              <ModalBody>
                {
                  isLoadingPRMSampleDetail ? 
                  <Spinner className="spinner-page" /> :
                  <AuctionListing listing={pmrSampleDetail} showValuation={false} allowLightbox={false} isEmbedded={true} />
                }
                
              </ModalBody>
              <ModalFooter>
                {
                  !isLoadingPRMSampleDetail &&
                  <Button color="secondary" onClick={this.closePMRSampleDetail}>Close</Button>
                }
              </ModalFooter>
            </Modal>
            
          }
      </ValuationProviderCard>
    )
  }
}



const CustomNode = ({ node, x, y, isIndicator, size, color, blendMode, onMouseEnter, onMouseMove, onMouseLeave, onClick }) => {
    if (node.data.isIndicator === true) {
        return (
          <g transform={`translate(${x},${y})`}>
          <rect
                x={size * -0.125}
                y={size * -0.75}
                rx={2}
                ry={2}
                width={size * 0.25}
                height={size * 1.5}
                fill={'#02b1fc'}
                style={{ mixBlendMode: blendMode }}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            />
          </g>
        )
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <circle
                x={size * -0.5}
                y={size * -0.5}
                r={size / 2}
                fill={color}
                style={{ mixBlendMode: blendMode }}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            />
        </g>
    )
}
