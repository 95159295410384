import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import DataFreshnessMonitor from '../common/DataFreshnessMonitor'
import AuctionsListingsList from './AuctionsListingsList'
import AccountBug from '../user/AccountBug'
import Formatters from '../../api/constants/Formatters'
import Spinner from '../common/Spinner'
import platformFunctions from '../../platformFunctions'
import CalculatorBug from '../common/CalculatorBug'
import SessionStore from '../../api/stores/SessionStore'
import AuctionsListingShowModal from './AuctionsListingShowModal'
import AuctionVehiclesDisplayBar from './AuctionVehiclesDisplayBar'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

export default class AuctionLaneShow extends React.Component {

  state = {
    laneIsLoading: true,
    laneListings: null,
    location: null,
    date: null,
    isLoadingMoreListings: false,
    auctionListingsDisplayType: SessionStore.auctionListingsLaneDisplayType,
    clickedListingID: null,
    isShowingListing: false
  }

  componentDidMount() {
    this.lastFreshDataTime = null
    const laneNumber = this.props.navMatch.params.laneNumber
    const saleID = this.props.navMatch.params.saleID

    this.clientID = this.composeClientID(saleID, laneNumber)

    this.addListeners()

    AuctionsActionCreators.loadAuctionListingsForLane(saleID, laneNumber, this.clientID)
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Lane")
  }

  componentDidUpdate(prevProps, prevState) {
    const laneNumber = this.props.navMatch.params.laneNumber
    const saleID = this.props.navMatch.params.saleID

    const newClientID = this.composeClientID(saleID, laneNumber)
    if (laneNumber && saleID && (this.clientID === undefined || this.clientID !== newClientID)) {
       this.removeListeners()

       this.clientID = newClientID
       this.addListeners()
       this.lastFreshDataTime = null

       this.setState({
         laneIsLoading: true,
         laneListings: null
       })

       AuctionsActionCreators.loadAuctionListingsForLane(saleID, laneNumber, this.clientID)
    }
  }

  composeClientID = (saleID, laneNumber) => {
    return `ll-${saleID}-${laneNumber}`
  }

  addListeners = () => {
    AuctionStore.on(`auction_lane_listings_change_${this.clientID}`, this.laneListingsDidChange)
    AuctionStore.on('global_auction_listing_update', this.laneListingsDidChange)
  }

  removeListeners = () => {
    AuctionStore.removeListener(`auction_lane_listings_change_${this.clientID}`, this.laneListingsDidChange)
    AuctionStore.removeListener('global_auction_listing_update', this.laneListingsDidChange)
  }

  laneListingsDidChange = () => {
    const laneListings = AuctionStore.auctionLaneListingsWithClientID(this.clientID)
    const existingListings = this.state.laneListings

    if (!existingListings || existingListings.length === 0) {
      this.lastFreshDataTime = moment()
    }
    this.setState({
      laneIsLoading: false,
      laneListings: laneListings,
      isLoadingMoreListings: false,
      location: AuctionStore.auctionLaneListingsLocationWithClientID(this.clientID),
      date: AuctionStore.auctionLaneListingsDateWithClientID(this.clientID)
    })
  }

  onScrollEnd = () => {
    const isEnd = AuctionStore.auctionLaneListingsEndOfListReached.dataWithID(this.clientID) === true
    if (isEnd || this.state.laneIsLoading) { return }

    if (!this.state.isLoadingMoreListings) {
      const laneNumber = this.props.navMatch.params.laneNumber
      const saleID = this.props.navMatch.params.saleID
      const lastRunNumber = AuctionStore.auctionLaneListingsLastRunNumber.dataWithID(this.clientID)
      AuctionsActionCreators.loadAuctionListingsForLane(saleID, laneNumber, this.clientID, lastRunNumber)
      this.setState({
        isLoadingMoreListings: true
      })

    }
  }

  onWatchlistToggle = (listing) => {
    // Hacky.  In addition to the store update, we're immediately updating this list
    var newListings = [...this.state.laneListings]
    var targListing = newListings.find(l => l.id === listing.id)
    if (targListing) {
      targListing.watchlist = !targListing.watchlist
    }
    this.setState({
      laneListings: newListings
    })
    platformFunctions.logAnalyticsEvent("Auctions", "Toggled Watchlist - Lane")
    UserActionCreators.createMetric(AppConstants.metricNames.auctions_watchlist_lane)
  }

  onListingClick = (event, listingID) => {
    event.preventDefault()

    this.setState({
      clickedListingID: listingID,
      isShowingListing: true
    })
  }


  render() {
    const { laneIsLoading, laneListings, location, date, auctionListingsDisplayType, isShowingListing, clickedListingID } = this.state
    const laneNumber = this.props.navMatch.params.laneNumber


    if (laneIsLoading) {
      return (
        <div className="body-content">
          <Spinner className="spinner-page" />
        </div>
      )
    }

    return (
      <div className="body-content">
        <div className="content-header">
          <div>
            <h1>Lane {laneNumber}</h1>
            {
              Boolean(location?.name) &&
              <h2>{location.name} - {Formatters.formatVerboseDateWithDay(date)}</h2>
            }
          </div>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          this.lastFreshDataTime !== null &&
          <div style={{marginRight: '20px'}}>
            <DataFreshnessMonitor lastFreshDataTime={this.lastFreshDataTime} label='Auction data is' />
          </div>
        }

        {
          laneListings && laneListings.length > 0 &&
          <div className="card" style={{border: 'none', boxShadow: 'none', backgroundColor: 'transparent', borderTop: 'none', marginTop: '0px'}}>
            <div className="card-list" style={{backgroundColor: 'transparent'}}>
              <AuctionVehiclesDisplayBar
                defaultDisplayType={SessionStore.auctionListingsLaneDisplayType}
                showSort={false}
                onListingsDisplayModeChange={(newAuctionListingsDisplayType) => { 
                  this.setState({ auctionListingsDisplayType: newAuctionListingsDisplayType })
                  SessionStore.setAuctionListingsLaneDisplayType(newAuctionListingsDisplayType)
                  UserActionCreators.createMetric(AppConstants.metricNames['auctions_lane_list_type_change'], newAuctionListingsDisplayType)
                }}
                style={{paddingLeft: 0}} />

              <AuctionsListingsList
                isActivityList={false}
                listings={laneListings}
                onScrollEnd={this.onScrollEnd}
                auctionListingsDisplayType={auctionListingsDisplayType}
                hasMore={AuctionStore.auctionLaneListingsEndOfListReached.dataWithID(this.clientID) !== true}
                showRunNumber={true}
                showChannel
                onWatchlistToggle={this.onWatchlistToggle}
                onClick={(event, listingID) => {
                  this.onListingClick(event, listingID)
                }} />
            </div>
          </div>
        }

        {
          (!laneListings || laneListings.length === 0) &&
          <div>There are no vehicles listed for this lane</div>
        }

        {
          isShowingListing &&
          <AuctionsListingShowModal
            listingsContext={laneListings}
            listingID={clickedListingID}
            history={this.props.history}
            onDone={() => {
              this.setState({ isShowingListing: false })
            }} />
        }

      </div>
    )
  }

}
