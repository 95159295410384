import React, { memo, useCallback, useState, useEffect } from 'react'
import Spinner from '../common/Spinner'
import Activity from './Activity'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import useIntersectionObserver from '../hooks/useIntersectionObserver'

const defaultState = {
  isLoading: false,
  activities: [],
  hasLastPage: false,
  cutoff: null,
}

const ActivitiesInfiniteScroll = ({ clientID, scope, onActivityClick, getActivityIsDisabled }) => {
  const [
    {
      isLoading,
      activities,
      hasLastPage,
      cutoff,
    },
    setState,
  ] = useState(defaultState)

  const onActivitiesChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: false,
      activities: ValuationStore.vehicleActivitiesData.dataWithID(clientID).collection,
      hasLastPage: ValuationStore.vehicleActivitiesData.dataWithID(clientID).hasLastPage(),
      cutoff: ValuationStore.vehicleActivitiesData.dataWithID(clientID).getCutoff(),
    }))
  }, [clientID])

  const loadNextPage = useCallback(() => {
    if (!isLoading) {
      setState(prev => ({
        ...prev,
        isLoading: true,
      }))
      ValuationActionCreators.loadVehicleActivities(cutoff, scope, clientID)
    }
  }, [cutoff, isLoading, clientID, scope])

  const refresh = useCallback(() => {
    setState(prev => ({
      ...prev,
      ...defaultState,
      isLoading: true,
    }))
    ValuationActionCreators.loadVehicleActivities(defaultState.cutoff, scope, clientID)
  }, [scope, clientID])

  const {
    scrollRef,
  } = useIntersectionObserver({ onIntersect: loadNextPage, shouldObserve: !hasLastPage })

  useEffect(() => {
    ValuationStore.on(`vehicle_activities_change_${clientID}`, onActivitiesChange)
    return () => {
      ValuationStore.removeListener(`vehicle_activities_change_${clientID}`, onActivitiesChange)
    }
  }, [clientID, onActivitiesChange])

  useEffect(() => {
    ValuationStore.on(`valuation_change_${clientID}`, refresh)
    return () => {
      ValuationStore.removeListener(`valuation_change_${clientID}`, refresh)
    }
  }, [clientID, refresh])

  return (
    <div className="card">
      <h2 className="card-title">Carbly activity from all team members</h2>
      {
        (isLoading || activities.length) ?
          activities.map(activity => {
            return (
              <Activity
                key={activity.id}
                activity={activity}
                onClick={onActivityClick}
                isDisabled={getActivityIsDisabled ? getActivityIsDisabled(activity) : false}
              />
            )
          }) :
          <div className="secondary-text" style={{margin: '20px'}}>No activity yet</div>
      }
      <div ref={scrollRef}>
        {
          isLoading &&
            <Spinner key="pagination-spinner" />
        }
      </div>
    </div>
  )
}

export default memo(ActivitiesInfiniteScroll)
