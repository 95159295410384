import React, { useState, useMemo, useCallback, useEffect } from 'react'
import useGetDotAuctionUser from './useGetUser'
import usePlaceBid from './usePlaceBid'
import useCancelMaxBid from './useCancelMaxBid'
import useCountdown from './useCountdown'
import CommonFunctions from '../../constants/CommonFunctions'
import Formatters from '../../constants/Formatters'
import useGetDotAuctionListing from './useGetDotAuctionListing'

const Countdown = ({ endDate }) => {
  const { msLeft, formattedStr } = useCountdown(endDate)
  if (msLeft < 0) return '--'
  return formattedStr
}

export default (listingId, platform) => {
  const {
    data = {},
    loading: isLoadingListings,
  } = useGetDotAuctionListing(listingId)

  const {
    bidders = [],
    loading: isLoadingBidders,
  } = useGetDotAuctionUser()

  const isLoading = isLoadingBidders || isLoadingListings

  const {
    id,
    numberOfBids,
    floorPriceMet,
    currentBid,
    timeRange = {},
    openingPrice,
    bidIncrement = {},
    buyNowPrice,
    state,
    endedStatus,
    myActiveBid,
    myFailedBid,
  } = data

  const currentBidAmount = currentBid?.bidAmount?.amount
  const currentBidBidderId = currentBid?.bidderId
  const { startTime, plannedEndTime, actualEndTime } = timeRange
  const openingPriceAmount = openingPrice?.amount ?? 0
  const bidIncrementAmount = bidIncrement.amount
  const bidIncrementCurrency = bidIncrement.currency ?? 'USD'
  const buyNowPriceAmount = buyNowPrice?.amount
  const incrementalBidAmount = currentBidAmount ? currentBidAmount + bidIncrementAmount : openingPriceAmount

  const firstBidderId = bidders[0]?.id

  // FIXME handle no bidders?
  const [biddingAsBidderId, setBiddingAsBidderid] = useState(firstBidderId)
  const [pendingIncrementalBidAmount, setPendingIncrementalBidAmount] = useState(incrementalBidAmount)
  const [pendingHardBid, setPendingHardBid] = useState(incrementalBidAmount)
  const [pendingMaxBid, setPendingMaxBid] = useState(null)
  const [hardBidIsShown, setHardBidIsShown] = useState(false)
  const [maxBidIsShown, setMaxBidIsShown] = useState(false)
  const [buyNowConfirmIsShown, setBuyNowConfirmIsShown] = useState(false)

  const userBidderIds = useMemo(() => {
    return bidders.map(bidder => bidder.id)
  }, [bidders])

  const maxBid = useMemo(() => {
    return myActiveBid && myActiveBid.bidType === 'Max' ? myActiveBid : null
  }, [myActiveBid])

  const isHighBidder = useMemo(() => {
    return userBidderIds.includes(currentBidBidderId)
  }, [currentBidBidderId, userBidderIds])

  const maxBidAmount = maxBid?.bid?.amount
  const maxBidId = maxBid?.id

  const biddingAsBidder = useMemo(() => {
    return bidders.find(bidder => bidder.id === biddingAsBidderId)
  }, [biddingAsBidderId, bidders])

  const bidderStatusMessage = useMemo(() => {
    // A BuyNow winning bid will result in isHighBidder but no myActiveBid
    if (!(myActiveBid || isHighBidder)) return null
    switch (state) {
      case 'Started':
      case 'Extended':
      case 'Ended':
        switch (endedStatus) {
          case 'NoSale':
          case 'FloorNotMet':
            return null
          case 'Sold':
            return isHighBidder ? 'You won!' : 'You did not win'
          case 'IfOffer':
            return isHighBidder ? 'Waiting for seller to review your high bid' : 'You did not win'
          default:
            return isHighBidder ? 'You are high bidder' : 'You have been outbid'
        }
      default:
        return null
    }
  }, [myActiveBid, state, endedStatus, isHighBidder])

  const timeStatus = useMemo(() => {
    switch (state) {
      case 'Started':
        return <Countdown endDate={plannedEndTime} />
      case 'Extended':
        return <><Countdown endDate={actualEndTime} /> Extended</>
      case 'Ended':
        switch (endedStatus) {
          case 'Sold':
            return 'Sold'
          case 'NoSale':
            return 'Ended: No sale'
          case 'FloorNotMet':
            return 'Ended: Floor not met'
          case 'IfOffer':
            return 'Pending'
        }
      case 'NotStarted':
        return `Starts: ${new Date(startTime).toLocaleString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`
      case 'Canceled':
        return 'Canceled'
    }
  }, [state, plannedEndTime, actualEndTime, startTime, endedStatus])

  const isRunning = useMemo(() => {
    switch (state) {
      case 'Started':
      case 'Extended':
        return true
      default:
        return false
    }
  }, [state])

  const [
    placeBid,
    {
      loading: placeBidIsLoading,
      data: placeBidData,
      reset: resetPlaceBid,
      // called: placeBidIsCalled,
      // error: placeBidError,
    },
  ] = usePlaceBid(id, bidIncrementCurrency, biddingAsBidderId, platform)

  const [
    cancelMaxBid,
    {
      loading: cancelMaxBidIsPending,
      reset: resetCancelMaxBid,
      // error: cancelMaxBidError,
    },
  ] = useCancelMaxBid(id, maxBidId)

  const placeHardBid = useCallback(() => {
    return placeBid('Hard', pendingHardBid)
  }, [placeBid, pendingHardBid])

  const placeMaxBid = useCallback(() => {
    return placeBid('Max', pendingMaxBid)
  }, [placeBid, pendingMaxBid])

  const placeBuyNowBid = useCallback(() => {
    return placeBid('BuyNow', buyNowPriceAmount)
  }, [placeBid, buyNowPriceAmount])

  useEffect(() => {
    if (!biddingAsBidderId && firstBidderId) {
      setBiddingAsBidderid(firstBidderId)
    }
  }, [firstBidderId, biddingAsBidderId])

  useEffect(() => {
    if (
      placeBidData && (
        (myActiveBid && placeBidData.placeBid.id === myActiveBid.id) ||
        (myFailedBid && placeBidData.placeBid.id === myFailedBid.id)
      )
    ) {
      resetPlaceBid()
    }
  }, [myActiveBid, placeBidData, resetPlaceBid, myFailedBid])

  const isPlacedBidPending = useMemo(() => {
    if (placeBidIsLoading) return true
    if (!placeBidData) return false
    const placedBidId = placeBidData.placeBid.id
    return placedBidId !== myActiveBid?.id && placedBidId !== myFailedBid?.id
  }, [placeBidIsLoading, placeBidData, myActiveBid, myFailedBid])

  const bidActionIsPending = isPlacedBidPending || cancelMaxBidIsPending

  const isPendingIncrementalBidStale = useMemo(() => {
    return incrementalBidAmount !== pendingIncrementalBidAmount && !isHighBidder
  }, [incrementalBidAmount, pendingIncrementalBidAmount, !isHighBidder])

  const isBuyNowAvailable = useMemo(() => {
    return [
      !floorPriceMet,
      buyNowPriceAmount,
      (currentBidAmount ?? 0) < buyNowPriceAmount,
    ].every(Boolean)
  }, [buyNowPriceAmount, floorPriceMet, currentBidAmount])

  const bidError = useMemo(() => {
    switch (myFailedBid?.status) {
      case null:
      case 'Placed':
        return null
      case 'AmountBelowOpeningPrice':
        return 'Bid amount must be above opening price'
      case 'AmountBelowCurrentBid':
        return 'Bid amount must be higher than the current bid'
      case 'VenueListingEnded':
        return 'This sale has ended'
      case 'VenueListingNotStarted':
        return 'This sale has not yet started'
      case 'VenueListingCanceled':
        return 'This sale has been canceled'
      case 'VenueListingPaused':
        return 'This sale has been paused'
      case 'BuyNowNotAllowed':
        return 'Buy Now is not enabled'
      case 'BidderCantOutbidSelf':
        return 'You aleady have the highest bid'
      case 'BidderCantReplaceYourWinnerMaxBid':
      case 'AmountBelowBuyNowPrice':
        // FIXME: Readable error messages
        return `Error: ${myFailedBid.status}`
      default:
        return null
    }
  }, [myFailedBid])

  // NOTE: Not supported
  const isMaxBidCancelable = false // maxBidAmount && !(isHighBidder && maxBidAmount === currentBidAmount)

  const formatCurrency = useCallback(value => {
    return Formatters.formatCurrency(value ?? 0)
  }, [])

  const isBidDivisibleByIncrement = useCallback(bidValue => {
    return bidValue % bidIncrementAmount === 0
  }, [bidIncrementAmount])

  const isBidOverMinimum = useCallback(bidValue => {
    return bidValue >= incrementalBidAmount
  }, [incrementalBidAmount])

  const isBidValid = useCallback(bidValue => {
    return isBidOverMinimum(bidValue) && isBidDivisibleByIncrement(bidValue)
  }, [isBidOverMinimum, isBidDivisibleByIncrement])

  const setBiddingAsBidder = useCallback(bidder => {
    setBiddingAsBidderid(bidder.id)
  }, [])

  const resetHardBid = useCallback(() => {
    setPendingIncrementalBidAmount(incrementalBidAmount)
    setPendingHardBid(incrementalBidAmount)
  }, [incrementalBidAmount])

  const toggleHardBid = useCallback(() => {
    const willShow = !hardBidIsShown
    if (willShow) {
      resetHardBid()
    }
    setHardBidIsShown(willShow)
  }, [hardBidIsShown, resetHardBid])

  const toggleBuyNowConfirm = useCallback(() => {
    const willShow = !buyNowConfirmIsShown
    setBuyNowConfirmIsShown(willShow)
  }, [buyNowConfirmIsShown])

  const cleanInputValue = useCallback(value => {
    const cleanedValue = parseInt(CommonFunctions.stripNonNumericNoDecimal(value))
    return isNaN(cleanedValue) ? null : cleanedValue
  }, [])

  const onHardBidInputValueChange = useCallback(value => {
    setPendingHardBid(cleanInputValue(value))
  }, [cleanInputValue])

  const onHardBidCancel = useCallback(() => {
    setHardBidIsShown(false)
  }, [])

  const onMaxBidCancel = useCallback(() => {
    setPendingMaxBid(null)
    setMaxBidIsShown(false)
  }, [])

  const onBuyNowConfirmCancel = useCallback(() => {
    setBuyNowConfirmIsShown(false)
  }, [])

  const onMaxBidInputValueChange = useCallback(value => {
    setPendingMaxBid(cleanInputValue(value))
  }, [cleanInputValue])

  const toggleMaxBid = useCallback(() => {
    const willShow = !maxBidIsShown
    if (!willShow) {
      setPendingMaxBid(null)
    }
    setMaxBidIsShown(willShow)
  }, [maxBidIsShown])

  return {
    id,
    isLoading,
    numberOfBids,
    floorPriceMet,
    bidders,
    isHighBidder,
    biddingAsBidder,
    isRunning,
    currentBidAmount,
    incrementalBidAmount,
    maxBidAmount,
    buyNowPriceAmount,
    bidIncrementAmount,
    pendingHardBid,
    pendingMaxBid,
    hardBidIsShown,
    maxBidIsShown,
    buyNowConfirmIsShown,
    bidderStatusMessage,
    timeStatus,
    isPlacedBidPending,
    isPendingIncrementalBidStale,
    isBuyNowAvailable,
    cancelMaxBidIsPending,
    bidActionIsPending,
    isMaxBidCancelable,
    bidError,

    isBidValid,
    isBidDivisibleByIncrement,
    isBidOverMinimum,
    setBiddingAsBidder,
    resetHardBid,
    toggleHardBid,
    toggleMaxBid,
    toggleBuyNowConfirm,
    placeHardBid,
    placeMaxBid,
    placeBuyNowBid,
    onHardBidInputValueChange,
    onHardBidCancel,
    onMaxBidCancel,
    onMaxBidInputValueChange,
    formatCurrency,
    onBuyNowConfirmCancel,
    cancelMaxBid,
  }
}
