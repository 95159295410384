import React from 'react'
import ValuationProviderCard from './ValuationProviderCard'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import AdjustmentsTable from './AdjustmentsTable'
import SessionStore from '../../api/stores/SessionStore'
import AddDeducts from './AddDeducts'

const title = AppConstants.providerNames.galves

export default class ValuationGalves extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addDeductsHidden: SessionStore.areAddDeductsHidden(this.props.providerKey)
    }
  }

  render() {
    const galvesData = this.props.providerData
    if (!galvesData) { return null }
    const description = galvesData.description
    const appraisal = galvesData.appraisal
    const overallCondition = this.props.overallCondition
    const error = galvesData.error
    const systemData = this.props.systemData
    const changesLocked = (systemData && systemData.changes_locked === true)
    const adItems = galvesData.add_deducts && galvesData.add_deducts.items && galvesData.add_deducts.items.length > 0 ? galvesData.add_deducts.items : null
    const adLogic = galvesData.add_deducts && galvesData.add_deducts.logic ? galvesData.add_deducts.logic : null
    const { addDeductsHidden } = this.state

    var headerValuesCount = 0
    if (Boolean(appraisal && appraisal['market_ready'] && appraisal['market_ready']['adjusted'])) {
      headerValuesCount++
    }
    if (Boolean(appraisal && appraisal['tradein'] && appraisal['market_ready']['tradein'])) {
      headerValuesCount++
    }

    const hasTradeIn = Boolean(appraisal && appraisal['tradein'] && appraisal['tradein']['adjusted'])
    const hasMarketReady = Boolean(appraisal && appraisal['market_ready'] && appraisal['market_ready']['adjusted'])

    const orderedAppraisalValues = hasTradeIn && hasMarketReady ? {tradein: appraisal.tradein, market_ready: appraisal.market_ready} : null

    return (
      <ValuationProviderCard
        providerData={galvesData}
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey={this.props.providerKey}
        systemData={systemData}
        fullChoices={this.props.fullChoices}
        currentChoiceUID={galvesData.description && galvesData.description.uid ? galvesData.description.uid : null}
        title={title}
        subtitle={error || !description ? null : description.display_choice}
        hasError={error ? true : false}
        showAdjustmentMessage={true}
        valuationMileage={this.props.valuationMileage}
        providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
        messages={galvesData.messages}
        onBookout={this.props.onBookout}
      >

        <div>
          <div style={{flex: 'row', justifyContent: 'space-around'}}>
            <div className="valuation-subsection header-values">
              {
                hasTradeIn &&
                <div className="header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                  <div className="header-value-value">{Formatters.formatCurrency(appraisal['tradein']['adjusted'])}</div>
                  <div className="header-value-label">Trade-In</div>
                </div>
              }
              {
                hasMarketReady &&
                <div className="header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                  <div className="header-value-value">{Formatters.formatCurrency(appraisal['market_ready']['adjusted'])}</div>
                  <div className="header-value-label">Market Ready</div>
                </div>
              }
            </div>


          </div>
          <AdjustmentsTable appraisalValues={orderedAppraisalValues} />

          {
              description?.avg_mileage &&
              <div style={{marginTop: '10px'}}>Base Mileage: <span style={{fontWeight: 'bold'}}>{Formatters.formatThousands(description.avg_mileage)}</span></div>
            }
        </div>

        {
          adItems && !global.isPartnerPreview &&
          <div className="valuation-subsection">
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <h3 className="valuation-subsection-title">Vehicle Options</h3>
              <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
                e.preventDefault()
                SessionStore.setHiddenAddDeducts(!addDeductsHidden, this.props.providerKey)
                this.setState({ addDeductsHidden: !addDeductsHidden })
              }}>{addDeductsHidden ? 'Show' : 'Hide'}</a>
            </div>
            {
              !addDeductsHidden &&
              <AddDeducts items={adItems} adLogic={adLogic} providerKey="galves" clientID={this.props.clientID} vehicleUUID={this.props.vehicleUUID} overallCondition={overallCondition} />
            }
          </div>
        }

        {
          global.isPartnerPreview &&
          <div className="valuation-subsection partner-preview-access-message">
            Modifying options and accessories is only available with a registered <a href="https://getcarbly.com/register/?r=edge" target="_blank">Carbly account</a>.
          </div>
        }

      </ValuationProviderCard>
    )
  }
}
