import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import OfferingsPicker from '../common/OfferingsPicker'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AccountReactivation from '../user/AccountReactivation'
import WarningCircleIcon from '../common/icons/WarningCircleIcon'


export default class AccountNeedsAction extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    isLoadingAvailableOfferings: true,
    accountDetails: null,
    compactViewIsExpanded: false
  }
  
  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)
    SessionStore.on("account_reactivation_received", this.accountReactivationReceived)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
    SessionStore.removeListener("account_reactivation_received", this.accountReactivationReceived)
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  accountReactivationReceived = () => {
    setTimeout(() => {
      if (SessionStore.accountReactivationErrorMessage) {
        this.setState({
          accountReactivationErrorMessage: SessionStore.accountReactivationErrorMessage,
          isReactivating: false,
          isLoading: false
        }) 
      } else {
        window.location.reload()
      }
    }, 6000);
  }
  

  render() {
    const { showCompact } = this.props
    const { compactViewIsExpanded } = this.state
    const hasAccountOfferings = SessionStore.hasAccountOfferings()
    const shouldShowReactivation = SessionStore.shouldShowAccountReactivation()
    const shouldShowOfferingsPicker = !hasAccountOfferings || (!shouldShowReactivation && SessionStore.hasFeatureOfferings())

    let title = 'Your Carbly account is not active'
    if (!shouldShowReactivation && SessionStore.hasFeatureOfferings()) {
      title = "Add vehicle provider data to your account"
    }

    return (
      <div className={`${this.props.className} ${showCompact && !compactViewIsExpanded ? ' account-action-warn' : ''}`}>
        <div style={{position: 'relative'}}>
          <div className="content-header">
            <h1>{title}</h1>
          </div>
          {
            showCompact &&
            <WarningCircleIcon color='#fff' size={110} style={{position: 'absolute', right: '-40px', top: '-30px', opacity: 0.4}} />
          }
        </div>

        {
          showCompact && !compactViewIsExpanded &&
          <div>
            {this.props.subtitle || 'You do not currently have an active Carbly subscription, and most features have been disabled.'}
            {
              SessionStore.isAccountOwner() ?
              <a href="#learn-more" onClick={(e) => {
                e.preventDefault()
                this.setState({ compactViewIsExpanded: true })
              }}>{shouldShowOfferingsPicker ? `${this.props.callToAction || ' Get started with a subscription'}` : ' Learn more...'}</a> :
              <div style={{fontWeight: 'bold'}}>Contact the account owner to make changes</div>
            }
            
          </div>
        }


        {
          Boolean(shouldShowReactivation && (!showCompact || compactViewIsExpanded)) &&
          <div>
          <AccountReactivation />
          </div>
        }

        {
          Boolean(shouldShowOfferingsPicker && (!showCompact || compactViewIsExpanded)) &&
          <div>
            
            {
              SessionStore.isAccountOwner() ?
              <>
                <div style={{margin: '10px 0'}}>Choose the data providers you would like to add to your subscription to get started. <a href="https://getcarbly.com" target="_blank" rel="noopener noreferrer">Lean more about what Carbly can do for you</a>.</div>

                <OfferingsPicker
                  offerings={this.state.availableOfferings}
                  accountDetails={this.state.accountDetails}
                  onOfferingsAdded={() => {
                    window.location.reload()
                  }}
                  useTotalPrice={true} />
              </>
               :
              <div style={{fontWeight: 'bold'}}>Contact the account owner to make changes</div>
            }
            
          </div>
        }
      </div>
    )    

  }
}
