import { useCallback, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import { API, Hub } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { addTypenameToDocument, removeDirectivesFromDocument } from '@apollo/client/utilities'
import { ON_VENUE_LISTING_CHANGED_PRIVATE, ON_VENUE_LISTING_CHANGED_PUBLIC } from '../../../api/bidding/subscriptions'
import { GET_VENUE_LISTING } from '../../../api/bidding/queries'
import {
  UPDATE_VENUE_LISTING_PUBLIC_CACHE,
  UPDATE_VENUE_LISTING_PRIVATE_CACHE,
  UPDATE_VENUE_LISTING_MY_ACTIVE_BID_CACHE,
} from '../../../api/bidding/cache'
import useHasLinkedAuctionEdge from './useHasLinkedAuctionEdge'
import getBiddingToken from '../functions/getBiddingToken'
import useGetListings from './useGetListings'

// Amplify issue
// https://github.com/aws-amplify/amplify-js/issues/8755

const publicQuery = addTypenameToDocument(ON_VENUE_LISTING_CHANGED_PUBLIC)
const privateQuery = addTypenameToDocument(
  removeDirectivesFromDocument([{ name: 'client', remove: true }],
  ON_VENUE_LISTING_CHANGED_PRIVATE)
)

const readPrevListing = (client, id) => {
  return client.readQuery({
    query: GET_VENUE_LISTING,
    variables: {
      id,
    },
  })
}

const writeListing = (client, query, id, venueListing) => {
  return client.writeQuery({
    query,
    data: {
      venueListing,
    },
    variables: {
      id,
    },
  })
}

const onDataPublic = ({ client, data: { data: { onVenueListingChangedPublic: data }} }) => {
  const { id, updatedAt } = data
  const prevData = readPrevListing(client, id)

  if (!prevData) return

  const { venueListing: { updatedAt: prevUpdatedAt } } = prevData

  if (updatedAt < prevUpdatedAt) return

  writeListing(client, UPDATE_VENUE_LISTING_PUBLIC_CACHE, id, data)
}

const onDataPrivate = ({ client, data: { data: { onVenueListingChangedPrivate: data }} }) => {
  const { id, myActiveBid, updatedAt, __typename } = data
  const prevData = readPrevListing(client, id)

  if (!prevData) return

  const { venueListing: { updatedAt: prevUpdatedAt, myActiveBid: prevMyActiveBid } } = prevData

  let nextData = data
  let query = UPDATE_VENUE_LISTING_PRIVATE_CACHE

  if (updatedAt < prevUpdatedAt) {
    const nextReceivedAt = myActiveBid.receivedAt
    const prevReceivedAt = prevMyActiveBid?.receivedAt

    if (!prevReceivedAt || prevReceivedAt <= nextReceivedAt) {
      query = UPDATE_VENUE_LISTING_MY_ACTIVE_BID_CACHE
      nextData = {
        __typename,
        id,
        myActiveBid,
      }
    } else {
      return
    }
  }

  const { myActiveBid: nextMyActiveBid } = nextData

  const isNextMyActiveBidSuccess = nextMyActiveBid.status === 'Placed'

  nextData = {
    ...nextData,
    myFailedBid: isNextMyActiveBidSuccess ? null : nextMyActiveBid,
    myActiveBid: isNextMyActiveBidSuccess ? nextMyActiveBid : prevMyActiveBid,
  }

  writeListing(client, query, id, nextData)
}

export default (isAppBackground = false, { onError }) => {
  const client = useApolloClient()
  const [, { refetchCached }] = useGetListings()
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()
  const prevIsAppBackgroundRef = useRef(isAppBackground)
  const publicSubRef = useRef(null)
  const privateSubRef = useRef(null)

  const subscribe = useCallback((query, authToken, next) => {
    return API.graphql({
      query,
      authToken,
    }).subscribe({
      next: ({ value: data }) => {
        next({ client, data })
      },
      error: ({ error }) => {
        onError(error)
      },
    })
  }, [client, onError])

  const connect = useCallback(async () => {
    const authToken = await getBiddingToken(client, { fetchPolicy: 'network-only' })
    publicSubRef.current = subscribe(publicQuery, authToken, onDataPublic)
    privateSubRef.current = subscribe(privateQuery, authToken, onDataPrivate)
  }, [client, subscribe])

  useEffect(() => {
    let unsubscribeHub
    if (hasLinkedAuctionEdge && !isAppBackground) {
      let prevConnectionState
      let isPendingReconnect = false

      unsubscribeHub = Hub.listen('api', (data) => {
        const { payload } = data
        if (payload.event === CONNECTION_STATE_CHANGE) {
          const connectionState = payload.data.connectionState
          switch(connectionState) {
            case ConnectionState.Disconnected:
              // Must wait until receive Disconnected state before attempting reconnect
              // else Amplify thinks its disconnected (see issue above)
              // Typically a 10 second delay between ConnectionDisrupted and Disconnected
              if (prevConnectionState === ConnectionState.ConnectionDisrupted) {
                console.log('appsync - reconnect')
                isPendingReconnect = true
                connect()
              }
              break
            case ConnectionState.Connected:
              if (isPendingReconnect || prevIsAppBackgroundRef.current || prevConnectionState === ConnectionState.ConnectedPendingNetwork) {
                refetchCached()
              }
              isPendingReconnect = false
              break
          }

          prevConnectionState = connectionState
        }
      })

      connect()
    }

    return () => {
      prevIsAppBackgroundRef.current = isAppBackground
      unsubscribeHub?.()
      privateSubRef.current?.unsubscribe()
      publicSubRef.current?.unsubscribe()
    }
  }, [connect, hasLinkedAuctionEdge, isAppBackground, refetchCached])
}
