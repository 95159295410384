import React from 'react'

export default class DisclosureIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '1em'
    const height = this.props.height || '1em'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-plus-circle" fill={color} style={this.props.style} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    )
  }

}