import { useEffect, useRef } from 'react'

export default (fn, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      fn()
    } else {
      didMount.current = true
    }
  }, deps) // eslint-disable-line
}
