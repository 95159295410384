import React from 'react'
import { Link } from 'react-router-dom'
import AuctionStore from '../../api/stores/AuctionStore'
import SessionStore from '../../api/stores/SessionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import ValuationScopeSelect from '../valuations/ValuationScopeSelect'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import platformFunctions from '../../platformFunctions'
import useHasLinkedAuctionEdge from '../../api/bidding/hooks/useHasLinkedAuctionEdge'
import CalculatorBug from '../common/CalculatorBug'
import AuctionsWatchlistList from './AuctionsWatchlistList'


class AuctionsWatchlistInner extends React.Component {
  state = {
    watchlistVehicles: null,
    sectionedWatchlistListings: null,
    isLoadingVehicles: true,
    isChangingScope: false,
    scope: SessionStore.watchlistFilter,
    watchlistCount: 0,
    auctionsRooftopWatchlistCount: 0
  }

  componentDidMount() {
    AuctionStore.on('auctions_watchlist_received', this.watchlistVehiclesDidLoad)
    AuctionStore.on('global_auction_listing_update', this.watchlistVehiclesDidLoad)
    SessionStore.on('watchlist_scope_change', this.onScopeChange)
    AuctionStore.on('auctions_watchlist_count_change', this.watchlistCountDidChange)
    AuctionsActionCreators.loadAuctionsWatchlistVehicles(SessionStore.watchlistFilter)
    this.watchlistCountDidChange()
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Watchlist")
  }

  componentWillUnmount() {
    AuctionStore.removeListener('auctions_watchlist_received', this.watchlistVehiclesDidLoad)
    AuctionStore.removeListener('global_auction_listing_update', this.watchlistVehiclesDidLoad)
    SessionStore.removeListener('watchlist_scope_change', this.onScopeChange)
    AuctionStore.removeListener('auctions_watchlist_count_change', this.watchlistCountDidChange)
  }

  watchlistVehiclesDidLoad = () => {
    const originalWatchlistListings = AuctionStore.auctionsWatchlist?.original_or_relistings || []
    const runningElsewhereWatchlistListings = AuctionStore.auctionsWatchlist?.running_elsewhere || []

    const restructuredOriginal = AuctionFunctions.restructureWatchlistListings(originalWatchlistListings)
    const restructuredElsewhere = AuctionFunctions.restructureWatchlistListings(runningElsewhereWatchlistListings)

    this.setState({
      watchlistVehicles: restructuredOriginal,
      sectionedWatchlistListings: restructuredOriginal['sectionedListings'],
      sectionedRunningElsewhereListings: restructuredElsewhere['sectionedListings'],
      isLoadingVehicles: false,
      isChangingScope: false
    })
  }

  onListingShow = (listing) => {
    this.props.history.push({
      pathname: `/auctions/listing/${listing.id}`,
      listingsContext: this.state.flattenedWatchlistListings
    })
  }

  onScopeChange = () => {
    this.setState({
      scope: SessionStore.watchlistFilter,
      isChangingScope: true
    })
    AuctionsActionCreators.loadAuctionsWatchlistVehicles(SessionStore.watchlistFilter)
    platformFunctions.logAnalyticsEvent("Auctions", "Watchlist Scope Change")
  }

  onScopeSelect = (value) => {
    SessionStore.setWatchlistFilter(value.value)
  }

  onWatchlistToggle = () => {
    platformFunctions.logAnalyticsEvent("Auctions", "Toggled Watchlist - Watchlist")
  }

  watchlistCountDidChange = () => {
    this.setState({
      watchlistCount: AuctionStore.auctionsWatchlistCount,
      auctionsRooftopWatchlistCount: AuctionStore.auctionsRooftopWatchlistCount
    })
  }


  render() {
    const { isLoadingVehicles, sectionedWatchlistListings, sectionedRunningElsewhereListings, isChangingScope, scope, watchlistCount, auctionsRooftopWatchlistCount } = this.state

    const isEmptyData = (!sectionedWatchlistListings || (sectionedWatchlistListings && sectionedWatchlistListings.length === 0) && (!sectionedRunningElsewhereListings || (sectionedRunningElsewhereListings && sectionedRunningElsewhereListings.length === 0)))

    return (
      <div className="body-content" style={{minHeight: '600px'}}>
        <div className="content-header">
          <h1>Auctions Watchlist</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {/* <div className="content-context-actions">
          <Link to='/auctions/watchlist/archive'>
            <svg className="bi bi-clock" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"/>
              <path fillRule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
          View past watchlist vehicles</Link>
        </div> */}


        <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {
            SessionStore.hasTeam() &&
            <ValuationScopeSelect
              scope={this.state.scope}
              onChange={this.onScopeSelect}
            />
          }

          <div style={{flexGrow: 1, flexShrink: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: '1.1rem'}}>
            {
              watchlistCount > 0 && auctionsRooftopWatchlistCount > 0 &&
              <div>Mine <span style={{fontWeight: 'bold'}}>{watchlistCount}</span></div>
            }
            {
              auctionsRooftopWatchlistCount > 0 &&
              <div style={{marginLeft: '20px'}}>Team <span style={{fontWeight: 'bold'}}>{auctionsRooftopWatchlistCount}</span></div>
            }
          </div>

        </div>


        {
          sectionedWatchlistListings && !isLoadingVehicles && !isChangingScope &&
          <AuctionsWatchlistList
            listings={sectionedWatchlistListings}
            onListingShow={this.onListingShow}
            onWatchlistToggle={this.onWatchlistToggle}
            showRunNumber={true}
            showWatchlistToggle={true}
            showRelisting={true}
            />
        }

        {
          sectionedRunningElsewhereListings?.length > 0 && !isLoadingVehicles && !isChangingScope &&
          <div style={{marginTop: '80px'}}>
            <h2>Returning Vehicles</h2>
            <div className='secondary-text' style={{marginBottom: '10px'}}>These are vehicles that you previously watchlisted and are currently running at another auction again.</div>
            <AuctionsWatchlistList
              listings={sectionedRunningElsewhereListings}
              onListingShow={this.onListingShow}
              onWatchlistToggle={this.onWatchlistToggle}
              showRunNumber={true}
              showWatchlistToggle={true}
              sectionHeaderStyle={{backgroundColor: '#aaa'}}
              />
          </div>
        }


        {
          isEmptyData &&
          <div style={{marginTop: '40px'}}>
            {
              ((scope && scope === '_mine') || !SessionStore.hasTeam()) &&
              <div>You don't have any upcoming vehicles in your watchlist.  To add a vehicle to your watchlist, click the "eye" for the vehicle.</div>
            }
            {
              scope && scope !== '_mine' && SessionStore.hasTeam() &&
              <div>No watchlist vehicles found for this filter.</div>
            }
          </div>
        }

        {
          isLoadingVehicles &&
          <Spinner className="spinner-page" />
        }


      </div>
    )
  }
}

const AuctionsWatchlist = (props) => {
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()
  return (
    <AuctionsWatchlistInner
      {...props}
      hasLinkedAuctionEdge={hasLinkedAuctionEdge}
    />
  )
}

export default AuctionsWatchlist
