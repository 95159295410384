import { gql } from '@apollo/client'
import { VENUE_LISTING_PRIVATE_FIELDS, BIDDER_FIELDS } from './fragments'

// bidding engine (default)
const GET_VENUE_LISTING = gql`
  ${VENUE_LISTING_PRIVATE_FIELDS}
  query VenueListing($id: ID!) {
    venueListing(venueListingId: $id) {
      ...VenueListingPrivateFields
    }
  }
`

const GET_VENUE_LISTINGS = gql`
  ${VENUE_LISTING_PRIVATE_FIELDS}
  query VenueListings($ids: [ID!]) {
    venueListings(venueListingIds: $ids) {
      ...VenueListingPrivateFields
    }
  }
`

// pipeline
const GET_USER = gql`
  ${BIDDER_FIELDS}
  query Me {
    me {
      id
      emailAddress
      firstName
      lastName
      mobileNumber
      phoneNumber
      bidEngineUserInfo {
        bidders {
          ...BidderFields
        }
        token
      }
    }
  }
`

export {
  GET_VENUE_LISTING,
  GET_VENUE_LISTINGS,
  GET_USER,
}
