import React from 'react'
import AppConstants from '../api/constants/AppConstants'
import SessionActionCreators from '../api/actions/SessionActionCreators'

export default class AccountCannotBeReinstated extends React.Component {


  render() {
    return (
      <div className="login-c">
        <div style={{textAlign: 'center', color: '#fff', margin: '0 20px'}}>
          <a href="https://getcarbly.com"><img src="/images/login-logo.png" className="login-logo" alt="Carbly" /></a>
          <h1 style={{fontSize: '2.2rem'}}>Account Not Active</h1>

          <div style={{fontSize: '1.3rem'}}>{this.props.message}</div>

          <div style={{marginTop: '4rem'}}>
            <div>Phone: {AppConstants.phoneNumber}</div>
            <div>Email: <a href={`mailto:${AppConstants.supportEmailAddress}`} target="_blank" rel="noopener noreferrer">{AppConstants.supportEmailAddress}</a></div>
            <div style={{marginTop: '10px'}}><a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout();}}>Logout</a></div>
          </div>

          <div style={{paddingTop: '40px'}}>Visit <a href="https://getcarbly.com">GetCarbly.com</a></div>

        </div>
      </div>
    )
  }


}
