import moment from 'moment'
import SessionStore from '../stores/SessionStore'
import AppConstants from './AppConstants'
import CommonFunctions from './CommonFunctions'
import Formatters from './Formatters'

export default {
  nextListingInListingsContext: function(listing, listingsContext) {
    if (listing && listingsContext) {
      const matchIndex = listingsContext.findIndex((el) => el.id === listing.id)
      if (matchIndex > -1 && matchIndex < listingsContext.length - 1) {
        return listingsContext[matchIndex + 1]
      }
    }
    return null
  },

  previousListingInListingsContext: function(listing, listingsContext) {
    if (listing && listingsContext) {
      const matchIndex = listingsContext.findIndex((el) => el.id === listing.id)
      if (matchIndex > 0) {
        return listingsContext[matchIndex - 1]
      }
    }
    return null
  },

  restructureSaleListings: function(saleListings) {
    var newSectionedListings = []
    for (let listing of saleListings) {
      const laneNumber = listing['auction_lane_number']

      let laneExists = false
      for (let section of newSectionedListings) {
        if (section['title'] === laneNumber) {
          laneExists = true
          section['data'].push(listing)
          break
        }
      }

      if (laneExists === false) {
        newSectionedListings.push({
          title: laneNumber,
          data: [listing]
        })
      }
    }
    return newSectionedListings
  },

  formattedAuctionsByState: function(auctions) {
    var results = []
    var auctionsSearchArray = []
    for (let stateKey of Object.keys(auctions)) {
      const fullName = CommonFunctions.stateAbbreviations()[stateKey]
      if (fullName) {
        // If not fullName, it's Canada
        results.push({
          title: fullName,
          data: auctions[stateKey]
        })
        auctionsSearchArray.push(...auctions[stateKey])
      }
    }
    return({results: results, auctionsSearchArray})
  },

  initializeEmptySavedSearch: function(overrides) {
    let digitalCodes = null
    const lastSelectedDigitalAuctions = JSON.parse(SessionStore.lastSelectedDigitalAuctions)
    if (lastSelectedDigitalAuctions && lastSelectedDigitalAuctions.length === 1 && lastSelectedDigitalAuctions[0] === AppConstants.auctionNoDigitalAuctionsSentinel) {
      digitalCodes = []
    } else {
      if (lastSelectedDigitalAuctions && lastSelectedDigitalAuctions.length > 0) {
        digitalCodes = lastSelectedDigitalAuctions
      } else {
        digitalCodes = Object.keys(AppConstants.auctionOnlineSourceNames)
      }
    }

    var savedSearch = {
      order: 'start_time ASC',
      physical_codes: [],
      digital_codes: digitalCodes,
      condition_max: null,
      condition_min: null,
      include_no_condition: true,
      location_radius: null,
      location_type: 'auctions',
      mileage_max: null,
      mileage_min: null,
      name: '',
      year_max: null,
      year_min: null,
      zip_code: null,
      classification: [],
      wholesale_min: null,
      wholesale_max: null,
      retail_min: null,
      retail_max: null,
      profit_min: null,
      buy_now_min: null,
      buy_now_max: null,
    }
    return { ...savedSearch, ...overrides };
  },

  sanitizeSearchDefaults: function(savedSearch, userAuctionCodes) {
    if (savedSearch.condition_min === null) { savedSearch.condition_min = 0 }
    if (savedSearch.condition_max === null) { savedSearch.condition_max = 5 }
    if (savedSearch.location_radius === null) { savedSearch.location_radius = AppConstants.auctionSearchDefaultDistanceRadius }
    if (savedSearch.include_no_condition === null) { savedSearch.include_no_condition = true }

    if (!savedSearch.physical_codes || Object.keys(savedSearch.physical_codes).length === 0) {
      savedSearch.physical_codes = userAuctionCodes || []
    }
    return savedSearch
  },

  hasAuctionsPlusParams: function(savedSearch) {
    return Boolean(savedSearch.wholesale_min || savedSearch.wholesale_max || savedSearch.retail_min || savedSearch.retail_max || savedSearch.profit_min || savedSearch.buy_now_min || savedSearch.buy_now_max || savedSearch.vin)
  },

  stripAuctionsPlusServerParams: function(savedSearch) {
    // We persist the auctions plus params in the saved search for when they upgrader to auctions plus
    // but we don't want to send them to the server for queries
    var params = {...savedSearch}
    delete params['retail_min']
    delete params['retail_max']
    delete params['wholesale_min']
    delete params['wholesale_max']
    delete params['profit_min']
    delete params['buy_now_min']
    delete params['buy_now_max']
    delete params['vin']

    params.order = this.stripAuctionsPlusSortOrders(params.order)

    return params
  },

  stripAuctionsPlusSortOrders: function(sortOrder) {
    if (sortOrder === 'retail_value_cents DESC' || sortOrder === 'retail_value_cents ASC' || sortOrder === 'profit_value DESC' || sortOrder === 'profit_value ASC' || sortOrder === 'wholesale_value_cents DESC' || sortOrder === 'wholesale_value_cents ASC') {
      return 'start_time ASC'
    }
    return sortOrder
  },

  savedSearchServerParams: function(savedSearch, modifiedName, userAuctionCodes) {
    var params = {...savedSearch}

    // If auctions list is same as user's home auctions, send a null so they're updated whenever
    // the home auctions change
    var hasAuctionsChanged = savedSearch?.physical_codes?.length !== userAuctionCodes?.length
    if (!hasAuctionsChanged) {
      for (let searchAuctionCode of savedSearch.physical_codes) {
        if (!userAuctionCodes.includes(searchAuctionCode)) {
          hasAuctionsChanged = true
          break
        }
      }
    }
    if (!hasAuctionsChanged) {
      params.physical_codes = []
    } else {
      params.physical_codes = savedSearch.physical_codes
    }

    // UUID is specified in the endpoint URI if updating
    delete params['uuid']
    if (params.mileage_min === 0) { params.mileage_min = null }
    if (params.mileage_max === AppConstants.auctionSearchMileageMaxAll) { params.mileage_max = null }

    if (params.condition_min === 0) { params.condition_min = null }
    if (params.condition_max === 5) { params.condition_max = null}

    const modifiedNameTrimmed = modifiedName?.trim()
    params.name = modifiedNameTrimmed?.length > 0 ? modifiedNameTrimmed : params.name.trim()

    return params
  },

  sanitizeClassification: (classification) => {
    let newClassification = classification.slice()

    // Find all with trim
    let trims = classification.filter((el) => el.trim)
    for (let trim of trims) {
      // Remove all with same make and model but without a trim
      newClassification = newClassification.filter((el) => !(el.make === trim.make && el.model === trim.model && !el.trim))
    }

    let models = classification.filter((el) => el.model)
    for (let model of models) {
      // Remove all with same make but without a model
      newClassification = newClassification.filter((el) => !(el.make === model.make && !el.model))
    }

    return newClassification
  },

  globalFiltersServerParams: function(globalFilterSavedSearch) {
    var params = {...globalFilterSavedSearch}
    delete params['physical_codes']
    delete params['location_radius']
    delete params['location_type']
    delete params['zip_code']
    delete params['condition_min']
    delete params['condition_max']
    delete params['include_no_condition']
    delete params['name']

    return params
  },

  shouldShowSimilarVehiclesForProvider: function(provider) {
    if (provider === 'manheim') { return false }
    return true
  },

  savedSearchForSimilarVehicles: function(vehicle) {
    if (vehicle?.year && vehicle?.make && vehicle?.model) {
      let savedSearch = this.initializeEmptySavedSearch()
      savedSearch.year_min = vehicle.year
      savedSearch.year_max = vehicle.year
      savedSearch.classification = [{make: vehicle.make, model: vehicle.model}]
      return savedSearch
    }
    return null
  },

  savedSearchServerQueryFormatter: function(savedSearch) {
    // Remove params not needed for querying
    var formattedQueryParams = {...savedSearch}
    delete formattedQueryParams["uuid"]
    delete formattedQueryParams["name"]

    if (formattedQueryParams.mileage_min === 0) {
      formattedQueryParams.mileage_min = null
    }

    if (formattedQueryParams.mileage_max === AppConstants.auctionSearchMileageMaxAll) {
      formattedQueryParams.mileage_max = null
    }

    if (savedSearch?.physical_codes?.length > 0) {
      formattedQueryParams.physical_codes = savedSearch.physical_codes
    }

    if (formattedQueryParams?.location_radius === AppConstants.auctionSearchMaxDistanceRadius) {
      formattedQueryParams.location_radius = AppConstants.auctionSearcAllAuctionsRadius
    }

    formattedQueryParams.today_count_cutoff_date = CommonFunctions.startOfDayUTC()

    return formattedQueryParams
  },

  reconCostForCRScore: function(crScore) {
    if (crScore === null || crScore === undefined) { return 0 }

    const rooftop = SessionStore.user?.rooftop
    if (rooftop?.vehicle_condition_zero_cost_cents && rooftop.vehicle_condition_zero_cost_cents > 0) {
      const crScoreFloor = Math.floor(crScore)
      const crScoreFraction = crScore - crScoreFloor
      switch (crScoreFloor) {
        case 0:
          return rooftop.vehicle_condition_zero_cost_cents - ((rooftop.vehicle_condition_zero_cost_cents - rooftop.vehicle_condition_one_cost_cents) * crScoreFraction)
        case 1:
          return rooftop.vehicle_condition_one_cost_cents - ((rooftop.vehicle_condition_one_cost_cents - rooftop.vehicle_condition_two_cost_cents) * crScoreFraction)
        case 2:
          return rooftop.vehicle_condition_two_cost_cents - ((rooftop.vehicle_condition_two_cost_cents - rooftop.vehicle_condition_three_cost_cents) * crScoreFraction)
        case 3:
          return rooftop.vehicle_condition_three_cost_cents - ((rooftop.vehicle_condition_three_cost_cents - rooftop.vehicle_condition_four_cost_cents) * crScoreFraction)
        case 4:
          return rooftop.vehicle_condition_four_cost_cents - ((rooftop.vehicle_condition_four_cost_cents - rooftop.vehicle_condition_five_cost_cents) * crScoreFraction)
        case 5:
          return rooftop.vehicle_condition_five_cost_cents
        default:
          return 0
      }
    }

    return 0
  },

  restructureWatchlistListings: function(watchlistListings) {
    var newSectionedListings = []
    var newFlattenedWatchlistListings = []

    for (let sale of watchlistListings) {
      let section = {
        title: sale.auction === AppConstants.auctionOnlineSaleSentinel ? 'Online Auctions' : sale.auction,
        saleDate: sale.start_time,
        isOnline: sale.auction === AppConstants.auctionOnlineSaleSentinel,
        data: []
      }
      if (sale.lanes) {
        for (let lane of sale.lanes) {
          section.data.push({type: 'lane', number: lane.number})

          let listingIndex = 0
          for (let listing of lane.listings) {
            if (listingIndex === lane.listings.length - 1) {
              section.data.push({isLast: true, ...listing})
            } else {
              section.data.push(listing)
            }
            newFlattenedWatchlistListings.push(listing)
            listingIndex ++
          }
        }
      } else if (sale.listings) {
        section.data = sale.listings
        newFlattenedWatchlistListings.push(...sale.listings)
      }
      newSectionedListings.push(section)
    }
    return {sectionedListings: newSectionedListings, flattenedListings: newFlattenedWatchlistListings}
  },

  groupedListingsByDay: function(listings, todayCount) {
    if (!listings) { return null }
    const parsedListings = listings.map(listing => ({
      ...listing,
      created_at: moment(listing.created_at, 'YYYY-MM-DD HH:mm'),
    }));
  
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
  
    const groupedListings = parsedListings.reduce((grouped, listing) => {
      const day = listing.created_at.startOf('day');
      let title;
  
      if (day.isSame(today, 'day')) {
        title = `${todayCount > 0 ? todayCount : ''} Today`;
      } else if (day.isSame(yesterday, 'day')) {
        title = 'Yesterday';
      } else {
        title = day.format('dddd, MMMM Do');
      }
  
      if (!grouped[title]) {
        grouped[title] = { title, data: [] };
      }
      grouped[title].data.push(listing);
      return grouped;
    }, {});
  
    return Object.values(groupedListings);
  },

  nearestAuctions: function(allAuctions, userHomeAuctionCodes, userLongitude, userLatitude, limit = 5) {
    if (!allAuctions || !userLongitude || !userLatitude) { return null }
    var nearestAuctions = []

    for (let auc of allAuctions) {
      if (!userHomeAuctionCodes || (userHomeAuctionCodes && !userHomeAuctionCodes.includes(auc.code))) {
        if (auc.longitude && auc.latitude) {
          auc['distance'] = CommonFunctions.distanceInMilesBetweenCoordinates(auc.latitude, auc.longitude, userLatitude, userLongitude)
          nearestAuctions.push(auc)
        }
      }
    }

    if (nearestAuctions) {
      nearestAuctions = nearestAuctions.sort((a, b) => a.distance - b.distance).slice(0, limit)
    }
    return nearestAuctions
  },

  digitalChannelsDisplayString: function(savedSearch) {
    var digitalChannelsDisplayString = null
    if (savedSearch?.digital_codes?.length > 0) {
      let newDigitalCodes = savedSearch.digital_codes.map((c) => AppConstants.auctionOnlineSourceNames[c])
      newDigitalCodes.sort()
      digitalChannelsDisplayString = newDigitalCodes.join(', ')
      if (savedSearch.location_radius && savedSearch.location_type !== 'distance') {
        if (savedSearch.location_radius === AppConstants.auctionSearchMaxDistanceRadius) {
          digitalChannelsDisplayString = `Including online sales from ${digitalChannelsDisplayString} at all locations nationwide` 
        } else {
          digitalChannelsDisplayString = `Including online sales from ${digitalChannelsDisplayString} within ${Formatters.formatThousands(savedSearch.location_radius)} miles of ${savedSearch.zip_code}`
        }
      }
    }
    return digitalChannelsDisplayString
  },

  crCostsAreSequenced: function(crCosts) {
    // Special case for no CR score cost (index #6)
    for (let i = 1; i < crCosts.length; i++) {
      if (i < 6 && parseInt(crCosts[i]) > parseInt(crCosts[i - 1])) {
        return false
      }
    }
    return true
  },

  makes: function() {
    return [
      'AMC',
      'Acura',
      'Alfa Romeo',
      'Ariel',
      'Audi',
      'Bentley',
      'BMW',
      'Bugatti',
      'Buick',
      'Cadillac',
      'Chevrolet',
      'Chrysler',
      'Daewoo',
      'Daihatsu',
      'Datsun',
      'DeTomaso',
      'Dodge',
      'Eagle',
      'Ferrari',
      'Fiat',
      'Fisker',
      'Ford',
      'Freightliner',
      'Genesis',
      'Geo',
      'GMC',
      'Holden',
      'Honda',
      'Hummer',
      'Hyundai',
      'Icon',
      'Infiniti',
      'Isuzu',
      'Jaguar',
      'Jeep',
      'Kia',
      'Lamborghini',
      'Lancia',
      'Land Rover',
      'Lexus',
      'Lincoln',
      'Maserati',
      'Maybach',
      'Mazda',
      'McLaren',
      'Mercedes-Benz',
      'Mercury',
      'MINI',
      'Mitsubishi',
      'Morgan',
      'Morris',
      'Nissan',
      'Noble',
      'Oldsmobile',
      'Opel',
      'Panoz',
      'Pantera',
      'Peugeot',
      'Plymouth',
      'Pontiac',
      'Porsche',
      'Ram',
      'Reliant',
      'Renault',
      'Rolls-Royce',
      'Saab',
      'Saturn',
      'Scion',
      'Smart',
      'Sprinter',
      'Spyker',
      'Sterling',
      'Studebaker',
      'Subaru',
      'Suzuki',
      'Tesla',
      'Toyota',
      'Triumph',
      'TVR',
      'Ural',
      'Volkswagen',
      'Volvo',
      'Wagoneer',
      'Willys',
      'Yugo' ]
  }
}
