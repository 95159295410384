import ConnectivityStore from '../stores/ConnectivityStore'
var keyMirror = require('keymirror')

function APIEndpoints() {
  let APIRoot = ConnectivityStore.getApiRoot()
  let APIRootVersioned = APIRoot + "/v6"

  return {
    APIRoot:                        APIRoot,
    LOGIN:                          APIRootVersioned + "/auth/sign_in/",
    USER:                           APIRootVersioned + "/users/me/",
    HEARTBEAT:                      APIRootVersioned + "/users/heartbeat/",
    RECLAIM_CONTROL:                APIRootVersioned + "/users/reclaim_control/",
    RELINQUISH_CONTROL:             APIRootVersioned + "/users/relinquish_control/",
    USERS:                          APIRootVersioned + "/users/",
    CARFAX_CONNECT_AUTHORIZE_URL:   APIRootVersioned + "/users/carfax_connect_authorize_url/",
    CARFAX_CONNECT_LOGOUT:          APIRootVersioned + "/users/carfax_connect_logout/",
    ROOFTOPS:                       APIRootVersioned + "/rooftops/",
    ACCOUNTS:                       APIRootVersioned + "/accounts/",
    ACCOUNT_MANAGEMENT:             APIRootVersioned + "/account_management/",
    OFFERING_SLATES:                APIRootVersioned + "/offering_slates/",
    AVAILABLE_OFFERINGS:            APIRootVersioned + "/available_offerings/",
    VEHICLES_HOME:                  APIRootVersioned + "/vehicles/home/",
    FOLDERS:                        APIRootVersioned + "/vehicle_folders/",
    FOLDERS_ORDER:                  APIRootVersioned + "/vehicle_folders/order/",
    VALUATIONS:                     APIRootVersioned + "/vehicles/",
    VALUATION_PREVIEW:              APIRootVersioned + "/vehicles/valuation_preview/",
    SHOW_VALUATION:                 APIRootVersioned + "/vehicles/",
    RECENT_VALUATIONS:              APIRootVersioned + "/vehicles/",
    VALUATIONS_CORPUS:              APIRootVersioned + "/vehicles/filter_corpus/",
    VEHICLES:                       APIRootVersioned + "/vehicles/",
    VEHICLE_PHOTOS:                 APIRootVersioned + "/vehicle_photos/",
    PMR_SAMPLES:                    APIRootVersioned + "/samples/uid/pmr/",
    PMR_SAMPLE_DETAIL:              APIRootVersioned + "/samples/pmr/",
    LLM_SAMPLE_DETAIL:              APIRootVersioned + "/samples/universe/",
    LLM_TRIM_SAMPLES:               APIRootVersioned + "/samples/uid/universe/",
    AVAILABLE_YEARS:                APIRootVersioned + "/values/years/",
    AVAILABLE_MAKES_MODELS:         APIRootVersioned + "/values/years/",
    AUCTIONS_SALES:                 APIRootVersioned + "/runlists/sales/",
    AUCTIONS_BY_STATE:              APIRootVersioned + "/runlists/auctions/by_state",
    AUCTION_NEXT_SALES:             APIRootVersioned + "/runlists/sales/by_auction/",
    AUCTION_LISTING:                APIRootVersioned + "/runlists/listings/",
    AUCTIONS_SEARCH:                APIRootVersioned + "/runlists/listings/search",
    AUCTIONS_WATCHLIST:             APIRootVersioned + "/watched_vehicles/",
    AUCTIONS_FOR_VIN:               APIRootVersioned + "/runlists/listings/by_vin/",
    DECODES:                        APIRootVersioned + "/vin_decodes/",
    VEHICLE_SHARES:                 APIRootVersioned + "/vehicle_shares/",
    VEHICLE_ACTIVITIES:             APIRootVersioned + "/vehicle_activities/",
    RETAIL_LISTING:                 APIRootVersioned + "/apollo/vehicles/",
    EDGE_AUTHORIZE_URL:             APIRootVersioned + "/users/edge_authorize_url/",
    REFRESH_AUCTION_EDGE_TOKEN:     APIRootVersioned + "/users/refresh_auction_edge_token/",
    DOT_AUCTION_EVENTS:             APIRootVersioned + "/runlists/listings/live_bidding",
    LOGOUT_AUCTION_EDGE:            APIRootVersioned + "/users/auction_edge_logout",
    LISTING_BID_AUDITS:             APIRootVersioned + "/audits/listing_bids",
    SAVED_SEARCHES:                 APIRootVersioned + "/runlists/listing_searches/",
    UTILITIES:                      APIRootVersioned + "/utilities/",
    REGIONAL_REPORT:                APIRootVersioned + "/reports/regional",
    REGIONAL_LISTINGS:              APIRootVersioned + "/reports/regional/listings",
    REGIONAL_MODEL_STATS:           APIRootVersioned + "/reports/regional/model_stats",
    METRICS:                        APIRootVersioned + "/metrics/",
    VDS_CORPUS:                     APIRootVersioned + "/values/corpus/",
    APOLLO_CAMPAIGN_LOGIN:          APIRootVersioned + "/callbacks/auctions_plus/",
    PARTNER_ORIGIN_REGISTER:        APIRootVersioned + "/callbacks/partner_origin/",
  }
}

export default {
  APIEndpoints: APIEndpoints,

  vehicleScopeMine: '_mine',
  vehicleScopeAll: '_rooftop',
  orderByCreated: 'created',
  orderByUpdated: 'updated',
  orderByViewed: 'viewed',

  ListResponseTypes: keyMirror({
    ALL: null,
    CUTOFF: null,
  }),

  ActionTypes: keyMirror({
    LOGIN_REQUEST: null,
    LOGIN_RESPONSE: null,
    LOGOUT: null,
    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_RECEIVED: null,
    SET_PASSWORD_REQUEST: null,
    SET_PASSWORD_RECEIVED: null,

    UNAUTHORIZED_USER: null,
    INVALID_API_VERSION: null,
    SERVER_MAINTENANCE_MODE: null,

    USER_REFRESH_REQUEST: null,
    USER_REFRESH_RECEIVED: null,

    HEARTBEAT_REQUEST: null,
    HEARTBEAT_RECEIVED: null,
    RECLAIM_CONTROL_REQUEST: null,
    RECLAIM_CONTROL_RECEIVED: null,
    RELINQUISH_CONTROL_REQUEST: null,

    CHECK_NEWS: null,
    NEWS_RECEIVED: null,

    UPDATE_USER: null,
    USER_UPDATED: null,

    UPDATE_ROOFTOP: null,
    ROOFTOP_UPDATED: null,

    UPDATE_USER_AUCTION_FILTERS: null,
    USER_AUCTION_FILTERS_UPDATED: null,


    LOAD_ACCOUNT_DETAILS: null,
    ACCOUNT_DETAILS_RECEIVED: null,

    LOAD_AVAILABLE_OFFERINGS: null,
    AVAILABLE_OFFERINGS_RECEIVED: null,

    LOAD_PRICING_FOR_OFFERINGS: null,
    PRICING_FOR_OFFERINGS_RECEIVED: null,

    ADD_OFFERINGS: null,
    OFFERINGS_ADDED: null,

    LOAD_OFFERINGS_SLATE: null,
    OFFERINGS_SLATE_RECEIVED: null,

    REACTIVATE_ACCOUNT: null,
    REACTIVATE_ACCOUNT_RECEIVED: null,

    UPDATE_BILLING_INTERVAL: null,
    UPDATE_BILLING_INTERVAL_RECEIVED: null,

    UPDATE_CC: null,
    CC_UPDATED: null,

    RETRY_CC: null,
    CC_RETRIED: null,

    USER_UPDATE_CARFAX_CREDENTIALS: null,
    USER_CARFAX_CREDENTIALS_UPDATED: null,

    USER_REMOVE_CARFAX_CREDENTIALS: null,
    USER_CARFAX_CREDENTIALS_REMOVED: null,

    FETCH_CARFAX_AUTHORIZE_URL: null,
    CARFAX_AUTHORIZE_URL_RECEIVED: null,

    USER_LOGOUT_CARFAX_CONNECT: null,
    USER_LOGOUT_CARFAX_CONNECT_RECEIVED: null,

    LOAD_CARFAX_SUMMARY: null,
    RECEIVED_CARFAX_SUMMARY: null,

    LOAD_VINTEL_REPORT: null,
    RECEIVED_VINTEL_REPORT: null,

    LOAD_AUTOCHECK_SUMMARY: null,
    RECEIVED_AUTOCHECK_SUMMARY: null,

    USER_UPDATE_AUTOCHECK_CREDENTIALS: null,
    USER_AUTOCHECK_CREDENTIALS_UPDATED: null,
    USER_REMOVE_AUTOCHECK_CREDENTIALS: null,
    USER_AUTOCHECK_CREDENTIALS_REMOVED: null,

    ADD_USER: null,
    USER_ADDED: null,

    LOAD_ADDITIONAL_USER_PRICING: null,
    ADDITIONAL_USER_PRICING_RECEIVED: null,

    LOAD_AUTOCHECK_REPORT: null,
    RECEIVED_AUTOCHECK_REPORT: null,

    LOAD_MILEAGE_FAMILY: null,
    RECEIVED_MILEAGE_FAMILY: null,

    TAB_SELECTION_CHANGED: null,

    LOAD_VALUATION_PREVIEW: null,
    RECEIVED_VALUATION_PREVIEW: null,

    RECEIVED_PREVIEW_FOR_VEHICLE: null,

    CREATE_VALUATION: null,

    PUBLIC_VALUATION: null,

    UPDATE_VALUATION: null,
    RECEIVED_UPDATE_VALUATION: null,

    UPDATE_VALUATION_CHOICE: null,
    RECEIVED_UPDATE_VALUATION_CHOICE: null,

    DELETE_VALUATION: null,
    RECEIVED_DELETE_VALUATION: null,

    LOAD_VALUATION: null,
    RECEIVED_VALUATION: null,
    RECEIVED_EXISTING_VALUATION: null,

    RECEIVED_PUBLIC_VALUATION: null,

    LOAD_LIMITED_PROVIDERS_VALUATION: null,

    UPDATE_VALUATION_CHOICES: null,
    RECEIVED_UPDATE_VALUATION_CHOICES: null,

    CREATE_VALUATION_NOTE: null,
    VALUATION_NOTE_CREATED: null,

    LOAD_VALUATION_NOTES: null,
    RECEIVED_VALUATION_NOTES: null,

    LOAD_PMR_SAMPLE: null,
    RECEIVED_PMR_SAMPLE: null,

    LOAD_ALL_PMR_SAMPLES: null,
    RECEIVED_ALL_PMR_SAMPLES: null,

    LINK_VALUATION_PARTNER: null,
    RECEIVED_LINKED_VALUATION_PARTNER: null,

    LOAD_VEHICLES_HOME: null,
    RECEIVED_VEHICLES_HOME: null,

    CREATE_FOLDER: null,
    FOLDER_CREATED: null,

    UPDATE_FOLDER: null,
    FOLDER_UPDATED: null,

    UPDATE_FOLDERS_ORDER: null,
    FOLDERS_ORDER_UPDATED: null,

    DELETE_FOLDER: null,
    FOLDER_DELETED: null,

    LOAD_FOLDER_INFO: null,
    RECEIVED_FOLDER_INFO: null,

    LOAD_FOLDERS: null,
    RECEIVED_FOLDERS: null,

    LOAD_RECENT_VALUATIONS: null,
    RECEIVED_RECENT_VALUATIONS: null,

    LOAD_VALUATIONS_CORPUS: null,
    RECEIVED_VALUATIONS_CORPUS: null,

    VALUATIONS_SEARCH: null,
    RECEIVED_VALUATIONS_SEARCH: null,

    LOAD_AVAILABLE_YEARS: null,
    RECEIVED_AVAILABLE_YEARS: null,

    LOAD_AVAILABLE_MAKES_MODELS: null,
    RECEIVED_AVAILABLE_MAKES_MODELS: null,

    SEARCH_AUCTION_VEHICLES: null,
    AUCTION_VEHICLES_SEARCH_RECEIVED: null,

    LOAD_AUCTIONS_BY_STATE: null,
    AUCTIONS_BY_STATE_RECEIVED: null,

    LOAD_AUCTION_LISTING: null,
    AUCTION_LISTING_RECEIVED: null,

    PREFETCH_AUCTION_LISTING: null,
    AUCTION_LISTING_PREFETCH_RECEIVED: null,

    LOAD_NEXT_AUCTION_SALES: null,
    NEXT_AUCTION_SALES_RECEIVED: null,

    LOAD_AUCTION_LISTINGS_FOR_LANE: null,
    AUCTION_LISTINGS_FOR_LANE_RECEIVED: null,

    LOAD_AUCTION_LISTINGS_FOR_SALE: null,
    AUCTION_LISTINGS_FOR_SALE_RECEIVED: null,

    UPDATE_AUCTION_LISTING: null,
    AUCTION_LISTING_UPDATED: null,

    LOAD_AUCTION_WATCHLIST: null,
    AUCTION_WATCHLIST_RECEIVED: null,

    LOAD_AUCTION_WATCHLIST_ARCHIVE: null,
    AUCTION_WATCHLIST_ARCHIVE_RECEIVED: null,

    LOAD_AUCTION_WATCHLIST_VEHICLE_COUNTS: null,
    AUCTION_WATCHLIST_VEHICLE_COUNTS_RECEIVED: null,

    WATCHLIST_VIN: null,
    VIN_WATCHLISTED: null,

    CHECK_AUCTIONS_FOR_VIN: null,
    AUCTIONS_FOR_VIN_RECEIVED: null,

    CREATE_VALUATION_SHARE: null,
    VALUATION_SHARE_CREATED: null,

    LOAD_SHARED_VALUATION: null,
    RECEIVED_SHARED_VALUATION: null,

    UPDATE_PHOTO: null,
    PHOTO_UPDATED: null,

    DELETE_PHOTO: null,
    PHOTO_DELETED: null,

    LOAD_VEHICLE_ACTIVITIES: null,
    RECEIVED_VEHICLE_ACTIVITIES: null,

    LOAD_LLM_SAMPLE_DETAIL: null,
    RECEIVED_LLM_SAMPLE_DETAIL: null,

    LOAD_LLM_TRIM_SAMPLES: null,
    RECEIVED_LLM_TRIM_SAMPLES: null,

    LOAD_MY_LOT: null,
    RECEIVED_MY_LOT: null,

    LOAD_MY_LOT_PAST: null,
    RECEIVED_MY_LOT_PAST: null,

    LOAD_MY_LOT_ANALYTICS: null,
    RECEIVED_MY_LOT_ANALYTICS: null,

    LOAD_REGIONAL_REPORT: null,
    RECEIVED_REGIONAL_REPORT: null,

    LOAD_REGIONAL_LISTINGS: null,
    RECEIVED_REGIONAL_LISTINGS: null,

    LOAD_REGIONAL_MODEL_STATS: null,
    RECEIVED_REGIONAL_MODEL_STATS: null,

    LOAD_MOST_RECENT_RETAIL_LISTING: null,
    RECEIVED_MOST_RECENT_RETAIL_LISTING: null,

    LOAD_VEHICLE_REPORT: null,
    RECEIVED_VEHICLE_REPORT: null,

    PURCHASE_VEHICLE_REPORT: null,
    VEHICLE_REPORT_PURCHASED: null,

    UPDATE_PERMISSIONS: null,
    PERMISSIONS_UPDATED: null,

    LOAD_EDGE_AUTHORIZE_URL: null,
    EDGE_AUTHORIZE_URL_RECEIVED: null,

    LOAD_DOT_AUCTION_EVENTS: null,
    DOT_AUCTION_EVENTS_RECEIVED: null,

    LOAD_AUCTION_LIVE_SALES_STATUS: null,
    AUCTION_LIVE_SALES_STATUS_RECEIVED: null,

    LOAD_AUCTION_LISTING_BIDS: null,
    AUCTION_LISTING_BIDS_RECEIVED: null,

    LOGOUT_AUCTION_EDGE: null,
    LOGOUT_AUCTION_EDGE_RECEIVED: null,

    SUBMIT_LISTING_BID_AUDIT: null,

    LOAD_SAVED_SEARCHES: null,
    SAVED_SEARCHES_RECEIVED: null,

    LOAD_SAVED_SEARCH: null,
    SAVED_SEARCH_RECEIVED: null,

    CREATE_SAVED_SEARCH: null,
    UPDATE_SAVED_SEARCH: null,

    UPDATE_GLOBAL_SAVED_SEARCH: null,
    GLOBAL_SAVED_SEARCH_UPDATED: null,

    DELETE_SAVED_SEARCH: null,
    SAVED_SEARCH_DELETED: null,

    TOGGLE_USER_GLOBAL_AUCTION_FILTERS: null,
    TOGGLE_USER_GLOBAL_AUCTION_FILTERS_UPDATED: null,

    LOAD_COORDINATES_FOR_ZIPCODE: null,
    COORDINATES_FOR_ZIPCODE_RECEIVED: null,

    CREATE_METRIC: null,

    LOAD_VDS_BASE_CORPUS: null,
    VDS_BASE_CORPUS_RECEIVED: null,

    LOAD_VDS_MAKES_CORPUS: null,
    VDS_MAKES_CORPUS_RECEIVED: null,

    LOGIN_WITH_APOLLO_CAMPAIGN_CONTACT_ID: null,
    RECEIVED_APOLLO_CAMPAIGN_CONTACT_ID: null,

    VERIFY_APOLLO_CAMPAIGN_CONTACT_ID: null,
    VERIFIED_APOLLO_CAMPAIGN_CONTACT_ID: null,

    REGISTER_WITH_PARTNER_ORIGIN_CODE: null,
    RECEIVED_REGISTER_WITH_PARTNER_ORIGIN_CODE: null,
  }),

  CARFAX_CONNECT_LOGOUT_URL: 'https://auth.carfax.com/v2/logout',
}
