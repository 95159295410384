import React from 'react'
import { Link } from 'react-router-dom'
import DOMPurify from "dompurify"
import ConnectivityStore from '../../api/stores/ConnectivityStore'
import platformFunctions from '../../platformFunctions'

export default class SettingsNews extends React.Component {
  state = {
    isLoadingContent: true,
    newsBody: null
  }

  componentDidMount() {
    var d = new Date()
    var n = d.toString()
    fetch(`${ConnectivityStore.getNewsHTMLUrl()}?ts=${n}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/html'
      }
    })
    .then(response => {
      return Promise.all([response.text()])
    })
    .then(([body]) => {
      const sanitizedBody = DOMPurify.sanitize(body, {
        ALLOWED_TAGS: ["div", "p", "span", "img", "a", "ul", "ol", "li", "h1", "h2", "h3", "h4", "br"],
        ALLOWED_ATTR: ["style", "href", "src", "id", "class", "target", "rel"],
      })
      
      this.setState({ newsBody: sanitizedBody})
    })
    .catch((error) => {
      console.log(error)
    })

    platformFunctions.logAnalyticsEvent("User", "Opened News")
  }
  
  render() {
    const { newsBody, isLoadingContent } = this.state
    
    return (
      <div className="body-content settings-content-c">
        <div className="content-header">
          <Link to='/settings/'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
            Back to Settings
          </Link>
        </div>
        <div style={{flex: 1, height: '100%'}}>
          {
            isLoadingContent && newsBody &&
            <div className="app-news" dangerouslySetInnerHTML={{__html: newsBody}}></div>
          }
        </div>

      </div>
    )
    
  }
    

}