import React from 'react'
import Select from 'react-select'
import MakeModelSelectorStore from '../../api/stores/MakeModelSelectorStore'
import MakeModelSelectorActionCreators from '../../api/actions/MakeModelSelectorActionCreators'
import Spinner from '../common/Spinner'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import AppConstants from '../../api/constants/AppConstants'

export default class MakeModelPicker extends React.Component {

  state = {
    yearsAreLoading: true,
    makeModelsAreLoading: true,
    selectedYearId: null,
    selectedMakeId: null,
    selectedModelId: null,
    selectedSeriesId: null,
    selectedStyleId: null,
    availableYears: MakeModelSelectorStore.availableYears(),
    availableMakesModels: [],
  }

  componentDidMount() {
    MakeModelSelectorStore.on("years_did_change", this.yearsDidLoad)
    MakeModelSelectorStore.on("makes_models_did_change", this.makesModelsDidLoad)
    if (this.state.availableYears.length === 0) {
      MakeModelSelectorActionCreators.loadYears()
    } else {
      this.setState({
        yearsAreLoading: false
      })
    }
  }

  componentWillUnmount() {
    MakeModelSelectorStore.removeListener("years_did_change", this.yearsDidLoad)
    MakeModelSelectorStore.removeListener("makes_models_did_change", this.makesModelsDidLoad)
  }

  yearsDidLoad = () => {
    this.setState({
      availableYears: MakeModelSelectorStore.availableYears(),
      yearsAreLoading: false
    })
  }

  onYearSelect = (selectedOption) => {
    const year = selectedOption ? selectedOption.value : null
    this.setState({
      selectedYearId: year,
      availableMakesModels: [],
      selectedMakeId: null,
      selectedModelId: null,
      selectedSeriesId: null,
      selectedStyleId: null,
      makeModelsAreLoading: true
    })
    
    if (year) {
      MakeModelSelectorActionCreators.loadMakesModels(year)
    }

    this.selectionDidChange()
  }

  makesModelsDidLoad = () => {
    const makesModels = MakeModelSelectorStore.availableMakesModels(this.state.selectedYearId)

    if (Object.keys(makesModels).length > 0) {
      this.setState({
        availableMakesModels: makesModels,
        makeModelsAreLoading: false
      })
    }
  }

  onMakeSelect = (make) => {
    const { availableMakesModels } = this.state

    this.setState({
      selectedMakeId: make.value,
      selectedModelId: null,
      selectedSeriesId: null,
      selectedStyleId: null
    })

    const modelChoices = Object.keys(ValuationFunctions.drilldownModelChoices(availableMakesModels, make.value))
    if (modelChoices.length === 1) { setTimeout(() => this.onModelSelect({value: modelChoices[0]}), 100) }
    setTimeout(() => { this.selectionDidChange() }, 150)
  }

  onModelSelect = (model) => {
    const { availableMakesModels, selectedMakeId } = this.state

    this.setState({
      selectedModelId: model.value,
      selectedSeriesId: null,
      selectedStyleId: null
    })

    const seriesChoices = Object.keys(ValuationFunctions.drilldownSeriesChoices(availableMakesModels, selectedMakeId, model.value))

    //  Next level down, series, might only have 1 choice, proactively select it
    if (seriesChoices.length === 1 && seriesChoices[0] === '') {
      this.setState({selectedSeriesId: AppConstants.emptySeriesValue})
    } else if (seriesChoices.length === 1) {
      setTimeout(() => this.onSeriesSelect({value: seriesChoices[0]}), 100)
    }
    setTimeout(() => { this.selectionDidChange() }, 150)
  }

  onSeriesSelect = (series) => {
    const { availableMakesModels, selectedMakeId, selectedModelId } = this.state

    this.setState({
      selectedSeriesId: series.value,
      selectedStyleId: null
    })

    const styleChoices = ValuationFunctions.drilldownStyleChoices(availableMakesModels, selectedMakeId, selectedModelId, series.value)
    if (styleChoices.length === 1) { setTimeout(() => this.onStyleSelect({value: styleChoices[0]}), 100) }
    setTimeout(() => { this.selectionDidChange() }, 150)
  }

  onStyleSelect = (style) => {
    this.setState({selectedStyleId: style.value})
    setTimeout(() => { this.selectionDidChange() }, 150)
  }

  selectionDidChange = () => {
    const { selectedYearId, selectedMakeId, selectedModelId, selectedSeriesId, selectedStyleId } = this.state

    const hashedDrilldown = {
      selectedYearId: selectedYearId,
      selectedMakeId: selectedMakeId,
      selectedModelId: selectedModelId,
      selectedSeriesId: selectedSeriesId,
      selectedStyleId: selectedStyleId
    }

    if (ValuationFunctions.isDrilldownComplete(hashedDrilldown) === true) {
      this.props.onDrilldownComplete(hashedDrilldown)
    } else {
      this.props.onDrilldownComplete(null)
    }

  }

  render() {
    const { availableYears, selectedYearId, availableMakesModels, selectedMakeId, selectedModelId, selectedSeriesId, selectedStyleId, yearsAreLoading, makeModelsAreLoading } = this.state
    const selectedModels = ValuationFunctions.drilldownModelChoices(availableMakesModels, selectedMakeId)
    const selectedSerieses = ValuationFunctions.drilldownSeriesChoices(availableMakesModels, selectedMakeId, selectedModelId)
    const selectedStyles = ValuationFunctions.drilldownStyleChoices(availableMakesModels, selectedMakeId, selectedModelId, selectedSeriesId)

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #aaa',
        padding: '10px'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        marginBottom: '5px'
      })
    }

    if (yearsAreLoading) {
      return ( <Spinner /> )
    }


    return (
      <div className="drilldown-c">

          <Select
            styles={customStyles}
            name="year"
            value={selectedYearId ? {value: selectedYearId, label: selectedYearId} : null}
            onChange={this.onYearSelect}
            placeholder='Select year...'
            options={
              availableYears.map((year) => {
                return {value: year, label: year}
              })
            }
          />

          {
            selectedYearId && makeModelsAreLoading &&
            <div>
              <Spinner className='spinner-medium' />
            </div>
          }
          {
            selectedYearId && !makeModelsAreLoading &&
            <div>
              <Select
                styles={customStyles}
                name="make"
                value={selectedMakeId ? {value: selectedMakeId, label: selectedMakeId} : null}
                onChange={this.onMakeSelect}
                placeholder='Make...'
                options={
                  Object.keys(availableMakesModels).map(function (key) { return {value: key, label: key} })
                }
              />
              {
                selectedMakeId &&
                <Select
                styles={customStyles}
                  name="model"
                  value={selectedModelId ? {value: selectedModelId, label: selectedModelId} : null}
                  onChange={this.onModelSelect}
                  placeholder='Model...'
                  options={
                    Object.keys(selectedModels).map(function (key) { return {value: key, label: key} })
                  }
                />
              }

              {
                selectedModelId &&
                <Select
                  styles={customStyles}
                  name="series"
                  value={selectedSeriesId ? {value: selectedSeriesId, label: selectedSeriesId === AppConstants.emptySeriesValue ? 'None' : selectedSeriesId} : null}
                  onChange={this.onSeriesSelect}
                  placeholder='Series...'
                  options={
                    Object.keys(selectedSerieses).map(function (key) {
                      if (selectedSeriesId === AppConstants.emptySeriesValue) {
                        return {value: AppConstants.emptySeriesValue, label: 'None'}
                      }
                      return {value: key, label: key === '' ? 'No series' : key}
                    })
                  }
                />
              }

              {
                selectedSeriesId &&
                <Select
                  styles={customStyles}
                  name="style"
                  value={selectedStyleId ? {value: selectedStyleId, label: selectedStyleId} : null}
                  onChange={this.onStyleSelect}
                  placeholder='Style...'
                  clearable={false}
                  options={
                    selectedStyles.map(function (key) { return {value: key, label: key} })
                  }
                />
              }

            </div>
          }

      </div>
    )
  }
}
