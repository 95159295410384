import React, { useMemo } from 'react'
import { Input } from 'reactstrap'
import { IMaskMixin } from 'react-imask'

const maskDefaults = {
  mask: Number,
  thousandsSeparator: ',',
}

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
  return (
    <Input
      {...props}
      innerRef={inputRef}
      type='text'
      className='field'
      autoComplete='off'
    />
  )
})

const CurrencyInputField = ({ value: propsValue, onChange, innerRef, style, acceptCents = false, max = 999999, ...rest }) => {
  const value = useMemo(() => {
    return propsValue ? `${propsValue}` : ''
  }, [propsValue])

  const mask = useMemo(() => {
    let _mask = {
      ...maskDefaults,
      max,
    }

    if (acceptCents) {
      _mask = {
        ..._mask,
        radix: '.',
        padFractionalZeros: true,
      }
    }

    return [_mask]
  }, [acceptCents, max])

  return (
    <div style={{flexGrow: 1, flexShink: 1, marginTop: '2px', position: 'relative'}}>
      <MaskedInput
        {...rest}
        style={{ paddingLeft: '20px', ...style }}
        value={value}
        mask={mask}
        inputRef={innerRef}
        onAccept={onChange}
      />
      <div style={{position: 'absolute', top: 0, left: 0, height: '100%', display: 'flex', alignItems: 'center'}}>
        <span className='currency-input-label' style={{fontSize: '1.2rem', margin: '0 5px', marginTop: '-1px', color: '#aaa'}}>
          $
        </span>
      </div>
    </div>
  )
}

export default CurrencyInputField
