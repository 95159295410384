import React from 'react'
import { Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import SessionActionCreators from '../../api/actions/SessionActionCreators'


export default class PartnerLanderRegistrationForm extends React.PureComponent {
  state = {
    isSubmittingUser: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  }

  componentDidMount() {
    SessionStore.on('register_partner_origin_errors', this.handleOriginErrors)
  }

  componentWillUnmount() {
    SessionStore.removeListener('register_partner_origin_errors', this.handleOriginErrors)
  }

  handleOriginErrors = () => {
    this.setState({ isSubmittingUser: false })
  }

  onSubmit = () => {
    if (!this.isSubmitEnabled()) {
      return false
    }

    const { firstName, lastName, phoneNumber, password, email } = this.state
    const { origin } = this.props

    let serverParams = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      password: password
    }
    
    this.setState({ isSubmittingUser: true })
    SessionActionCreators.registerWithPartnerOriginCode(origin, serverParams)
  }

  isSubmitEnabled = () => {
    let isSubmitEnabled = false
    const { firstName, lastName, phoneNumber, password, email } = this.state

    if (firstName.length > 0 && lastName.length > 0 && phoneNumber.length === 10  && password.length >= 8 && CommonFunctions.isValidEmailAddress(email)) {
      isSubmitEnabled = true
    }
    return isSubmitEnabled
  }

  render() {
    const { firstName, lastName, phoneNumber, email, password, isSubmittingUser } = this.state

    const isSubmitEnabled = this.isSubmitEnabled() && !isSubmittingUser

    return (
      <form onSubmit={(e) => {e.preventDefault(); this.onSubmit()}}>
      <div className='split-row' style={{columnGap: '10px'}}>
        <input type="text" placeholder="First Name" value={firstName} onChange={(e) => {
          this.setState({firstName: e.target.value})
        }} />
        <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => {
          this.setState({lastName: e.target.value})
        }} />
      </div>

      <div>
        <input type="email" placeholder="Email address" value={email} onChange={(e) => {
          this.setState({email: e.target.value})
        }}/>
      </div>


      <div className='split-row' style={{columnGap: '10px'}}>
        <input type="tel" maxLength={10} placeholder="Cell number (10 digits)" value={phoneNumber} onChange={(e) => {
          const formattedNumber = CommonFunctions.stripNonNumericNoDecimal(e.target.value)
          this.setState({phoneNumber: formattedNumber})
        }}/>
        <input type="password" placeholder="Password (at least 8)" value={password} onChange={(e) => {
          this.setState({password: e.target.value})
        }}/>
      </div>


      <Button type='submit' color='primary' disabled={!isSubmitEnabled} style={{width: '100%', marginBottom: '20px'}}>Start my trial</Button>
      </form>
    )
}

}

