import React from 'react'
import { Input } from 'reactstrap'
import CommonFunctions from '../../api/constants/CommonFunctions'
import validZipCodes from '../../api/constants/ZipCodes'

export default class ZipCodeEntryField extends React.Component {
  state = {
    zipCode: ''
  }

  componentDidMount() {
    if (this.props.defaultZipCode) {
      this.zipCodeChanged(this.props.defaultZipCode)
    }
  }

  zipCodeChanged = (text) => {
    const sanitizedZipCode = CommonFunctions.stripNonNumericNoDecimal(text)

    var isComplete = false
    var isValid = false
    if (sanitizedZipCode.length === 5) {
      const unpaddedZipCode = parseInt(sanitizedZipCode)
      isComplete = true
      isValid = validZipCodes.zipCodes.includes(unpaddedZipCode)
    }

    this.setState({
      zipCode: sanitizedZipCode,
      isComplete: isComplete,
      isValid: isValid
    })
    this.props.onZipCodeChange(sanitizedZipCode, isValid)
  }

  focus = () => {
    if (this.refs.zipRef) {
      this.refs.zipRef.focus()
    }
  }


  render() {
    const { zipCode, isComplete, isValid } = this.state
    return (
      <input ref='zipRef' type="text" className="field" name="zip" onChange={e => this.zipCodeChanged(e.target.value)} value={zipCode} placeholder={this.props.placeholder} maxLength="5" autoComplete="off" style={{marginBottom: 0, flexGrow: 1, flexShink: 1, marginTop: '2px', color: isComplete && !isValid ? 'red' : 'inherit', ...this.props.style}} />
    )
  }

}
