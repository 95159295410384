import React from 'react'
import moment from 'moment'
import ReloadIcon from './icons/ReloadIcon'

export default class DateFreshnessMonitor extends React.Component {
  state = {
    stalenessMessage: null
  }

  componentDidMount() {
    if (this.props.lastFreshDataTime) {
      this.checkDataFreshness()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lastFreshDataTime !== null && (prevProps.lastFreshDataTime === null || prevProps.lastFreshDataTime === undefined)) {
      this.checkDataFreshness()
    }
  }

  checkDataFreshness = () => {
    const timeoutHours = 4.1
    const timeoutDuration = (60 * timeoutHours)*60*1000

    const lastFreshDataTime = this.props.lastFreshDataTime

    if (lastFreshDataTime) {
      const currentDate = moment()
      if (currentDate.isAfter(moment(lastFreshDataTime.toISOString()).add(24, 'hours'))) {
        this.setState({ stalenessMessage: '24 hours' })
      } else if (currentDate.isAfter(moment(lastFreshDataTime.toISOString()).add(4, 'hours'))) {
        this.setState({ stalenessMessage: 'several hours' })
        setTimeout(() => {this.checkDataFreshness()}, timeoutDuration)
      } else {
        setTimeout(() => {this.checkDataFreshness()}, timeoutDuration)
      }
    }
  }

  render () {
    const stalenessMessage = this.state.stalenessMessage
    if (stalenessMessage === null) { return null }

    return (
      <a href="#refresh" className="stale-data-reload" onClick={(e) => {
        e.preventDefault()
        window.location.reload()
      }}><div>{this.props.label} {stalenessMessage} old</div><ReloadIcon color='#fff' style={{ marginLeft: '10px'}} /></a>
    )
  }
}
