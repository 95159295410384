import React from 'react'

export default class Spinner extends React.PureComponent {
  render() {
    let className = 'spinner'
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }

    // return (
    //   <div class="loader">
    //     <svg viewBox="0 0 80 80">
    //       <circle id="test" cx="40" cy="40" r="32"></circle>
    //     </svg>
    //   </div>
    // )

    return (
      <div className={className} style={this.props.style}><div className="dot1"></div><div className="dot2"></div></div>
    )
  }
}
