import React from 'react'

export default class PlusPlanIcon extends React.PureComponent {
  render() {
    const color = this.props.color || '#2e8bff'
    return (
      <div style={{borderRadius: '4px', border: `2px solid ${color}`, fontSize: '0.7rem', color: color, padding: '2px 4px', display: 'inline-block'}}>PLUS</div>
    )
  }

}
