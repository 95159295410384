import React, { memo, useCallback } from 'react'
import AccountBug from '../components/user/AccountBug'
import CommonFunctions from '../../src/api/constants/CommonFunctions'
import ActivitiesInfiniteScroll from '../components/activities/ActivitiesInfiniteScroll'
import CalculatorBug from '../components/common/CalculatorBug'

const clientID = 'vehicleActivitiesPage'

const VehicleActivitiesPage = ({ history }) => {
  const getActivityIsDisabled = useCallback(activity => {
    return activity.vehicle_is_deleted
  }, [])

  const onActivityClick = useCallback(({ vehicle_uuid: uuid }) => {
    history.push(CommonFunctions.valuationURL({ uuid }))
  }, [history])

  return (
    <div style={{ height: '100%' }}>
      <div className='page-body-c dashboard-body-c'>
        <div className='body-content'>
          <div className='content-header'>
            <h1>Activity</h1>

            <div className="global-tools">
              <AccountBug />
              <CalculatorBug />
            </div>
          </div>
          <div style={{ marginTop: 40 }}>
            <ActivitiesInfiniteScroll
              clientID={clientID}
              onActivityClick={onActivityClick}
              getActivityIsDisabled={getActivityIsDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(VehicleActivitiesPage)
