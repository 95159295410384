import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, CustomInput } from 'reactstrap'
import GlobalCostsEntry from './GlobalCostsEntry'
import Spinner from '../common/Spinner'

export default class GlobalCostsEntryModal extends React.Component {
  state = {
    isSaving: false
  }

  render() {
   const { isSaving } = this.state

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '1000px'}}>
        <ModalHeader className='modal-header'>
            Cost Estimates
        </ModalHeader>
        <ModalBody>

          <div style={{position: 'relative'}}>
            <div style={{opacity: isSaving ? 0 : 1}}>
            <GlobalCostsEntry onSave={() => {
              this.setState({isSaving: true})
            }} />
            </div>
            {
              isSaving &&
              <Spinner style={{position: 'absolute', top: '0%', left: '50%', transform: 'translate(-50%, -50%)'}} />
            }
          </div>
          
          
          <div style={{marginTop: '20px', textAlign: 'right'}}>
            <a href="#cancel" onClick={(e) => {
              e.preventDefault()
              this.props.onCancel()
              }}>Cancel</a>
          </div>
        
        </ModalBody>
      </Modal>
    )
  }
}
