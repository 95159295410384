import React, { useCallback } from 'react'
import AuctionsListingRow from './AuctionsListingRow'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import useHasLinkedAuctionEdge from '../../api/bidding/hooks/useHasLinkedAuctionEdge'
import useLoadDotAuctionItems from '../../api/bidding/hooks/useLoadDotAuctionItems'
import AuctionEdgeAuth from '../common/AuctionEdgeAuth'
import UserActionCreators from '../../api/actions/UserActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import AppConstants from '../../api/constants/AppConstants'
import CalculatorBug from '../common/CalculatorBug'

export default (props) => {
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()

  const storeDataSourceFunc = useCallback(() => (
    SessionStore.auctionListingBids
  ), [])

  const actionCreatorFunction = useCallback(() => (
    UserActionCreators.loadAuctionListingBids()
  ), [])

  const {
    data: events,
    isLoading,
    isEmptyData,
  } = useLoadDotAuctionItems( hasLinkedAuctionEdge, storeDataSourceFunc, SessionStore,  'user_auction_listing_bids_changed', actionCreatorFunction )

  const onWatchlistToggle = useCallback(() => {
    // FIXME do a real thing
  }, [])

  return (
    <div className="body-content">
      <div className="content-header">
        <h1>My Bids</h1>
        <div className="global-tools">
          <AccountBug />
          <CalculatorBug />
        </div>
      </div>

      {
        !hasLinkedAuctionEdge &&
        <div style={{marginTop: '40px', border: '1px solid #ddd', textAlign: 'center', padding: '20px', borderRadius: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
            <img src="/images/edge-cube-logo-large-dark.png" style={{width: '60px', height: '61px', marginRight: '20px'}} />
            <div>Link your {AppConstants.providerNames.auction_edge} account to enable bidding on {AppConstants.auctionOnlineSourceNames.EDGE_DOT_AUCTION} sales.</div>
          </div>
          <AuctionEdgeAuth />
        </div>
      }

      {
        hasLinkedAuctionEdge &&
        <div className="card" style={{marginTop: '20px'}}>
          {
            events && !isLoading && !isEmptyData &&
            <div className="card-list">
              {
                events.map(event => {
                  return (
                    <div key={event.channel}>
                      {
                        event.listings.map(listing => {
                          return (
                            <AuctionsListingRow
                              hasLinkedAuctionEdge
                              listing={listing}
                              showRunNumber={false}
                              showChannel
                              key={listing.id}
                              onWatchlistToggle={onWatchlistToggle}
                            />
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          }

          {
            isEmptyData && !isLoading &&
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
              <div style={{marginTop: '20px'}}>No recent bids</div>
            </div>
          }

          {
            isLoading &&
            <Spinner />
          }
        </div>
      }
    </div>
  )
}
