import React from 'react'
import CommonFunctions from '../../api/constants/CommonFunctions'

const thousandsMileageLabel = "thousand miles"
const onesMileageLabel = "miles"

export default class MileageEntryField extends React.Component {
  state = {
    mileage: ''
  }

  componentDidMount() {
    if (this.props.defaultMileage) {
      let m = this.props.defaultMileage
      if (m >= 1000 && (m % 1000 === 0)) {
        m = m / 1000
      } else if (m < 1000) {
        m = `0${m}`
      }
      this.mileageChanged(`${m}`)
    } else {
      this.setState({ mileage: '' })
    }
  }

  mileageChanged = (text) => {
    var sanitizedMileage = CommonFunctions.sanitizedMileage(text)
    if (sanitizedMileage.split('.').length > 2) { return }
    if (sanitizedMileage === '.') { sanitizedMileage = '' }

    const hasDecimal = sanitizedMileage.split('.').length > 1

    // If the existing state has moved into "ones", no longer "thousands", don't allow a decimal
    const oldVal = this.state.mileage
    if (oldVal && !CommonFunctions.numberIsInThousands(oldVal) && hasDecimal) {
      return
    }

    const isInThousands = CommonFunctions.numberIsInThousands(sanitizedMileage)

    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.font = "17px Helvetica Neue";

    this.setState({
      mileage: sanitizedMileage,
      mileageStringWidth: ctx.measureText(sanitizedMileage).width,
      mileageThousandsLabel: isInThousands ? thousandsMileageLabel : onesMileageLabel
    })

    this.props.onMileageChange(sanitizedMileage)
  }

  focus = () => {
    if (this.refs.mileageRef) {
      this.refs.mileageRef.focus()
    }
  }


  render() {
    const { mileage, mileageStringWidth, mileageThousandsLabel} = this.state
    return (
      <div style={{...this.props.style}}>
        <div style={{position: 'relative'}}>
          <input ref='mileageRef' type="text" className="field mileage" name="mileage" onChange={e => this.mileageChanged(e.target.value)} value={mileage} placeholder={this.props.placeholder ? this.props.placeholder : 'Enter mileage'} maxLength="6" autoComplete="off" style={{marginBottom: 0, flexGrow: 1, flexShink: 1, marginTop: '2px'}} />
          {
            mileage && mileage.length > 0 &&
            <div className="mileage-thousands" style={{pointerEvents: 'none', left: `${mileageStringWidth + 20}px`}}>{mileageThousandsLabel}</div>
          }

        </div>
        <canvas id="myCanvas" width="300" height="150" style={{display: 'none'}}></canvas>
      </div>
    )
  }

}
