import React from 'react'
import CurrencyInputField from './CurrencyInputField'
import CalculatorIcon from './icons/CalculatorIcon'
import LockedIcon from './icons/LockedIcon'
import DeleteIcon from './icons/DeleteIcon'
import Formatters from '../../api/constants/Formatters'
import CloseIcon from './icons/CloseIcon'
import SessionStore from '../../api/stores/SessionStore'
import SaveIcon from './icons/SaveIcon'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import CommonFunctions from '../../api/constants/CommonFunctions'
import pluralize from 'pluralize'
import Spinner from './Spinner'
import ReloadIcon from './icons/ReloadIcon'
import ExpandIcon from './icons/ExpandIcon'
import ContractIcon from './icons/ContractIcon'


export default class Calculator extends React.PureComponent {
  state = {
    valuation: this.props.valuation,
    originalValuationValues: null,
    isSaving: false,
    isCollapsed: SessionStore.hiddenEmbeddedCalculator,
    hasUserValues: Boolean(this.props.valuation?.purchase_price_cents || this.props.valuation?.sale_price_cents),
  }

  componentDidMount() {
    this.hasReceivedValues = false
    this.setInitialValues()
  }

  componentDidUpdate(prevProps) {
    // If choices were needed, we might not have gotten them on mount
    if (!this.hasReceivedValues && ((this.props.wholesalePriceEstimate && !this.state.valuation.purchase_price_cents) || (this.props.retailPriceEstimate && !this.state.valuation.sale_price_cents))) {
      let newValuation = {...this.state.valuation}
      const hasUserValues = Boolean(this.props.valuation?.purchase_price_cents || this.props.valuation?.sale_price_cents)
      newValuation.purchase_price_cents = this.props.wholesalePriceEstimate
      newValuation.sale_price_cents = this.props.retailPriceEstimate
      this.hasReceivedValues = true
      this.setState({
        valuation: newValuation,
        hasUserValues
      })
    }
  }

  setInitialValues = () => {
    let valuation = this.props.valuation ? {...this.props.valuation} : {}
    const rooftop = SessionStore.user?.rooftop
    valuation = CommonFunctions.calculatorInitialValuesForValuation(valuation, rooftop, this.props.wholesalePriceEstimate, this.props.retailPriceEstimate, this.props.auctionListingCRScore, this.props.auctionListingLongitude, this.props.auctionListingLatitude, this.props.hasAuctionListing === true)

    const originalValuationValues = CommonFunctions.calculatorOriginalValuesFromValuation(valuation)

    if (valuation.purchase_price_cents || valuation.sale_price_cents) {
      this.hasReceivedValues = true
    }

    this.setState({
      valuation,
      originalValuationValues,
      computedCRScore: valuation.computed_cr_score,
      computedTransportCostDistance: valuation.computed_transport_cost_distance,
    })
  }

  clearValues = () => {
    let newValuation = {...this.state.valuation}
    newValuation.purchase_price_cents = 0
    newValuation.sale_price_cents = 0
    newValuation.profit_cents = 0
    newValuation.recon_cost_cents = 0
    newValuation.transportation_cost_cents = 0
    newValuation.auction_fees_cost_cents = 0
    newValuation.misc_fees_cost_cents = 0

    this.setState({
      valuation: newValuation
    })
  }


  inputField = (name, lockable = false) => {
    const valuation = this.state.valuation
    const lockedField = valuation?.locked_calculator_field || 'profit_cents'
    

    if (lockedField === name) {
      return ( <div>{Formatters.formatCurrency(CommonFunctions.calculatorComputedValueForField(name, valuation))}</div> )
    }

    return (
      <div className={lockable ? 'lockable-c' : ''}>
        <div className='input-c'>
          <CurrencyInputField
            onChange={(cost) => {
              let newValuation = {...this.state.valuation}
              newValuation[name] = CommonFunctions.stripCommas(cost)
              this.setState({
                valuation: newValuation
              })
            }}
            value={valuation && valuation[name] ? CommonFunctions.stripCommas(valuation[name]) : 0}
            disabled={lockedField === name} />
        </div>
        {
          lockable &&
          <>
            { lockedField === name ? <LockedIcon style={{opacity: 0.5}} /> : <a className='input-lock-button' href="#lock" onClick={(e) => {
              e.preventDefault()
              let newValuation = {...this.state.valuation}
              newValuation['locked_calculator_field'] = name
              this.setState({
                valuation: newValuation
              })
            }}><LockedIcon style={{opacity: 0.5}} /></a> }
          </>
        }
        
      </div>
    )
  }

  lockableRow = (name, title) => {
    const valuation = this.state.valuation
    const lockedField = valuation?.locked_calculator_field || 'profit_cents'

    let className = ''
    if (name === 'profit_cents') {
      const computedProfit = CommonFunctions.calculatorComputedValueForField('profit_cents', valuation)
      if (computedProfit < 0) {
        className = 'profit-negative'
      } else if (computedProfit > 0) {
        className = 'profit-positive'
      }
    }

    const marketPrice = this.props.marketPrice
    const salePrice = valuation?.sale_price_cents
    let percentOfRetailMarket = null
    if (marketPrice && name === 'sale_price_cents' && salePrice && salePrice > 0) {
      if (lockedField  === 'sale_price_cents') {
        percentOfRetailMarket = Math.round((CommonFunctions.calculatorComputedValueForField(name, valuation) / marketPrice) * 100)
      } else if (salePrice) {
        percentOfRetailMarket = Math.round((CommonFunctions.stripCommas(salePrice) / marketPrice) * 100)
      }
    }

    if (name === lockedField) {
      return (
        <div className={`locked-c ${className}`}>
          <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className='locked-body'>
              <h2>{title}</h2>
              { this.inputField(name, true) }
            </div>
            {
              Boolean(percentOfRetailMarket) &&
              <div className='value-note'>{percentOfRetailMarket}% of market</div>
            }
          </div>
        </div>  
      )
    }

    
    return (
      <div>
        <div className='calculator-row-cell'>
          <h2>{title}</h2>
          { this.inputField(name, true) }
        </div>
        {
          Boolean(percentOfRetailMarket) &&
          <div className='value-note'>{percentOfRetailMarket}% of market</div>
        }
      </div>
    )
  }

  save = () => {
    const { valuation } = this.state
    const serverParams = CommonFunctions.calculatorServerParamsForValuation(valuation)

    this.setState({ isSaving: true })
    setTimeout(() => {
      this.setState({ isSaving: false })
    }, 1000)

    ValuationActionCreators.updateValuation(valuation.uuid, serverParams, 'calc')
  }

  renderCalculatorLabel = () => {
    const { valuation, hasUserValues } = this.state
    if (hasUserValues) {
      // Costs array
      
      let calculatorCosts = 0
      if (valuation.recon_cost_cents) { calculatorCosts += parseInt(valuation.recon_cost_cents) }
      if (valuation.transportation_cost_cents) { calculatorCosts += parseInt(valuation.transportation_cost_cents) }
      if (valuation.auction_fees_cost_cents) { calculatorCosts += parseInt(valuation.auction_fees_cost_cents) }
      if (valuation.misc_fees_cost_cents) { calculatorCosts += parseInt(valuation.misc_fees_cost_cents) }
      const calculatorProfit = valuation.sale_price_cents - valuation.purchase_price_cents - calculatorCosts

      let inputs = []
      let results = []
      inputs.push(
        <div key='cl-r'>
          <div style={{fontSize: '0.9rem', fontWeight: 'bold', color: '#aaa', marginBottom: '-3px'}}>Retail</div>
          <div style={{fontSize: '0.98rem'}}>{Formatters.formatCurrency(valuation.sale_price_cents)}</div>
        </div>
      )

      inputs.push(
        <div key='cl-c'>
          <div style={{fontSize: '0.9rem', fontWeight: 'bold', color: '#aaa', marginBottom: '-3px'}}>Costs</div>
          <div style={{fontSize: '0.98rem'}}>{Formatters.formatCurrency(calculatorCosts)}</div>
        </div>
      )

      inputs.push(
        <div key='cl-p'>
          <div style={{fontSize: '0.9rem', fontWeight: 'bold', color: '#aaa', marginBottom: '-3px'}}>Purchase</div>
          <div style={{fontSize: '0.98rem'}}>{Formatters.formatCurrency(valuation.purchase_price_cents)}</div>
        </div>
      )


      results.push(
        <div style={{width: '100%', margin: '0 10px'}}>
          <div key='cl-prof' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {inputs}
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap: '5px', margin: '4px 0'}}>
            <div style={{fontSize: '0.9rem', fontWeight: 'bold', color: '#aaa'}}>Profit</div>
            <div>{Formatters.formatCurrency(calculatorProfit)}</div>
          </div>
        </div>
      )

      return (
        <div style={{display: 'flex'}}>
          { results }
        </div>
      )

    } else {
      return null
    }
  }


  render() {
    const { isCollapsed } = this.state
    const { isEmbedded } = this.props
    const rooftop = SessionStore.user?.rooftop

    return (
      <div className='calculator-c'>
        {
          isEmbedded &&
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: '10px'}}>
            <CalculatorIcon width={22} height={22} color='#aaa' style={{flexGrow: 0, flexShrink: 0, width: 22, marginLeft: '10px'}} />
            <h2 style={{flexGrow: 1, flexShrink: 1, marginLeft: '5px'}}>Calculator</h2>
            <a href="#toggle-calculator" onClick={(e) => {
              e.preventDefault()
              this.setState({ isCollapsed: !isCollapsed })
              SessionStore.setHiddenEmbeddedCalculator(!isCollapsed)
              }}>
                { isCollapsed ? 
                <ExpandIcon width={22} height={22} color='#aaa' /> :
                <ContractIcon width={22} height={22} color='#aaa' />
                }
            </a>
          </div>
        }
        {
          !isCollapsed &&
          <div>
            <div className='calculator-row calculator-row-lockable' style={{borderBottom: '1px solid #ddd', marginTop: '6px'}}>
              { this.lockableRow('purchase_price_cents', 'Purchase Price') }
            </div>

            <div className='calculator-row ' style={{borderTop: '1px solid #ddd'}}>
              <div className='calculator-row-cell calculator-row-cell-split'>
                <h2>{isEmbedded ? 'Recon' : 'Recon Costs'}</h2>
                { this.inputField('recon_cost_cents') }
              </div>
              <div className='calculator-row-cell calculator-row-cell-split'>
                <h2>{isEmbedded ? 'Transport' : 'Transport Costs'}</h2>
                { this.inputField('transportation_cost_cents') }
              </div>
            </div>

            <div className='calculator-row' style={{borderBottom: '1px solid #ddd'}}>
              <div className='calculator-row-cell calculator-row-cell-split'>
                <h2>Auction Fees</h2>
                { this.inputField('auction_fees_cost_cents') }
              </div>
              <div className='calculator-row-cell calculator-row-cell-split'>
                <h2>{isEmbedded ? 'Other' : 'Other Costs'}</h2>
                { this.inputField('misc_fees_cost_cents') }
              </div>
            </div>

            <div className='calculator-row calculator-row-lockable' style={{borderBottom: '1px solid #ddd', borderTop: '1px solid rgba(255,255,255,0.13)'}}>
              { this.lockableRow('sale_price_cents', 'Retail Price') }
            </div>

            
            <div className='calculator-row calculator-row-lockable' style={{borderTop: '1px solid #ddd'}}>
              { this.lockableRow('profit_cents', 'Profit') }
            </div>

            <div className='calculator-footer'>
              <a href="#clear-calculator" onClick={(e) => {
                e.preventDefault()
                this.clearValues()
              }
              }><DeleteIcon /> Clear</a>

              
              {
                !this.props.onClose &&
                <a href="#clear-calculator" onClick={(e) => {
                  e.preventDefault()
                  this.setState({ valuation: this.state.originalValuationValues })
                }
                }><ReloadIcon /> Reset</a>
              }

              {
                this.state.valuation?.uuid &&
                <a href="#save-calculator" onClick={(e) => {
                  e.preventDefault()
                  this.save()
                }
                }>
                  {
                    this.state.isSaving ? <Spinner width={16} height={16} style={{width: '20px', height: '20px', margin: '0px', marginRight: '5px'}} /> : (<><SaveIcon width={16} height={16} /> Save</>)
                  }
                </a>
              }

              {
                this.props.onClose &&
                <a href="#close-calculator" onClick={(e) => {
                  e.preventDefault()
                  this.props.onClose()
                }
                }><CloseIcon width={16} height={16} /> Close</a>
              }
              
            </div>
            {
              Boolean(this.state.computedCRScore) &&
              <div className='secondary-text' style={{textAlign: 'center', padding: '3px'}}>Recon costs computed based on this vehicle having a <span style={{color: '#111'}}>CR score of {this.state.computedCRScore}</span>.</div>
            }

            {
              Boolean(isEmbedded && !this.state.computedCRScore && this.state.valuation?.recon_cost_cents && rooftop.vehicle_condition_none_cost_cents && this.state.valuation.recon_cost_cents === rooftop.vehicle_condition_none_cost_cents / 100) &&
              <div className='secondary-text' style={{textAlign: 'center', padding: '3px'}}>Recon costs based on your setting of {Formatters.formatCurrency(this.state.valuation.recon_cost_cents)} for vehicles with <span style={{color: '#111'}}>no CR score</span>.</div>
            }

            {
              Boolean(this.state.computedTransportCostDistance) &&
              <div className='secondary-text' style={{textAlign: 'center', padding: '3px'}}>Transport costs computed based on this vehicle being <span style={{color: '#111'}}>{pluralize('mile', this.state.computedTransportCostDistance, true)} away</span>.</div>
            }
          </div>
        }

        {
          isCollapsed &&
          this.renderCalculatorLabel()
        }
      </div>
    )
  }
}