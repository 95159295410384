import React from 'react'
import { useDrag } from 'react-dnd'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import UserActionCreators from '../../api/actions/UserActionCreators'

const style = {

}
const ItemTypes = {
  BOX: 'box',
}
const DraggableValuation = ({ valuation, existingFolderID, clientID, onFolderChange, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { valuation, clientID, type: ItemTypes.BOX },
    canDrag: true,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && valuation && dropResult) {
        if (onFolderChange) {
          onFolderChange(valuation, existingFolderID, dropResult.folder)
        }

        setTimeout(() => {
          ValuationActionCreators.updateValuation(valuation.uuid, {vehicle_folder_id: dropResult.folder.id}, clientID)
          UserActionCreators.createMetric(AppConstants.metricNames['vehicle_assign_folder'])
        }, 20)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} style={{ ...style, opacity }}>
      {children}
    </div>
  )
}
export default DraggableValuation
