import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore';
import PaymentForm from './PaymentForm'

export default class PaymentFormModal extends React.Component {

  render() {
    const description = this.props.description || 'You will not be billed until your free trial is over'

    return(
      <Modal size="lg" centered={true} isOpen={true} contentClassName='modal-c'>
        <ModalHeader className='modal-header'>{this.props.title || 'Update Payment Method'}</ModalHeader>
        <ModalBody>
          {
            SessionStore.isTrialing() &&
            <div style={{marginBottom: '10px'}}>{description}</div>
          }
          {
            this.props.isAccountOwner &&
            <PaymentForm message={this.props.message} />
          }
          {
            !this.props.isAccountOwner &&
            <div>
              <div>{this.props.message}</div>
            </div>
          }

          {
            Boolean(this.props.onCancel) &&
            <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '10px'}}>
              <a href="#cancel" onClick={(e) => {e.preventDefault(); this.props.onCancel();}}>Cancel</a>
            </div>
          }

        </ModalBody>
      </Modal>
    )
  }

}
