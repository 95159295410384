import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import Select from 'react-select'
import RetailModelStats from '../retail/ModelStats'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import ListingsModal from '../retail/ListingsModal'
import AppConstants from '../../api/constants/AppConstants'

const radiusOptions = (() => {
  const numOpts = [20, 50, 100, 200, 300, 400, 500].map(value => {
    return { value, label: `${value} miles` }
  })
  return [
    ...numOpts,
    { value: AppConstants.llmAllRadiusValue, label: 'All' },
  ]
})()

const defaultRadius = 100
const disabledMakeModelDataMessage = 'Only include dealers within 300 miles or less to see all years and trims'

const ValuationRetailModelStats = ({ valuation }) => {
  const {
    make,
    model,
    year,
    series: trim,
    zip_code,
  } = valuation

  const [selectedRadius, setSelectedRadius] = useState(() => radiusOptions.find(item => item.value === defaultRadius))
  const [endDate, setEndDate] = useState(null)
  const [initialEndDateKey] = useState(() => new Date().getTime())
  const endDateKeyMap = useRef({ [endDate]: initialEndDateKey })

  const radius = selectedRadius?.value
  const isEnabledMakeModelData = radius <= 300

  const regionParams = useMemo(() => {
    return {
      zip: zip_code,
      radius,
    }
  }, [zip_code, radius])

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({
      ...regionParams,
      make,
      model,
      includeUngrouped: false,
      includeGrouped: true,
      endDate: endDateKeyMap.current[endDate],
    })
  }, [regionParams, make, model, endDate])

  const ungroupedClientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({
      ...regionParams,
      make,
      model,
      includeUngrouped: true,
      includeGrouped: false,
      endDate,
    })
  }, [regionParams, make, model, endDate])

  const [
    {
      isLoading,
      data,
      isLoadingUngrouped,
      ungroupedData,
      isShowingListingsModal,
      selectedModelStat,
      isShowingMakeModelData,
      selectedYear,
      selectedTrim,
    },
    setState,
  ] = useState({
    isShowingListingsModal: false,
    isShowingMakeModelData: false,
    selectedModelStat: null,
    isLoading: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(clientID),
    data: ValuationStore.getRegionalModelStatsWithClientID(clientID),
    isLoadingUngrouped: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(ungroupedClientID),
    ungroupedData: ValuationStore.getRegionalModelStatsWithClientID(ungroupedClientID),
    selectedYear: year,
    selectedTrim: trim,
  })

  const onDataChanged = useCallback(() => {
    const nextData = ValuationStore.getRegionalModelStatsWithClientID(clientID)
    endDateKeyMap.current = { [nextData.end_date]: initialEndDateKey }
    setEndDate(nextData.end_date)

    setState(prev => ({
      ...prev,
      data: nextData,
      ungroupedData: ValuationStore.getRegionalModelStatsWithClientID(ungroupedClientID),
    }))
  }, [clientID, ungroupedClientID, initialEndDateKey])

  const onIsLoadingModelStatsChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(clientID), isLoadingUngrouped: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(ungroupedClientID) }))
  }, [clientID, ungroupedClientID])

  const onSelectedMakeModelChange = useCallback(_option => {
    // TODO: analytics, metrics
  }, [])

  const onSelectedYearChange = useCallback(option => {
    // TODO: analytics, metrics
    setState(prev => ({ ...prev, selectedYear: option?.year ?? null }))
  }, [])

  const onSelectedTrimChange = useCallback(option => {
    // TODO: analytics, metrics
    setState(prev => ({ ...prev, selectedTrim: option?.trim ?? null }))
  }, [])

  const onListingsShowClick = useCallback((item, e) => {
    e.preventDefault()
    // TODO: Metrics?
    setState(prev => ({ ...prev, isShowingListingsModal: true, selectedModelStat: item }))
  }, [])

  const onListingsModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingListingsModal: false, selectedModelStat: null }))
  }, [])

  const onToggleShowMakeModelData = useCallback((e) => {
    e.preventDefault()
    setState(prev => ({ ...prev, isShowingMakeModelData: !prev.isShowingMakeModelData }))
  }, [])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      isShowingMakeModelData: false,
      data: ValuationStore.getRegionalModelStatsWithClientID(clientID),
      isLoading: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(clientID),
      ungroupedData: ValuationStore.getRegionalModelStatsWithClientID(ungroupedClientID),
      isLoadingUngrouped: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(ungroupedClientID),
    }))
  }, [clientID, ungroupedClientID])

  useEffect(() => {
    const loadingEv = `regional_model_stats_is_loading_change_${clientID}`
    const dataEv = `regional_model_stats_change_${clientID}`

    const loadingUngroupedEv = `regional_model_stats_is_loading_change_${ungroupedClientID}`
    const dataUngroupedEv = `regional_model_stats_change_${ungroupedClientID}`

    ValuationStore.on(dataEv, onDataChanged)
    ValuationStore.on(loadingEv, onIsLoadingModelStatsChanged)

    ValuationStore.on(dataUngroupedEv, onDataChanged)
    ValuationStore.on(loadingUngroupedEv, onIsLoadingModelStatsChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onDataChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingModelStatsChanged)
      ValuationStore.removeListener(dataUngroupedEv, onDataChanged)
      ValuationStore.removeListener(loadingUngroupedEv, onIsLoadingModelStatsChanged)
    }
  }, [onDataChanged, onIsLoadingModelStatsChanged, clientID, ungroupedClientID])

  useEffect(() => {
    if (!data) {
      const { zip, radius } = regionParams
      ValuationActionCreators.loadRegionalModelStats({ make, model, zip, radius, endDate, clientID, includeGrouped: true, includeUngrouped: false })
    }
  }, [regionParams, clientID, data, make, model, endDate])

  useEffect(() => {
    if (!ungroupedData && !isLoadingUngrouped && isShowingMakeModelData) {
      const { zip, radius } = regionParams
      ValuationActionCreators.loadRegionalModelStats({ make, model, zip, radius, endDate, clientID: ungroupedClientID, includeGrouped: false, includeUngrouped: true })
    }
  }, [regionParams, isShowingMakeModelData, ungroupedClientID, ungroupedData, make, model, isLoadingUngrouped, endDate])

  const yearOptions = useMemo(() => {
    let opts = data?.by_year || []
    if (selectedYear) {
      const existingOpt = opts.find(item => item.year === selectedYear)
      if (!existingOpt) {
        opts = [{ year: selectedYear }, ...opts]
      }
    }
    return opts
  }, [data, selectedYear])

  const trimOptions = useMemo(() => {
    let opts =  data?.by_trim || []
    if (selectedTrim) {
      const existingOpt = opts.find(item => item.trim === selectedTrim)
      if (!existingOpt) {
        opts = [{ trim: selectedTrim }, ...opts]
      }
    }
    return opts
  }, [data, selectedTrim])

  const persistedSelectedYearOption = useMemo(() => {
    return selectedYear ? yearOptions.find(item => item.year === selectedYear) : null
  }, [yearOptions, selectedYear])

  const persistedSelectedTrimOption = useMemo(() => {
    return selectedTrim ? trimOptions.find(item => item.trim === selectedTrim) : null
  }, [trimOptions, selectedTrim])

  const stats = useMemo(() => {
    if (!data) return null
    const { end_date, ...rest } = data
    const { ungrouped } = ungroupedData || {}
    return {
      makeModelStats: ungrouped,
      drilldownStats: rest,
    }
  }, [data, ungroupedData])

  const makeModelStats = stats?.makeModelStats
  const drilldownStats = stats?.drilldownStats

  const selectedMakeModelOption = useMemo(() => {
    return makeModelStats?.[0]
  }, [makeModelStats])

  const regionalListingsParams = useMemo(() => {
    if (!selectedModelStat) return null
    const { year, make, model, trim } = selectedModelStat
    const { zip, radius } = regionParams
    return {
      zip,
      radius,
      year,
      make,
      model,
      trim,
    }
  }, [selectedModelStat, regionParams])

  const renderBody = () => {
    if (isLoading || !drilldownStats) return <Spinner />
    // FIXME: No data message
    if (!drilldownStats?.by_year_and_trim?.length) return <div>No data</div>
    return (
      <>
        <div style={{ marginTop: 10 }}>
          <RetailModelStats
            makeModelStats={makeModelStats}
            drilldownStats={drilldownStats}
            selectedMakeModelOption={selectedMakeModelOption}
            selectedYearOption={persistedSelectedYearOption}
            selectedTrimOption={persistedSelectedTrimOption}
            onListingsShowClick={onListingsShowClick}
            onSelectedMakeModelChange={onSelectedMakeModelChange}
            onSelectedYearChange={onSelectedYearChange}
            onSelectedTrimChange={onSelectedTrimChange}
            isLoadingDrilldownStats={false}
            isDrilldownFirst
            isLoadingMakeModelStats={isLoadingUngrouped}
            onToggleShowMakeModelData={onToggleShowMakeModelData}
            isShowingMakeModelData={isShowingMakeModelData}
            yearOptions={yearOptions}
            trimOptions={trimOptions}
            isDisabled
            isEnabledMakeModelData={isEnabledMakeModelData}
            disabledMakeModelDataMessage={disabledMakeModelDataMessage}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <section className="card valuation-section">
        <h2>{AppConstants.providerNames['regional_report']}</h2>
        <h3>{make} {model}</h3>
        <div className="split-row split-row-left">
          <div style={{marginRight: '10px'}}>Includes dealers within</div>
          <div style={{ width: 200 }}>
            <Select
              styles={reactSelectStyles}
              name='radius'
              className='react-select'
              isSearchable={false}
              onChange={setSelectedRadius}
              value={selectedRadius}
              options={radiusOptions}
              isDisabled={isLoading}
            />
          </div>
        </div>
        {renderBody()}
      </section>
      {
        isShowingListingsModal &&
        <ListingsModal
          onClose={onListingsModalCloseClick}
          regionalListingsParams={regionalListingsParams}
        />
      }
    </>
  )
}

const reactSelectStyles = {
  option: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: '10px'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}
export default ValuationRetailModelStats

