import Dispatcher from './Dispatcher'

function logAction(type, additionalData) {
  const args = [`action: ${type}`]
  if (additionalData) args.push(additionalData)
  console.log(...args)
}

export default {
  dispatchAction: function(type, action, additionalData) {
    if (Dispatcher.isDispatching()) {
      setTimeout(() => {
        logAction(type, additionalData)
        Dispatcher.dispatch({ type: type, ...additionalData })
        if (action) {
          action()
        }
      })
    } else {
      logAction(type, additionalData)
      Dispatcher.dispatch({ type: type, ...additionalData })
      if (action) {
        action()
      }
    }
  }
}
