import { EventEmitter } from 'events'
import Dispatcher from '../actions/Dispatcher'
import VDSActionCreators from '../actions/VDSActionCreators'
import RemoteConstants from '../constants/RemoteConstants'
import moment from 'moment'
var ActionTypes = RemoteConstants.ActionTypes

const BASE_CORPUS_EXPIRATION_DAYS = 2

class VDSStore extends EventEmitter {
  constructor() {
    super()
    this.baseCorpus = null
    this.lastBaseCorpusLoadTime = null
    this.isFetchingBaseCorpus = false
    this.makesCorpus = null
  }

  getBaseCorpus() {
    console.log("___ getBaseCorpus ___");
    if (this.isFetchingBaseCorpus) {
      console.log("____CORPUS IS ALREADY FETCHING");
      return null
    }
    const now = moment()
    if (this.baseCorpus && this.lastBaseCorpusLoadTime && moment(this.lastBaseCorpusLoadTime).add(BASE_CORPUS_EXPIRATION_DAYS, 'days').isAfter(now)) {
      console.log("____CORPUS IS EXPIRED");
      this.baseCorpus = null
      VDSActionCreators.loadBaseCorpus()
    } else if (this.baseCorpus) {
      console.log("____CORPUS IS CACHED");
      return this.baseCorpus
    } else {
      console.log("____CORPUS IS NULL, FETCHING");
      VDSActionCreators.loadBaseCorpus()
    }
    return null
  }

  handleActions(action) {
    switch(action.type) {
      case ActionTypes.LOAD_VDS_BASE_CORPUS: {
        this.isFetchingBaseCorpus = true
        this.emit('base_vds_corpus_load')
        break
      }
      case ActionTypes.VDS_BASE_CORPUS_RECEIVED: {
        this.isFetchingBaseCorpus = false
        if (!action.errors || action.errors.length === 0) {
          this.baseCorpus = action.corpus
          this.emit('base_vds_corpus_change')
        } else {
          // Retry?
          console.log("ERROR: ", action.errors);
        }
        break
      }

      case ActionTypes.VDS_MAKES_CORPUS_RECEIVED: {
        if (!action.errors || action.errors.length === 0) {
          this.makesCorpus = action.corpus
          this.emit('makes_vds_corpus_change')
        } else {
          // Retry?
          console.log("ERROR: ", action.errors);
        }
        break
      }

      default: {
        break
      }
    }
  }

}


const vdsStore = new VDSStore()
Dispatcher.register(vdsStore.handleActions.bind(vdsStore))

export default vdsStore