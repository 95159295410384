import React from 'react'
import {  Button, CustomInput, Modal, ModalHeader, ModalBody, Input, Tooltip } from 'reactstrap'
import AppConstants from '../../api/constants/AppConstants'
import AuctionsMap from './AuctionsMap'
import DistanceFromZipcodeControl from '../common/DistanceFromZipcodeControl'
import SessionStore from '../../api/stores/SessionStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionStore from '../../api/stores/AuctionStore'

export default class AuctionsEditOnlineChannelsModal extends React.Component {
  state = {
    digitalCodes: this.props.savedSearch?.digital_codes,
    defaultZipCode: this.props.savedSearch?.zip_code,
    zipCode: this.props.savedSearch?.zip_code,
    modifiedZipCodeIsValid: true,
    locationRadius: this.props.savedSearch?.location_radius,
    searchHasChanged: false,
    coordinatesForZipOverride: null
  }

  componentDidMount() {
    AuctionStore.on('coordinates_for_zip_change', this.coordinatesForZipDidChange)
  }

  componentWillUnmount() {
    AuctionStore.removeListener('coordinates_for_zip_change', this.coordinatesForZipDidChange)
  }

  coordinatesForZipDidChange = () => {
    this.setState({
      coordinatesForZipOverride: AuctionStore.coordinatesForZip
    })
  }
  
  toggleOnlineChannel = (channel) => {
    let newDigitalCodes = this.state.digitalCodes ? [...this.state.digitalCodes] : []

    if (newDigitalCodes.includes(channel)) {
      newDigitalCodes = newDigitalCodes.filter((c) => c !== channel)
    } else {
      newDigitalCodes.push(channel)
    }

    this.setState({
      digitalCodes: newDigitalCodes,
      searchHasChanged: true
    })

    // Persist on disk for use on other new searches
    SessionStore.setLastSelectedDigitalAuctions(newDigitalCodes?.length > 0 ? JSON.stringify(newDigitalCodes) : JSON.stringify([AppConstants.auctionNoDigitalAuctionsSentinel]))
  }

  zipCodeChanged = (zipCode, isValid) => {
    const sanitizedZipCode = CommonFunctions.stripNonNumericNoDecimal(zipCode)

    this.setState({
      zipCode: sanitizedZipCode,
      modifiedZipCodeIsValid: isValid,
      searchHasChanged: true
    })

    if (isValid) {
      AuctionsActionCreators.getCoordinatesForZipCode(sanitizedZipCode)
    }
  }

  onRadiusChange = (distance) => {
    this.setState({ locationRadius: distance })
  }

  onAfterRadiusChange = (distance) => {
    this.setState({
      searchHasChanged: true,
      locationRadius: distance
    })

    // this.props.onAfterChange(distance)
  }

  renderRow = (key) => {
    const { digitalCodes } = this.state
    return (
      <div className='inline-checkbox'>
        <CustomInput
          id={key}
          name={key}
          type="switch"
          checked={digitalCodes?.includes(key)}
          label={AppConstants.auctionOnlineSourceNames[key]}
          onChange={() => { this.toggleOnlineChannel(key) }} />
      </div>
    )
  }

  onDone = () => {
    const { searchHasChanged, zipCode, modifiedZipCodeIsValid, locationRadius, digitalCodes } = this.state

    if (searchHasChanged) {
      let modifiedParams = []

      if (this.props.showDistance !== false) {
        if (zipCode && modifiedZipCodeIsValid) {
          modifiedParams.push({name: 'zip_code', value: zipCode})
        }
        modifiedParams.push({name: 'location_radius', value: locationRadius})
      }
      
      modifiedParams.push({name: 'digital_codes', value: digitalCodes})

      if (this.props.onMultipleSearchParamsChange) {
        this.props.onMultipleSearchParamsChange(modifiedParams)
      }
    }

    setTimeout(() => {
      if (this.props.onDone) {
        this.props.onDone()
      }
    }, 100)
  }

  render() {
    const { savedSearch, coordinatesForZip } = this.props
    const { defaultZipCode, locationRadius, coordinatesForZipOverride } = this.state

    const coordinates = coordinatesForZip || {longitude: SessionStore.user?.rooftop?.longitude, latitude: SessionStore.user?.rooftop?.latitude}

    return(
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '800px'}}>
        <ModalHeader className='modal-header'>
          Change Online Sales
        </ModalHeader>
        <ModalBody>

          <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{flexGrow: 1, flexShrink: 1}}>
                Include the following online sales in this search:
                <div style={{margin: '20px 0'}}>
                  { this.renderRow('ADESA_CLEAR') }
                  { this.renderRow('DEALER_BLOCK') }
                  { this.renderRow('AUCTION_CENTRAL') }
                  { this.renderRow('E_BLOCK_WEST') }
                  { this.renderRow('EDGE_DOT_AUCTION') }
                  { this.renderRow('MANHEIM_EXPRESS') }
                  { this.renderRow('MANHEIM_OVE') }
                  { this.renderRow('SMART_AUCTION') }
                </div>

                {
                  savedSearch.location_type === 'auctions' &&
                    <DistanceFromZipcodeControl
                      onDistanceChange={this.onRadiusChange}
                      onAfterChange={this.onAfterRadiusChange}
                      defaultDistance={locationRadius}
                      defaultZipCode={defaultZipCode}
                      onZipCodeChange={this.zipCodeChanged}
                      realtimeDistanceValue={locationRadius}
                    />
                }
              </div>

              <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '20px', width: '280px', height: '280px', boxShadow: '0 3px 16px rgba(0,0,0,0.12)', zIndex: 999}} className='zoomIn'>
                <AuctionsMap
                  scopeType={'distance'}
                  distanceRadiusMiles={locationRadius === AppConstants.auctionSearchMaxDistanceRadius ? AppConstants.auctionSearcAllAuctionsRadius : locationRadius}
                  centerForDistanceScopeType={coordinatesForZipOverride !== null ? coordinatesForZipOverride : coordinates} width='280px' height='280px' hideAuctions ref='channelsMap' />
              </div>

            </div>



            <div style={{marginTop: '20px'}}>
              <Button style={{width: '100%'}} color="primary" onClick={this.onDone}>Done</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )

  }

}
