import React from 'react'
import { Route, Switch } from 'react-router-dom'
import UserSettings from '../components/settings/UserSettings'
import AccountSettings from '../components/settings/AccountSettings'
import SettingsNews from '../components/settings/SettingsNews'
import SessionStore from '../api/stores/SessionStore'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import EditProfileModal from '../components/user/EditProfileModal'
import Avatar from '../components/user/Avatar'
import Formatters from '../api/constants/Formatters'
import AppConstants from '../api/constants/AppConstants'
import AccountNeedsAction from '../components/user/AccountNeedsAction'

export default class SettingsPage extends React.Component {
  state = {
    user: SessionStore.user,
    isShowingEditProfileModal: false
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.userDataChanged)
    this.indicateAsViewed()
    SessionStore.on('news_notification_change', this.newsNotificationDidChange)
  }

  componentDidUpdate(prevProps, prevState) {
    // Let's refresh the user when viewing
    if (this.props.navMatch && !prevProps.navMatch) {
      SessionActionCreators.refreshUser()
      this.indicateAsViewed()
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    SessionStore.removeListener('news_notification_change', this.newsNotificationDidChange)
  }

  userDataChanged = () => {
    this.setState({ user: SessionStore.user })
  }

  newsNotificationDidChange = () => {
    // If we're already on settings when it comes through, supress
    const shouldShow = SessionStore.shouldShowNewsNotification()
    if (shouldShow === true) {
      SessionStore.newsViewed()
    }
  }

  indicateAsViewed = () => {
    SessionStore.setHasShownSettingsBadge()
    SessionStore.newsViewed()
  }


  render() {
    const { user } = this.state
    const accountStatusRestricted = this.props.accountStatusRestricted === true
    const accountNeedsActionComponent = accountStatusRestricted ? <AccountNeedsAction showCompact className='account-action-page-header' /> : null
    const { accountStateMessage } = this.props

    return (
      <div style={{height: '100%', display: this.props.navMatch ? 'inherit' : 'none'}}>
        {
          accountStateMessage &&
          <div className="body-with-nav account-warnings"><div className="icon">!</div><div className="message">{accountStateMessage}</div></div>
        }
        
        <nav className="secondary-nav settings-nav">
          <div className="user-details">
            <Avatar
              user={user}
              size={AppConstants.avatarSizes.large}
              className="avatar"
            />
            <h2 className='text-ellipsis'>{user.full_name}</h2>
            <div>{user.email}</div>
            <div>{user.rooftop.name}</div>
            <div>{user.rooftop.zip_code}</div>
            {
              user.phone_number &&
              <div>{Formatters.formatPhoneNumber(user.phone_number)}</div>
            }
            
            <button className='link' onClick={() => this.setState({ isShowingEditProfileModal: true })}>
              Edit your name and photo
            </button>
          </div>
        </nav>

        <div className="page-body-c body-with-nav">
          <Switch>
            <Route
              exact
              path='/settings'
              children={({ match }) => (
                <div>
                  { accountNeedsActionComponent }
                  <UserSettings history={this.props.history} navMatch={match} accountStatusRestricted={accountStatusRestricted} />
                </div>
              )} />

            <Route
              exact
              path='/settings/account'
              children={({ match }) => (
                <AccountSettings navMatch={match} />
              )} />

            <Route
              exact
              path='/settings/news'
              children={({ match }) => (
                <SettingsNews navMatch={match} />
              )} />
          </Switch>
        </div>


        {
          this.state.isShowingEditProfileModal &&
          <EditProfileModal
            user={ user }
            onCancel={ () => this.setState({ isShowingEditProfileModal: false }) }
          />
        }

      </div>
    )
  }
}
