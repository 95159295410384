import React from 'react'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import AppConstants from '../api/constants/AppConstants'

export default class IFrameNoAccessPage extends React.Component {
  render() {
    return (
      <div style={{height: '100%'}}>

        <div className="iframe-no-access-body-c">
          <div className="content-header">
            <h1>Add Carbly Embedded Access</h1>
          </div>
          <div>
            <p>You do not currently have access to Carbly for embedded integrations.  If you'd like to add it to your account, please give us a call at {AppConstants.phoneNumber} and we'll be happy to help you out.</p>
            <p><a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout()}}>Logout</a></p>
            <p><a href="https://getcarbly.com" target="_blank" rel="noopener noreferrer">Visit GetCarbly.com</a></p>
          </div>
        </div>
      </div>
    )
  }
}
