import React from 'react'

export default class RegionalReportsIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '36px'
    const height = this.props.height || '36px'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 1000 1000" fill={color} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd"
 d="M500.000,-0.000 C776.142,-0.000 1000.000,223.858 1000.000,500.000 C1000.000,776.142 776.142,1000.000 500.000,1000.000 C223.858,1000.000 -0.000,776.142 -0.000,500.000 C-0.000,223.858 223.858,-0.000 500.000,-0.000 ZM500.000,31.970 C743.011,31.970 942.766,217.176 965.811,454.137 C956.282,453.718 864.458,455.422 864.458,455.422 L862.048,364.458 L771.687,366.265 L769.277,145.181 L677.711,151.205 L669.880,587.952 L578.313,587.952 L573.494,259.639 L485.542,260.843 L481.325,503.012 L387.349,500.000 L384.337,365.663 L290.361,365.060 L288.554,561.446 L195.181,559.639 L193.976,315.663 L106.024,315.663 L104.217,474.699 C104.217,474.699 36.869,474.819 32.726,473.182 C46.623,227.175 250.515,31.970 500.000,31.970 Z"/>
      </svg>
    )
  }

}