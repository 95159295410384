import React from 'react'
import useSubscribeToListingChanged from '../hooks/useSubscribeToListingChanged'

export default (WrappedComponent, subscriptionOptions) => {
  return ({ isAppBackground, ...rest }) => {
    useSubscribeToListingChanged(isAppBackground, subscriptionOptions)

    return (
      <WrappedComponent {...rest} />
    )
  }
}
