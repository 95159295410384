import React from 'react'
import { Link } from 'react-router-dom'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import AuctionsWatchlistList from './AuctionsWatchlistList'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import platformFunctions from '../../platformFunctions'
import CalculatorBug from '../common/CalculatorBug'

export default class AuctionsWatchlistArchive extends React.Component {
  state = {
    watchlistVehicles: null,
    sectionedWatchlistListings: null,
    isLoadingVehicles: true
  }

  componentDidMount() {
    AuctionStore.on('auctions_watchlist_archive_received', this.watchlistVehiclesDidLoad)
    AuctionStore.on('global_auction_listing_update', this.watchlistVehiclesDidLoad)

    AuctionsActionCreators.loadAuctionsWatchlistArchiveVehicles(null)
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Watchlist Archive")
  }

  componentWillUnmount() {
    AuctionStore.removeListener('auctions_watchlist_archive_received', this.watchlistVehiclesDidLoad)
    AuctionStore.removeListener('global_auction_listing_update', this.watchlistVehiclesDidLoad)
  }

  watchlistVehiclesDidLoad = () => {
    this.restructureWatchlistListings(AuctionStore.auctionsWatchlistArchive)
  }

  restructureWatchlistListings = (watchlistListings) => {
    const restructuredData = AuctionFunctions.restructureWatchlistListings(watchlistListings)

    this.setState({
      watchlistVehicles: watchlistListings,
      sectionedWatchlistListings: restructuredData['sectionedListings'],
      isLoadingVehicles: false,
    })
  }

  onListingShow = (listing) => {
    this.props.history.push({
      pathname: `/auctions/listing/${listing.id}`,
      listingsContext: this.state.watchlistVehicles
    })
  }

  render() {
    const { isLoadingVehicles, sectionedWatchlistListings } = this.state

    return (
      <div className="body-content" style={{minHeight: '600px'}}>
        <div className="content-header">
          <h1>Past Auctions Watchlist</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        <div className="content-context-actions">
          <Link to='/auctions/watchlist/'>
            <svg className="bi bi-clock" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"/>
              <path fillRule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
          View current watchlist vehicles</Link>
        </div>

        {
          sectionedWatchlistListings && !isLoadingVehicles &&
          <AuctionsWatchlistList
            listings={sectionedWatchlistListings}
            onListingShow={this.onListingShow}
            showRunNumber={false}
            showWatchlistToggle={false}
            />
        }


        {
          (!sectionedWatchlistListings || (sectionedWatchlistListings && sectionedWatchlistListings.length === 0)) && !isLoadingVehicles &&
          <div style={{marginTop: '40px'}}>
            <div>You don't have any archived watchlist vehicles.</div>
          </div>
        }

        {
          isLoadingVehicles &&
          <Spinner className="spinner-page" />
        }


      </div>
    )
  }
}
