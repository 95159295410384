import { useEffect, useMemo, useCallback, useState } from 'react'
import CommonFunctions from '../../../api/constants/CommonFunctions'
import ValuationActionCreators from '../../../api/actions/ValuationActionCreators'
import ValuationStore from '../../../api/stores/ValuationStore'
import UserActionCreators from '../../../api/actions/UserActionCreators'
import AppConstants from '../../../api/constants/AppConstants'
import platformFunctions from '../../../platformFunctions'

const useModelStatsProvider = ({ regionParams, selectedMakeModelOption: initialSelectedMakeModelOption }) => {
  const [
    {
      isLoadingDrilldownStats,
      drilldownStats,
      selectedMakeModelOption,
      selectedYearOption,
      selectedTrimOption,
    },
    setState,
  ] = useState(() => {
    const { make, model } = initialSelectedMakeModelOption || {}
    const initialClientID = (make && model) ? clientID : CommonFunctions.cacheKeyFromObject({ ...regionParams, make, model })
    return {
      isLoadingDrilldownStats: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(initialClientID),
      drilldownStats: ValuationStore.getRegionalModelStatsWithClientID(initialClientID),
      selectedMakeModelOption: initialSelectedMakeModelOption,
    }
  })

  const clientID = useMemo(() => {
    const { make, model } = selectedMakeModelOption || {}
    if (!(make && model)) return null
    return CommonFunctions.cacheKeyFromObject({
      ...regionParams,
      make: make,
      model: model,
    })
  }, [regionParams, selectedMakeModelOption])

  const onSelectedMakeModelChange = useCallback(option => {
    setState(prev => ({
      ...prev,
      selectedMakeModelOption: option,
      selectedYearOption: null,
      selectedTrimOption: null
    }))
    platformFunctions.logAnalyticsEvent(AppConstants.providerNames.regional_report, "Model Selected")
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_model_change'])
  }, [])

  const onSelectedYearChange = useCallback(option => {
    setState(prev => ({ ...prev, selectedYearOption: option }))
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_year_change'])
  }, [])

  const onSelectedTrimChange = useCallback(option => {
    setState(prev => ({ ...prev, selectedTrimOption: option }))
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_trim_change'])
  }, [])

  const onModelStatsChanged = useCallback(() => {
    setState(prev => ({ ...prev, drilldownStats: ValuationStore.getRegionalModelStatsWithClientID(clientID) }))
  }, [clientID])

  const onIsLoadingModelStatsChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoadingDrilldownStats: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(clientID) }))
  }, [clientID])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      drilldownStats: ValuationStore.getRegionalModelStatsWithClientID(clientID),
      isLoadingDrilldownStats: ValuationStore.getRegionalModelStatsIsLoadingWithClientID(clientID),
    }))
  }, [clientID])

  useEffect(() => {
    const loadingEv = `regional_model_stats_is_loading_change_${clientID}`
    const dataEv = `regional_model_stats_change_${clientID}`

    ValuationStore.on(dataEv, onModelStatsChanged)
    ValuationStore.on(loadingEv, onIsLoadingModelStatsChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onModelStatsChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingModelStatsChanged)
    }
  }, [onModelStatsChanged, onIsLoadingModelStatsChanged, clientID])

  useEffect(() => {
    const { make, model } = selectedMakeModelOption || {}
    if (!drilldownStats && make && model) {
      const { zip, radius, endDate } = regionParams
      ValuationActionCreators.loadRegionalModelStats({ make, model, zip, radius, endDate, clientID })
    }
  }, [regionParams, clientID, drilldownStats, selectedMakeModelOption])

  return {
    onSelectedMakeModelChange,
    onSelectedYearChange,
    onSelectedTrimChange,
    isLoadingDrilldownStats,
    drilldownStats,
    selectedMakeModelOption,
    selectedYearOption,
    selectedTrimOption,
  }
}

export default useModelStatsProvider
