import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationRow from './ValuationRow'
import VINEntryField from '../common/VINEntryField'
import DroppableFolder from './DroppableFolder'
import DraggableValuation from './DraggableValuation'
import AccountBug from '../user/AccountBug'
import NoteIcon from '../common/icons/NoteIcon'
import PlusIcon from '../common/icons/PlusIcon'
import NoVehicles from './NoVehicles'
import CommonFunctions from '../../api/constants/CommonFunctions'
import RemoteConstants from '../../api/constants/RemoteConstants'
import Spinner from '../common/Spinner'
import Activity from '../activities/Activity'
import platformFunctions from '../../platformFunctions'
import CalculatorBug from '../common/CalculatorBug'
import AccountNeedsAction from '../user/AccountNeedsAction'

const vehicleScopes = [
  {
    name: 'All Vehicles',
    value: RemoteConstants.vehicleScopeAll,
  },
  {
    name: 'My Vehicles',
    value: RemoteConstants.vehicleScopeMine,
  },
]

export default withRouter(class VehiclesHome extends React.Component {
  state = {
    homeData: null,
    homeDataRecent: null,
    homeDataFolders: null,
    homeDataActivity: null,
    isLoading: true,
    scope: SessionStore.vehiclesHomeRecentFilter
  }

  componentDidMount() {
    this.clientID = 'home'
    ValuationStore.on('vehicles_home_change', this.homeDataDidChange)
    ValuationStore.on('folder_created', this.loadVehicles)
    ValuationStore.on('folder_updated', this.loadVehicles)
    ValuationStore.on('folders_order_updated', this.loadVehicles)
    ValuationStore.on('folder_deleted', this.loadVehicles)
    ValuationStore.on('valuation_photo_deleted', this.loadVehicles)
    ValuationStore.on('valuation_photo_updated', this.loadVehicles)
    ValuationStore.on('valuation_folder_updated', this.loadVehicles)
    ValuationStore.on('valuation_deleted', this.loadVehicles)
    ValuationStore.on('existing_valuation_received', this.loadVehicles)
    SessionStore.on('vehicles_home_scope_change', this.onVehicleScopeChange)
    SessionStore.on('user_data_changed', this.userWasRefreshed)
    this.loadVehicles()
  }

  componentWillUnmount() {
    ValuationStore.removeListener('vehicles_home_change', this.homeDataDidChange)
    ValuationStore.removeListener('folder_created', this.loadVehicles)
    ValuationStore.removeListener('folder_updated', this.loadVehicles)
    ValuationStore.removeListener('folders_order_updated', this.loadVehicles)
    ValuationStore.removeListener('folder_deleted', this.loadVehicles)
    ValuationStore.removeListener('valuation_photo_deleted', this.loadVehicles)
    ValuationStore.removeListener('valuation_photo_updated', this.loadVehicles)
    ValuationStore.removeListener('valuation_folder_updated', this.loadVehicles)
    ValuationStore.removeListener('valuation_deleted', this.loadVehicles)
    ValuationStore.removeListener('existing_valuation_received', this.loadVehicles)
    SessionStore.removeListener('vehicles_home_scope_change', this.onVehicleScopeChange)
    SessionStore.removeListener('user_data_changed', this.userWasRefreshed)
  }

  onVehicleScopeChange = () => {
    this.setState({
      scope: SessionStore.vehiclesHomeRecentFilter
    }, this.loadVehicles)
  }

  loadVehicles = (e) => {
    const scope = SessionStore.hasTeam() ? this.state.scope : RemoteConstants.vehicleScopeAll
    ValuationActionCreators.loadVehiclesHome(scope)
  }

  homeDataDidChange = () => {
    const homeData = ValuationStore.vehiclesHomeData
    this.setState({
      homeData: homeData,
      homeDataRecent: homeData.recent,
      homeDataFolders: homeData.folders,
      homeDataActivity: homeData.activity,
      isLoading: false
    })
  }

  userWasRefreshed = () => {
    // FIXME: centralize in layout
    if (!this.refs.mainRef) { return }
    const newValuationsEnabled = SessionStore.valuationsEnabled()
    const newValuationsTrialEndingDays = SessionStore.valuationsTrialEndingDays()
    if (this.state.valuationsEnabled !== newValuationsEnabled || this.state.valuationsTrialEndingDays !== newValuationsTrialEndingDays) {
      this.setState({
        valuationsEnabled: newValuationsEnabled,
        valuationsTrialEndingDays: newValuationsTrialEndingDays
      })
    }
  }

  vinChanged = (vin, isVINValid, vinFailedChecksum) => {
    if (vin && isVINValid && !vinFailedChecksum && this.props.onQuickVINEntered) {
      this.props.onQuickVINEntered(vin)
      setTimeout(() => {
        if (this.refs.vinRef) {
          this.refs.vinRef.clear()
        } else if (this.refs.noVehiclesRef) {
          this.refs.noVehiclesRef.clearVIN()
        }
      }, 200)
    }
  }

  onCycleVehicleScope = () => {
    const newIndex = (vehicleScopes.findIndex(s => s.value === this.state.scope) + 1) % vehicleScopes.length
    SessionStore.setVehiclesHomeRecentFilter(vehicleScopes[newIndex].value)
    platformFunctions.logAnalyticsEvent("Valuations", "Vehicles Home Scope Change")
  }

  onActivityClick = ({ vehicle_uuid: uuid }) => {
    this.props.history.push(CommonFunctions.valuationURL({ uuid }))
    platformFunctions.logAnalyticsEvent("Valuations", "Vehicles Open from Activity")
  }

  getActivityIsDisabled = activity => {
    return activity.vehicle_is_deleted
  }

  renderBody() {
    const { accountStatusRestricted } = this.props
    const { homeDataRecent, homeDataFolders, homeDataActivity } = this.state

    const hasFolders = homeDataFolders && homeDataFolders.length > 0
    var hasRecentVehicles = homeDataRecent && homeDataRecent.data && homeDataRecent.data.length > 0
    const hasMoreValuations = hasRecentVehicles && homeDataRecent.full_collection_count > homeDataRecent.pagination_count

    if (!hasRecentVehicles) {
      return (
        <NoVehicles onVinChanged={this.vinChanged} ref='noVehiclesRef' accountStatusRestricted={accountStatusRestricted} />
      )
    }

    return (
      <div ref='mainRef'>        
        <div className="card">
          <h2 className="card-title card-title-with-control">
            <div>Recent Vehicles</div>

            {
              SessionStore.hasTeam() &&
              <div className="card-title-control">
                <span>Showing </span>
                <button
                  className='link'
                  onClick={this.onCycleVehicleScope}
                >
                  {vehicleScopes.find(s => s.value === this.state.scope).name}
                </button>
              </div>
            }
          </h2>
          <div className="card-list">
          {
            hasRecentVehicles &&
            homeDataRecent.data.map((valuation) => {
              return (
                <DraggableValuation key={`home-val-${valuation.uuid}`} valuation={valuation} existingFolderID={valuation.vehicle_folder ? valuation.vehicle_folder.id : null} clientID={this.clientID} onFolderChange={(valuation, existingFolderID, targetFolder) => ValuationStore.changeHomeValuationFolder(valuation, existingFolderID, targetFolder)}>
                  <ValuationRow
                    valuation={valuation}
                    clientID={this.clientID}
                    accountStatusRestricted={accountStatusRestricted}
                    onDeleteValuation={(valuation) => {
                      ValuationStore.deleteValuation(valuation.uuid, this.clientID)
                    }} />
                </DraggableValuation>
              )
            })
          }
          </div>
          
          <div className="card-actions" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {
              hasRecentVehicles && accountStatusRestricted !== true &&
              <div className="inline-vin-entry">
                <VINEntryField ref='vinRef' onVinChanged={this.vinChanged} placeholder='Add vehicle with a VIN' />
              </div>
            }
            {
              hasRecentVehicles && hasMoreValuations &&
              <Link to='/vehicles/recent' style={{marginTop: accountStatusRestricted !== true ? '-28px' : '-20px', marginLeft: '10px'}}>View all vehicles</Link>
            }
          </div>
        </div>

        {
          !hasFolders && hasRecentVehicles && homeDataRecent?.data?.length < 2 &&
          // FIXME: to deal with auto signup from edge partner page where they created a vehicle w/o seeing home
          // FIXME: could be better if we have source or channel
          <NoVehicles onVinChanged={this.vinChanged} ref='noVehiclesRef' accountStatusRestricted={accountStatusRestricted} />
        }


        {
          hasFolders &&
          <div className="card folders-kanban-rows">
            <h2 className="card-title">Folders</h2>
            {
            homeDataFolders.map((folder) => {
              let folderURL = CommonFunctions.folderURL(folder)
              return (
                <DroppableFolder key={`dr-${folder.id}`} folder={folder} className="kanban-row" enabled={accountStatusRestricted !== true}>
                  <h2>
                    <svg className="bi bi-folder-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                    </svg>

                    <Link to={folderURL}>{ folder.name }</Link></h2>
                  <div className="kanban-items-c">
                  {
                    folder.vehicles &&
                    folder.vehicles.map((fv) => {
                      let url = CommonFunctions.valuationURL(fv)
                      return (
                        <DraggableValuation key={`home-folder-val-${folder.id}${fv.uuid}`} valuation={fv} clientID={this.clientID} existingFolderID={folder.id} onFolderChange={(valuation, existingFolderID, targetFolder) => ValuationStore.changeHomeValuationFolder(valuation, existingFolderID, targetFolder)}>
                          <Link to={url} key={`kb-home-${fv.id}`}>
                            <div className="kanban-item">
                              <div className="kanban-details">
                                <div className="year">{fv.year}</div>
                                <div className="title">{fv.make} {fv.model}</div>
                                <div className="details secondary-text">{fv.vin}</div>
                              </div>
                              {
                                fv.has_vehicle_notes === true &&
                                <div className="kanban-notes">
                                  {fv.vehicle_notes_count} <NoteIcon />
                                </div>
                              }

                            </div>
                          </Link>
                        </DraggableValuation>

                      )
                    })
                  }
                  </div>
                </DroppableFolder>
              )
            })
            }
          </div>
        }
        {
          !hasFolders && hasRecentVehicles && this.props.accountStatusRestricted !== true &&
          <div style={{marginBottom: '40px'}}>You don't have any folders to organize your vehicles.  <a  href="#new-folder" onClick={(e) => {e.preventDefault(); ValuationStore.folderNew()}}>Create one now</a>.</div>
        }

        <div className="card">
          <h2 className="card-title">Recent Activity</h2>
            {
              homeDataActivity && Boolean(homeDataActivity.length) ?
                <>
                  {
                    homeDataActivity.map(activity => {
                      return (
                        <Activity
                          key={activity.id}
                          activity={activity}
                          onClick={this.onActivityClick}
                          isDisabled={this.getActivityIsDisabled(activity)}
                        />
                      )
                    })
                  }
                  <div style={{textAlign: 'right', margin: '10px 0'}}>
                    <Link to='/activity' style={{ margin: 10 }}>View all activity</Link>
                  </div>
                </> :
                <div className="secondary-text" style={{margin: '20px'}}>No activity yet</div>
            }
        </div>
      </div>
    )
  }

  render() {
    const { homeDataRecent } = this.state
    const { accountStatusRestricted } = this.props
    const hasRecentVehicles = homeDataRecent && homeDataRecent.data && homeDataRecent.data.length > 0
    return (
      <>

        {
          !accountStatusRestricted && !SessionStore.hasBookOfferings() && !SessionStore.isAuxUser() &&
          <AccountNeedsAction showCompact className='account-action-page-header' subtitle={'You do not currently have any active vehicle data providers in your subscription and as a result vehicle features are limited. '} callToAction={'Show available data providers'} />
        }

        <div className="body-content">
          {
            hasRecentVehicles &&
            <div className="content-header">
              <h1>Vehicles</h1>

              <div className="global-tools">
                <AccountBug />
                <CalculatorBug />
              </div>
            </div>
          }
          
          {
            hasRecentVehicles && !accountStatusRestricted &&
            <div className="content-context-actions">
              <Link to="/vehicles/new">
                <PlusIcon />
              Add a vehicle</Link> |
              <a href="#new-vehicle" onClick={(e) => {e.preventDefault(); ValuationStore.folderNew()}}>
                <svg className="bi bi-folder" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z"/>
                  <path fillRule="evenodd" d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"/>
                </svg>
              Create a folder</a>
            </div>
          }

          {
            this.state.isLoading ?
              <Spinner className="spinner-page" /> :
              this.renderBody()
          }
        </div>
      </>
    )
  }
})
