import React, { memo, useMemo } from 'react'
import Select, { components as SelectComponents } from 'react-select'
import UserProfile from '../user/Profile'
import SessionStore from '../../api/stores/SessionStore'
import RemoteConstants from '../../api/constants/RemoteConstants'

const SelectChildren = props => (
  props.data.user ?
    <UserProfile user={props.data.user}/> :
    props.children
)

const selectComponents = {
  Option: props => (
    <SelectComponents.Option {...props}>
      <SelectChildren {...props} />
    </SelectComponents.Option>
  ),

  SingleValue: props => (
    <SelectComponents.SingleValue {...props}>
      <SelectChildren {...props} />
    </SelectComponents.SingleValue>
  )
}

const ValuationScopeSelect = ({ scope, onChange }) => {
  const users = SessionStore.user.team_users.filter((u) => u.full_name !== null)
  const options = useMemo(() => {
    const opts = [
      {
        label: 'My Vehicles',
        value: RemoteConstants.vehicleScopeMine,
      },
      {
        label: 'All Vehicles',
        value: RemoteConstants.vehicleScopeAll,
      },
    ]

    if (users.length) {
      opts.push({
        label: 'Team users',
        options: users.map(user => ({ user, value: user.uuid }))
      })
    }
    return opts
  }, [users])

  const value = useMemo(() => {
    let found
    const recursiveFind = (opts) => {
      let i = 0
      let opt = opts[i]
      do {
        if (opt.options) {
          recursiveFind(opt.options)
        } else {
          if (opt.value === scope) {
            found = opt
            break
          }
        }
        opt = opts[i++]
      } while (opt)
      return found
    }
    return recursiveFind(options)
  }, [options, scope])

  return (
    <div style={{ width: 500 }}>
      <Select
        maxMenuHeight={400}
        value={value}
        components={selectComponents}
        onChange={onChange}
        options={options}
        isSearchable={false}
      />
    </div>
  )
}

export default memo(ValuationScopeSelect)
