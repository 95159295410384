import React from 'react'
import { useHistory } from "react-router-dom"
import { DropdownItem } from 'reactstrap'
import SessionActionCreators from '../../api/actions/SessionActionCreators'

function LogoutButton() {
  let history = useHistory();

  return (
    <DropdownItem onClick={() => {
      history.replace('/')
      SessionActionCreators.logout()
    }}>
      <div className="icon">
        <svg className="bi bi-arrow-up-left-circle-fill" width="22px" height="22px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM9.5 5h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 1 0V6.707l4.146 4.147a.5.5 0 0 0 .708-.708L6.707 6H9.5a.5.5 0 0 0 0-1z"/>
        </svg>
      </div>
      Logout

    </DropdownItem>
  );
}


export default LogoutButton;
