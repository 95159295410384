import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore';

export default class ValuationHUD extends React.PureComponent {
  state = {
    affix: this.props.persistent === true,
    isHidden: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    ValuationStore.on('hud_should_hide', this.hideHUD)
    ValuationStore.on('hud_should_show', this.showHUD)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    ValuationStore.removeListener('hud_should_hide', this.hideHUD)
    ValuationStore.removeListener('hud_should_show', this.showHUD)
  }

  showHUD = () => {
    this.setState({ isHidden: false })
  }

  hideHUD = () => {
    this.setState({ isHidden: true })
  }

  handleScroll = () => {
    const { affix } = this.state
    const offset = this.props.offset ? this.props.offset : 180
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

    if (!affix && scrollTop >= offset) {
      this.setState({ affix: true })
    }

    if (affix && scrollTop < offset) {
      this.setState({ affix: false })
    }
  };

  render() {
    const { persistent, showScrollToTop } = this.props
    const { affix, isHidden } = this.state
    if (isHidden && persistent !== true) { return null }
    return (
      <a href="#scroll-to-top" onClick={(e) => {
        e.preventDefault()
        if (showScrollToTop !== false) {
          window.scrollTo(0, 0)
        }
      }} className={`valuation-hud${affix ? ' fadeIn' : ' fadeOut'}`} style={{...this.props.style}}>
        {
          this.props.items.map((item, index) => {
            return (
              <div key={`hud-${index}`}>{item}</div>
            )
          })
        }
        {
          showScrollToTop !== false &&
          <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-capslock-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1z"/>
          </svg>
        }
      </a>
    )
  }

}
