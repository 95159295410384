import React from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationStore from '../../api/stores/ValuationStore'
import RemoteConstants from '../../api/constants/RemoteConstants'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import AccountBug from '../user/AccountBug'
import DraggableValuation from './DraggableValuation'
import ValuationRow from './ValuationRow'
import Spinner from '../common/Spinner'
import ValuationScopeSelect from './ValuationScopeSelect'
import platformFunctions from '../../platformFunctions'
import PlusIcon from '../common/icons/PlusIcon'
import CalculatorBug from '../common/CalculatorBug'

const orderOptions = [
  {
    name: 'date created',
    value: RemoteConstants.orderByCreated,
  },
  {
    name: 'date changed',
    value: RemoteConstants.orderByUpdated,
  },
  {
    name: 'date viewed',
    value: RemoteConstants.orderByViewed,
  },
]

export default class RecentValuations extends React.Component {
  state = {
    listErrors: false,
    loadingMore: false,
    recentValuationsEndOfListReached: false,
    cutoff: null,
    isLoading: true,
    scope: SessionStore.vehiclesAllFilter,
    order: ValuationStore.allVehiclesOrder,
    recentValuations: [],
  }

  componentDidMount() {
    this.clientID = 'recent'
    ValuationStore.on('recent_valuations_change', this.recentValuationsDidChange)
    ValuationStore.on('valuation_folder_updated', this.reload)
    ValuationStore.on('valuation_created', this.reload)
    SessionStore.on('all_vehicles_scope_change', this.onScopeChange)
    ValuationStore.on('all_vehicles_order_change', this.onOrderChange)
    this.load()
  }

  componentWillUnmount() {
    ValuationStore.removeListener('recent_valuations_change', this.recentValuationsDidChange)
    ValuationStore.removeListener('valuation_folder_updated', this.reload)
    ValuationStore.removeListener('valuation_created', this.reload)
    SessionStore.removeListener('all_vehicles_scope_change', this.onScopeChange)
    ValuationStore.removeListener('all_vehicles_order_change', this.onOrderChange)
  }

  onScopeChange = () => {
    this.setState({
      scope: SessionStore.vehiclesAllFilter
    }, this.load)
    platformFunctions.logAnalyticsEvent("Valuations", "All Vehicles Scope Change")
  }

  onOrderChange = () => {
    this.setState({
      order: ValuationStore.allVehiclesOrder
    }, this.load)
    platformFunctions.logAnalyticsEvent("Valuations", "All Vehicles Order Change")
  }

  load = () => {
    this.setState({
      recentValuationsLoading: true,
      recentValuations: null,
      cutoff: null,
      isLoading: true,
      recentValuationsEndOfListReached: false,
    }, this.reload)
  }

  reload = () => {
    const scope = SessionStore.hasTeam() ? this.state.scope : RemoteConstants.vehicleScopeAll
    ValuationActionCreators.loadRecentValuations(null, this.props.folderID, this.clientID, scope, this.state.order)
  }

  loadMore = () => {
    if (this.state.recentValuationsEndOfListReached) {
      return
    }
    if (!this.state.loadingMore) {
      this.setState({loadingMore: true})
      const scope = SessionStore.hasTeam() ? this.state.scope : RemoteConstants.vehicleScopeAll
      ValuationActionCreators.loadRecentValuations(this.state.cutoff, null, this.clientID, scope, this.state.order)
    }
  }

  recentValuationsDidChange = () => {
    const data = ValuationStore.recentValuations.dataWithID(this.clientID)
    this.setState({
      recentValuations: data.collection,
      loadingMore: false,
      isLoading: false,
      cutoff: data.getCutoff(),
      recentValuationsEndOfListReached: data.hasLastPage(),
      listErrors: data?.errors ? data.errors : null
    })
  }

  vinChanged = (vin, isVINValid, vinFailedChecksum) => {
    if (vin && isVINValid && !vinFailedChecksum && this.props.onQuickVINEntered) {
      this.props.onQuickVINEntered(vin)
      setTimeout(() => {
        if (this.refs.noVehiclesRef) {
          this.refs.noVehiclesRef.clearVIN()
        }
      }, 200)
    }
  }

  onScopeSelect = ({ value }) => {
    SessionStore.setVehiclesAllFilter(value)
  }

  onOrderClick = () => {
    const { order } = this.state
    for (let i = 0; i < orderOptions.length; i++) {
      if (order === orderOptions[i].value) {
        const nextIndex = i === orderOptions.length - 1 ? 0 : i + 1
        ValuationStore.setAllVehiclesOrder(orderOptions[nextIndex].value)
        return
      }
    }
  }

  renderNoVehicles() {
    const { scope, recentValuations, isLoading } = this.state
    if (!recentValuations?.length && !isLoading) {
      if ([RemoteConstants.vehicleScopeAll, RemoteConstants.vehicleScopeMine].includes(scope)) {
        return <div>No vehicles were found</div>
      }
    } else {
      return null
    }
  }

  renderVehicles() {
    const { recentValuationsEndOfListReached, recentValuations } = this.state
    if (Boolean(recentValuations?.length)) {
      return (
        <div className="card">
          <div className="card-list">
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMore}
              useWindow={true}
              hasMore={!recentValuationsEndOfListReached}
              loader={<Spinner key="pagination-spinner" />} >

              {
                recentValuations.map((valuation) => {
                  return (
                    <DraggableValuation key={`valrow-${valuation.uuid}`} valuation={valuation} existingFolderID={valuation.vehicle_folder ? valuation.vehicle_folder.id : null} clientID={this.clientID} onFolderChange={(valuation, existingFolderID, targetFolder) => ValuationStore.changeRecentsValuationFolder(valuation, existingFolderID, targetFolder, this.clientID)}>
                      <ValuationRow
                        accountStatusRestricted={this.props.accountStatusRestricted === true}
                        valuation={valuation}
                        clientID={this.clientID}
                        onDeleteValuation={(valuation) => {
                          ValuationStore.deleteValuation(valuation.uuid, this.clientID)
                        }}
                      />
                    </DraggableValuation>
                  )
                })
              }
            </InfiniteScroll>
          </div>
        </div>
      )
    } else {
      return this.renderNoVehicles()
    }
  }

  render() {
    const { order } = this.state
    return (
      <div className="body-content" style={{minHeight: '600px'}}>
        <div className="content-header">
          <h1>Vehicles</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          this.props.accountStatusRestricted !== true &&
          <div className="content-context-actions">
            <Link to="/vehicles/new">
              <PlusIcon />
            Add a vehicle</Link>
          </div>
        }

        <div className='flex-row-and-cols ai-c' style={{ marginBottom: 10 }}>
          {
            SessionStore.hasTeam() &&
            <div>
              <ValuationScopeSelect
                scope={this.state.scope}
                onChange={this.onScopeSelect}
              />
            </div>
          }
          <div className='flex-row-and-cols ai-c'>
            <div>Ordered by </div>
            <button
              className='link'
              onClick={this.onOrderClick}
              >
              {orderOptions.find((f) => f['value'] === order)[['name']]}
            </button>
          </div>
        </div>
        {
          this.state.isLoading && !this.state.loadingMore ?
            <Spinner className="spinner-page" /> :
            this.renderVehicles()
        }
      </div>
    )
  }
}
