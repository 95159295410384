import AppConstants from './AppConstants'
import SessionStore from '../stores/SessionStore'
import Formatters from './Formatters'


const emptyChoiceValue = AppConstants.emptyChoiceValue

export default {
  valuationForChoice: (data, choiceUID) => valuationForChoice(data, choiceUID),

  valuationHasEmptyData: function(data) {
    if (!data || data.error || !data.description || (data.description && data.description.has_valuation_data === false)) {
      return true
    }
    return false
  },

  isLimitedValuationNeedsFetch: function(data) {
    if (data && data.system && data.system.requires_explicit_fetch === true && !data.description) {
      return true
    }
    return false
  },

  populatePreviewDisplayDetails: function(valuationPreview, valuationPreviewChoices) {
    // Extract blackbook details to display during listing creation
    if (valuationPreview.make !== null && valuationPreview.model !== null && valuationPreview.series !== null && valuationPreview.style !== null) {
      return valuationPreview
    }

    for (let choice of valuationPreviewChoices) {
      if (choice.providerKey === 'black_book' && choice.selectedChoiceUID !== null) {
        const selectedChoice = valuationForChoice(choice.choices, choice.selectedChoiceUID)
        if (selectedChoice && selectedChoice.description) {
          const description = selectedChoice.description
          valuationPreview.make = description.make
          valuationPreview.model = description.model
          valuationPreview.series = description.series
          valuationPreview.style = description.style
          return valuationPreview
        }
      }
    }
    return valuationPreview
  },

  carfaxIsConnect: function(carfax) {
    return carfax.results != null || carfax.error != null
  },

  hasCarfaxPreviewData: function(carfax) {
    if (!carfax) return false
    if (this.carfaxIsConnect(carfax)) {
      return Boolean(!carfax.errors && carfax.results && carfax.results.dealerReport)
    } else {
      return !carfax.errors && carfax.carfax_response.vin_info.inventory.in_inventory !== 'N'
    }
  },

  carfaxRequiresReauth: function(carfax) {
    if (!carfax) return false
    if (this.carfaxIsConnect(carfax)) {
      return carfax.error && carfax.error.code === 403
    } else {
      return false
    }
  },

  carfaxIsPurchasable: function(carfax) {
    if (!carfax) return false
    if (this.carfaxIsConnect(carfax)) {
      return carfax.error && carfax.error.code === 402
    } else {
      return !carfax.errors && carfax.carfax_response.vin_info.inventory.in_inventory === 'N'
    }
  },

  carfaxSnapshotStatus: function(carfax) {
    const errorStatus = {
      type: 'error',
      message: `Error loading ${AppConstants.providerNames.carfax}`
    }

    const ignoreStatus = {
      type: 'ignore',
      message: ''
    }

    const warnStatus = {
      type: 'warn',
      message: `${AppConstants.providerNames.carfax} reports issues with this vehicle`
    }

    const goodStatus = {
      type: 'good',
      message: `${AppConstants.providerNames.carfax} is clean`
    }

    if (this.carfaxRequiresReauth(carfax)) return errorStatus
    if (!this.hasCarfaxPreviewData(carfax)) return ignoreStatus
    if (this.carfaxIsConnect(carfax)) {
      if (carfax.results?.dealerReport?.fourPillars?.accident?.hasAccidents) {
        return warnStatus
      } else {
        const searchableText = carfax.results?.dealerReport?.fourPillars?.accident?.searchableText
        if (searchableText === 'rollback' || searchableText === 'alert' || searchableText === 'accident' || searchableText === 'orangeCar' || searchableText === 'collisionRepair' || searchableText === 'certifiedCollisionRepair') {
          return warnStatus
        } else {
          return goodStatus
        }
      }
    } else {
      const info = carfax.carfax_response.vin_info
      if (parseInt(info.accident_count, 10) > 0 || info.accident_indicator !== 'N' || info.branded_title_indicator.branded_title_indicator_value !== 'N' || info.damage_indicator.damage_indicator_value !== 'N' || info.frame_damage_indicator.frame_damage_value !== 'N' || info.major_problem_indicator.major_problem_value !== 'N') {
        return warnStatus
      } else {
        return goodStatus
      }
    }
  },

  vintelSnapshotStatus: function(vintel) {
    const status = vintel?.DASHBOARD?.dsrStatus
    if (status === 'normal') {
      return 'good'
    } else if (status === 'exception') {
      return 'warn'
    } else {
      return 'error'
    }
  },

  vintelDashboardItems: function(vintel) {
    const items = [
      {
        key: 'dsrStatus',
        title: 'Status',
      },
      {
        key: 'checkEngineStatus',
        title: 'Check engine light',
      },
      {
        key: 'otherDashLightStatus',
        title: 'Other dash lights',
      },
      {
        key: 'troubleCodes',
        title: 'Trouble codes',
      },
      {
        key: 'codesReset',
        title: 'Codes reset',
      },
      {
        key: 'emissionsStatus',
        title: 'Emissions',
      },
      {
        key: 'batteryInspect',
        title: 'Battery',
      },
      {
        key: 'electricalInspect',
        title: 'Electrical',
      },
    ]

    const dashboard = vintel.DASHBOARD
    return items.map(item => {
      const { key, title } = item
      const iconDataKey = `${key}StatusIcon`.replace(/(?:Status)+/, 'Status')
      const iconData = dashboard[iconDataKey] ?? {} 
      const { display: value, icon: iconUrl } = iconData
      if (!value) return null
      return {
        title,
        iconUrl,
        key,
        value,
      }
    })
  },

  autoCheckSummaryIsComplete: function(autoCheckPreview) {
    return Boolean(autoCheckPreview && autoCheckPreview.score && autoCheckPreview.typical_high_score && autoCheckPreview.typical_low_score)
  },

  noSummaryText: function(reason) {
    switch (reason) {
      case AppConstants.noAppraisalVehicleLackingData:
        return 'Not enough vehicle data'
      default:
        return 'No Appraisal'
    }
  },

  extendedVehicleDetails: function(valuation) {
    let vehicleDetails = []

    if (valuation) {
      if (valuation.body_class && valuation.body_class.length > 0) {
        vehicleDetails.push({label: 'Body class', value: valuation.body_class})
      }

      if (valuation.drive_type && valuation.drive_type.length > 0) {
        vehicleDetails.push({label: 'Drive type', value: valuation.drive_type})
      }

      if (valuation.engine_description?.length > 0) {
        vehicleDetails.push({label: 'Engine', value: valuation.engine_description})
      }

      if (valuation.engine_cylinders && valuation.engine_cylinders.length > 0) {
        vehicleDetails.push({label: 'Cylinders', value: valuation.engine_cylinders})
      }

      if (valuation.transmission && valuation.transmission.length > 0) {
        vehicleDetails.push({label: 'Transmission', value: valuation.transmission})
      }

      if (valuation.transmission_description && valuation.transmission_description.length > 0) {
        vehicleDetails.push({label: 'Transmission Details', value: valuation.transmission_description})
      }

      if (valuation.fuel_type && valuation.fuel_type.length > 0) {
        vehicleDetails.push({label: 'Fuel type', value: valuation.fuel_type})
      }

      if (valuation.engine_displacement && valuation.engine_displacement.length > 0) {
        vehicleDetails.push({label: 'Displacement', value: `${valuation.engine_displacement}L`})
      }

      if (valuation.num_doors && valuation.num_doors.length > 0) {
        vehicleDetails.push({label: 'Doors', value: valuation.num_doors})
      }

      if (valuation.msrp > 0) {
        vehicleDetails.push({label: 'MSRP', value: Formatters.formatCurrency(valuation.msrp)})
      }

      if (valuation.exterior_color?.length > 0) {
        vehicleDetails.push({label: 'Exterior', value: valuation.exterior_color})
      }

      if (valuation.interior_color?.length > 0) {
        vehicleDetails.push({label: 'Interior', value: valuation.interior_color})
      }
    }
    return vehicleDetails
  },

  structuredAddDeducts: function(adItems, adLogic, shouldSort = true) {
    // Deep copy
    var addDeductsPool = JSON.parse(JSON.stringify(adItems))
    var includedAddDeducts = []
    let i = addDeductsPool.length

    // Is this just for the string that represents standard equipment
    while (i--) {
      let item = addDeductsPool[i]
      if (item['description']['included'] === true) {
        if (!adLogic || !adLogic[item.description.uid]) {
          includedAddDeducts.push(item)
          addDeductsPool.splice(i, 1)
        } else {
          // We might have a package that is included, and need to only add to std equip if not exclusionary
          let hasExclusion = false
          for (let logicKey of Object.keys(adLogic)) {
            if (adLogic[logicKey]['included'] === false) {
              hasExclusion = true
              break
            }
          }
          if (hasExclusion === false) {
            // Remove it from the items list
            includedAddDeducts.push(item)
            addDeductsPool.splice(i, 1)
          }
        }

      }
    }

    var structuredAddDeducts = []
    var exclusiveAddDeducts = []

    if (adLogic) {
      for (let logicKey of Object.keys(adLogic)) {
        var targetParent = addDeductsPool.find(parentCandidate => parentCandidate.description.uid === logicKey)
        if (targetParent) {
          let logicChildren = adLogic[logicKey]

          // Get rid of the parent, so that it doesn't show up twice, since it will be in the top structured packages
          addDeductsPool = addDeductsPool.filter(item => item.description.uid !== targetParent.description.uid)

          targetParent['children'] = []

          // foundInclude later determines if it goes in structured or exclusive
          var foundInclude = false
          for (let logicChild of logicChildren) {
            let targetChild = addDeductsPool.find(childCandidate => childCandidate.description.uid === logicChild.uid)
            if (targetChild) {
              if (logicChild.included === true) {
                // Create our structure
                foundInclude = true
                targetChild['parent'] = targetParent.description.uid
                targetParent['children'].push(Object.assign(targetChild))
                if (targetParent.description.user_added === true) {
                  targetChild.description.user_added = true
                  targetChild.disabled = true
                }
              }
            }
          }

          if (foundInclude) {
            structuredAddDeducts.push(targetParent)
          } else {
            exclusiveAddDeducts.push(targetParent)
          }
        }
      }
    }

    // Combine exclusives and the pool, then sort
    addDeductsPool = addDeductsPool.concat(exclusiveAddDeducts)
    if (shouldSort) {
      addDeductsPool = addDeductsPool.sort((a, b) => (a.description.name > b.description.name) ? 1 : -1)
    }

    // Structured appears at the beginning of the list
    structuredAddDeducts = structuredAddDeducts.concat(addDeductsPool)
    return {'includedAddDeducts': includedAddDeducts, 'structuredAddDeducts': structuredAddDeducts}
  },

  onAddDeductsSelect: function(addDeducts, adLogic, selectedItem) {
    var updatedAddDeducts = Object.assign(addDeducts)
    // Loop through all items that are parents and see if this belongs to one, since individuals can belong to many
    if (selectedItem.parent) {
      for (let logicItemKey of Object.keys(adLogic)) {
        let match = adLogic[logicItemKey].find(f => f.uid === selectedItem.description.uid)
        if (match) {
          var parentItem = updatedAddDeducts.find(obj => obj.description.uid === logicItemKey)
          if (parentItem && parentItem.description.user_added === true) {
            return
          }
        }
      }
    }

    // If it's a parent, go through all non-parents and deselect first.  This clears the slate for package selection
    if (selectedItem?.children?.length > 0) {
      for (let item of addDeducts) {
        if (!item.children || item.children.length === 0) {
          item.description.user_added = false
          item.disabled = false
        }
      }
    }

    var newTargetIsAdded = !selectedItem.description.user_added
    selectedItem.description.user_added = newTargetIsAdded
    if (selectedItem.children && selectedItem.children.length > 0) {
      for (let targetChild of selectedItem.children) {
          // Auto select / deselect children
          targetChild.description.user_added = newTargetIsAdded
          targetChild.disabled = newTargetIsAdded
      }
    }

    // Check for exclusions
    if (newTargetIsAdded) {
      if (adLogic && adLogic[selectedItem.description.uid]) {
        const logicItem = adLogic[selectedItem.description.uid]
        for (let child of logicItem) {
          if (child.included === false) {
            var targetExclusion = updatedAddDeducts.find(obj => obj.description.uid === child.uid)
            if (targetExclusion) {
              targetExclusion.description.user_added = false
            }
          }
        }
      }
    }

    return updatedAddDeducts

  },

  formattedAddDeductsDisplay: function(results, isShowingFullIncludedAddDeducts) {
    const adItems = results.add_deducts && results.add_deducts.items && results.add_deducts.items.length > 0 ? results.add_deducts.items : null

    var selectedItems = []
    if (adItems) {
      for (let item of adItems) {
        const desc = item.description
        if (desc.included === false && desc.user_added === true) {
          selectedItems.push(desc.name)
        }
      }
    }
    if (selectedItems.length > 0) {
      var displayItems = selectedItems
      var combinedString = ''
      var isTruncated = false
      if (selectedItems.length > 6 && !isShowingFullIncludedAddDeducts) {
        displayItems = selectedItems.slice(0,5)
        combinedString = `${displayItems.join(', ')}, ...`
        isTruncated = true
      } else {
        combinedString = selectedItems.join(', ')
      }

      return {
        combinedString: combinedString,
        isTruncated: isTruncated
      }
    } else {
      return null
    }
  },

  isMileageInBroadMatchRange: function(mileage, baselineMileage) {
    if (!mileage || mileage === AppConstants.noMileageValue) {
      return false
    }
    const range = [
      ((baselineMileage - 10000) - (baselineMileage * 0.1)),
      ((baselineMileage + 10000) + (baselineMileage * 0.1))
    ]
    return (mileage >= range[0] && mileage <= range[1])
  },

  isMileageInNarrowMatchRange: function(mileage, baselineMileage) {
    if (!mileage || mileage === AppConstants.noMileageValue) {
      return false
    }
    const range = [
      baselineMileage * 0.9,
      baselineMileage * 1.1
    ]
    return (mileage >= range[0] && mileage <= range[1])
  },

  llmDistanceChoiceValues: function(currentValue) {
    // The server does a best fit, and the value could be something like 237 miles
    // This slots that choice in, and replaces the nearest fixed value
    currentValue = currentValue === null ? AppConstants.llmAllRadiusValue : currentValue
    var distanceValues = []
    if (currentValue < 35) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(20)
    }

    if (currentValue >= 35 && currentValue < 75) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(50)
    }

    if (currentValue >= 75 && currentValue < 150) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(100)
    }

    if (currentValue >= 150 && currentValue < 250) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(200)
    }

    if (currentValue >= 250 && currentValue < 350) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(300)
    }

    if (currentValue >= 350 && currentValue < 450) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(400)
    }

    if (currentValue >= 450 && currentValue < 550) {
      distanceValues.push(currentValue)
    } else {
      distanceValues.push(500)
    }

    if (currentValue >= 550) {
      distanceValues.push(currentValue)
    }

    if (currentValue !== AppConstants.llmAllRadiusValue) {
      distanceValues.push(AppConstants.llmAllRadiusValue)
    }
    return distanceValues
  },

  generateClientID: function(baseString) {
    // In order to register a specific valution with the valuationStore
    // we need a unique key.  Add some randomness to prevent dupes
    // FIXME: I think the random logic is flawed.  If we have the same valuation open in 2 place, they both should update
    const r = Math.floor(Math.random() * 50000)
    return `${baseString}-${r}`
  },

  rangeForValuesFromHash: (valuesHash) => rangeForValuesFromHash(valuesHash),

  xPosForValue: function(val, xOffset, width, lowVal, highVal) {
    let xPosMult = (val - lowVal) / (highVal - lowVal)
    if (isNaN(xPosMult)) {
      xPosMult = 1
    }
    return xOffset + (width * xPosMult)
  },

  drilldownModelChoices: function(makesModels, selectedMake) {
    if (makesModels === null || makesModels.length === 0 || selectedMake === null) {
      return []
    }

    return makesModels[selectedMake]
  },

  drilldownSeriesChoices: function(makesModels, selectedMake, selectedModel) {
    if (makesModels === null || makesModels.length === 0 || selectedMake === null || selectedModel === null) {
      return []
    }

    return makesModels[selectedMake][selectedModel]
  },

  drilldownStyleChoices: function(makesModels, selectedMake, selectedModel, selectedSeries) {
    if (makesModels === null || makesModels.length === 0 || selectedMake === null || selectedModel === null || selectedSeries === null) {
      return []
    }

    const seriesData = makesModels[selectedMake][selectedModel]
    let stylesData = []
    if (selectedSeries === AppConstants.emptySeriesValue) {
      stylesData = seriesData[""]
    } else {
      stylesData = seriesData[selectedSeries]
    }

    return stylesData
  },

  isDrilldownComplete: function(selectedDrilldownValues) {
    if (selectedDrilldownValues !== null && selectedDrilldownValues.selectedYearId !== null && selectedDrilldownValues.selectedYearId !== undefined && selectedDrilldownValues.selectedMakeId !== null && selectedDrilldownValues.selectedModelId !== null && selectedDrilldownValues.selectedSeriesId !== null && selectedDrilldownValues.selectedStyleId !== null) {
      return true
    }
    return false
  },

  formattedProvidersForShare: function(providers, includeCarbly) {
    var newProviders = null
    if (providers && providers.length > 0) {
      newProviders = providers.map((p) => {
        return {
          name: p,
          selected: true
        }
      })

      if (includeCarbly) {
        newProviders.unshift({
          name: 'carbly',
          selected: true
        })
      }
    }

    return newProviders

  },

  dataForTrendGraph: function(timelineData, containerWidth, containerHeight) {
    if (!timelineData) {
      return null
    }
    let rawRangeData = {}
    // Manheim sometimes gives us a timeline of all zeros
    let hasNonZeroData = false
    for (let key of Object.keys(timelineData)) {
      const input = timelineData[key]
      if ('wholesale' in input) {
        if (input['wholesale'] > 0) {
          hasNonZeroData = true
        }
        rawRangeData[`w-${key}`] = input['wholesale']
      }
      if ('retail' in input) {
        if (input['retail'] > 0) {
          hasNonZeroData = true
        }
        rawRangeData[`r-${key}`] = input['retail']
      }
    }

    if (hasNonZeroData === false) {
      return null
    }

    const rangeValues = this.rangeForValuesFromHash(rawRangeData)
    const spread = containerWidth / 24
    const xPositions = {
      last_year: 0,
      last_six_months: 6 * spread,
      last_two_months: 10 * spread,
      next_month: 13 * spread,
      next_year: 24 * spread
    }

    let elements = {}
    elements['xPositions'] = xPositions
    elements['wholesale'] = {}
    elements['wholesale']['lines'] = []
    elements['wholesale']['dots'] = []
    elements['retail'] = {}
    elements['retail']['lines'] = []
    elements['retail']['dots'] = []


    for (let typeKey of ['wholesale', 'retail']) {
      let lastXPos = -1
      let lastYPos = -1

      for (let key of Object.keys(xPositions)) {
        if (timelineData[key][typeKey] === undefined) {
          continue
        }
        if (timelineData[key][typeKey] !== 0) {
          const xPos = xPositions[key]
          const yPos = yPositionForValue(timelineData[key][typeKey], rangeValues.highValue, rangeValues.lowValue, containerHeight)
          elements[typeKey]['dots'].push(
            {x: xPos, y: yPos, key: key}
          )

          if (lastXPos === -1) {
            lastXPos = xPos
            lastYPos = yPos
          } else {

            elements[typeKey]['lines'].push(
              {x1: lastXPos, x2: xPos, y1: lastYPos, y2: yPos}
            )
            lastXPos = xPos
            lastYPos = yPos
          }

        }
      }
    }

    return elements
  },

  formatAppraisalData: function(appraisalValues) {
    if (!appraisalValues) { return }
    var columnKeys = Object.keys(appraisalValues)

    // Loop through all of them to get universe of adjustment keys
    var adjustmentsKeys = []
    for (let column of columnKeys) {
      if (appraisalValues[column] && appraisalValues[column]['adjustments']) {
        for (let adjustment of appraisalValues[column]['adjustments']) {
          if (!adjustmentsKeys.includes(adjustment.name)) {
            adjustmentsKeys.push(adjustment.name)
          }
        }
      }
    }

    var adjustmentRows = []
    for (let adjustmentKey of adjustmentsKeys) {
      let newRow = {name: adjustmentLabelForKey(adjustmentKey), values: []}
      for (let columnKey of columnKeys) {
        let newVal = 0
        if (appraisalValues[columnKey]) {
          let foundVal = appraisalValues[columnKey]['adjustments'].find(a => a['name'] === adjustmentKey)
          if (foundVal) {
            newVal = foundVal['value']
          }
        }
        newRow.values.push(newVal)
      }
      adjustmentRows.push(newRow)
    }

    var adjustmentsData = {
      titles: columnKeys.map((colKey) => adjustmentLabelForKey(colKey)),
      base: columnKeys.map((k) => {return(appraisalValues[k] && appraisalValues[k]['base'] ? appraisalValues[k]['base'] : 0)}),
      adjustments: adjustmentRows,
      adjusted: columnKeys.map((k) => {return(appraisalValues[k] && appraisalValues[k]['adjusted'] ? appraisalValues[k]['adjusted'] : 0)}),
    }

    var hasResults = false
    if (adjustmentsData && adjustmentsData.adjusted && adjustmentsData.adjusted.length > 0) {
      for (let a of adjustmentsData.adjusted) {
        if (a > 0) {
          hasResults = true
          break
        }
      }
    }

    return hasResults ? adjustmentsData : null
  },

  formatMatchupData: function(comparison, overallCondition) {
    const user = SessionStore.user
    const activeCards = user && user.active_cards ? user.active_cards : null
    const trialCards = user && user.trial_cards ? user.trial_cards : null
    const allCards = activeCards.concat(trialCards)

    var dataRows = []

    var initialMarkerPrice = null
    if (comparison['carbly'] && comparison['carbly'].conditions) {
      dataRows.push(this.valuesRow('carbly', comparison, ['rough', 'average', 'clean']))
      if (overallCondition) {
        initialMarkerPrice = comparison['carbly']['conditions'][overallCondition]
      }
    }

    for (let providerKey of allCards) {
      if (comparison[providerKey]?.conditions) {
        switch (providerKey) {
          case 'black_book':
            dataRows.push(this.valuesRow(providerKey, comparison, ['rough', 'average', 'clean', 'xclean']))
            break;
          case 'manheim':
            dataRows.push(this.valuesRow(providerKey, comparison, ['below', 'average', 'above']))
            break;
          case 'kbb':
            dataRows.push(this.valuesRow(providerKey, comparison, ['fair', 'good', 'excellent']))
            break;
          case 'nada':
            dataRows.push(this.valuesRow(providerKey, comparison, ['rough', 'average', 'clean']))
            break;
          case 'universe':
            dataRows.push(this.valuesRow(providerKey, comparison, ['all']))
            break;
          case 'pin':
            dataRows.push(this.valuesRow(providerKey, comparison, ['all']))
            break;
          case 'pmr':
            dataRows.push(this.valuesRow(providerKey, comparison, ['rough', 'average', 'clean']))
            break;
          case 'galves':
            dataRows.push(this.valuesRow(providerKey, comparison, ['all']))
            break;

          default:

        }
      }
    }

    var minPrice = 999999
    var maxPrice = 0
    for (let row of dataRows) {
      for (let vals of row.values) {
        if (vals.price < minPrice) { minPrice = vals.price }
        if (vals.price > maxPrice) { maxPrice = vals.price }
      }
    }

    return {
      minPrice: minPrice,
      maxPrice: maxPrice,
      rows: dataRows,
      initialMarkerPrice: initialMarkerPrice
    }
  },

  valuesRow: function(providerKey, comparison, conditions) {
    var vals = []
    for (let condition of conditions) {
      if (comparison[providerKey]['conditions'][condition]) {
        vals.push({"price": comparison[providerKey]['conditions'][condition], "condition": condition})
      }
    }

    if (vals.length === 0 && comparison[providerKey]['conditions']['all']) {
      vals.push({"price": comparison[providerKey]['conditions']['all'], "condition": "all"})
    }

    return {
      providerKey: providerKey,
      providerName: AppConstants.providerShortNames[providerKey],
      providerFullName: AppConstants.providerNames[providerKey],
      values: vals
    }
  },


  formatMileageFamilyData: function(mileageFamily, dataType) {
    var i = 0
    var structuredData = []
    var lowestPriceVal = 999999
    var highestPriceVal = 0
    var bbData = []
    var kbbData = []
    var manheimData = []
    var nadaData = []
    const baseType = mileageFamily[dataType]
    for (let mileage of mileageFamily.mileages) {

      const bb = baseType.black_book[i]
      if (bb < lowestPriceVal) { lowestPriceVal = bb}
      if (bb > highestPriceVal) { highestPriceVal = bb}
      bbData.push({
        x: mileage,
        y: baseType.black_book[i],
        formattedXValue: Formatters.formatThousands(mileage),
        formattedYValue: Formatters.formatCurrency(baseType.black_book[i])
      })

      const kbb = baseType.kbb[i]
      if (kbb < lowestPriceVal) { lowestPriceVal = kbb}
      if (kbb > highestPriceVal) { highestPriceVal = kbb}
      kbbData.push({
        x: mileage,
        y: baseType.kbb[i],
        formattedXValue: Formatters.formatThousands(mileage),
        formattedYValue: Formatters.formatCurrency(baseType.kbb[i])
      })

      const mh = baseType.manheim[i]
      if (mh < lowestPriceVal) { lowestPriceVal = mh}
      if (mh > highestPriceVal) { highestPriceVal = mh}
      manheimData.push({
        x: mileage,
        y: baseType.manheim[i],
        formattedXValue: Formatters.formatThousands(mileage),
        formattedYValue: Formatters.formatCurrency(baseType.manheim[i])
      })

      const nada = baseType.nada[i]
      if (nada < lowestPriceVal) { lowestPriceVal = nada}
      if (nada > highestPriceVal) { highestPriceVal = nada}
      nadaData.push({
        x: mileage,
        y: baseType.nada[i],
        formattedXValue: Formatters.formatThousands(mileage),
        formattedYValue: Formatters.formatCurrency(baseType.nada[i])
      })

      i++
    }

    structuredData.push({
      "id": AppConstants.providerNames['black_book'],
      "provider_key": "black_book",
      "color": "#666",
      "data": bbData
    })

    structuredData.push({
      "id": AppConstants.providerNames['kbb'],
      "provider_key": "kbb",
      "color": "#0054a6",
      "data": kbbData
    })

    structuredData.push({
      "id": AppConstants.providerNames['manheim'],
      "provider_key": "manheim",
      "color": "#f26521",
      "data": manheimData
    })

    structuredData.push({
      "id": AppConstants.providerNames['nada'],
      "provider_key": "nada",
      "color": "#9d0a0f",
      "data": nadaData
    })

    return {structuredData: structuredData, lowestPriceVal: lowestPriceVal, highestPriceVal: highestPriceVal}
  },

  normalizedColor: function(srcColor) {
    if (!srcColor || srcColor === 'No Color' || srcColor === 'N/A' || srcColor === 'Cloth') { return null }
    var colorClass = srcColor.toLowerCase()
    var colorName = srcColor
    switch (colorClass) {
      case 'burgundy':
      case 'burg':
      case 'burgan':
      case 'burgandy':
        colorClass = 'maroon'
        colorName = 'Burgundy'
        break
      case 'gry':
      case 'gy':
      case 'gra':
        colorName = 'Grey'
      case 'shale':
      case 'primer':
      case 'moonstone':
        colorClass = 'grey'
        break
      case 'ash':
      case 'graphite':
      case 'gun':
        colorClass = 'grey'
        break
      case 'pewter':
        colorClass = 'lightslategrey'
        break
      case 'bg':
        colorName = 'Beige'
      case 'cashmere':
      case 'cream':
        colorClass = 'beige'
        break
      case 'sand':
        colorClass = 'tan'
        break
      case 'bronze':
        colorClass = 'sandybrown'
        break
      case 'br':
        colorClass = 'brown'
        colorName = 'Brown'
        break
      case 'ye':
      case 'yl':
        colorClass = 'yellow'
        colorName = 'Yellow'
        break
      case 'cognac':
        colorClass = 'indianred'
        break
      case 're':
      case 'rd':
        colorClass = 'red'
        colorName = 'Red'
        break
      case 'charcoal':
        colorClass = 'dimgray'
        break
      case 'grn':
        colorClass = 'green'
        colorName = 'Green'
        break
      case 'champagne':
        colorClass = 'palegoldenrod'
        break
      case 'rust':
        colorClass = 'firebrick'
        break
      case 'dark blue':
        colorClass = 'darkblue'
        break
      case 'light blue':
        colorClass = 'lightblue'
        break
      case 'dark green':
        colorClass = 'darkgreen'
        break
      case 'rose':
        colorClass = 'red'
        break
      case 'copper':
        colorClass = 'chocolate'
        break
      case 'lt green':
        colorClass = 'lightgreen'
        colorName = 'Light Green'
        break
      case 'wh':
      case 'wht':
        colorClass = 'white'
        colorName = 'White'
        break
      case 'ebony':
        colorClass = 'black'
        break
      case 'bk':
        colorClass = 'black'
        colorName = 'Black'
        break
      case 'tn':
        colorClass = 'tan'
        colorName = 'Tan'
        break
      default:
        if (colorClass.includes(' red') || colorClass.includes('ruby') || colorClass.includes('red candy')) {
          colorClass = 'red'
        } else if (colorClass.includes('grey') || colorClass.includes('gray') || colorClass.includes('stone') || colorClass.includes('cement') || colorClass.includes('titanium') || colorClass.includes('slate') || colorClass.includes('graphite')) {
          colorClass = 'grey'
        } else if (colorClass.includes('black') || colorClass.includes('pepper')) {
          colorClass = 'black'
        } else if (colorClass.includes('blue')) {
          colorClass = 'blue'
        } else if (colorClass.includes('silver') || colorClass.includes('steel')) {
          colorClass = 'silver'
        } else if (colorClass.includes('pewter')) {
          colorClass = '#686861'
        } else if (colorClass.includes('gold')) {
          colorClass = 'gold'
        } else if (colorClass.includes('white') | colorClass.includes('pearl')) {
          colorClass = 'white'
        } else if (colorClass.includes('claret') || colorClass.includes('garnet')) {
          colorClass = 'darkred'
        } else if (colorClass.includes('green')) {
          colorClass = 'green'
        } else if (colorClass.includes('yellow') || colorClass.includes('parchment')) {
          colorClass = 'yellow'
        } else if (colorClass.includes('orange')) {
          colorClass = 'orange'
        } else if (colorClass.includes('purple')) {
          colorClass = 'purple'
        } else if (colorClass.includes('brown') || colorClass.includes('cocoa') || colorClass.includes('almond') || colorClass.includes('cappuccino') || colorClass.includes('dune') || colorClass.includes('camel') || colorClass.includes('saddle') || colorClass.includes('mocha') || colorClass.includes('java') || colorClass.includes('coffee') || colorClass.includes('earth') || colorClass.includes('walnut') || colorClass.includes('espresso') || colorClass.includes('nutmeg') || colorClass.includes('sable') || colorClass.includes('sepia')) {
          colorClass = 'brown'
        } else if (colorClass.includes('beige') || colorClass.includes('khaki')) {
          colorClass = 'beige'
        } else if (colorClass.includes('taupe')) {
          colorClass = '#ab9a8b'
        } else if (colorClass.includes('bisque')) {
          colorClass = '#f2d2bd'
        } else if (colorClass.includes('oyster')) {
          colorClass = '#dc9b1'
        } else if (colorClass.includes('amber')) {
          colorClass = '#ffbf00'
        } else if (colorClass.includes('ecru')) {
          colorClass = '#c2b280'
        } else if (colorClass.includes('alien')) {
          colorClass = '#aac35b'
        } else if (colorClass.includes('bronze')) {
          colorClass = 'sandybrown'
        }
        break;
    }

    return {
      colorClass: colorClass,
      colorName: colorName
    }
  }
}


function valuationForChoice(data, choiceUID) {
  if (choiceUID === emptyChoiceValue || data === null || data === undefined) {
    return null
  }

  for (let result of data) {
    if (result.description && result.description.uid) {
      if (result.description.uid === choiceUID) {
        return result
      }
    }
  }
  return null
}

function yPositionForValue(value, highValue, lowValue, containerHeight) {
  if (highValue === lowValue) {
    return containerHeight / 2
  }
  const adjustedVal = highValue - value
  return (adjustedVal / (highValue - lowValue)) * containerHeight
}

function rangeForValuesFromHash(valuesHash) {
  let localLow = 9999999
  let localHigh = -1

  for (var key of Object.keys(valuesHash)) {
    if (valuesHash[key] > 0 && valuesHash[key] < (localLow)) {
      localLow = valuesHash[key]
    }
    if (valuesHash[key] > localHigh) {
      localHigh = valuesHash[key]
    }
  }
  return {lowValue: localLow, highValue: localHigh}
}

function adjustmentLabelForKey(adjustmentKey) {
  switch (adjustmentKey) {
    case 'wholesale':
      return 'Wholesale'
    case 'retail':
      return 'Retail'
    case 'auction':
      return 'Auction'
    case 'tradein':
      return 'Trade-In'
    case 'market_ready':
      return 'Market Ready'
    case 'fair_purchase':
      return 'Fair Purchase Price'
    case 'lending':
      return 'Lending'
    case 'loan':
      return 'Loan'
    case 'mileage':
      return 'Mileage'
    case 'private_party':
      return 'Private Party'
    case 'region':
      return 'Region'
    case 'add_deduct':
      return 'Options'
    default:
      return adjustmentKey
  }
}
