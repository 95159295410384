import { gql } from '@apollo/client'
import {
  VENUE_LISTING_PRIVATE_FIELDS,
  VENUE_LISTING_PUBLIC_FIELDS,
} from './fragments'

const ON_VENUE_LISTING_CHANGED_PUBLIC = gql`
  ${VENUE_LISTING_PUBLIC_FIELDS}
  subscription OnVenueListingChangedPublic($id: ID) {
    onVenueListingChangedPublic(id: $id) {
      ...VenueListingPublicFields
    }
  }
`

const ON_VENUE_LISTING_CHANGED_PRIVATE = gql`
  ${VENUE_LISTING_PRIVATE_FIELDS}
  subscription OnVenueListingChangedPrivate($id: ID) {
    onVenueListingChangedPrivate(id: $id) {
      ...VenueListingPrivateFields
    }
  }
`

export {
  ON_VENUE_LISTING_CHANGED_PUBLIC,
  ON_VENUE_LISTING_CHANGED_PRIVATE,
}
