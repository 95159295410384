import { useEffect, useState } from 'react'

const useNetworkConnectivity = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const events = ['online', 'offline']
    const cb = () => setIsOnline(navigator.onLine)
    events.forEach(ev => window.addEventListener(ev, cb))

    return () => {
      events.forEach(ev => window.removeEventListener(ev, cb))
    }
  }, [])

  return isOnline
}

export default useNetworkConnectivity
