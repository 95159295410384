import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, CustomInput } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import platformFunctions from '../../platformFunctions'
import TFSelect from '../common/TFSelect'
import TFMinMaxSelectors from '../common/TFMinMaxSelectors'
import AuctionsSearchMileageRangeSlider from './AuctionsSearchMileageRangeSlider'
import Spinner from '../common/Spinner'

const MIN_MAX_SELECTOR_WIDTH = 132
export default class AuctionListingsGlobalFilterModal extends React.Component {

  state = {
    isLoadingSavedSearches: true,
    globalFilterSavedSearch: null,
    isSavingGlobalFilters: false,
    savedSearchHasChanged: false,
    globalFiltersEnabled: SessionStore.globalAuctionFilterEnabled()
  }

  componentDidMount() {
    this.clientID = 'ss-global-filter'
    AuctionStore.on('saved_searches_received', this.savedSearchesDidChange)
    SessionStore.on('user_auction_filters_changed', this.toggleDidChange)
    AuctionsActionCreators.loadSavedSearches()

    platformFunctions.logAnalyticsEvent("Auctions", "Opened Filter Modal")
  }

  componentWillUnmount() {
    AuctionStore.removeListener('saved_searches_received', this.savedSearchesDidChange)
    AuctionStore.removeListener('user_auction_filters_changed', this.toggleDidChange)
  }

  toggleDidChange = () => {
    this.setState({
      globalFiltersEnabled: SessionStore.globalAuctionFilterEnabled()
    })
  }

  savedSearchesDidChange = () => {
    var globalFilterSavedSearch = AuctionStore.savedSearches?.global

    // FIXME: error handling for no global
    if (globalFilterSavedSearch) {
      globalFilterSavedSearch = AuctionFunctions.sanitizeSearchDefaults(globalFilterSavedSearch)
    }

    this.setState({
      globalFilterSavedSearch,
      savedSearchHasChanged: true,
      isLoadingSavedSearches: false
    })
  }

  toggleFilterState = () => {
    const { globalFiltersEnabled } = this.state
    UserActionCreators.toggleUserGlobalAuctionFilters(!globalFiltersEnabled)
    this.setState({
      globalFiltersEnabled: !globalFiltersEnabled
    })
  }

  saveFilters = (closeWhenDone = true) => {
    const { globalFilterSavedSearch } = this.state
    const uuid = globalFilterSavedSearch.uuid

    this.setState({ isSavingGlobalFilters: true })

    var params = AuctionFunctions.savedSearchServerParams(globalFilterSavedSearch, '', {})
    params = {listing_search: AuctionFunctions.globalFiltersServerParams(params)}
    
    AuctionsActionCreators.updateSavedSearch(uuid, params, this.clientID)
  }

  searchDidChange = (paramName, value) => {
    var updatedGlobalFilterSavedSearch = {...this.state.globalFilterSavedSearch}
    updatedGlobalFilterSavedSearch[paramName] = value
    this.setState({
      globalFilterSavedSearch: updatedGlobalFilterSavedSearch
    })
  }


  render() {
    const { globalFilterSavedSearch, globalFiltersEnabled, isLoadingSavedSearches, savedSearchHasChanged, selectedMakes } = this.state

    var availableMakes = AuctionFunctions.makes().map((m) => {return {label: m, value: m}})

    const y = parseInt(new Date().getFullYear())
    var possibleYears = []
    for (let i = 1982; i <= y+1; i++) {
      possibleYears.push({ label: `${i}`, value: i})
    }
    
    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
        <ModalHeader className='modal-header'>
            Global Filters
        </ModalHeader>
        <ModalBody>

          {
            isLoadingSavedSearches &&
            <Spinner />
          }

          {
            !isLoadingSavedSearches &&
            <div>
              <div className='inline-checkbox' style={{ borderBottom: '1px solid #ddd', marginBottom: '10px', paddingBottom: '10px'}}>
                <CustomInput
                  type="switch"
                  id="global-toggle"
                  checked={globalFiltersEnabled}
                  name="global-toggle"
                  onChange={() => {
                    this.toggleFilterState()
                  }}
                  label={`Global Filters are ${globalFiltersEnabled ? 'on' : 'off'}`} />
              </div>

              <div>When global filters are enabled, <strong>Carbly will apply these conditions to all searches</strong>. This is a good way to eliminate cars that you never plan on purchasing.</div>

              <div style={{fontWeight: 'bold', marginTop: '20px', borderBottom: '1px solid #ddd'}}>Model Year</div>
              <div className='secondary-text'>Only ever show cars between these years</div>

              <TFMinMaxSelectors
                possibleValues={possibleYears}
                defaultMinValue={globalFilterSavedSearch.year_min}
                defaultMaxValue={globalFilterSavedSearch.year_max}
                minPlaceholder='None'
                maxPlaceholder='None'
                controlWidth={MIN_MAX_SELECTOR_WIDTH}
                onChange={(val) => {
                  this.searchDidChange('year_min', val.minValue)
                  this.searchDidChange('year_max', val.maxValue)
                }} />

              

              <div style={{fontWeight: 'bold', marginTop: '20px', borderBottom: '1px solid #ddd'}}>Mileage</div>
              <div className='secondary-text'>Only ever show cars with mileage in this range</div>
              <div style={{marginTop: '5px', marginBottom: '40px', padding: '0 10px'}}>
                <AuctionsSearchMileageRangeSlider
                  defaultMin={globalFilterSavedSearch.mileage_min}
                  defaultMax={globalFilterSavedSearch.mileage_max}
                  onAfterChange={(val) => {
                    this.searchDidChange('mileage_min', val[0])
                    this.searchDidChange('mileage_max', val[1])
                  }}
                />
                
              </div>


              <div style={{fontWeight: 'bold', marginTop: '20px', marginBottom: '10px', borderBottom: '1px solid #ddd'}}>Makes</div>
              <div className='secondary-text'>Ignore the following makes</div>

              <TFSelect
                name="newSelectedMake"
                isMulti={true}
                value={selectedMakes}
                onChange={(v) => {
                  this.setState({
                    selectedMakes: v
                  })
                }}
                closeMenuOnSelect={false}
                placeholder={selectedMakes && selectedMakes.length > 0 ? 'Ignore another make' : 'Ignore makes'}
                options={availableMakes} />

              <div className="modal-controls" style={{marginTop: '20px'}}>
                <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                <Button color="primary" disabled={!savedSearchHasChanged} onClick={this.saveFilters}>Save Filters</Button>
              </div>
            </div>
          }

        </ModalBody>
      </Modal>
    )
  }
}
