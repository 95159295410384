import React from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import Reorder from 'react-reorder'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'

export default class FoldersReorderModal extends React.Component {
  state = {
    folders: null
  }

  componentDidMount() {
    this.setState({
      folders: ValuationStore.folders,
      hasOrderChanged: false,
      isSubmitting: false
    })
    ValuationStore.on('folders_order_updated', this.ordersUpdated)
  }


  componentWillUnmount() {
    ValuationStore.removeListener('folders_order_updated', this.ordersUpdated)
  }

  ordersUpdated = () => {
    this.props.onCancel()
  }


  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    var newFolders = CommonFunctions.arrayMove(Object.assign(this.state.folders), previousIndex, nextIndex)
    this.setState({
      folders: newFolders,
      hasOrderChanged: true
    })
  }



  submit = () => {
    var newKeys = []
    for (let folder of this.state.folders) {
      newKeys.push(folder.id)
    }
    this.setState({
      isSubmitting: true
    })
    ValuationActionCreators.updateFoldersOrder(newKeys)
  }

  render() {
    const { folders, hasOrderChanged, isSubmitting } = this.state

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
        <ModalHeader className='modal-header'>Drag to Reorder Folders</ModalHeader>
        <ModalBody>
          <div className="folder-reorder-c">
            {
              folders && !isSubmitting &&
              <Reorder
                reorderId="my-list" // Unique ID that is used internally to track this list (required)
                reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                onReorder={this.onReorder} // Callback when an item is dropped (you will need this to update your state)
                autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                disabled={false} // Disable reordering (optional), defaults to false
                disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
              >
                {
                  folders.map((item) => (
                    <div key={item.id} className="folder">
                      {item.name}
                    </div>
                  ))
                }
              </Reorder>
            }
            {
              isSubmitting &&
              <Spinner />
            }
          </div>


          <div className="modal-controls" style={{marginTop: '20px'}}>
            <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
            <Button color="primary" disabled={!hasOrderChanged} onClick={this.submit}>Save New Folder Order</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
