import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import ReactGA from 'react-ga'
import ValuationActionCreators from '../api/actions/ValuationActionCreators'
import ValuationStore from '../api/stores/ValuationStore'
import Valuation from '../components/valuations/Valuation'
import IncompatibleBrowserPage from './IncompatibleBrowserPage'
import ProvidersLegal from '../components/common/ProvidersLegal'
import Spinner from '../components/common/Spinner'
import VersionChecker from '../components/common/VersionChecker'
import platformFunctions from '../platformFunctions'

const { detect } = require('detect-browser')
const browser = detect()

export default class Layout extends React.Component {
  state = {
    isLoadingValuation: true,
    incompatibleBrowser: false,
    sharedValuation: null,
    sharedProviders: null,
    hideNotes: true,
    hidePhotos: true,
    hideCarfax: true
  }

  constructor(props) {
    super(props)
    ValuationStore.isSharedValuation = true
  }

  componentDidMount() {
    if (browser && browser.name && browser.name === 'ie' && browser.version) {
      if (parseFloat(browser.version) < 11) {
        this.setState({ incompatibleBrowser: true })
      }
    }
    ValuationStore.on('shared_valuation_change', this.getSharedValuation)
    var params = window.location.href.split('/')
    const uuid = params[params.length - 1]
    ValuationActionCreators.loadSharedValuation(uuid)

    if (platformFunctions.isProduction()) {
      ReactGA.initialize(platformFunctions.googleAnalyticsTrackingID())
      platformFunctions.logAnalyticsEvent("Valuations", "Shared valuation view")
    }
  }

  componentWillUnmount() {
    ValuationStore.removeListener('shared_valuation_change', this.getSharedValuation)
  }

  getSharedValuation = () => {
    const sharedValuation = ValuationStore.sharedValuation
    this.setState({
      sharedValuation: sharedValuation,
      sharedErrors: ValuationStore.sharedValuationErrors,
      isLoadingValuation: false,
      sharedProviders: sharedValuation && sharedValuation.options && sharedValuation.options.providers ? sharedValuation.options.providers : null,
      hideNotes: sharedValuation && sharedValuation.options && sharedValuation.options.shareNotes === false,
      hidePhotos: sharedValuation && sharedValuation.options && sharedValuation.options.sharePhotos === false,
      hideCarfax: sharedValuation && sharedValuation.options && sharedValuation.options.shareCarfax === false
    })
  }


  render() {
    const { incompatibleBrowser, isLoadingValuation, sharedValuation, sharedErrors, sharedProviders, hideNotes, hidePhotos, hideCarfax } = this.state

    if (incompatibleBrowser === true) {
      return <IncompatibleBrowserPage />
    }

    if (isLoadingValuation === true) {
      return <Spinner />
    }

    const siteLink = 'https://getcarbly.com?utm_campaign=shared_report'

    return (
      <div className="page-c shared-page-c">

        {
          (sharedValuation || sharedErrors) &&
          <div>
            <div className="shared-valuation-header">
              <a href={siteLink} target="_blank" rel="noopener noreferrer"><img src="/images/carbly-nav-logo-dark.png" alt="Carbly" /></a>
              <div>Report generated by <a href={siteLink} target="_blank" rel="noopener noreferrer">Carbly VIN Scanner</a></div>
            </div>

            {
              !sharedErrors &&
              <Valuation valuationData={sharedValuation.vehicle} clientID='shared' limitProviders={sharedProviders} hideNotes={hideNotes} hidePhotos={hidePhotos} hideCarfax={hideCarfax}/>
            }

            {
              sharedErrors && !sharedValuation &&
              <div style={{fontSize: '1.4rem', textAlign: 'center', margin: '180px 0'}}>
                {sharedErrors}
              </div>
            }

            <div className="legal">
              <ProvidersLegal />
            </div>
          </div>

        }


        <VersionChecker />

        <ToastContainer
          autoClose={2000}
          draggable={false}
          transition={Slide}
          toastClassName='toast-c'
          progressClassName='toast-progress'
          />
      </div>

    )
  }
}
