import React from 'react'
import packageJson from '../../../package.json'
global.appVersion = packageJson.version

const CHECK_PERIOD = 60000 * 20 // 20 mins

export default class Spinner extends React.Component {

  state = {
    isWindowVisible: true,
    loading: true,
    isLatestVersion: false,
    showReloadNotification: false
  }

  componentDidMount() {
    this.checkForNewVersion()
  }

  handleWindowVisibilityChange = (isVisible) => {
    this.setState({ isWindowVisible: isVisible })
  }


  refreshCacheAndReload = () => {
    console.log('Reloading...')
    window.location.reload(true);
  }

  checkForNewVersion = () => {
    console.log("Checking for new version")

    fetch('/meta.json', {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    })
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.version;
      const currentVersion = global.appVersion;

      const shouldRefresh = this.semverGreaterThan(latestVersion, currentVersion);
      if (shouldRefresh) {
        console.log(`New version available - ${latestVersion}.`);
        this.setState({ showReloadNotification: true })
      } else {
        setTimeout(() => { this.checkForNewVersion() }, CHECK_PERIOD)
      }
    })
    .catch((error) => {
      console.log("Unable to check for new version: ", error)
      setTimeout(() => { this.checkForNewVersion() }, CHECK_PERIOD)
    })
  }

  // version from response - first param, local version second param
  semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  }

  render() {
    const { showReloadNotification } = this.state


    if (showReloadNotification) {
      return (
        <a id="new-version-notice" href="#reload" onClick={(e) => {e.preventDefault(); this.refreshCacheAndReload()}}>A new version of Carbly Desktop is available. Click to update</a>
      )
    }
    return ( null )
  }
}
