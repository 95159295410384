import React from 'react'
import { Input } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionsMap from './AuctionsMap'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import platformFunctions from '../../platformFunctions'
import pluralize from 'pluralize'
import XIcon from '../common/icons/XIcon'
import PlusIcon from '../common/icons/PlusIcon'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import CalculatorBug from '../common/CalculatorBug'
import CommonFunctions from '../../api/constants/CommonFunctions'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

export default class AuctionsSettings extends React.Component {

  state = {
    user: SessionStore.user,
    globalAuctionCodes: AuctionStore.savedSearches?.global?.physical_codes,
    isLoadingSavedSearches: false,
    isLoadingAuctionsByState: false,
    auctionsByState: null,
    userChanged: false,
    isUpdatingUser: false,
    auctionsSearchArray: null,
    auctionsSearchString: '',
    auctionsSearchResults: null,
    nearestAuctions: null
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.getUser)
    AuctionStore.on('load_auctions_by_state', this.auctionsByStateLoading)
    AuctionStore.on('auctions_by_state_change', this.auctionsByStateDidChange)
    AuctionStore.on('saved_searches_received', this.savedSearchesDidChange)
    AuctionsActionCreators.loadAuctionsByState()
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Settings")

    if (!this.state.globalAuctionCodes) {
      AuctionsActionCreators.loadSavedSearches()
      this.setState({ isLoadingSavedSearches: true })
    }
    
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.getUser)
    AuctionStore.removeListener('load_auctions_by_state', this.auctionsByStateLoading)
    AuctionStore.removeListener('auctions_by_state_change', this.auctionsByStateDidChange)
    AuctionStore.removeListener('saved_searches_received', this.savedSearchesDidChange)
  }

  savedSearchesDidChange = () => {
    this.setState({
      globalAuctionCodes: AuctionStore.savedSearches?.global?.physical_codes,
      isLoadingSavedSearches: false
    })
  }

  getUser = () => {
    this.setState({
      user: SessionStore.user,
      globalAuctionCodes: AuctionStore.savedSearches.global.physical_codes,
      userChanged: false
    })

    this.auctionsByStateDidChange()
  }

  auctionsByStateDidChange = () => {
    const auctions = AuctionStore.auctionsByState
    const auctionLocationCodes = this.state.globalAuctionCodes

    const formattedAuctionsByState = AuctionFunctions.formattedAuctionsByState(auctions)
    var results = formattedAuctionsByState.results
    var auctionsSearchArray = formattedAuctionsByState.auctionsSearchArray

    var nearestAuctions = this.nearestAuctions()
    
    results = results.sort((a,b) => (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0))

    this.setState({
      auctionsByState: results,
      auctionsSearchArray: auctionsSearchArray,
      isLoadingAuctionsByState: false,
      nearestAuctions: nearestAuctions
    })
  }

  auctionsByStateLoading = () => {
    this.setState({ isLoadingAuctionsByState: true })
  }

  removeAuction = (locationCode) => {
    let { globalAuctionCodes } = this.state
    globalAuctionCodes = globalAuctionCodes.filter(function(l) { return l !== locationCode })
    

    this.setState({ globalAuctionCodes: globalAuctionCodes })

    this.updateGlobalAuctionCodes(globalAuctionCodes)
    platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location")
    platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location - Auctions Settings")
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_removed_auction'], 'settings')

    setTimeout(() => {
      let nearestAuctions = this.nearestAuctions()
      this.setState({ nearestAuctions: nearestAuctions })
    }, 100);
  }

  addAuction = (locationCode) => {
    let { globalAuctionCodes } = this.state

    if (!globalAuctionCodes.includes(locationCode)) {
      globalAuctionCodes.push(locationCode)
    }

    this.setState({ globalAuctionCodes: globalAuctionCodes })

    this.updateGlobalAuctionCodes(globalAuctionCodes)
    platformFunctions.logAnalyticsEvent("Auctions", "Added Auction Location")
    platformFunctions.logAnalyticsEvent("Auctions", "Added Auction Location - Auctions Settings")
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_added_auction'], 'settings')

    setTimeout(() => {
      let nearestAuctions = this.nearestAuctions()
      this.setState({ nearestAuctions: nearestAuctions })
    }, 100);
  }

  updateGlobalAuctionCodes = (locationCodes) => {
    this.setState({ userChanged: true })
    AuctionsActionCreators.updateGlobalSavedSearch(AuctionStore.savedSearches.global.uuid, {physical_codes: locationCodes})
  }

  nearestAuctions = () => {
    return AuctionFunctions.nearestAuctions(AuctionStore.allAuctions, this.state.globalAuctionCodes, this.state.user?.rooftop?.longitude, this.state.user?.rooftop?.latitude)
  }

  searchChanged = (e) => {
    var text = e.target.value
    const auctionsSearchArray = this.state.auctionsSearchArray

    const searchText = text && text.length > 0 ? text.toLowerCase() : null

    var results = null
    if (searchText && searchText.length > 0) {
      results = auctionsSearchArray.filter((auc) => auc.name && auc.name.toLowerCase().indexOf(searchText) > -1)
    }

    if (results && results.length === 0) { results = null}

    this.setState({
      auctionsSearchString: text,
      auctionsSearchResults: results
    })
  }

  renderLocation = (loc, index, auctionLocationCodes) => {
    const isIncluded = auctionLocationCodes?.includes(loc.code)
    const userChanged = this.state.userChanged
    return (
      <div className="card-list-row auctions-settings-location-row" key={`auc-loc-${loc.code}-${index}`} style={{opacity: isIncluded ? 0.3 : 1}}>
        <a href="#add-auction" style={{cursor: isIncluded ? 'default' : 'pointer'}} onClick={(e) => {
          e.preventDefault()
          if (!isIncluded) {
            this.addAuction(loc.code)
          }
        }}><h2>{loc.name}</h2>
          <div style={{opacity: userChanged ? 0.1 : 1.0}}>{isIncluded ? 'Added' : 'Add auction'}</div>
        </a>
      </div>
    )
  }

  render() {
    const { globalAuctionCodes, auctionsByState, isLoadingAuctionsByState, auctionsSearchString, auctionsSearchResults, nearestAuctions, isLoadingSavedSearches } = this.state

    const hasAuctionLocations = globalAuctionCodes && globalAuctionCodes.length > 0
    const hasAuctionsByState = auctionsByState && auctionsByState.length > 0

    return (
      <div className="body-content">
        <div className="content-header">
          <h1>Manage Auctions</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        <div style={{marginBottom: '20px'}}>
          <AuctionsMap
            scopeType={'auctions'}
            maxInitialZoom={6}
            showAddAuction={false}
            onAddAuction={this.addAuction}
            onRemoveAuction={this.removeAuction} />
        </div>


          {
            hasAuctionLocations && !isLoadingAuctionsByState && hasAuctionsByState && !isLoadingSavedSearches &&
            <div>
              <div className='secondary-text' style={{marginBottom: '5px'}}>You are currently following {pluralize('auction', globalAuctionCodes.length, true)}</div>
              <div className='card' style={{padding: '5px 10px'}}>

                <div className="tokenized-list-c">
                {
                  globalAuctionCodes.map((locationCode, index) => {
                    const fullAuction = AuctionStore.auctionDetailsForLocationCode(locationCode)
                    return (
                      <div className="token" key={`ea-${locationCode}-${index}`}>
                        <div className='token-label'>
                          { fullAuction?.name ? fullAuction.name : locationCode }
                        </div>
                        <a href="#remove" className='token-action' onClick={(e) => {
                          e.preventDefault();
                          this.removeAuction(locationCode)
                        }}
                        style={{}}>
                          <XIcon color='#fff' />
                        </a>
                      </div>
                    )
                  })
                }
                </div>
              </div>
            </div>
          }

          {
            !hasAuctionLocations &&
            <div style={{border: '1px solid #ddd', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#fff', marginBottom: '20px'}}>
              You are not following any auctions.  Add some auctions below.
            </div>
          }

          {
           !isLoadingAuctionsByState && !isLoadingSavedSearches && hasAuctionsByState && nearestAuctions && nearestAuctions.length > 0 &&
            <div>
              <div className='secondary-text' style={{marginBottom: '5px'}}>Nearest auctions that you're <strong>not</strong> following</div>
              <div className='card' style={{padding: '5px 10px'}}>

                <div className="tokenized-list-c">
                {
                  nearestAuctions.map((loc, index) => {
                    return (
                      <div className="token" key={`close-${loc}-${index}`}>
                        <div className='token-label' style={{backgroundColor: '#aaa'}}>
                          { loc.name }
                        </div>
                        <a href="#add" className='token-action' style={{backgroundColor: '#888'}} onClick={(e) => {
                          e.preventDefault()
                          this.addAuction(loc.code)
                          setTimeout(() => {
                            UserActionCreators.createMetric(AppConstants.metricNames['auctions_added_nearby_auction'])
                          }, 1000)
                        }}>
                          <PlusIcon width={22} height={22} color='#fff' style={{margin: '0 4px'}} />
                        </a>
                      </div>
                    )
                  })
                }
                </div>
              </div>
            </div>
          }

          {
            !isLoadingAuctionsByState && !isLoadingSavedSearches && hasAuctionsByState &&
            <div style={{marginTop: '40px'}}>
              <div className='split-row' style={{marginTop: '40px', marginBottom: '20px', alignItems: 'center', borderBottom: '1px solid #aaa', paddingBottom: '5px'}}>
                <h2 style={{margin: 0}}>Auction Locations by State</h2>
                <div className='split-row' style={{width: '340px', height: '100%', alignItems: 'center'}}>
                  <MagnifyingGlassIcon width={28} height={28} color='#aaa' style={{marginRight: '10px'}} />
                  <Input type="text" name="text" id="search" placeholder="Search auctions" value={auctionsSearchString} onChange={this.searchChanged} ref='searchRef' style={{margin: 0}} />
                </div>

              </div>


              {
                auctionsSearchString.length === 0 &&
                auctionsByState.map((state) => {
                  return (
                    <div className="card" key={state.title}>
                      <h2 className="card-title">{state.title}</h2>
                      <div className="card-list">
                      {
                        state.data.map((loc, index) => {
                          return ( this.renderLocation(loc, index, globalAuctionCodes) )
                        })
                      }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }

          {
            !isLoadingAuctionsByState && !isLoadingSavedSearches && auctionsSearchString.length > 0 &&
            <div style={{marginTop: '40px'}}>
              {
                auctionsSearchResults === null &&
                <div style={{textAlign: 'center'}}>No results found</div>
              }
              {
                auctionsSearchResults !== null &&
                <div className="card">
                  <div className="card-list">
                    {
                      auctionsSearchResults.map((loc, index) => {
                        return ( this.renderLocation(loc, index, globalAuctionCodes) )
                      })
                    }
                  </div>
                </div>
              }
            </div>
          }

          {
            Boolean(isLoadingAuctionsByState || isLoadingSavedSearches) &&
            <Spinner />
          }
      </div>

    )
  }

}
