import React from 'react'
import AuctionsListingRow from './AuctionsListingRow'
import AuctionLocationIcon from '../common/icons/AuctionLocationIcon'
import Formatters from '../../api/constants/Formatters'
import AuctionOnlineIcon from '../common/icons/AuctionOnlineIcon'

export default class AuctionsWatchlistList extends React.Component {
  

  render() {
    const { listings } = this.props

    return (
      listings.map((sale, index) => {
        return (
          <div className="card" key={`aws-${index}`}>
            <div className="card-list">
              <div className="watchlist-sale-header" style={{...this.props.sectionHeaderStyle}}>
                {
                  sale.isOnline ?
                  <AuctionOnlineIcon color='#fff' style={{marginRight: '5px'}} /> :
                  <AuctionLocationIcon color='#fff' style={{marginRight: '5px'}} />
                }
                <h2 className="auction-lane-list-title" style={{paddingLeft: 5}}>{sale.title}</h2>
                {
                  !sale.isOnline && sale.saleDate &&
                  <div style={{fontSize: '1.1rem', marginLeft: '20px'}}>{Formatters.formatVerboseDateWithDay(sale.saleDate)}</div>
                }
              </div>
              {
                sale.data.map((item, index) => {
                  if (item['type'] === 'lane') {
                    return (
                      <div key={`wll-${index}`} style={{margin: '10px', marginLeft: '20px', fontSize: '1.4rem', fontWeight: 'bold'}}>Lane {item.number}</div>
                    )
                  }
                  return (
                    <AuctionsListingRow hasLinkedAuctionEdge={this.props.hasLinkedAuctionEdge} listing={item} showRunNumber={this.props.showRunNumber} showLaneNumber showChannel key={`auv-${item.id}`} onListingShow={this.props.onListingShow} showWatchlistToggle={this.props.showWatchlistToggle} onWatchlistToggle={this.props.onWatchlistToggle} showRelisting={this.props.showRelisting} />
                  )
                })
              }

            </div>
          </div>
        )
      })
    )
      
  }
}
