import React from 'react'

export default class LockedIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 15
    const height = this.props.height || 20
    const color = this.props.color || '#333'

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 394 522" style={{...this.props.style}}>
        <path fillRule="evenodd" d="M0.999,254.0 C0.999,228.848 15.578,216.999 32.0,216.999 C35.966,216.999 43.999,216.999 43.999,216.999 C43.999,216.999 43.999,270.677 43.999,179.999 C43.999,89.322 102.462,0.0 194.0,0.0 C285.537,0.0 353.0,75.333 353.0,179.999 C353.0,206.674 353.0,216.999 353.0,216.999 C353.0,216.999 364.499,216.999 366.999,216.999 C386.141,216.999 393.999,227.841 393.999,255.0 C393.999,271.902 393.999,454.898 393.999,487.0 C393.999,514.228 373.279,521.999 363.0,521.999 C352.720,521.999 43.531,521.999 30.0,521.999 C16.468,521.999 0.0,515.642 0.0,484.0 C0.0,452.357 0.999,287.414 0.999,254.0 ZM290.999,215.999 L105.0,215.999 C105.0,215.999 105.0,238.759 105.0,162.0 C105.0,98.304 147.341,57.999 195.999,57.999 C245.301,57.999 290.999,96.443 290.999,161.0 C290.999,211.892 290.999,215.999 290.999,215.999 Z"/>
      </svg>
    )
  }
}
