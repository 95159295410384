import { EventEmitter } from 'events'
import Dispatcher from '../actions/Dispatcher'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

class MakeModelSelectorStore extends EventEmitter {
  constructor() {
    super()
    this.years = {}
  }

  availableYears = () => {
    return Object.keys(this.years).sort((a, b) => b - a)
  }

  availableMakesModels = (year) => {
    return this.years[year]
  }

  handleActions(action) {
    switch(action.type) {
      case ActionTypes.RECEIVED_AVAILABLE_YEARS: {
        if (action.errors == null && action.availableYears.length > 0) {
          let years = {}
          for (let year of action.availableYears) {
            years[year] = []
          }
          this.years = years
        }

        this.emit("years_did_change")
        break
      }
      case ActionTypes.RECEIVED_AVAILABLE_MAKES_MODELS: {
        if (action.errors == null && action.availableMakesModels) {
          this.years[action.year] = action.availableMakesModels
        }

        this.emit("makes_models_did_change")
        break
      }
      default:
        break
    }
  }
}


const makeModelSelectorStore = new MakeModelSelectorStore()
Dispatcher.register(makeModelSelectorStore.handleActions.bind(makeModelSelectorStore))

export default makeModelSelectorStore
