import React from 'react'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import Formatters from '../../api/constants/Formatters'


export default class UniverseDistributionGraph extends React.Component {

  render() {
    const prices = this.props.data['all_prices']
    const priceBreakpoints = this.props.data['price_breakpoints'] && this.props.data.price_breakpoints.length > 2 ? this.props.data.price_breakpoints.slice(0, 2) : this.props.data['price_breakpoints']
    const priceMin = prices[0]
    const priceMax = prices[prices.length - 1]

    const formattedPrices = [ { "id": "sale_price", "data":
      prices.map((price) => {
        return {
          "x": price,
          "y": 1,
          "formattedPrice": Formatters.formatCurrency(price),
          "priceMin": priceMin,
          "priceMax": priceMax
        }
      })
    } ]

    var segments = []
    const priceDelta = priceMax - priceMin
    if (priceBreakpoints.length === 2) {
      const seg1WidthVal = Math.round(((priceBreakpoints[0] - priceMin) / priceDelta) * 100)
      const seg1Width = `${seg1WidthVal}%`
      segments.push(
        <div style={{position: 'absolute', width: seg1Width, height: '100%', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '12px'}} key='seg-0' />
      )

      const seg2WidthVal = Math.round(((priceBreakpoints[1] - priceBreakpoints[0]) / priceDelta) * 100)
      const seg2Width = `${seg2WidthVal}%`
      segments.push(
        <div style={{position: 'absolute', width: seg2Width, height: '100%', top: 0, left: seg1Width, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '12px'}} key='seg-1' />
      )

      segments.push(
        <div style={{position: 'absolute', width: `${100 - seg1WidthVal - seg2WidthVal}%`, height: '100%', top: 0, left: `${seg1WidthVal + seg2WidthVal}%`, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '12px'}} key='seg-2' />
      )
    }



    return (
      <div style={{flexGrow: 1, flexShrink: 1, height: '30px', position: 'relative'}}>
        { segments }
        
        <ResponsiveScatterPlot
          data={formattedPrices}
          margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
          xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          blendMode="multiply"
          colors={(node) => {
            return "#45BF55"
          }}
          nodeSize={(node) => {
            if (node.isIndicator === true) {
              return 26
            } else {
              return 16
            }
          }}
          enableGridX={false}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          tooltip={(el) => {
            return (
              <div className="graph-tooltip">
                <div>{el.node.data.formattedPrice}</div>
                <div>{el.node.data.formattedMiles}</div>
              </div>
            )
          }}
          legends={[]}
        />
        <div className="graph-x-axis">
          <div>{Formatters.formatCurrency(priceMin)}</div>
          <div>{Formatters.formatCurrency(Math.round(priceMin + (priceDelta / 2)))}</div>
          <div>{Formatters.formatCurrency(priceMax)}</div>
        </div>
      </div>
    )
  }
}
