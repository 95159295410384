import React from 'react'
import DistanceSlider from './DistanceSlider'
import ZipCodeEntryField from './ZipCodeEntryField'
import platformFunctions from '../../platformFunctions'
import Formatters from '../../api/constants/Formatters'
import SessionStore from '../../api/stores/SessionStore'
import AppConstants from '../../api/constants/AppConstants'


export default class DistanceFromZipcodeControl extends React.Component {

  constructor (props) {
    super(props)

    this.hasDoneInitialChange = false
  }

  onZipCodeChange = (zipCode, isValid) => {
    if (this.hasDoneInitialChange && this.props.onZipCodeChange) {
      this.props.onZipCodeChange(zipCode, isValid)
    }
    this.hasDoneInitialChange = true
  }

  render() {
    const { onDistanceChange, onBeforeChange, onAfterChange, defaultDistance, defaultZipCode, realtimeDistanceValue } = this.props

    return(
      <>
        <div style={{marginLeft: '10px', marginRight: '10px'}}>
          <DistanceSlider
            onDistanceChange={onDistanceChange}
            onBeforeChange={onBeforeChange}
            onAfterChange={onAfterChange}
            style={{height: '40px', width: '100%', marginTop: '10px'}}
            defaultDistance={defaultDistance}
            {...platformFunctions.sliderStyleProps()} />
        </div>
        <div className='split-row split-row-left'>{realtimeDistanceValue === AppConstants.auctionSearchMaxDistanceRadius ? 'All auctions' : `${Formatters.formatThousands(realtimeDistanceValue)} miles from ZIP code`}
        {
          Boolean(defaultZipCode) ?
          <ZipCodeEntryField
            defaultZipCode={defaultZipCode}
            onZipCodeChange={this.onZipCodeChange} style={{margin: 0, width: '80px', flexGrow: 0, flexShrink: 0, marginLeft: '5px', paddingTop: 3, paddingBottom: 3, paddingLeft: 5}} /> :
            ` ${defaultZipCode || SessionStore.user?.rooftop?.zip_code}`
        }
          
        </div>
      </>
    )

  }

}