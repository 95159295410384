import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import AnimateOnChange from 'react-animate-on-change'
import pluralize from 'pluralize'
import CheckCircleIcon from '../common/icons/CheckCircleIcon'
import ClockIcon from '../common/icons/ClockIcon'
import WarningCircleIcon from '../common/icons/WarningCircleIcon'
import usePrevious from '../../api/bidding/hooks/usePrevious'
import useBiddingComponentProps from '../../api/bidding/hooks/useBiddingComponentProps'
import useValueBecameTrueState from '../../api/bidding/hooks/useValueBecameTrueState'
import useDidMountEffect from '../../api/bidding/hooks/useDidMountEffect'
import CurrencyInputField from '../common/CurrencyInputField'
import platformFunctions from '../../platformFunctions'

const withEnsureBidders = (WrappedComponent) => {
  return ({ bidderSelectProps, ...rest}) => {
    const { bidders } = bidderSelectProps
    const { isVisible, target, toggle } = rest
    if (!bidders.length) {
      return (
        <Popover
          placement='left'
          trigger='legacy'
          isOpen={isVisible}
          target={target}
          toggle={toggle}
        >
          <PopoverHeader>Authorization Required</PopoverHeader>
          <PopoverBody className='popover-med'>
            <p>
              You must have an AuctionACCESS membership at edgepipeline.com to bid.
            </p>
            <p>
              Please add your <a href='https://www.edgepipeline.com/components/timed_bidding' rel="noopener noreferrer" target="_blank">AuctionACCESS account.</a>
            </p>
          </PopoverBody>
        </Popover>
      )
    }

    return (
      <WrappedComponent
        {...rest}
        biddersSelect={<BiddersSelect { ...bidderSelectProps }/>}
      />
    )
  }
}

const useAutofocus = (ref, shouldFocus) => {
  // Autofocus input field.
  // Prevent auto-scrolling to element position on focus.
  // Behavior differs between safari/chrome without this workaround
  useEffect(() => {
    if (shouldFocus) {
      setTimeout(() => {
        ref.current?.focus({ preventScroll: true })
      })
    }
  }, [shouldFocus])
}

const BiddersSelect = ({ biddingAsBidder, setShowBiddingAs, setBiddingAsBidder, showBiddingAs, bidders }) => {
  return (
    <div className='bidding-as' style={{ marginTop: 20, marginBottom: 5, paddingTop: 10, borderTop: '1px solid #ddd' }}>
      <div className='secondary-text'>Bidding as</div>
      {
        bidders.length < 2 ?
        <div className='bidding-as-name'>{biddingAsBidder.name}</div> :
        <Dropdown isOpen={showBiddingAs} toggle={() => {setShowBiddingAs(!showBiddingAs)}}>
          <DropdownToggle className='bidding-as-name dropdown-toggle'>
            {biddingAsBidder.name}
          </DropdownToggle>
          <DropdownMenu>
            {
              bidders.map(bidder => (
                <DropdownItem
                  key={bidder.id}
                  active={bidder === biddingAsBidder}
                  onClick={() => setBiddingAsBidder(bidder)}
                >
                  {bidder.name}
                </DropdownItem>
              ))
            }
          </DropdownMenu>
        </Dropdown>
      }
    </div>
  )
}

const BuyNowConfirmPopover = withEnsureBidders(({
  isSubmitDisabled,
  target,
  toggle,
  isVisible,
  onCancel,
  onSubmit,
  formatCurrency,
  isPlacedBidPending,
  isBuyNowAvailable,
  buyNowPriceAmount,
  bidError,
  biddersSelect,
}) => {
  const bidWasProcessed = useValueBecameTrueState(!isPlacedBidPending, !isVisible || isPlacedBidPending)

  return (
    <Popover
      placement='left'
      trigger='legacy'
      isOpen={isVisible}
      target={target}
      toggle={toggle}
    >
      <PopoverHeader>Confirm Buy Now</PopoverHeader>
      <PopoverBody className='popover-med'>
        {
          bidWasProcessed &&
          <div style={{margin: '10px 0'}}>
            {
              bidError ?
              <div className='bid-error'>{bidError}</div> :
              <div>Your Buy Now bid was accepted</div>
            }
          </div>
        }

        <div className='split-row bid-actions' style={{gap: '5px'}}>
          {
            isBuyNowAvailable &&
            <Button
              className='confirm'
              size='sm'
              onClick={onSubmit}
              disabled={isSubmitDisabled}
            >
              {`Buy now ${formatCurrency(buyNowPriceAmount)}`}
            </Button>
          }
          <Button className='cancel' size='sm' onClick={onCancel}>
            {!isBuyNowAvailable ? 'Close' : 'Cancel'}
          </Button>
        </div>
        {biddersSelect}
      </PopoverBody>
    </Popover>
  )
})

const HardBidPopover = withEnsureBidders(({
  isSubmitDisabled,
  hasBidIndivisibleMessage,
  target,
  toggle,
  isVisible,
  currentBidAmount,
  incrementalBidAmount,
  bidIncrementAmount,
  onValueChange,
  value,
  onResetBidClick,
  onCancel,
  onSubmit,
  formatCurrency,
  isPlacedBidPending,
  isHighBidder,
  isPendingIncrementalBidStale,
  bidError,
  biddersSelect,
}) => {
  const bidWasProcessed = useValueBecameTrueState(!isPlacedBidPending, !isVisible || isPlacedBidPending)
  const actionIsShown = !isHighBidder

  const inputRef = useRef()

  useAutofocus(inputRef, isVisible)

  return (
    <Popover
      placement='left'
      trigger='legacy'
      isOpen={isVisible}
      target={target}
      toggle={toggle}
    >
      <PopoverHeader>Make Bid</PopoverHeader>
      <PopoverBody className='popover-med'>
        <div><span className='secondary-text'>Current bid:</span> {formatCurrency(currentBidAmount)}</div>
        <div><span className='secondary-text'>Minimum bid:</span> {formatCurrency(incrementalBidAmount)}</div>
        <div><span className='secondary-text'>Bid increments:</span> {formatCurrency(bidIncrementAmount)}</div>

        {
          bidWasProcessed &&
          <div style={{margin: '10px 0'}}>
            {
              bidError ?
              <div className='bid-error'>{bidError}</div> :
              <div>{`Your bid was accepted, ${isHighBidder ? 'and you are now the high bidder' : 'but you have been outbid'}`}</div>
            }
          </div>
        }

        {
          isPendingIncrementalBidStale &&
          <div style={{margin: '10px 0'}}>
            <span>A new bid has come in. </span>
            <a
              href='#click'
              onClick={onResetBidClick}
            >
              {`Change your bid to ${formatCurrency(incrementalBidAmount)}`}
            </a>
          </div>
        }

        {
          actionIsShown &&
          <div>
            <CurrencyInputField
              placeholder='Enter bid'
              style={{marginTop: 20, marginBottom: 5 }}
              innerRef={inputRef}
              onChange={onValueChange}
              value={value}
            />
            <div className='bid-error' style={{ marginTop: 5, marginBottom: 10, minHeight: '1.5em' }}>
              {hasBidIndivisibleMessage && `Bid must be a multiple of ${formatCurrency(bidIncrementAmount)}`}
            </div>
          </div>
        }

        <div className='split-row bid-actions' style={{gap: '5px'}}>
          {
            actionIsShown &&
            <Button
              className='confirm'
              size='sm'
              onClick={onSubmit}
              disabled={isSubmitDisabled}
            >
              {`Bid ${formatCurrency(value)}`}
            </Button>
          }
          <Button
            className='cancel'
            size='sm'
            onClick={onCancel}
          >
            {isHighBidder ? 'Close' : 'Cancel'}
          </Button>
        </div>
        {biddersSelect}
      </PopoverBody>
    </Popover>
  )
})

const MaxBidPopover = withEnsureBidders(({
  isSubmitDisabled,
  hasBidIndivisibleMessage,
  target,
  toggle,
  isVisible,
  currentBidAmount,
  incrementalBidAmount,
  bidIncrementAmount,
  onValueChange,
  value,
  onCancel,
  onSubmit,
  formatCurrency,
  isPlacedBidPending,
  isHighBidder,
  cancelMaxBid,
  maxBidAmount,
  cancelMaxBidIsPending,
  bidActionIsPending,
  isMaxBidCancelable,
  bidError,
  biddersSelect,
}) => {
  const bidWasProcessed = useValueBecameTrueState(!isPlacedBidPending, !isVisible || bidActionIsPending)
  const bidWasCanceled = false // useValueBecameTrueState(!cancelMaxBidIsPending, !isVisible || bidActionIsPending)
  const inputRef = useRef()

  useAutofocus(inputRef, isVisible)

  return (
    <Popover
      placement='left'
      trigger='legacy'
      isOpen={isVisible}
      target={target}
      toggle={toggle}
    >
      <PopoverHeader>Proxy Bid</PopoverHeader>
      <PopoverBody className='popover-med'>
        <div><span className='secondary-text'>Current bid:</span> {formatCurrency(currentBidAmount)}</div>
        <div><span className='secondary-text'>Minimum bid:</span> {formatCurrency(incrementalBidAmount)}</div>
        <div><span className='secondary-text'>Bid increments:</span> {formatCurrency(bidIncrementAmount)}</div>

        {/* TODO: Different message for becoming high bidder vs already high bidder and changing the proxy bid? */}
        {
          bidWasProcessed &&
          <div style={{margin: '10px 0'}}>
            {
              bidError ?
              <div className='bid-error'>{bidError}</div> :
              <div>{`Your bid was accepted, ${isHighBidder ? 'and you are now the high bidder' : 'but you have been outbid'}`}</div>
            }
          </div>
        }

        {
          bidWasCanceled &&
          <div style={{margin: '10px 0'}}>
            {`Your bid was canceled and you ${isHighBidder ? 'are the high bidder' : 'have been outbid'}`}
          </div>
        }

        <div>
          <CurrencyInputField
            placeholder='Set proxy bid'
            style={{marginTop: 20, marginBottom: 5 }}
            innerRef={inputRef}
            onChange={onValueChange}
            value={value}
          />
          <div className='bid-error' style={{ marginTop: 5, marginBottom: 10, minHeight: '1.5em' }}>
            {hasBidIndivisibleMessage && `Bid must be a multiple of ${formatCurrency(bidIncrementAmount)}`}
          </div>
        </div>

        {
          isMaxBidCancelable &&
          <div style={{ marginBottom: '10px'}}>
            <a href='#click' className={bidActionIsPending ? 'disabled-anchor' : null} onClick={cancelMaxBid}>
              {`Cancel current proxy bid of ${formatCurrency(maxBidAmount)}`}
            </a>
          </div>
        }

        <div className='split-row bid-actions' style={{gap: '5px'}}>
          <Button
            className='confirm'
            size='sm'
            onClick={onSubmit}
            disabled={isSubmitDisabled}
          >
            Submit Bid
          </Button>
          <Button
            className='cancel'
            size='sm'
            onClick={onCancel}
          >
            {isHighBidder ? 'Close' : 'Cancel'}
          </Button>
        </div>
        {biddersSelect}
      </PopoverBody>
    </Popover>
  )
})

const AuctionEdgeBiddingInner = ({
  id,
  numberOfBids,
  floorPriceMet,
  bidders,
  isHighBidder,
  biddingAsBidder,
  isRunning,
  currentBidAmount,
  incrementalBidAmount,
  maxBidAmount,
  buyNowPriceAmount,
  bidIncrementAmount,
  pendingHardBid,
  pendingMaxBid,
  hardBidIsShown,
  maxBidIsShown,
  buyNowConfirmIsShown,
  bidderStatusMessage,
  timeStatus,
  isPlacedBidPending,
  isPendingIncrementalBidStale,
  isBuyNowAvailable,
  cancelMaxBidIsPending,
  bidActionIsPending,
  isMaxBidCancelable,
  bidError,

  isBidValid,
  isBidDivisibleByIncrement,
  isBidOverMinimum,
  setBiddingAsBidder,
  resetHardBid,
  toggleHardBid,
  toggleMaxBid,
  toggleBuyNowConfirm,
  placeHardBid,
  placeMaxBid,
  placeBuyNowBid,
  onHardBidInputValueChange,
  onHardBidCancel,
  onMaxBidCancel,
  onMaxBidInputValueChange,
  formatCurrency,
  cancelMaxBid,
}) => {
  const [showBiddingAs, setShowBiddingAs] = useState(false)
  const prevNumberOfBids = usePrevious(numberOfBids)

  const hardBidTargetId = `hard-bid-${id}`
  const maxBidTargetId = `max-bid-${id}`
  const buyNowTargetId = `buy-now-${id}`

  const playSound = useCallback(soundFile => {
    const audio = new Audio(soundFile)
    audio.volume = 0.5;
    audio.play()
  }, [])

  const onBidAccepted = useCallback(() => {
    playSound('/sounds/bidsuccess.mp3')
  }, [playSound])

  const onOutBid = useCallback(() => {
    playSound('/sounds/bidoutbid.mp3')
  }, [playSound])

  const prevIsHighBidder = usePrevious(isHighBidder)

  useDidMountEffect(() => {
    if (isHighBidder !== prevIsHighBidder) {
      if (isHighBidder) {
        onBidAccepted()
      } else {
        onOutBid()
      }
    }
  }, [isHighBidder, onOutBid, onBidAccepted])

  const bidderSelectProps = useMemo(() => ({
    biddingAsBidder,
    setShowBiddingAs,
    setBiddingAsBidder,
    showBiddingAs,
    bidders,
  }), [
    biddingAsBidder,
    setShowBiddingAs,
    setBiddingAsBidder,
    showBiddingAs,
    bidders,
  ])

  return (
    <div className='bidding'>

      <div className='bid-body'>
        <div className='split-row bid-meta' style={{borderBottom: !isRunning ? 'none' : 'inherit'}}>
          <div>
            <ClockIcon style={{marginBottom: '2px', marginRight: '1ch'}} />
            {timeStatus}
          </div>
          <AnimateOnChange
            animationClassName='bidding-change-bounce'
            animate={numberOfBids !== prevNumberOfBids}
          >
            {pluralize('bid', numberOfBids, true)}
          </AnimateOnChange>
          {
            bidderStatusMessage &&
            <div className={`bid-status bid-status-${isHighBidder ? 'high-bidder' : 'outbid'}`}>
              {bidderStatusMessage}
              {
                isHighBidder ?
                  <CheckCircleIcon color='#fff' size='18px' checked /> :
                  <WarningCircleIcon color='#fff' size='18px' />
              }
            </div>
          }
        </div>


        {
          isRunning &&
            <>
              <div className='split-row bid-actions' style={{gap: '5px'}}>
                <div className='split-row split-row-left' style={{alignItems: 'flex-end', marginTop: '5px'}}>
                  <div className='current-bid'>{formatCurrency(currentBidAmount)}</div>
                  <div className='current-bid-label'>current bid</div>
                </div>
                <Button
                  color='primary'
                  className='hard-bid'
                  size='sm'
                  id={hardBidTargetId}
                  disabled={isHighBidder || bidActionIsPending}
                >
                  Make Bid
                </Button>
              </div>

              <div className='split-row'>
                {
                  floorPriceMet &&
                  <div className='floor-met'>
                    <CheckCircleIcon size='13px' color='currentColor' checked /> Floor met
                  </div>
                }
                {
                  !isBuyNowAvailable &&
                  <div/>
                }

                <div style={{ display: isBuyNowAvailable ? 'block' : 'none' }}>
                  <span>Buy now: </span>
                  <a
                    href='#click'
                    className={bidActionIsPending ? 'disabled-anchor' : null}
                    id={buyNowTargetId}
                  >
                    {formatCurrency(buyNowPriceAmount)}
                  </a>
                </div>

                <div>
                  {maxBidAmount && <span>Proxy bid set: </span>}
                  <a href='#click' className={bidActionIsPending ? 'disabled-anchor' : null} id={maxBidTargetId}>
                    {maxBidAmount ? formatCurrency(maxBidAmount) : 'Set a proxy bid'}
                  </a>
                </div>
              </div>



              <div style={{ marginTop: floorPriceMet || isBuyNowAvailable ? 5 : 0 }}>

              </div>

              <HardBidPopover
                isSubmitDisabled={!isBidValid(pendingHardBid) || bidActionIsPending}
                hasBidIndivisibleMessage={!isBidDivisibleByIncrement(pendingHardBid) && isBidOverMinimum(pendingHardBid)}
                target={hardBidTargetId}
                toggle={toggleHardBid}
                isVisible={hardBidIsShown}
                currentBidAmount={currentBidAmount}
                incrementalBidAmount={incrementalBidAmount}
                bidIncrementAmount={bidIncrementAmount}
                onValueChange={onHardBidInputValueChange}
                value={pendingHardBid}
                onResetBidClick={resetHardBid}
                onCancel={onHardBidCancel}
                onSubmit={placeHardBid}
                formatCurrency={formatCurrency}
                isPlacedBidPending={isPlacedBidPending}
                isHighBidder={isHighBidder}
                isPendingIncrementalBidStale={isPendingIncrementalBidStale}
                bidError={bidError}
                bidderSelectProps={bidderSelectProps}
              />

              <MaxBidPopover
                isSubmitDisabled={!isBidValid(pendingMaxBid) || bidActionIsPending || (maxBidAmount === parseInt(pendingMaxBid))}
                hasBidIndivisibleMessage={!isBidDivisibleByIncrement(pendingMaxBid) && isBidOverMinimum(pendingMaxBid)}
                target={maxBidTargetId}
                toggle={toggleMaxBid}
                isVisible={maxBidIsShown}
                currentBidAmount={currentBidAmount}
                incrementalBidAmount={incrementalBidAmount}
                bidIncrementAmount={bidIncrementAmount}
                onValueChange={onMaxBidInputValueChange}
                value={pendingMaxBid}
                onCancel={onMaxBidCancel}
                onSubmit={placeMaxBid}
                formatCurrency={formatCurrency}
                isPlacedBidPending={isPlacedBidPending}
                isHighBidder={isHighBidder}
                cancelMaxBid={cancelMaxBid}
                maxBidAmount={maxBidAmount}
                cancelMaxBidIsPending={cancelMaxBidIsPending}
                bidActionIsPending={bidActionIsPending}
                isMaxBidCancelable={isMaxBidCancelable}
                bidError={bidError}
                bidderSelectProps={bidderSelectProps}
              />

              <BuyNowConfirmPopover
                isBuyNowAvailable={isBuyNowAvailable}
                isSubmitDisabled={bidActionIsPending}
                target={buyNowTargetId}
                toggle={toggleBuyNowConfirm}
                isVisible={buyNowConfirmIsShown}
                onCancel={toggleBuyNowConfirm}
                onSubmit={placeBuyNowBid}
                formatCurrency={formatCurrency}
                isPlacedBidPending={isPlacedBidPending}
                buyNowPriceAmount={buyNowPriceAmount}
                bidError={bidError}
                bidderSelectProps={bidderSelectProps}
              />
            </>
        }
      </div>
    </div>
  )
}

const AuctionEdgeBidding = ({ listingId, vehicle }) => {
  const {
    isLoading,
    id,
    ...rest
  } = useBiddingComponentProps(listingId, platformFunctions.platform)

  // FIXME: loading state
  if (isLoading) {
    return 'loading'
  }

  if (!id) {
    return null
  }

  return <AuctionEdgeBiddingInner {...rest} id={id} />
}

export default AuctionEdgeBidding
