import React from 'react'
import { Button } from 'reactstrap'
import { MapContainer, TileLayer, Marker} from 'react-leaflet'
import { ResponsivePie } from '@nivo/pie'
import AccountBug from '../user/AccountBug'
import PlusIcon from '../common/icons/PlusIcon'
import CalculatorBug from '../common/CalculatorBug'


export default class AuctionLocationShow extends React.Component {

  render() {

    const brandsData = [
      {
        "id": "Ford",
        "label": "Ford",
        "value": 129
      },
      {
        "id": "Chevrolet",
        "label": "Chevrolet",
        "value": 238
      },
      {
        "id": "Kia",
        "label": "Kia",
        "value": 146,
      },
      {
        "id": "Subaru",
        "label": "Subaru",
        "value": 386
      },
      {
        "id": "Toyota",
        "label": "Toyota",
        "value": 180
      },
      {
        "id": "Others",
        "label": "Others",
        "value": 464
      }
    ]

    const yearsData = [
      {
        "id": "< 1990",
        "label": "Ford",
        "value": 5
      },
      {
        "id": "1990 - 2000",
        "label": "Chevrolet",
        "value": 34
      },
      {
        "id": "2000 - 2010",
        "label": "Kia",
        "value": 101,
      },
      {
        "id": "2010 - 2020",
        "label": "Subaru",
        "value": 155
      },
      {
        "id": "> 2020",
        "label": "Toyota",
        "value": 12
      }
    ]

    
    return (
      <div className="body-content">
        <div className="content-header">
          <div>
            {/* <img src="/images/placeholder-auction-logo.png" alt="Logo" style={{width: '250px', height: '21px'}} /> */}
            <h1>AutoNation Auto Auction Orlando</h1>
          </div>
          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        <div className="split-row" style={{alignItems: 'flex-start', backgroundColor: '#eee', borderRadius: '10px', marginTop: '10px'}}>
          <MapContainer zoom={10} center={[28.7086926, -81.3296029,17]} scrollWheelZoom={true} style={{width: '100%', height: '320px', borderRadius: '10px', flexGrow: 1, flexShrink: 1, zIndex: 100}}>
            <TileLayer attribution='' url="https://tileserver.getcarbly.com/osm/{z}/{x}/{y}.png" />
            <Marker position={[28.7086926, -81.3296029,17]} />
          </MapContainer>
          <div style={{padding: '20px', flexShrink: 0, flexGrow: 0, width: '300px'}}>
            <div>650 N US Hwy 17 92</div>
            <div>Longwood, FL 32750</div>
            <div>(855) 906-2622</div>
            <Button style={{marginTop: '40px', display: 'flex', alignItems: 'center'}}><PlusIcon style={{marginRight: '5px'}} /> Add to Your Auctions</Button>
          </div>
        </div>

        <section style={{marginTop: '20px'}}>
          <div className="split-row">
            <div>197 vehicles currently for sale</div>
            <div>299 vehicles for sale on average</div>
            <div>48% average sell through</div>
          </div>
        </section>
        
        <div className="split-row" style={{marginTop: '40px'}}>
          <div style={{width: '32%', height: '300px'}}>
            <ResponsivePie
              data={brandsData}
              margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
              innerRadius={0.5}
              padAngle={2}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}/>
            <div style={{textAlign: 'center'}}>Current Sales by Brand</div>
          </div>

          <div style={{width: '32%', height: '300px'}}>
            <ResponsivePie
              data={brandsData}
              margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
              innerRadius={0.5}
              padAngle={2}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}/>
            <div style={{textAlign: 'center'}}>Historic Sales by Brand</div>
          </div>

          <div style={{width: '32%', height: '300px'}}>
            <ResponsivePie
              data={yearsData}
              margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
              innerRadius={0.5}
              padAngle={2}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}/>
            <div style={{textAlign: 'center'}}>Sales by Year</div>
          </div>
        </div>

      </div>
    )
  }

}