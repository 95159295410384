import React, { useCallback, useEffect, useState } from 'react'
import Slider from 'rc-slider'
import platformFunctions from '../../platformFunctions'


const DistanceSlider = ({ onDistanceChange, defaultDistance, ...rest }) => {
  const [distance, setDistance] = useState(defaultDistance)

  const onChange = useCallback((value) => {
    setDistance(value)
    onDistanceChange(value)
  }, [onDistanceChange])

  return (
    <Slider
      value={distance}
      min={5}
      max={1500}
      onChange={onChange}
      step={5}
      marks = {{
        5: '5 mi',
        250: '250 mi',
        500: '500 mi',
        750: '750 mi',
        1000: '1000 mi',
        1500: 'All'
      }}
      {...platformFunctions.sliderStyleProps()}
      {...rest}
      
    />
  )
}

export default DistanceSlider
