import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationProviderCard from './ValuationProviderCard'
import UniverseDistributionGraph from './UniverseDistributionGraph'
import DataTable from './DataTable'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'

const title = AppConstants.providerNames.universe

const sampleColumns = [
          { title: 'Price', accessor: 'price'},
          { title: 'Days on Lot', accessor: 'days', width: '180px'},
          { title: 'Mileage', accessor: 'mileage', width: '144px'},
          { title: 'Vehicle History', accessor: 'hist', width: '180px'},
          { title: 'Distance Away', accessor: 'dist', width: '144px'}
        ]

export default class ValuationUniverse extends React.Component {
  constructor(props) {
    super(props)

    const universeData = this.props.providerData
    const error = this.props.providerData && this.props.providerData['error']
    const showCPO = false
    var stateParams = {
      showCPO: showCPO
    }

    if (universeData && universeData.description && !error) {
      stateParams.selectedTrimsIndex = this.defaultSelectedTrimsIndex()
    }
    this.state = stateParams
    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidUpdate(prevProps, prevState) {
    // We didn't have a description before, but do now because it's a limited plan user, poplulate default trims
    if (prevProps.providerData.system && !prevProps.providerData.description && this.props.providerData.description) {
      this.setState({
        selectedTrimsIndex: this.defaultSelectedTrimsIndex()
      })
    }
  }

  formattedSample(sample) {
    return (
      {
        price: {
          display_value: sample.price ? Formatters.formatCurrency(sample.price) : '',
          raw_value: sample.price
        },
        days: {
          display_value: sample.days ? sample.days : '',
          raw_value: sample.days
        },
        mileage: {
          display_value: sample.miles ? Formatters.formatThousands(sample.miles) : '',
          raw_value: sample.miles
        },
        hist: {
          display_value: sample.hist ? 'Clean' : 'Not clean',
          raw_value: sample.hist
        },
        dist: {
          display_value: sample.dist ? `${sample.dist} miles` : '',
          raw_value: sample.dist
        }
      }
    )
  }

  defaultSelectedTrimsIndex = () => {
    const universeData = this.props.providerData
    const currentYear = universeData['description']['year']
    const allTrims = universeData['all_trims_table']
    for (let i = 0; i < allTrims.length; i++) {
      if (currentYear === allTrims[i]['year']) {
        return i
      }
    }
    return 0
  }

  dataForCPOStatus = (allData) => {
    const { showCPO } = this.state
    return showCPO === true ? allData['cpo'] : allData['non_cpo']
  }

  allTrimsForSelectedYear = () => {
    const { showCPO, selectedTrimsIndex } = this.state
    const universeData = this.props.providerData
    const allTrims = universeData['all_trims_table']


    if (allTrims && selectedTrimsIndex !== -1 && allTrims[selectedTrimsIndex]) {
      var parentTrims = allTrims[selectedTrimsIndex]['results']
      if (showCPO === true) {
        return parentTrims['cpo']
      } else {
        return parentTrims['non_cpo']
      }
    }
  }


  renderTrimsItem = (item) => {
    const { showCPO } = this.state

    const allTrims = showCPO === true ? item['results']['cpo'] : item['results']['non_cpo']

    return (
      <div>
        <div className="universe-trim-header">
        {item['year']} Model Year
          <div className="universe-trim-titles">
            <div className="trim">&nbsp;</div>
            <div className="transactions">#</div>
            <div className="price">Avg. Price</div>
          </div>
        </div>
        {
          allTrims && allTrims.length === 0 &&
          <div style={{borderColor: '#ccc', borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1, borderRadius: 5}}>
            No data for {showCPO === true ? 'CPO' : 'non-CPO'} vehicles for the selected year
          </div>
        }

        {
          allTrims && allTrims.length > 0 &&
          <div className="universe-trim-body">

            {
              allTrims.map((trim, i) => {
                return (
                  <div className={`universe-trim-cols${trim['selected'] ? ' highlighted' : ''}`} key={`alltrims-${i}`}>
                    <div className="trim">{trim['trim']}</div>
                    <div className="transactions">{trim['transaction_count']}</div>
                    <div className="price">{Formatters.formatCurrency(trim['avg_price'])}</div>
                  </div>
                )
              })
            }
          </div>
        }


      </div>
    )
  }



  render() {
    const universeData = this.props.providerData
    if (!universeData) { return null }
    const error = universeData.error
    const description = universeData.description
    const systemData = this.props.systemData

    const { showCPO } = this.state
    const selectedData = this.dataForCPOStatus(universeData)

    // FIXME: performance.  If we subscribe to notifications on trim change, we can update this dynamically instead of each render
    var samplesData = null
    if (selectedData && selectedData['samples']) {
      samplesData = selectedData['samples'].map((s) => this.formattedSample(s))
    }

    var noDataMessage = null
    var selectedDescription = null
    if (!selectedData) {
      noDataMessage = (
        <div>No data is available for {showCPO === true ? 'CPO only vehicles' : 'Non-CPO only vehicles'}</div>
      )
    } else {
      selectedDescription = selectedData['description']
    }

    var allTrimsForSelectedYear = null
    var subtitle = null
    var pricesLength = null

    if (!error) {
      allTrimsForSelectedYear = this.allTrimsForSelectedYear()

      pricesLength = selectedData && selectedData['all_prices'] ? selectedData['all_prices'].length : 0
      if (!error && selectedDescription && selectedDescription['trim']) {
        subtitle = !description ? null : description.display_choice

        if (universeData['description'] && universeData['description']['distance']) {
          subtitle = `${subtitle} - Within ${universeData['description']['distance']} miles`
        }
      }
    }


    return (
      <ValuationProviderCard
        providerData={universeData}
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey={this.props.providerKey}
        systemData={systemData}
        fullChoices={this.props.fullChoices}
        currentChoiceUID={universeData.description && universeData.description.uid ? universeData.description.uid : null}
        title={title}
        subtitle={subtitle}
        hasError={error ? true : false}
        valuationMileage={this.props.valuationMileage}
        providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
        showAdjustmentMessage={false}
        messages={universeData.messages}
        >

        {
        !error &&
        <div>
          <div key='universe-data'>
              <div className="universe-cpo-toggle">
                Showing: <a href="#cpo-toggle" onClick={(e) => {
                  e.preventDefault();
                  this.defaultSelectedTrimsIndex()
                  this.setState({ showCPO: !showCPO })
                }}>{showCPO === true ? 'CPO Only' : 'Non-CPO'}</a>
              </div>

            {
              noDataMessage
            }

            {
              noDataMessage == null &&

              <div className="valuation-subsection universe-data-table">
                <div className="overview">
                  {
                    selectedDescription.mileage_adjusted_price &&
                    <div className="universe-row">
                      <div>Mileage adjusted suggested price:</div>
                      <div>{Formatters.formatCurrency(selectedDescription.mileage_adjusted_price)}</div>
                    </div>
                  }
                  {
                    selectedDescription.avg_price &&
                    <div className="universe-row">
                      <div>Average asking price:</div>
                      <div>{Formatters.formatCurrency(selectedDescription.avg_price)}</div>
                    </div>
                  }
                  {
                    selectedDescription.avg_mileage &&
                    <div className="universe-row">
                      <div>Avg mileage:</div>
                      <div>{Formatters.formatThousands(selectedDescription.avg_mileage)}</div>
                    </div>
                  }
                  {
                    selectedDescription.mdn_market_day_supply &&
                    <div className="universe-row">
                      <div>Median days on lot:</div>
                      <div>{selectedDescription.mdn_market_day_supply}</div>
                    </div>
                  }
                </div>
                <div className="history-adjusted">
                  {
                    selectedDescription.non_clean_history_adjustment &&
                    selectedDescription.non_clean_history_adjustment < 0 &&
                    <div>
                      <div className="universe-row">
                        <div>Clean history average:</div>
                        <div>{Formatters.formatCurrency(selectedDescription.avg_clean_history_price)}</div>
                      </div>
                      <div className="universe-row">
                        <div>Not clean history average:</div>
                        <div>{Formatters.formatCurrency(selectedDescription.avg_non_clean_history_price)}</div>
                      </div>
                      <div className="universe-row">
                        <div>Not clean history adjustment:</div>
                        <div>{Formatters.formatCurrency(selectedDescription.non_clean_history_adjustment)}</div>
                      </div>
                    </div>
                  }
                  {
                    selectedDescription.non_clean_history_adjustment &&
                    selectedDescription.non_clean_history_adjustment >= 0 &&
                      <div>
                        Bad history report adjustment: None
                      </div>
                  }

                </div>
              </div>



            }

          </div>

          {
            noDataMessage == null && pricesLength > 8 &&
            <div className="valuation-subsection">
              <h3 className="valuation-subsection-title">Distribution of local asking prices</h3>

              <UniverseDistributionGraph
                data={selectedData}
                dataKey='price' />
            </div>
          }


          {
            noDataMessage == null && allTrimsForSelectedYear &&
            <div className="valuation-subsection">
              {
                universeData['all_trims_table'].length > 0 &&
                <div className="universe-trims-c">
                  {
                    universeData['all_trims_table'].map((trim, i) => {
                      return (
                        <div className="universe-trim-table" style={{marginRight: i < universeData['all_trims_table'].length - 1 ? '5px' : 0, marginLeft: i > 0 ? '5px' : 0, width: `${(1 / universeData['all_trims_table'].length) * 100}%`}} key={`trims-year-tab-${trim['year']}`}>
                          {this.renderTrimsItem(trim)}
                        </div>
                      )

                    })
                  }
                </div>
              }
            </div>

          }

          {
            selectedData && samplesData === null &&
            <div className="valuation-subsection">No Local Comps</div>
          }

          {
            selectedData && samplesData !== null &&
            <div className="valuation-subsection">
              <h3 className="valuation-subsection-title">Local vehicles with similar trim</h3>
              <DataTable columns={sampleColumns} data={samplesData} providerKey='universe' defaultSort='price' valuationMileage={this.props.valuationMileage} />
            </div>
          }
        </div>
      }

        </ValuationProviderCard>
    )
  }

}
