import React from 'react'

export default class ProviderMarketTrackerLogo extends React.PureComponent {
  render() {
    const width = this.props.width || '200px'
    const height = this.props.height || '37px'

    return (
      <img src="/images/regional-reports-plus-logo.png" style={{width: width, height: height, ...this.props.style}} />
    )
  }
}
