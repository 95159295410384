import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, CustomInput } from 'reactstrap'

const MAKE_MODEL_VALUE_SEPARATOR = '___'

export default class ModelsAndTrimsModal extends React.Component {

  trimToggled = (event) => {
    const { id, checked } = event.target
    const [make, model, trim] = id.split(MAKE_MODEL_VALUE_SEPARATOR)
    if (checked) {
      this.props.addTrim(make, model, trim)
    } else {
      this.props.removeTrim(make, model, trim)
    }
  }

  renderOptions = (makesCorpus, classification) => {
    if (!makesCorpus) {
      return null
    }
    const selectedMakeModels = classification.filter((c) => { return c.model?.length > 0 })

    // Collapse duplicate make/model combinations in order to iterate
    let uniqueSelectedMakeModels = selectedMakeModels.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj['make'] + mapObj['model']).indexOf(obj['make'] + obj['model']) === pos
    })

    // Sort by make, then model
    uniqueSelectedMakeModels.sort((a, b) => {
      if (a.make < b.make) {
        return -1
      } else if (a.make > b.make) {
        return 1
      } else {
        if (a.model < b.model) {
          return -1
        } else if (a.model > b.model) {
          return 1
        } else {
          return 0
        }
      }
    })
    
    let results = []
    for (let selectedMakeModel of uniqueSelectedMakeModels) {
      if (makesCorpus[selectedMakeModel.make]) {
        const makeName = selectedMakeModel.make
        const corpusMake = makesCorpus[makeName]
        if (corpusMake[selectedMakeModel.model]) {
          const modelName = selectedMakeModel.model
          const corpusModel = corpusMake[selectedMakeModel.model]
          results.push(
            <div style={{marginBottom: '30px'}} key={`mm-${makeName}-${modelName}`}>
              <h2 style={{borderBottom: '1px solid #ddd', marginBottom: '5px'}}>{makeName} {modelName}</h2>
              <div style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center'}}>
                {
                  corpusModel.map((trim, index) => {
                    const isSelected = Boolean(selectedMakeModels.find((c) => { return c.make === makeName && c.model === modelName && c.trim === trim }))
                    return (
                      <div className='inline-checkbox' style={{marginRight: '20px'}} key={`trim-${makeName}-${modelName}-${index}`}>
                        <CustomInput
                          type="checkbox"
                          id={`${makeName}${MAKE_MODEL_VALUE_SEPARATOR}${modelName}${MAKE_MODEL_VALUE_SEPARATOR}${trim}`}
                          key={`select-${makeName}-${modelName}-${index}`}
                          label={trim} 
                          checked={isSelected}
                          onChange={this.trimToggled}
                          />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        }
      }
    }

    return results
  }

  render() {
    const { classification, makesCorpus } = this.props

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '1000px'}}>
        <ModalHeader className='modal-header'>
            Models and Trims
        </ModalHeader>
        <ModalBody>
          <div style={{maxHeight: '65vh', overflowY: 'scroll'}}>
            { this.renderOptions(makesCorpus, classification) }
          </div>
          
          <div className="modal-controls" style={{marginTop: '20px'}}>
            <Button color="primary" disabled={false} onClick={this.props.onCancel}>Done</Button>
          </div>
        
        </ModalBody>
      </Modal>
    )
  }
}
