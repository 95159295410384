import React from 'react'
import { CustomInput } from 'reactstrap'
import moment from 'moment'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import DataFreshnessMonitor from '../common/DataFreshnessMonitor'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import platformFunctions from '../../platformFunctions'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import AuctionsMap from './AuctionsMap'
import AuctionVehiclesDisplayBar from './AuctionVehiclesDisplayBar'
import { Link } from 'react-router-dom'
import LiveAuctionAnimation from '../common/animations/LIveAuctionAnimation'
import FunnelIcon from '../common/icons/FunnelIcon'
import GearIcon from '../common/icons/GearIcon'
import AuctionsGlobalFiltersControl from './AuctionsGlobalFiltersControl'
import AppConstants from '../../api/constants/AppConstants'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import pluralize from 'pluralize'
import useHasLinkedAuctionEdge from '../../api/bidding/hooks/useHasLinkedAuctionEdge'
import GlobalCostsEntry from './GlobalCostsEntry'
import CalculatorBug from '../common/CalculatorBug'
import AuctionsListingsList from './AuctionsListingsList'
import AuctionsEditOnlineChannelsModal from './AuctionsEditOnlineChannelsModal'
import AuctionsListingShowModal from './AuctionsListingShowModal'
import UserActionCreators from '../../api/actions/UserActionCreators'

class AuctionsHomeInner extends React.Component {
  state = {
    user: SessionStore.user,
    globalAuctionCodes: [],
    updatedHomeSavedSearch: null,
    upcomingVehicles: null,
    isLoadingMoreUpcomingVehicles: false,
    isLoadingHomeVehicles: false,
    auctionListingsDisplayType: SessionStore.auctionListingsDisplayType,
    clickedListingID: null,
    isShowingListing: false,
    isSavingHomeSavedSearch: false,
    auctionLiveSalesStatus: AuctionStore.auctionLiveSalesStatus,
    isShowingEditChannelsModal: false
  }

  componentDidMount() {
    this.clientID = 'auc-home'
    SessionStore.on("user_data_changed", this.getUser)
    AuctionStore.on(`auction_vehicles_search_change-${this.clientID}`, this.upcomingVehiclesDidChange)
    AuctionStore.on(`auction_vehicles_search_change-${this.clientID}`, this.upcomingVehiclesWatchlistDidToggle)
    AuctionStore.on('global_auction_listing_update', this.updateUpcomingVehicles)
    AuctionStore.on('fresh_user_auction_upcoming_vehicles_load', this.loadingFreshUpcomingVehicles)
    AuctionStore.on('saved_search_change', this.saveSearchUpdated)
    AuctionStore.on('user_auction_upcoming_vehicles_watchlist_toggle', this.upcomingVehiclesWatchlistDidToggle)
    AuctionStore.on('auction_live_sales_status_change', this.auctionLiveSalesStatusDidChange)

    // If we already have saved searches, fetch the home, otherwise we'll get updated props later
    const { savedSearches } = this.props
    const homeSavedSearch = savedSearches?.home
    const globalSavedSearch = savedSearches?.global
    this.page = 1


    if (homeSavedSearch) {
      this.runHomeSavedSearchQuery(homeSavedSearch, 1)
    }
    if (globalSavedSearch) {
      this.setState({globalAuctionCodes: globalSavedSearch.physical_codes})
    }


    // FIXME: Try modal again to see if this should all go away
    // Used to determine if we should refetch data (don't refetch if user drills down and comes back)

    // if (!AuctionStore.auctionListingsSearchResults.dataWithID(this.clientID)) {
    //   AuctionsActionCreators.searchAuctionListings(1)
    //   this.setState({
    //     isLoadingHomeVehicles: true
    //   })
    // } else {
    //   this.updateUpcomingVehicles()
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.savedSearches?.home && this.props.savedSearches?.home) {
      this.runHomeSavedSearchQuery(this.props.savedSearches.home, 1)
    }
    if (!prevProps.savedSearches?.global && this.props.savedSearches?.global) {
      this.setState({
        globalAuctionCodes: this.props.savedSearches.global.physical_codes
      })
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.getUser)
    AuctionStore.removeListener(`auction_vehicles_search_change-${this.clientID}`, this.upcomingVehiclesDidChange)
    AuctionStore.removeListener(`auction_vehicles_search_change-${this.clientID}`, this.upcomingVehiclesWatchlistDidToggle)
    AuctionStore.removeListener('global_auction_listing_update', this.updateUpcomingVehicles)
    AuctionStore.removeListener('fresh_user_auction_upcoming_vehicles_load', this.loadingFreshUpcomingVehicles)
    AuctionStore.removeListener('saved_search_change', this.saveSearchUpdated)
    AuctionStore.removeListener('user_auction_upcoming_vehicles_watchlist_toggle', this.upcomingVehiclesWatchlistDidToggle)
    AuctionStore.removeListener('auction_live_sales_status_change', this.auctionLiveSalesStatusDidChange)
  }

  auctionLiveSalesStatusDidChange = () => {
    this.setState({
      auctionLiveSalesStatus: AuctionStore.auctionLiveSalesStatus
    })
  }

  getUser = () => {
    this.setState({user: SessionStore.user})

    // If the user changed because of auction or channel change, we need to refetch the home
    if (this.state.userChanged) {
      this.setState({ userChanged: false })
      const { savedSearches } = this.props
      const homeSavedSearch = savedSearches?.home

      if (homeSavedSearch) {
        this.runHomeSavedSearchQuery(homeSavedSearch, 1)
      }
    }
  }

  runHomeSavedSearchQuery = (homeSavedSearch, page = 1) => {
    if (page === 1) { this.setState({ isLoadingHomeVehicles: true }) }

    // Force home search to always sort by created_at DESC
    homeSavedSearch.order = 'created_at desc'

    // Force home digital channels to always be from rooftop zip
    homeSavedSearch.zip_code = SessionStore.user?.rooftop?.zip_code

    let serverSearch = AuctionFunctions.savedSearchServerQueryFormatter(homeSavedSearch)
    serverSearch.search_type = 'home'
    AuctionsActionCreators.searchAuctionListings(page, serverSearch, this.clientID)
  }

  loadingFreshUpcomingVehicles = () => {
    // FIXME: needed?
    this.setState({
      isLoadingHomeVehicles: true
    })
  }

  upcomingVehiclesDidChange = () => {
    const isInitial = AuctionStore.auctionListingsSearchResultsPageNumber.dataWithID(this.clientID) === 1
    if (isInitial) {
      // Stamp our initial load for staleness checks
      SessionStore.auctionUpcomingLastFreshDataTime = moment()
    }
    this.updateUpcomingVehicles()
  }

  removeAuction = (locationCode) => {
    const { globalAuctionCodes } = this.state
    let newGlobalAuctionCodes = [...globalAuctionCodes]
    newGlobalAuctionCodes = newGlobalAuctionCodes.filter(function(l) { return l !== locationCode })
    this.setState({ globalAuctionCodes: newGlobalAuctionCodes })

    this.updateGlobalAuctionCodes(newGlobalAuctionCodes)


    // this.searchDidChange()
    platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location")
    platformFunctions.logAnalyticsEvent("Auctions", "Removed Auction Location - Auctions Home")
  }

  addAuction = (locationCode) => {
    const { globalAuctionCodes } = this.state
    let newGlobalAuctionCodes = [...globalAuctionCodes]

    if (!newGlobalAuctionCodes.includes(locationCode)) {
      newGlobalAuctionCodes.push(locationCode)
    }

    this.setState({ globalAuctionCodes: newGlobalAuctionCodes })

    this.updateGlobalAuctionCodes(newGlobalAuctionCodes)
    platformFunctions.logAnalyticsEvent("Auctions", "Added Auction Location")
    platformFunctions.logAnalyticsEvent("Auctions", "Added Auction Location - Auctions Home")
  }

  updateGlobalAuctionCodes = (locationCodes) => {
    const { savedSearches } = this.props
    const homeSavedSearch = savedSearches?.home

    this.setState({ userChanged: true })
    AuctionsActionCreators.updateGlobalSavedSearch(this.props.savedSearches.global.uuid, {physical_codes: locationCodes})

    if (homeSavedSearch) {
      let newHomeSavedSearch = {...homeSavedSearch}
      newHomeSavedSearch.physical_codes = locationCodes
      this.runHomeSavedSearchQuery(newHomeSavedSearch, 1)
    }
  }

  saveSearchUpdated = () => {;
    const updatedHomeSavedSearch = AuctionStore.savedSearchWithClientID(this.clientID)
    this.setState({ updatedHomeSavedSearch })
    this.runHomeSavedSearchQuery(updatedHomeSavedSearch, 1)
  }

  multipleSearchParamsDidChange = (params) => {
    const { savedSearches } = this.props
    const homeSavedSearch = savedSearches?.home
    this.setState({ isLoadingHomeVehicles: true})

    let searchParams = {}
    for (let param of params) {
      searchParams[param.name] = param.value
    }

    AuctionsActionCreators.updateSavedSearch(homeSavedSearch.uuid, searchParams, this.clientID)
  }

  searchDidChange = (paramName, value) => {
    const { savedSearches } = this.props
    const homeSavedSearch = savedSearches?.home
    this.setState({ isLoadingHomeVehicles: true})
    AuctionsActionCreators.updateSavedSearch(homeSavedSearch.uuid, {[paramName]: value}, this.clientID)
  }

  onWatchlistToggle = () => {
    platformFunctions.logAnalyticsEvent("Auctions", "Toggled Watchlist - Home")
    UserActionCreators.createMetric(AppConstants.metricNames.auctions_watchlist_home)
  }

  upcomingVehiclesWatchlistDidToggle = () => {
    // FIXME: investigate if this is used
    this.updateUpcomingVehicles()
  }

  updateUpcomingVehicles = () => {
    const upcomingVehicles = AuctionStore.auctionListingsSearchResults.dataWithID(this.clientID)
    this.setState({
      upcomingVehicles: upcomingVehicles,
      isLoadingHomeVehicles: false,
      isLoadingMoreUpcomingVehicles: false
    })
  }

  onScrollEnd = () => {
    if (AuctionStore.auctionListingsSearchResultsEndOfListReached.dataWithID(this.clientID) === true) { return }

    const { upcomingVehicles } = this.state
    const isEmptyResults = upcomingVehicles && upcomingVehicles.length === 0

    if (!this.state.isLoadingMoreUpcomingVehicles && !isEmptyResults) {
      const newPage = AuctionStore.auctionListingsSearchResultsPageNumber.dataWithID(this.clientID) + 1

      const { savedSearches } = this.props
      const homeSavedSearch = savedSearches?.home

      this.runHomeSavedSearchQuery(homeSavedSearch, newPage)
      platformFunctions.logAnalyticsEvent("Auctions", "Upcoming Listings Pagination")
      this.setState({
        isLoadingMoreUpcomingVehicles: true
      })
    }
  }

  onListingClick = (event, listingID) => {
    event.preventDefault()

    this.setState({
      clickedListingID: listingID,
      isShowingListing: true
    })
  }


  render() {
    const { savedSearches, accountStatusRestricted } = this.props
    const { isLoadingHomeVehicles, auctionListingsDisplayType, updatedHomeSavedSearch, isShowingEditChannelsModal, isShowingListing, clickedListingID } = this.state
    const { globalAuctionCodes, upcomingVehicles, auctionLiveSalesStatus } = this.state

    const homeSavedSearch = updatedHomeSavedSearch || savedSearches?.home
    const channels = homeSavedSearch?.digital_codes || []

    const hasHomeAuctionCodes = globalAuctionCodes?.length > 0
    const hasChannels = channels.length > 0

    const dotAuctionSalesStatus = auctionLiveSalesStatus?.find((sale) => sale.auction_code === 'EDGE_DOT_AUCTION')
    const dotAuctionSalesCount = dotAuctionSalesStatus?.active_listings_count

    const isEmptyResults = upcomingVehicles && upcomingVehicles.length === 0
    const needsCostsConfirmation = SessionStore.needsGlobalCostsEntry() && !accountStatusRestricted

    var digitalChannelsDisplayString = AuctionFunctions.digitalChannelsDisplayString(homeSavedSearch)

    return (
      <div className="body-content">
        <div className="content-header">
          <h1>Auctions</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          SessionStore.auctionUpcomingLastFreshDataTime !== null &&
          <DataFreshnessMonitor lastFreshDataTime={SessionStore.auctionUpcomingLastFreshDataTime} label='Auction data is' />
        }

        <div style={{marginBottom: '20px'}}>
          <AuctionsMap
            scopeType='auctions'
            maxInitialZoom={6}
            onAddAuction={this.addAuction}
            onRemoveAuction={this.removeAuction}
            isEmbedded
            showAddAuction />
        </div>

        {
          dotAuctionSalesStatus?.visible &&
          <Link to='/auctions/edge-dot-auction-sales' className="card auctions-home-edge-feature" style={{marginBottom: needsCostsConfirmation ? 0 : '20px'}}>
            <img src='/images/edge-cube-logo-large.png' style={{position: 'absolute', right: -20, top: -40, width: '200px', opacity: 0.1}} />
            <h2 className="card-title" style={{color: '#fff', fontSize: '1.6rem', marginBottom: '10px'}}>{AppConstants.auctionOnlineSourceNames['EDGE_DOT_AUCTION']} Live Sales</h2>
            <div style={{padding: '0 20px', marginBottom: '10px', color: 'rgba(255,255,255,0.8)'}}>
              There are currently <strong style={{color: '#fff'}}>{dotAuctionSalesCount > 0 ? pluralize('vehicle', dotAuctionSalesCount, true) : 'no live sales'}</strong> you can bid on right now.
            </div>
          </Link>
        }

        {
          needsCostsConfirmation &&
          <div className="auctions-home-features">
            <div className='common-feature' style={{flexGrow: 0, flexShrink: 0, width: '500px'}}>
              <h3 className="subsection-title">Common Actions</h3>
              <Link to='/auctions/search/' className="split-row split-row-left" style={{borderBottom: '1px solid rgba(255,255,255,0.4)', paddingBottom: '10px', marginBottom: '10px'}}>
                <MagnifyingGlassIcon color="#fff" width={24} height={24} style={{marginRight: '10px', flexGrow: 0, flexShrink: 0}} />
                <div>Search for vehicles</div>
              </Link>

              {
                SessionStore.hasGlobalAuctionFilterAccess() &&
                <div style={{borderBottom: '1px solid rgba(255,255,255,0.4)', paddingBottom: '10px', marginBottom: '10px'}}>
                  <div className='global-filters'>
                    <AuctionsGlobalFiltersControl />
                  </div>
                  <div style={{opacity: 0.7, marginLeft: '34px'}}>Ignore cars you're not interested in</div>
                </div>
              }
              <div className="split-row split-row-left" style={{alignItems: 'flex-start'}}>
                <GearIcon color="#fff" width={24} height={24} style={{marginRight: '10px', flexGrow: 0, flexShrink: 0}} />
                <div>
                  <Link to='/auctions/settings/'>Manage your auctions</Link>
                  <div style={{opacity: 0.7}}>Pick auctions you'd like to see vehicles from</div>
                </div>
              </div>
            </div>

            <GlobalCostsEntry />
          </div>
        }


        {
          !needsCostsConfirmation &&
          <div style={{marginBottom: '20px'}} className='split-row split-row-left auctions-home-shortcuts'>
            <Link to='/auctions/search/'><MagnifyingGlassIcon color="currentColor" />New search</Link>
            {
              SessionStore.hasGlobalAuctionFilterAccess() &&
              <a href="#global"><FunnelIcon width={20} height={20} color='currentColor' />Edit global filters</a>
            }
            <Link to='/auctions/settings/'><GearIcon width={20} height={20} color="currentColor" />Manage auctions</Link>
          </div>
        }

        <div className="card">
          {
            homeSavedSearch &&
            <div className="padded-row" style={{alignItems: 'flex-start'}}>
              <div>
                <h2>
                  Recently Added At Your Auctions
                </h2>

                <div>
                  {digitalChannelsDisplayString || 'No online sales selected'}
                  <a href="#edit" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingEditChannelsModal: true })
                  }}> Change online sales</a>
                </div>

              </div>
            </div>
          }

          {
            upcomingVehicles && !isLoadingHomeVehicles && !isEmptyResults &&
              <div className="card-list">
                <AuctionVehiclesDisplayBar
                  defaultDisplayType={SessionStore.auctionListingsDisplayType}
                  onListingsDisplayModeChange={(newAuctionListingsDisplayType) => {
                    this.setState({ auctionListingsDisplayType: newAuctionListingsDisplayType })
                    SessionStore.setAuctionListingsDisplayType(newAuctionListingsDisplayType)
                    UserActionCreators.createMetric(AppConstants.metricNames['auctions_list_type_change'], newAuctionListingsDisplayType)
                  }} />

                <AuctionsListingsList
                  isActivityList
                  listings={upcomingVehicles}
                  todayCount={AuctionStore.auctionListingsSearchResultsTodayCount.dataWithID(this.clientID)}
                  onScrollEnd={this.onScrollEnd}
                  auctionListingsDisplayType={auctionListingsDisplayType}
                  hasMore={AuctionStore.auctionListingsSearchResultsEndOfListReached.dataWithID(this.clientID) !== true}
                  showRunNumber={false}
                  showChannel
                  onWatchlistToggle={this.onWatchlistToggle}
                  onClick={(event, listingID) => {
                    this.onListingClick(event, listingID)
                  }} />
              </div>
          }

          {
            isEmptyResults && !isLoadingHomeVehicles && (hasHomeAuctionCodes || hasChannels) &&
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
              <div style={{marginTop: '20px'}}>No upcoming vehicles for the selected auctions.</div>
            </div>
          }

          {
            isEmptyResults && !isLoadingHomeVehicles && !hasHomeAuctionCodes && !hasChannels &&
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
              <div style={{marginTop: '20px'}}>Select some home options on the <Link to='/auctions/settings'>Manage Auctions</Link> screen or enable some digital sales above.</div>
            </div>
          }

          {
            isLoadingHomeVehicles &&
            <Spinner />
          }

          {
            isShowingEditChannelsModal &&
            <AuctionsEditOnlineChannelsModal
              savedSearch={homeSavedSearch}
              onDone={() => {
                this.setState({ isShowingEditChannelsModal: false })
              }}
              onMultipleSearchParamsChange={this.multipleSearchParamsDidChange}
            />
          }

          {
            isShowingListing &&
            <AuctionsListingShowModal
              listingsContext={upcomingVehicles}
              onEndOfListReached={this.onScrollEnd}
              listingID={clickedListingID}
              history={this.props.history}
              onDone={() => {
                this.setState({ isShowingListing: false })
              }} />
          }
        </div>
      </div>
    )
  }
}


const AuctionsHome = (props) => {
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()
  return (
    <AuctionsHomeInner
      {...props}
      hasLinkedAuctionEdge={hasLinkedAuctionEdge}
    />
  )
}

export default AuctionsHome
