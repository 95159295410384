const phoneNumber = '(800) 996-4028'
const supportEmailAddress = 'support@getcarbly.com'

export default {
  supportEmailAddress: supportEmailAddress,
  phoneNumber: phoneNumber,
  googleAnalyticsTrackingID: 'UA-111492234-1',
  VINLength: 17,
  vinEntryTypes: {
    'manual': 'manual',
    'paste': 'paste',
    'barcode': 'barcode',
    'ocr': 'ocr',
    'extension': 'extension',
    'iframe': 'iframe'
  },
  heartbeatPeriod: 30 * 1000,
  periodicUserRefreshPeriod: 3600000,
  initialUserRefreshDelay: 1000,
  initialHeartbeatDelay: 12000,
  initialNewsCheckDelay: 500,
  newsCheckPeriod: 60000 * 60,
  serverTimeoutPeriod: 30 * 1000,
  keyboardNumeralsHeight: 54,
  llmAllRadiusValue: 9999,
  notificationPermissionPromptDuration: (60 * 60) * 24,
  recentValuationsListClientID: 'history',
  emptySeriesValue: 'empty_series',
  emptyChoiceValue: -1,
  serverErrorMessage: 'There was a problem on the Carbly servers.  We apologize, and will investigate immediately.',
  server403Message: `You do not have access to view this.\n\nIf you feel you're seeing this message in error, call us at ${phoneNumber} or email us at ${supportEmailAddress}`,
  server404Message: 'No longer available',
  server422Message: 'This request could not be processed',
  connectivityErrorMessage: 'We were unable to connect to the Carbly servers.  Please check that you have internet access and try again.',
  loginServerErrorMessage: `There was a problem connecting. Try again or call support at ${phoneNumber}`,
  invalidLoginMessage: 'Email address or password is incorrect',
  adjustmentsMessage: 'Adjusted for mileage and region',
  noInternetNotificationMessage: 'No internet connection detected',
  enableNotificationsInstructionsTitle: 'Enabling Notifications',
  enableNotificationsInstructionsBody: 'It appears that you previously disabled push notifications for Carbly.  To re-enable them, open the Settings App on your device.',
  enablePhotosAccessInstructionsTitle: 'Photo Library Access',
  enablePhotosAccessInstructionsBody: 'It appears that you previously prevented Carbly from accessing your photo library.  To re-enable, open the Settings App on your device.',
  enableCameraAccessInstructionsTitle: 'Camera Access',
  enableCameraAccessInstructionsBody: 'It appears that you previously prevented Carbly from accessing your camera.  To re-enable, open the Settings App on your device.',
  enableVINScannerAccessInstructionsBody: 'In order to scan a VIN barcode, Carbly needs access to your camera. To enable access, open the Settings App on your device.',
  valuationsDisabledTitle: 'Appraisals are not active',
  valuationsExpiringDaysThreshold: 3,
  permissionsVehiclesAccess: 'access_vehicles',
  permissionsValuationProvidersAccess: 'access_valuation_providers',
  permissionsIFrameAccess: 'access_iframe',
  planNameBasic: 'basic',
  planNamePro: 'pro',
  endDateTenseArray: ['Ends ', 'Ended '],
  generalDescriptionPlaceholder: 'Add any additional notes about the vehicle',
  providerNames: {
    'black_book': 'Black Book®',
    'manheim': 'Manheim Market Report',
    'nada': 'J.D. Power Values',
    'carfax': 'CARFAX™',
    'autocheck': 'AutoCheck®',
    'vintel': 'VinTel®',
    'pin': 'Real Retail',
    'universe': 'Live Local Market',
    'kbb': 'Kelley Blue Book®',
    'carbly': 'Carbly Appraisal Assistant',
    'matchup_wholesale': 'Wholesale SpeedView®',
    'matchup_retail': 'Retail SpeedView®',
    'pmr': 'Pipeline Market Report',
    'galves': 'Galves® Market Data',
    'auction_edge': 'Auction Edge',
    'regional_report': 'Market Tracker',
    'auctions_plus': 'Auctions Plus'
  },
  providerShortNames: {
    'carbly': 'Carbly',
    'manheim': 'MMR',
    'nada': 'JDP',
    'kbb': 'KBB',
    'black_book': 'BB',
    'pin': 'RR',
    'universe': 'LLM',
    'pmr': 'PMR',
    'galves': 'Galves',
    'matchup_wholesale': 'WSV',
    'matchup_retail': 'RSV',
    'carfax': 'FAX',
    'autocheck': 'AC',
  },
  skuMappings: {
    'manheim': 'book_mmr',
    'nada': 'book_nada',
    'kbb': 'book_kbb',
    'black_book': 'book_bb',
    'pin': 'book_pin',
    'universe': 'book_universe',
    'pmr': 'book_pmr',
    'galves': 'book_galves',
    'marketTracker': 'feature_market_tracker',
    'auctions_plus': 'feature_auctions_plus',
  },
  providerDescriptions: {
    'manheim': 'The Market Report is the premier indicator of wholesale prices and shows retail values, price history and projections.',
    'nada': 'Standard for lenders when underwriting loans, and offering Enhanced Vehicle Values with pre-selected add / deducts and trims for the VIN.',
    'kbb': 'Comprehensive data for wholesale and retail. A must have for trade-in negotiations and loan underwriting.',
    'black_book': 'Delivers actionable intelligence and accurate car value data you can count on for both the wholesale and retail markets.',
    'pin': 'Actual transaction data from dealers in the US, sale price, days on lot and profits. Comp analysis.',
    'universe': 'Insight into the inventory of your competitors. Average mileage and price, history specific pricing and analysis of comps.',
    'pmr': 'A must-have for indpendent auction buyers and sellers.  Pipeline EDGE sale transactions, trends and market pricing.',
    'galves': 'Wholesale market data updated daily, driven by thousands of transactions analyzed by a team of national remarketing specialists.',
    'auctions_plus': 'Unlimited saved searches, search by vehicle price and profit, team search sharing, and more.',
  },
  matchupGenericName: 'Carbly SpeedView®',
  defaultProviderOrder: ['matchup_wholesale', 'matchup_retail', 'black_book', 'kbb', 'manheim', 'pmr', 'pin', 'universe', 'nada', 'galves', 'carfax', 'vintel', 'autocheck'],
  edgePipelinePreviewDefaultProviderOrder: ['pmr', 'nada', 'manheim', 'black_book', 'kbb', 'universe', 'pin', 'carfax', 'galves', 'vintel', 'autocheck'],
  defaultOverallCondition: 'average',
  overallConditions: {
    'rough': {title: 'Rough', description: 'Significant damage and wear. Needs repairs.', icon: '★'},
    'average': {title: 'Average', description: 'Typical wear and tear for a vehicle with this mileage. Systems all functional.', icon: '★★'},
    'clean': {title: 'Clean', description: 'In better condition than most vehicles with this mileage. No damage and very few cosmetic defects.', icon: '★★★'}
  },
  auctionOnlineSourceNames: {
    'MANHEIM_OVE': 'OVE',
    'MANHEIM_EXPRESS': 'Manheim Express',
    'SMART_AUCTION': 'SmartAuction',
    'EDGE_DOT_AUCTION': 'EDGE.Auction',
    'AUCTION_CENTRAL': 'Auction Central',
    'E_BLOCK_WEST': 'EBlock',
    'ADESA_CLEAR': 'ADESA Clear',
    'DEALER_BLOCK': 'ADESA DealerBlock',
  },
  edgePipelineName: 'EDGE Pipeline',
  commonFontSizeMultiplier: 1.2,
  auctionIndexTypes: {
    'upcoming': 'upcoming',
    'lane': 'lane'
  },
  auctionOnlineSaleSentinel: '__digital',
  auctionSearchMileageMaxAll: 180000,
  auctionSearchWholesaleValueMaxAll: 90000,
  auctionSearchDefaultDistanceRadius: 50,
  auctionSearchMaxDistanceRadius: 1500,
  auctionSearcAllAuctionsRadius: 4000,
  auctionSimilarVehiclesSearchRadius: 100,
  auctionNoDigitalAuctionsSentinel: '__NO_DIGITAL_AUCTIONS__',
  noAppraisalMoreBooksNeeded: 'more_books_needed',
  noAppraisalVehicleLackingData: 'vehicle_lacking_data',
  avatarSizes: {
    'xsmall': 28,
    'small': 32,
    'medium': 56,
    'large': 136
  },
  matchupTypes: {
    prices: 'prices',
    mileage: 'mileage',
    trends: 'trends'
  },
  userRequiredFields: ['first_name', 'last_name', 'phone_number'],
  rooftopRequiredFields: ['zip_code', 'name'],
  sortParams: {
    'start_time ASC': 'Closest sale date',
    'start_time DESC': 'Furthest sale date',
    'created_at ASC': 'Oldest added',
    'created_at DESC': 'Newest added',
    'condition_grade ASC': 'Condition grade (low to high)',
    'condition_grade DESC': 'Condition grade (high to low)',
    'mileage ASC': 'Mileage (low to high)',
    'mileage DESC': 'Mileage (high to low)',
    'year ASC': 'Model year (low to high)',
    'year DESC': 'Model year (high to low)',
    'make ASC': 'Make (A to Z)',
    'make DESC': 'Make (Z to A)',
    'wholesale_value_cents ASC': 'Estimated sale price (low to high)',
    'wholesale_value_cents DESC': 'Estimated sale price (high to low)',
    'retail_value_cents ASC': 'Estimated retail price (low to high)',
    'retail_value_cents DESC': 'Estimated retail price (high to low)',
    'profit_value DESC': 'Estimated profit (high to low)',
  },
  auctionsPlusOnlySortParams: ['wholesale_value_cents ASC', 'wholesale_value_cents DESC', 'retail_value_cents ASC', 'retail_value_cents DESC', 'profit_value DESC'],
  auctionsSearchEmptyLocationsSentinel: '__NO_AUCTIONS__',
  metricNames: {
    'market_tracker_upsell': 'mt_upsell',
    'market_tracker_zip_change': 'mt_zip',
    'market_tracker_radius_change': 'mt_radius',
    'market_tracker_model_change': 'mt_model',
    'market_tracker_year_change': 'mt_year',
    'market_tracker_trim_change': 'mt_trim',
    'market_tracker_listings_show': 'mt_listings',
    'market_tracker_model_show_at_auction': 'market_tracker_model_show_at_auction',
    'market_tracker_model_show_details': 'market_tracker_model_show_details',
    'auctions_view_home': 'auctions_view_home',
    'auctions_plus_upsell': 'aplus_upsell',
    'auctions_new_search': 'auctions_new_search',
    'auctions_query_run': 'auctions_query_run',
    'auctions_query_run_free': 'auctions_query_run_free',
    'auctions_query_run_paid': 'auctions_query_run_paid',
    'auctions_query_with_access': 'auctions_query_with_access',
    'auctions_query_without_access': 'auctions_query_without_access',
    'auctions_similar_vehicles_search': 'auctions_similar_vehicles_search',
    'auctions_load_search': 'auctions_load_search',
    'auctions_load_team_search': 'auctions_load_team_search',
    'auctions_save_search': 'auctions_save_search',
    'auctions_delete_search': 'auctions_delete_search',
    'llm_found_at_retail_show': 'llm_found_at_retail_show',
    'llm_found_at_retail_upsell': 'llm_found_at_retail_upsell',
    'found_at_auction_show': 'found_at_auction_show',
    'calulator_global_show': 'calulator_global_show',
    'auctions_list_type_change': 'auctions_list_type_change',
    'auctions_lane_list_type_change': 'auctions_lane_list_type_change',
    'auctions_today_view_show': 'auctions_today_view_show',
    'auctions_added_auction': 'auctions_added_auction',
    'auctions_removed_auction': 'auctions_removed_auction',
    'auctions_added_nearby_auction': 'auctions_added_nearby_auction',
    'global_costs_save': 'global_costs_save',
    'add_user_show': 'add_user_show',
    'auctions_show_lanes': 'auctions_show_lanes',
    'auctions_show_sale_vehicles': 'auctions_show_sale_vehicles',
    'vehicles_view_search': 'vehicles_view_search',
    'vehicles_search_ymm': 'vehicles_search_ymm',
    'vehicles_search_vin': 'vehicles_search_vin',
    'vehicle_assign_folder': 'vehicle_assign_folder',
    'auctions_watchlist_lane': 'auctions_watchlist_lane',
    'auctions_watchlist_dot_auction': 'auctions_watchlist_dot_auction',
    'auctions_watchlist_home': 'auctions_watchlist_home',
    'auctions_watchlist_search': 'auctions_watchlist_search',
    'plan_management_show': 'plan_management_show',
    'plan_management_open_offering': 'plan_management_open_offering',
    'plan_management_added_offering': 'plan_management_added_offering',
  }
}
