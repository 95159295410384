const noValueSentinal = 'None'

export default {

  choicesForYears: function(selectedYear, selectedMake, selectedModel, corpus) {
    var yearResults = []
    if (!selectedMake && !selectedModel) {
      yearResults = corpus.map((year) => { return ( year.year )})
    } else {
      for (let year of corpus) {
        for (let make of year.makes) {
          if (!selectedMake || (selectedMake && make.name === selectedMake)) {
            if (selectedModel) {
              for (let model of make.models) {
                if (model === selectedModel) {
                  yearResults.push(year.year)
                  break
                }
              }
            } else {
              yearResults.push(year.year)
              break
            }
          }
        }
      }
    }
    if (selectedYear !== null) {
      yearResults.unshift(noValueSentinal)
    }
    return yearResults
  },

  choicesForMakes: function(selectedYear, selectedMake, selectedModel, corpus) {
    var makeResults = []
    if (selectedYear === null) {
      for (let year of corpus) {
        for (let make of year.makes) {
          if (!makeResults.includes(make.name)) {
            makeResults.push(make.name)
          }
        }
      }
    } else {
      for (let year of corpus) {
        if (year.year === selectedYear) {
          makeResults = year.makes.map((make) => make.name)
        }
      }
    }

    makeResults.sort()
    if (selectedMake !== null) {
      makeResults.unshift(noValueSentinal)
    }

    return makeResults
  },

  choicesForModels: function(selectedYear, selectedMake, selectedModel, corpus) {
    var modelResults = []
    for (let year of corpus) {
      if (!selectedYear || (selectedYear && selectedYear === year.year)) {
        for (let make of year.makes) {
          if (!selectedMake || (selectedMake && make.name === selectedMake)) {
            for (let model of make.models) {
              if (!modelResults.includes(model)) {
                modelResults.push(model)
              }
            }
          }
        }
      }
    }
    modelResults.sort()
    if (selectedModel !== null) {
      modelResults.unshift(noValueSentinal)
    }

    return modelResults
  },

  paramsForSearch: function({ selectedYear, selectedMake, selectedModel, vinSearch }) {
    const params = {}
    if (selectedYear) { params['qyear'] = selectedYear}
    if (selectedMake) { params['qmake'] = selectedMake }
    if (selectedModel) { params['qmodel'] = selectedModel}
    if (vinSearch) { params['qvin'] = vinSearch}
    return params
  }


}
