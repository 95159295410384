import React from 'react'
import AppConstants from '../api/constants/AppConstants'

export default class NoLocalStorageSupport extends React.Component {
  render() {
    return (
      <div style={{height: '100%'}}>

        <div style={{margin: '10px'}}>
          <div className="content-header">
            <h1>Browser Configuration</h1>
          </div>
          <div>
            <p>Your browser is currently configured to block third party cookies, which means it is also blocking the Carbly Extension.  In order to use the extension, you'll need to do one of the following.</p>

            <h1 style={{color: '#111'}}>Enable Carbly for All Sites</h1>

            <ol style={{marginTop: '20px'}}>
              <li style={{marginBottom: '10px'}}>
                <strong>Edit your Chrome browser settings</strong> by going to the "Chrome" menu at the top of your screen and selecting "Preferences".  This will open a new browser tab, so you can refer back to this tab to continue following the steps.
              </li>
              <li style={{marginBottom: '10px'}}>
                Under the <strong>"Privacy and security"</strong> section, you'll see <strong>"Cookies and other site data"</strong>, click it.
              </li>
              <li style={{marginBottom: '10px'}}>
                You should see the currently selected choice at the top is "Block third-party cookies".  You want to change that choice to be <strong>"Block third-party cookies in Incognito"</strong>.
              </li>
              <li style={{marginBottom: '10px'}}>
                Now, <strong>refresh this page</strong> in your browser and you should be all set.
              </li>
            </ol>


            <h1 style={{color: '#111', marginTop: '40px'}}>Or, Enable Carbly on a Per-Site Basis</h1>
            <ol style={{marginTop: '20px'}}>
              <li style={{marginBottom: '10px'}}>
                <div><img src="/images/chrome-privacy-icon.png" alt="Chrome privacy" style={{width: '97px', height: '36px', boxShadow: '0 1px 12px rgba(0,0,0,0.25)', float: 'right', marginLeft: '10px', marginBottom: '5px'}} />In the address bar of your browser, to the far right, you should see an icon that looks like an eye with a line through it.  <strong>Click that icon</strong>.</div>
                
              </li>
              <li style={{marginBottom: '10px'}}>
                In the little window that pops up, <strong>click "Site not working?"</strong>
              </li>
              <li style={{marginBottom: '10px'}}>
                <strong>Click "Allow Cookies"</strong>
              </li>
              <li style={{marginBottom: '10px'}}>
                You'll have to do this step for each different site you want to use Carbly on.
              </li>
            </ol>

            <p>If you have any problems, don't hesitate to call us at {AppConstants.phoneNumber}, and we'll be glad to help you out.</p>
            
          </div>
        </div>
      </div>
    )
  }
}
