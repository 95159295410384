import React from 'react'
import ExtensionIcon from '../components/common/icons/ExtensionIcon'
import platformFunctions from '../platformFunctions'
import AppConstants from '../api/constants/AppConstants'

const chromeStoreURL = 'https://chrome.google.com/webstore/detail/carbly-vin-scanner-extens/hpodldbdjhamlalomidlimbfbaimofmh/'

export default class ExtensionPage extends React.Component {
  componentDidMount() {
    platformFunctions.logAnalyticsEvent("User", "Opened Extension Lander")
  }

  render() {
    var browserType = null
    browserType = null
    if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
      browserType = 'chrome'
    } else if(navigator.userAgent.indexOf("Safari") !== -1) {
      // browserType = 'safari'
    }
    
    return (
      <div style={{height: '100%'}}>
        
        <div className="page-body-c extension-body-c">

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} className="content-header">
            <div className="extension-icon"><ExtensionIcon /></div>
            <h1 style={{margin: '0 0 0 10px'}}>Carbly Browser Extension</h1>
          </div>

          <h2>Your Carbly data wherever you see a VIN</h2>
          <p>As a dealer, you spend a lot of time looking at vehicles online. Sourcing in a competitive market demands that you buy from a wide variety of online auctions and wholesale marketplaces. <strong>Save time and increase efficiency with the Carbly Browser Extension</strong>; it will automatically <strong>find VINs on any web page</strong> and show Carbly market data directly in your browser.</p>

          <iframe className="embed-responsive-item" width="620" height="349" src="https://www.youtube.com/embed/uau75_83vo4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

          <div style={{display: 'flex', flexDirection: 'row'}}>

            <div>
              <h2>How it works</h2>
              <p>Once installed, the Carbly Browser Extension is always working in the background looking for VINs. <strong>Just click the blue tab to see your Carbly market data without ever leaving the page</strong>.</p>


              <p>The Carbly Browser Extension works on vehicle detail pages where a single VIN is displayed or on pages that display mulitple VINs, meaning you can assess market data on lists of vehicles in the extension without having to click into individual vehicle detail pages.</p>


              <h2 style={{marginTop: '40px'}}>How to get started</h2>
              <p>It's quick and easy to get started. Just follow the simple steps below:</p>

              <div className="extension-instruction">
                <div className="number">1</div>
                <div className="number-instructions">
                  <h3>Install the Extension</h3>
                  <p>Currently, the extension is only available for the Chrome browser from Google.</p>
                  
                  {
                    browserType !== 'chrome' &&
                    <p>If you don't already have it, you can <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">download Chrome here</a>.</p>
                  }
                  
                  
                  {
                    (browserType === null || browserType === 'chrome') &&
                    <a href={chromeStoreURL} className="install-button" target="_blank" rel="noopener noreferrer">
                      <div>
                        <img src="/images/chrome-icon.png" style={{width: '46px', height: '46px', marginRight: '10px', flexGrow: 0, flexShrink: 0}} alt="Chrome logo" />
                        <div>
                          <h3>Install the Carbly Extension for Chrome</h3>
                          <div>Click <span style={{textDecoration: 'underline'}}>here</span> to open the Chrome Store and install the Carbly Browser Extension.</div>
                        </div>
                      </div>
                    </a>
                  }
                  
                </div>
              </div>

              <div className="extension-instruction">
                <div className="number">2</div>
                <div className="number-instructions">
                  <h3>View Appraisal Data</h3>
                  <p>Once you visit a web page that has a VIN number on it, you'll see a blue Carbly tab appear in the top right of your browser window.  Click it to show the Carbly Browser Extension.  Click the blue tab again to hide the data if you wish.</p>


                </div>
              </div>

              <div style={{margin: "40px 0"}}>Need help getting started?  We're happy to help, just give us a call at {AppConstants.phoneNumber}.</div>


            </div>


            <div style={{marginLeft: '40px'}}>
              <img src="/images/extension-screenshot.png" alt="Browser extension" style={{width: "540px", height: "560px"}} />
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', justifyContent: 'center'}}>
                <div>Available for: </div>
                <a href={chromeStoreURL} target="_blank" rel="noopener noreferrer" style={{color: '#111'}}>
                  <img src="/images/chrome-icon.png" alt="Google Chrome" style={{width: '40px', height: '40px', marginLeft: '20px', marginRight: '6px'}} />
                  Chrome
                </a>
              </div>
            </div>
          </div>
        </div>


      </div>
    )
  }
}
