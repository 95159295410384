// https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-v3-no-offline-support
import { createAuthLink } from 'aws-appsync-auth-link'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  fromPromise,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from "@apollo/client/link/retry"
import { getMainDefinition } from '@apollo/client/utilities'
import SessionStore from '../stores/SessionStore'
import WebAPIUtils from '../actions/WebAPIUtils'
import getBiddingToken from './functions/getBiddingToken'
import { Amplify } from 'aws-amplify'

let client

const cacheConfig = {
  typePolicies: {
    VenueListing: {
      fields: {
        myFailedBid: {
          read(existing) {
            return existing ?? null
          },
        },
      },
    },
    Query: {
      fields: {
        venueListings: {
          merge(existing, incoming, { readField }) {
            const merged = { ...existing }
            incoming.forEach(item => {
              merged[readField('id', item)] = item
            })
            return merged
          },
          read(existing) {
            return Object.values(existing || {})
          },
        },

        // Read data from venueListings cache if queried as single venueListing
        venueListing(_, { args, toReference }) {
          return toReference({
            __typename: 'VenueListing',
            id: args.venueListingId,
          });
        },
      },
    },
  },
}

const getAuthHeaders = ({ headers }, token) => {
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    }
  }
}

const createPipelineLink = config => {
  return ApolloLink.from([
    setContext((_operation, context) => {
      const token = SessionStore.user?.auction_edge_token
      return getAuthHeaders(context, token)
    }),

    // TODO: This handles auth errors (i.e. expired token). Auth errors should not be
    // graphQLErrors with 200 response, rather a server error (i.e. unauthorized).
    // So watch for API changes.
    onError(({ forward, operation, graphQLErrors }) => {
      if (graphQLErrors?.[0]?.message === 'Current Project not specified') {
        // FIXME: Handle error?
        return fromPromise(WebAPIUtils.refreshAuctionEdgeToken()).flatMap(([data, _status]) => {
          operation.setContext(getAuthHeaders(operation.getContext(), data.auction_edge_token))
          return forward(operation)
        })
      }
    }),

    new HttpLink({ uri: config.url }),
  ])
}

const isSubscriptionConnectionCloseError = (operation, error) => {
  return getMainDefinition(operation.query).operation === 'subscription' &&
    ['Connection closed', 'Timeout disconnect'].includes(error?.errors?.[0].message)
}

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    console.log('retryLink', count, operation.operationName, error)
    return isSubscriptionConnectionCloseError(operation, error) && count <= 2
  },
  delay: (_count, operation, error) => {
    if (isSubscriptionConnectionCloseError(operation, error)) {
      return 3000
    } else {
      // should not run
    }
  }
})

const createBiddingLink = config => {
  return ApolloLink.from([
    setContext(async (_operation, context) => {
      const token = await getBiddingToken(client)
      return getAuthHeaders(context, token)
    }),

    retryLink,

    onError((args) => {
      const { forward, operation, graphQLErrors, networkError } = args
      if (
        // Query/mutation
        (graphQLErrors?.[0].errorType === 'UnauthorizedException') ||
        // Subscription
        (networkError?.errors?.[0].message.includes('UnauthorizedException'))
      ) {
        return fromPromise(getBiddingToken(client, { fetchPolicy: 'network-only' })).flatMap(token => {
          operation.setContext(getAuthHeaders(operation.getContext(), token))
          return forward(operation)
        })
      }
    }),

    createAuthLink(config),

    new HttpLink({ uri: config.url }),
  ])
}

export default ({ pipelineConfig, biddingConfig }) => {
  Amplify.configure({
    aws_appsync_graphqlEndpoint: biddingConfig.url,
    aws_appsync_region: biddingConfig.region,
    aws_appsync_authenticationType: biddingConfig.auth.type,
    aws_appsync_apiKey: biddingConfig.auth.token,
  })

  const link = new ApolloLink.split(
    (operation) => operation.getContext().endpointName === 'pipeline',
    createPipelineLink(pipelineConfig),
    createBiddingLink(biddingConfig),
  )

  client = new ApolloClient({
    link,
    cache: new InMemoryCache(cacheConfig),
    resolvers: {},
  })

  return client
}
