import React from 'react'
import Select, {createFilter, IndicatorSeparator} from 'react-select'

export default class TFSelect extends React.Component {

  render() {

    const filterConfig = {
      matchFrom: this.props.matchFrom ? this.props.matchFrom : 'start'
    }

    return (
      <Select
        styles={
          {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px solid #aaa',
              padding: '10px'
            }),
            control: (provided, state) => ({
              ...provided,
              width: this.props.controlWidth,
              marginBottom: '5px',
              border: '1px solid #aaa',
              borderRadius: '8px',
              ...this.props.controlStyle
            }),
            groupHeading: (provided, state) => ({
              ...provided,
              backgroundColor: '#ddd',
              color: '#111',
              paddingTop: '6px',
              paddingBottom: '6px',
              fontWeight: 'bold'
            })
          }
        }
        maxMenuHeight={400}
        {...this.props}
        filterOption = {
          createFilter(filterConfig)
        }
      />      
    )
  }

}
