import React, { memo, useMemo, useCallback } from 'react'
import moment from 'moment'
import Avatar from '../user/Avatar'
import TimeAgo from '../common/TimeAgo'
import SessionStore from '../../api/stores/SessionStore'
import AppConstants from '../../api/constants/AppConstants'


const Activity = ({ activity, onClick: propsOnClick, isDisabled }) => {
  const { message, created, user_uuid } = activity

  const onClick = useCallback(() => {
    !isDisabled && propsOnClick && propsOnClick(activity)
  }, [propsOnClick, activity, isDisabled])

  const className = useMemo(() => {
    return [
      'activity',
      !isDisabled && propsOnClick && 'cursor-pointer no-select',
    ].filter(Boolean).join(' ')
  }, [propsOnClick, isDisabled])

  const user = SessionStore.getActivityUser(user_uuid)

  if (!user) {
    return null
  }

  const { full_name: userName } = user
  const text = `${userName} ${message}`

  return (
    <div
      className={className}
      onClick={onClick}
      style={{opacity: isDisabled ? 0.8 : 1.0, backgroundColor: isDisabled ? '#eee' : '#fff'}}
    >
      <div className='activity__col-1'>
        <Avatar
          user={user}
          size={AppConstants.avatarSizes.medium}
        />
        <div className='activity__created'>
          <div>
            { moment(created).format('MMM D') }
          </div>
          <div>
            { moment(created).format('h:mm a') }
          </div>
        </div>
      </div>
      <div className='activity__col-2'>
        {text}
        <div className="timeago"><TimeAgo time={created} /></div>
      </div>
    </div>
  )
}

export default memo(Activity)
