import React from 'react'
import { Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Avatar from '../user/Avatar'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'


export default class ValuationNotes extends React.Component {

  state = {
    newNoteText: '',
    isSubmitting: false
  }

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidMount() {
    ValuationStore.on('valuation_note_created', this.noteSaved)
  }

  componentWillUnmount() {
    ValuationStore.removeListener('valuation_note_created', this.noteSaved)
  }

  saveNote = () => {
    this.setState({ isSubmitting: true })
    ValuationActionCreators.createValuationNote(this.props.vehicleUUID, this.state.newNoteText, this.props.clientID)
  }

  noteSaved = () => {
    this.setState({
      isSubmitting: false,
      newNoteText: ''
    })
  }


  render() {
    const { newNoteText, isSubmitting } = this.state
    const vehicleNotes = this.props.vehicleNotes

    return (
      <div className="valuation-notes-c">

        {
          vehicleNotes && vehicleNotes.length > 0 &&
          <div className="valuation-notes">
          {
            vehicleNotes.map((note, index) => {
              let user = note.user
              return (
                <div className="valuation-note" key={`v-note-${index}`}>
                  <div className="avatar">
                    <Avatar
                      user={user}
                      size={AppConstants.avatarSizes.small}
                    />
                  </div>
                  <div className="note-body">
                    {note.note}
                    <div className="secondary-text" style={{marginTop: '2px', fontStyle: 'italic'}}>{user.full_name}</div>
                    <div className="secondary-text" style={{fontStyle: 'italic'}}>{Formatters.formatVerboseDate(note.created)}</div>
                  </div>
                </div>
              )
            })
          }
          </div>
        }

        {
          isSubmitting === false && !this.isShared &&
          <div>
            <textarea value={newNoteText} onChange={(e) => this.setState({newNoteText: e.target.value})} placeholder="Add a note..." />
            {
              newNoteText.length > 0 &&
              <div className="save-note-button-c">
                <Button color="primary" onClick={this.saveNote}>Save note</Button>
              </div>
            }
          </div>
        }

        {
          isSubmitting === true &&
          <Spinner />
        }

      </div>
    )
  }



}
