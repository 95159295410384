import { useCallback, useEffect, useRef } from 'react'

export default ({ onIntersect, shouldObserve }) => {
  const scrollRef = useRef()

  const handleObserver = useCallback(([target]) => {
    if (target.isIntersecting) onIntersect()
  }, [onIntersect])

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    }
    const observer = new IntersectionObserver(handleObserver, options);
    const currentRef = scrollRef.current

    if (currentRef && shouldObserve) {
      observer.observe(scrollRef.current)
    }

    return () => observer.unobserve(currentRef)
  }, [handleObserver, shouldObserve])

  return {
    scrollRef,
  }
}
