import React from 'react'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionListing from './AuctionListing'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import useHasLinkedAuctionEdge from '../../api/bidding/hooks/useHasLinkedAuctionEdge'
import SessionStore from '../../api/stores/SessionStore'
import platformFunctions from '../../platformFunctions'

class AuctionsListingShowInner extends React.Component {

  constructor() {
    super()
    this.listingID = null

    this.state = {
      isLoading: true,
      listing: null,
      serverErrorMessage: null,
      is404: false,
      valuation: null,
      isLoadingValuation: false
    }
  }

  componentDidMount() {
    // FIXME: can probably get rid of all of the match prop stuff
    if (!CommonFunctions.hasValidIDMatchProp(this.props) && !this.props.isModal) {return}
    this.listingID = this.props.navMatch?.params?.id || this.props.listingID

    const listingsContext = this.props.history?.location?.listingsContext || this.props.listingsContext
    if (listingsContext) {
      this.listingsContext = listingsContext
    }

    this.addListeners()
    this.fetchListing(this.listingID)

    if (!SessionStore.hasAccountOfferings()) {
      platformFunctions.logAnalyticsEvent("User", "Free User Show Auction Listing")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!CommonFunctions.hasValidIDMatchProp(this.props) && !this.props.isModal) {return}

    const listingID = this.props.navMatch?.params?.id || this.props.listingID

    if (listingID && (this.listingID === undefined || this.listingID !== listingID)) {
      this.setState({
        listing: null,
        valuation: null,
        isLoading: true,
        is404: false
      })
      
      this.removeListeners()
      this.listingID = listingID
      this.addListeners()

      // Immediate is too fast for the images to clear, so wait a bit
      setTimeout(() => {
        this.fetchListing(this.listingID)
      }, 50)
      
    }
  }

  componentWillUnmount() {
    this.removeListeners()
  }


  fetchListing = () => {
    AuctionStore.fetchListingWithID(this.listingID)
  }

  prefetchNextListing = (currentListing) => {
    const nextListingInContext = AuctionFunctions.nextListingInListingsContext(currentListing, this.listingsContext)
    if (nextListingInContext) {
      setTimeout(() => {
        AuctionStore.preFetchListingWithID(nextListingInContext.id)
      }, 150)
    }
  }

  listingIsLoading = () => {
    this.setState({ isLoading: true })
  }

  getListing = () => {
    if (!this.refs.mainRef) { return }
    const listing = AuctionStore.listingWithID(this.listingID)
    if (this.props.onListingChange) {
      this.props.onListingChange(listing)
    }
    if (listing) {
      var isLoadingValuation = false
      if (this.props.showValuation !== false && listing.vehicle && listing.vehicle.uuid) {
        isLoadingValuation = true
        setTimeout(() => {
          ValuationActionCreators.loadExistingVehicle(listing.vehicle.uuid, this.listingID)
        })
      }
      this.setState({
        listing: listing,
        isLoading: false,
        isLoadingValuation: isLoadingValuation
      })

      // Eagerly fetch the next listing
      if (this.listingsContext) {
        this.prefetchNextListing(listing)
      }
    } else {
      this.setState({
        isLoading: false,
        is404: true
      })
    }
  }

  addListeners = () => {
    AuctionStore.on('auction_listing_load', this.listingIsLoading)
    AuctionStore.on('auction_listing_received', this.getListing)
    AuctionStore.on('auction_listing_change', this.auctionListingDidChange)
    ValuationStore.on(`valuation_change_${this.listingID}`, this.getValuation)
  }

  removeListeners = (listingID) => {
    AuctionStore.removeListener('auction_listing_load', this.listingIsLoading)
    AuctionStore.removeListener('auction_listing_received', this.getListing)
    AuctionStore.removeListener('auction_listing_change', this.auctionListingDidChange)
    ValuationStore.removeListener(`valuation_change_${this.listingID}`, this.getValuation)
  }



  getValuation = () => {
    if (this.refs.mainRef) {
      const newValuation = ValuationStore.valuationWithClientID(this.listingID)

      this.setState({
        valuation: newValuation,
        isLoadingValuation: false
      })
    }
  }

  updateValuationWithReload = (params) => {
    const { valuation } = this.state

    this.setState({
      isLoadingValuation: true,
      valuation: null
    })

    ValuationActionCreators.updateValuation(valuation.uuid, params, this.listingID)
  }

  auctionListingDidChange = () => {
    const { valuation } = this.state
    if (!valuation) { return }

    // Auction listing changed, (due to watchlist toggle), need to refresh this vehicle's activity, which is nested
    const scope = { vehicle_uuid: valuation.uuid }
    ValuationActionCreators.loadVehicleActivities(null, scope, this.listingID)
  }

  onNavigateInContext = (listing) => {
    this.setState({
      listing: null,
      serverErrorMessage: null,
      is404: false,
      valuation: null,
      isLoadingValuation: false
    })

    if (!this.props.isModal) {
      // FIXME: probably not needed
      // Give the photos a chance to clear from previous listing, since it leads to a confusing render
      setTimeout(() => {
        this.props.history.replace({
          pathname: `/auctions/listing/${listing.id}`,
          listingsContext: this.listingsContext
        })
      }, 10)
    }
  }

  render() {
    const { listing, isLoading, is404, isLoadingValuation, valuation } = this.state
    if (is404) {
      return (
        <div className="body-content">
          <div>Auction listing not found</div>
        </div>
      )
    }

    if (isLoading) {
      return (
        <div className="body-content" ref="mainRef">
          <Spinner className="spinner-page" />
        </div>
       )
    }
    if (listing) {
      return (
        <AuctionListing
          hasLinkedAuctionEdge={this.props.hasLinkedAuctionEdge}
          ref='mainRef'
          listing={listing}
          clientID={this.listingID}
          showValuation={true}
          valuation={valuation}
          isModal={this.props.isModal}
          isLoadingValuation={isLoadingValuation}
          updateValuationWithReload={this.updateValuationWithReload}
          isEmbedded={this.props.isEmbedded} />
      )
    }
    return (
      <div ref="mainRef" />
    )
  }
}

const AuctionsListingShow = (props) => {
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()
  return (
    <AuctionsListingShowInner
      {...props}
      hasLinkedAuctionEdge={hasLinkedAuctionEdge}
    />
  )
}

export default AuctionsListingShow
