import React from 'react'
import { Link } from 'react-router-dom'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import Formatters from '../../api/constants/Formatters'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import CalendarIcon from '../common/icons/CalendarIcon'
import pluralize from 'pluralize'
import platformFunctions from '../../platformFunctions'
import CalculatorBug from '../common/CalculatorBug'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

export default class AuctionsLanes extends React.Component {

  state = {
    user: SessionStore.user,
    lanesAreLoading: true,
    lanes: null,
    showSellers: false,
    showInfoSales: []
  }

  componentDidMount() {
    AuctionStore.on('auction_next_sales_change', this.salesDidChange)
    AuctionsActionCreators.loadNextAuctionSales()
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Browse Lanes")
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_show_lanes'])
  }


  componentWillUnmount() {
    AuctionStore.removeListener('auction_next_sales_change', this.salesDidChange)
  }

  salesDidChange = () => {
    this.setState({
      lanesAreLoading: false,
      lanes: AuctionStore.nextAuctionLanes
    })
  }

  render() {
    const { lanesAreLoading, lanes, showSellers, showInfoSales } = this.state

    if (lanesAreLoading) {
      return (
        <div className="body-content">
          <Spinner className="spinner-page" />
        </div>
      )
    }

    return (
      <div className="body-content">
        <div className="content-header">
          <h1>Browse Lanes</h1>
          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        <div className="content-context-actions">
          <a href="#toggle" onClick={(e) => { e.preventDefault(); this.setState({ showSellers: !showSellers })}}>
            <svg className="bi bi-filter-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
            </svg>
            {showSellers ? 'Hide sellers' : 'Show sellers'}</a>
        </div>

        <div>
          {
            lanes && lanes.length > 0 &&
            lanes.map((auc, index) => {
              var isEmpty = Boolean(!auc.sales || auc.sales.length === 0)
              if (!isEmpty) {
                let saleEmptyCheck = true
                for (let sale of auc.sales) {
                  if (sale.lanes && sale.lanes.length > 0) {
                    saleEmptyCheck = false
                    break
                  }
                }
                isEmpty = saleEmptyCheck
              }

              return (
                <div className="card" key={`auc-lanes-${auc.code}${index}`}>
                  <h2 className="auction-lane-list-title">{auc.name}</h2>

                  {
                    auc.sales && auc.sales.length > 0 &&
                    <div className="card-list auction-lane-list">
                    {
                      auc.sales.map((sale, index) => {
                        if (sale.lanes && sale.lanes.length > 0) {
                          return (
                            <div key={`${auc.code}-${index}`} className="auction-lane-sale">

                              <div className="auction-sale-date">
                                <CalendarIcon />
                                {Formatters.formatVerboseDateWithDay(sale.start_time)}
                                {
                                  sale.description && sale.description.length > 0 &&
                                  <a href="#info" onClick={(e) => {
                                    e.preventDefault()
                                    var newShowInfoSales = Object.assign(showInfoSales)
                                    if (newShowInfoSales.includes(sale.id)) {
                                      newShowInfoSales = newShowInfoSales.filter(item => item !== sale.id)
                                    } else {
                                      newShowInfoSales.push(sale.id)
                                    }
                                    this.setState({ showInfoSales: newShowInfoSales })
                                  }}>Info</a>
                                }
                                <Link to={`/auctions/sales/${sale.id}`}>Show all sale vehicles</Link>
                              </div>

                              {
                                sale.description?.length > 0 && showInfoSales.includes(sale.id) &&
                                sale.description.map((event, eventIndex) => {
                                  return (
                                    <div key={`sale-event-${index}${eventIndex}`} className="auction-sale-events">
                                    {
                                      event.name &&
                                      <div>{event.name}</div>
                                    }
                                    {
                                      event.description &&
                                      <div>{event.description}</div>
                                    }
                                    {
                                      event.type &&
                                      <div>{event.type}</div>
                                    }
                                    </div>
                                  )
                                })
                              }

                              {
                                sale.lanes.map((lane) => {
                                  return (
                                    <div className="card-list-row" key={`a-lane-${auc.name}${lane.lane}`}>
                                      <Link to={`/auctions/${sale.id}/lanes/${lane.lane}`} className="auction-lane-row">
                                        <div className="auction-lane-details-row">
                                          <div>Lane {lane.lane}</div>
                                          <div>{pluralize('vehicle', lane.count, true)}</div>
                                        </div>
                                        {
                                          showSellers && lane.sellers && lane.sellers.length > 0 &&
                                          <div className="lane-sellers-row secondary-text">{lane.sellers.join(', ')}</div>
                                        }
                                        {
                                          showSellers && (!lane.sellers || lane.sellers.length === 0) &&
                                          <div className="lane-sellers-row secondary-text">No seller names available</div>
                                        }
                                      </Link>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        }
                        return null
                      })
                    }
                    </div>
                  }
                  {
                    isEmpty &&
                    <div style={{marginLeft: 20, marginTop: 5, marginBottom: '20px', color: '#aaa'}}>No lanes available currently...</div>
                  }
                </div>
              )
            })
          }
          {
            Boolean(!lanes || lanes.length === 0) &&
            <div>None of your selected physical auction locations have any upcoming vehicles.</div>
          }
        </div>




      </div>
    )
  }

}
