import React from 'react'

export default class WatchlistIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 32
    const height = this.props.height || 32
    const color = this.props.color || '#1c1c1c'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 52 34" style={{...this.props.style}} className={this.props.className}>
        <path fillRule="evenodd" d="M25.831,33.676 C9.624,33.676 -0.015,17.956 -0.015,16.831 C-0.015,15.779 9.519,-0.015 25.831,-0.015 C42.411,-0.015 51.676,16.174 51.676,16.831 C51.676,17.881 42.411,33.676 25.831,33.676 ZM25.836,6.367 C20.054,6.367 15.367,11.054 15.367,16.836 C15.367,22.617 20.054,27.304 25.836,27.304 C31.617,27.304 36.304,22.617 36.304,16.836 C36.304,11.054 31.617,6.367 25.836,6.367 ZM25.839,22.767 C22.565,22.767 19.911,20.113 19.911,16.839 C19.911,13.565 22.565,10.911 25.839,10.911 C29.113,10.911 31.767,13.565 31.767,16.839 C31.767,20.113 29.113,22.767 25.839,22.767 Z"/>
      </svg>
    )
  }
}
