const clientConfig = {
  pipelineConfig: {
    url: process.env.REACT_APP_AUCTION_EDGE_PIPELINE_GRAPHQL_ENDPOINT,
  },
  biddingConfig: {
    url: process.env.REACT_APP_AUCTION_EDGE_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    region: process.env.REACT_APP_AUCTION_EDGE_AWS_APPSYNC_REGION,
    auth: {
      type: process.env.REACT_APP_AUCTION_EDGE_AWS_APPSYNC_AUTHENTICATION_TYPE,
      token: process.env.REACT_APP_AUCTION_EDGE_AWS_APPSYNC_TOKEN,
    }
  }
}

export default clientConfig
