import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationProviderCard from './ValuationProviderCard'
import AdjustmentsTable from './AdjustmentsTable'
import AddDeducts from './AddDeducts'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import { Input, Label } from 'reactstrap'
import platformFunctions from '../../platformFunctions'
import Spinner from '../common/Spinner'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import moment from 'moment'
import CommonFunctions from '../../api/constants/CommonFunctions'

const title = AppConstants.providerNames.nada

export default class ValuationNADA extends React.Component {

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
    this.state = {
      isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('nada'),
      addDeductsHidden: SessionStore.areAddDeductsHidden(props.providerKey),
      isUpdatingPreviewData: false
    }
  }

  componentDidMount() {
    SessionStore.on('user_data_changed', this.userDataChanged)
    ValuationStore.on(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.userDataChanged)
    ValuationStore.removeListener(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
  }

  userDataChanged = () => {
    this.setState({ isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('nada') })
  }

  valuationDidChange = () => {
    this.setState({
      isUpdatingPreviewData: false
    })
  }

  render() {
    const nadaData = this.props.providerData
    if (!nadaData) { return null }
    const description = nadaData.description
    const appraisal = nadaData.appraisal
    const loanNode = appraisal ? appraisal.loan : null
    const retailNode = appraisal ? appraisal.retail : null
    const auctionAddDeducts = appraisal && appraisal.auction && appraisal.auction.add_deducts ? appraisal.auction.add_deducts : null
    const error = nadaData.error
    const systemData = this.props.systemData
    const changesLocked = (systemData && systemData.changes_locked === true)
    const adItems = nadaData.add_deducts && nadaData.add_deducts.items && nadaData.add_deducts.items.length > 0 ? nadaData.add_deducts.items : null
    const adLogic = nadaData.add_deducts && nadaData.add_deducts.logic ? nadaData.add_deducts.logic : null
    const overallCondition = this.props.overallCondition
    const avgMileage = description ? nadaData.description.avg_mileage : null
    const msrp = description ? nadaData.description.msrp_price : null
    const hasBuildData = description && description.build_data === true

    const adjustmentsValuesForCondition = {loan: loanNode, retail: retailNode, tradein: appraisal && appraisal[overallCondition] ? appraisal[overallCondition]['tradein'] : null, auction: appraisal && appraisal[overallCondition] ? appraisal[overallCondition]['auction'] : null}

    var hasAdjustmentValues = false
    if ((loanNode && loanNode.adjusted && loanNode.adjusted > 0) || (retailNode && retailNode.adjusted && retailNode.adjusted.value > 0) || (adjustmentsValuesForCondition.tradein && adjustmentsValuesForCondition.tradein.adjusted && adjustmentsValuesForCondition.tradein.adjusted > 0) || (adjustmentsValuesForCondition.auction && adjustmentsValuesForCondition.auction.adjusted && adjustmentsValuesForCondition.auction > 0)) {
      hasAdjustmentValues = true
    }

    const loanAdjusted = loanNode && loanNode.adjusted && loanNode.adjusted > 0 ? loanNode.adjusted : null
    const retailAdjusted = retailNode && retailNode.adjusted && retailNode.adjusted > 0 ? retailNode.adjusted : null

    const { isShowingAdjustments, addDeductsHidden, isUpdatingPreviewData } = this.state

    var changeableDateComponent
    if (nadaData?.preview?.is_preview_period === true) {
      changeableDateComponent = isUpdatingPreviewData ? (<Spinner className='spinner-medium' style={{width: '32px', height: '32px', margin: '20px auto'}} />) :
      (<span> - <a href="#toggle"
        onClick={(e) => {
          e.preventDefault()
          platformFunctions.logAnalyticsEvent("Valuations", "Toggle JDP Preview Data")
          this.setState({
            isUpdatingPreviewData: true
          })
          var serverHash = {}
          serverHash['updated_provider_specific_options'] = {[this.props.providerKey]: {force_current_period: nadaData.preview.is_preview_data}}
          ValuationActionCreators.updateValuation(this.props.vehicleUUID, serverHash, this.props.clientID)
        }}
      >Show {moment().add(nadaData?.preview?.is_preview_data === true ? 0 : 1, 'months').format('MMMM')} values</a></span>
        
      )
    }
          


    var hasTradeInValues = false
    var tradeIn = {}
    if (appraisal) {
      if (appraisal.rough.tradein && appraisal.rough.tradein.adjusted && appraisal.rough.tradein.adjusted > 0) {
        tradeIn['rough'] = appraisal.rough.tradein.adjusted
        hasTradeInValues = true
      }
      if (appraisal.average.tradein && appraisal.average.tradein.adjusted && appraisal.average.tradein.adjusted > 0) {
        tradeIn['average'] = appraisal.average.tradein.adjusted
        hasTradeInValues = true
      }
      if (appraisal.clean.tradein && appraisal.clean.tradein.adjusted && appraisal.clean.tradein.adjusted > 0) {
        tradeIn['clean'] = appraisal.clean.tradein.adjusted
        hasTradeInValues = true
      }
    }

    var hasAuctionValues = false
    var auction = {}
    if (appraisal) {
      if (appraisal.rough.auction && appraisal.rough.auction.adjusted && appraisal.rough.auction.adjusted > 0) {
        auction['rough'] = appraisal.rough.auction.adjusted
        hasAuctionValues = true
      }
      if (appraisal.average.auction && appraisal.average.auction.adjusted && appraisal.average.auction.adjusted > 0) {
        auction['average'] = appraisal.average.auction.adjusted
        hasAuctionValues = true
      }
      if (appraisal.clean.auction && appraisal.clean.auction.adjusted && appraisal.clean.auction.adjusted > 0) {
        auction['clean'] = appraisal.clean.auction.adjusted
        hasAuctionValues = true
      }
    }

    var auctionAdjustmentArray = []
    var auctionAdjustmentString = null

    if (hasAuctionValues && auctionAddDeducts && auctionAddDeducts.length > 0) {
      for (let auctionAdjust of auctionAddDeducts) {
        let targetedAD = adItems.find(adItem => adItem.description.uid === auctionAdjust)
        if (targetedAD && targetedAD.description && targetedAD.description.name) {
          auctionAdjustmentArray.push(targetedAD.description.name)
        }
      }
      auctionAdjustmentString = `Auction values pre-adjusted by: ${auctionAdjustmentArray.join(', ')}`
    }

    return (
      <ValuationProviderCard
        providerData={nadaData}
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey={this.props.providerKey}
        systemData={systemData}
        fullChoices={this.props.fullChoices}
        currentChoiceUID={nadaData.description && nadaData.description.uid ? nadaData.description.uid : null}
        title={title}
        subtitle={error || !description || global.isExtensionMode ? null : description.display_choice}
        hasError={error ? true : false}
        showAdjustmentMessage={true}
        valuationMileage={this.props.valuationMileage}
        providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
        messages={nadaData.messages}
        onBookout={global.isExtensionMode ? null : this.props.onBookout}
        changeableDateComponent={changeableDateComponent}
      >
        {
          global.isExtensionMode ?
          <>
          <div>{title} is no longer available in the browser extension.</div>
          
          <div style={{marginTop: '10px'}}><a href={CommonFunctions.valuationURL({uuid: this.props.vehicleUUID})} target="_blank" rel="noopener noreferrer">Open this vehicle in Carbly</a> to display {title}.</div>
          </>
          :
          <>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} className="">
              <div>Average mileage <span style={{fontWeight: 'bold'}}>{Formatters.formatThousands(avgMileage)}</span></div>
              <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
                e.preventDefault()
                this.setState({ isShowingAdjustments: !isShowingAdjustments })
                SessionStore.saveProviderDisplayMode('nada', !isShowingAdjustments)
              }}>Switch to {isShowingAdjustments ? 'conditions' : 'adjustments'} view</a>
            </div>

            {
              isShowingAdjustments &&
              <div>
                <AdjustmentsTable appraisalValues={adjustmentsValuesForCondition} />
                {
                  hasAdjustmentValues &&
                  <div className="secondary-text" style={{textAlign: 'right'}}>Condition: {AppConstants.overallConditions[overallCondition].title}</div>
                }
              </div>
            }


            {
              !isShowingAdjustments &&
              <div className="valuation-subsection">
                <div className="nada-capsules">
                  {
                    loanAdjusted > 0 &&
                    <div className="nada-capsule"><div className="nada-capsule-label">Loan</div><div className="nada-capsule-value">{Formatters.formatCurrency(loanAdjusted)}</div></div>
                  }
                  {
                    retailAdjusted > 0 &&
                    <div className="nada-capsule"><div className="nada-capsule-label">Retail</div><div className="nada-capsule-value">{Formatters.formatCurrency(retailAdjusted)}</div></div>
                  }
                  {
                    msrp > 0 &&
                    <div className="nada-capsule"><div className="nada-capsule-label">MSRP</div><div className="nada-capsule-value">{Formatters.formatCurrency(description.msrp_price)}</div></div>
                  }
                </div>
              </div>
            }


            {
              !isShowingAdjustments && (hasTradeInValues || hasAuctionValues) &&
              <div className="valuation-subsection">
                <div className="nada-trade-auction-c">
                  {
                    hasAuctionValues &&
                    <div style={{marginRight: hasTradeInValues ? '20px' : 0}}>
                      <h3 className="valuation-subsection-title" style={{display: 'flex', alignItems: 'center', paddingBottom: '4px'}}><img src="/images/auction-icon.png" alt="Auction" style={{width: '25px', height: '16px', marginRight: '8px'}} />Auction Values</h3>
                      <div className="nada-trade-auction-row">
                        {auction.rough !== null && auction.rough > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'rough' ? ' highlighted' : '')}>
                            Rough: {Formatters.formatCurrency(auction.rough)}
                          </div>
                        }
                        {auction.average !== null && auction.average > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'average' ? ' highlighted' : '')}>
                            Average: {Formatters.formatCurrency(auction.average)}
                          </div>
                        }
                        {auction.clean !== null && auction.clean > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'clean' ? ' highlighted' : '')}>
                            Clean: {Formatters.formatCurrency(auction.clean)}
                          </div>
                        }
                      </div>
                    </div>
                  }

                  {
                    hasTradeInValues &&
                    <div style={{marginLeft: hasAuctionValues ? '20px' : 0}}>
                      <h3 className="valuation-subsection-title" style={{display: 'flex', alignItems: 'center', paddingBottom: '4px'}}><img src="/images/trade-in-icon.png" alt="Trade-in" style={{width: '24px', height: '13px', marginRight: '8px'}} />Trade-in Values</h3>
                      <div className="nada-trade-auction-row">
                        {tradeIn.rough !== null && tradeIn.rough > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'rough' ? ' highlighted' : '')}>
                            Rough: {Formatters.formatCurrency(tradeIn.rough)}
                          </div>
                        }
                        {tradeIn.average !== null && tradeIn.average > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'average' ? ' highlighted' : '')}>
                            Average: {Formatters.formatCurrency(tradeIn.average)}
                          </div>
                        }
                        {tradeIn.clean !== null && tradeIn.clean > 0 &&
                          <div className={'nada-trade-auction-col' + (overallCondition && overallCondition === 'clean' ? ' highlighted' : '')}>
                            Clean: {Formatters.formatCurrency(tradeIn.clean)}
                          </div>
                        }
                      </div>
                    </div>
                  }

                </div>
              </div>
            }


            {
              hasBuildData &&
              <div style={{marginTop: '40px', backgroundColor: '#f9fadd', padding: 5}}>
                <span style={{fontWeight: 'bold'}}>{title} build data applied</span>.  Options have already been added or removed to match specific build.
              </div>
            }

            {
              auctionAdjustmentString !== null &&
              <div className="secondary-text" style={{marginTop: '5px'}}>{auctionAdjustmentString}</div>
            }


            {
              adItems && !global.isPartnerPreview === true &&
              <div className="valuation-subsection">
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <h3 className="valuation-subsection-title">{this.isShared || changesLocked ? 'Vehicle Options' : 'Change Vehicle Options & Accessories'}</h3>
                  <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
                    e.preventDefault()
                    SessionStore.setHiddenAddDeducts(!addDeductsHidden, this.props.providerKey)
                    this.setState({ addDeductsHidden: !addDeductsHidden })
                  }}>{addDeductsHidden ? 'Show' : 'Hide'}</a>
                </div>
                {
                  !addDeductsHidden &&
                  <AddDeducts items={adItems} adLogic={adLogic} providerKey="nada" clientID={this.props.clientID} vehicleUUID={this.props.vehicleUUID} changesLocked={changesLocked}/>
                }
              </div>
            }

            {
              global.isPartnerPreview &&
              <div className="valuation-subsection partner-preview-access-message">
                Modifying options and accessories is only available with a registered <a href="https://getcarbly.com/register/?r=edge" target="_blank">Carbly account</a>.
              </div>
            }
          </>
        }
      </ValuationProviderCard>
    )
  }
}
