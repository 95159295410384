import React from 'react'

export default class SaveIconFilled extends React.PureComponent {
  render() {
    const width = this.props.width || 31
    const height = this.props.width || 31
    const color = this.props.color || 'currentColor'

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 16 16" style={{...this.props.style}} className={this.props.className}>
        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
      </svg>
    )
  }
}