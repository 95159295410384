import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import CalculatorIcon from './icons/CalculatorIcon'
import platformFunctions from '../../platformFunctions'
import Calculator from './Calculator'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import SessionStore from '../../api/stores/SessionStore'


export default class CalculatorBug extends React.PureComponent {
  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }

  state = {
    isShowingCalculator: false
  }

  render() {
    if (this.isShared || global.isExtensionMode || global.isIFrameMode || !SessionStore.canUseCalculator()) { return null }
    const { isShowingCalculator } = this.state

    return (
      <div>
        <div className="calculator-bug-c">
          <a href="#show-calculator"
            onClick={(e) => {
              e.preventDefault()
              this.setState({ isShowingCalculator: true })
              platformFunctions.logAnalyticsEvent("User", "Opened Global Calculator")
              UserActionCreators.createMetric(AppConstants.metricNames['calulator_global_show'])
            }} className="calculator-bug">
            <CalculatorIcon />
          </a>
          <div className='global-calculator-c' style={{display: isShowingCalculator ? 'inherit ' : 'none'}}>
            <Calculator valuation={this.props.valuation} onClose={() => this.setState({ isShowingCalculator: false })} hasAuctionListing={false} />
          </div>
        </div>

        {
          isShowingCalculator &&
          <div className='global-calculator-dismiss-overlay' onClick={() => this.setState({ isShowingCalculator: false })} />
        }
      </div>
    )
  }
}