import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { CANCEL_MAX_BID } from '../mutations'
import { GET_VENUE_LISTING } from '../queries'

export default (venueListingId, bidId) => {
  const [_cancelMaxBid, state] = useMutation(CANCEL_MAX_BID, {
    refetchQueries: [
      {
        query: GET_VENUE_LISTING,
        variables: { id: venueListingId }
      },
    ],
    awaitRefetchQueries: true,
  })

  const cancelMaxBid = useCallback(() => {
    return _cancelMaxBid({
      variables: {
        input: {
          venueListingId,
          id: bidId,
        }
      }
    })
  }, [_cancelMaxBid, venueListingId, bidId])

  return [cancelMaxBid, state]
}
