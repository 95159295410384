import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import SessionStore from '../../api/stores/SessionStore'
import ValuationProviderCard from './ValuationProviderCard'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'

const title = AppConstants.providerNames.manheim
const xPositionLabels = {
  last_year: '1 year ago',
  last_six_months: '6 months ago',
  last_two_months: '2 months ago',
  next_month: '1 month from now',
  next_year: '1 year from now'
}

export default class ValuationManheim extends React.Component {

  state = {
    selectedRegionFilter: SessionStore.mhCompsRegion
  }

  componentDidMount() {
    SessionStore.on("mh_comps_region_changed", this.regionDidChange)
  }

  componentWillUnmount() {
    SessionStore.removeListener("mh_comps_region_changed", this.regionDidChange)
  }

  regionDidChange = () => {
    this.setState({
      selectedRegionFilter: SessionStore.mhCompsRegion
    })
  }


  render() {
    const manheimData = this.props.providerData
    if (!manheimData) { return null }
    const appraisal = manheimData.appraisal
    const error = manheimData.error
    const systemData = this.props.systemData

    const description = manheimData.description
    const overallCondition = this.props.overallCondition
    const conditionGrade = this.props.conditionGrade
    const dataTableRowLabels = [
      AppConstants.overallConditions['rough'].title,
      AppConstants.overallConditions['average'].title,
      AppConstants.overallConditions['clean'].title

    ]

    const wholesale = appraisal ? [appraisal.below.wholesale.adjusted, appraisal.average.wholesale.adjusted, appraisal.above.wholesale.adjusted] : null
    const retail = appraisal ? [appraisal.below.retail.adjusted, appraisal.average.retail.adjusted, appraisal.above.retail.adjusted] : null


    var mhTableBelow = appraisal ? [appraisal.below.wholesale.adjusted, appraisal.below.retail.adjusted, appraisal.below.retail.adjusted - appraisal.below.wholesale.adjusted] : null
    var mhTableAverage = appraisal ? [appraisal.average.wholesale.adjusted, appraisal.average.retail.adjusted, appraisal.average.retail.adjusted - appraisal.average.wholesale.adjusted] : null
    var mhTableAbove = appraisal ? [appraisal.above.wholesale.adjusted, appraisal.above.retail.adjusted, appraisal.above.retail.adjusted - appraisal.above.wholesale.adjusted] : null


    const transCount = description ? description.transaction_count : null
    const avgMileage = description ? description.avg_mileage : null
    var footerStrings = []
    if (transCount > 0) {
      footerStrings.push(`Number of auctions: ${transCount}`)
    }
    if (avgMileage > 0) {
      footerStrings.push(`Average mileage: ${Formatters.formatThousands(avgMileage)}`)
    }

    var formattedTrendData = []
    var wholesaleData = []
    var retailData = []
    const timeline = manheimData.timeline
    var lowestVal = 999999
    if (timeline) {
      for (let key of Object.keys(timeline)) {
        let pointGroup = timeline[key]
        if (pointGroup.wholesale) {
          if (pointGroup.wholesale < lowestVal) { lowestVal = pointGroup.wholesale}
          wholesaleData.push({
            x: xPositionLabels[key],
            y: pointGroup.wholesale
          })
        }
        if (pointGroup.retail) {
          if (pointGroup.retail < lowestVal) { lowestVal = pointGroup.retail}
          retailData.push({
            x: xPositionLabels[key],
            y: pointGroup.retail
          })
        }
      }
    }

    if (wholesaleData.length > 0) {
      formattedTrendData.push({
        "id": "Wholesale",
        "color": "#505d62",
        "data": wholesaleData
      })
    }

    if (retailData.length > 0) {
      formattedTrendData.push({
        "id": "Retail",
        "color": "#02b1fc",
        "data": retailData
      })
    }


    return (
      <ValuationProviderCard
          providerData={manheimData}
          vehicleUUID={this.props.vehicleUUID}
          clientID={this.props.clientID}
          providerKey={this.props.providerKey}
          systemData={systemData}
          fullChoices={this.props.fullChoices}
          currentChoiceUID={manheimData.description && manheimData.description.uid ? manheimData.description.uid : null}
          title={title}
          subtitle={error || !description ? null : description.display_choice}
          hasError={error ? true : false}
          showAdjustmentMessage={true}
          messages={manheimData.messages}
          valuationMileage={this.props.valuationMileage}
          providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
          >
          <div>
            {
              manheimData?.extended_coverage === true &&
              <div style={{fontSize: '0.9rem', fontWeight: 'bold'}}>Small sample size for this vehicle</div>
            }
            {
              conditionGrade &&
              <div style={{fontSize: '0.9rem', fontWeight: 'bold'}}>Condition of {conditionGrade} used from auction listing</div>
            }

            <div className="manheim-c">
            {
              wholesale && wholesale.length > 0 && retail && retail.length > 0 &&

              <div className="valuation-subsection prices-table">
                <div className="prices-table-row">
                  <div className="prices-table-cell header-cell">&nbsp;</div>
                  <div className="prices-table-cell header-cell">Wholesale</div>
                  <div className="prices-table-cell header-cell">Retail</div>
                  <div className="prices-table-cell header-cell">Spread</div>
                </div>

                {
                  [mhTableBelow, mhTableAverage, mhTableAbove].map((row, index) => {
                    return (
                      <div className={`prices-table-row${(index === 0 && overallCondition === 'rough') || (index === 1 && overallCondition === 'average') || (index === 2 && overallCondition === 'clean') ? ' prices-table-row-highlighted' : ''}`} key={`manheim-row-${index}`}>
                        <div className="prices-table-cell row-label">
                          <div>{dataTableRowLabels[index]}</div>
                        </div>
                        {
                          row.map((col, index) => {
                            return (
                              <div className="prices-table-cell" key={`manheim-col-${index}`}>{!isNaN(col) ? Formatters.formatCurrency(col) : ''}</div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }

              </div>
            }

            {
              formattedTrendData && formattedTrendData.length > 0 &&
              <div className="valuation-subsection">
                <h3 className="valuation-subsection-title">Wholesale and Retail Price Trends</h3>
                <div className="trend-graph">

                  <ResponsiveLine
                    data={formattedTrendData}
                    margin={{ top: 30, right: 40, bottom: 26, left: 40 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: false, min: lowestVal - (lowestVal * 0.5), max: 'auto' }}
                    yFormat={(node) => {
                      return Formatters.formatCurrency(node)
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 2,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: null,
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={null}
                    enableGridY={false}
                    colors={(node => {
                      return node.color;
                    })}
                    lineWidth={5}
                    pointSize={12}

                    pointBorderWidth={3}
                    pointBorderColor={{ from: 'serieColor' }}
                    enablePointLabel={true}
                    pointLabel="yFormatted"
                    pointLabelYOffset={-12}
                    areaBaselineValue={130}
                    useMesh={true}
                    tooltip={(el) => {
                      return (
                        <div className="graph-tooltip">
                          <div>{el.point.serieId}</div>
                          <div>{el.point.data.xFormatted}</div>
                          <div>{el.point.data.yFormatted}</div>
                        </div>
                      )
                    }}

                  />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px'}}>
                  <div style={{fontSize: '0.8rem', marginRight: '20px'}}>
                    <span style={{display: 'inline-block', backgroundColor: '#505d62', width: '10px', height: '10px', borderRadius: '5px'}} /> Wholesale
                  </div>

                  <div style={{fontSize: '0.8rem'}}>
                    <span style={{display: 'inline-block', backgroundColor: '#02b1fc', width: '10px', height: '10px', borderRadius: '5px'}} /> Retail
                  </div>
                </div>

                <div className="secondary-text trend-graph-info">Price trends are based on vehicles with a variety of mileages, which may not align with the mileage specified.</div>
                <div className="provider-footer-info">{footerStrings.join(', ')}</div>
              </div>
            }

            {
              Boolean(manheimData.source_url) &&
              <a href={manheimData.source_url} target="_blank" rel="noopener noreferrer">View vehicle on Manheim.com <ExternalLinkIcon /></a>
            }


          </div>
        </div>

      </ValuationProviderCard>
    )
  }
}
