import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

export default {

  loadYears: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AVAILABLE_YEARS, WebAPIUtils.loadAvailableYears() )
  },

  loadMakesModels: function(year) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AVAILABLE_MAKES_MODELS, WebAPIUtils.loadAvailableMakesModels(year) )
  }
}
