import React from 'react'
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Tooltip} from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import CommonFunctions from '../../api/constants/CommonFunctions'
import SessionStore from '../../api/stores/SessionStore'
import ValuationStore from '../../api/stores/ValuationStore'

export default class LLMSamplesMap extends React.Component {
  state = {
    hasDoneInitialZoom: false
  }

  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.initialZoom()
    }, 2000);
  }

  initialZoom = () => {
    if (!this.mapRef.current || !this.props.samples || this.state.hasDoneInitialZoom) {
      return
    }

    if (global.isExtensionMode) {
      this.mapRef.current.scrollWheelZoom.disable()
    }
    
    this.zoomToFitMarkers()
    this.setState({ hasDoneInitialZoom: true })
  }

  zoomToFitMarkers = () => {
    const map = this.mapRef.current
    const samples = this.props.samples

    if (!samples || samples.length === 0) { return }

    var sampleCoordinates = []
    for (let sample of samples) {
      if (sample.coordinates?.latitude && sample.coordinates?.longitude) {
        sampleCoordinates.push(
          { latitude: sample.coordinates.latitude, longitude: sample.coordinates.longitude}
        )
      }
    }

    var paddingOptions = {padding: [12,12], maxZoom: 9}
    if (sampleCoordinates && sampleCoordinates.length > 0) {
      const displayBounds = CommonFunctions.boundingBoxForCoordinates(sampleCoordinates)
      map.fitBounds(displayBounds, paddingOptions)
    }
  }

  render() {
    const { samples, defaultSelectedId } = this.props
    const rooftop = SessionStore.user?.rooftop

    var markers = null
    if (samples && samples.length > 0) {
      markers = samples.map((sample, index) => {
        if (sample.coordinates?.latitude && sample.coordinates?.longitude) {
          return (
            <Marker
              position={[sample.coordinates.latitude, sample.coordinates.longitude]}
              icon={ sample.id && sample.id === defaultSelectedId ? selectedLocationMarker : locationMarker }
              key={`samp-map-${index}`}
              eventHandlers={{
                click: () => {
                  if (!ValuationStore.isSharedValuation && this.props.onClick) {
                    this.props.onClick(sample.id)
                  }
                }
              }}>
                <Tooltip>{sample.dealer}</Tooltip>
            </Marker>
          )
        }

      })
    }

    if (rooftop?.longitude && rooftop?.latitude) {
      if (!markers) {
        markers = []
      }
      markers.push(
        <Marker
          position={[rooftop.latitude, rooftop.longitude]}
          icon={ roofoptLocationMarker }
          key='roof-mrkr'
          >
        </Marker>
      )
    }

    return (
      <MapContainer ref={this.mapRef} zoom={8} zoomSnap={0.25} center={[43.3, -88.6]} scrollWheelZoom={true} style={{width: '100%', height: '100%', borderRadius: '10px', zIndex: 100}} attributionControl={false}>
        <TileLayer
        attribution=''
        url={`https://tileserver.getcarbly.com/osm/{z}/{x}/{y}.png`}
      />
      
        { markers }
        {
          this.props.showEnlarge && this.props.onEnlarge &&
          <Control position='topleft'>
            <a href="#zoom" onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.props.onEnlarge()
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{marginTop: '-2px'}}>
                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
              </svg>
            </a>
          </Control>
        }
      </MapContainer>
    )
  }

}


const locationMarker = new L.Icon({
  iconUrl: '/images/map-vehicle-marker.svg',
  iconSize: [14,14],
  iconAnchor: [7, 7],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
})

const selectedLocationMarker = new L.Icon({
  iconUrl: '/images/map-vehicle-marker-selected.svg',
  iconSize: [14,14],
  iconAnchor: [7, 7],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
})

const roofoptLocationMarker = new L.Icon({
  iconUrl: '/images/map-rooftop-marker.svg',
  iconSize: [24,24],
  iconAnchor: [12, 12],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
})
