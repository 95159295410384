import React from 'react'
import { Route, NavLink, Switch } from 'react-router-dom'
import AuctionStore from '../api/stores/AuctionStore'
import AuctionsActionCreators from '../api/actions/AuctionsActionCreators'
import AuctionsHome from '../components/auctions/AuctionsHome'
import AuctionsLanes from '../components/auctions/AuctionsLanes'
import AuctionsSearch from '../components/auctions/AuctionsSearch'
import AuctionLocationShow from '../components/auctions/AuctionLocationShow'
import AuctionLaneShow from '../components/auctions/AuctionLaneShow'
import AuctionSaleShow from '../components/auctions/AuctionSaleShow'
import AuctionsSettings from '../components/auctions/AuctionsSettings'
import AuctionsWatchlist from '../components/auctions/AuctionsWatchlist'
import AuctionsWatchlistArchive from '../components/auctions/AuctionsWatchlistArchive'
import AuctionsListingShow from '../components/auctions/AuctionsListingShow'
import AuctionsGlobalFiltersControl from '../components/auctions/AuctionsGlobalFiltersControl'
import HomeIcon from '../components/common/icons/HomeIcon'
import GearIcon from '../components/common/icons/GearIcon'
import WatchlistIcon from '../components/common/icons/WatchlistIcon'
import LanesIcon from '../components/common/icons/LanesIcon'
import MagnifyingGlassIcon from '../components/common/icons/MagnifyingGlassIcon'
import Spinner from '../components/common/Spinner'
import PlusIcon from '../components/common/icons/PlusIcon'
import AuctionsEdgeSales from '../components/auctions/AuctionsEdgeSales'
import platformFunctions from '../platformFunctions'
import AuctionsMyBids from '../components/auctions/AuctionsMyBids'
import AppConstants from '../api/constants/AppConstants'
import SessionStore from '../api/stores/SessionStore'
import AuctionsPlusUpsellModal from '../components/auctions/AuctionsPlusUpsellModal'
import PlusPlanIcon from '../components/common/icons/PlusPlanIcon'
import UserActionCreators from '../api/actions/UserActionCreators'

export default class AuctionsPage extends React.Component {
  state = {
    watchlistCount: 0,
    auctionsRooftopWatchlistCount: 0,
    isLoadingSavedSearches: true,
    savedSearches: null,
    auctionLiveSalesStatus: null
  }

  componentDidMount() {
    AuctionStore.on('auctions_watchlist_count_change', this.watchlistCountDidChange)
    AuctionStore.on('saved_searches_received', this.savedSearchesDidChange)
    AuctionStore.on('saved_search_change', this.savedSearchWasSaved)
    AuctionStore.on('auction_live_sales_status_change', this.auctionLiveSalesStatusDidChange)

    this.periodicAuctionLiveSalesStatusRefresh()

    AuctionsActionCreators.loadSavedSearches()
    platformFunctions.logAnalyticsEvent("Auctions", "Opened Tab")
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_view_home'])

    AuctionsActionCreators.loadAuctionsWatchlistVehicleCounts()
  }

  componentWillUnmount() {
    AuctionStore.removeListener('auctions_watchlist_count_change', this.watchlistCountDidChange)
    AuctionStore.removeListener('saved_searches_received', this.savedSearchesDidChange)
    AuctionStore.removeListener('saved_search_change', this.savedSearchWasSaved)
    AuctionStore.removeListener('auction_live_sales_status_change', this.auctionLiveSalesStatusDidChange)
  }

  watchlistCountDidChange = () => {
    const auctionsWatchlistCounts = AuctionStore.auctionsWatchlistCounts

    this.setState({
      watchlistCount: auctionsWatchlistCounts?.watchlist_count || 0,
      auctionsRooftopWatchlistCount: auctionsWatchlistCounts?.rooftop_watchlist_count || 0
    })
  }

  savedSearchesDidChange = () => {
    this.setState({
      savedSearches: AuctionStore.savedSearches,
      isLoadingSavedSearches: false
    })
  }

  savedSearchWasSaved = () => {
    AuctionsActionCreators.loadSavedSearches()
  }

  auctionLiveSalesStatusDidChange = () => {
    this.setState({
      auctionLiveSalesStatus: AuctionStore.auctionLiveSalesStatus
    })
  }

  periodicAuctionLiveSalesStatusRefresh = () => {
    // if (process.env.REACT_APP_ENV_NAME === 'development') { return }
    AuctionsActionCreators.loadAuctionLiveSalesStatus()

    setTimeout(() => {
      this.periodicAuctionLiveSalesStatusRefresh()
    }, 1000 * 60 * 60);
  }

  render() {
    const { watchlistCount, auctionsRooftopWatchlistCount, auctionLiveSalesStatus, isLoadingSavedSearches, savedSearches } = this.state
    const { accountStatusRestricted, accountStateMessage } = this.props

    const dotAuctionSalesStatus = auctionLiveSalesStatus?.find((sale) => sale.auction_code === 'EDGE_DOT_AUCTION')
    const dotAuctionSalesCount = dotAuctionSalesStatus?.active_listings_count

    const hasAuctionsPlus = SessionStore.hasAuctionsPlus()
    const hasPurchased = SessionStore.hasPurchasedSKU(AppConstants.skuMappings.auctions_plus)
    const isTrialingAuctionsPlus = hasAuctionsPlus && !hasPurchased


    return (
      <div style={{height: '100%'}}>
        {
          accountStateMessage &&
          <div className="body-with-nav account-warnings"><div className="icon">!</div><div className="message">{accountStateMessage}</div></div>
        }
        <nav className="secondary-nav">
          <div className="secondary-nav-links">
            <section>
              <NavLink to="/auctions" exact activeClassName="active">
                <HomeIcon className="icon" color="currentColor" />
                Home
              </NavLink>
              <NavLink to="/auctions/settings" exact activeClassName="active">
                <GearIcon className="icon" color="currentColor" />
                Manage Auctions
              </NavLink>
            </section>

            <section>
              <div className="split">
                <NavLink to="/auctions/watchlist" exact activeClassName="active">
                  <div><WatchlistIcon className="icon" color="currentColor" />
                  Watchlist</div>
                  <div>{watchlistCount !== null && watchlistCount > 0 ? watchlistCount : '' }{auctionsRooftopWatchlistCount !== null && auctionsRooftopWatchlistCount > 0 ? ` ${watchlistCount > 0 ? '/' : ''} ${auctionsRooftopWatchlistCount}` : ''}</div>
                </NavLink>
              </div>
            </section>

            <section>
              <NavLink to="/auctions/search?new=true" exact activeClassName="active" style={{alignItems: 'center'}}>
                <MagnifyingGlassIcon className="icon" color="currentColor" />
                New Search
              </NavLink>
              <NavLink to="/auctions/lanes" exact activeClassName="active">
                <LanesIcon className="icon" color="currentColor" />
                Browse Lanes
              </NavLink>
            </section>

            <section>
              <div className="split global-filters-row">
                <AuctionsGlobalFiltersControl />
              </div>
            </section>

            {
              dotAuctionSalesStatus?.visible &&
              <section>
                <div>

                  <h3 style={{display: 'flex', flexDirection: 'row', alignItems: 'center', textTransform: 'none'}}>
                    <img src="/images/edge-cube-logo-small.png" alt="" style={{width: '18px', height: '18px', marginRight: '5px', opacity: 0.2}} />
                    {AppConstants.auctionOnlineSourceNames['EDGE_DOT_AUCTION']}
                  </h3>
                </div>

                <div className='split'>
                  <NavLink to="/auctions/edge-dot-auction-sales" exact activeClassName="active">
                    <div>Active Sales</div>
                    <div>{dotAuctionSalesCount}</div>
                  </NavLink>
                </div>

                <NavLink to="/auctions/my-bids" exact activeClassName="active">My Bids</NavLink>
              </section>
            }

            {
              isLoadingSavedSearches &&
              <div style={{marginTop: '40px'}}>
                <Spinner className='spinner-secondary-nav' />
              </div>
            }

            {
              !isLoadingSavedSearches &&
              <section style={{marginTop: '40px'}} className='auctions-searches-section'>
                <div className="section-label">Saved Searches</div>
                {
                  savedSearches?.user?.length > 0 &&
                  savedSearches.user.map((search, index) => (
                    <div className="split saved-search-item" key={`ss-u-${index}`}>
                      {
                        <NavLink key={`ss-${search.uuid}`} to={`/auctions/search/${search.uuid}`} exact activeClassName="active" disabled>
                          {search.name}
                          {
                            // index === 1 &&
                            // <div className='new-count-indicator'>12 new</div>
                          }
                        </NavLink>
                      }

                    </div>
                  ))
                }
                <NavLink to="/auctions/search?new=true" exact activeClassName="NONE" style={{marginTop: '20px'}}><PlusIcon style={{marginRight: '5px'}} /> New search</NavLink>
              </section>
            }

            {
              Boolean(!hasAuctionsPlus || isTrialingAuctionsPlus) &&
              <section>
                <a href="#upgrade" className='upsell-feature' onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    isShowingAuctionsPlusUpsellModal: true,
                    auctionsUpsellSource: 'auctions-page-feature',
                    auctionsUpsellSubtitle: null,
                  })
                }}>Upgrade to Auctions Plus
                <PlusPlanIcon color='currentColor' />
                </a>
              </section>
            }

            {
              this.state.isShowingAuctionsPlusUpsellModal &&
              <AuctionsPlusUpsellModal
                 onClose={() => {
                  this.setState({ isShowingAuctionsPlusUpsellModal: false })
                }}
                onDone={() => {
                  this.setState({ isShowingAuctionsPlusUpsellModal: false })
                }}
                metricsValue={this.state.auctionsUpsellSource}
                subTitle={this.state.auctionsUpsellSubtitle}
                 />
            }

            {
              !isLoadingSavedSearches && savedSearches?.team?.length > 0 &&
              <section className='auctions-searches-section' style={{marginTop: '40px', marginBottom: '120px'}}>
                <div className="section-label">Team Saved Searches</div>
                {
                  savedSearches.team.map((search, index) => {
                    return (
                      <div className="split saved-search-item team-saved-search-item" key={`ss-t-${index}`}>
                        <NavLink to={`/auctions/search/${search.uuid}`} exact activeClassName="active" style={{display: 'block'}}>
                          {search.name}
                          {
                          Boolean(search.user_full_name) &&
                          <div className='saved-search-owner'>{search.user_full_name}</div>
                        }
                        </NavLink>
                        
                      </div>
                      )
                  })
                }
              </section>
            }
          </div>

        </nav>

        {
          // Don't proceed until saved searches are loaded
          !isLoadingSavedSearches &&
          <div className="page-body-c body-with-nav">
            <Switch>
              <Route
                exact
                path='/auctions'
                children={({ match }) => (
                  <AuctionsHome navMatch={match} savedSearches={savedSearches} accountStatusRestricted={accountStatusRestricted} />
                )} />

              <Route
                exact
                path='/auctions/lanes'
                children={({ match }) => (
                  <AuctionsLanes navMatch={match} />
                )} />

              <Route
                exact
                path='/auctions/locations/:auctionID'
                children={({ match }) => (
                  <AuctionLocationShow navMatch={match} />
                )} />

              <Route
                exact
                path='/auctions/search/:searchUUID'
                children={({ match }) => (
                  <AuctionsSearch navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/search'
                children={({ match }) => (
                  <AuctionsSearch navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/sales/:saleID'
                children={({ match }) => (
                  <AuctionSaleShow navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/:saleID/lanes/:laneNumber'
                children={({ match }) => (
                  <AuctionLaneShow navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/watchlist'
                children={({ match }) => (
                  <AuctionsWatchlist navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/watchlist/archive'
                children={({ match }) => (
                  <AuctionsWatchlistArchive navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/listing/:id'
                children={({ match }) => (
                  <AuctionsListingShow navMatch={match} history={this.props.history} />
                )} />

              <Route
                exact
                path='/auctions/settings'
                children={({ match }) => (
                  <AuctionsSettings navMatch={match} />
                )} />

              <Route
                exact
                path='/auctions/edge-dot-auction-sales'
                children={({ match }) => (
                  <AuctionsEdgeSales navMatch={match} />
                )} />

              <Route
                exact
                path='/auctions/my-bids'
                children={({ match }) => (
                  <AuctionsMyBids navMatch={match} />
                )} />
            </Switch>
          </div>
        }
      </div>
    )
  }
}
