import React from 'react'
import Slider from 'rc-slider'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'

export default class AuctionsSearchRangeSlider extends React.Component {
  state = {
    valMin: this.props.defaultMin,
    valMax: this.props.defaultMax
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultMin !== this.props.defaultMin || prevProps.defaultMax !== this.props.defaultMax) {
      this.setState({
        valMin: this.props.defaultMin,
        valMax: this.props.defaultMax
      })
    }
  }

  render() {
    const { valMin, valMax } = this.state
    return (
      <div>
        <div className='secondary-text'>{this.props.onChangeFormatter(valMin, valMax)}</div> 
        <div className="slider">
          <Slider
            range
            value={[valMin, valMax]}
            onChange={(val) => {
              this.setState({valMin: val[0], valMax: val[1]})
            }}
            {...this.props}
            {...platformFunctions.sliderStyleProps()}
            />
        </div>
      </div>
    )
    
  }
}