import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import Spinner from '../../components/common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'
import pluralize from 'pluralize'
import Formatters from '../../api/constants/Formatters'
import ConnectivityStore from '../../api/stores/ConnectivityStore'

const componentProps = (data => {
  return {
    yearMakeModel: `${data.year} ${data.make} ${data.model}`,
    trim: data.trim,
    dealerName: data.dealer_name,
    price: Formatters.formatCurrency(data.price),
    mileage: data.mileage ? `${Formatters.formatThousands(data.mileage)} ${pluralize('mile', data.mileage)}` : null,
    daysOnLot: `${Formatters.formatThousands(data.days_on_lot)} ${pluralize('day', data.days_on_lot)}`,
    numPriceChanges: pluralize('price change', data.num_price_changes, true),
    photoUrl: data.primary_photo_url ? `${ConnectivityStore.apolloApiRoot}${data.primary_photo_url}` : null,
  }
})

const useSortListings = (data, sort) => {
  const sortedData = useMemo(() => {
    if (!data) return data

    switch (sort) {
      case 'mileage':
      case '-mileage':
      case 'price':
      case '-price':
      case 'days_on_lot':
      case '-days_on_lot':
      case 'num_price_changes':
      case '-num_price_changes':
      case 'year':
      case '-year':
        const split = sort.split(/^-/)
        const attr = split.pop()
        const isAscending = !split.length

        const sortFn = isAscending ?
        (a, b) => (
          // nulls first
          (b[attr] == null) - (a[attr] == null) ||
          a[attr] - b[attr] ||
          (a.id > b.id ? 1 : -1)
        ) :

        (a, b) => (
          // nulls last
          (a[attr] == null) - (b[attr] == null) ||
          b[attr] - a[attr] ||
          (a.id > b.id ? -1 : 1)
        )

        return [...data].sort(sortFn)

      default:
        throw new Error(`Invalid sort option: ${sort}`)
    }
  }, [data, sort])

  return sortedData
}

const makeListingsSortOptions = params => {
  return [
    {
      label: '',
      options: [
        { value: 'mileage', label: 'Mileage (low to high)' },
        { value: '-mileage', label: 'Mileage (high to low)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'price', label: 'Price (low to high)' },
        { value: '-price', label: 'Price (high to low)' },
      ],
    },
    params.year == null && {
      label: '',
      options: [
        { value: 'year', label: 'Year (old to new)' },
        { value: '-year', label: 'Year (new to old)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'days_on_lot', label: 'Days on lot (low to high)' },
        { value: '-days_on_lot', label: 'Days on lot (high to low)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'num_price_changes', label: 'Price changes (low to high)' },
        { value: '-num_price_changes', label: 'Price changes (high to low)' },
      ],
    },
  ].filter(Boolean)
}

const reactSelectGroupStyles = {
  option: (provided) => ({
    ...provided,
    padding: '5px 10px'
  }),
  group: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

const ListingImage = ({ src, style }) => {
  const hasErrorRef = useRef(false)
  const fallbackImage = '/images/missing-photo-placeholder.png'

  const onError = useCallback((e) => {
    e.target.src = hasErrorRef.current ? '' : fallbackImage
    hasErrorRef.current = true
  }, [])

  return (
    <img
      src={src || fallbackImage}
      loading='lazy'
      onError={onError}
      style={{ ...style, objectFit: 'cover', objectPosition: 'center' }}
      alt='Listing'
    />
  )
}

const ListingsModal = ({ regionalListingsParams, onClose }) => {
  const listingsSortOptions = useMemo(() => {
    return makeListingsSortOptions(regionalListingsParams )
  }, [regionalListingsParams])

  const [selectedSortOption, setSelectedSortOption] = useState(listingsSortOptions[0].options[0])
  const sort = selectedSortOption.value

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({ ...regionalListingsParams })
  }, [regionalListingsParams])

  const [
    {
      isLoading,
      data,
    },
    setState,
  ] = useState({
    isLoading: ValuationStore.getRegionalListingsIsLoadingWithClientID(clientID),
    data: ValuationStore.getRegionalListingsWithClientID(clientID),
  })

  const sortedData = useSortListings(data, sort)

  const onDataChanged = useCallback(() => {
    setState(prev => ({ ...prev, data: ValuationStore.getRegionalListingsWithClientID(clientID) }))
  }, [clientID])

  const onIsLoadingChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: ValuationStore.getRegionalListingsIsLoadingWithClientID(clientID) }))
  }, [clientID])

  useEffect(() => {
    const loadingEv = `regional_listings_is_loading_change_${clientID}`
    const dataEv = `regional_listings_change_${clientID}`

    ValuationStore.on(dataEv, onDataChanged)
    ValuationStore.on(loadingEv, onIsLoadingChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onDataChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingChanged)
    }
  }, [onDataChanged, onIsLoadingChanged, clientID])

  useEffect(() => {
    if (!data) {
      const { year, make, model, trim, zip, radius } = regionalListingsParams
      ValuationActionCreators.loadRegionalListings(year, make, model, trim, zip, radius, clientID)
    }
  }, [regionalListingsParams, clientID, data])

  // FIXME: handle no data, errors

  return (
    <Modal isOpen={true} zIndex={10000} backdrop={true} toggle={onClose} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '840px'}}>
      <ModalHeader className='modal-header split-modal-header'>
        Nearby Retail Listings
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="split-row split-row-left" style={{marginBottom: '10px'}}>
            <div style={{marginRight: '10px'}}>Sort by</div>
            <div style={{ width: 280 }}>
              <Select
                styles={reactSelectGroupStyles}
                name='listings-sort'
                className='react-select'
                value={selectedSortOption}
                onChange={setSelectedSortOption}
                options={listingsSortOptions}
                isSearchable={false}
              />
            </div>
          </div>
          {
            isLoading ?
            <Spinner /> :

            <div style={{overflow: 'scroll', maxHeight: '630px'}}>
              {
                !sortedData || !sortedData.length ?
                <div>No listings found</div> :

                sortedData.map(item => {
                  const { photoUrl, yearMakeModel, trim, dealerName, price, mileage, daysOnLot, numPriceChanges } = componentProps(item)
                  return (
                    <div className="split-row" style={{borderBottom: '1px solid #eee'}} key={item.id}>
                      <ListingImage
                        src={photoUrl}
                        style={{width: '100px', height: '75px', marginRight: '10px'}}
                      />
                      <div style={{flexGrow: 1, flexShrink: 1}}>
                        <div className="list-emphasis">{yearMakeModel}</div>
                        <div className="secondary-text">{trim}</div>
                        <div className="secondary-text">{dealerName}</div>
                      </div>

                      <div style={{flexGrow: 0, flexShrink: 0, width: '80px'}}>
                        {price}
                      </div>

                      <div style={{flexGrow: 0, flexShrink: 0, width: '120px'}}>
                        {mileage}
                      </div>

                      <div style={{flexGrow: 0, flexShrink: 0, width: '80px'}}>
                        {daysOnLot}
                      </div>

                      <div style={{flexGrow: 0, flexShrink: 0, width: '140px'}}>
                        {numPriceChanges}
                      </div>

                    </div>
                  )
                  })
              }
            </div>
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ListingsModal
