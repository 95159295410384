import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import OfferingUpsellModal from '../common/OfferingUpsellModal'
import AppConstants from '../../api/constants/AppConstants'
import PlusIcon from '../common/icons/PlusIcon'


export default class BooksUpsellList extends React.Component {
  state = {
    isLoadingAvailableOfferings: true,
    availableOfferings: null,
    isShowingUpsellModal: false,
    selectedOffering: null
  }

  componentDidMount() {
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)
  
    if (SessionStore.isAccountOwner()) {
      UserActionCreators.loadAvailableOfferings()
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  render() {
    if (!SessionStore.isAccountOwner()) { return null }

    const { isLoadingAvailableOfferings, availableOfferings, isShowingUpsellModal, selectedOffering } = this.state

    if (isLoadingAvailableOfferings || !availableOfferings || availableOfferings.length === 0) {
      return null
    }

    return (
      <div>
        {
          availableOfferings.map((offering) => {
            return (
              <a href="#add"
                key={`add-off-${offering.sku}`}
                onClick={(e) => {e.preventDefault(); this.setState({
                  isShowingUpsellModal: true,
                  selectedOffering: offering
                 })}} className="upsell-c">
                <PlusIcon width={40} height={40} />
                <h2 style={{ marginTop: '4px' }}>Add {offering.name} to your plan</h2>
              </a>
            )
          })
        }

        {
          isShowingUpsellModal &&
          <OfferingUpsellModal 
            offering={selectedOffering}
            onDone={() => {this.setState({ isShowingUpsellModal: false })}}
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              if (this.props.onReloadValuation) {
                this.props.onReloadValuation()
              } else {
                window.location.reload()
              }
            }}
            providerKey='pmr'
            sku={AppConstants.skuMappings['pmr']} />
        }
      </div>
    )
  }
}

