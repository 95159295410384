import React from 'react'
import { Button } from 'reactstrap'
import Spinner from '../components/common/Spinner'
import AppConstants from '../api/constants/AppConstants'
import platformFunctions from '../platformFunctions'
import CommonFunctions from '../api/constants/CommonFunctions'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import SessionStore from '../api/stores/SessionStore'

export default class ApolloPromotionalLanderStatic extends React.Component {
  state = {
    isVerifyingUser: true,
    isSubmittingUser: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  }

  componentDidMount() {
    SessionStore.on('apollo_user_verified', this.userVerified)
    SessionActionCreators.verifyApolloCampaignContactID(this.props.apolloPromotionalID)
  }

  userVerified = () => {
    if (SessionStore.verifiedApolloUserEmail) {
      this.setState({
        email: SessionStore.verifiedApolloUserEmail,
        isVerifyingUser: false
      })
    }
  }

  onSubmit = () => {
    if (!this.isSubmitEnabled()) {
      return false
    }

    const { firstName, lastName, phoneNumber, password } = this.state
    let serverParams = {}
    if (firstName?.length > 0) {
      serverParams.first_name = firstName
    }
    if (lastName?.length > 0) {
      serverParams.last_name = lastName
    }
    if (phoneNumber?.length > 0) {
      serverParams.phone_number = phoneNumber
    }
    if (password?.length > 0) {
      serverParams.password = password
    }
    SessionActionCreators.loginWithApolloCampaignContactID(this.props.apolloPromotionalID, serverParams)
    this.setState({ isSubmittingUser: true })
  }

  isSubmitEnabled = () => {
    let isSubmitEnabled = false
    const { firstName, lastName, phoneNumber, password } = this.state

    if (firstName.length > 0 && lastName.length > 0 && phoneNumber.length === 10  && password.length >= 8) {
      isSubmitEnabled = true
    }
    return isSubmitEnabled
  }

  render() {
    const { isVerifyingUser, isSubmittingUser, firstName, lastName, phoneNumber, email, password } = this.state
    const todayDate = new Date()
    const isSubmitEnabled = this.isSubmitEnabled() && !isSubmittingUser

    if (isVerifyingUser) {
      return (
        <div className="apollo-promotional-lander-static">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="partner-lander-c">
        <div className="partner-header-bg" />
        <div className="partner-lander">
          <div className="partner-lander-valuation">
            <div>
              <img src="/images/auctions-plus-static-screenshot.png" style={{width: '100%', height: 'auto'}} alt="Carbly Auctions Plus" />
            </div>
          </div>


          <div className="partner-lander-overview">
            <div className="intro">
              <div className="header-logos">
                <img src="/images/login-logo.png" className="carbly-logo" alt="Carbly" />
              </div>

              <p><a href="https://getcarbly.com/?r=edge" target="_blank">Carbly</a> is a modern vehicle sourcing and appraisal tool, providing the market data, vehicle history and auction search tools you need to make more money buying and selling cars.</p>
            </div>

            <div className="partner-body">

              <div>
                <p>Sign up for a FREE trial and test drive Carbly's auction features.  <a href="https://getcarbly.com/auctions-plus/" target="_blank">Carbly's Auctions Plus</a> can help you find the right vehicles for your dealership.</p>

                <p>Simply fill out your information below.  No credit card required.</p>

                <form onSubmit={(e) => {e.preventDefault(); this.onSubmit()}}>
                  <div className='split-row' style={{columnGap: '10px'}}>
                    <input type="text" placeholder="First Name" value={firstName} onChange={(e) => {
                      this.setState({firstName: e.target.value})
                    }} />
                    <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => {
                      this.setState({lastName: e.target.value})
                    }} />
                  </div>

                  <div className='split-row' style={{columnGap: '10px'}}>
                    <input type="tel" maxLength={10} placeholder="Cell number (10 digits)" value={phoneNumber} onChange={(e) => {
                      const formattedNumber = CommonFunctions.stripNonNumericNoDecimal(e.target.value)
                      this.setState({phoneNumber: formattedNumber})
                    }}/>
                    <input type="password" placeholder="Password (at least 8)" value={password} onChange={(e) => {
                      this.setState({password: e.target.value})
                    }}/>
                  </div>

                  <div className='split-row'>
                    
                  </div>

                  {
                    email.length > 0 &&
                    <div style={{textAlign: 'center', marginBottom: '10px'}}>Email for trial: <span style={{fontWeight: 'bold'}}>{email}</span></div>
                  }

                  <Button type='submit' color='primary' disabled={!isSubmitEnabled} style={{width: '100%', marginBottom: '20px'}}>Start my trial</Button>
                </form>

                <p>Carbly's <a href="https://getcarbly.com/auctions-plus/" target="_blank">Auctions Plus</a> transforms your vehicle sourcing strategy by helping you find and evaluate the best vehicles from over 300 auctions and digital marketplaces across the country - with powerful saved searches and a single, unified experience.</p>

                <div className='split-row'>
                  <ul>
                    <li>Year, Make, Model, Trim</li>
                    <li>Odometer</li>
                    <li>Vehicle Details</li>
                    <li>Body Types</li>
                    <li>CR Score</li>
                    <li>Exterior/Interior Color</li>
                  </ul>

                  <ul>
                    <li>Wholesale Estimate</li>
                    <li>Retail Estimate</li>
                    <li>Profit Potential</li>
                    <li>Buy it Now</li>
                    <li>Distance from You</li>
                    <li>VIN Search</li>
                  </ul>
                </div>

                <div className="provider-logos">
                  <img src="/images/provider-black_book.png" alt="Black Book" />
                  <img src="/images/provider-manheim.png" alt="Manheim Market Report" />
                  <img src="/images/provider-real_retail.png" alt="Real Retail" className="real-retail" />
                  <img src="/images/provider-nada.png" alt="NADA" />
                  <img src="/images/provider-jd-power.png" alt="J.D. Power" className="jd-power" />
                  <img src="/images/provider-carfax.png" alt="CARFAX" className="carfax" />
                  <img src="/images/provider-kbb.png" alt="Kelley Blue Book" />
                  <img src="/images/provider-live_local_market.png" alt="Live Local Market" className="live-local-market" />
                  <img src="/images/auctions-plus-logo.png" alt="Auctions Plus" className="live-local-market" />
                  <img src="/images/regional-reports-plus-logo.png" style={{width: '200px', height: '37px'}} />
                </div>
              </div>

              <div style={{textAlign: 'center'}}>Have questions?  Give us a call at {AppConstants.phoneNumber}</div>


              <div className="login">
                Already have a Carbly account? <a href="/">Login</a>
              </div>

              <div className="screenshots">
                <a href="https://getcarbly.com/?r=edge" target="_blank"><img src="/images/carbly-valuation-screenshots.png" alt="Carbly mobile" /></a>
              </div>
            </div>
          </div>

        </div>

        <div className="partner-footer-c">
          <div className="partner-footer">
            <div className="partner-footer-body">
              <img src="/images/carbly-logo-yellow.png" alt="Carbly" />
              <div>
                <h3>Contact us</h3>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  {AppConstants.phoneNumber}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                  </svg>
                  {AppConstants.supportEmailAddress}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                  </svg>
                  <a href="https://getcarbly.com/?r=edge" target="_blank">getcarbly.com</a>
                </div>

              </div>
            </div>
            <div className="partner-legal">
              <div>Carbly&reg; is a registered trademark of Ambient Automotive, Inc</div>
              <div>Copyright &copy; {todayDate.getFullYear()} Ambient Automotive, Inc</div>
            </div>

          </div>
        </div>

        {
          platformFunctions.isProduction() &&
          <iframe src="https://getcarbly.com/landers/edge-iframe/?utm_source=edge&utm_medium=referral&utm_campaign=Edge+Embed&r=edge" width={1} height={1} title="shell" />
        }
      </div>
    )
  }



}
