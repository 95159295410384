import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import ValuationProviderCard from './ValuationProviderCard'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import CarfaxConnectAuth from '../common/CarfaxConnectAuth'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import platformFunctions from '../../platformFunctions'
const title = AppConstants.providerNames.carfax

export default class Carfax extends React.Component {
  state = {
    isLoadingPreview: ValuationStore.carfaxIsLoadingForVehicle(this.props.vehicleID),
    carfaxPreview: null,
    hasAuthorizedCarfaxConnect: SessionStore.hasAuthorizedCarfaxConnect(),
    showCarfaxHistoryValue: SessionStore.showCarfaxHistoryValue
  }

  componentDidMount() {
    ValuationStore.on(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxPreviewDidChange)
    ValuationStore.on(`carfax_summary_is_loading_changed_${this.props.vehicleID}`, this.carfaxPreviewIsLoadingDidChange)
    SessionStore.on("user_data_changed", this.userDataChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    ValuationStore.removeListener(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxPreviewDidChange)
    ValuationStore.removeListener(`carfax_summary_is_loading_changed_${this.props.vehicleID}`, this.carfaxPreviewIsLoadingDidChange)
  }

  carfaxPreviewIsLoadingDidChange = () => {
    this.setState({
      isLoadingPreview: ValuationStore.carfaxIsLoadingForVehicle(this.props.vehicleID)
    })
  }

  userDataChanged = () => {
    this.setState({
      hasAuthorizedCarfaxConnect: SessionStore.hasAuthorizedCarfaxConnect(),
    })
  }

  purchaseCarfax = () => {
    this.setState({
      isLoadingPreview: true
    })
    ValuationActionCreators.loadCarfaxSummary(this.props.vehicleID, true)
  }

  carfaxPreviewDidChange = () => {
    const carfaxPreview = ValuationStore.carfaxSummaryWithID(this.props.vehicleID)
    this.setState({
      carfaxPreview: carfaxPreview
    })
  }

  runReport = (e) => {
    e.preventDefault()
    this.purchaseCarfax()
  }

  renderPreviewItem(label, indicator, isOK) {
    return (
      <div className={`carfax-preview-item${!isOK ? ' carfax-preview-item-warning' : ''}`}><div>{label}</div> <div>{indicator}</div></div>
    )
  }

  renderColumn(pillar, overrideText) {
    const { iconUrl, iconText } = pillar || {}
    if (!(iconUrl && iconText)) return null
    return (
      <div className="carfax-preview-item">
        <div>
          <img src={iconUrl} alt='icon'/>
        </div>
        <div className="carfax-preview-text">
          {overrideText || iconText}
        </div>
      </div>
    )
  }

  renderFullReportLink(url) {
    if (!url) return null
    return (
      <div>
        <div className="full-report-link">
          <a href={url} target="_blank" rel="noopener noreferrer">View Full Report in New Window <ExternalLinkIcon style={{marginTop: '-2px'}} /></a>
        </div>
      </div>
    )
  }

  renderLegacyPreview(carfaxPreview) {
    const cfVINInfo = carfaxPreview.carfax_response.vin_info
    const damageOK = cfVINInfo.damage_indicator.damage_indicator_value === 'N'
    const frameOK = cfVINInfo.frame_damage_indicator.frame_damage_value === 'N'
    const titleOK = cfVINInfo.branded_title_indicator.branded_title_indicator_value === 'N'
    const accidentOK = cfVINInfo.accident_indicator === 'N'
    const problemsOK = cfVINInfo.major_problem_indicator.major_problem_value === 'N'

    return (
      <div>
        <div style={{color: 'red', marginTop: '10px', fontWeight: 'bold'}}>{AppConstants.providerNames.carfax} will soon be cutting off access for legacy logins.  Please switch to {AppConstants.providerNames.carfax} Connect immediately.</div>
        <div style={{marginBottom: '20px'}}><Link to='/settings'>Open Settings</Link>, unlink your {AppConstants.providerNames.carfax} account, and re-link {AppConstants.providerNames.carfax} again.</div>
        <div className="carfax-preview-data">
          { this.renderPreviewItem('Damage', this.renderIndicator(damageOK), damageOK)}
          { this.renderPreviewItem('Frame Damage', this.renderIndicator(frameOK), frameOK)}
          { this.renderPreviewItem('Branded Title', this.renderIndicator(titleOK), titleOK)}
        </div>
        <div className="carfax-preview-data">
          { this.renderPreviewItem('Accidents', this.renderIndicator(accidentOK), accidentOK)}
          { this.renderPreviewItem('Major Problems', this.renderIndicator(problemsOK), problemsOK)}
          { this.renderPreviewItem('Previous Owners', cfVINInfo.number_of_owners_indicator.number_of_owners_indicator_value, true)}
        </div>

        { this.renderFullReportLink(carfaxPreview.web_url) }
      </div>
    )
  }

  renderConnectPreview({ results }) {
    const dealerReport = results?.dealerReport
    const historyBasedValueFetch = results?.historyBasedValueFetch
    const fourPillars = dealerReport?.fourPillars
    const carfaxLink = dealerReport?.carfaxLink
    const serviceRecordCount = fourPillars?.serviceRecord?.recordCount
    var serviceText = null
    if (serviceRecordCount) {
      serviceText = `${serviceRecordCount} Service History ${pluralize('Record', serviceRecordCount, false)}`
    }

    const overallCondition = this.props.overallCondition

    var selectedOptions = null
    if (historyBasedValueFetch?.options?.length > 0) {
      selectedOptions = historyBasedValueFetch.options.filter((o) => o.selected === true).map((o) => (o.name))
    }

    var arrowsArray = []
    if (historyBasedValueFetch?.arrows?.up?.length > 0) {
      for (let arrow of historyBasedValueFetch.arrows.up) {
        arrowsArray.push(arrow)
      }
    }
    if (historyBasedValueFetch?.arrows?.down?.length > 0) {
      for (let arrow of historyBasedValueFetch.arrows.down) {
        arrowsArray.push(arrow)
      }
    }

    var selectedTrim = null
    if (historyBasedValueFetch?.trims?.length > 0) {
      for (let trim of historyBasedValueFetch.trims) {
        if (trim.selected === true) {
          selectedTrim = trim.name
        }
      }
    }

    const showCarfaxHistoryValue = this.state.showCarfaxHistoryValue

    return (
      <div>
        <div className="carfax-connect-preview-data" style={{backgroundColor: '#eee', borderRadius: '6px', padding: '10px', marginTop: '10px'}}>
          { this.renderColumn(fourPillars.accident) }
          { this.renderColumn(fourPillars.owner) }
          { this.renderColumn(fourPillars.useType) }
          { this.renderColumn(fourPillars.serviceRecord, serviceText) }
          {
            Boolean(dealerReport?.recall?.hasOpenRecall) &&
            this.renderColumn(dealerReport.recall)
          }
        </div>
        { this.renderFullReportLink(carfaxLink.url) }

        {
          historyBasedValueFetch &&
          <div style={{border: '1px solid #ddd', padding: '10px', borderRadius: '6px', marginTop: '20px'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0'}}>
              <h3 className="valuation-subsection-title" style={{margin: 0, marginBottom: 0}}>{AppConstants.providerNames['carfax']} History-Based Value</h3>
              <a href="#toggle" onClick={(e) => {
                e.preventDefault()

                platformFunctions.logAnalyticsEvent("Valuations", `Carfax History Value ${showCarfaxHistoryValue ? 'Hide' : 'Show'}`)
                SessionStore.setShowCarfaxHistoryValue(!showCarfaxHistoryValue)
                this.setState({ showCarfaxHistoryValue: !showCarfaxHistoryValue })
              }}>
                {
                  showCarfaxHistoryValue ?
                  <span>Hide</span> : <span>Show</span>
                }
              </a>
            </div>

            {
              showCarfaxHistoryValue &&
              <div>
                <div style={{marginBottom: '5px'}}>Values are adjusted for specific mileage and region and may differ from the values shown in the full {AppConstants.providerNames['carfax']} report.</div>
                <div className="valuation-subsection prices-table" style={{marginTop: 0}}>
                  <div className="prices-table-row prices-table-row-header">
                    <div className="prices-table-cell header-cell">&nbsp;</div>
                    <div className="prices-table-cell header-cell">Wholesale</div>
                    <div className="prices-table-cell header-cell">Retail</div>
                    <div className="prices-table-cell header-cell">Certified</div>
                  </div>

                  <div>
                    <div className={`prices-table-row${overallCondition && overallCondition === 'rough' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-fair`}>
                      <div className="prices-table-cell row-label">
                        <div>Fair</div>
                      </div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.fairConditionValues?.wholesale)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.fairConditionValues?.retail)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.fairConditionValues?.certified)}</div>
                    </div>


                    <div className={`prices-table-row${overallCondition && overallCondition === 'average' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-good`}>
                      <div className="prices-table-cell row-label">
                        <div>Good</div>
                      </div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.goodConditionValues?.wholesale)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.goodConditionValues?.retail)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.goodConditionValues?.certified)}</div>
                    </div>


                    <div className={`prices-table-row${overallCondition && overallCondition === 'clean' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-excellent`}>
                      <div className="prices-table-cell row-label">
                        <div>Excellent</div>
                      </div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.excellentConditionValues?.wholesale)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.excellentConditionValues?.retail)}</div>
                      <div className="prices-table-cell">{Formatters.formatCurrency(historyBasedValueFetch?.excellentConditionValues?.certified)}</div>
                    </div>
                  </div>
                </div>

                {
                  selectedOptions && selectedOptions.length > 0 &&
                  <div style={{marginTop: '10px'}}>
                    <div>Selected Options</div>
                    <div className='secondary-text'>{selectedOptions.join(', ')}</div>
                  </div>
                }

                {
                  Boolean(selectedTrim) &&
                  <div style={{marginTop: '10px'}}>
                    <div>Trim: {selectedTrim}</div>
                  </div>
                }

                {
                  arrowsArray && arrowsArray.length > 0 &&
                  <div style={{marginTop: '10px'}}>
                    <div style={{fontWeight: 'bold'}}>History events affecting this vehicle's value</div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '10px'}}>
                      {
                        arrowsArray.map((arrow, index) => {
                          return (
                            <div key={`arrow-${index}`} style={{width: '23%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                              <img src={arrow.direction} style={{width: '56px', height: '34px', marginBottom: '5px'}} alt='Arrow'/>

                              <div style={{fontSize: 13, textAlign: 'center'}}>{arrow.text}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    )
  }

  renderIndicator(ok) {
    return (
      <img src={`/images/carfax-${ok === true ? 'ok' : 'alert'}.png`} alt={ok === true ? 'OK' : 'Alert'}/>
    )
  }

  renderBody = () => {
    const { carfaxPreview, isLoadingPreview } = this.state

    if (this.props.hasVIN !== true) {
      return (
        <div>VIN required for vehicle history</div>
      )
    }

    if (!this.state.hasAuthorizedCarfaxConnect) {
      return (
        <div style={{marginTop: '10px'}}>
          {
            global.isIframeMode || global.isExtensionMode ?
              <div>
                Link your {title} account on <a target='_blank' href="/settings/">Carbly Desktop</a>
              </div> :
              <CarfaxConnectAuth/>
          }
        </div>
      )
    }

    if (ValuationFunctions.carfaxRequiresReauth(carfaxPreview)) {
      return (
        <div style={{margin: '10px 0'}}>
          <div style={{marginBottom: '10px'}}>{title} periodically requires users to re-authorize their accounts.  Please click the button below to do so.</div>
          <CarfaxConnectAuth/>
          {
            (global.isIframeMode || global.isExtensionMode) &&
            <div style={{marginTop: '5px'}}>After re-authorizing, refresh your browser</div>
          }
        </div>
      )
    }

    if (isLoadingPreview === true) {
      return <Spinner />
    }

    if (carfaxPreview && carfaxPreview.errors) {
      return (
        <div>{carfaxPreview.errors}</div>
      )
    }

    if (ValuationFunctions.hasCarfaxPreviewData(carfaxPreview)) {
      return ValuationFunctions.carfaxIsConnect(carfaxPreview) ?
        this.renderConnectPreview(carfaxPreview) :
        this.renderLegacyPreview(carfaxPreview)
    }

    if (ValuationFunctions.carfaxIsPurchasable(carfaxPreview)) {
      return (
        <div className="carfax-preview-data">
          <a href="#carfax-report" onClick={this.runReport}>View {title} Report</a>
        </div>
      )
    }
  }

  render() {
    const title = AppConstants.providerNames['carfax']

    return (
      <ValuationProviderCard title={title} forceHasData={true}>
        { this.renderBody() }
      </ValuationProviderCard>
    )
  }
}
