import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationProviderCard from './ValuationProviderCard'
import AppConstants from '../../api/constants/AppConstants'
import Spinner from '../common/Spinner'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import pluralize from 'pluralize'
import { Button } from 'reactstrap'
import Formatters from '../../api/constants/Formatters'
import moment from 'moment'
import platformFunctions from '../../platformFunctions'
import PaymentFormModal from '../settings/PaymentFormModal'

const title = AppConstants.providerNames.autocheck

export default class AutoCheck extends React.Component {

  state = {
    hasLinkedAutoCheck: SessionStore.hasLinkedAutoCheck(),
    isLoadingPreview: false,
    autoCheckPreview: ValuationStore.autoCheckSummaryWithID(this.props.vehicleUUID),
    isLoadingVehicleReport: false,
    vehicleReport: null,
    purchasedReportErrors: null,
    isPurchasingReport: false,
    isShowingAddPaymentModal: false,
    isAddingPaymentForPurchase: false
  }

  componentDidMount() {
    ValuationStore.on(`autocheck_summary_changed_${this.props.vehicleUUID}`, this.autoCheckPreviewDidChange)
    ValuationStore.on(`autocheck_summary_is_loading_changed_${this.props.vehicleUUID}`, this.autoCheckPreviewIsLoadingDidChange)
    ValuationStore.on(`vehicle_report_change_${this.props.clientID}`, this.vehicleReportDidChange)
    SessionStore.on("user_data_changed", this.userDataChanged)

    if (this.props.hasVIN && !SessionStore.hasLinkedAutoCheck()) {
      ValuationActionCreators.loadVehicleReport(this.props.vehicleUUID, 'autocheck', this.props.clientID)
      this.setState({ isLoadingVehicleReport: true })
    }
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`autocheck_summary_changed_${this.props.vehicleUUID}`, this.autoCheckPreviewDidChange)
    ValuationStore.removeListener(`autocheck_summary_is_loading_changed_${this.props.vehicleUUID}`, this.autoCheckPreviewIsLoadingDidChange)
    ValuationStore.removeListener(`vehicle_report_change_${this.props.clientID}`, this.vehicleReportDidChange)
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
  }

  userDataChanged = () => {
    const user = SessionStore.user
    if (this.state.isShowingAddPaymentModal && this.state.isAddingPaymentForPurchase) {
      // Free user just successfully added a payment method
      this.setState({
        isShowingAddPaymentModal: false,
        isAddingPaymentForPurchase: false
      })
      setTimeout(() => {
        this.purchaseVehicleReport()
      }, 200)
    }
  }

  autoCheckPreviewIsLoadingDidChange = () => {
    this.setState({
      isLoadingPreview: Boolean(ValuationStore.autoCheckIsLoadingForVehicle(this.props.vehicleUUID))
    })
  }

  autoCheckPreviewDidChange = () => {
    const autoCheckPreview = ValuationStore.autoCheckSummaryWithID(this.props.vehicleUUID)
    this.setState({
      autoCheckPreview: autoCheckPreview
    })
  }

  vehicleReportDidChange = () => {
    // Check to see if we have a purchased AutoCheck report
    const vehicleReport = ValuationStore.vehicleReportWithClientID(this.props.clientID)
    const purchasedReportErrors = Boolean(vehicleReport?.errors && vehicleReport.errors.length > 0) ? vehicleReport.errors : null

    this.setState({
      isLoadingVehicleReport: false,
      vehicleReport: Boolean(purchasedReportErrors) ? this.state.vehicleReport : vehicleReport,
      purchasedReportErrors
    })
  }

  purchaseVehicleReport = () => {
    if (SessionStore.needsPaymentMethod()) {
      this.setState({
        isShowingAddPaymentModal: true,
        isAddingPaymentForPurchase: true
      })
    } else if (SessionStore.user?.permissions?.make_purchases === true) {
      const { vehicleReport } = this.state
      const confirmMessage = `You will be immediately charged ${Formatters.formatCurrencyCents(vehicleReport.price)}. If a report cannot be generated, you will be immediately refunded the full amount.`
      if (window.confirm(confirmMessage)) {
        this.purchaseVehicleReportConfirmed()
      }
    }
  }

  purchaseVehicleReportConfirmed = () => {
    platformFunctions.logAnalyticsEvent("User", "AutoCheck Purchase")
    this.setState({
      isLoadingVehicleReport: true
    })
    ValuationActionCreators.purchaseVehicleReport(this.props.vehicleUUID, 'autocheck', this.props.clientID)
  }

  iconForWarningType = (warningType) => {
    return (<img src={`/images/appraisal-message-${warningType === 'warning' ? 'warning' : 'ok'}.png`} alt="" style={{flexGrow: 0, flexShrink: 0, width: '20px', height: '20px', marginRight: '5px'}} />)
  }

  renderVehicleReportSummaryRow = (name, warningType, additionalRow = null) => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '2px', paddingBottom: '2px', borderBottom: '1px solid #eee'}} key={`r-${name}-${warningType}`}>
        <div style={{width: '30px'}}>{ this.iconForWarningType(warningType) }</div>
        <div style={{flexGrow: 1, flexShrink: 1}}>
          <div style={{flexShrink: 1, flexGrow: 1}}>{name}</div>
          {
            Boolean(additionalRow) &&
            <div style={{flexShrink: 1, flexGrow: 1}} className='secondary-text'>{additionalRow}</div>
          }
        </div>
      </div>
    )
  }

  renderPurchasedVehicleReport = (vehicleReport) => {
    const data = vehicleReport.data
    if (!data) { return null }

    let elements = []

    const autoScoreSection = data.autoScore
    const titleProblemSection = data.titleProblem
    const accidentPremiumSection = data.accidentPremium
    const ownershipPremiumSection = data.ownershipPremium
    const mileagePremiumSection = data.mileagePremium

    var titleWarning = false
    if (titleProblemSection?.titleProblemCheck === 'y' || titleProblemSection?.insuranceLoss === 'Y' || titleProblemSection?.titledToInsurance === 'Y' || titleProblemSection?.greyMarket) {
      titleWarning = true
    }


    if (autoScoreSection) {
      if (autoScoreSection.autocheckScore && autoScoreSection.scoreRangeLow && autoScoreSection.scoreRangeHigh) {
        const score = autoScoreSection.autocheckScore
        const scoreLow = autoScoreSection.scoreRangeLow
        const scoreHigh = autoScoreSection.scoreRangeHigh

        let currentScoreBarColor = '#24488a'
        if (score < scoreLow) {
          currentScoreBarColor = 'darkred'
        } else if (score > scoreHigh) {
          currentScoreBarColor = 'darkgreen'
        }


        elements.push(
          <div style={{display: 'inline-block', marginTop: '10px'}} key='score-ind'>
            <div style={{border: '1px solid #ddd', backgroundColor: '#f4f4f4', borderRadius: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px 20px', flexGrow: 1, flexShrink: 1}}>
              <div style={{width: '96px', height: '96px', borderRadius: '48px', marginRight: '10px', backgroundColor: currentScoreBarColor, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '88px', height: '88px', borderRadius: '45px', fontSize: '50px', fontWeight: 'bold', backgroundColor: currentScoreBarColor, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #fff', margin: '5px 5px'}}>
                  {score}
                </div>
              </div>

              <div>
                <div style={{color: '#24488a', fontSize: '22px', fontWeight: 'bold'}}>{title} Score</div>
                <div>Low: {scoreLow}</div>
                <div>High: {scoreHigh}</div>
              </div>
            </div>
          </div>
        )
      }
    }

    var positiveFactors = []
    if (autoScoreSection?.autocheckPosScoreFactor1) { positiveFactors.push(autoScoreSection.autocheckPosScoreFactor1) }
    if (autoScoreSection?.autocheckPosScoreFactor2) { positiveFactors.push(autoScoreSection.autocheckPosScoreFactor2) }
    if (autoScoreSection?.autocheckPosScoreFactor3) { positiveFactors.push(autoScoreSection.autocheckPosScoreFactor3) }

    if (positiveFactors.length > 0) {
      elements.push(
        <div style={{margin: '10px 0'}} key='factors-pos'>
          <div style={{}}>Factors positively affecting score: <span style={{fontWeight: 'bold'}}>{positiveFactors.join('. ')}</span></div>
        </div>
      )
    }

    var negativeFactors = []
    if (autoScoreSection?.autocheckNegScoreFactor1) { negativeFactors.push(autoScoreSection.autocheckNegScoreFactor1) }
    if (autoScoreSection?.autocheckNegScoreFactor2) { negativeFactors.push(autoScoreSection.autocheckNegScoreFactor2) }
    if (autoScoreSection?.autocheckNegScoreFactor3) { negativeFactors.push(autoScoreSection.autocheckNegScoreFactor3) }

    if (negativeFactors.length > 0) {
      elements.push(
        <div style={{marginVertical: '5px'}} key='factors-neg'>
          <div style={{}}>Factors negatively affecting score: <span style={{fontWeight: 'bold'}}>{negativeFactors.join('. ')}</span></div>
        </div>
      )
    }


    let summaryItems = []
    if (titleProblemSection) {
      summaryItems.push(this.renderVehicleReportSummaryRow(`${titleWarning === 'Y' ? '' : 'No '}Title Check Warnings`, titleWarning === 'Y' ? 'warning' : 'ok'))
      summaryItems.push(this.renderVehicleReportSummaryRow(`${titleProblemSection?.accidentCount > 0 || titleProblemSection?.accidentCheck === 'Y' ? '' : 'No'}Accident(s) Reported`, titleProblemSection?.accidentCount > 0 || titleProblemSection?.accidentCheck === 'Y' ? 'warning' : 'ok'))
      if (titleProblemSection?.airbagDeployed === 'Y') {
        summaryItems.push(this.renderVehicleReportSummaryRow('Air Bag Deployed', 'warning'))
      }
  
      // Make conditional
      summaryItems.push(this.renderVehicleReportSummaryRow(`${titleProblemSection.structuralDamage === 'Y' ? '' : 'No '}Structural Damage Reported`, titleProblemSection.structuralDamage === 'Y' ? 'warning' : 'ok'))
  
      // Make conditional
      summaryItems.push(this.renderVehicleReportSummaryRow(`${titleProblemSection.damageCheck === 'Y' ? '' : 'No '}Other Damage Reported`, titleProblemSection.damageCheck === 'Y' ? 'warning' : 'ok'))
    }

    
    if (titleProblemSection && mileagePremiumSection) {
      summaryItems.push(this.renderVehicleReportSummaryRow(`${titleProblemSection.odometerRollback === 'N' ? 'No' : ''} Odometer Problem(s) Reported`, titleProblemSection.odometerRollback === 'Y' ? 'warning' : 'ok', mileagePremiumSection?.lastOdometer ? `Last Odometer Reading: ${Formatters.formatThousands(mileagePremiumSection?.lastOdometer)}` : null))
    }
    
    
    elements.push(
      <div key='summary-section' className='autocheck-section' style={{marginTop: '20px'}}>
        <h3>Summary</h3>
        { summaryItems }
      </div>
    )

    if (titleProblemSection) {
      elements.push(
        <div key='title-section' className='autocheck-section'>
          <h3>Title</h3>
        
          {
            this.renderVehicleReportSummaryRow(`${titleProblemSection.titleProblemCheck === 'Y' ? '' : 'No '}Title Issues Reported`, titleProblemSection.titleProblemCheck === 'Y' ? 'warning' : 'ok')
          }

          {
            this.renderVehicleReportSummaryRow(`${titleProblemSection.insuranceLoss === 'Y' ? '' : 'No '}Insurance Loss Record`, titleProblemSection.insuranceLoss === 'Y' ? 'warning' : 'ok')
          }

          {
            this.renderVehicleReportSummaryRow(`${titleProblemSection.titledToInsurance === 'Y' ? '' : 'No '}Titled to Insurance Company`, titleProblemSection.titledToInsurance === 'Y' ? 'warning' : 'ok')
          }

          {
            this.renderVehicleReportSummaryRow(`${titleProblemSection.greyMarket === 'Y' ? '' : 'No '}Grey Market Title Record`, titleProblemSection.greyMarket === 'Y' ? 'warning' : 'ok')
          }
          
        </div>
      )
    }


    if (accidentPremiumSection?.accidentCount > 0 && accidentPremiumSection?.reportedAccidents?.length > 0) {
      elements.push(
        <div key='accident-section' className='autocheck-section'>
          <h3>Accidents</h3>
          
          <div style={{}}>{pluralize('accident', accidentPremiumSection.accidentCount, true)} reported</div>
          <div style={{marginTop: '10px', fontWeight: 'bold'}}>Accident Report Records</div>
            {
              accidentPremiumSection.reportedAccidents.map((acc, index) => {
                let cityStateArray = []
                if (acc.city) {cityStateArray.push(acc.city)}
                if (acc.state) {cityStateArray.push(acc.state)}
                return (
                  <div key={`acc-item-${index}`} style={{borderTop: '1px solid #ddd', padding: '4px 0'}}>
                    <div style={{}}>{acc.reportedDate} {cityStateArray.length > 0 ? `- ${cityStateArray.join(', ')}` : ''}</div>
                    <div>{acc.type}</div>
                  
                  </div>
                )
              })
            }
          </div>
      )
    }
    


    if (ownershipPremiumSection) {
      elements.push(
        <div key='owner-section' className='autocheck-section'>
          <h3>Ownership</h3>
        
          <div style={{}}>{pluralize('owner', ownershipPremiumSection.ownerCount, true)} reported</div>
          <div style={{marginTop: '10px', fontWeight: 'bold'}}>Ownership Records</div>
          {
            ownershipPremiumSection.ownershipDetails?.length > 0 &&
            <div>
              {
              ownershipPremiumSection.ownershipDetails.map((owner, index) => {
                return (
                  <div key={`own-item-${index}`} style={{borderTop: '1px solid #ddd', padding: '10px 0'}}>
                    <div style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <div><span style={{fontWeight: 'bold'}}>Purchased:</span> {owner.startDate}</div>
                      <div><span style={{fontWeight: 'bold'}}>Where:</span> {owner.state}</div>
                    </div>
                  
                    <div><span style={{fontWeight: 'bold'}}>Owned from:</span> {owner.startDate} {owner.endDate ? `- ${owner.endDate}` : ''}</div>
                  </div>
                )
              })
            }
            </div>
          }
        </div>
      )
    }

    if (mileagePremiumSection) {
      elements.push(
        <div key='odom-section' className='autocheck-section'>
          <h3>Odometer</h3>
          {
            titleProblemSection?.odometerRollback === 'N' ? 
            <div>No odometer rollback has been reported</div>:
            <div>Odometer rollback has been reported</div>
          }
        
        <div style={{marginTop: '10px', fontWeight: 'bold'}}>Odometer Readings</div>
          {
            mileagePremiumSection.reportedOdometerReadings?.length > 0 &&
            <div>
              {
              mileagePremiumSection.reportedOdometerReadings.map((odo, index) => {
                let cityStateArray = []
                if (odo.city) {cityStateArray.push(odo.city)}
                if (odo.state) {cityStateArray.push(odo.state)}
                return (
                  <div key={`odo-item-${index}`} style={{borderTop: '1px solid #ddd', padding: '4px 0'}}>
                    <div style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <div><span style={{fontWeight: 'bold'}}>Mileage:</span> {Formatters.formatThousands(odo.odometer)}</div>
                      <div><span style={{fontWeight: 'bold'}}>Date:</span> {odo.date}</div>
                    </div>
                    {
                      cityStateArray.length > 0 &&
                      <div>{cityStateArray.join(', ')}</div>
                    }
                    {
                      odo.type &&
                      <div>{odo.type}</div>
                    }
                  </div>
                )
              })
            }
            </div>
          }
        </div>
      )
    }


    const daysOld = Math.abs(moment(vehicleReport.created).diff(moment(), 'days'))
    const isStale = daysOld > 29
    const canMakePurchases = SessionStore.user?.permissions?.make_purchases === true

    return (
      <div>
        <div style={{}}>{title} data purchased {Formatters.formatVerboseDate(vehicleReport.created)}</div>

        {
          isStale && canMakePurchases &&
          <div style={{margin: '20px 0', border: '1px solid #ddd', padding: 5, borderRadius: '6px'}}>
            <div>This {title} data was generated {pluralize('day', daysOld, true)} ago. You can re-purchase the report if you'd like an updated version. Re-purchasing might not result in additional data.</div>
            <a href="#repurchase" style={{textAlign: 'center'}} onClick={(e) => {
              e.preventDefault()
              this.purchaseVehicleReport()
            }}>Re-purchase Report?</a>
          </div>
        }

        {elements}

      </div>
    )
  }

  render() {
    // FIXME: i think we have to break out see_autocheck and autocheck_sid??
    const isAutoCheckVisible = SessionStore.isAutoCheckVisible()
    if (isAutoCheckVisible !== true) { return null }

    let { hasLinkedAutoCheck, isLoadingPreview, autoCheckPreview, isLoadingVehicleReport, vehicleReport, purchasedReportErrors, isShowingAddPaymentModal } = this.state
    const hasPurchasedReportErrors = Boolean(purchasedReportErrors)
    const hasVIN = this.props.hasVIN
    const isIframeOrExtension = Boolean(global.isIframeMode || global.isExtensionMode)
    const autoCheckSummaryIsComplete = ValuationFunctions.autoCheckSummaryIsComplete(autoCheckPreview)
    const textStyle = autoCheckSummaryIsComplete && autoCheckPreview.score < autoCheckPreview.typical_low_score ? {backgroundColor: '#b00c0c', color: '#fff', padding: '2px 10px', borderRadius: '6px'} : null

    const canMakePurchases = SessionStore.user?.permissions?.make_purchases === true
    const hasPurchasedReport = Boolean(vehicleReport?.data)
    
    return (
      <ValuationProviderCard title={title} forceHasData={true}>
      {
          hasVIN &&
          <div>
            {
              hasLinkedAutoCheck &&
              <div className="autocheck-preview-data">
                {
                  isLoadingPreview &&
                  <Spinner />
                }
                {
                  Boolean(!isLoadingPreview && autoCheckSummaryIsComplete) &&
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
                    <div style={{width: '50px', height: '50px', backgroundColor: '#154b8e', borderRadius: 25, marginRight: '10px', flexDirection: 'row', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center'}}>
                      <div style={{fontSize: '22px', fontWeight: 'bold', color: '#fff', width: '100%', textAlign: 'center', alignItems: 'center', justifyContent: 'center', height: '50px', lineHeight: '50px'}}>{autoCheckPreview.score}</div>
                    </div>
                    <div style={{flexGrow: 1, flexShrink: 1}}>
                      <div style={{marginBottom: '2px'}}>{title} score for this vehicle is {autoCheckPreview.score}.</div>
                      <div style={{...textStyle, display: 'inline-block'}}>The range for similar vehicles is {autoCheckPreview.typical_low_score} to {autoCheckPreview.typical_high_score}.</div>
                    </div>
                  </div>
                }
                <div>
                  <a href={`/autocheck_report/${this.props.vehicleUUID}`} target="_blank" rel="noopener noreferrer">Run {title} Report</a>
                </div>
              </div>
            }

            {
              !hasLinkedAutoCheck && isIframeOrExtension && !hasPurchasedReport &&
                <div>
                  Link your {title} account on <a target='_blank' href="/settings/">Carbly Desktop</a>
                </div>
            }
            {
              !hasLinkedAutoCheck && !isIframeOrExtension && !hasPurchasedReport &&
                <div>If you have an {title} account, you can link it on the Settings tab</div>
            }

            {
              !hasLinkedAutoCheck &&
              <div>
                {
                  isLoadingVehicleReport &&
                  <Spinner />
                }

                {
                  !isLoadingVehicleReport && !hasPurchasedReport && vehicleReport && canMakePurchases &&
                  <Button color='primary' onClick={this.purchaseVehicleReport} style={{marginTop: '10px', padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'stretch', border: 0, overflow: 'hidden'}}>
                    <div style={{textAlign: 'center', color: '#fff', fontSize: '20px', fontWeight: 'bold', backgroundColor: '#3f8eca', padding: '5px 10px'}}>{Formatters.formatCurrencyCents(vehicleReport.price)}</div>
                    <div style={{color: '#fff', fontSize: '18px', padding: '5px 10px', textAlign: 'left', display: 'flex', alignItems: 'center', fontWeight: 'normal'}}>Purchase {title} data for this vehicle</div>
                    
                  </Button>
                }

                {
                  !isLoadingVehicleReport && !hasPurchasedReport && vehicleReport && !canMakePurchases &&
                  <div style={{marginTop: '20px'}}>If you would like to purchase {title} data for this vehicle, contact your account owner to enable purchasing.</div>
                }
              </div>
            }

            {
              hasPurchasedReport && !hasPurchasedReportErrors &&
              <div>{this.renderPurchasedVehicleReport(vehicleReport)}</div>
            }

            {
              hasPurchasedReportErrors &&
              <div style={{marginTop: '20px', fontWeight: 'bold', color: 'red'}}>{purchasedReportErrors.join(". ")}</div>
            }
          </div>
        }

        {
          !hasVIN &&
          <div>VIN required for vehicle history</div>
        }

        {
          isShowingAddPaymentModal &&
          <PaymentFormModal
            isAccountOwner={SessionStore.isAccountOwner()}
            onCancel={() => this.setState({ isShowingAddPaymentModal: false})}
            title='Add a Payment Method'
            description='In order to purchase this data, please enter a credit card first.'
            />
        }
      </ValuationProviderCard>
    )
  }
}
