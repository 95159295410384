import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import platformFunctions from '../../platformFunctions'

import StripeForm from './StripeForm'
import { Button } from 'reactstrap'

const stripePromise = loadStripe(platformFunctions.isProduction() ? 'pk_live_XAlgBkGs6TPVfbQm4uUayhVT' : 'pk_test_ZWnzUXq9EnnYjKQo8zk8DIRh')

export default class PaymentForm extends React.Component {

  render() {
    return(
      <div className="account-payment-form">
        {
          this.props.message &&
          <div style={{color: 'red', marginBottom: '20px'}}>{this.props.message}</div>
        }

        <Elements stripe={stripePromise}>
          <StripeForm />
        </Elements>


        <div style={{textAlign: 'center', marginTop: '30px', fontSize: '13px'}}>
          <img src="/images/stripe-logo.png" alt="Stripe" style={{width: '60px', height: 'auto'}} />
          <div>Credit card processing is handled by <a href="http://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>, and all communications are SSL encrypted.</div>
        </div>
      </div>
    )
  }

}
