import React from 'react'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import moment from 'moment'
import pluralize from 'pluralize'
import ValuationProviderCard from './ValuationProviderCard'
import DataTable from './DataTable'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'

const title = AppConstants.providerNames.pin
const graphSamplesThreshold = 5

const sampleColumns = [
          { title: 'Price', accessor: 'price'},
          { title: 'Profit', accessor: 'gross', width: '150px'},
          { title: 'Days on Lot', accessor: 'market_day_supply', width: '170px'},
          { title: 'Mileage', accessor: 'mileage', width: '160px'},
          { title: 'Sale Date', accessor: 'purchase_date', width: '106px'}
        ]

export default class ValuationPIN extends React.Component {

  renderSamples = (samples) => {
    var samplesData = samples?.data
    if (!samplesData || samplesData.length === 0) {
      return (<div style={{textAlign: 'center', padding: '10px 0'}}>No comps available</div>)
    }

    var isPreviewLimited = false
    if (global.isPartnerPreview && samplesData.length > 10) {
      samplesData = samplesData.slice(0, 10)
      isPreviewLimited = true
    }

    const formattedSamples = samplesData.map((sample) => (
      {
        price: {
          display_value: Formatters.formatCurrencyRoundedCents(sample.price),
          raw_value: sample.price
        },
        mileage: {
          display_value: sample.mileage !== null && sample.mileage > 0 ? Formatters.formatThousands(sample.mileage) : '',
          raw_value: sample.mileage
        },
        market_day_supply: {
          display_value: sample.market_day_supply,
          raw_value: sample.market_day_supply
        },
        gross: {
          display_value: sample.gross && sample.gross > 100  ? Formatters.formatCurrencyRoundedCents(sample.gross) : '',
          raw_value: sample.gross
        },
        purchase_date: {
          display_value: moment(sample.purchase_date).format('M/D/YYYY'),
          raw_value: moment(sample.purchase_date).format('YYYYMMDD'),
        }
      }
    ))

    return (
      <div className="valuation-subsection">
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <h3 className="valuation-subsection-title">Recent retail sales</h3>
          {
            Boolean(samples.region) &&
            <div>{samples.region}</div>
          }
        </div>
        <DataTable columns={sampleColumns} data={formattedSamples} providerKey='pin' defaultSort='mileage' valuationMileage={this.props.valuationMileage} />
        {
          global.isPartnerPreview && isPreviewLimited &&
          <div className="valuation-subsection partner-preview-access-message" style={{marginTop: '5px'}}>
            This list is limited to 10 retail sale transactions.  Create a Carbly account to view all available comps.
          </div>
        }
      </div>
    )
  }


  render() {
    const pinData = this.props.providerData
    if (!pinData) { return null }
    const description = pinData.description
    const error = pinData.error
    const systemData = this.props.systemData

    const samples = pinData.samples
    const allPrices = pinData.all_prices
    const allMDS = pinData.all_market_day_supply
    const oldestSampleDate = description && description.oldest_sale_date ? description.oldest_sale_date : null
    const samplesRange = moment(new Date()).diff(moment(oldestSampleDate), 'days')
    var headerValuesCount = 0
    if (!error && description && description.mileage_adjusted_price) { headerValuesCount++ }
    if (!error && description && description.avg_gross) { headerValuesCount++ }
    if (!error && description && description.avg_mileage) { headerValuesCount++ }



    var mdsGraphData = null
    var mdsMin = null
    var mdsMax = null

    var avgSaleGraphData = null
    var avgSaleMin = null
    var avgSaleMax = null
    if (samples && samples.data && samples.data.length >= graphSamplesThreshold) {
      var sortedSamples = samples.data.sort((a, b) => a.market_day_supply - b.market_day_supply)
      mdsMin = sortedSamples[0].market_day_supply
      mdsMax = sortedSamples[sortedSamples.length - 1].market_day_supply
      sortedSamples = samples.data.sort((a, b) => a.price - b.price)
    }

    var hasPricesGraphData = false
    if (allPrices && allPrices.length > graphSamplesThreshold) {
      hasPricesGraphData = true
      var formattedAvgSaleSamplesData = allPrices.map((s) => {
        return {
          "x": s,
          "y": 1,
          "isIndicator": false,
          "formattedPrice": `Sale price: ${Formatters.formatCurrencyRoundedCents(s)}`
        }
      })
      formattedAvgSaleSamplesData.push({
        "x": description.avg_price,
        "y": 1,
        "isIndicator": true,
        "formattedPrice": `Average sale price: ${Formatters.formatCurrencyRoundedCents(description.avg_price)}`,
      })
      avgSaleGraphData = [ { "id": "sale_price", "data": formattedAvgSaleSamplesData } ]
      avgSaleMin = allPrices[0]
      avgSaleMax = allPrices[allPrices.length - 1]
    }

    var hasMDSGraphData = false
    if (allMDS && allMDS.length > graphSamplesThreshold) {
      hasMDSGraphData = true
      var formattedMDSSamplesData = allMDS.map((s) => {
        return {
          "x": s,
          "y": 1,
          "isIndicator": false,
          "formattedMDS": `${pluralize('day', s, true)} on the lot`
        }
      })
      formattedMDSSamplesData.push({
        "x": description.mdn_market_day_supply,
        "y": 1,
        "isIndicator": true,
        "formattedMDS": `Median: ${pluralize('day', description.mdn_market_day_supply, true)} on the lot`,
      })
      mdsGraphData = [ { "id": "mds", "data": formattedMDSSamplesData } ]
    }



    return (
      <ValuationProviderCard
          providerData={pinData}
          vehicleUUID={this.props.vehicleUUID}
          clientID={this.props.clientID}
          providerKey={this.props.providerKey}
          systemData={systemData}
          fullChoices={this.props.fullChoices}
          currentChoiceUID={pinData.description && pinData.description.uid ? pinData.description.uid : null}
          title={title}
          subtitle={error || !description ? null : description.display_choice}
          hasError={error ? true : false}
          valuationMileage={this.props.valuationMileage}
          providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
          showAdjustmentMessage={false}
          messages={pinData.messages}
          >

          {
            !error && description &&
            <div>
              {
                samplesRange > 0 &&
                <h3>Dealer sales data from the last <strong>{pluralize('day', samplesRange, true)}</strong></h3>
              }

              {
                (description.mileage_adjusted_price || description.avg_gross || description.avg_mileage) &&
                <div className="valuation-subsection header-values">
                  {
                    Boolean(description.mileage_adjusted_price) &&
                    <div className="header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                      <div className="header-value-value">{Formatters.formatCurrencyRoundedCents(description.mileage_adjusted_price)}</div>
                      <div className="header-value-label">Mileage adjusted retail sale price</div>
                    </div>
                  }
                  {
                    Boolean(description.avg_gross) &&
                    <div className="header-value-c" style={{width: `${1 / headerValuesCount}%`}}>
                      <div className="header-value-value">{Formatters.formatCurrencyRoundedCents(description.avg_gross)}</div>
                      <div className="header-value-label">Average profit</div>
                    </div>
                  }
                  {
                    Boolean(description.avg_mileage) &&
                    <div className="header-value-c" style={{width: `${1 / headerValuesCount}%`, position: 'relative'}}>
                      <div className="header-value-value">{Formatters.formatThousands(description.avg_mileage)}</div>
                      <div className="header-value-label">Average mileage</div>
                      {
                        this.props.valuationMileage && this.props.valuationMileage < description.avg_mileage ?
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'lightgreen'}} title='Current vehicle lower than average'>▼</div> : 
                        <div style={{position: 'absolute', top: '3px', right: '7px', color: 'red'}} title='Current vehicle above average'>▲</div>
                      }
                    </div>
                  }
                </div>
              }
              <div className="valuation-subsection">
                <div className="distributions-c">
                  {
                    Boolean(description.mdn_market_day_supply) &&
                    <div className="distribution-col">
                      <div className="distribution-header">
                        <span className="distribution-header-value">{pluralize('day', description.mdn_market_day_supply, true)}</span> median days on lot
                      </div>

                      {
                        mdsGraphData !== null && hasMDSGraphData &&
                        <div style={{flexGrow: 1, flexShrink: 1, height: '46px'}}>
                          <ResponsiveScatterPlot
                            data={mdsGraphData}
                            height={46}
                            margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                            xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            blendMode="multiply"
                            colors={(node) => {
                              if (node.x <= 60) {
                                return 'rgba(2, 177, 252, 0.4)'
                              } else {
                                return 'rgba(255, 10, 10, 0.4)'
                              }

                            }}
                            nodeSize={(node) => {
                              if (node.isIndicator === true) {
                                return 26
                              } else {
                                return 16
                              }
                            }}
                            enableGridX={false}
                            enableGridY={true}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            tooltip={(el) => {
                              return (
                                <div className="graph-tooltip">
                                  <div>{el.node.data.formattedMDS}</div>
                                </div>
                              )
                            }}
                            legends={[]}
                            renderNode={CustomNode}
                            />
                          <div className="graph-x-axis">
                            <div>{pluralize('day', mdsMin, true)}</div>
                            <div>{pluralize('day', mdsMax, true)}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {
                    Boolean(description.avg_price) &&
                    <div className="distribution-col">
                      <div className="distribution-header">
                        <span className="distribution-header-value">{Formatters.formatCurrencyRoundedCents(description.avg_price)}</span> average retail sale price
                      </div>
                      {
                        avgSaleGraphData !== null && hasPricesGraphData &&
                        <div style={{flexGrow: 1, flexShrink: 1, height: '46px'}}>
                          <ResponsiveScatterPlot
                            height={46}
                            data={avgSaleGraphData}
                            margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                            xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            blendMode="multiply"
                            colors='rgba(2, 177, 252, 0.4)'
                            nodeSize={(node) => {
                              if (node.isIndicator === true) {
                                return 26
                              } else {
                                return 16
                              }
                            }}
                            enableGridX={false}
                            enableGridY={true}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            tooltip={(el) => {
                              return (
                                <div className="graph-tooltip">
                                  <div>{el.node.data.formattedPrice}</div>
                                </div>
                              )
                            }}
                            legends={[]}
                            renderNode={CustomNode}
                            />
                          <div className="graph-x-axis">
                            <div>{Formatters.formatCurrencyRoundedCents(avgSaleMin)}</div>
                            <div>{Formatters.formatCurrencyRoundedCents(avgSaleMax)}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>

              </div>

              {this.renderSamples(samples, description.avg_price)}
            </div>
          }
      </ValuationProviderCard>
    )
  }
}



const CustomNode = ({ node, x, y, isIndicator, size, color, blendMode, onMouseEnter, onMouseMove, onMouseLeave, onClick }) => {
    // if (node.isIndicator === true) {
    //     return (
    //         <g transform={`translate(${x},${y})`}>
    //             <circle
    //                 r={size / 2}
    //                 fill={color}
    //                 style={{ mixBlendMode: blendMode }}
    //                 onMouseEnter={onMouseEnter}
    //                 onMouseMove={onMouseMove}
    //                 onMouseLeave={onMouseLeave}
    //                 onClick={onClick}
    //             />
    //         </g>
    //     )
    // }

    if (node.data.isIndicator === true) {
        return (
          <g transform={`translate(${x},${y})`}>
          <rect
                x={size * -0.125}
                y={size * -0.75}
                rx={2}
                ry={2}
                width={size * 0.25}
                height={size * 1.5}
                fill={'#02b1fc'}
                style={{ mixBlendMode: blendMode }}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            />
          </g>
        )
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <circle
                x={size * -0.5}
                y={size * -0.5}
                r={size / 2}
                fill={color}
                style={{ mixBlendMode: blendMode }}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            />
        </g>
    )
}
