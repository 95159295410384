import ReactGA from 'react-ga'

export default {
  platform: 'web',
  localStorageIsSupported: () => localStorageIsSupported(),
  getLocalStorageValue: (keyName) => getLocalStorageValue(keyName),
  setLocalStorageValue: (keyName, keyValue) => setLocalStorageValue(keyName, keyValue),
  removeLocalStorageValue: (keyName) => removeLocalStorageValue(keyName),
  readFile: (path) => readFile(path),
  googleAnalyticsTrackingID: () => "UA-111492234-4",
  isProduction: () => isProduction(),
  logAnalyticsEvent: (category, action) => logAnalyticsEvent(category, action),
  photoURLBase: () => process.env.REACT_APP_ENV_NAME === 'development' ? process.env.REACT_APP_API_ROOT : '',
  sliderStyleProps: () => sliderStyleProps(),
  similarVehiclesSearchLink,
}


function localStorageIsSupported(getStorage) {
  try {
    const checkKey = "__storage_check__";
    setLocalStorageValue(checkKey, checkKey)
    removeLocalStorageValue(checkKey)
    return true;
  } catch (e) {
    console.log("Local storage not supported");
    return false;
  }
}

function getLocalStorageValue(keyName) {
  return new Promise((resolve, reject) => {
    const item = localStorage.getItem(keyName)
    if (item === null) {
      resolve(null)
    }
    resolve(JSON.parse(item))
  })
}

function setLocalStorageValue(keyName, keyValue) {
  try {
    const item = JSON.stringify(keyValue)
    localStorage.setItem(keyName, item)
  } catch (err) {
    console.log("Error persisting ", keyName)
  }
}

function removeLocalStorageValue(keyName) {
  localStorage.removeItem(keyName)
}

function readFile(path) {
  return null
}

function isProduction() {
  return process.env.REACT_APP_ENV_NAME === 'production'
}

function logAnalyticsEvent(category, action) {
  if (isProduction()) {
    ReactGA.event({
      category: category,
      action: action
    })
  } else {
    console.log("Analytics Event: ", category, action);
  }
}

function sliderStyleProps() {
  const sliderStyleProps = {
    dotStyle: {backgroundColor: '#ddd', border: 'none'},
    activeDotStyle: {backgroundColor: '#e95656', border: 'none'},
    handleStyle: [{backgroundColor: '#e95656', border: 'none'}, {backgroundColor: '#e95656', border: 'none'}],
    railStyle: {backgroundColor: '#ddd'},
    trackStyle: [{backgroundColor: '#e95656'}]
  }
  return sliderStyleProps
}

function similarVehiclesSearchLink(year, make, model, trim) {
  let params = []
  if (year) {
    params.push(`year_min=${year}&year_max=${year}`)
  }
  if (make) {
    params.push(`make=${make}`)
  }
  if (model) {
    params.push(`model=${model}`)
  }
  if (trim) {
    params.push(`trim=${trim}`)
  }
  return `/auctions/search/new?${params.join('&')}&location_type=distance&location_radius=100`
}
