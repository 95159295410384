import React from 'react'
import { Link } from 'react-router-dom'
import NewsItem  from '../user/NewsItem'

export default class SettingsLatestNews extends React.Component {
  render() {
    const news = this.props.news
    const hasNewsFeatures = Boolean(news?.items && news.items.length > 0)
    return (
      <div style={{marginBottom: '20px'}}>
        {
          hasNewsFeatures &&
          news.items.map((item, index) => {
            return (
              <div key={`news-item-${index}`} style={{marginBottom: '5px'}}>
                <NewsItem 
                  newsItem={item}
                  index={index} />
              </div>
            )
          })
        }
        
        <div className="view-news">
          {
            !hasNewsFeatures &&
            <div className="no-news-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="news-icon">
                <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              </svg>
            </div>
          }
          <Link to='/settings/news/'>View {hasNewsFeatures ? 'all' : 'Carbly'} news and updates</Link>
        </div>
        
      </div>
    )
    
  }
    

}