import React from 'react'

export default class MagnifyingGlassIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 16
    const height = this.props.height || 16
    const color = this.props.color || '#111'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 16 16" style={{...this.props.style}} className={this.props.className}>
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
      </svg>
    )
  }
}