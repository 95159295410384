import React from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import PaymentForm from './PaymentForm'
import AccountBug from '../user/AccountBug'
import AppConstants from '../../api/constants/AppConstants'
import Spinner from '../common/Spinner'
import OfferingsPicker from '../common/OfferingsPicker'
import ChangeBillingIntervalModal from '../common/ChangeBillingIntervalModal'
import ContractIcon from '../common/icons/ContractIcon'
import ExpandIcon from '../common/icons/ExpandIcon'
import CalculatorBug from '../common/CalculatorBug'


export default class AccountSettings extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    isLoadingAvailableOfferings: true,
    costForSelected: null,
    accountDetails: null,
    availableOfferings: null,
    isShowingChangeBillingIntervalModal: false,
    user: SessionStore.user,
    isShowingChangePaymentMethod: false,
    addOfferingErrors: null,
    costForSelectedOfferingsHasErrors: false
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.userDataChanged)
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()

    // These prices can be stale, especially for yearly plan prorations.
    // Reload if sitting inactive for more than 1 hour
    setTimeout(() => {
      window.location.reload()
    }, 1000 * 60 * 60)
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  userDataChanged = () => {
    const user = SessionStore.user
    if (this.state.isShowingAddPaymentModal) {
      // Free user just successfully added a payment method
      // FIXME: check that there is payment?
      this.setState({
        user: SessionStore.user,
        isShowingAddPaymentModal: false
      })
      this.addOfferings()
    } else {
      this.setState({ user: SessionStore.user })
    }

  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  modifiedSelectedOfferingsDidChange = (modifiedSelectedOfferings, pricingForModifiedSelectedOfferings) => {
    this.setState({
      modifiedSelectedOfferings: modifiedSelectedOfferings
    })
  }

  onCostForSelectedOfferingsLoadingStateChange = (isLoading, hasErrors = false) => {
    this.setState({
      isPickerLoadingCostForSelectedOfferings: isLoading,
      costForSelectedOfferingsHasErrors: hasErrors
    })
  }

  addOfferings = () => {
    const { modifiedSelectedOfferings } = this.state
    UserActionCreators.addOfferings(modifiedSelectedOfferings.map((offering) => offering.sku))
    this.setState({
      isAddingOfferings: true
    })
  }

  offeringsAdded = () => {
    var addOfferingErrors = null
    if (SessionStore.addOfferingsErrorMessage) {
      toast.error(SessionStore.addOfferingsErrorMessage.join(' '))
      addOfferingErrors = SessionStore.addOfferingsErrorMessage.join(' ')
    }

    this.setState({
      isLoadingAccountDetails: true,
      isLoadingAvailableOfferings: true
    })

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  billingIntervalUpdated = () => {
    this.setState({
      isLoadingAccountDetails: true,
      isLoadingAvailableOfferings: true,
      isShowingChangeBillingIntervalModal: false,
      accountDetails: null,
      availableOfferings: null
    })
    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  accountStateLabel = (accountState) => {
    switch (accountState) {
      case 'trialing':
        return 'Trialing'
      case 'active':
        return 'Active'
      case 'past_due':
        return 'Past Due'
      case 'canceled':
        return 'Canceled'
      case 'invalid':
        return 'Invalid'
      default:

    }
  }

  render() {
    if (!this.props.navMatch) { return null }
    const { user, isLoadingAccountDetails, isLoadingAvailableOfferings, accountDetails, availableOfferings, costForSelected, isShowingChangeBillingIntervalModal, isShowingChangePaymentMethod } = this.state
    const account = user.account
    var canModifyAccount = false
    const isBillingIntervalYearly = accountDetails?.billing_interval === 'year'

    if (account.owner === true && account.state !== 'canceled' && account.state !== 'invalid') {
      canModifyAccount = true
    }

    const isLoading = isLoadingAccountDetails || isLoadingAvailableOfferings
    if (isLoading) {
      return (<div className="body-content settings-content-c"><Spinner /></div>)
    }

    if (account.owner === false) {
      return (
        <div style={{marginTop: '20px', marginLeft: '20px', fontWeight: 'bold'}}>If you would like to make changes, please contact the account owner.</div>
      )
    }

    const isDivergent = accountDetails?.divergent_offerings === true
    const isCustomBilling = accountDetails?.custom_billing === true
    const needsPaymentMethod = SessionStore.needsPaymentMethod()
    const hasAccountOfferings = SessionStore.hasAccountOfferings()
    const canGoYearly = !isBillingIntervalYearly && !isDivergent && !isCustomBilling && accountDetails?.can_upgrade_to_yearly !== false && hasAccountOfferings && !needsPaymentMethod


    var nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    return (
      <div className="body-content settings-content-c">
        <div className="content-header">
          <h1>Account Settings</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        Account status: <span style={{fontWeight: 'bold'}}>{this.accountStateLabel(account.state)}</span>

        {
          canGoYearly &&
          <div style={{marginTop: '10px'}}>
            <Button onClick={() => {
              this.setState({
                isShowingChangeBillingIntervalModal: true
              })
            }} color='primary' style={{marginBottom: '20px'}}>Switch to yearly billing and save</Button>

          </div>
        }

        {
          !needsPaymentMethod &&
          <section style={{paddingLeft: '10px', marginBottom: 0, border: '1px solid #ddd', borderRadius: '10px', paddingBottom: 0, paddingTop: 0, marginRight: '20px', backgroundColor: '#fff', marginBottom: '20px'}}>
            <a href="#toggle" onClick={(e) => { e.preventDefault(); this.setState({ isShowingChangePaymentMethod: !isShowingChangePaymentMethod })}} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: '#111'}}>
              <h2>Change Payment Method</h2>
                {
                  isShowingChangePaymentMethod && user?.account?.payment_method !== 'invalid' &&
                  <ContractIcon color='#aaa' />
                }
                {
                  !isShowingChangePaymentMethod && user?.account?.payment_method !== 'invalid' &&
                  <ExpandIcon color='#aaa' />
                }
            </a>

            {
              Boolean(isShowingChangePaymentMethod || account?.state_message !== null || user?.account?.payment_method === 'invalid') &&
              <div style={{margin: '10px 20px'}}>
                {
                  account?.state_message !== null &&
                  <div className="account-state-message">{account.state_message}</div>
                }

                {
                  canModifyAccount &&
                  <PaymentForm />
                }
              </div>
            }
          </section>
        }


        {
          canModifyAccount &&
            <section style={{paddingLeft: 0, marginBottom: 0}}>
            <h2>{availableOfferings.length > 0 ? 'Add ' : ''}Data Providers</h2>
            {
              hasAccountOfferings &&
              <div style={{marginBottom: '10px'}}>Plan: {user.offering_list_message?.length > 0 ? user.offering_list_message : ''}</div>
            }

            {
              !isDivergent && !isCustomBilling && availableOfferings &&  availableOfferings.length > 0 &&
              <div>
                <OfferingsPicker
                  offerings={availableOfferings}
                  accountDetails={accountDetails}
                  onOfferingsAdded={this.offeringsAdded}
                  useTotalPrice={!hasAccountOfferings} />

                <div className="secondary-text" style={{marginTop: '40px', textAlign: 'center'}}>If you need anything, call us at {AppConstants.phoneNumber} or <a href={`mailto: ${AppConstants.supportEmailAddress}`} target='_blank' rel="noopener noreferrer">email us</a>, and we'll be happy to help.</div>
              </div>
            }

            {
              !isDivergent && !isCustomBilling && (!availableOfferings || availableOfferings.length === 0) &&
              <div>There are no options to add for your account</div>
            }

            {
              Boolean(isDivergent || isCustomBilling) &&
              <div>Your account has {isDivergent ? 'users with varying options' : 'custom billing'}.  If you'd like to make a change, please give us a call at {AppConstants.phoneNumber} and we'd be happy to help.</div>
            }
          </section>
        }



        {
          isShowingChangeBillingIntervalModal &&
          <ChangeBillingIntervalModal accountDetails={accountDetails} onCancel={() => {this.setState({ isShowingChangeBillingIntervalModal: false })}} onComplete={this.billingIntervalUpdated} />
        }
      </div>
    )
  }
}
