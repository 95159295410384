import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { PLACE_BID } from '../mutations'
import UserActionCreators from '../../actions/UserActionCreators'

const submitListingBidAudit = (bidType, amount, bidderId, bidId, sourceId, platform) => {
  const auditBidTypeMap = {
    Hard: 'hard',
    BuyNow: 'buy_now',
    Max: 'max',
  }

  UserActionCreators.submitListingBidAudit({
    provider: 'edge',
    channel: 'dot_auction',
    platform,
    bid_type: auditBidTypeMap[bidType],
    source_id: sourceId,
    bidding_id: bidderId,
    source_bid_id: bidId,
    amount,
  })
}

export default (venueListingId, currency, bidderId, platform) => {
  const [_placeBid, state] = useMutation(PLACE_BID)

  const placeBid = useCallback((bidType, amount) => {
    return _placeBid({
      onCompleted: (placeBidData) => {
        submitListingBidAudit(
          bidType,
          amount,
          bidderId,
          placeBidData.placeBid.id,
          venueListingId,
          platform
        )
      },
      variables: {
        input: {
          venueListingId,
          bidAmount: {
            amount,
            currency,
          },
          bidType,
          bidderId,
        }
      }
    })
  }, [_placeBid, venueListingId, bidderId])

  return [placeBid, state]
}
