import React from 'react'
import { Link } from 'react-router-dom'
import ValuationStore from '../api/stores/ValuationStore'
import ValuationActionCreators from '../api/actions/ValuationActionCreators'
import AuctionStore from '../api/stores/AuctionStore'
import AuctionsActionCreators from '../api/actions/AuctionsActionCreators'
import ValuationRowMinimal from '../components/valuations/ValuationRowMinimal'
import AuctionsListingRow from '../components/auctions/AuctionsListingRow'
import AccountBug from '../components/user/AccountBug'
import VINEntryField from '../components/common/VINEntryField'
import CommonFunctions from '../api/constants/CommonFunctions'
import AppConstants from '../api/constants/AppConstants'
import PlusIcon from '../components/common/icons/PlusIcon'
import CalculatorBug from '../components/common/CalculatorBug'


export default class DashboardPage extends React.Component {

  state = {
    homeData: null,
    homeDataRecent: null,
    homeDataFolders: null,
    upcomingVehicles: null,
    watchlistVehicles: null
  }

  // FIXME: endpoints??
  componentDidMount() {
    ValuationStore.on("vehicles_home_change", this.homeDataDidChange)
    AuctionStore.on('auctions_watchlist_received', this.watchlistVehiclesDidLoad)
    AuctionStore.on('auction_vehicles_search_change', this.upcomingVehiclesDidChange)

    AuctionsActionCreators.loadAuctionsWatchlistVehicles()
  }

  componentWillUnmount() {
    ValuationStore.removeListener('vehicles_home_change', this.homeDataDidChange)
    AuctionStore.removeListener('auctions_watchlist_received', this.watchlistVehiclesDidLoad)
    AuctionStore.removeListener('auction_vehicles_search_change', this.upcomingVehiclesDidChange)
  }

  homeDataDidChange = () => {
    const homeData = ValuationStore.vehiclesHomeData
    this.setState({
      homeData: homeData,
      homeDataRecent: homeData.recent,
      homeDataFolders: homeData.folders
    })
  }

  upcomingVehiclesDidChange = () => {
    this.setState({
      upcomingVehicles: AuctionStore.userAuctionUpcomingVehicles ? AuctionStore.userAuctionUpcomingVehicles.slice(0, 8) : null
    })
  }

  watchlistVehiclesDidLoad = () => {
    this.setState({
      watchlistVehicles: AuctionStore.auctionsWatchlist,
    })
  }

  vinChanged = (vin, isVINValid, vinFailedChecksum) => {
    if (vin && isVINValid && !vinFailedChecksum) {
      window.location.href = `/vehicles/new?vin=${vin}`
    }
  }

  render() {
    const { homeDataRecent, homeDataFolders, watchlistVehicles, upcomingVehicles } = this.state

    const hasFolders = homeDataFolders && homeDataFolders.length > 0
    const hasRecentVehicles = homeDataRecent && homeDataRecent.data && homeDataRecent.data.length > 0

    return (
      <div style={{height: '100%'}}>

        <div className="page-body-c dashboard-body-c">
          <div className="body-content">

            <div className="content-header">
              <h1>Dashboard</h1>

              <div className="global-tools">
                <AccountBug />
                <CalculatorBug />
              </div>
            </div>

            <div className="content-context-actions">
              <Link to="/vehicles/new">
                <PlusIcon />
              Add a vehicle</Link> |
              <a href="#new-folder" onClick={(e) => {e.preventDefault(); ValuationStore.folderNew()}}>
                <svg className="bi bi-folder" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z"/>
                  <path fillRule="evenodd" d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"/>
                </svg>
              Create a folder</a>
            </div>



            <div className="dashboard-cards-c">
              <div className="col">

                <div className="card">
                  <h2 className="card-title">Recent Vehicles</h2>
                  <div>
                    {
                      hasRecentVehicles &&
                      homeDataRecent.data.map((valuation) => {
                        return (
                          <ValuationRowMinimal
                            valuation={valuation}
                            clientID={this.clientID}
                            key={`dash-v-${valuation.uuid}`}
                            onDeleteValuation={(valuation) => {
                              this.setState({isLoadingHomeData: true})
                              ValuationActionCreators.updateValuation(valuation.uuid, {state: AppConstants.listingStates.ARCHIVED}, 'home')
                            }} />
                        )
                      })
                    }
                  </div>
                  <div className="card-actions" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className="inline-vin-entry">
                      <VINEntryField ref='vinRef' onVinChanged={this.vinChanged} placeholder="Add vehicle with a VIN" />
                    </div>
                    <Link to='/vehicles'>View all vehicles</Link>
                  </div>
                </div>



                <div className="card">
                  <h2 className="card-title">Auction Watchlist</h2>
                  <div className="dashboard-card-auction-listings">
                  {
                    watchlistVehicles && watchlistVehicles.map((listing) => {
                      return (
                        <AuctionsListingRow listing={listing} showRunNumber={false} showChannel style={{paddingRight: '20px'}} key={`dash-a-${listing.id}`}/>
                      )
                    })
                  }
                  </div>
                  <div className="card-actions">
                    <Link to='/auctions'>View all listings</Link>
                  </div>
                </div>



                <div className="card">
                  <h2 className="card-title">Latest Auction Listings</h2>
                  <div className="dashboard-card-auction-listings">
                  {
                    upcomingVehicles && upcomingVehicles.map((listing) => {
                      return (
                        <AuctionsListingRow listing={listing} showRunNumber={false} showChannel key={`auv-${listing.id}`} style={{paddingRight: '20px'}}/>
                      )
                    })
                  }
                  </div>
                  <div className="card-actions">
                    <Link to='/auctions'>View all listings</Link>
                  </div>
                </div>



              </div>

              <div className="col">

                <div className="card dashboard-card-carfax">
                  <h2>Link your Vehicle History Account</h2>
                  <p>If you'd like to see { AppConstants.providerNames.carfax } or { AppConstants.providerNames.autocheck } reports on your valuations, just add your login information on the settings page.</p>

                  <div className="links-footer">
                    <Link className="action" to='/settings'>
                      <svg className="bi bi-gear-fill icon" width="14px" height="14px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
                      </svg>
                      Open Settings</Link>
                    <a href="#no-carfax" className="hide" onClick={(e) => {e.preventDefault();}}>Hide</a>
                  </div>
                </div>



                <div className="card">
                  <h2 className="card-title">Folders</h2>
                  <div className="dashboard-folders-c">
                    {
                      hasFolders &&
                      homeDataFolders.map((folder) => {
                        return (
                          <Link to={CommonFunctions.folderURL(folder)} className="dashboard-folder" key={`d-f-${folder.id}`}>
                            <svg className="bi bi-folder-fill" width="46px" height="46px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>

                            </svg>
                            <div className="vehicles-count">{folder.vehicles_count}</div>
                            {folder.name}
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>



                <div className="card dashboard-card-mobile-app">
                  <h2>Get the App</h2>
                  <p>Our mobile app has everything you love about Carbly in the browser, plus a text-recognition VIN scanner.  No extra cost.</p>


                  <div className="bottom-section">
                    <img className="mobile-devices" src="/images/dash-card-mobile-devices.png" alt="Devices" />

                    <div className="right-col">
                      <div className="store-links">
                        <a href="https://itunes.apple.com/us/app/carbly/id1326227629?ls=1&mt=8" target="_blank" rel="noopener noreferrer"><img src="/images/dash-card-apple-logo.png" className="apple" alt="Apple App Store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.ambientautomotive.carbly" target="_blank" rel="noopener noreferrer"><img src="/images/dash-card-android-logo.png" className="android" alt="Google Play Store" /></a>
                      </div>

                      <a href="#hide" className="hide" onClick={(e) => {e.preventDefault();}}>Hide</a>
                    </div>


                  </div>


                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
