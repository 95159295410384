import React from 'react'
import { Button } from 'reactstrap'
import pluralize from 'pluralize'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import UserActionCreators from '../../api/actions/UserActionCreators'

const nullFolderSentinelID = -99

export default class FolderAssignment extends React.Component {

  state = {
    isLoadingFolders: true,
    isSaving: false,
    isShowingNewFolder: false,
    newFolderName: '',
    newFolderNameHasConflict: false,
    selectedFolderID: this.props.valuation.vehicle_folder ? this.props.valuation.vehicle_folder.id : null
  }
  componentDidMount() {
    ValuationStore.on("folders_change", this.foldersReceived)
    ValuationStore.on("valuation_folder_updated", this.onSaveComplete)

    ValuationActionCreators.loadFolders()
  }

  componentWillUnmount() {
    ValuationStore.removeListener("folders_change", this.foldersReceived)
    ValuationStore.removeListener("valuation_folder_updated", this.onSaveComplete)
  }

  foldersReceived = () => {
    this.setState({
      isLoadingFolders: false,
      folders: ValuationStore.folders
    })
  }

  onSave = () => {
    const { selectedFolderID } = this.state
    this.setState({
      isSaving: true
    })
    let params = {}
    if (this.state.isShowingNewFolder) {
      params = {vehicle_folder_name: this.state.newFolderName}
    } else {
      params = {vehicle_folder_id: selectedFolderID === nullFolderSentinelID ? null : selectedFolderID}
    }
    UserActionCreators.createMetric(AppConstants.metricNames['vehicle_assign_folder'])
    ValuationActionCreators.updateValuation(this.props.valuation.uuid, params, this.props.clientID)
  }

  onSaveComplete = () => {
    this.props.onDone()
  }

  onNewFolderNameChange = (e) => {
    const text = e.target.value
    let hasConflict = false
    for (let folder of this.state.folders) {
      if (folder.name.toLowerCase() === text.trim().toLowerCase()) {
        hasConflict = true
        break
      }
    }
    this.setState({
      newFolderName: text,
      newFolderNameHasConflict: hasConflict
    })
  }

  renderFolder = (folder, isSelected) => {
    return (
      <a href="#select-folder" onClick={(e) => {e.preventDefault(); this.setState({selectedFolderID: folder ? folder.id : nullFolderSentinelID})}} key={`assign-folder-${folder ? folder.id : nullFolderSentinelID}`} className={`popover-item${isSelected ? ' popover-item-selected' : ''}`}>

          <div className="folder-assignment-folder-c">
            <div className="details">
              <div style={{fontWeight: 'bold', margin: 0, lineHeight: '1.1rem'}}>{folder ? folder.name : 'Remove from folder'}</div>
              <div className="secondary-text">{folder ? pluralize('vehicles', folder.vehicles_count, true) : ' '}</div>
            </div>
            <div className="icon">
              {
                isSelected &&
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                </svg>
              }
            </div>
          </div>


      </a>
    )
  }

  render() {
    const valuation = this.props.valuation
    const hasInitialFolder = valuation.vehicle_folder !== null && valuation.vehicle_folder !== undefined
    const { isLoadingFolders, isSaving, isShowingNewFolder, folders, selectedFolderID, newFolderName, newFolderNameHasConflict } = this.state
    let submitEnabled = selectedFolderID !== null

    if (!isShowingNewFolder && hasInitialFolder && selectedFolderID === valuation.vehicle_folder.id) {
      submitEnabled = false
    } else if (isShowingNewFolder) {
      submitEnabled = newFolderName.trim().length > 0 && newFolderNameHasConflict === false
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: this.props.maxHeight ? `${this.props.maxHeight}px` : 'inherit'}}>
        <div style={{height: '100%', flexGrow: 1, flexShrink: 1, overflow: (isLoadingFolders || isSaving) ? 'hidden' : 'auto', maxHeight: this.props.maxHeight ? `${this.props.maxHeight - 60}px` : 'inherit'}}>
          {
            (isLoadingFolders || isSaving) &&
            <div style={{justifyContent: 'space-between', height: '100%', minHeight: '100px'}}>
              <Spinner />
            </div>
          }
          {
            !isLoadingFolders && !isSaving &&
            <div style={{}}>
              {
                !isShowingNewFolder && hasInitialFolder &&
                this.renderFolder(null, selectedFolderID === nullFolderSentinelID)
              }
              {
                !isShowingNewFolder && folders?.length > 0 &&
                folders.map((folder) => {
                  const isSelected = selectedFolderID && selectedFolderID === folder.id

                  return (
                    this.renderFolder(folder, isSelected)
                  )
                })
              }
              {
                !isShowingNewFolder &&
                <div style={{margin: '10px 0'}}>
                  <a href="#new-folder" onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isShowingNewFolder: true })
                    setTimeout(() => {
                      if (this.refs.newFolderNameRef) {
                        this.refs.newFolderNameRef.focus()
                      }
                    }, 200)
                  }}>Add to a new folder</a>
                </div>
              }
              {
                isShowingNewFolder &&
                <div style={{minHeight: '100px'}}>
                  <form onSubmit={(e) => {
                    e.preventDefault()
                    if (submitEnabled) {
                      this.onSave()
                    }
                    }}>
                    <input type="text" ref='newFolderNameRef' onChange={this.onNewFolderNameChange} value={newFolderName} placeholder="New folder name" className="form-control" style={{marginBottom: 0}} />

                    {
                      newFolderNameHasConflict === true &&
                      <div style={{color: 'red', marginTop: 2}}>A folder with this name already exists</div>
                    }
                  </form>

                </div>
              }
            </div>
          }
        </div>
        {
          !isSaving &&
          <div className='popover-controls' style={{flexGrow: 0, flexShrink: 0}}>
            <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
            <Button color="primary" style={{opacity: submitEnabled ? '1.0' : '0.4'}} onClick={submitEnabled ? this.onSave : null}>Update</Button>
          </div>
        }

      </div>
    )

  }
}
