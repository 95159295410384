import React from 'react'
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js'
import { Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import WebAPIUtils from '../../api/actions/WebAPIUtils'
import RemoteConstants from '../../api/constants/RemoteConstants'
import Spinner from '../common/Spinner'

const HEADERS = {'Accept': 'application/json', 'Content-Type': 'application/json'}

class StripeForm extends React.Component {
  state = {
    isSubmitting: false,
    isRetryingCard: false,
    successMessage: null,
    errorMessage: null
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true })

    const {stripe, elements} = this.props;
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    stripe.createSource(cardElement, {}).then((result) => {
      if (result.error) {
        console.log("STRIPE ERROR: ", result.error);
        this.setState({
          errorMessage: result.error.message,
          successMessage: null,
          isSubmitting: false
        })
      } else {
        const sourceID = result?.source?.id

        // Keeping anything remotely related to Stripe out of JSAPI, so no appearance happens in native code
        fetch(`${RemoteConstants.APIEndpoints().ACCOUNTS}update_cc${WebAPIUtils.authGETQueryString()}`, {
          method: 'PUT',
          headers: HEADERS,
          body: JSON.stringify({
            stripe_source_id: sourceID
          })
        })
        .then(response => {
          return Promise.all([response.status, response.json()])
        })
        .then(([status, json]) => {
          var successMessage = null
          var errorMessage = null
          if (status === 200) {
            successMessage = "Card successfully updated"
            var user = {...json}
            this.updateUserWithSuccess(user)
            cardElement.clear()
          } else {
            console.log("Server ERROR");
            errorMessage = json.errors.join(". ")
          }
          this.setState({
            errorMessage: errorMessage,
            successMessage: successMessage,
            isSubmitting: false
          })
        })
        .catch((error) => {
          console.log("CAUGHT ERRROR", error)
        })
      }
    });
  };

  retryCurrentCard = () => {
    this.setState({ isRetryingCard: true })
    const user = SessionStore.user

    // Keeping anything remotely related to Stripe out of JSAPI, so no appearance happens in native code
    // This is a public route, doesn't need session params
    // FIXME: this is safe to move into the main flow UserActionCreators.retryCurrentCC()
    fetch(`${RemoteConstants.APIEndpoints().ACCOUNTS}${user.uuid}/retry_cc${WebAPIUtils.authGETQueryString()}`, {
      method: 'PUT',
      headers: HEADERS
    })
    .then(response => {
      return Promise.all([response.status])
    })
    .then(([status]) => {
      if (status === 200) {
        this.setState({
          errorMessage: null,
          successMessage: 'Existing card successfully updated.',
          isRetryingCard: false
        })

        this.updateUserWithSuccess(user)
      } else {
        console.log("Server ERROR");
        this.setState({
          errorMessage: 'Attempt to use your current card failed.  Please contact us if you need assistance',
          successMessage: null,
          isRetryingCard: false
        })
      }
    })
    .catch((error) => {
      console.log("CAUGHT ERRROR", error)
    })
  }

  updateUserWithSuccess = (user) => {
    // FIXME: no longer needed to be done externally
    // If the server needs to do a .pay() call, won't have an active account state immediately
    // Assume all went well and get the user active again
    if (user.account?.state === 'past_due' && user.account?.past_grace_period === true ) {
      user['account']['state'] = 'active'
      user['account']['payment_method'] = 'valid'
      user['account']['state_message'] = null
    }
    SessionStore.userUpdatedExternally(user)
  }

  render() {
    const { isSubmitting, isRetryingCard, successMessage, errorMessage } = this.state
    const { stripe } = this.props
    var showRetryCard = SessionStore.user?.account?.state === 'past_due'

    if (isRetryingCard) {
      return ( <Spinner /> )
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={{flexGrow: 1, flexShink: 1, border: '1px solid #ddd', padding: '5px', borderRadius: '6px'}}>
            <CardElement
              options={{
                hidePostalCode: true,
                iconStyle: 'solid',
                style: {
                  base: {
                    fontSize: '18px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }} />
          </div>

          <Button type="submit" disabled={!stripe || isSubmitting} color="primary" style={{height: '40px'}}>{isSubmitting ? <Spinner className="spinner-button" /> : 'Submit'}</Button>

        </form>
        {
          successMessage !== null &&
          <div className="account-success">{successMessage}</div>
        }

        {
          errorMessage !== null &&
          <div className="account-error">{errorMessage}</div>
        }

        {
          showRetryCard &&
          <a href="#retry" onClick={(e) => {e.preventDefault(); this.retryCurrentCard()}} style={{display: 'block', textAlign: 'center', marginTop: '20px', fontSize: '1.2rem', fontWeight: 'bold'}}>Or, retry your existing payment method</a>
        }
      </div>
    )
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <StripeForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};
