export default class DotAuctionEventsCollection {
  constructor(initialCollection = [], errors = null) {
    this.collection = initialCollection
    this.lastPage = initialCollection
    this.errors = errors
    this._hasLastPage = false
  }

  getUniqById(a, idKey) {
    return [
      ...new Map(a.map(x => [x[idKey], x])).values()
    ]
  }

  addToCollection(data, errors) {
    this.lastPage = data
    this.errors = errors
    if (data.length === 0) {
      this._hasLastPage = true
    }
    const nextCollection = [...this.collection]
    data.forEach(parent => {
      const currentParent = nextCollection.find(p => p.id === parent.id)
      if (currentParent) {
        const nextListings = currentParent.listings.concat(parent.listings)
        currentParent.listings = this.getUniqById(nextListings, 'id')
      } else {
        nextCollection.push(parent)
      }
    })
    this.collection = this.getUniqById(nextCollection, 'id')
  }

  hasLastPage() {
    return this.errors && Boolean(this.errors.length) ? true : this._hasLastPage
  }
}
