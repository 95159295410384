import React from 'react'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AppConstants from '../../api/constants/AppConstants'

export default class VINEntryField extends React.Component {
  state = {
    vin: this.props.defaultVIN ? this.props.defaultVIN : '',
    hasValidVIN: false,
    vinFailedChecksum: false
  }

  vinChanged = (text) => {
    var uppercaseVIN = text.toUpperCase()
    const sanitizedVIN = CommonFunctions.sanitizedVIN(uppercaseVIN)
    const isVINValid = CommonFunctions.isVINValid(sanitizedVIN)
    const vinFailedChecksum = isVINValid === true && CommonFunctions.isVINCheckDigitCorrect(sanitizedVIN) === false
    this.setState({
      vin: sanitizedVIN,
      hasValidVIN: isVINValid,
      vinFailedChecksum: vinFailedChecksum
    })
    if (this.props.onVinChanged) {
      this.props.onVinChanged(sanitizedVIN, isVINValid, vinFailedChecksum)
    }
  }

  focus = () => {
    if (this.refs.vinRef) {
      this.refs.vinRef.focus()
    }
  }

  clear = () => {
    this.setState({
      vin: ''
    })
  }

  render() {
    const { vin, vinFailedChecksum } = this.state
    const vinMaxLength = AppConstants.VINLength
    const vinEnteredLength = vin.length

    var charsText = ''
    if (vinFailedChecksum) {
      charsText = (<span style={{color: 'red'}}>Invalid VIN</span>)
    } else if (vinEnteredLength > 0) {
      charsText = `${vinEnteredLength} / ${vinMaxLength}`
    }

    return (
      <div>
        <input ref='vinRef' type="text" className={`field vin${vinFailedChecksum ? ' vin-bad-checksum' : ''}`} name="vin" onChange={e => this.vinChanged(e.target.value)} value={vin} placeholder={this.props.placeholder ? this.props.placeholder : "Enter VIN"} autoFocus={this.props.autoFocus} maxLength="17" style={{marginBottom: 0, ...this.props.style}} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
        <div className="vin-chars-c">
          <div className="secondary-text">{charsText}</div>
          {
            this.props.showDrilldown === true &&
            <div className="browse-link">Don't have a VIN? <a href="#browse" onClick={(e) => {
              e.preventDefault()
              if (this.props.onDrilldown) {
                this.props.onDrilldown()
              }
            }}>Browse vehicles instead</a></div>
          }
        </div>
      </div>
    )
  }
}
