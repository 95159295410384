import React, {useState, useCallback} from 'react'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import ListIcon from '../common/icons/ListIcon'
import GridIcon from '../common/icons/GridIcon'
import AuctionsGlobalFiltersControl from './AuctionsGlobalFiltersControl'
import SessionStore from '../../api/stores/SessionStore'
import AppConstants from '../../api/constants/AppConstants'
import UserActionCreators from '../../api/actions/UserActionCreators'


const AuctionVehiclesDisplayBar = ({onListingsDisplayModeChange, showSort, style, currentSort, onSortChange, defaultDisplayType}) => {
  const [sortDropdownIsOpen, setSortDropdownIsOpen] = useState(false)
  const [listingsDisplayMode, setListingDisplayMode] = useState(defaultDisplayType)

  const toggleListingsDisplayMode = () => {
    const newListingsDisplayMode = listingsDisplayMode === 'grid' ? 'row' : 'grid'

    
    setListingDisplayMode(newListingsDisplayMode)
    if (onListingsDisplayModeChange) {
      onListingsDisplayModeChange(newListingsDisplayMode)
    }
  }

  const setSort = useCallback((sort) => {
    if (currentSort !== sort) {
      onSortChange(sort)
    }
  }, [currentSort])

  const dropdownItemForSort = useCallback((sort, enabled = true) => {
    return (
      <DropdownItem active={currentSort === sort} onClick={() => setSort(sort)} disabled={!enabled}>
        {AppConstants.sortParams[sort]}
      </DropdownItem>
    )
  })

  const hasAuctionsPlus = SessionStore.hasAuctionsPlus()


  return (
    <div className='split-row' style={{padding: '10px 20px', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'transparent', borderTop: '1px solid #dbdde3', ...style}}>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          showSort &&
          <>
            Sort by
            <Dropdown size="sm" group isOpen={sortDropdownIsOpen} toggle={() => setSortDropdownIsOpen(!sortDropdownIsOpen)} value={currentSort}>
              <DropdownToggle tag="div" caret className='dropdown-contained'>
                { AppConstants.sortParams[currentSort] }
              </DropdownToggle>
              <DropdownMenu>
                { dropdownItemForSort('start_time ASC') }
                { dropdownItemForSort('start_time DESC') }

                <DropdownItem divider />
                
                { dropdownItemForSort('created_at DESC') }
                { dropdownItemForSort('created_at ASC') }

                <DropdownItem divider />

                { dropdownItemForSort('condition_grade ASC') }
                { dropdownItemForSort('condition_grade DESC') }
                

                <DropdownItem divider />

                { dropdownItemForSort('mileage ASC') }
                { dropdownItemForSort('mileage DESC') }

                <DropdownItem divider />

                { dropdownItemForSort('year ASC') }
                { dropdownItemForSort('year DESC') }

                <DropdownItem divider />

                { dropdownItemForSort('make ASC') }
                { dropdownItemForSort('make DESC') }

                <DropdownItem divider />

                { dropdownItemForSort('wholesale_value_cents ASC', hasAuctionsPlus) }
                { dropdownItemForSort('wholesale_value_cents DESC', hasAuctionsPlus) }

                <DropdownItem divider />

                { dropdownItemForSort('retail_value_cents ASC', hasAuctionsPlus) }
                { dropdownItemForSort('retail_value_cents DESC', hasAuctionsPlus) }

                <DropdownItem divider />

                { dropdownItemForSort('profit_value DESC', hasAuctionsPlus) }
              </DropdownMenu>
            </Dropdown>
          </>
        }
      </div>

      <div className='split-row split-row-right'>
        <div className='auction-listings-display-bar-global-filters'>
          <AuctionsGlobalFiltersControl />
        </div>
        <a href="#switch-view" onClick={(e) => {
            e.preventDefault()
            toggleListingsDisplayMode()
          }} style={{marginLeft: '30px'}}>
          {
            listingsDisplayMode === 'grid' ? <ListIcon /> : <GridIcon />
          }
        </a>
      </div>
      
    </div>
  )

}

export default AuctionVehiclesDisplayBar