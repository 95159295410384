const storageCount = 8

export default class LimitedCollection {
  constructor() {
    this.collection = []
  }

  addToCollection(id, data) {
    this.removeItem(id)

    let newArray = [...this.collection]
    newArray.unshift({id: id, data: data})

    this.collection = newArray
    this.garbageCollect()
  }

  garbageCollect() {
    if (this.collection.length > storageCount) {
      let newCollection = [...this.collection]
      newCollection.splice(storageCount)
      this.collection = newCollection
    }
  }

  dataWithID(id) {
    let dataMatch = this.collection.find(l => l.id === id)
    if (dataMatch && dataMatch.data) {
      return dataMatch.data
    }
    return null
  }

  removeItem(id) {
    let col = this.collection
    for (let i = 0; i < col.length; i++) {
      if (col[i].id === id) {
        let newArray = [...col]
        newArray.splice(i, 1)
        this.collection = newArray
        break
      }
    }
  }
}
