import React from 'react'

export default class ProviderAuctionsPlusLogo extends React.PureComponent {
  render() {
    const width = this.props.width || 200
    const height = this.props.height || 34

    return (
      <img src="/images/auctions-plus-logo.png" style={{width: width, height: height, ...this.props.style}} />
    )
  }
}
