import { useState, useMemo, useCallback, useEffect } from 'react'

const usePagination = (data, pageSize) => {
  const [page, setPage] = useState(1)

  const numPages = useMemo(() => {
    return Math.ceil(data.length / pageSize)
  }, [data, pageSize])

  const paginatedData = useMemo(() => {
    return data.slice(0, page * pageSize)
  }, [data, page, pageSize])

  const addPage = useCallback(() => {
    setPage(prev => Math.min(prev + 1, numPages))
  }, [numPages])

  const removePage = useCallback(() => {
    setPage(prev => Math.max(prev - 1, 1))
  }, [])

  useEffect(() => {
    setPage(prev => Math.min(prev, Math.max(numPages, 1)))
  }, [numPages])

  return {
    page,
    numPages,
    addPage,
    removePage,
    data: paginatedData,
  }
}

export default usePagination
