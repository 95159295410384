import React from 'react'

export default class MobileAppInstall extends React.Component {

  render () {
    return (
      <section className={this.props.className}>
        <h2>Install the Mobile App</h2>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div>
            <p>Available for iPhone and Android devices, the Carbly mobile app allows you to scan vehicles on the go.</p>
            <p>Scan the QR code to the right with your mobile phone to download the Carbly mobile app.</p>
          </div>
          <img src='/images/app-store-qr.png' style={{width: '130px', height: '130px', boxShadow: '0 4px 16px rgba(0,0,0,0.1)', marginLeft: '30px'}} />
        </div>
      </section>
    )
  }
}