import React from 'react'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import AuctionsSearchRangeSlider from './AuctionsSearchRangeSlider'

export default class AuctionsSearchMileageRangeSlider extends React.Component {

  render() {
    const {defaultMin, defaultMax} = this.props
    return (
      <AuctionsSearchRangeSlider
        defaultMin={defaultMin}
        defaultMax={defaultMax}
        marks={{
          0: '0',
          20000: '20k',
          40000: '40k',
          60000: '60k',
          80000: '80k',
          100000: '100k',
          140000: '140k',
          180000: '180k+'
        }}
        step={1000}
        allowCross={false}
        min={0}
        max={AppConstants.auctionSearchMileageMaxAll}
        {...platformFunctions.sliderStyleProps()}
        onAfterChange={(val) => {
          this.props.onAfterChange(val)
        }}
        onChangeFormatter={(valMin, valMax) => (`${Formatters.formatThousands(valMin)} miles to ${Formatters.formatThousands(valMax)}${valMax === AppConstants.auctionSearchMileageMaxAll ? '+' : ''} miles`)} />
    )
  }

}