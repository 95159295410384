import { gql } from '@apollo/client'
import { CURRENCY_VALUE_FIELDS } from './fragments'

const PLACE_BID = gql`
  ${CURRENCY_VALUE_FIELDS}
  mutation PlaceBid($input: ItemBidInput!) {
    placeBid(input: $input) {
      id
      bidderId
      bid {
        ...CurrencyValueFields
      }
    }
  }
`

const CANCEL_MAX_BID = gql`
  ${CURRENCY_VALUE_FIELDS}
  mutation CancelMaxBid($input: CancelMaxBidInput!) {
    cancelMaxBid(input: $input) {
      id
      bidderId
      bid {
        ...CurrencyValueFields
      }
    }
  }
`

export {
  PLACE_BID,
  CANCEL_MAX_BID,
}
