import React from 'react'

export default class ProviderRealRetailLogo extends React.PureComponent {
  render() {
    const width = this.props.width || 100
    const height = this.props.height || 40
    const color = this.props.color || "#111"

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 269.51 50.41" style={{...this.props.style}}>
        <path fill="#db8806" d="M9.43,0c3.67,0,9-.21,12.39,4a13.79,13.79,0,0,1,2.66,8.79v3.81a12.68,12.68,0,0,1-2.66,8A10.11,10.11,0,0,1,18,27.15l7.92,23.26H18.43l-7-21.75H7.2V50.41H0V0ZM7.2,22.18h4.11a6.08,6.08,0,0,0,4.46-1.66c1.44-1.36,1.51-2.59,1.51-4.53v-3.6a6.74,6.74,0,0,0-1.65-4.61c-1.44-1.44-3.17-1.3-4.69-1.3H7.2ZM31.68,0H53.06V6.84H38.88V21.68H51.41v6.48H38.88V43.57h14.4v6.84H31.68ZM77.33,50.41,75,39.61H65.74l-2.38,10.8h-7.2L67.18,0h6.33l11,50.41ZM70.2,14,66.46,33.2h7.63ZM96.41,0V43.57h15.26v6.84H89.21V0Z"/>
        <path fill="#222" d="M128.1,0c3.67,0,9-.21,12.39,4a13.79,13.79,0,0,1,2.66,8.79v3.81a12.68,12.68,0,0,1-2.66,8,10.11,10.11,0,0,1-3.82,2.59l7.92,23.26H137.1l-7-21.75h-4.25V50.41h-7.2V0Zm-2.23,22.18H130a6.09,6.09,0,0,0,4.47-1.66C135.88,19.16,136,17.93,136,16v-3.6a6.7,6.7,0,0,0-1.66-4.61c-1.44-1.44-3.16-1.3-4.68-1.3h-3.74ZM150.35,0h21.38V6.84H157.55V21.68h12.53v6.48H157.55V43.57H172v6.84h-21.6ZM200.6,0V6.84h-8.92V50.41h-7.2V6.84h-8.93V0Zm19.08,50.41-2.37-10.8h-9.22l-2.37,10.8h-7.21L209.53,0h6.34l11,50.41ZM212.56,14,208.81,33.2h7.63Zm26.2-14V50.41h-7.2V0Zm15.48,0V43.57h15.27v6.84H247V0Z"/>
      </svg>
    )
  }
}
