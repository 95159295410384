import React, { memo, useMemo } from 'react'
import Avatar from './Avatar'

const UserProfile = ({ user, className }) => {
  const { full_name } = user
  const _className = useMemo(() => {
    return [
      'user-profile',
      'flex-row-and-cols',
      'ai-c',
      'no-select',
      'overflow-hidden',
      className,
    ].filter(Boolean).join(' ')
  }, [className])

  return (
    <div className={_className}>
      <div>
        <Avatar user={user} />
      </div>
      <div className='text-ellipsis text-nowrap'>
        {full_name || 'User'}
      </div>
    </div>
  )
}

export default memo(UserProfile)
