import { gql } from '@apollo/client'
import { VENUE_LISTING_PRIVATE_FIELDS, VENUE_LISTING_PUBLIC_FIELDS, ITEM_BID_FIELDS } from './fragments'

const UPDATE_VENUE_LISTING_PUBLIC_CACHE = gql`
  ${VENUE_LISTING_PUBLIC_FIELDS}
  query WriteVenueListingPublic($id: ID!) {
    venueListing(id: $id) {
      ...VenueListingPublicFields
    }
  }
`

const UPDATE_VENUE_LISTING_PRIVATE_CACHE = gql`
  ${VENUE_LISTING_PRIVATE_FIELDS}
  query WriteVenueListingPrivate($id: ID!) {
    venueListing(id: $id) {
      ...VenueListingPrivateFields
    }
  }
`

const UPDATE_VENUE_LISTING_MY_ACTIVE_BID_CACHE = gql`
  ${ITEM_BID_FIELDS}
  query WriteVenueListingMyActiveBid($id: ID!) {
    venueListing(id: $id) {
      myActiveBid {
        ...ItemBidFields
      }
      myFailedBid {
        ...ItemBidFields
      }
    }
  }
`

export {
  UPDATE_VENUE_LISTING_PUBLIC_CACHE,
  UPDATE_VENUE_LISTING_PRIVATE_CACHE,
  UPDATE_VENUE_LISTING_MY_ACTIVE_BID_CACHE,
}
