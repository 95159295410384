import React from 'react'
import { Link } from 'react-router-dom'
import SessionStore from '../../api/stores/SessionStore'
import VINEntryField from '../common/VINEntryField'
import AppConstants from '../../api/constants/AppConstants'
import CarblyLogo from '../common/icons/carbly-logo'
import CarIcon from '../common/icons/CarIcon'
import AuctionLocationIcon from '../common/icons/AuctionLocationIcon'
import GearIcon from '../common/icons/GearIcon'
import MobileAppInstall from '../common/MobileAppInstall'


export default class NoVehicles extends React.PureComponent {
  clearVIN = () => {
    if (this.refs.vinRef) {
      this.refs.vinRef.clear()
    }
  }
  

  render() {
    const { accountStatusRestricted } = this.props

    return (
      <div className="no-vehicles-home">
        <section>
          <CarblyLogo />
          <h2>Welcome</h2>
          <div>Carbly is a modern vehicle appraisal and VIN Scanner tool, providing the market data and vehicle history you need to make more money buying and selling cars.</div>
        </section>

        <MobileAppInstall />

        <section>
          <h2 style={{marginBottom: '10px'}}>Let's Get Started</h2>

          <div className='get-started' style={{position: 'relative'}}>
            {
              accountStatusRestricted !== true &&
              <div className='enter-vin'>
                <CarIcon color='#000' style={{position: 'absolute', bottom: -50, right: -40, zIndex: 0, opacity: 0.05}} width={190} height={190} />
                
                <div style={{fontWeight: 'bold', marginBottom: '5px', position: 'inherit', fontSize: '1.1rem'}}>Get vehicle pricing by VIN</div>
                <div className="inline-vin-entry" style={{position: 'inherit'}}>
                  <VINEntryField ref='vinRef' onVinChanged={this.props.onVinChanged} placeholder='Type or paste a VIN here' style={{opacity: 0.6}} />
                  <div className='secondary-text'>Don't have a VIN handy?</div>
                  <Link to="/vehicles/new?vin=19XFB2F86FE080823&mileage=39000" className="new">View a sample vehicle</Link>
                </div>
              </div>
            }

            <Link to='/auctions'>
              <AuctionLocationIcon color='#000' style={{position: 'absolute', bottom: -70, right: -30, zIndex: 0, opacity: 0.05}} width={190} height={190} />
              
              <div style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '1.1rem'}}>Browse Auction Listings</div>
              <div>See what's for sale near you from Manheim, {AppConstants.edgePipelineName} and SmartAuction.</div>
            </Link>

            <Link to='/settings'>
              <GearIcon color='#000' style={{position: 'absolute', bottom: -70, right: -50, zIndex: 0, opacity: 0.05}} width={190} height={190} />
              
              <div style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '1.1rem'}}>Setup Vehicle History Reports</div>
              <div>Link your {AppConstants.providerNames.carfax} or {AppConstants.providerNames.autocheck} accounts on the Settings Tab.</div>
            </Link>
          </div>
        </section>

        {
          !SessionStore.hasAccountOfferings() &&
          <section style={{display: 'flex', flexDirection: 'row', marginTop: '120px'}}>
            <img src="/images/carbly-website-thumbnail.jpg" alt="Carbly website" style={{width: '320px', height: '203px', borderRadius: '10px', boxShadow: '0 4px 16px rgba(0,0,0,0.1)', marginRight: '20px'}} />

            <div>
              <div style={{fontWeight: 'bold'}}>Need to learn more about what Carbly can do?</div>
              <div style={{marginBottom: '20px', maxWidth: '530px'}}>Visit <a href="https://getcarbly.com" target="_blank">getcarbly.com</a> to learn about Carbly's vehicle pricing data, auction sourcing capabilities, team collaboration tools, mobile VIN scanner, and much, much more.</div>
              <div>Have questions?  Give us a call at {AppConstants.phoneNumber} and we'd be happy to help.</div>
            </div>
          </section>
        }

        {
          !SessionStore.hasAccountOfferings() &&
          <section style={{marginTop: '20px'}}>
            <div style={{marginBottom: '20px'}}>
              <h2>Accurate and Fast Vehicle Appraisals</h2>
              Get market data (auction, wholesale and retail) from trusted sources like {AppConstants.providerNames.manheim}, {AppConstants.providerNames.black_book}, {AppConstants.providerNames.nada}, {AppConstants.providerNames.kbb} and {AppConstants.providerNames.galves}. Vehicle history reports from providers, such as {AppConstants.providerNames.carfax} and {AppConstants.providerNames.autocheck} (requires an existing account).
            </div>

            <div style={{marginBottom: '20px'}}>
              <h2>Manheim, SmartAuction & EDGE Pipeline Auctions</h2>
              Browse the lanes and quickly appraise vehicles at all Manheim, SmartAuction and EDGE Pipeline auction locations across the US, included free with all Carbly plans. Save interesting vehicles for later and view Carbly appraisal data in the auction listing. Make smarter and faster decisions at the auction.
            </div>

            <div style={{marginBottom: '20px'}}>
              <h2>Automatic Trim and Options Selection</h2>
              We've partnered with J.D. Power to implement their new VIN Precision+ data, which automatically decodes over 125 million vehicles down to the specific trim and pre-selects all appropriate add / deducts.
            </div>

            <div style={{marginBottom: '20px'}}>
              <h2>World Class Text & Barcode VIN Scanner</h2>
              Never type a VIN number again. The Carbly VIN scanner can read barcodes as well as text VINs. No more typing those tedious digits, just point your camera at a VIN and Carbly does the rest. Download the mobile app for <a href="https://itunes.apple.com/us/app/carbly/id1326227629" target="_blank" rel="noopener noreferrer">iPhone</a> or <a href="https://play.google.com/store/apps/details?id=com.ambientautomotive.carbly" target="_blank" rel="noopener noreferrer">Android</a> and get scanning.
            </div>

            <div style={{marginBottom: '20px'}}>
              <h2>Work as a Team</h2>
              Carbly for Teams allows your dealership to manage vehicles and collaborate quickly and easily. Save time and money by keeping everyone in-the-loop.
            </div>
          </section>
        }
      </div>
    )
  }

}
