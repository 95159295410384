import React from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import NoteIcon from '../common/icons/NoteIcon'
import Avatar from '../user/Avatar'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'

export default class ValuationRowNotesPopover extends React.Component {
  state = {
    isLoading: false,
    isShowingNotesPopover: false,
    notes: null
  }

  componentDidMount() {
    this.clientID = `val_prev_notes_${this.props.valuation.uuid}`
    ValuationStore.on(`valuation_notes_received_${this.clientID}`, this.notesReceived)
  }

  componentWillUnmount() {
    const clientID = this.props.clientID
    ValuationStore.removeListener(`valuation_notes_received_${clientID}`, this.notesReceived)
  }

  notesReceived = () => {
    const notes = ValuationStore.valuationPreviewNotes.dataWithID(this.clientID)
    this.setState({
      isLoading: false,
      notes: notes
    })
  }

  toggle = () => {
    const { isShowingNotesPopover } = this.state
    var isLoading = false
    if (!isShowingNotesPopover) {
      isLoading = true
      ValuationActionCreators.loadValuationNotes(this.props.valuation.uuid, this.clientID)
    }
    this.setState({
      isShowingNotesPopover: !isShowingNotesPopover,
      isLoading: isLoading
    })
  }

  render() {
    const { valuation } = this.props
    const notesCount = valuation.vehicle_notes_count
    const { isShowingNotesPopover, notes, isLoading } = this.state

    return (
      <div className="list-row-notes">
        {
          valuation.has_vehicle_notes === true &&
          <div>
            <span title="Notes" onClick={(e) => e.preventDefault()} id={`notes_popover_${valuation.uuid}`}>
              <NoteIcon />
              {
                notesCount && notesCount > 0 &&
                notesCount
              }
            </span>
            <Popover placement="left" isOpen={isShowingNotesPopover} trigger="legacy" target={`notes_popover_${valuation.uuid}`} toggle={this.toggle}>
              <PopoverHeader>Vehicle Notes
                <a href="#close" type="button" className="close" onClick={(e) => { e.preventDefault(); this.setState({ isShowingNotesPopover: false })}} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </a>
              </PopoverHeader>
              <PopoverBody className='popover-med' style={{maxHeight: '400px'}}>
                {
                  isLoading &&
                  <Spinner />
                }
                {
                  !isLoading && notes &&
                  <div className="valuation-preview-notes" >
                  {
                    notes.map((note, index) => {
                      return (
                        <div className="note" key={`pop-note-${index}`}>
                          <div style={{marginRight: '5px'}}>
                            <Avatar
                              user={note.user}
                              size={AppConstants.avatarSizes.small}
                            />
                          </div>
                          <div>
                            {note.note}
                            <div className="secondary-text">{Formatters.formatVerboseDate(note.created)}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                  </div>
                }
              </PopoverBody>
            </Popover>
          </div>

        }

      </div>
    )
  }


}
