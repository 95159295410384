export default class PaginatedCollection {
  constructor(data = {}, errors = null, orderAttribute, idKey = 'id') {
    const { data: initialCollection = [], pagination_count } = data
    this.collection = initialCollection
    this.lastPage = initialCollection
    this.paginationCount = pagination_count ?? -1
    this.errors = errors
    this.idKey = idKey
    this.orderAttribute = orderAttribute
    this.cutoff = null
  }

  getUniqById(a) {
    return [
      ...new Map(a.map(x => [x[this.idKey], x])).values()
    ]
  }

  setLastPage({ data, pagination_count }, errors) {
    this.errors = errors
    this.lastPage = data
    this.paginationCount = pagination_count
    const newCollection = [
      ...this.collection,
      ...data
    ]
    this.collection = this.getUniqById(newCollection)
  }

  getCutoff() {
    return this.collection?.length ?
      this.collection[this.collection.length - 1][this.orderAttribute] : null
  }

  hasLastPage() {
    return this.errors && Boolean(this.errors.length) ?
      true :
      this.lastPage.length < this.paginationCount
  }
}
