import React from 'react'
import TFSelect from './TFSelect'
import Formatters from '../../api/constants/Formatters'

export default class TFMinMaxSelectors extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      possibleValues: props.possibleValues.map((x) => { return { value: x.value, label: x.label, originalLabel: x.label } }),
      selectedMinValue: this.props.defaultMinValue,
      selectedMaxValue: this.props.defaultMaxValue
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultMinValue !== this.props.defaultMinValue || prevProps.defaultMaxValue !== this.props.defaultMaxValue) {
      this.setState({
        selectedMinValue: this.props.defaultMinValue,
        selectedMaxValue: this.props.defaultMaxValue
      })
    }
  }


  minValueWasSelected = (value) => {
    const minValue = value !== null ? value.value : null
    this.setState({ selectedMinValue: minValue })
    if (this.props.onChange) {
      setTimeout(() => {
        this.props.onChange({ minValue: minValue, maxValue: this.state.selectedMaxValue})
      })
    }
  }

  maxValueWasSelected = (value) => {
    const maxValue = value !== null ? value.value : null
    this.setState({ selectedMaxValue: maxValue})
    if (this.props.onChange) {
      setTimeout(() => {
        this.props.onChange({ minValue: this.state.selectedMinValue, maxValue: maxValue})
      })
    }
  }

  labelForValue = (value) => {
    const { possibleValues } = this.state
    const selectedValue = possibleValues.find((y) => y.value === value)
    if (selectedValue) {
      // Label could have been modified by the counts thus we need to use the original label
      return selectedValue.originalLabel
    } else {
      return value
    }
  }
  

  render() {
    const { possibleValues, selectedMinValue, selectedMaxValue } = this.state
    const { minValueCounts, maxValueCounts } = this.props

    var availableMinValues = [...possibleValues]
    if (selectedMaxValue) {
      availableMinValues = availableMinValues.filter((y) => y.value <= selectedMaxValue)
    }
    availableMinValues.unshift({label: 'No min', value: null})
    if (minValueCounts) {
      for (let minValue of availableMinValues) {
        // FIXME: the intent here is that the selected item doesn't show counts, but the others do
        // updates don't seem to be working on react-select
        if (minValue.value in minValueCounts) {
          if (minValue.value !== selectedMinValue) {
            let html = `<div style="display: flex; flex-direction: row; justify-content: space-between;">${this.labelForValue(minValue.value)} <div style="color: #888; font-size: 0.9rem; padding-left: 10px;">${Formatters.formatThousands(minValueCounts[minValue.value])}</div></div>`
            minValue.label = <span dangerouslySetInnerHTML={{ __html: html }} />
          }
        }
      }
    }

    var availableMaxValues = [...possibleValues]
    if (selectedMinValue) {
      availableMaxValues = availableMaxValues.filter((y) => y.value >= selectedMinValue)
    }
    availableMaxValues.unshift({label: 'No max', value: null})

    return (
      <div className="split-row" style={{marginTop: '5px'}}>
        <div>
          {
            this.props.minHeading &&
            <div className='secondary-text'>{this.props.minHeading}</div>
          }
          
          <TFSelect
            name="minValue"
            value={selectedMinValue ? {label: this.labelForValue(selectedMinValue), value: selectedMinValue} : null}
            onChange={this.minValueWasSelected}
            placeholder={this.props.minPlaceholder || 'No min'}
            controlWidth={this.props.controlWidth}
            options={availableMinValues} />
        </div>

        <div>{this.props.dividerLabel || 'to'}</div>

        <div>
          {
            this.props.maxHeading &&
            <div className='secondary-text'>{this.props.maxHeading}</div>
          }
          
          <TFSelect
            name="maxValue"
            value={selectedMaxValue ? {label: this.labelForValue(selectedMaxValue), value: selectedMaxValue} : null}
            onChange={this.maxValueWasSelected}
            placeholder={this.props.maxPlaceholder || 'No max'}
            controlWidth={this.props.controlWidth}
            options={availableMaxValues} />
        </div>
      </div>
    )
  }

}


