import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

export default {

  submitLogin: function(email, password, platform, version) {
    TFDispatcher.dispatchAction(ActionTypes.LOGIN_REQUEST, WebAPIUtils.submitLogin(email, password, platform, version) )
  },

  submitForgotPassword: function(email) {
    TFDispatcher.dispatchAction(ActionTypes.FORGOT_PASSWORD_REQUEST, WebAPIUtils.submitForgotPassword(email) )
  },

  setNewPassword: function(token, newPassword) {
    TFDispatcher.dispatchAction(ActionTypes.SET_PASSWORD_REQUEST, WebAPIUtils.setNewPassword(token, newPassword) )
  },

  logout: function() {
    TFDispatcher.dispatchAction(ActionTypes.RELINQUISH_CONTROL_REQUEST, WebAPIUtils.relinquishControl() )
    setTimeout(() => TFDispatcher.dispatchAction(ActionTypes.LOGOUT ), 100)
  },

  refreshUser: function() {
    TFDispatcher.dispatchAction(ActionTypes.USER_REFRESH_REQUEST, WebAPIUtils.refreshUser() )
  },

  heartbeat: function(platform, version) {
    TFDispatcher.dispatchAction(ActionTypes.HEARTBEAT_REQUEST, WebAPIUtils.heartbeat(platform, version) )
  },

  reclaimControl: function() {
    TFDispatcher.dispatchAction(ActionTypes.RECLAIM_CONTROL_REQUEST, WebAPIUtils.reclaimControl() )
  },

  checkForNews: function() {
    TFDispatcher.dispatchAction(ActionTypes.CHECK_NEWS, WebAPIUtils.checkNews() )
  },

  loginWithApolloCampaignContactID: function(apolloCampaignContactID, serverParams) {
    TFDispatcher.dispatchAction(ActionTypes.LOGIN_WITH_APOLLO_CAMPAIGN_CONTACT_ID, WebAPIUtils.loginWithApolloCampaignContactID(apolloCampaignContactID, serverParams) )
  },

  verifyApolloCampaignContactID: function(apolloCampaignContactID) {
    TFDispatcher.dispatchAction(ActionTypes.VERIFY_APOLLO_CAMPAIGN_CONTACT_ID, WebAPIUtils.verifyApolloCampaignContactID(apolloCampaignContactID) )
  },

  registerWithPartnerOriginCode: function(originCode, userParams) {
    TFDispatcher.dispatchAction(ActionTypes.REGISTER_WITH_PARTNER_ORIGIN_CODE, WebAPIUtils.registerWithPartnerOriginCode(originCode, userParams) )
  }

}
