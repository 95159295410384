import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
import platformFunctions from '../../platformFunctions'
var ActionTypes = RemoteConstants.ActionTypes

export default {
  loadBaseCorpus: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VDS_BASE_CORPUS, () => {WebAPIUtils.loadVDSBaseCorpus()} )
  },
  loadMakesCorpus: function(makes) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VDS_MAKES_CORPUS, () => {WebAPIUtils.loadVDSMakesCorpus(makes)} )
  }
}