import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'

export default class ValuationPartners extends React.PureComponent {
  state = {
    isLinking: false,
    updatedPartners: null
  }

  componentDidMount() {
    ValuationStore.on(`vehicle_partners_change_${this.props.clientID}`, this.partnersDidChange)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`vehicle_partners_change_${this.props.clientID}`, this.partnersDidChange)
  }

  partnersDidChange = () => {
    this.setState({
      updatedPartners: ValuationStore.valuationPartnerActions.dataWithID(this.props.clientID),
      isLinking: false
    })
  }

  render() {
    const valuation = this.props.valuation
    const partners = this.state.updatedPartners !== null ? this.state.updatedPartners : valuation.partners
    const isLinking = this.state.isLinking

    if (partners.errors) {
      return ( <div className="valuation-partners">{partners.errors.join('. ')}</div> )
    }

    return (
      <div className="valuation-partners">
        {
          isLinking &&
          <div>Linking...</div>
        }
        {
          !isLinking &&
          partners.map((partner) => {
            const partnerCode = partner.code
            if (partner.linkable === false) { return null }
            if (partner.linked === false) {
              return (
                <a href="#add-to-partner" key={`partner-${partnerCode}`}
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isLinking: true })
                    ValuationActionCreators.linkValuationOnPartner(valuation.uuid, partnerCode, this.props.clientID)
                  }}
                  >
                  <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cloud-upload" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                    <path fillRule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                  </svg>
                  Add to {partner.name}</a>
              )
            }
            if (partner.linked === true && partner.deep_link) {
              return (
                <a href={partner.deep_link} target="_blank" rel="noopener noreferrer" key={`partner-${partnerCode}`}>
                  <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-reply-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L9.079 4.1A.716.716 0 0 0 8 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z"/>
                  </svg>
                  Open in {partner.name}
                </a>
              )
            }
            if (partner.linked === true && !partner.deep_link) {
              return (
                <div key={`partner-${partnerCode}`}>Created in {partner.name}</div>
              )
            }
            return null
          })
        }
      </div>
    )
  }

}
