import React from 'react'
import { Modal, ModalBody, Button } from 'reactstrap'
import UserActionCreators from '../../api/actions/UserActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'

export default class AutoCheckPermissionsModal extends React.Component {
  state = {
    modifiedTeam: [...SessionStore.user?.rooftop?.team],
    isSaving: false,
    saveComplete: false,
    hasChanged: false
  }

  componentDidMount() {
    SessionStore.on("user_permissions_changed", this.permissionsChanged)
    platformFunctions.logAnalyticsEvent("User", "Show Purchase Permissions")
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_permissions_changed", this.permissionsChanged)
    
  }

  permissionsChanged = () => {
    this.setState({
      saveComplete: true,
      isSaving: false
    })

    setTimeout(() => {
      if (this.props.onDone) {
        this.props.onDone()
      }
    }, 2000)
  }

  updatePermissions = () => {
    const { modifiedTeam } = this.state
    const submittableUsers = modifiedTeam.filter((u) => u.uuid !== SessionStore.user.uuid)
    const serverParams = submittableUsers.map((u) => (
      {user_id: u.uuid, permissions: { make_purchases: u.permissions.make_purchases}}
    ))

    this.setState({ isSaving: true })
    UserActionCreators.updatePermissions({permissions: serverParams})
  }

  togglePermission = (user) => {
    const { modifiedTeam } = this.state
    let updatedTeam = [...modifiedTeam]
    for (let u of updatedTeam) {
      if (u.uuid === user.uuid) {
        u.permissions.make_purchases = !u.permissions.make_purchases
      }
    }
    this.setState({
      modifiedTeam: updatedTeam,
      hasChanged: true
    })
  }

  render() {
    const { isSaving, saveComplete, hasChanged, modifiedTeam } = this.state
    const user =  SessionStore.user

    return(
      <Modal centered={true} isOpen={true} contentClassName='modal-c'>
        <ModalBody>
          {
            isSaving &&
            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Spinner />
            </div>
          }
          {
            saveComplete && !isSaving &&
            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              Permissions updated
            </div>
          }
          {
            !isSaving && !saveComplete &&
            <div>
              <div style={{marginBottom: '20px'}}>Specify which users are allowed to make {AppConstants.providerNames.autocheck} data purchases. Charges will be applied to the primary account credit card.</div>

              <div style={{overflowX: 'hidden', overflowY: 'auto', maxHeight: '400px', minHeight: '200px'}}>
                {
                  modifiedTeam.map((u) => {
                    if (u.uuid !== user.uuid) {
                      return (
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomColor: '#ddd', borderBottom: '1px solid #ddd'}} key={`perm-${u.uuid}`}>
                          <label style={{flexGrow: 1, flexShrink: 1, textTransform: 'none', fontSize: '16px'}} htmlFor={`chk-${u.uuid}`}>{u.full_name}</label>
                          <div style={{flexGrow: 0, flexShrink: 0, width: '40px', marginTop: '5px'}}>
                            <input type="checkbox" style={{margin: 0, padding: 0}} name={`chk-${u.uuid}`} id={`chk-${u.uuid}`} checked={u.permissions?.make_purchases} onChange={(e) => {
                              this.togglePermission(u)
                            }} />
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>

              
              <div className="modal-controls" style={{marginTop: '20px'}}>
                <Button color="secondary" onClick={this.props.onDone}>Cancel</Button>
                <Button color="primary" disabled={!hasChanged} onClick={this.updatePermissions}>Save</Button>
              </div>
            </div>
          }
        </ModalBody>
      </Modal>
    )
  }

}
