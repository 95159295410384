import React from 'react'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { ResponsiveLine } from '@nivo/line'
import { Modal, ModalBody, Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationProviderCard from './ValuationProviderCard'
import UniverseDistributionGraph from './UniverseDistributionGraph'
import DataTable from './DataTable'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'
import pluralize from 'pluralize'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import LLMSamplesMap from './LLMSamplesMap'
import LLMSampleDetail from './LLMSampleDetail'
import SessionStore from '../../api/stores/SessionStore'
import moment from 'moment'
import OfferingUpsellModal from '../common/OfferingUpsellModal'

const title = AppConstants.providerNames.universe

const sampleColumns = [
  { title: 'Price', accessor: 'price'},
  { title: 'Days on Lot', accessor: 'days_on_lot', width: '180px'},
  { title: 'Mileage', accessor: 'mileage', width: '144px'},
  { title: 'Color', accessor: 'color', width: global.isExtensionMode ? '120px ': '200px', reduced_size: true, hide_overflow: true},
  { title: 'Distance Away', accessor: 'distance', width: '144px'},
  { title: 'VIN', accessor: 'vin', width: '0', display: false}
]

export default class ValuationLLM extends React.Component {
  state = {
    distanceDropdownIsOpen: false,
    distance: 100,
    showAutoSelectedMessage: true,
    sampleDetail: null,
    isLoadingSampleDetail: false,
    isShowingSampleDetailModal: false,
    isLoadingTrimSamples: false,
    selectedTrimForSamples: null,
    trimSamples: null,
    selectedDetailId: null,
    isUpdatingDistance: false,
    isShowingUpsellModal: false
  }

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidMount() {
    ValuationStore.on(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
    ValuationStore.on('llm_sample_detail_change', this.sampleDetailChanged)
    ValuationStore.on('llm_trim_samples_change', this.trimSamplesChanged)

    const dist = this.props.providerData?.description?.distance
    this.setState({
      distance: dist && dist > 0 ? Math.round(dist) : 100
    })
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
    ValuationStore.removeListener('llm_sample_detail_change', this.sampleDetailChanged)
    ValuationStore.removeListener('llm_trim_samples_change', this.trimSamplesChanged)
  }

  componentDidUpdate(prevProps, prevState) {
    const newLLMData = this.props.providerData
    const oldLLMData = prevProps.providerData

    if (newLLMData?.description?.distance && newLLMData.description.distance !== oldLLMData?.description.distance) {
      this.setState({ distance: newLLMData.description.distance })
    }
  }

  valuationDidChange = () => {
    if (this.state.isUpdatingDistance && this.refs.summaryMapRef) {
      setTimeout(() => {
        this.refs.summaryMapRef.zoomToFitMarkers()
      }, 50);
    }

    this.setState({
      isUpdatingDistance: false
    })
  }

  sampleDetailChanged = () => {
    this.setState({
      isLoadingSampleDetail: false,
      sampleDetail: ValuationStore.llmSampleDetail
    })
  }

  trimSamplesChanged = () => {
    this.setState({
      isLoadingTrimSamples: false,
      trimSamples: ValuationStore.llmTrimSamples
    })
  }

  showSampleDetail = (id) => {
    const samples = this.props.providerData?.samples
    var distanceAway = null
    if (samples) {
      const selectedSample = samples.find((s) => s.id === id)
      distanceAway = selectedSample?.distance
    }

    if (id) {
      this.setState({
        selectedDetailId: id,
        isShowingSampleDetailModal: true,
        isLoadingSampleDetail: true,
        selectedDetailDistance: distanceAway
      })
      ValuationActionCreators.loadLLMSampleDetail(id, this.props.clientID)
    }
  }

  formattedSample(sample) {
    return (
      {
        price: {
          display_value: sample.price ? Formatters.formatCurrency(sample.price) : '',
          raw_value: sample.price
        },
        days_on_lot: {
          display_value: sample.days_on_lot ? sample.days_on_lot : '',
          raw_value: sample.days_on_lot
        },
        mileage: {
          display_value: sample.mileage ? Formatters.formatThousands(sample.mileage) : '',
          raw_value: sample.mileage
        },
        color: {
          raw_value: sample.color,
          values: [
            {
              display_value: sample.color
            },
            {
              display_value: sample.interior_color
            }
          ]
        },
        distance: {
          display_value: sample.distance ? `${sample.distance} miles` : '',
          raw_value: sample.distance
        },
        vin: {
          display_value: sample.vin,
          raw_value: sample.vin,
          display: false
        },
        id: sample.id,
      }
    )
  }

  renderDistanceSelector = () => {
    const llmData = this.props.providerData
    const { distanceDropdownIsOpen, distance, isUpdatingDistance, showAutoSelectedMessage } = this.state
    const minSuggestedDistance = llmData?.description?.min_suggested_distance
    const closestDistance = llmData?.description?.closest_distance
    if (isUpdatingDistance) {
      return ( <Spinner className='spinner-medium' style={{width: '32px', height: '32px', margin: '20px auto'}} /> )
    }
    const distanceChoiceValues = ValuationFunctions.llmDistanceChoiceValues(minSuggestedDistance)

    let items = []
    let index = 0
    for (let dist of distanceChoiceValues) {
      if (dist === minSuggestedDistance) {
        items.push(
          <DropdownItem
            key={`llm-ddi-${index}-div`}
            divider></DropdownItem>
        )
      }
      let isDisabled = closestDistance && closestDistance > dist
      items.push(
        <DropdownItem
          stlye={{color: 'red'}}
          key={`llm-ddi-${index}`}
          active={dist === distance}
          disabled={isDisabled}
          onClick={() => {
            this.setState({
              distance: dist,
              isUpdatingDistance: true,
              showAutoSelectedMessage: false
            })
            var serverHash = {}
            serverHash['updated_provider_specific_options'] = {[this.props.providerKey]: {distance: dist}}
            ValuationActionCreators.updateValuation(this.props.vehicleUUID, serverHash, this.props.clientID) }
          } >
          {dist === AppConstants.llmAllRadiusValue ? 'All' : `${dist} miles`}
        </DropdownItem>
      )
      index++
    }

    return (
      <div style={{marginTop: '30px'}}>
        <div>
          <span style={{fontWeight: 'bold'}}>{pluralize('Vehicle', llmData?.description?.transaction_count, true)} within</span>
          {
            ValuationStore.isSharedValuation &&
            <span>{ distance === AppConstants.llmAllRadiusValue ? ' All' : ` ${distance} miles`}</span>
          }
          {
            !ValuationStore.isSharedValuation &&
            <Dropdown size="sm" group isOpen={distanceDropdownIsOpen} toggle={() => this.setState({ distanceDropdownIsOpen: !distanceDropdownIsOpen})} value={distance}>
              <DropdownToggle caret style={{width: '130px', marginRight: 0}} className='dropdown-inline dropdown-contained cursor-pointer'>
                { distance === AppConstants.llmAllRadiusValue ? 'All' : `${distance} miles`}
              </DropdownToggle>
              <DropdownMenu>
                {items}
              </DropdownMenu>
            </Dropdown>
          }

          {
            !ValuationStore.isSharedValuation && llmData?.description?.min_suggested_distance && llmData?.description?.distance && llmData.description.min_suggested_distance === llmData.description.distance && showAutoSelectedMessage &&
            <span className='secondary-text' style={{paddingLeft: '10px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16" style={{marginLeft: '-4px', marginTop: '-2px'}}>
                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
              </svg>
              Distance auto selected</span>
          }
        </div>
        {
          this.hasComputedData() && llmData?.description?.min_suggested_distance && llmData?.description?.distance && llmData.description.min_suggested_distance > llmData.description.distance &&
          <div style={{color: '#b00c0c', border: '1px solid #ddd', padding: '2px 6px', borderRadius: '6px', marginTop: '5px'}}><span style={{fontWeight: 'bold'}}>Warning:</span> low number of vehicles within this radius. For actionable pricing data, select at least {pluralize('mile', llmData.description.min_suggested_distance, true)}.</div>
        }

        {
          this.hasComputedData() && !llmData?.description?.min_suggested_distance && llmData?.description?.distance === AppConstants.llmAllRadiusValue &&
          <div style={{color: '#b00c0c', border: '1px solid #ddd', padding: '2px 6px', borderRadius: '6px', marginTop: '5px'}}><span style={{fontWeight: 'bold'}}>Warning:</span> low number of available comps</div>
        }
      </div>
    )
  }

  renderTrimsBars = (allTrimsData) => {
    const llmData = this.props.providerData
    const description = llmData?.description

    var maxCount = 0
    for (let trim of allTrimsData) {
      maxCount = trim.transaction_count > maxCount ? trim.transaction_count : maxCount
    }

    return (
      <div className='llm-summary-trims-c'>
        <div className='secondary-text' style={{marginBottom: '5px'}}>Trims</div>
          <div className='llm-summary-trims-list'>
            {
              allTrimsData.map((trim, index) => {
                return (
                  <a href="#show-samples"
                    onClick={(e) => {
                      e.preventDefault()
                      if (!trim.selected && !ValuationStore.isSharedValuation) {
                        this.setState({
                          isLoadingTrimSamples: true,
                          isShowingTrimSamples: true,
                          selectedTrimForSamples: trim
                        })
                        ValuationActionCreators.loadLLMTrimSamples(trim.uid, this.state.distance, this.props.vehicleUUID, this.props.clientID)
                      }
                    }}
                    key={`trimrow-${index}`}
                    className={`trim-row ${trim.selected ? 'trim-row-selected' : ''}`}>
                    <div className='trim-row-bar' style={{width: `${(trim.transaction_count / maxCount) * 100}%`}} />
                    <div className='trim-details-c'>
                      <div className='trim-name'>{trim.trim}</div>
                      {
                        !trim.selected &&
                        <div className='trim-avg'>Avg. {Formatters.formatCurrency(trim.selected && description?.avg_price ? description.avg_price : trim.avg_price)} {trim.avg_days_on_lot ? ` | ${pluralize('day', trim.avg_days_on_lot, true)}` : ''}</div>
                      }
                      {
                        !trim.selected &&
                        <div className='trim-count' style={{fontSize: trim.transaction_count > 999 ? '12px' : null}} title={pluralize('vehicle', trim.transaction_count, true)}>{trim.transaction_count}</div>
                      }
                    </div>
                  </a>
                )
              })
            }
          </div>
      </div>
    )
  }

  hasComputedData = () => {
    const llmData = this.props.providerData
    const description = llmData.description
    return description?.mileage_adjusted_price && description?.avg_price
  }

  render() {
    const llmData = this.props.providerData
    if (!llmData) { return null }
    const error = llmData.error
    const description = llmData.description

    const { isShowingSampleDetailModal, isLoadingSampleDetail, sampleDetail, isLoadingTrimSamples, trimSamples, selectedTrimForSamples, selectedDetailDistance, isShowingTrimSamples, isShowingUpsellModal } = this.state

    const bookSKU = AppConstants.skuMappings['universe']

    var samplesData = null
    if (llmData && llmData['samples']) {
      samplesData = llmData['samples'].map((s) => this.formattedSample(s))
    }

    var samplesForTrimData = null
    if (trimSamples && trimSamples['samples']) {
      samplesForTrimData = trimSamples['samples'].map((s) => this.formattedSample(s))
    }

    var noDataMessage = null

    var allTrimsForSelectedYear = null
    var pricesLength = null

    if (!error) {
      allTrimsForSelectedYear = llmData['all_trims_table']
      pricesLength = llmData && llmData['all_prices'] ? llmData['all_prices'].length : 0
    }

    var formattedTrendData = []
    var formattedPoints = []
    const timeline = llmData.historical_data
    var lowestVal = 999999
    if (timeline && timeline.length > 1) {
      for (let pointGroup of timeline) {
        const convertedPrice = Math.round(pointGroup.avg_price)
        if (convertedPrice < lowestVal) { lowestVal = convertedPrice}
        var label
        if (global.isExtensionMode || global.isIFrameMode) {
          label = `-${pointGroup.months_ago} mo`
        } else {
          label = `${pluralize('month', pointGroup.months_ago, true)} ago`
        }

        formattedPoints.push({
          x: pointGroup.months_ago > 0 ? label : 'Today',
          y: convertedPrice
        })
      }

      formattedTrendData.push({
        "id": "Asking Prices",
        "color": "#505d62",
        "data": formattedPoints
      })
    }

    return (
      <ValuationProviderCard
        providerData={llmData}
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey={this.props.providerKey}
        fullChoices={this.props.fullChoices}
        currentChoiceUID={llmData.description && llmData.description.uid ? llmData.description.uid : null}
        title={title}
        subtitle={description?.display_choice}
        hasError={error ? true : false}
        valuationMileage={this.props.valuationMileage}
        providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
        showAdjustmentMessage={false}
        messages={llmData.messages} >

        {
        !error &&
        <div>
          <div key='llm-data'>
            {
              noDataMessage
            }
            {
              isShowingUpsellModal &&
              <OfferingUpsellModal
                offering={{sku: bookSKU, provider_key: 'universe'}}
                onDone={() => this.setState({ isShowingUpsellModal: false})}
                onPurchase={() => {
                  this.setState({ isShowingUpsellModal: false })
                  if (this.props.onReloadValuation) {
                    this.props.onReloadValuation()
                  } else {
                    window.location.reload()
                  }
                }} />
            }

            {
              noDataMessage === null &&
              <div>
                <div style={{marginTop: '-20px'}}>
                  { this.renderDistanceSelector() }
                </div>


                <div className="valuation-subsection llm-summary">
                  <div className='llm-summary-values-c'>
                    <div className='llm-summary-values'>
                      {
                        this.hasComputedData() &&
                        <div>
                          {
                            description.mileage_adjusted_price &&
                            <div>
                              <div className='secondary-text'>Mileage adjusted price</div>
                              <div style={{fontSize: '1.4rem'}}><strong>{Formatters.formatCurrency(description.mileage_adjusted_price)}</strong></div>
                            </div>
                          }
                          {
                            description.avg_price &&
                            <div>
                              <div className='secondary-text'>Average asking price</div>
                              <div style={{fontSize: '1.4rem'}}><strong>{Formatters.formatCurrency(description.avg_price)}</strong></div>
                            </div>
                          }
                          {
                            description.avg_mileage &&
                            <div>
                              <div className='secondary-text'>Avg mileage</div>
                              <div style={{fontSize: '1.4rem'}}><strong>{Formatters.formatThousands(description.avg_mileage)}</strong></div>
                            </div>
                          }
                          {
                            description.mdn_market_day_supply &&
                            <div>
                              <div className='secondary-text'>Median days on lot</div>
                              <div style={{fontSize: '1.4rem'}}><strong>{description.mdn_market_day_supply}</strong></div>
                            </div>
                          }
                        </div>
                      }

                      {
                        !this.hasComputedData() &&
                        <div style={{color: '#b00c0c'}}>Insufficient comps for actionable data. {description.distance !== AppConstants.llmAllRadiusValue ? 'Select a larger distance.' : null}</div>
                      }
                    </div>
                  </div>

                  <div className='llm-summary-graph-map'>
                    {
                      llmData.all_trims_table && llmData.all_trims_table.length > 1 &&
                      this.renderTrimsBars(llmData.all_trims_table)
                    }

                    <div className={`llm-summary-map-c ${!llmData.all_trims_table || llmData.all_trims_table.length === 0 ? 'llm-summary-map-c-wide' : ''}`}>
                      <LLMSamplesMap ref='summaryMapRef' samples={llmData?.samples} onClick={this.showSampleDetail} showEnlarge onEnlarge={() => {
                        this.setState({ isShowingSampleDetailModal: true})
                      }} />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          {
            noDataMessage === null && pricesLength > 8 &&
            <div className="valuation-subsection">
              <h3 className="valuation-subsection-title">Distribution of local asking prices</h3>

              <UniverseDistributionGraph
                data={llmData}
                dataKey='price' />
            </div>
          }

          {
            noDataMessage === null && formattedTrendData &&
            <div className="valuation-subsection">
              <h3 className="valuation-subsection-title">National Asking Price History</h3>
                <div className="trend-graph">
                  <ResponsiveLine
                    data={formattedTrendData}
                    margin={{ top: 30, right: 40, bottom: 26, left: 40 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: false, min: lowestVal - (lowestVal * 0.05), max: 'auto' }}
                    yFormat={(node) => {
                      return Formatters.formatCurrency(node)
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 2,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: null,
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={null}
                    enableGridY={false}
                    colors={(node => {
                      return node.color;
                    })}
                    lineWidth={5}
                    pointSize={12}

                    pointBorderWidth={3}
                    pointBorderColor={{ from: 'serieColor' }}
                    enablePointLabel={true}
                    pointLabel="yFormatted"
                    pointLabelYOffset={-12}
                    areaBaselineValue={130}
                    useMesh={true}
                    tooltip={(el) => {
                      return (
                        <div className="graph-tooltip">
                          <div>{el.point.data.xFormatted}</div>
                          <div>{el.point.data.yFormatted}</div>
                        </div>
                      )
                    }}

                  />

              </div>
            </div>
          }

          {
            llmData && samplesData === null &&
            <div className="valuation-subsection">No Local Comps</div>
          }

          {
            llmData && samplesData !== null &&
            <div className="valuation-subsection">
              <h3 className="valuation-subsection-title">Local comps with similar trim</h3>
              <DataTable
                columns={sampleColumns}
                data={samplesData}
                providerKey='universe'
                defaultSort='distance'
                valuationMileage={this.props.valuationMileage}
                onClick={this.showSampleDetail}
                onClickDataKey='id'
                isClickable={!ValuationStore.isSharedValuation}
                icon={<MagnifyingGlassIcon color='#888' />}
                highlightVIN
                currentVIN={this.props.vin}
                paginationCount={50}
                />
            </div>
          }
        </div>
      }

      {
        isShowingSampleDetailModal &&
        <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '400px', maxWidth: '1000px'}} backdrop={true} toggle={() => {this.setState({ isShowingSampleDetailModal: false })}}>
          <ModalBody>
            <div>
              {
                isLoadingSampleDetail &&
                <div style={{minHeight: '280px'}}>
                  <Spinner />
                </div>
              }

              {
                !isLoadingSampleDetail && sampleDetail &&
                <LLMSampleDetail sampleDetail={sampleDetail} additionalSamples={llmData?.samples} selectedDetailId={this.state.selectedDetailId} selectedDetailDistance={selectedDetailDistance} showSampleDetail={this.showSampleDetail} />
              }

              {
                !isLoadingSampleDetail && !sampleDetail &&
                <div className='secondary-text' style={{textAlign: 'center', margin: '10px 0'}}>
                  Select a vehicle above to view details
                </div>
              }

              <div className="modal-controls" style={{marginTop: '20px'}}>
                <Button color="secondary" style={{marginLeft: 0}} onClick={() => {
                  this.setState({
                    isShowingSampleDetailModal: false,
                    sampleDetail: null
                  })
                  }}>Close</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      }

      {
        isShowingTrimSamples &&
        <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '400px', maxWidth: '1000px'}} backdrop={true} toggle={() => {this.setState({ isShowingTrimSamples: false })}}>
          <ModalBody>
            <div>
              {
                isLoadingTrimSamples &&
                <div style={{minHeight: '280px'}}>
                  <Spinner />
                </div>
              }

              {
                !isLoadingTrimSamples && trimSamples &&
                <div style={{maxHeight: '675px', overflowY: 'scroll'}}>
                  <div className='secondary-text' style={{fontWeight: 'bold'}}>LOCAL COMPS FOR TRIM</div>
                  <h2 style={{marginBottom: '10px', marginTop: 0}}>{selectedTrimForSamples?.trim}</h2>
                  <DataTable
                    columns={sampleColumns}
                    data={samplesForTrimData}
                    providerKey='universe'
                    defaultSort='distance'
                    valuationMileage={this.props.valuationMileage}
                    onClick={this.showSampleDetail}
                    onClickDataKey='id'
                    icon={<MagnifyingGlassIcon color='#888' />}
                    highlightVIN
                    currentVIN={this.props.vin}
                    paginationCount={50}
                    />
                </div>
              }


              <div className="modal-controls" style={{marginTop: '20px'}}>
                <Button color="secondary" style={{marginLeft: 0}} onClick={() => {
                  this.setState({
                    isShowingTrimSamples: false,
                    trimSamples: null,
                    selectedTrimForSamples: null
                  })
                  }}>Close</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      }

      </ValuationProviderCard>
    )
  }

}
