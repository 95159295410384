import moment from 'moment'
import RemoteConstants from './RemoteConstants'
import AppConstants from './AppConstants'


// NOTE: don't forget to export your new function at the bottom

function formatThousands(val) {
  if (val === null || val === undefined) { return null }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function formatCurrency(val) {
  if(val === null || val === undefined) {
    return ''
  }
  const isNegative = val < 0
  val = isNegative ? val * -1 : val

  var interim = val > 999 ? formatThousands(val) : val
  interim = `${interim}`
  
  const lastPosOfDot = `${interim}`.lastIndexOf('.')
  if (lastPosOfDot !== -1 && lastPosOfDot === interim.length - 2) {
    interim = `${interim}0`
  }

  return `${isNegative ? '-' : ''}$${interim}`
}

function formatCurrencyCents(val) {
  return formatCurrency(val / 100)
}

function formatCurrencyRoundedCents(val) {
  return formatCurrency(Math.round(val / 100))
}

// Take it down to the precision we want (100s), then round it,
// then take it back to the original number (now rounded to 100s)
// and divide by 1000 to get the shorthand we want
function formatAbbreviatedCurrency(val) {
  return `$${Math.round(val / 100) * 100 / 1000}k`
}

function formatAbbeviatedThousands(val) {
  return `${Math.round(val / 100) * 100 / 1000}k`
}


function seriesStyleString(series, style) {
  let seriesStyle = []
  if (series && series !== AppConstants.emptySeriesValue) {
    seriesStyle.push(series)
  }
  if (style) {
    seriesStyle.push(style)
  }

  return seriesStyle.join(' - ')
}

function formatVerboseDate(dateString) {
  return moment(dateString).format('MMM Do YYYY, h:mm a')
}

function formatVerboseDateWithDay(dateString) {
  return moment(dateString).format('ddd MMM Do YYYY, h:mm a')
}

function formatShortDate(dateString) {
  return moment(dateString).format('M/D/YYYY, h:mm a')
}

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) { return null }
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

function prefixPathForImageURL(url) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  return (url.indexOf("http://") > -1 || url.indexOf("https://") > -1) ? '' : APIEndpoints.APIRoot
}

function distanceBetweenCoordinates(lat1, lon1, lat2, lon2) {
  let unit = 'M'

  var radlat1 = Math.PI * lat1/180
	var radlat2 = Math.PI * lat2/180
	var theta = lon1-lon2
	var radtheta = Math.PI * theta/180
	var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
	dist = Math.acos(dist)
	dist = dist * 180/Math.PI
	dist = dist * 60 * 1.1515
	if (unit==="K") { dist = dist * 1.609344 }
	if (unit==="M") { dist = dist * 0.8684 }
	return dist
}


export default {
  formatThousands,
  formatCurrency,
  formatCurrencyCents,
  formatCurrencyRoundedCents,
  formatAbbreviatedCurrency,
  formatAbbeviatedThousands,
  seriesStyleString,
  formatVerboseDate,
  formatVerboseDateWithDay,
  formatShortDate,
  formatPhoneNumber,
  prefixPathForImageURL,
  distanceBetweenCoordinates
}
