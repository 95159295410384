import React from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import FolderAssignment from './FolderAssignment'

export default class FolderAssignmentPopover extends React.Component {

  state = {
    isShowingFolderAssignmentPopover: false
  }

  wrapNode = (children) => {
    const { valuation, disabled } = this.props

    if (disabled === true) {
      return ( <span className="disabled">{children}</span> )
    }

    if (this.props.isRow) {
      return (
        <span onClick={(e) => e.preventDefault()} id={`folder_popover_${valuation.uuid}`}>{children}</span>
      )
    }

    return (<a href="#folder" onClick={(e) => e.preventDefault()} id={`folder_popover_${valuation.uuid}`}>{children}</a>)

  }

  render() {
    const { valuation, clientID, disabled } = this.props
    const vehicleFolder = valuation.vehicle_folder
    const { isShowingFolderAssignmentPopover } = this.state
    // FIXME: critical is this loading from server just mounting?

    return (
      this.wrapNode(
        <div>
          <svg className="bi bi-folder-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
          </svg>
          {vehicleFolder ? vehicleFolder.name : 'No folder'}
          {
            disabled !== true &&
            <Popover placement="left" isOpen={isShowingFolderAssignmentPopover} trigger="legacy" target={`folder_popover_${valuation.uuid}`} toggle={() => this.setState({ isShowingFolderAssignmentPopover: !isShowingFolderAssignmentPopover })}>
              <PopoverHeader>Change Folder</PopoverHeader>
              <PopoverBody className='popover-med' style={{maxHeight: '400px'}}>
                <FolderAssignment
                  onCancel={() => {this.setState({ isShowingFolderAssignmentPopover: false })}}
                  onDone={() => {this.setState({ isShowingFolderAssignmentPopover: false })}}
                  valuation={valuation}
                  clientID={clientID}
                  maxHeight={300}/>
              </PopoverBody>
            </Popover>
          }
        </div>
      )

    )
  }

}
