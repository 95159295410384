import React from 'react'
import Lottie from "lottie-react"
import anim from "./live-auction.json"

export default class LiveAuctionAnimation extends React.Component {
  render() {
    return (
      <Lottie animationData={anim} loop style={{...this.props.style}} />
    )
  }
}
