import React from 'react'
import { Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'

export default class AddDeducts extends React.Component {

  constructor(props) {
    super(props)

    var structuredAddDeducts = ValuationFunctions.structuredAddDeducts(props.items, props.adLogic, this.shouldSort())
    this.state = {
      includedAddDeducts: structuredAddDeducts['includedAddDeducts'],
      addDeducts: structuredAddDeducts['structuredAddDeducts'],
      isUpdating: false,
      isSubmittable: false
    }

    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items || this.props.adLogic !== prevProps.adLogic) {
      var structuredAddDeducts = ValuationFunctions.structuredAddDeducts(this.props.items, this.props.adLogic, this.shouldSort())
      this.setState({
        includedAddDeducts: structuredAddDeducts['includedAddDeducts'],
        addDeducts: structuredAddDeducts['structuredAddDeducts'],
        isUpdating: false,
        isSubmittable: false,
      })
    }
  }

  componentDidMount() {
    ValuationStore.on(`valuation_change_${this.props.clientID}`, this.addDeductsUpdated)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_change_${this.props.clientID}`, this.addDeductsUpdated)
  }

  shouldSort = () => {
    return this.props.providerKey && this.props.providerKey === 'kbb' ? false : true
  }

  addDeductsUpdated = () => {
    this.setState({
      isUpdating: false,
      isSubmittable: false
    })
  }

  selectAddDeduct = (item) => {
    const updatedAddDeducts = ValuationFunctions.onAddDeductsSelect(this.state.addDeducts, this.props.adLogic, item)

    if (updatedAddDeducts) {
      this.setState({
        addDeducts: updatedAddDeducts,
        isSubmittable: true
      })
    }
  }

  renderItem = (item) => {
    let isSelected = item.description.user_added
    return (
      <div className={`add-deduct${ isSelected ? ' add-deduct-selected' : ''}${item.disabled ? ' add-deduct-disabled' : ''}`} key={`ad-item-${item.description.uid}`} onClick={() => this.selectAddDeduct(item)}>
        <div className="add-deduct-details">
          <div className="add-deduct-title">{item.description.name}</div>
          <div className="secondary-text">
            { this.renderValues(item)}
          </div>
        </div>
        <div className="add-deduct-controls">
          <img src={`/images/add-deducts-check-${item.description.user_added ? 'selected' : 'empty'}.png`} alt="Toggle option" />
        </div>
      </div>
    )
  }

  renderChildren = (children) => {
    var retValues = children.map((child) => child.description.name)
    return retValues.join(', ')
  }

  saveAddDeducts = () => {
    const { addDeducts, isUpdating, isSubmittable } = this.state

    if (isUpdating || !isSubmittable) {
      return
    }

    var itemsHash = {}

    for (let item of addDeducts) {
      if (item.disabled === true) {
        continue
      }
      itemsHash[item.description.uid] = {
        included: item.description.included,
        added: item.description.added,
        user_added: item.description.user_added
      }
      if (item.children) {
        for (let child of item.children) {
          var childUserAdded = child.description.user_added
          // If parent is selected, deselect child
          if (item.description.user_added) {
            childUserAdded = false
          }
          itemsHash[child.description.uid] = {
            included: child.description.included,
            added: item.description.added,
            user_added: childUserAdded
          }
        }
      }
    }
    this.setState({
      isUpdating: true
    })

    var serverHash = {}
    serverHash['updated_selected_add_deducts'] = {}
    serverHash['updated_selected_add_deducts'][this.props.providerKey] = itemsHash

    ValuationActionCreators.updateValuation(this.props.vehicleUUID, serverHash, this.props.clientID)
  }

  renderValues = (item) => {
    const { providerKey, overallCondition } = this.props
    var retNodes = []
    if (providerKey === 'nada') {
      var combinedString = null
      var remainingValues = {'tradein': item.tradein, 'loan': item.loan, 'retail': item.retail}

      if (item.tradein === item.loan && item.tradein !== 0) {
        if (item.loan === item.retail) {
          combinedString = `Trade / Loan / Retail: ${Formatters.formatCurrency(item.tradein)}`
          remainingValues = {}
        } else {
          combinedString = `Trade / Loan: ${Formatters.formatCurrency(item.tradein)}`
          delete remainingValues['tradein']
          delete remainingValues['loan']
        }
      }

      if (item.loan == null && item.tradein == null && item.retail == null) {
        combinedString = ''
      }

      var subStrings = []

      if (combinedString) {
        subStrings.push(combinedString)
      }

      if (remainingValues.tradein) {
        subStrings.push(`Trade: ${Formatters.formatCurrency(item.tradein)}`)
      }

      if (remainingValues.loan) {
        subStrings.push(`Loan: ${Formatters.formatCurrency(item.loan)}`)
      }

      if (remainingValues.retail) {
        subStrings.push(`Retail: ${Formatters.formatCurrency(item.retail)}`)
      }

      if (subStrings.length === 0) {
        subStrings = ['$0']
      }

      retNodes.push(
        <div style={{color: item.loan < 0 ? 'red' : '#111'}} key={`nada-combined-${item.description.uid}`}>{subStrings.join(', ')}</div>
      )

    } else if (providerKey === 'black_book' && overallCondition && item[overallCondition]) {
      retNodes.push(
        <div style={{color: item.loan < 0 ? 'red' : '#111'}} key={`black_book-combined-${item.description.uid}`}>{Formatters.formatCurrency(item[overallCondition])}</div>
      )
    } else if (providerKey === 'galves' && item['amount']) {
      retNodes.push(
        <div style={{color: item.loan < 0 ? 'red' : '#111'}} key={`galves-combined-${item.description.uid}`}>{Formatters.formatCurrency(item['amount'])}</div>
      )
    }

    return retNodes
  }

  render() {
    const changesLocked = this.props.changesLocked
    const { includedAddDeducts, addDeducts, isSubmittable, isUpdating } = this.state

    var includedAddDeductsString = null
    if (includedAddDeducts && includedAddDeducts.length > 0) {
      includedAddDeductsString = includedAddDeducts.map((item) => {
        return item.description.name
      })
      includedAddDeductsString = includedAddDeductsString.join(', ')
    }

    var chosenAddDeducts = []
    if (this.isShared || changesLocked) {
      for (let item of addDeducts) {
        if (item.description.user_added === true) {
          chosenAddDeducts.push(item.description.name)
        }
        if (item.children && item.children.length > 0) {
          for (let child of item.children) {
            if (child.description.user_added === true) {
              chosenAddDeducts.push(child.description.name)
            }
          }
        }
      }
    }

    return (
      <div>
        {
          includedAddDeductsString !== null &&
          <div className="secondary-text" style={{marginBottom: '10px'}}>Included options: {includedAddDeductsString}</div>
        }
        {
          (this.isShared || changesLocked) && chosenAddDeducts && chosenAddDeducts.length > 0 &&
          <div className="secondary-text" style={{marginBottom: '10px'}}>Chosen options: {chosenAddDeducts.join(', ')}</div>
        }
        {
          (this.isShared || changesLocked) && (!chosenAddDeducts || chosenAddDeducts.length === 0) &&
          <div className="secondary-text" style={{marginBottom: '10px'}}>Chosen options: none</div>
        }
        {
          !this.isShared && !changesLocked &&
          <div className="add-deducts-c">
            {
              !isUpdating &&
              addDeducts.map((item) => {
                let isParent = item.children && item.children.length > 0
                return (
                  <div className={isParent ? 'add-deduct-parent' : ''} key={`ad-top-c-${item.description.uid}`}>
                    { this.renderItem(item) }
                    {
                      isParent &&
                      <div className='add-deduct-children'>
                      { this.renderChildren(item.children) }
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>
        }

        {
          !isUpdating && isSubmittable &&
          <Button color="primary" style={{width: '100%', marginTop: '20px'}} onClick={this.saveAddDeducts}>Save Options</Button>
        }
        {
          isUpdating &&
          <Spinner />
        }
      </div>
    )
  }

}
