import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationProviderCard from './ValuationProviderCard'
import AdjustmentsTable from './AdjustmentsTable'
import AddDeducts from './AddDeducts'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'

const title = AppConstants.providerNames.kbb

export default class ValuationKBB extends React.Component {

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
    this.state = {
      isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('kbb'),
      addDeductsHidden: SessionStore.areAddDeductsHidden(props.providerKey)
    }
  }

  componentDidMount() {
    SessionStore.on('user_data_changed', this.userDataChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.userDataChanged)
  }

  userDataChanged = () => {
    this.setState({ isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('kbb') })
  }

  renderSelectedAddDeducts = () => {
    const results = this.props.providerData
    const adItems = results.add_deducts && results.add_deducts.items && results.add_deducts.items.length > 0 ? results.add_deducts.items : null

    var selectedItems = []
    if (adItems) {
      for (let item of adItems) {
        const desc = item.description
        if (desc.included === false && desc.user_added === true) {
          selectedItems.push(desc.name)
        }
      }
    }
    if (selectedItems.length > 0) {
      return (
        <div>{selectedItems.join(', ')}</div>
      )
    } else {
      return null
    }
  }

  extractTableValues(rawValues, valueKey) {
    var hasValues = false
    var returnHash = {}
    if (rawValues) {
      if (rawValues['fair'] && rawValues['fair'][valueKey] && rawValues['fair'][valueKey]['adjusted'] !== null && rawValues['fair'][valueKey]['adjusted'] > 0) {
        returnHash['fair'] = rawValues['fair'][valueKey]['adjusted']
        hasValues = true
      }
      if (rawValues['good'] && rawValues['good'][valueKey] && rawValues['good'][valueKey]['adjusted'] !== null && rawValues['good'][valueKey]['adjusted']) {
        returnHash['good'] = rawValues['good'][valueKey]['adjusted']
        hasValues = true
      }
      if (rawValues['very_good'] && rawValues['very_good'][valueKey] && rawValues['very_good'][valueKey]['adjusted'] !== null && rawValues['very_good'][valueKey]['adjusted']) {
        returnHash['very_good'] = rawValues['very_good'][valueKey]['adjusted']
        hasValues = true
      }
      if (rawValues['excellent'] && rawValues['excellent'][valueKey] && rawValues['excellent'][valueKey]['adjusted'] !== null && rawValues['excellent'][valueKey]['adjusted']) {
        returnHash['excellent'] = rawValues['excellent'][valueKey]['adjusted']
        hasValues = true
      }

    }
    returnHash['hasValues'] = hasValues
    return returnHash
  }

  kbbConditionFromOverallCondition(overallCondition) {
    switch (overallCondition) {
      case 'rough':
        return 'fair'
      case 'average':
        return 'good'
      case 'clean':
        return 'excellent'
      default:
      return ''
    }
  }


  render() {
    const kbbData = this.props.providerData
    if (!kbbData) { return null }
    const appraisal = kbbData.appraisal
    const error = kbbData.error
    const description = kbbData.description
    const overallCondition = this.props.overallCondition
    const systemData = this.props.systemData
    const changesLocked = (systemData && systemData.changes_locked === true)

    const kbbCondition = this.kbbConditionFromOverallCondition(overallCondition)
    const fullAppraisalValuesForCondition = appraisal ? appraisal[kbbCondition] : null

    const appraisalValuesForCondition = fullAppraisalValuesForCondition ? {auction: fullAppraisalValuesForCondition.auction, fair_purchase: fullAppraisalValuesForCondition.fair_purchase, tradein: fullAppraisalValuesForCondition.tradein, private_party: fullAppraisalValuesForCondition.private_party, lending: appraisal.lending} : null

    var hasAdjustmentValues = false
    if (fullAppraisalValuesForCondition?.auction?.adjusted > 0 || fullAppraisalValuesForCondition?.fair_purchase?.adjusted > 0 || fullAppraisalValuesForCondition?.tradein?.adjusted > 0 || fullAppraisalValuesForCondition?.private_party?.adjusted > 0 || fullAppraisalValuesForCondition?.lending?.adjusted > 0) {
      hasAdjustmentValues = true
    }

    const adItems = kbbData.add_deducts && kbbData.add_deducts.items && kbbData.add_deducts.items.length > 0 ? kbbData.add_deducts.items : null
    const adLogic = kbbData.add_deducts && kbbData.add_deducts.logic ? kbbData.add_deducts.logic : null

    const tradeInHash = this.extractTableValues(appraisal, 'tradein')
    const privatePartyHash = this.extractTableValues(appraisal, 'private_party')
    const auctionHash = this.extractTableValues(appraisal, 'auction')
    const fairPurchaseHash = this.extractTableValues(appraisal, 'fair_purchase')

    var hasTableValues = false
    if (auctionHash.good || fairPurchaseHash.good || tradeInHash.good || privatePartyHash.good) {
      hasTableValues = true
    }

    const { isShowingAdjustments, addDeductsHidden } = this.state

    return (
      <ValuationProviderCard
        providerData={kbbData}
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey={this.props.providerKey}
        systemData={systemData}
        fullChoices={this.props.fullChoices}
        currentChoiceUID={kbbData.description && kbbData.description.uid ? kbbData.description.uid : null}
        title={title}
        subtitle={error || !description ? null : description.display_choice}
        hasError={error ? true : false}
        showAdjustmentMessage={true}
        valuationMileage={this.props.valuationMileage}
        providerMileage={this.props.providerData.description && this.props.providerData.description.mileage ? this.props.providerData.description.mileage : null}
        messages={kbbData.messages}
        onBookout={this.props.onBookout}
      >


      {
        !error && description &&
        <div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} className="adjustments-toggle-c">
            <div>Average mileage: <span style={{fontWeight: 'bold'}}>{Formatters.formatThousands(description.avg_mileage)}</span></div>
            {
              (hasTableValues || hasAdjustmentValues) &&
              <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
                e.preventDefault()
                this.setState({ isShowingAdjustments: !isShowingAdjustments })
                SessionStore.saveProviderDisplayMode('kbb', !isShowingAdjustments)
              }}>Switch to {isShowingAdjustments ? 'conditions' : 'adjustments'} view</a>
            }
          </div>

          {
            !isShowingAdjustments && hasTableValues &&
            <div className="kbb-table">

              <div className="valuation-subsection prices-table">
                <div className="prices-table-row prices-table-row-header">
                  <div className="prices-table-cell-6 header-cell">&nbsp;</div>
                  <div className="prices-table-cell-6 header-cell">Auction</div>
                  <div className="prices-table-cell-6 header-cell">Fair Purchase Price</div>
                  <div className="prices-table-cell-6 header-cell spread-cell">Spread</div>
                  <div className="prices-table-cell-6 header-cell">Trade-In</div>
                  <div className="prices-table-cell-6 header-cell">Private Party</div>
                </div>

                <div>
                  <div className={`prices-table-row${overallCondition && overallCondition === 'rough' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-fair`}>
                    <div className="prices-table-cell-6 row-label row-label-6">
                      <div>Fair</div>
                    </div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(auctionHash['fair'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(fairPurchaseHash['fair'])}</div>
                    <div className="prices-table-cell-6 prices-table-cell-separator">{auctionHash['fair'] && fairPurchaseHash['fair'] ? Formatters.formatCurrency(fairPurchaseHash['fair'] - auctionHash['fair']) : ''}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(tradeInHash['fair'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(privatePartyHash['fair'])}</div>
                  </div>


                  <div className={`prices-table-row${overallCondition && overallCondition === 'average' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-good`}>
                    <div className="prices-table-cell-6 row-label row-label-6">
                      <div>Good</div>
                    </div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(auctionHash['good'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(fairPurchaseHash['good'])}</div>
                    <div className="prices-table-cell-6 prices-table-cell-separator">{auctionHash['good'] && fairPurchaseHash['good'] ? Formatters.formatCurrency(fairPurchaseHash['good'] - auctionHash['good']) : ''}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(tradeInHash['fair'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(privatePartyHash['good'])}</div>
                  </div>


                  <div className={`prices-table-row${overallCondition && overallCondition === 'clean' ? ' prices-table-row-highlighted' : ''}`} key={`kbb-row-excellent`}>
                    <div className="prices-table-cell-6 row-label row-label-6">
                      <div>Excellent</div>
                    </div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(auctionHash['excellent'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(fairPurchaseHash['excellent'])}</div>
                    <div className="prices-table-cell-6 prices-table-cell-separator">{auctionHash['excellent'] && fairPurchaseHash['excellent'] ? Formatters.formatCurrency(fairPurchaseHash['excellent'] - auctionHash['excellent']) : ''}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(tradeInHash['excellent'])}</div>
                    <div className="prices-table-cell-6">{Formatters.formatCurrency(privatePartyHash['excellent'])}</div>
                  </div>
                </div>

              </div>

            </div>
          }

          {
            isShowingAdjustments &&
            <div>
              <AdjustmentsTable appraisalValues={appraisalValuesForCondition} />
              {
                hasAdjustmentValues === true &&
                <div className="secondary-text" style={{textAlign: 'right'}}>Condition: {AppConstants.overallConditions[overallCondition].title}</div>
              }
            </div>
          }




          <div style={{height: 20}} />

          <div className="kbb-section-table" style={{display: 'flex', justifyContent: 'space-between', textAlign: 'center'}}>
            {
              appraisal?.typical_listing && appraisal.typical_listing.adjusted &&
              <div>
                <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{Formatters.formatCurrency(appraisal.typical_listing.adjusted)}</div>
                <div>Typical Listing Price</div>
              </div>
            }
            {
              appraisal?.cpo && appraisal.cpo.typical_listing &&
              <div>
                <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{Formatters.formatCurrency(appraisal.cpo.typical_listing.adjusted)}</div>
                <div>CPO Typical Listing Price</div>
              </div>
            }
            {
              appraisal?.cpo && appraisal.cpo.fair_purchase && appraisal.cpo.fair_purchase && appraisal.cpo.fair_purchase.adjusted &&
              <div>
                <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{Formatters.formatCurrency(appraisal.cpo.fair_purchase.adjusted)}</div>
                <div>CPO Fair Purchase Price</div>
              </div>
            }
          </div>

          <div style={{height: 20}} />


          {
            appraisal?.lending && appraisal.lending.adjusted &&
            <div className="kbb-lending-c">
              <div><span>Lending value: </span>{Formatters.formatCurrency(appraisal.lending.adjusted)}</div>
              <div>LTV 115%: {Formatters.formatCurrency(Math.round(appraisal.lending.adjusted * 1.15))}</div>
              <div>LTV 120%: {Formatters.formatCurrency(Math.round(appraisal.lending.adjusted * 1.20))}</div>
            </div>
          }

          {
            adItems && !global.isPartnerPreview &&
            <div className="valuation-subsection">
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <h3 className="valuation-subsection-title">{this.isShared || changesLocked ? 'Vehicle Options' : 'Change Vehicle Options & Accessories'}</h3>
                <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
                  e.preventDefault()
                  SessionStore.setHiddenAddDeducts(!addDeductsHidden, this.props.providerKey)
                  this.setState({ addDeductsHidden: !addDeductsHidden })
                }}>{addDeductsHidden ? 'Show' : 'Hide'}</a>
              </div>
              {
                !addDeductsHidden &&
                <AddDeducts items={adItems} adLogic={adLogic} providerKey="kbb" clientID={this.props.clientID} vehicleUUID={this.props.vehicleUUID} changesLocked={changesLocked}/>
              }
            </div>
          }

          {
            global.isPartnerPreview &&
            <div className="valuation-subsection partner-preview-access-message">
              Modifying options and accessories is only available with a registered <a href="https://getcarbly.com/register/?r=edge" target="_blank">Carbly account</a>.
            </div>
          }
        </div>

        }

      </ValuationProviderCard>
    )
  }
}
