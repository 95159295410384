import React from 'react'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Formatters from '../../api/constants/Formatters'

export default class AdjustmentsTable extends React.Component {



  renderTable(adjustmentsData) {
    var out = []
    const cellWidth = `${(1 / (adjustmentsData.titles.length + 1)) * 100}%`

    out.push(
      <div className="prices-table-row prices-table-row-header" key='adj-header'>
        <div className="prices-table-cell-6 header-cell">&nbsp;</div>
        {
          adjustmentsData.titles.map((t) => (
            <div className="prices-table-cell-6 header-cell" style={{width: cellWidth}} key={`title-${t}`}>{t}</div>
          ))
        }
      </div>
    )

    out.push(
      <div className='prices-table-row adjustments-table-row' key='base' style={{marginBottom: '3px'}}>
        <div className="prices-table-cell-6 row-label row-label-6 adjustments-row-label" style={{fontWeight: 'bold'}}>Base</div>
        {
          adjustmentsData.base.map((b, index) => (
            <div className="prices-table-cell-6" key={`base-${index}`} style={{width: cellWidth, fontWeight: 'bold'}}>{Formatters.formatCurrency(b)}</div>
          ))
        }
      </div>
    )

    var adjIndex = 0
    const adjustments = adjustmentsData.adjustments
    for (let adj of adjustments) {
      out.push(
        <div className='prices-table-row adjustments-table-row' key={`adj-${adj.name}-${adjIndex}`}>
          <div className="prices-table-cell-6 row-label row-label-6 adjustments-row-label">{adj.name}</div>
          {
            adj.values.map((adjValue, index) => (
              <div className="prices-table-cell-6" key={`adj-${adj.name}-${index}`} style={{width: cellWidth, opacity: adjValue === 0 ? 0.5 : 1.0}}>{Formatters.formatCurrency(adjValue)}</div>
            ))
          }
        </div>
      )
      adjIndex++
    }


    out.push(
      <div className='prices-table-row adjustments-table-row' key='adj' style={{marginTop: '3px'}}>
        <div className="prices-table-cell-6 row-label row-label-6 adjustments-row-label" style={{fontWeight: 'bold'}}>{global.isIFrameMode ? 'Adj' : 'Adjusted'}</div>
        {
          adjustmentsData.adjusted.map((a, index) => (
            <div className="prices-table-cell-6" style={{width: cellWidth, fontWeight: 'bold'}} key={`adj-final-${index}`}>{Formatters.formatCurrency(a)}</div>
          ))
        }
      </div>
    )

    return out
  }


  render() {
    const adjustmentsData = ValuationFunctions.formatAppraisalData(this.props.appraisalValues)

    if (!adjustmentsData) {
      return null
    }

    return (
      <div className="valuation-subsection prices-table">
        { this.renderTable(adjustmentsData) }
      </div>
    )
  }
}
