import { useMemo, useCallback, useEffect, useState } from 'react'

const timeDiff = (ms) => {
  const secs = Math.floor(Math.abs(ms) / 1000)
  const mins = Math.floor(secs / 60)
  const hours = Math.floor(mins / 60)
  const days = Math.floor(hours / 24)
  const multiple = (term, n) => `${n}${term}`

  return {
    days: days,
    hoursTotal: hours,
    hours: hours % 24,
    minutesTotal: mins,
    minutes: mins % 60,
    seconds: secs % 60,
    secondsTotal: secs,
    get formattedStr() {
      return [
        this.days && multiple('d', this.days),
        this.hoursTotal && multiple('h', this.hours),
        this.minutesTotal && !this.days && multiple('m', this.minutes),
        !this.hoursTotal && multiple('s', this.seconds),
      ].filter(Boolean).join(' ')
    },
  }
}

export default (endDate) => {
  const diff = useCallback(() => {
    return new Date(endDate) - new Date()
  }, [endDate])

  const [msLeft, setMsLeft] = useState(diff)

  useEffect(() => {
    const interval = setInterval(() => setMsLeft(diff), 1000)
    return () => clearInterval(interval)
  }, [diff])

  const output = useMemo(() => {
    return {
      msLeft,
      formattedStr: timeDiff(msLeft).formattedStr
    }
  }, [msLeft])

  return output
}
