import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import ValuationBooksMatchup from './ValuationBooksMatchup'
import ExpandIcon from '../common/icons/ExpandIcon'
import ContractIcon from '../common/icons/ContractIcon'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'


export default class MatchupWholesale extends React.Component {
  state = {
    matchupWholesaleIsHidden: SessionStore.hiddenMatchupWholesale === true,
    matchupWholesaleMode: AppConstants.matchupTypes.prices
  }

  render() {
    const valuation = this.props.valuation
    const { matchupWholesaleIsHidden, matchupWholesaleMode } = this.state
    const formattedTitle = AppConstants.providerNames['matchup_wholesale'].replace("®", "<sup>&reg;</sup>")

    if (!valuation.full_valuations?.carbly?.results?.wholesale_comparison || this.isShared || global.isIFrameMode || this.isPartnerPreview) {
      return null
    }

    return (
      <section className={`card matchup-card valuation-section${ matchupWholesaleIsHidden ? ' valuation-section-collapsed' : ''}`} style={{overflow: 'visible'}}>
        <div className="header">
          <h2 style={{marginRight: '10px', flexGrow: 1, flexShrink: 1}} dangerouslySetInnerHTML={{__html: formattedTitle}}></h2>

          {
            false && matchupWholesaleIsHidden !== true &&
            // Mileage graphs disabled for now
            <div className="actions">
              <a href="#prices" className={matchupWholesaleMode === AppConstants.matchupTypes.prices ? 'active' : ''} onClick={(e) => {
                  e.preventDefault()
                  platformFunctions.logAnalyticsEvent("Valuations", "Matchup Show Prices")
                  this.setState({
                    matchupWholesaleMode: AppConstants.matchupTypes.prices
                  })
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" viewBox="0 0 16 16" style={{marginLeft: '-6px', marginRight: '2px'}}>
                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                </svg>
                Prices</a>
              <a href="#mileage" className={matchupWholesaleMode === AppConstants.matchupTypes.mileage ? 'active' : ''} onClick={(e) => {
                e.preventDefault()
                platformFunctions.logAnalyticsEvent("Valuations", "Matchup Show Mileage")
                this.setState({
                  matchupWholesaleMode: AppConstants.matchupTypes.mileage
                })
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" viewBox="0 0 502 454">
                  <path fillRule="evenodd" d="M427.046,428.330 C426.925,427.391 386.264,391.313 386.335,390.600 C422.984,355.148 445.769,305.453 445.769,250.436 C445.769,142.745 358.468,55.443 250.776,55.443 C143.085,55.443 55.784,142.745 55.784,250.436 C55.784,302.870 76.480,350.471 110.149,385.514 C110.720,385.505 74.187,427.391 74.151,427.980 C28.560,382.624 0.339,319.826 0.339,250.436 C0.339,112.124 112.463,-0.000 250.775,-0.000 C389.087,-0.000 501.211,112.124 501.211,250.436 C501.211,320.004 472.844,382.948 427.046,428.330 ZM234.369,270.766 C220.284,260.534 217.159,240.821 227.391,226.735 C237.622,212.650 345.090,90.193 359.176,100.424 C373.262,110.656 288.631,249.701 278.400,263.787 C268.168,277.873 248.455,280.997 234.369,270.766 ZM169.000,386.000 L328.000,386.000 C333.523,386.000 338.000,390.477 338.000,396.000 L338.000,444.000 C338.000,449.523 333.523,454.000 328.000,454.000 L169.000,454.000 C163.477,454.000 159.000,449.523 159.000,444.000 L159.000,396.000 C159.000,390.477 163.477,386.000 169.000,386.000 Z"/>
                </svg>
              Mileage Impact</a>
            </div>
          }

          <a href="#toggle" onClick={(e) => {
            e.preventDefault()
            platformFunctions.logAnalyticsEvent("Valuations", `Matchup ${matchupWholesaleIsHidden ? 'Show' : 'Hide'}`)
            this.setState({ matchupWholesaleIsHidden: !matchupWholesaleIsHidden })
            SessionStore.setHiddenMatchup(!matchupWholesaleIsHidden, 'wholesale')
          }}>
            {
              matchupWholesaleIsHidden ?
              <ExpandIcon width={28} height={28} color='#aaa' /> :
              <ContractIcon width={28} height={28} color='#aaa' />
            }
          </a>
        </div>
        {
          matchupWholesaleIsHidden !== true &&
          <div style={{width: '100%', marginBottom: '10px'}} className="wholesale-matchup-graph">
            {
              matchupWholesaleMode === AppConstants.matchupTypes.prices &&
              <ValuationBooksMatchup comparison={valuation.full_valuations.carbly.results.wholesale_comparison} overallCondition={valuation.overall_condition} title="Wholesale Comparison" subTitle="Comparison of all provider wholesale values" />
            }

            {/* {
              matchupWholesaleMode === AppConstants.matchupTypes.mileage &&
              <ValuationBooksMileageMatchup vehicleUUID={valuation.uuid} mileage={valuation.mileage} dataType='wholesale' subTitle='The impact of mileage on wholesale pricing' />
            } */}
          </div>
        }
      </section>


    )
  }
}







