import { useRef, useState, useEffect } from "react"
const SegmentedControl = ({
  segments,
  onValueChange,
  value,
  controlRef,
  segmentStyle
}) => {
  useEffect(() => {
    const activeSegmentRef = segments.find(s => s.value === value).ref
    const { offsetWidth, offsetLeft } = activeSegmentRef.current
    const { style } = controlRef.current

    style.setProperty("--highlight-width", `${offsetWidth}px`)
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`)
  }, [value, onValueChange, controlRef, segments])

  return (
    <div className="segment-control-container">
      <div className={`segment-control`}>
        <div className={`segment-control-underlay segment-control-underlay-ready`} ref={controlRef}/>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${item.value === value ? "active" : "inactive"}`}
            ref={item.ref}
            onClick={() => onValueChange(item.value)}
            style={{...segmentStyle}}
          >
            <label>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const TFSegmentedControl = ({segments, value, onValueChange, segmentStyle}) => {
  const [selectedValue, setSelectedValue] = useState("complete");

  return (
      <SegmentedControl
        onValueChange={onValueChange}
        controlRef={useRef()}
        segments={segments.map((segment) => ({label: segment.label, value: segment.value, ref: useRef()}))}
        value={value}
        segmentStyle={segmentStyle}
      />
  );
};

export default TFSegmentedControl;