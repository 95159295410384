import React from 'react'
import { Prompt } from 'react-router-dom'
import {  Button, Modal, ModalHeader, ModalBody, Input, Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import platformFunctions from '../../platformFunctions'
import Spinner from '../common/Spinner'
import AccountBug from '../user/AccountBug'
import AuctionVehiclesDisplayBar from './AuctionVehiclesDisplayBar'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'
import SaveIcon from '../common/icons/SaveIcon'
import DuplicateIcon from '../common/icons/DuplicateIcon'
import EditIcon from '../common/icons/EditIcon'
import DeleteIcon from '../common/icons/DeleteIcon'
import SaveIconFilled from '../common/icons/SaveIconFilled'
import CalculatorBug from '../common/CalculatorBug'
import pluralize from 'pluralize'
import AuctionsListingsList from './AuctionsListingsList'
import AuctionsListingShowModal from './AuctionsListingShowModal'
import AuctionsSearchFilters from './AuctionsSearchFilters'
import AuctionsPlusUpsellModal from './AuctionsPlusUpsellModal'
import UserActionCreators from '../../api/actions/UserActionCreators'
import WarningCircleIcon from '../common/icons/WarningCircleIcon'

const SIDEBAR_WIDTH = 350
const COLLAPSED_SIDEBAR_WIDTH = 34

export default class AuctionsSearch extends React.Component {
  state = {
    isLoadingSavedSearch: false,
    savedSearch: null,
    savedSearchLoadError: false,
    savedSearchHasChanged: false,
    isCreatingSavedSearch: false,
    isUpdatingSavedSearch: false,
    isDeletingSavedSearch: false,

    listings: null,
    listingsTotalCount: null,
    listingsTodayCount: null,
    isPerformingQuery: false,
    isPerformingPaginatedQuery: false,

    showModalAsSaveCopy: false,
    isShowingSaveSearchModal: false,
    auctionListingsDisplayType: SessionStore.auctionListingsDisplayType,

    nameValidationError: null,

    isShowingBuyNow: false,
    modifiedName: '',

    duplicateToolTipIsOpen: false,
    saveToolTipIsOpen: false,
    isSidebarCollapsed: SessionStore.hiddenAuctionsSearchFilters,

    coordinatesForZip: null,

    isShowingNewlyAdded: false,

    clickedListingID: null,
    isShowingListing: false,
    isShowingUpsellModal: false,

    isShowingFloatingSaveButton: false
  }

  componentDidMount() {
    this.updateSearchIdentifiers()
    this.queryID = 0
    AuctionStore.on(`auction_vehicles_search_change-${this.clientID}${this.queryID}`, this.queryDidFinish)
    AuctionStore.on('global_auction_listing_update', this.updateSearchResultListings)
    AuctionStore.on('saved_search_change', this.getSavedSearch)
    AuctionStore.on('saved_search_deleted', this.savedSearchDeleted)
    AuctionStore.on('coordinates_for_zip_change', this.coordinatesForZipDidChange)
    window.addEventListener('scroll', this.handleScroll)

    const queryString = require('query-string')
    const qs = queryString.parse(window.location.search)
    
    let isActivityView = false
    if (qs['whats-new']) {
      isActivityView = true
      this.setState({ isShowingNewlyAdded: true })
      this.stripWhatsNewFromUrl()
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_today_view_show'])
    }


    const searchUUID = this.props.navMatch?.params?.searchUUID
    this.hasLoadedInitialSearch = false

    if (this.clientID === 'new') {
      this.initializeEmptySearch()
      
      setTimeout(() => {
        // Wait for state to update
        const { savedSearch } = this.state
        let newParams = {...qs}
        newParams['classification'] = []
        if (qs['make'] && qs['model']) {
          let newClassification = {make: qs['make'], model: qs['model']}
          if (qs['trim']) {
            newClassification.trim = qs['trim']
          }
          newParams.classification.push(newClassification)
          
        } else if (qs['make']) {
          newParams.classification.push({make: qs['make']})
        }
        
        if (qs['retail_min']) { newParams['retail_min'] = parseInt(qs['retail_min']) }
        if (qs['retail_max']) { newParams['retail_max'] = parseInt(qs['retail_max']) }
        if (qs['wholesale_min']) { newParams['wholesale_min'] = parseInt(qs['wholesale_min']) }
        if (qs['wholesale_max']) { newParams['wholesale_max'] = parseInt(qs['wholesale_max']) }
        if (qs['profit_min']) { newParams['profit_min'] = parseInt(qs['profit_min']) }

        if (newParams?.year_max && newParams?.classification?.length > 0) {
          UserActionCreators.createMetric(AppConstants.metricNames['auctions_similar_vehicles_search'], null, {year: newParams.year_max, make: newParams.classification[0].make, model: newParams.classification[0].model})
        } else {
          UserActionCreators.createMetric(AppConstants.metricNames['auctions_new_search'])
        }

        // If vehicle type is not an array, make it one
        if (qs['vehicle_type']) {
          if (!Array.isArray(qs['vehicle_type'])) {
            newParams['vehicle_type'] = [qs['vehicle_type']]
          } else {
            newParams['vehicle_type'] = qs['vehicle_type']
          }
        }

        const newSavedSearch = Object.assign({}, savedSearch, newParams)
        newSavedSearch.location_radius = newSavedSearch.location_radius ? parseInt(newSavedSearch.location_radius) : null

        this.setState({ savedSearch: newSavedSearch })
        setTimeout(() => {
          this.runQuery(1)
        }, 100);

      }, 100);
      
    } else if (searchUUID) {
      this.setState({ isLoadingSavedSearch: true })
      AuctionsActionCreators.loadSavedSearch(searchUUID, this.clientID)
    } else {
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_new_search'])
      this.initializeEmptySearch()
      setTimeout(() => {
        this.runQuery(1)
      }, 50);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSearchUUID = prevProps.navMatch?.params?.searchUUID
    const newSearchUUID = this.props.navMatch?.params?.searchUUID
    const { savedSearchHasChanged } = this.state

    // If already on new search and user clicks new search we need special treatment to force
    // a fresh state
    const queryString = require('query-string')
    const qs = queryString.parse(window.location.search)
    let isNewNew = false
    if (qs['new']) {
      window.history.replaceState(null, "Carbly", "/auctions/search")
      isNewNew = true
    }

    if ((prevSearchUUID && !newSearchUUID) || isNewNew) {
      // Previously on an existing search, now on new
      this.hasLoadedInitialSearch = false
      this.updateSearchIdentifiers()
      this.initializeEmptySearch()
      this.setState({
        savedSearchHasChanged: false,
        isPerformingQuery: true,
        savedSearchLoadError: false
      })
      
      setTimeout(() => {
        this.runQuery(1)
      }, 50)
    } else if (newSearchUUID && newSearchUUID !== prevSearchUUID) {
      // On a different saved search
      this.hasLoadedInitialSearch = false
      this.updateSearchIdentifiers()
      this.setState({
        isLoadingSavedSearch: true,
        savedSearch: null,
        savedSearchHasChanged: false,
        isPerformingQuery: true
      })
      AuctionsActionCreators.loadSavedSearch(newSearchUUID, this.clientID)
    }

    if (savedSearchHasChanged) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount() {
    // Clear the beforeunload handler to chrome doesn't prompt for unsaved changes on reload
    window.onbeforeunload = undefined
    AuctionStore.removeListener(`auction_vehicles_search_change-${this.clientID}${this.queryID}`, this.queryDidFinish)
    AuctionStore.removeListener('global_auction_listing_update', this.updateSearchResultListings)
    AuctionStore.removeListener('saved_search_change', this.getSavedSearch)
    AuctionStore.removeListener('saved_search_deleted', this.savedSearchDeleted)
    AuctionStore.removeListener('coordinates_for_zip_change', this.coordinatesForZipDidChange)
    window.removeEventListener('scroll', this.handleScroll)
  }

  coordinatesForZipDidChange = () => {
    this.setState({
      coordinatesForZip: AuctionStore.coordinatesForZip
    })
    if (this.refs.channelsMap) {
      setTimeout(() => {
        this.refs.channelsMap.zoomToFitMarkers()
      }, 100);
    }
  }

  savedSearchDeleted = () => {
    AuctionsActionCreators.loadSavedSearches()
    this.props.history.replace({
      pathname: '/auctions/'
    })
  }

  updateSearchIdentifiers = () => {
    AuctionStore.removeListener(`auction_vehicles_search_change-${this.clientID}`, this.queryDidFinish)
    const randomID = Math.floor(Math.random() * 100000)
    const searchUUID = this.props.navMatch?.params?.searchUUID
    if (searchUUID === 'new') {
      // Explicitly set to new for new search with params
      this.clientID = 'new'
    } else {
      this.clientID = searchUUID ? `ss-${searchUUID}${randomID}` : `new-ss${randomID}`
    }
    
    AuctionStore.on(`auction_vehicles_search_change-${this.clientID}`, this.queryDidFinish)
  }

  loadCoordinatesForZipCodeFromSavedSearch = (savedSearch) => {
    const user = SessionStore.user
    const userZipCode = user?.zip_code
    const rooftopZipCode = user?.rooftop?.zip_code

    // If the zip is just the user's zip, and it's the same as rooftop get coords from the rooftop
    if (userZipCode && userZipCode === rooftopZipCode && (!savedSearch?.zip_code || savedSearch.zip_code === user.zip_code)) {
      this.setState({
        coordinatesForZip: {longitude: user.rooftop.longitude, latitude: user?.rooftop?.latitude}
      })
    } else if (savedSearch.zip_code) {
      this.loadCoordinatesForZipCode(savedSearch.zip_code)
    }
  }

  loadCoordinatesForZipCode = (zipCode) => {
    this.setState({
      coordinatesForZip: null
    })
    AuctionsActionCreators.getCoordinatesForZipCode(zipCode)
  }

  getSavedSearch = () => {
    // FIXME: error handling
    const { isCreatingSavedSearch, isUpdatingSavedSearch } = this.state
    let savedSearch = AuctionStore.savedSearchWithClientID(this.clientID)
    if (!savedSearch) {
      console.log("___NO SAVED SEARCH FOUND: ", this.clientID);
      this.setState({
        savedSearchLoadError: true,
        isLoadingSavedSearch: false
      })
      return null
    }
    
    const physicalCodes = AuctionStore.savedSearches?.global?.physical_codes || SessionStore.user?.auction_codes
    savedSearch = AuctionFunctions.sanitizeSearchDefaults(AuctionStore.savedSearchWithClientID(this.clientID), physicalCodes)
    const isSameSavedSearch = this.state.savedSearch?.uuid === savedSearch?.uuid

    // Prefetch the center point for the zipcode
    let searchDistanceZipCode = savedSearch?.zip_code
    if (!searchDistanceZipCode) {
      searchDistanceZipCode = SessionStore.user?.zip_code
    }

    this.loadCoordinatesForZipCodeFromSavedSearch(savedSearch)

    if (isCreatingSavedSearch) {
      toast.success("Search created")

      // Slight delay in order to get the states correct before navigation
      // Without this, app will prompt the user for unsaved changes
      setTimeout(() => {
        this.props.history.replace({
          pathname: `/auctions/search/${savedSearch.uuid}`
        })
      })
    } else if (isUpdatingSavedSearch) {
      toast.success("Search saved")
    }

    // Might be a saved search from plus, but they don't have it anymore
    if (!SessionStore.hasAuctionsPlus()) {
      savedSearch.order = AuctionFunctions.stripAuctionsPlusSortOrders(savedSearch.order)
    }

    this.setState({
      isLoadingSavedSearch: false,
      isCreatingSavedSearch: false,
      isUpdatingSavedSearch: false,
      isShowingSaveSearchModal: false,
      savedSearchHasChanged: false,
      modifiedName: savedSearch.name,
      showModalAsSaveCopy: false,
      savedSearchLoadError: false,
      savedSearch
    })

    if (!isSameSavedSearch) {
      setTimeout(() => {
        this.runQuery(1)
      }, 50)

      setTimeout(() => {
        if (this.isTeamSearch) {
          UserActionCreators.createMetric(AppConstants.metricNames['auctions_load_team_search'])
        }
      }, 2000);
    }
  }

  initializeEmptySearch = () => {
    const physicalCodes = AuctionStore.savedSearches?.global?.physical_codes || SessionStore.user?.auction_codes
    var savedSearch = AuctionFunctions.initializeEmptySavedSearch({zip_code: SessionStore.user?.zip_code})
    savedSearch = AuctionFunctions.sanitizeSearchDefaults(savedSearch, physicalCodes)
    this.setState({
      savedSearch,
      modifiedName: ''
    })

    setTimeout(() => {
      this.loadCoordinatesForZipCodeFromSavedSearch(savedSearch)
    }, 500)
  }

  runQuery = (page = 1) => {
    // Multiple queries can be in flight.  Only subscribe to changes to this particular query
    const newQueryID = this.queryID + 1
    AuctionStore.on(`auction_vehicles_search_change-${this.clientID}${newQueryID}`, this.queryDidFinish)
    AuctionStore.removeListener(`auction_vehicles_search_change-${this.clientID}${this.queryID}`, this.queryDidFinish)
    this.queryID = newQueryID
    

    const { savedSearch, isShowingNewlyAdded } = this.state
    let modifiedSearch = {...savedSearch}
    if (isShowingNewlyAdded) {
      modifiedSearch.order = 'created_at DESC'
    }

    if (page === 1) {
      this.setState({
        isPerformingQuery: true
      })
    }
    
    const hasAuctionsPlus = SessionStore.hasAuctionsPlus()
    // FIXME: no longer needed since users w/o auctions plus don't get results at all
    if (!hasAuctionsPlus) {
      // Strip auctions plus params if user doesn't have it
      modifiedSearch = AuctionFunctions.stripAuctionsPlusServerParams(modifiedSearch)
    }
    
    let serverSearch = AuctionFunctions.savedSearchServerQueryFormatter(modifiedSearch)
    serverSearch.search_type = 'user'
    console.log("____SEARCH____", serverSearch);


    AuctionsActionCreators.searchAuctionListings(page, serverSearch, this.clientID, `${this.queryID}`)


    if (hasAuctionsPlus) {
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_query_with_access'])
    } else {
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_query_without_access'])
    }

    // FIXME: no longer used now that non subscribers cannot search
    // UserActionCreators.createMetric(AppConstants.metricNames['auctions_query_run'])
    // if (AuctionFunctions.hasAuctionsPlusParams(modifiedSearch)) {
    //   UserActionCreators.createMetric(AppConstants.metricNames['auctions_query_run_paid'])
    // } else {
    //   UserActionCreators.createMetric(AppConstants.metricNames['auctions_query_run_free'])
    // }
  }

  queryDidFinish = () => {
    // FIXME: handle initial stuff vs. pagination
    const isInitial = AuctionStore.auctionListingsSearchResultsPageNumber.dataWithID(this.clientID) === 1
    if (isInitial) {
      const { savedSearch } = this.state
      if (savedSearch?.uuid && this.hasLoadedInitialSearch === false) {
        const totalCount = AuctionStore.auctionListingsSearchResultsTotalCount.dataWithID(this.clientID)
        UserActionCreators.createMetric(AppConstants.metricNames['auctions_load_search'], savedSearch.uuid, {results_count: totalCount})
      }
    }
    this.hasLoadedInitialSearch = true
    this.updateSearchResultListings()
  }

  updateSearchResultListings = () => {
    this.setState({
      listings: AuctionStore.auctionListingsSearchResults.dataWithID(this.clientID),
      listingsTotalCount: AuctionStore.auctionListingsSearchResultsTotalCount.dataWithID(this.clientID),
      listingsTodayCount: AuctionStore.auctionListingsSearchResultsTodayCount.dataWithID(this.clientID),
      isPerformingQuery: false,
      isPerformingPaginatedQuery: false
    })
  }

  onScrollEnd = () => {
    if (AuctionStore.auctionListingsSearchResultsEndOfListReached.dataWithID(this.clientID) === true) { return }

    const { upcomingVehicles } = this.state
    const isEmptyResults = upcomingVehicles && upcomingVehicles.length === 0

    if (!this.state.isPerformingPaginatedQuery && !isEmptyResults) {
      const newPage = AuctionStore.auctionListingsSearchResultsPageNumber.dataWithID(this.clientID) + 1
      this.runQuery(newPage)
      platformFunctions.logAnalyticsEvent("Auctions", "Saved Search Listings Pagination")
      this.setState({
        isPerformingPaginatedQuery: true
      })
    }
  }


  saveSearch = () => {
    const { savedSearch } = this.state
    
    if (savedSearch?.uuid) {
      this.setState({ isUpdatingSavedSearch: true })
      const params = {listing_search: AuctionFunctions.savedSearchServerParams(this.state.savedSearch, this.state.modifiedName, AuctionStore.savedSearches?.global?.physical_codes)}
      AuctionsActionCreators.updateSavedSearch(savedSearch.uuid, params, this.clientID)

      const totalCount = AuctionStore.auctionListingsSearchResultsTotalCount.dataWithID(this.clientID)
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_save_search'], savedSearch.uuid, {results_count: totalCount})
    } else {

      // Can't save if not a plus member and already have a saved search
      if (AuctionStore.savedSearches?.user?.length > 0 && !SessionStore.hasAuctionsPlus()) {
        this.setState({
          isShowingUpsellModal: true,
          auctionsUpsellSource: 'attempted-create',
        })
      } else {
        this.setState({ isShowingSaveSearchModal: true })
      }
    }
  }

  createSearch = (asDuplicate = false) => {
    const { savedSearch, modifiedName } = this.state
    this.setState({ isCreatingSavedSearch: true })

    var params = AuctionFunctions.savedSearchServerParams(savedSearch, modifiedName, AuctionStore.savedSearches?.global?.physical_codes)

    if (asDuplicate) {
      params.uuid = null
    }
    AuctionsActionCreators.createSavedSearch({listing_search: params}, this.clientID)
  }

  multipleSearchParamsDidChange = (params) => {
    var updatedSavedSearch = {...this.state.savedSearch}
    for (let param of params) {
      updatedSavedSearch[param.name] = param.value
    }

    this.setState({
      savedSearch: updatedSavedSearch,
      savedSearchHasChanged: true
    })

    setTimeout(() => {
      this.runQuery()
    }, 50)
  }

  searchDidChange = (paramName, value) => {
    var updatedSavedSearch = {...this.state.savedSearch}
    updatedSavedSearch[paramName] = value
    this.setState({
      savedSearch: updatedSavedSearch,
      savedSearchHasChanged: true
    })

    setTimeout(() => {
      this.runQuery(1)
    }, 50);
  }

  nameDidChange = (name) => {
    var nameValidationError = null
    if (name.trim().length > 0) {
      if (this.nameAlreadyExists(name)) {
        nameValidationError = "You already have a saved search with this name"
      } else {
        nameValidationError = null
      }
    } else {
      nameValidationError = "Enter a name for the saved search"
    }
    this.setState({
      nameValidationError,
      modifiedName: name
    })
  }

  auctionLocationsDidChange = (values) => {
    var newLocations = []
    if (values.length === 0) {
      // User has deleted all locations, add the sentinel to tell server not to default
      // to the user's auctions for searches
      newLocations = [AppConstants.auctionsSearchEmptyLocationsSentinel]
    } else {
      for (let v of values) {
        newLocations.push(v.value)
      }
    }
    this.searchDidChange('physical_codes', newLocations)
  }

  nameAlreadyExists = (name) => {
    const { savedSearch, showModalAsSaveCopy } = this.state
    const savedSearches = AuctionStore.savedSearches?.user
    const existingSavedSearch = savedSearches?.find((s) => s['name'].toLowerCase() === name.trim().toLowerCase())

    var doesNameExist = Boolean(savedSearches && existingSavedSearch)
    if (doesNameExist && !showModalAsSaveCopy && existingSavedSearch && existingSavedSearch.uuid === savedSearch.uuid) {
      doesNameExist = false
    }

    return doesNameExist
  }

  isValidSavedSearchName = () => {
    const { modifiedName } = this.state

    if (modifiedName.trim().length === 0) {
      return false
    } else if (modifiedName.trim() === 'new') {
      return false
    } else if (Boolean(!this.nameAlreadyExists(modifiedName))) {
      return true
    }
    return false
  }

  stripWhatsNewFromUrl = () => {
    let queryParams = new URLSearchParams(location.search)
    queryParams.delete('whats-new')
    this.props.history.replace({
      search: queryParams.toString(),
    })
  }

  onListingClick = (event, listingID) => {
    event.preventDefault()

    this.setState({
      clickedListingID: listingID,
      isShowingListing: true
    })
  }

  renderSaveButton = (isSaveable, isPersisted, className) => {
    return (
      <a href="#save"
      className={`${isSaveable ? 'saved-search-save-enabled' : ''} ${className}`}
        id='save'
        onClick={(e) => {
          e.preventDefault()
          if (isSaveable) {
            this.saveSearch()
          }
        }}
        style={{cursor: !isSaveable ? 'not-allowed' : 'pointer'}}>
          {
            isSaveable ? <SaveIconFilled width='1em' height='1em' /> : <SaveIcon width='1em' height='1em' />
          }
            Save Search{isPersisted ? '' : '...'}</a>
    )
  }

  handleScroll = (event) => {
    const scrollLimit = 220
    const scrollY = window.scrollY
    const { isShowingFloatingSaveButton } = this.state

    if (scrollY > scrollLimit && isShowingFloatingSaveButton === false) {
      this.setState({ isShowingFloatingSaveButton: true })
    } else if (window.scrollY <= scrollLimit && isShowingFloatingSaveButton === true) {
      this.setState({ isShowingFloatingSaveButton: false })
    }
  }

  isTeamSearch = () => {
    // FIXME: this should come from the server
    const { savedSearch } = this.state
    const savedSearches = AuctionStore.savedSearches
    const teamSavedSearches = AuctionStore.savedSearches?.team
    if (savedSearch && savedSearches && teamSavedSearches && teamSavedSearches.find((s) => s.uuid === savedSearch.uuid)) {
      return true
    }

    return false
  }

  onWatchlistToggle = (listing) => {
    UserActionCreators.createMetric(AppConstants.metricNames.auctions_watchlist_search)
  }


  render() {
    const { isLoadingSavedSearch, savedSearch, isPerformingQuery, listings, listingsTotalCount, listingsTodayCount, auctionListingsDisplayType, isShowingSaveSearchModal, nameValidationError, isCreatingSavedSearch, isUpdatingSavedSearch, isDeletingSavedSearch, showModalAsSaveCopy, modifiedName, savedSearchHasChanged, saveToolTipIsOpen, coordinatesForZip, isShowingNewlyAdded, isSidebarCollapsed, isShowingListing, clickedListingID, savedSearchLoadError } = this.state

    const hasAuctionsPlus = SessionStore.hasAuctionsPlus()
    const isEmptyResults = listings && listings.length === 0
    const isPersisted = Boolean(savedSearch?.uuid)
    const isSaveable = hasAuctionsPlus && ((isPersisted && savedSearchHasChanged) || !isPersisted)

    const shouldShowCollapsedSidebar = isPersisted && isSidebarCollapsed
    const dynamicSidebarWidth = shouldShowCollapsedSidebar ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH
    const resultsLeftMargin = shouldShowCollapsedSidebar ? '20px' : `${dynamicSidebarWidth}px`

    const isTeamSearch = this.isTeamSearch()
    const isTrialingAuctionsPlus = SessionStore.hasAuctionsPlus() && !SessionStore.hasPurchasedSKU('feature_auctions_plus')


    return (
      <div className="body-content" style={{position: 'relative', overflowX: 'hidden'}}>
        {
          isLoadingSavedSearch &&
          <Spinner />
        }

        {
          savedSearchLoadError &&
          <div className="content-header">There was a problem loading this saved search</div>
        }

        {
          !isLoadingSavedSearch && savedSearch &&
          <AuctionsSearchFilters
            savedSearch={savedSearch}
            onSearchChange={this.searchDidChange}
            onMultipleSearchParamsChange={this.multipleSearchParamsDidChange}
            onAuctionsChange={this.auctionLocationsDidChange}
            onListingClick={this.onListingClick}
            onShowEditChannelsModal={this.onShowEditChannelsModal}
            isPersisted={isPersisted}
            shouldShowCollapsedSidebar={shouldShowCollapsedSidebar}
            dynamicSidebarWidth={dynamicSidebarWidth}
            onToggleSidebar={() => {
              SessionStore.setHiddenAuctionsSearchFilters(!isSidebarCollapsed)
              this.setState({ isSidebarCollapsed: !isSidebarCollapsed })
            }}
            onZipCodeChange={this.loadCoordinatesForZipCode}
            coordinatesForZip={coordinatesForZip}
             />
        }

        {
          !isLoadingSavedSearch && savedSearch &&
          <div style={{marginLeft: `${resultsLeftMargin}`, paddingLeft: '0px', position: 'relative'}}>

            {
              this.state.isShowingFloatingSaveButton && isSaveable &&
              this.renderSaveButton(isSaveable, isPersisted, 'fixed-saved-search-save-button')
            }

            <div className="content-header">
              <h1 style={{fontSize: savedSearch.name?.length > 20 ? '1.8rem' : ''}}>{savedSearch.name?.length > 0 ? savedSearch.name : 'New Search'}</h1>

              <div className="global-tools">
                <AccountBug />
                <CalculatorBug />
              </div>
            </div>

            <div className="content-context-actions search-content-context-actions" style={{marginBottom: '10px'}}>

              {
                !isTeamSearch &&
                this.renderSaveButton(isSaveable, isPersisted)
              }

              {
                !isSaveable && !isTeamSearch &&
                <Tooltip placement="bottom" isOpen={saveToolTipIsOpen} target="save" toggle={() => this.setState({ saveToolTipIsOpen: !saveToolTipIsOpen})}>
                  {hasAuctionsPlus ? 'There are no changes to save' : 'Add Auctions Plus to save your search'}
                </Tooltip>
              }
              {
                isPersisted && hasAuctionsPlus &&
                <a href="#save-a-copy"
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({
                      isShowingSaveSearchModal: true,
                      showModalAsSaveCopy: true,
                      modifiedName: ''
                    })
                  }}><DuplicateIcon width='1em' height='1em' /> Save a copy...</a>
              }
              {
                isPersisted && !isTeamSearch && hasAuctionsPlus &&
                <a href="#edit" onClick={(e) => {e.preventDefault(); this.setState({ isShowingSaveSearchModal: true })}}><EditIcon width='1em' height='1em' /> Edit / Rename / Delete...</a>
              }
            </div>

            {
              isTeamSearch &&
              <div>This is a saved search created by {savedSearch?.user_full_name ? savedSearch.user_full_name : 'another user on your team'}.  You can save a copy if you would like to modify it.</div>
            }

            {
              isTrialingAuctionsPlus &&
              <div className='secondary-text'>You currently have a free trial for {AppConstants.providerNames['auctions_plus']} which allows you to create unlimited saved searches and search by vehicle price estimate, profit, and much more.</div>
            }


            <div className='speed-search-tabs' style={{alignItems: 'center'}}>
              {
                Boolean(listingsTotalCount !== null || listingsTodayCount !== null) && !isPerformingQuery &&
                <>
                  <a href="#" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingNewlyAdded: false })
                    this.runQuery(1)
                    
                  }} className={isShowingNewlyAdded ? '' : 'active'}>
                    {Formatters.formatThousands(listingsTotalCount)} {pluralize('vehicle', listingsTotalCount, false)} found
                    </a>
                  <a href="#" onClick={(e) => {
                    e.preventDefault()
                    this.setState({
                      isShowingNewlyAdded: true,
                      listings: null
                    })
                    setTimeout(() => {
                      this.runQuery(1)
                    }, 100)
                    
                    UserActionCreators.createMetric(AppConstants.metricNames['auctions_today_view_show'])
                  }} className={isShowingNewlyAdded ? 'active' : ''}>{listingsTodayCount !== null ? Formatters.formatThousands(listingsTodayCount) : 0} added today</a>
                </>
              }
            </div>


            <div className="card" style={{border: 'none', boxShadow: 'none', backgroundColor: 'transparent', borderTop: 'none', marginTop: '0px'}}>
              {
                listings && !isPerformingQuery && !isEmptyResults &&
                <div className="card-list" style={{backgroundColor: 'transparent', minHeight: '80vh'}}>
                  <AuctionVehiclesDisplayBar
                    defaultDisplayType={SessionStore.auctionListingsDisplayType}
                    currentSort={savedSearch.order}
                    onSortChange={(value) => {
                      this.searchDidChange('order', value)
                    }}
                    showSort={!isShowingNewlyAdded}
                    onListingsDisplayModeChange={(newAuctionListingsDisplayType) => { 
                      this.setState({auctionListingsDisplayType: newAuctionListingsDisplayType })
                      SessionStore.setAuctionListingsDisplayType(newAuctionListingsDisplayType)
                      UserActionCreators.createMetric(AppConstants.metricNames['auctions_list_type_change'], newAuctionListingsDisplayType)
                    }
                      
                    }
                    style={{paddingLeft: 0}} />

                  {
                    isShowingNewlyAdded &&
                    <div>Showing newly added vehicles for this search.  <a href="#toggle" style={{color: '#e95656'}} onClick={(e) => {
                      e.preventDefault()
                      this.setState({ isShowingNewlyAdded: false })
                      this.runQuery(1)
                    }}>View all search results instead</a>.</div>
                  }

                  {
                    hasAuctionsPlus &&
                    <AuctionsListingsList
                      isActivityList={isShowingNewlyAdded}
                      listings={listings}
                      onScrollEnd={this.onScrollEnd}
                      auctionListingsDisplayType={auctionListingsDisplayType}
                      hasMore={AuctionStore.auctionListingsSearchResultsEndOfListReached.dataWithID(this.clientID) !== true}
                      showRunNumber={false}
                      rowStyle={{padding: '10px 0'}}
                      showChannel
                      onWatchlistToggle={this.onWatchlistToggle}
                      onClick={(event, listingID) => {
                        this.onListingClick(event, listingID)
                      }} />
                  }
                </div>
              }

              {
                !hasAuctionsPlus && !isPerformingQuery &&
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', top: '160px', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', backgroundColor: '#fff', boxShadow: '0 2px 30px rgba(0,0,0,0.15)', borderRadius: '10px', border: '1px solid #aaa', padding: '40px', minWidth: '400px'}}>
                    <img src="/images/auctions-plus-logo.png" alt="Auctions Plus" style={{width: '220px', height: '37px', marginBottom: '20px'}} />

                    <div style={{fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '20px', lineHeight: '1.4rem'}}>Upgrade to Auctions Plus to see the search results</div>

                    {
                      listingsTotalCount && listingsTotalCount > 0 &&
                      <div style={{marginBottom: '30px'}}>See the {Formatters.formatThousands(listingsTotalCount)} results {listingsTodayCount && listingsTodayCount > 0 ? ` (including ${Formatters.formatThousands(listingsTodayCount)} new listings added today)` : ''}</div>
                    }

                    <div style={{fontSize: '0.9rem'}}>Auctions Plus allows you to create unlimited saved searches and search by vehicle price estimate, profit, and much, much more.</div>

                    <button className='btn btn-primary' style={{marginTop: '20px'}} onClick={() => {
                      this.setState({ isShowingUpsellModal: true, auctionsUpsellSource: 'hidden-results' })
                    }}>Add Auctions Plus</button>
                  </div>
                    
                  <img src="/images/no-auctions-plus-placeholder.jpg" style={{width: '100%'}} />
                </div>
              }

              {
                !isPerformingQuery && isEmptyResults && hasAuctionsPlus &&
                <div>
                  <div>There are currently no results found for this search</div>
                  {
                    savedSearch?.location_type === 'auctions' && savedSearch?.physical_codes?.length === 1 && savedSearch.physical_codes[0] === AppConstants.auctionsSearchEmptyLocationsSentinel && savedSearch?.digital_codes?.length === 0 &&
                    <div><WarningCircleIcon /> You do not have any physical auctions or digital sales selected.</div>
                  }
                </div>
              }

              {
                isPerformingQuery &&
                <Spinner />
              }
            </div>

          </div>
        }
        
        {
          isShowingSaveSearchModal &&
          <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}} animation={false} autoFocus={false}>
            <ModalHeader className='modal-header'>
              {showModalAsSaveCopy ? 'Save a Copy' : (isPersisted ? 'Edit Search' : 'Save Search') }
            </ModalHeader>
            <ModalBody>
              {
                !isCreatingSavedSearch && !isUpdatingSavedSearch &&
                <form onSubmit={(e) => {e.preventDefault(); this.createSearch()}}>
                  <div className='secondary-text'>Name of saved search</div>
                  <Input
                    type="text"
                    className="field"
                    style={{marginBottom: 0}}
                    onChange={(e) => {this.nameDidChange(e.target.value)}}
                    value={modifiedName}
                    placeholder="Name of search"
                    autoFocus={true}
                    maxLength={50}
                    onFocus={(e) => {e.target.select()}} />
                    <div style={{color: 'red', marginTop: '2px', height: '20px'}}>
                    {
                      Boolean(nameValidationError) &&
                      <span>{nameValidationError}</span>
                    }
                    </div>

                  <div className="modal-controls" style={{marginTop: '20px'}}>
                    <Button color="secondary"
                      onClick={() => {
                        this.setState({
                          isShowingSaveSearchModal: false,
                          showModalAsSaveCopy: false,
                          modifiedName: '',
                          nameValidationError: null
                        })
                      }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        disabled={Boolean(!this.isValidSavedSearchName())}
                        onClick={() => {
                          if (showModalAsSaveCopy) {
                            this.createSearch(true)
                          } else {
                            isPersisted ? this.saveSearch() : this.createSearch()
                          }
                        }}>
                        {isPersisted ? (showModalAsSaveCopy ? 'Save Copy' : 'Update') : 'Save'}
                      </Button>
                  </div>
                  {
                    isPersisted && !showModalAsSaveCopy &&
                    <a href="#delete"
                    style={{display: 'inline-block', marginTop: '10px'}}
                    onClick={(e) => {
                      e.preventDefault()
                      if (window.confirm('Are you sure you want to delete this saved search?')) {
                        UserActionCreators.createMetric(AppConstants.metricNames['auctions_delete_search'])
                        this.setState({
                          isDeletingSavedSearch: true
                        })
                        AuctionsActionCreators.deleteSavedSearch(savedSearch.uuid)
                      }
                    }}><DeleteIcon /> Delete this saved search</a>
                  }
                </form>
              }
              {
                (isCreatingSavedSearch || isUpdatingSavedSearch || isDeletingSavedSearch) &&
                <Spinner />
              }

            </ModalBody>
          </Modal>
        }

        {
          this.state.isShowingUpsellModal &&
          <AuctionsPlusUpsellModal
            onClose={() => {
              this.setState({ isShowingUpsellModal: false })
            }}
            onDone={() => {
              this.setState({ isShowingUpsellModal: false })
            }}
            metricsValue={this.state.auctionsUpsellSource}
            />
        }


        <Prompt
          when={savedSearchHasChanged && hasAuctionsPlus}
          message='You have unsaved changes, are you sure you want to leave?'
        />

        {
          isShowingListing &&
          <AuctionsListingShowModal
            listingsContext={listings}
            listingID={clickedListingID}
            history={this.props.history}
            onDone={() => {
              this.setState({ isShowingListing: false })
            }} />
        }
      </div>
    )
  }
}