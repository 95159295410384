import React from 'react'
import { useDrop } from 'react-dnd'
import SessionStore from '../../api/stores/SessionStore'
const style = {
}
const ItemTypes = {
  BOX: 'box',
}

const DroppableFolder = ({folder, children, className, enabled}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    canDrop(props, monitor) {
      if (enabled === false) {
        return false
      }
      const valuation = monitor.getItem()
      const user = SessionStore.user
      if (valuation && valuation['valuation']) {
        const vehicleOwner = valuation['valuation'].user
        const isVehicleOwner = vehicleOwner && vehicleOwner.uuid === user.uuid
        return isVehicleOwner
      }
      return false
    },
    accept: ItemTypes.BOX,
    drop: () => ({ folder: folder }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  var combinedClassName = `${className}`


  if (isActive) {
    combinedClassName = `${className} droppable-active` 
  } else if (canDrop) {
    combinedClassName = `${className} droppable`
  }

  return (
    <div ref={drop} style={{ ...style }} className={combinedClassName}>
      { children }
    </div>
  )
}
export default DroppableFolder
