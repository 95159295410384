import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'


export default class AutoCheckReport extends React.Component {
  state = {
    autoCheckFullReport: null,
    isLoadingFullReport: true,
    errors: null
  }

  componentDidMount() {
    const loc = window.location.href
    const startLoc = loc.indexOf('/autocheck_report') + 18
    this.vehicleID = loc.substring(startLoc)
    ValuationStore.on('autocheck_report_changed', this.autoCheckReportDidChange)
    ValuationActionCreators.loadAutoCheckReport(this.vehicleID)
  }

  componentWillUnmount() {
    ValuationStore.removeListener('autocheck_report_changed', this.autoCheckReportDidChange)
  }

  autoCheckReportDidChange = () => {
    this.setState({
      isLoadingFullReport: false,
      autoCheckFullReport: ValuationStore.autoCheckReport
    })
  }

  viewFullReport = (e) => {
    e.preventDefault()
    this.setState({
      isLoadingFullReport: true
    })

  }


  render() {
    const { autoCheckFullReport, isLoadingFullReport, errors } = this.state

    return (
      <div className="carfax-full-report">
        {
          isLoadingFullReport &&
          <Spinner />
        }

        {
          autoCheckFullReport && errors === null &&
          <div className="carfax-full-report-c" dangerouslySetInnerHTML={{__html: this.state.autoCheckFullReport}}>
          </div>
        }

        {
          errors !== null &&
          <div className="carfaxFullReport-c" style={{textAlign: 'center'}}>
            Sorry, we are unable to load this report
          </div>
        }
      </div>
    )
  }
}
