import { useMemo } from 'react'

const useReportData = (data, { dealerType, inventoryLevelsMake, salesByModelMake }) => {
  const { model_stats, dealer_stats, avg_price_by_year, ...rest } = data

  const allLowestDaysOnLotVehicles = useMemo(() => {
    return model_stats.filter(m => m.num_sold >= 3)
  }, [model_stats])

  const allHighestSellingVehicles = useMemo(() => {
    return model_stats.filter(m => m.num_sold >= 1)
  }, [model_stats])

  const allHighestDemandVehicles = useMemo(() => {
    return model_stats.filter(m => m.demand_score > 0)
  }, [model_stats])

  const inventoryLevels = useMemo(() => {
    const all = model_stats.filter(m => m.average_inventory > 0)
    const filtered = inventoryLevelsMake ? all.filter(m => m.make === inventoryLevelsMake) : all

    return {
      inventory_levels_make_filter_options: Array.from(new Set(all.map(a => a.make))),

      inventory_levels_low: [...filtered]
        .filter(m => m.current_inventory < m.average_inventory)
        .sort((a, b) => a.current_inventory_ratio - b.current_inventory_ratio),

      inventory_levels_high: [...filtered]
        .filter(m => m.current_inventory > m.average_inventory)
        .sort((a, b) => b.current_inventory_ratio - a.current_inventory_ratio),
    }
  }, [model_stats, inventoryLevelsMake])

  const salesByModelFilterOptions = useMemo(() => {
    return {
      sales_by_model_make_filter_options: Array.from(new Set(
        [
          ...allHighestDemandVehicles.map(a => a.make),
          ...allLowestDaysOnLotVehicles.map(a => a.make),
          ...allHighestSellingVehicles.map(a => a.make),
        ]
      )),
    }
  }, [allLowestDaysOnLotVehicles, allHighestSellingVehicles, allHighestDemandVehicles])

  const salesByModel = useMemo(() => {
    const filteredHighestSellingVehicles = salesByModelMake ? allHighestSellingVehicles.filter(m => m.make === salesByModelMake) : allHighestSellingVehicles
    const filteredLowestDaysOnLotVehicles = salesByModelMake ? allLowestDaysOnLotVehicles.filter(m => m.make === salesByModelMake) : allLowestDaysOnLotVehicles
    const filteredHighestDemandVehicles = salesByModelMake ? allHighestDemandVehicles.filter(m => m.make === salesByModelMake) : allHighestDemandVehicles

    return {
      highest_selling_vehicles: [...filteredHighestSellingVehicles]
        .sort((a, b) => b.num_sold - a.num_sold || a.avg_days_on_lot - b.avg_days_on_lot),

      lowest_days_on_lot_vehicles: [...filteredLowestDaysOnLotVehicles]
        .sort((a, b) => a.avg_days_on_lot - b.avg_days_on_lot || b.num_sold - a.num_sold),

      highest_demand_vehicles: [...filteredHighestDemandVehicles]
        .sort((a, b) => b.demand_score - a.demand_score || b.num_sold - a.num_sold),
    }
  }, [allLowestDaysOnLotVehicles, allHighestSellingVehicles, allHighestDemandVehicles, salesByModelMake])

  const dealerStats = useMemo(() => {
    const d = {
      all: () => dealer_stats,
      indy: () => dealer_stats.filter(d => d.business_type === null),
      franchise: () => dealer_stats.filter(d => d.business_type === 'oem'),
    }[dealerType]();

    return {
      most_active_dealers: d
        .filter(d => d.num_sold > 0)
        .sort((a, b) => b.num_sold - a.num_sold),

      lowest_days_on_lot_dealers: d
        .filter(d => d.avg_days_on_lot > 0 && d.num_sold > 10)
        .sort((a, b) => a.avg_days_on_lot - b.avg_days_on_lot),

      current_highest_inventory_value_dealers: d
        .filter(d => d.inventory_value > 1000)
        .sort((a, b) => b.inventory_value - a.inventory_value),

      current_highest_inventory_dealers: d
        .filter(d => d.inventory > 0)
        .sort((a, b) => b.inventory - a.inventory),
    }
  }, [dealer_stats, dealerType])

  const avgPriceByYear = useMemo(() => {
    return {
      avg_price_by_year: avg_price_by_year
        .reverse().slice(0, 40).reverse(),
    }
  }, [avg_price_by_year])

  const result = {
    ...inventoryLevels,
    ...salesByModel,
    ...dealerStats,
    ...avgPriceByYear,
    ...salesByModelFilterOptions,

    model_stats,
  }

  return {
    ...rest,
    ...result,
  }
}

export default useReportData
