import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import QueryParamsFunctions from '../../api/constants/QueryParamsFunctions'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'

export default class ForgotPassword extends React.Component {

  state = {
    email: this.props.email ? this.props.email : '',
    secureToken: '',
    newPassword: '',
    newPasswordRepeated: '',
    isShowingEnterCode: false,
    didRequestCode: false,
    forgotIsLoading: false,
    error: false,
    newPasswordSaved: false,
    newPasswordSaveErrors: false,
    isNewPasswordValid: false,
    doNewPasswordsMatch: false
  }

  componentDidMount() {
    SessionStore.on("forgot_password_change", this.forgotPasswordResponse)
    SessionStore.on("set_new_password_change", this.setNewPasswordResponse)

    const paramsObj = QueryParamsFunctions.toObject()
    if (Boolean(paramsObj && paramsObj['forgotPasswordCode'])) {
      this.setState({
        secureToken: paramsObj['forgotPasswordCode'],
        isShowingEnterCode: true
      })
    }

    if (Boolean(paramsObj && paramsObj['email'] && this.state.email === '')) {
      this.setState({
        email: paramsObj['email']
      })
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("forgot_password_change", this.forgotPasswordResponse)
    SessionStore.removeListener("set_new_password_change", this.setNewPasswordResponse)
  }

  newPasswordDidChange = (e) => {
    var text = e.target.value

    var isNewPasswordValid = text && text.length >= 8

    this.setState({
      newPassword: text,
      isNewPasswordValid: isNewPasswordValid
    })
    setTimeout(() => this.checkNewPasswordsMatch(), 100)
  }

  newPasswordRepeatedDidChange = (e) => {
    var text = e.target.value

    var isNewPasswordValid = text && text.length >= 8

    this.setState({
      newPasswordRepeated: text,
      isNewPasswordValid: isNewPasswordValid
    })
    setTimeout(() => this.checkNewPasswordsMatch(), 100)
  }

  checkNewPasswordsMatch = () => {
    const { newPassword, newPasswordRepeated } = this.state
    const doNewPasswordsMatch = newPassword === newPasswordRepeated
    this.setState({
      doNewPasswordsMatch: doNewPasswordsMatch
    })
  }

  forgotPasswordResponse = () => {
    const error = SessionStore.forgotPasswordErrors === true
    console.log("EROR: ", error);
    if (!error) {
      this.setState({
        forgotIsLoading: false,
        didRequestCode: true
      })
    } else {
      this.setState({
        forgotIsLoading: false,
        didRequestCode: false,
        isShowingEnterCode: false,
        error: error
      })
    }
  }

  requestSecureCode = (e) => {
    // Send email to get a code mailed
    e.preventDefault()

    this.setState({
      error: false,
      forgotIsLoading: true,
      gotForgotPasswordResponse: false
    })
    SessionActionCreators.submitForgotPassword(this.state.email)
  }


  setNewPasswordResponse = () => {
    const success = !SessionStore.setNewPasswordErrors || SessionStore.setNewPasswordErrors !== true

    this.setState({
      newPasswordSaveErrors: !success,
      newPasswordSaved: success,
      forgotIsLoading: false
    })
  }

  submitNewPassword = (e) => {
    // Send email to get a code mailed
    this.setState({
      forgotIsLoading: true
    })

    SessionActionCreators.setNewPassword(this.state.secureToken, this.state.newPassword)
  }



  render() {
    const { email, forgotIsLoading, isShowingEnterCode, didRequestCode, secureToken, newPassword, newPasswordRepeated, doNewPasswordsMatch, error, newPasswordSaved, newPasswordSaveErrors, isNewPasswordValid } = this.state
    const newPasswordSubmitEnabled = isNewPasswordValid && doNewPasswordsMatch

    if (forgotIsLoading) {
      return (
        <div>
          <div className="login-spinner-c" style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spinner className='spinner-medium' />
          </div>
          <div style={{textAlign: 'center'}}>
            <a href="#cancel" onClick={(e) => {e.preventDefault(); this.props.onCancel()}}>Cancel</a>
          </div>
        </div>
      )
    }

    if (newPasswordSaveErrors) {
      return (
        <div>
          <div style={{textAlign: 'center'}}>
            <p style={{marginBottom: '20px'}}>We were unable to update your password.  Please call us at {AppConstants.phoneNumber}</p>
            <a href="#cancel" onClick={(e) => {e.preventDefault(); this.props.onCancel()}}>Cancel</a>
          </div>
        </div>
      )
    }

    if (newPasswordSaved) {
      return (
        <div>
          <div style={{textAlign: 'center'}}>
            <div style={{fontSize: '1.2rem', marginBottom: '20px'}}>Password saved</div>
            <p style={{marginBottom: '20px'}}>You can now <a href="#login" onClick={(e) => {e.preventDefault(); this.props.onComplete(email)}}>login using your new password</a>.</p>
            <a href="#cancel" onClick={(e) => {e.preventDefault(); this.props.onCancel()}}>Cancel</a>
          </div>
        </div>
      )
    }

    return (
      <div>

        {
          !isShowingEnterCode && !didRequestCode &&
          <div className="form-group-collection">
            <div className="form-group">
              <form name="loginForm" onSubmit={this.requestSecureCode}>
                <div style={{marginBottom: '10px'}}>{ error ? 'Unable to find an account with that email address' : 'To reset your password, start by entering your email address'}</div>
                <input type="email" className="field" name="email" onChange={e => this.setState({email: e.target.value})} value={email} placeholder="Email address" autoFocus={true} />
                <input type="submit" value="Submit" className="capsule-button" />
              </form>
            </div>
            <div style={{textAlign: 'center'}}>
              <a href="#cancel" onClick={(e) => {e.preventDefault(); this.props.onCancel()}}>Cancel</a>
              <p style={{marginTop: '20px'}}>If you need assistance, please call us at {AppConstants.phoneNumber}</p>
            </div>
          </div>
        }

        {
          didRequestCode &&
          <div className="form-group-collection">
            <div className="form-group">
              <div style={{marginBottom: '10px'}}>Check your email to find the instructions to reset your password</div>
            </div>
            <div style={{textAlign: 'center'}}>
              <a href="/">Cancel</a>
            </div>
          </div>
        }

        {
          isShowingEnterCode &&
          <div className="form-group-collection">
            <div className="form-group">
              <form name="loginForm" onSubmit={this.submitNewPassword}>
                <div style={{marginBottom: '10px'}}>Please enter your new password</div>
                <input type="text" className="field" name="forgot-code" maxLength={12} onChange={e => this.setState({secureToken: e.target.value.toUpperCase()})} value={secureToken} placeholder="Activation code from your email" style={{display: 'none'}} />
                <input type="password" className="field" style={{marginBottom: '2px'}} name="newPassword" onChange={this.newPasswordDidChange} value={newPassword} placeholder="New password" />
                <div className="secondary-text" style={{marginLeft: '10px'}}>At least 8 characters</div>

                <input type="password" className="field" style={{marginBottom: '2px'}} name="newPassword2" onChange={this.newPasswordRepeatedDidChange} value={newPasswordRepeated} placeholder="New password again" />
                {
                  !doNewPasswordsMatch && newPasswordRepeated.length > 0 && newPassword.length > 0 &&
                  <div className="secondary-text" style={{marginLeft: '10px', color: 'red'}}>Passwords do not match</div>
                }

                <input type="submit" value="Submit" className="capsule-button" disabled={!newPasswordSubmitEnabled} style={{opacity: newPasswordSubmitEnabled ? '1.0' : '0.4'}} />
              </form>
            </div>
            <div style={{textAlign: 'center'}}>
              <a href="/">Cancel</a>
            </div>
          </div>
        }

      </div>
    )
  }









}
