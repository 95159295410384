import { gql } from '@apollo/client'

const CURRENCY_VALUE_FIELDS = gql`
  fragment CurrencyValueFields on CurrencyValue {
    amount
    currency
  }
`

const TIME_RANGE_FIELDS = gql`
  fragment TimeRangeFields on VenueTimeRange {
    startTime
    plannedEndTime
    actualEndTime
  }
`

const ITEM_BID_FIELDS = gql`
  ${CURRENCY_VALUE_FIELDS}
  fragment ItemBidFields on ItemBid {
    id
    bidderId
    bidType
    receivedAt
    status
    bid {
      ...CurrencyValueFields
    }
  }
`

const BIDDER_FIELDS = gql`
  fragment BidderFields on BidEngineBidder {
    id
    name
  }
`

const CURRENT_BID_INFO_FIELDS = gql`
  ${CURRENCY_VALUE_FIELDS}
  fragment CurrentBidInfoFields on CurrentBidInfo {
    bidId
    bidderId
    bidType
    bidAmount {
      ...CurrencyValueFields
    }
  }
`

const VENUE_LISTING_PUBLIC_FIELDS = gql`
  ${CURRENCY_VALUE_FIELDS}
  ${TIME_RANGE_FIELDS}
  ${CURRENT_BID_INFO_FIELDS}
  fragment VenueListingPublicFields on VenueListing {
    id
    venueEventId
    state
    endedStatus
    timeRange {
      ...TimeRangeFields
    }
    openingPrice {
      ...CurrencyValueFields
    }
    floorPrice {
      ...CurrencyValueFields
    }
    buyNowPrice {
      ...CurrencyValueFields
    }
    floorPriceMet
    bidIncrement {
      ...CurrencyValueFields
    }
    numberOfBids
    currentBid {
      ...CurrentBidInfoFields
    }
    updatedAt
  }
`

const VENUE_LISTING_PRIVATE_FIELDS = gql`
  ${VENUE_LISTING_PUBLIC_FIELDS}
  ${ITEM_BID_FIELDS}
  fragment VenueListingPrivateFields on VenueListing {
    ...VenueListingPublicFields
    myActiveBid {
      ...ItemBidFields
    }
    myFailedBid @client {
      ...ItemBidFields
    }
  }
`

export {
  VENUE_LISTING_PRIVATE_FIELDS,
  VENUE_LISTING_PUBLIC_FIELDS,
  CURRENCY_VALUE_FIELDS,
  CURRENT_BID_INFO_FIELDS,
  ITEM_BID_FIELDS,
  TIME_RANGE_FIELDS,
  BIDDER_FIELDS,
}
