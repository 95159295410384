import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_VENUE_LISTINGS } from '../../../api/bidding/queries'

const pageSize = 100

function batchArr(arr) {
  return Array.from(
    new Array(Math.ceil(arr.length / pageSize)),
    (_, i) => arr.slice(i * pageSize, i * pageSize + pageSize)
  )
}

export default (options = {}) => {
  const [
    initialLoad,
    {
      data,
      fetchMore,
      client,
      ...rest
    }
  ] = useLazyQuery(GET_VENUE_LISTINGS, {
    fetchPolicy: 'network-only',
    ...options,
  })

  const getCachedIds = useCallback(() => {
    return Object.values(client.cache.extract())
    .reduce((accum, item ) => {
      if (item.__typename === 'VenueListing') {
        return [
          ...accum,
          item.id,
        ]
      } else {
        return accum
      }
    }, [])
  }, [client.cache])

  const load = useCallback(async ids => {
    const idsBatches = batchArr(ids)
    const firstBatch = idsBatches.shift()
    await initialLoad({ variables: { ids: firstBatch }})
    return Promise.all(idsBatches.map(batch => {
      return fetchMore({ variables: { ids: batch }})
    }))
  }, [initialLoad, fetchMore])

  const fetchPage = useCallback(async ids => {
    const idsBatches = batchArr(ids)
    return Promise.all(idsBatches.map(batch => {
      return fetchMore({ variables: { ids: batch } })
    }))
  }, [fetchMore])

  const refetchCached = useCallback(async () => {
    const ids = getCachedIds()
    if (ids.length) {
      return load(ids)
    }
  }, [load, getCachedIds])

  return [
    load,
    {
      ...rest,
      refetch: load, // There are quirks with apollo refetch - use load, don't rely on apollo's loading state
      fetchPage,
      data: data?.venueListings,
      refetchCached,
      client,
    },
  ]
}
