import { useQuery } from '@apollo/client'
import { GET_VENUE_LISTING } from '../queries'

export default id => {
  const { data, ...rest } = useQuery(GET_VENUE_LISTING, {
    variables: { id },
  })

  return {
    data: data?.venueListing,
    ...rest,
  }
}
