import React from 'react'
import moment from 'moment'

export default class ProvidersLegal extends React.PureComponent {

  render() {
    const kbbDate = moment().format('MM/DD/YYYY')
    const currentYear = moment().format('YYYY')
    return (
      <div>

      <div style={{marginBottom: '20px'}}>
        Carbly® and SpeedView® are registered trademarks of Ambient Automotive, Inc
      </div>
        <div style={{marginBottom: '20px'}}>
          <img src="/images/powered-by-black-book.png" style={{height: '21px', width: 'auto'}} alt="Powered by Black Book®" />
          <div>Black Book® is a registered trademark of Hearst Business Media Corporation. Copyright © {currentYear} Hearst Business Media Corp. ALL RIGHTS RESERVED.</div>
        </div>

        <div style={{marginBottom: '20px'}}>©{currentYear} Kelley Blue Book Co., Inc. All rights reserved. As of {kbbDate} Edition. Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary. The value and pricing information displayed for a particular vehicle is based upon the specification, mileage and/or condition information provided by the person generating this report. Kelley Blue Book assumes no responsibility for errors or omissions.</div>

        <div style={{marginBottom: '20px'}}>NADA Official Used Car Guide®, a registered trademark of the National Automobile Dealers Association, used under license by J.D. Power and Associates.</div>

        <div>Geocoding data provided by <a href="http://www.geonames.org" target="_blank"  rel="noopener noreferrer">GeoNames</a></div>
      </div>
    )
  }

}
