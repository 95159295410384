import React, { useEffect, useState, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import AccountBug from '../user/AccountBug'
import MyLotRow from './MyLotRow'
import Spinner from '../common/Spinner'
import CalculatorBug from '../common/CalculatorBug'
import { ResponsiveBar } from '@nivo/bar'
import SessionStore from '../../api/stores/SessionStore'

const MyLot = ({ isCurrent = true }) => {
  const [
    {
      isLoading,
      results,
      endOfListReached,
      analytics
    },
    setState
  ] = useState({
    isLoading: true,
    results: null,
    endOfListReached: false,
  })

  const onMyLotListingsChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: false,
      results: ValuationStore.myLotListings,
      endOfListReached: true
    }))
  }, [])

  const onMyLotAnalyticsChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      analytics: ValuationStore.myLotAnalytics,
    }))
  }, [])

  const loadMore = useCallback(() => {
  }, [])

  useEffect(() => {
    const ev = isCurrent ? 'my_lot_listings_change' : 'my_lot_listings_past_change'
    ValuationStore.on(ev, onMyLotListingsChange)
    ValuationActionCreators[isCurrent ? 'loadMyLot' : 'loadMyLotPast']()

    ValuationStore.on('my_lot_analytics_change', onMyLotAnalyticsChange)
    ValuationActionCreators.loadMyLotAnalytics()

    return () => {
      ValuationStore.removeListener(ev, onMyLotListingsChange)
      ValuationStore.removeListener('my_lot_analytics_change', onMyLotAnalyticsChange)
    }
  }, [isCurrent])

  if (!SessionStore.hasMyLotAccess()) {
    return null
  }

  return (
    <div className="body-content" style={{overflow: 'visible', minHeight: '500px'}}>
      <div className="content-header">
        <h1>My Lot</h1>

        <div className="global-tools">
          <AccountBug />
          <CalculatorBug />
        </div>
      </div>

      <div className="content-context-actions">
        <Link to={`/vehicles/my_lot/${isCurrent ? 'past' : ''}`}>
          <svg className="bi bi-clock" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"/>
            <path fillRule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          {`View ${isCurrent ? 'past' : 'current'} Listings`}
        </Link>
      </div>

      {
        isLoading &&
        <Spinner />
      }

      {
        analytics &&
        <div className='split-row' style={{marginBottom: '40px'}}>
          <div style={{height: '120px', width: '25%'}}>
            <div>Inventory by days on lot</div>
            <ResponsiveBar
              data={analytics.days_on_lot_groupings.map((grouping) => ({id: grouping.range, value: grouping.count}))}
              colors={['#2c6e8e']}
              margin={{ top: 10, right: 26, bottom: 26, left: 35 }}
              enableLabel={false}
              axisBottom={{
                tickSize: 0,
              }}
            />
          </div>
          <div style={{height: '120px', width: '25%'}}>
            <div>Days on lot trend</div>
            <ResponsiveBar
              data={analytics.days_on_lot_groupings.map((grouping) => ({id: grouping.range, value: grouping.count}))}
              colors={['#2c6e8e']}
              margin={{ top: 10, right: 26, bottom: 26, left: 35 }}
              enableLabel={false}
              axisBottom={{
                tickSize: 0,
              }}
            />
          </div>
          <div style={{height: '120px', width: '25%'}}>
            <div>Inventory levels trend</div>
            <ResponsiveBar
              data={analytics.days_on_lot_groupings.map((grouping) => ({id: grouping.range, value: grouping.count}))}
              colors={['#2c6e8e']}
              margin={{ top: 10, right: 26, bottom: 26, left: 35 }}
              enableLabel={false}
              axisBottom={{
                tickSize: 0,
              }}
            />
          </div>

          <div style={{height: '120px', width: '25%'}}>
              <div>Lot value trend</div>
              <ResponsiveBar
                data={analytics.days_on_lot_groupings.map((grouping) => ({id: grouping.range, value: grouping.count}))}
                colors={['#2c6e8e']}
                margin={{ top: 10, right: 26, bottom: 26, left: 35 }}
                enableLabel={false}
                axisBottom={{
                  tickSize: 0,
                }}
              />
          </div>
        </div>
      }

      {
        results &&
        <div className="card">
          <div className="card-list">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              useWindow={true}
              hasMore={!endOfListReached}
              loader={<Spinner key="pagination-spinner" />} >
              <div>
                {
                  results.map((listing) => {
                    return (
                      <MyLotRow
                        key={listing.id}
                        listing={listing}
                      />
                    )
                  })
                }
              </div>
            </InfiniteScroll>
          </div>
        </div>
      }
    </div>
  )
}

export default MyLot
