import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import DataFreshnessMonitor from '../common/DataFreshnessMonitor'
import AuctionsListingRow from './AuctionsListingRow'
import AccountBug from '../user/AccountBug'
import Formatters from '../../api/constants/Formatters'
import Spinner from '../common/Spinner'
import platformFunctions from '../../platformFunctions'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import CalculatorBug from '../common/CalculatorBug'
import AppConstants from '../../api/constants/AppConstants'
import UserActionCreators from '../../api/actions/UserActionCreators'

const PAGE_SIZE = 40

export default class AuctionSaleShow extends React.Component {

  state = {
    saleIsLoading: true,
    isLoadingMoreListings: false,
    saleListings: null,
    sectionedSaleListings: null,
    hasSaleListingsError: false,
    location: null,
    date: null
  }

  constructor(props) {
    super(props)
    this.currentPage = 0
  }

  componentDidMount() {
    const saleID = this.props.navMatch.params.saleID
    this.clientID = `sl-${saleID}`
    this.lastFreshDataTime = null

    AuctionStore.on(`auction_sale_listings_change_${this.clientID}`, this.saleListingsDidChange)
    AuctionStore.on('global_auction_listing_update', this.getSaleListings)

    AuctionsActionCreators.loadAuctionListingsForSale(saleID, PAGE_SIZE, this.clientID, 0)

    platformFunctions.logAnalyticsEvent("Auctions", "Opened Sale")
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_show_sale_vehicles'])
  }

  componentWillUnmount() {
    AuctionStore.removeListener(`auction_sale_listings_change_${this.clientID}`, this.saleListingsDidChange)
    AuctionStore.removeListener('global_auction_listing_update', this.getSaleListings)
  }

  getSaleListings = () => {
    const newListings = Object.assign(AuctionStore.auctionSaleListingsWithClientID(this.clientID))
    if (this.refs.mainRef) {
      this.restructureSaleListings(newListings)
    }
  }

  saleListingsDidChange = () => {
    const existingListings = this.state.sectionedSaleListings
    if (!existingListings || existingListings.length === 0) {
      this.lastFreshDataTime = moment()
    }
    const newListings = AuctionStore.auctionSaleListingsWithClientID(this.clientID)
    if (newListings) {
      const saleListings = [...newListings]
      this.restructureSaleListings(saleListings)
    } else {
      this.setState({
        hasSaleListingsError: true,
        saleIsLoading: false
      })
    }
  }


  restructureSaleListings = (saleListings) => {
    const newSectionedListings = AuctionFunctions.restructureSaleListings(saleListings)

    this.setState({
      saleIsLoading: false,
      saleListings: saleListings,
      sectionedSaleListings: newSectionedListings,
      isLoadingMoreListings: false,
      hasSaleListingsError: false,
      location: AuctionStore.auctionSaleListingsLocationWithClientID(this.clientID),
      date: AuctionStore.auctionSaleListingsDateWithClientID(this.clientID)
    })
  }


  onListingShow = (listing) => {
    this.props.history.push({
      pathname: `/auctions/listing/${listing.id}`,
      listingsContext: this.state.saleListings
    })
  }


  onScrollEnd = () => {
    const saleID = this.props.navMatch.params.saleID
    const isEnd = AuctionStore.auctionSaleListingsEndOfListReached.dataWithID(this.clientID) === true
    if (isEnd || this.state.saleIsLoading) { return }
    if (!this.state.isLoadingMoreListings) {
      this.currentPage++

      AuctionsActionCreators.loadAuctionListingsForSale(saleID, PAGE_SIZE, this.clientID, this.currentPage * PAGE_SIZE)
      this.setState({
        isLoadingMoreListings: true
      })
    }
  }

  onWatchlistToggle = (listing) => {
    // Hacky.  In addition to the store update, we're immediately updating this list
    var newListings = [...this.state.saleListings]
    var targListing = newListings.find(l => l.id === listing.id)
    if (targListing) {
      targListing.watchlist = !targListing.watchlist
    }
    this.restructureSaleListings(newListings)
    platformFunctions.logAnalyticsEvent("Auctions", "Toggled Watchlist - Sale")
    UserActionCreators.createMetric(AppConstants.metricNames.auctions_watchlist_lane)
  }


  render() {
    const { saleIsLoading, sectionedSaleListings, hasSaleListingsError, location, date } = this.state


    if (saleIsLoading) {
      return (
        <div className="body-content">
          <Spinner className="spinner-page" />
        </div>
      )
    }

    if (hasSaleListingsError === true) {
      return (
        <div className="body-content">
          <div className="content-header">
            <div>
              <h1>Sale Auction Listings</h1>
            </div>
            <div className="global-tools">
              <AccountBug />
              <CalculatorBug />
            </div>
          </div>
          <div style={{marginTop: '40px'}}>
            No auction listings were found for this sale
          </div>
        </div>
      )
    }

    return (
      <div className="body-content">
        <div className="content-header">
          <div>
            <h1>{location.name}</h1>
            <h2>{Formatters.formatVerboseDateWithDay(date)}</h2>
          </div>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          this.lastFreshDataTime !== null &&
          <div style={{marginRight: '20px'}}>
            <DataFreshnessMonitor lastFreshDataTime={this.lastFreshDataTime} label='Auction data is' />
          </div>
        }

        {
          sectionedSaleListings && sectionedSaleListings.length > 0 &&
          <InfiniteScroll
            pageStart={0}
            loadMore={this.onScrollEnd}
            useWindow={true}
            hasMore={AuctionStore.auctionSaleListingsEndOfListReached.dataWithID(this.clientID) !== true}
            loader={<Spinner key="pagination-spinner" />} >


                {
                  sectionedSaleListings.map((lane, index) => {
                    return (
                      <div className="card" key={`sl-${index}`}>
                        <div className="card-list">
                          <h2 className="auction-lane-list-title">Lane {lane.title}</h2>
                          {
                            lane.data.map((listing, index) => {
                              return (
                                <AuctionsListingRow listing={listing} showChannel={false} showRunNumber key={`ll-${listing.id}`} onWatchlistToggle={this.onWatchlistToggle} onListingShow={this.onListingShow}/>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }


          </InfiniteScroll>
        }

        {
          (!sectionedSaleListings || sectionedSaleListings.length === 0) &&
          <div>There are no vehicles listed for this sale</div>
        }

      </div>
    )
  }

}
