import React from 'react'
import Select, {createFilter} from 'react-select'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SearchFunctions from '../../api/constants/SearchFunctions'
import AccountBug from '../user/AccountBug'
import ValuationRow from './ValuationRow'
import Spinner from '../common/Spinner'
import ValuationScopeSelect from './ValuationScopeSelect'
import CommonFunctions from '../../api/constants/CommonFunctions'
import CalculatorBug from '../common/CalculatorBug'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const noValueSentinal = 'None'

export default class ValuationsSearch extends React.Component {
  state = {
    isLoadingCorpus: false,
    isPerformingSearch: false,
    selectedYear: null,
    selectedMake: null,
    selectedModel: null,
    vinSearch: '',
    corpus: null,
    corpusErrors: false,
    results: null,
    endOfListReached: false,
    loadingMore: false,
    scope: ValuationStore.searchVehiclesScope,
  }

  componentDidMount() {
    ValuationStore.on('valuation_corpus_change', this.corpusChange)
    ValuationStore.on('valuation_search_change', this.searchChange)
    ValuationStore.on('valuation_folder_updated', this.folderChange)
    ValuationStore.on('search_vehicles_scope_change', this.onScopeChange)

    if (this.props.accountStatusRestricted !== true) {
      this.loadCorpus()
      this.setState({ isLoadingCorpus: true })
    }
    this.clientID = 'search'

    UserActionCreators.createMetric(AppConstants.metricNames['vehicles_view_search'])
  }

  componentWillUnmount() {
    ValuationStore.removeListener('valuation_corpus_change', this.corpusChange)
    ValuationStore.removeListener('valuation_search_change', this.searchChange)
    ValuationStore.removeListener('valuation_folder_updated', this.folderChange)
    ValuationStore.removeListener('search_vehicles_scope_change', this.onScopeChange)
  }

  onScopeChange = () => {
    this.setState({
      scope: ValuationStore.searchVehiclesScope,
      selectedYear: null,
      selectedMake: null,
      selectedModel: null,
      results: null,
    }, this.loadCorpus)
  }

  loadCorpus = () => {
    ValuationActionCreators.loadValuationsCorpus(this.state.scope)
  }

  onScopeSelect = ({ value }) => {
    ValuationStore.setSearchVehiclesScope(value)
  }

  corpusChange = () => {
    var corpus = ValuationStore.valuationsCorpus
    var corpusErrors = false
    if (!corpus || corpus.errors) {
      corpusErrors = true
      corpus = null
    }

    this.setState({
      isLoadingCorpus: false,
      corpusErrors: corpusErrors,
      corpus: ValuationStore.valuationsCorpus
    })
  }

  searchChange = () => {
    this.setState({
      isPerformingSearch: false,
      loadingMore: false,
      results: ValuationStore.valuationsSearchResults.collection,
      endOfListReached: ValuationStore.valuationsSearchResults.hasLastPage()
    })
  }

  folderChange = () => {
    this.performSearch()
  }

  choicesForYears = () => {
    const { selectedYear, selectedMake, selectedModel, corpus } = this.state
    return SearchFunctions.choicesForYears(selectedYear ? selectedYear.value : null, selectedMake ? selectedMake.value : null, selectedModel ? selectedModel.value : null, corpus)
  }

  choicesForMakes = () => {
    const { selectedYear, selectedMake, selectedModel, corpus } = this.state
    return SearchFunctions.choicesForMakes(selectedYear ? selectedYear.value : null, selectedMake ? selectedMake.value : null, selectedModel ? selectedModel.value : null, corpus)
  }

  choicesForModels = () => {
    const { selectedYear, selectedMake, selectedModel, corpus } = this.state
    return SearchFunctions.choicesForModels(selectedYear ? selectedYear.value : null, selectedMake ? selectedMake.value : null, selectedModel ? selectedModel.value : null, corpus)
  }

  yearWasSelected = (item) => {
    this.setState({ selectedYear: item.value === noValueSentinal ? null : item})
    setTimeout(() => { this.performSearch() }, 200)
  }

  makeWasSelected = (item) => {
    this.setState({
      selectedMake: item.value === noValueSentinal ? null : item,
      selectedModel: null
    })

    setTimeout(() => { this.performSearch() }, 200)
  }

  modelWasSelected = (item) => {
    this.setState({ selectedModel: item.value === noValueSentinal ? null : item})
    setTimeout(() => { this.performSearch() }, 200)
  }

  onVinSearchChange = (value) => {
    const sanitizedVIN = CommonFunctions.sanitizedVIN(value)
    this.setState({ vinSearch: sanitizedVIN })
    setTimeout(() => { this.performSearch() }, 200)
  }

  vinSearchIsValid = () => {
    return this.state.vinSearch && this.state.vinSearch.length > 2
  }

  performSearch = () => {
    const { selectedYear, selectedMake, selectedModel, vinSearch } = this.state
    const _vinSearchIsValid = this.vinSearchIsValid()

    if (selectedYear || selectedMake || selectedModel || _vinSearchIsValid) {
      if (selectedYear || selectedMake || selectedModel) {
        UserActionCreators.createMetric(AppConstants.metricNames['vehicles_search_ymm'])
      }

      if (_vinSearchIsValid) {
        UserActionCreators.createMetric(AppConstants.metricNames['vehicles_search_vin'])
      }
      
      this.setState({
        results: null,
        isPerformingSearch: true
      })
      const params = SearchFunctions.paramsForSearch({
        selectedYear: selectedYear?.value,
        selectedMake: selectedMake?.value,
        selectedModel: selectedModel?.value,
        vinSearch: _vinSearchIsValid ? vinSearch : null,
      })
      ValuationActionCreators.searchValuations(null, params, this.state.scope)
    } else {
      this.setState({
        results: null
      })
    }
  }


  loadMore = () => {
    if (this.state.endOfListReached) {
      return
    }

    if (!this.state.loadingMore) {
      const { selectedYear, selectedMake, selectedModel, vinSearch } = this.state
      const _vinSearchIsValid = this.vinSearchIsValid()
      this.setState({loadingMore: true})
      const params = SearchFunctions.paramsForSearch({
        selectedYear: selectedYear?.value,
        selectedMake: selectedMake?.value,
        selectedModel: selectedModel?.value,
        vinSearch: _vinSearchIsValid ? vinSearch : null,
      })
      ValuationActionCreators.searchValuations(ValuationStore.valuationsSearchResults.getCutoff(), params, this.state.scope)
    }
  }



  render() {
    const { accountStatusRestricted } = this.props
    const { isLoadingCorpus, isPerformingSearch, selectedYear, selectedMake, selectedModel, vinSearch, results, endOfListReached, corpus, corpusErrors } = this.state
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #aaa',
        padding: '10px'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        marginBottom: '5px'
      })
    }

    const filterConfig = {
      matchFrom: 'start'
    }


    return (
      <div className="body-content" style={{overflow: 'visible', minHeight: '500px'}}>
        <div className="content-header">
          <h1>Search Vehicles</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        {
          isLoadingCorpus &&
          <Spinner />
        }

        {
          !isLoadingCorpus && !corpusErrors && corpus &&
          <div>
            <div style={{marginBottom: '10px'}}>Find vehicles you've previously added.</div>


            {
              SessionStore.hasTeam() &&
              <div style={{ marginBottom: 10 }}>
                <ValuationScopeSelect
                  scope={this.state.scope}
                  onChange={this.onScopeSelect}
                />
              </div>
            }

            <div className="search-controls-c">
              <Select
                styles={customStyles}
                className='react-select'
                name="year"
                maxMenuHeight={400}
                value={selectedYear}
                onChange={this.yearWasSelected}
                placeholder='Year...'
                options={
                  this.choicesForYears().map((y) => {
                    return (
                      {value: y, label: y}
                    )
                  })
                }
                filterOption = {
                  createFilter(filterConfig)
                }
              />

              <Select
                styles={customStyles}
                className='react-select'
                name="make"
                maxMenuHeight={400}
                value={selectedMake}
                onChange={this.makeWasSelected}
                placeholder='Make...'
                options={
                  this.choicesForMakes().map((make) => {
                    return (
                      {value: make, label: make}
                    )
                  })
                }
                filterOption = {
                  createFilter(filterConfig)
                }
              />
              <Select
                styles={customStyles}
                className='react-select'
                name="model"
                maxMenuHeight={400}
                value={selectedModel}
                onChange={this.modelWasSelected}
                placeholder='Model...'
                options={
                  this.choicesForModels().map((model) => {
                    return (
                      {value: model, label: model}
                    )
                  })
                }
                filterOption = {
                  createFilter(filterConfig)
                }
              />

              <div>
                <input
                  type='text'
                  className='text-input'
                  name='vinsearch'
                  onChange={e => this.onVinSearchChange(e.target.value)}
                  value={vinSearch}
                  placeholder='VIN search'
                  autoComplete='off'
                  maxLength={17}
                />
                {
                  this.vinSearchIsValid() ||
                    <span className='secondary-text'>
                      Type at least 3 characters
                    </span>
                }
              </div>
            </div>
          </div>
        }

        {
          corpusErrors === true &&
          <div>No vehicles available to search</div>
        }

        {
          isPerformingSearch &&
          <Spinner />
        }

        {
          results &&
          <div className="card">
            <div className="card-list">
              {
                results.length === 0 ?
                <div style={{textAlign: 'center', margin: '20px 0'}}>No matching vehicles found</div> :
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMore}
                  useWindow={true}
                  hasMore={!endOfListReached}
                  loader={<Spinner key="pagination-spinner" />} >
                  <div>
                    {
                      results.map((valuation) => {
                        return (
                          <ValuationRow
                            key={`searchvalrow-${valuation.uuid}`}
                            valuation={valuation}
                            clientID={this.clientID}
                          />
                        )
                      })
                    }
                  </div>
                </InfiniteScroll>
              }
            </div>
          </div>
        }

        {
          accountStatusRestricted === true &&
          <div>You must have an active Carbly subscription in order to search vehicles.</div>
        }
      </div>
    )
  }
}
