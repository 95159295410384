import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationChoices from './ValuationChoices'
import Spinner from '../common/Spinner'
import ChoicesFunctions from '../../api/constants/ChoicesFunctions'


export default class ValuationChoiceReset extends React.Component {
  state = {
    isLoading: false,
    valuationPreviewChoices: null
  }

  componentDidMount() {
    ValuationStore.on("vehicle_preview_change", this.getPreview)
    ValuationStore.on("vehicle_choices_change", this.choicesUpdateComplete)

    this.setState({
      isLoading: true
    })
    setTimeout(() => {
      ValuationActionCreators.loadPreviewForVehicle(this.props.vehicleID)
    }, 100)

  }

  componentWillUnmount() {
    ValuationStore.removeListener("vehicle_preview_change", this.getPreview)
    ValuationStore.removeListener("vehicle_choices_change", this.choicesUpdateComplete)
  }

  getPreview = () => {
    const preview = ValuationStore.vehiclePreview
    // FIXME: if no choices, skip.  This could have come from vehicle without choices

    if (preview) {
      const choices = ChoicesFunctions.initializeChoices(preview)

      this.setState({
        isLoading: false,
        valuationPreviewChoices: choices
      })

      // This should never happen, since we don't reset choices if they come back as complete
      const choicesComplete = ChoicesFunctions.areChoicesComplete(choices)
      if (choicesComplete === true) {
        this.onChoicesComplete(choices)
      }
    }
  }

  onChoicesComplete = (choices) => {
    this.setState({ isLoading: true })
    const formattedChoices = ChoicesFunctions.formattedChoicesForServer(choices)
    ValuationActionCreators.updateValuationChoices(this.props.vehicleID, formattedChoices)
  }


  choicesUpdateComplete = () => {
    this.props.onNewChoicesSaved()
  }

  render() {
    const { isLoading, valuationPreviewChoices } = this.state

    if (isLoading) {
      return (
        <div style={{height: '100px'}}>
          <Spinner style={{height: '30px'}}/>
        </div>
      )
    }

    if (valuationPreviewChoices) {
      return (
        <ValuationChoices valuationPreviewChoices={valuationPreviewChoices} onChoicesComplete={this.onChoicesComplete} />
      )
    }

    return (
      <div></div>
    )


  }
}
