// import { NetInfo } from 'react-native'
import { EventEmitter } from 'events'

class ConnectivityStore extends EventEmitter {
  constructor() {
    super()
    this.isConnected = true
    this.apiRoot = null
    this.apolloApiRoot = null
    this.appVersionString = null
    this.environmentName = null
    this.newsJSONUrl = null
    this.newsHTMLUrl = null
    this.accountHTMLRoot = null
    this.tempDotAuctionVehicleIds = null
    this.oneSignalAppID = null
    this.isSimulator = false


    // NetInfo.addEventListener('connectionChange', this.connectivityDidChange)
    // NetInfo.isConnected.fetch().done(
    //   (isConnected) => { this.isConnected = isConnected }
    // )
  }

  // FIXME: connectivity should move out of the api as it's platform specific
  // FIXME: apiroot stuff doesn't belong here
  setApiRoot(root) {
    this.apiRoot = root
  }

  getApiRoot() {
    return this.apiRoot
  }

  setApolloApiRoot(root) {
    this.apolloApiRoot = root
  }

  getApolloApiRoot() {
    return this.apolloApiRoot
  }

  setNewsJSONUrl(url) {
    this.newsJSONUrl = url
  }

  getNewsJSONUrl() {
    return this.newsJSONUrl
  }

  setNewsHTMLUrl(url) {
    this.newsHTMLUrl = url
  }

  getNewsHTMLUrl() {
    return this.newsHTMLUrl
  }

  setAccountHTMLRoot(url) {
    this.accountHTMLRoot = url
  }

  getAccountHTMLRoot() {
    return this.accountHTMLRoot
  }

  setOneSignalAppID(id) {
    this.oneSignalAppID = id
  }
  
  getOneSignalAppID() {
    return this.oneSignalAppID
  }

  setIsSimulator(isSim) {
    this.isSimulator = isSim === 'true' || isSim === true
  }
  
  getIsSimulator() {
    return this.isSimulator
  }

  // connectivityDidChange = (connectionInfo) => {
    // FIXME: this is super unreliable from RN NetInfo.  Need a more robust solution.
    // disabling for now
  //   console.log("CONNECTION CHANGE: ", connectionInfo);
  //   const lastConnected = this.isConnected
  //   if (connectionInfo.type == 'none' || connectionInfo.type == 'NONE') {
  //     console.log("Connectivity lost")
  //     this.isConnected = false
  //     if (lastConnected === true) {
  //       this.emit("change")
  //     }
  //   } else {
  //     console.log("Connectivity gained")
  //     this.isConnected = true
  //     if (lastConnected === false) {
  //       this.emit("change")
  //     }
  //   }
  // }
}

export default new ConnectivityStore()
