import React from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import ChangeBillingIntervalModal from '../common/ChangeBillingIntervalModal'
import CalculatorBug from '../common/CalculatorBug'
import Formatters from '../../api/constants/Formatters'
import ProviderLogo from '../common/icons/providers/ProviderLogo'
import OfferingUpsellModal from '../common/OfferingUpsellModal'
import AppConstants from '../../api/constants/AppConstants'


export default class PlanManagement extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    isLoadingAvailableOfferings: true,
    costForSelected: null,
    accountDetails: null,
    availableOfferings: null,
    isShowingChangeBillingIntervalModal: false,
    user: SessionStore.user,
    addOfferingErrors: null,
    costForSelectedOfferingsHasErrors: false,
    isShowingUpsellModal: false,
    selectedOffering: null,
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.userDataChanged)
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)

    UserActionCreators.createMetric(AppConstants.metricNames['plan_management_show'])

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  userDataChanged = () => {
    this.setState({ user: SessionStore.user })
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  billingIntervalUpdated = () => {
    this.setState({
      isLoadingAccountDetails: true,
      isLoadingAvailableOfferings: true,
      isShowingChangeBillingIntervalModal: false,
      accountDetails: null,
      availableOfferings: null
    })
    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  accountStateLabel = (accountState) => {
    switch (accountState) {
      case 'trialing':
        return 'Trialing'
      case 'active':
        return 'Active'
      case 'past_due':
        return 'Past Due'
      case 'canceled':
        return 'Canceled'
      case 'invalid':
        return 'Invalid'
      default:

    }
  }


  descriptionForOfferingSKU = (sku) => {
    switch (sku) {
      case 'book_universe':
        return "Powerful retail inventory data from millions of active listings. Understand regional market conditions and view similar competitor listings in an instant."
      case 'book_bb':
        return "Delivers actionable intelligence and accurate car value data you can count on for both the wholesale and retail markets."
      case 'book_mmr':
        return "The Market Report is the premier indicator of wholesale prices and shows retail values, price history and projections."
      case 'book_nada':
        return "Formerly NADA. Standard for lenders when underwriting loans, and offering Enhanced Vehicle Values with pre-selected add / deducts and trims for the VIN."
      case 'book_kbb':
        return "Comprehensive data for wholesale and retail. A must have for trade-in negotiations and loan underwriting."
      case 'book_pin':
        return "Actual transaction data from dealers in the US, sale price, days on lot and profits. Comp analysis."
      case 'book_pmr':
        return "A must-have for indpendent auction buyers and sellers. EDGE Pipeline sale transactions, trends and market pricing."
      case 'book_galves':
        return "Daily wholesale data with critial market insight driven by thousands of transactions analyzed by remarketing specialists."
      default:
        return ''
    }
  }

  additionalStylesForProviderLogoSKU = (sku) => {
    switch (sku) {
      case 'book_bb':
        return {width: '35%', marginTop: '0', maxHeight: '60px'}
      case 'book_kbb':
        return {width: '40%', marginTop: '10px'}
      case 'book_pmr':
        return {width: '50%', marginTop: '-10px'}
      default:
        return {}
    }
  }

  learnMoreLinkForOfferingSKU = (sku) => {
    switch (sku) {
      case 'book_universe':
        return 'https://getcarbly.com/vehicle-appraisals/live-local-market/'
      case 'book_bb':
        return 'https://getcarbly.com/vehicle-appraisals/black-book/'
      case 'book_mmr':
        return 'https://getcarbly.com/vehicle-appraisals/manheim-market-report/'
      case 'book_nada':
        return 'https://getcarbly.com/vehicle-appraisals/jd-power/'
      case 'book_kbb':
        return 'https://getcarbly.com/vehicle-appraisals/kelley-blue-book/'
      case 'book_pin':
        return 'https://getcarbly.com/vehicle-appraisals/real-retail/'
      case 'book_pmr':
        return 'https://getcarbly.com/vehicle-appraisals/pipeline-market-report/'
      case 'book_galves':
        return 'https://getcarbly.com/vehicle-appraisals/galves/'
      default:
        return ''
    }
  }
  

  renderDataProviders = (offerings) => {
    return offerings.map((offering) => {
      return (
        <div key={offering.sku} className='data-provider-c'>
          <div style={{flexGrow: 1, flexShrink: 1}}>
            <div style={{textAlign: 'center', height: '70px'}} className='plan-provider-logo'>
              <ProviderLogo providerKey={offering.provider_key} style={{margin: '0 auto', width: '60%', height: 'auto', ...this.additionalStylesForProviderLogoSKU(offering.sku)}} />
            </div>
            {
              offering.sku === 'book_universe' &&
              <div style={{textAlign: 'center', marginTop: '-15px'}}>
              <div style={{display: 'inline-block', textAlign: 'center', marginBottom: '5px', fontSize: '0.75rem', color: 'green', backgroundColor: '#fff', border: '1px solid green', borderRadius: '22px', padding: '2px 8px'}}>Most Popular Retail Data</div>
              </div>
            }
            <p>{this.descriptionForOfferingSKU(offering.sku)}  <a href={this.learnMoreLinkForOfferingSKU(offering.sku)} target='_blank' rel="noopener noreferrer">Learn more</a></p>
          </div>
          <div style={{flexGrow: 0, flexShrink: 0, height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            
            <div style={{marginBottom: '5px', textAlign: 'center', fontWeight: 'bold', fontSize: '1.05rem'}}>{Formatters.formatCurrencyCents(offering.price?.month)} per month</div>
            <div style={{textAlign: 'center'}}>
              <Button onClick={() => {
                UserActionCreators.createMetric(AppConstants.metricNames['plan_management_open_offering'])
                this.setState({
                  isShowingUpsellModal: true,
                  selectedOffering: offering
                })
              }} color='primary' style={{width: '100%'}}>Add {offering.name}</Button>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    if (!this.props.navMatch) { return null }
    const { user, isLoadingAccountDetails, isLoadingAvailableOfferings, accountDetails, availableOfferings, costForSelected, isShowingChangeBillingIntervalModal } = this.state
    const account = user.account
    var canModifyAccount = false
    const isBillingIntervalYearly = accountDetails?.billing_interval === 'year'

    if (account.owner === true && account.state !== 'canceled' && account.state !== 'invalid') {
      canModifyAccount = true
    }

    const isLoading = isLoadingAccountDetails || isLoadingAvailableOfferings
    if (isLoading) {
      return (<div className="body-content settings-content-c"><Spinner /></div>)
    }

    if (account.owner === false) {
      return (
        <div style={{marginTop: '20px', marginLeft: '120px', fontWeight: 'bold'}}>If you would like to make changes, please contact the account owner.</div>
      )
    }

    const isDivergent = accountDetails?.divergent_offerings === true
    const isCustomBilling = accountDetails?.custom_billing === true
    const needsPaymentMethod = SessionStore.needsPaymentMethod()
    const hasAccountOfferings = SessionStore.hasAccountOfferings()
    const canGoYearly = !isBillingIntervalYearly && !isDivergent && !isCustomBilling && accountDetails?.can_upgrade_to_yearly !== false && hasAccountOfferings && !needsPaymentMethod


    var nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    const hasAuctionsPlus = accountDetails?.account_offerings?.find((offering) => offering.sku === 'feature_auctions_plus')
    const auctionsPlusOffering = availableOfferings?.find((offering) => offering.sku === 'feature_auctions_plus')

    const hasMarketTracker = accountDetails?.account_offerings?.find((offering) => offering.sku === 'feature_market_tracker')
    const marketTrackerOffering = availableOfferings?.find((offering) => offering.sku === 'feature_market_tracker')

    // Book providers section
    let additionalAvailableOfferings = [...availableOfferings].filter((offering) => offering.sku !== 'feature_auctions_plus' && offering.sku !== 'feature_market_tracker')

    const llmOffering = additionalAvailableOfferings.find((offering) => offering.sku === 'book_universe')
    if (llmOffering) {
      additionalAvailableOfferings = additionalAvailableOfferings.filter(item => item.sku !== "book_universe");
      additionalAvailableOfferings.unshift(llmOffering);
    }

    return (
      <div className="page-body-c regional-reports-plus-body-c">
        <div className="content-header">
          <h1>Add Data and Features</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
          <div>Account status: <span style={{fontWeight: 'bold'}}>{this.accountStateLabel(account.state)}</span></div>
          {
            canGoYearly &&
            <>
              <span style={{padding: '0 10px', color: '#ccc'}}>|</span>
              <a href="yearly" onClick={(e) => {
                e.preventDefault()
                this.setState({
                  isShowingChangeBillingIntervalModal: true
                })
              }} style={{}}>Switch to yearly billing and save</a>
            </>
          }

          {
            !needsPaymentMethod &&
            <>
              { canGoYearly && <span style={{padding: '0 10px', color: '#ccc'}}>|</span> }
              <Link to='/settings/account'>Change payment method</Link>
            </>
          }
        </div>

        {
          user.offering_list_message?.length > 0 &&
          <div style={{marginBottom: '10px'}}>
            <div className='secondary-text'>Current plan: {user.offering_list_message}</div>
          </div>
        }

        {
          canModifyAccount &&
            <>
              {
                Boolean(!hasAuctionsPlus && auctionsPlusOffering) &&
                <div className='split-row split-row-left featured-provider-c' style={{alignItems: 'flex-start'}}>
                  <div style={{width: '200px'}}>
                    <img src="https://getcarbly.com/images/external-auctions-plus-thumbnail.png" alt="Auctions Plus" style={{width: '200px', height: '154px'}} />
                  </div>
                  <div style={{marginLeft: '20px', flexGrow: 1, flexShrink: 1}}>
                    <h2>Carbly Auctions Plus</h2>
                    <div>
                      <div>Transform your vehicle sourcing strategy finding and evaluating the best vehicles from over 300 auctions and digital marketplaces across the country. With unlimited saved searches and a single, unified experience - Auctions Plus is the only tool you need to find the best vehicles at auction.  <a href="https://getcarbly.com/auctions-plus/" target='_blank' rel="noopener noreferrer">Learn more about Auctions Plus</a></div>
                      <div style={{textAlign: 'center'}}>
                        <img src="https://getcarbly.com/images/external-auction-providers-transparent.png" alt="Auction Providers" style={{width: '100%', maxWidth: '580px', margin: '0 auto'}} />
                      </div>
                    </div>
                  </div>

                  <div style={{width: '210px', marginLeft: '20px', flexGrow: 0, flexShrink: 0}}>
                    <Button onClick={() => {
                      UserActionCreators.createMetric(AppConstants.metricNames['plan_management_open_offering'])
                      this.setState({
                        isShowingUpsellModal: true,
                        selectedOffering: auctionsPlusOffering
                      })
                    }} color='primary' style={{marginTop: '20px', width: '100%'}}>Add Auctions Plus</Button>
                    <div style={{marginBottom: '5px', fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'center', width: '100%'}}>{Formatters.formatCurrencyCents(auctionsPlusOffering.price?.month)} per month</div>
                  </div>
                </div>
              }


              {
                Boolean(!hasMarketTracker && marketTrackerOffering) &&
                <div className='split-row split-row-left featured-provider-c' style={{alignItems: 'flex-start'}}>
                  <div style={{width: '200px'}}>
                    <img src="https://getcarbly.com/images/external-market-tracker-thumbnail.png" alt="Market Tracker" style={{width: '200px', height: '154px'}} />
                  </div>
                  <div style={{marginLeft: '20px', flexGrow: 1, flexShrink: 1}}>
                    <h2>Market Tracker</h2>
                    <div>
                      <div>Market Tracker offers a comprehensive set of tools that provide auto dealers with unparalleled visibility into regional inventory and vehicle sales performance.</div>

                      <div style={{marginTop: '10px'}}>Better understand your sales volume, days on lot, and lot value compared to your competitors. By harnessing the power of data-driven intelligence, dealers can make informed decisions, optimize their operations, and maximize profitability. <a href="https://getcarbly.com/vehicle-appraisals/market-tracker/" target='_blank' rel="noopener noreferrer">Learn more about Market Tracker</a></div>
                    </div>
                  </div>

                  <div style={{width: '210px', marginLeft: '20px', flexGrow: 0, flexShrink: 0}}>
                    <Button onClick={() => {
                      UserActionCreators.createMetric(AppConstants.metricNames['plan_management_open_offering'])
                      this.setState({
                        isShowingUpsellModal: true,
                        selectedOffering: marketTrackerOffering
                      })
                    }} color='primary' style={{marginTop: '20px'}}>Add Market Tracker</Button>
                    <div style={{marginBottom: '5px', fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'center', width: '100%'}}>
                      {Formatters.formatCurrencyCents(marketTrackerOffering.price?.month)} per month
                    </div>
                  </div>
                </div>
              }

              {
                additionalAvailableOfferings.length > 0 &&
                <div>
                  <h2 style={{marginBottom: '10px', marginTop: '40px'}}>Add Data Providers</h2>
                  <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}} className='plan-management-data-providers'>
                    { this.renderDataProviders(additionalAvailableOfferings) }
                  </div>
                </div>
              }
          </>
        }

        {
          hasAuctionsPlus && hasMarketTracker && additionalAvailableOfferings.length === 0 &&
          <div style={{marginTop: '40px'}}>
            There are no additional offerings available for your plan.
          </div>
        }


        {
          isShowingChangeBillingIntervalModal &&
          <ChangeBillingIntervalModal accountDetails={accountDetails} onCancel={() => {this.setState({ isShowingChangeBillingIntervalModal: false })}} onComplete={this.billingIntervalUpdated} />
        }

        {
          this.state.isShowingUpsellModal &&
          <OfferingUpsellModal 
            offering={this.state.selectedOffering}
            onDone={() => {this.setState({ isShowingUpsellModal: false })}}
            onPurchase={() => {
              UserActionCreators.createMetric(AppConstants.metricNames['plan_management_added_offering'])
              this.setState({
                isShowingUpsellModal: false,
                isLoadingAccountDetails: true,
                isLoadingAvailableOfferings: true
              })
              UserActionCreators.loadAccountDetails()
              UserActionCreators.loadAvailableOfferings()
              toast.success("Plan updated successfully")
            }} />
        }
      </div>
    )
  }
}
