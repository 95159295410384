import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationProviderCard from './ValuationProviderCard'
import AdjustmentsTable from './AdjustmentsTable'
import AddDeducts from './AddDeducts'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'

const title = AppConstants.providerNames.black_book

export default class ValuationBlackBook extends React.Component {
  constructor(props) {
    super(props)

    this.isShared = ValuationStore.isSharedValuation
    this.state = {
      isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('black_book'),
      addDeductsHidden: SessionStore.areAddDeductsHidden(props.providerKey)
    }
  }

  componentDidMount() {
    SessionStore.on('user_data_changed', this.userDataChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_data_changed', this.userDataChanged)
  }

  userDataChanged = () => {
    this.setState({ isShowingAdjustments: SessionStore.isShowingAdjustmentsForProvider('black_book') })
  }

  render() {
    const blackBookData = this.props.providerData
    if (!blackBookData) { return null }
    const appraisal = blackBookData.appraisal
    const error = blackBookData.error
    const description = blackBookData.description
    const overallCondition = this.props.overallCondition
    const appraisalValuesForCondition = appraisal ? appraisal[overallCondition] : null

    const msrp = description ? blackBookData.description.msrp_price : null
    const retailEquip = description ? blackBookData.description.retail_equipment_price : null
    const adItems = blackBookData.add_deducts && blackBookData.add_deducts.items && blackBookData.add_deducts.items.length > 0 ? blackBookData.add_deducts.items : null
    const adLogic = blackBookData.add_deducts && blackBookData.add_deducts.logic ? blackBookData.add_deducts.logic : null

    const wholesale_rough    = appraisal?.rough?.wholesale
    const wholesale_average  = appraisal?.average?.wholesale
    const wholesale_clean    = appraisal?.clean?.wholesale
    const wholesale_xclean   = appraisal?.xclean?.wholesale
    const retail_rough       = appraisal?.rough?.retail
    const retail_average     = appraisal?.average?.retail
    const retail_clean       = appraisal?.clean?.retail
    const retail_xclean      = appraisal?.xclean?.retail

    // Just check one
    const hasTradeInValues = appraisal && appraisal.average && appraisal.average.tradein && appraisal.average.tradein.adjusted && appraisal.average.tradein.adjusted > 0

    const hasRough = wholesale_rough || retail_rough ? (wholesale_rough?.adjusted && wholesale_rough?.adjusted > 0) || (retail_rough?.adjusted && retail_rough?.adjusted > 0) : null
    const hasAverage = wholesale_average || retail_average ? (wholesale_average?.adjusted && wholesale_average?.adjusted > 0) || (retail_average?.adjusted && retail_average?.adjusted > 0) : null
    const hasClean = wholesale_clean || retail_clean ? (wholesale_clean?.adjusted && wholesale_clean?.adjusted > 0) || (retail_clean?.adjusted && retail_clean?.adjusted > 0) : null
    const hasXClean = wholesale_xclean || retail_xclean ? (wholesale_xclean?.adjusted && wholesale_xclean > 0) || (retail_xclean?.adjusted && retail_xclean?.adjusted > 0) : null

    const { isShowingAdjustments, addDeductsHidden } = this.state

    // FIXME: use arrow like kbb for spread

    return (
      <ValuationProviderCard
        vehicleUUID={this.props.vehicleUUID}
        clientID={this.props.clientID}
        providerKey='black_book'
        fullChoices={this.props.fullChoices}
        currentChoiceUID={blackBookData.description && blackBookData.description.uid ? blackBookData.description.uid : null}
        title={title}
        subtitle={error ? null : description.display_choice}
        hasError={error ? true : false}
        showAdjustmentMessage={true}
        messages={blackBookData.messages}
        providerData={blackBookData}
        onBookout={this.props.onBookout}
      >

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} className="adjustments-toggle-c">
        <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
          e.preventDefault()
          this.setState({ isShowingAdjustments: !isShowingAdjustments })
          SessionStore.saveProviderDisplayMode('black_book', !isShowingAdjustments)
        }}>Switch to {isShowingAdjustments ? 'conditions' : 'adjustments'} view</a>
      </div>

      {
        isShowingAdjustments && overallCondition !== null &&
        <div>
          <AdjustmentsTable
            appraisalValues={appraisalValuesForCondition}
          />
          <div className="secondary-text" style={{textAlign: 'right'}}>Condition: {AppConstants.overallConditions[overallCondition].title}</div>
        </div>
      }

      {
        !isShowingAdjustments &&
        <div className="valuation-subsection prices-table" style={{marginTop: 10}}>
          <div className="prices-table-row">
            <div className="prices-table-cell header-cell">&nbsp;</div>
            <div className="prices-table-cell header-cell">Wholesale</div>
            <div className="prices-table-cell header-cell">Retail</div>
            <div className="prices-table-cell header-cell">Spread</div>
          </div>

          {
            hasRough === true &&
            <div className={`prices-table-row${overallCondition === 'rough' ? ' prices-table-row-highlighted' : ''}`}>
              <div className="prices-table-cell row-label">Rough</div>

              <div className="prices-table-cell">{wholesale_rough?.adjusted ? Formatters.formatCurrency(wholesale_rough?.adjusted) : ''}</div>
              <div className="prices-table-cell">{retail_rough?.adjusted ? Formatters.formatCurrency(retail_rough?.adjusted) : ''}</div>
              <div className="prices-table-cell">{wholesale_rough?.adjusted && retail_rough?.adjusted ? Formatters.formatCurrency(retail_rough?.adjusted - wholesale_rough?.adjusted) : ''}</div>
            </div>
          }

          {
            hasAverage === true &&
            <div className={`prices-table-row${overallCondition === 'average' ? ' prices-table-row-highlighted' : ''}`}>
              <div className="prices-table-cell row-label">Average</div>

              <div className="prices-table-cell">{wholesale_average ? Formatters.formatCurrency(wholesale_average?.adjusted) : ''}</div>
              <div className="prices-table-cell">{retail_average ? Formatters.formatCurrency(retail_average?.adjusted) : ''}</div>
              <div className="prices-table-cell">{wholesale_average && retail_average ? Formatters.formatCurrency(retail_average?.adjusted - wholesale_average?.adjusted) : ''}</div>
            </div>
          }

          {
            hasClean === true &&
            <div className={`prices-table-row${overallCondition === 'clean' ? ' prices-table-row-highlighted' : ''}`}>
              <div className="prices-table-cell row-label">Clean</div>

              <div className="prices-table-cell">{wholesale_clean ? Formatters.formatCurrency(wholesale_clean?.adjusted) : ''}</div>
              <div className="prices-table-cell">{retail_clean ? Formatters.formatCurrency(retail_clean?.adjusted) : ''}</div>
              <div className="prices-table-cell">{wholesale_clean && retail_clean ? Formatters.formatCurrency(retail_clean?.adjusted - wholesale_clean?.adjusted) : ''}</div>
            </div>
          }

          {
            hasXClean === true &&
            <div className={'prices-table-row'}>
              <div className="prices-table-cell row-label">X Clean</div>

              <div className="prices-table-cell">{wholesale_xclean ? Formatters.formatCurrency(wholesale_xclean?.adjusted) : ''}</div>
              <div className="prices-table-cell">{retail_xclean ? Formatters.formatCurrency(retail_xclean?.adjusted) : ''}</div>
              <div className="prices-table-cell">{wholesale_xclean && retail_xclean ? Formatters.formatCurrency(retail_xclean?.adjusted - wholesale_xclean?.adjusted) : ''}</div>
            </div>
          }

        </div>
      }

      {
        !isShowingAdjustments && hasTradeInValues &&
        <div style={{marginTop: '20px'}}>
          <div style={{fontWeight: 'bold'}}>Trade-In</div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {
              appraisal.rough.tradein && appraisal.rough.tradein.adjusted &&
              <div style={{fontWeight: overallCondition === 'rough' ? 'bold' : 'normal'}}><span className="secondary-text">Rough: </span>{Formatters.formatCurrency(appraisal.rough.tradein.adjusted)}</div>
            }
            {
              appraisal.average.tradein && appraisal.average.tradein.adjusted &&
              <div style={{fontWeight: overallCondition === 'average' ? 'bold' : 'normal'}}><span className="secondary-text">Average: </span>{Formatters.formatCurrency(appraisal.average.tradein.adjusted)}</div>
            }
            {
              appraisal.clean.tradein && appraisal.clean.tradein.adjusted &&
              <div style={{fontWeight: overallCondition === 'clean' ? 'bold' : 'normal'}}><span className="secondary-text">Clean: </span>{Formatters.formatCurrency(appraisal.clean.tradein.adjusted)}</div>
            }
          </div>
        </div>
      }



      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '20px'}} className="provider-footer-info">
        {
          msrp && msrp > 0 &&
          <div style={{marginRight: '20px'}}>MSRP: {Formatters.formatCurrency(msrp)}</div>
        }
        {
          retailEquip > 0 &&
          <div>Retail Equip: {Formatters.formatCurrency(retailEquip)}</div>
        }
      </div>

      {
        adItems &&
        <div className="valuation-subsection">
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <h3 className="valuation-subsection-title">Vehicle Options</h3>
            <a href="#toggle" className="adjustments-toggle" onClick={(e) => {
              e.preventDefault()
              SessionStore.setHiddenAddDeducts(!addDeductsHidden, this.props.providerKey)
              this.setState({ addDeductsHidden: !addDeductsHidden })
            }}>{addDeductsHidden ? 'Show' : 'Hide'}</a>
          </div>
          {
            !addDeductsHidden &&
            <AddDeducts items={adItems} adLogic={adLogic} providerKey="black_book" clientID={this.props.clientID} vehicleUUID={this.props.vehicleUUID} overallCondition={overallCondition} />
          }
        </div>
      }

      </ValuationProviderCard>
    )
  }
}
