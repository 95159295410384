import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import platformFunctions from '../../platformFunctions'
import AuctionsMap from './AuctionsMap'
import Formatters from '../../api/constants/Formatters'
import TFMinMaxSelectors from '../common/TFMinMaxSelectors'
import TFSegmentedControl from './TFSegmentedControl'
import TFSelect from '../common/TFSelect'
import AppConstants from '../../api/constants/AppConstants'
import ExpandIcon from '../common/icons/ExpandIcon'
import ContractIcon from '../common/icons/ContractIcon'
import AuctionsSearchRangeSlider from './AuctionsSearchRangeSlider'
import ModelsAndTrimsModal from './ModelsAndTrimsModal'
import pluralize from 'pluralize'
import DistanceFromZipcodeControl from '../common/DistanceFromZipcodeControl'
import AuctionsEditOnlineChannelsModal from './AuctionsEditOnlineChannelsModal'
import SlidersIcon from '../common/icons/SlidersIcon'
import VDSStore from '../../api/stores/VDSStore'
import VDSActionCreators from '../../api/actions/VDSActionCreators'
import VINEntryField from '../common/VINEntryField'
import PlusPlanIcon from '../common/icons/PlusPlanIcon'
import WarningCircleIcon from '../common/icons/WarningCircleIcon'
import AuctionsPlusUpsellModal from './AuctionsPlusUpsellModal'
import CommonFunctions from '../../api/constants/CommonFunctions'

const MIN_MAX_SELECTOR_WIDTH = 128
const MAKE_MODEL_VALUE_SEPARATOR = '___'
const TRIMS_DISPLAY_LIMIT = 10

// FIXME: check if loading another saved search updates correctly

export default class AuctionsSearchFilters extends React.Component {
  state = {
    isLoadingAuctionsByState: true,
    auctionsByState: null,
    auctionsSearchArray: null,
    isShowingDistanceMap: false,
    realtimeDistanceValue:  AppConstants.auctionSearchDefaultDistanceRadius,
    isShowingEditChannelsModal: false,
    isLoadingBaseCorpus: true,
    baseCorpus: VDSStore.getBaseCorpus(),
    isLoadingMakesCorpus: false,
    makesCorpus: null,
    isVehicleDetailsCollapsed: SessionStore.hiddenAuctionsSearchVehicleDetails,
    searchByVINIsValid: false,
    isShowingUpsellModal: false
  }

  componentDidMount() {
    AuctionStore.on('auctions_by_state_change', this.auctionsByStateDidChange)
    VDSStore.on('base_vds_corpus_load', this.baseCorpusIsLoading)
    VDSStore.on('base_vds_corpus_change', this.baseCorpusDidChange)
    VDSStore.on('makes_vds_corpus_change', this.makesCorpusDidChange)

    AuctionsActionCreators.loadAuctionsByState()
    this.mapHideTimeout = null

    const savedSearch = this.props.savedSearch
    this.setState({
      realtimeDistanceValue: savedSearch?.location_radius || AppConstants.auctionSearchDefaultDistanceRadius
    })

    if (savedSearch?.classification?.length > 0) {
      this.loadMakesCorpus(this.props.savedSearch.classification.map((el) => el.make))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savedSearch !== this.props.savedSearch) {
      const savedSearch = this.props.savedSearch

      // If we're loading a new saved search, we need to load the makes corpus if there are any makes
      if (savedSearch?.classification?.length > 0) {
        this.loadMakesCorpus(this.props.savedSearch.classification.map((el) => el.make))
      }

      this.setState({
        realtimeDistanceValue: savedSearch?.location_radius || AppConstants.auctionSearchDefaultDistanceRadius
      })
    }
  }

  componentWillUnmount() {
    AuctionStore.removeListener('auctions_by_state_change', this.auctionsByStateDidChange)
    VDSStore.removeListener('base_vds_corpus_load', this.baseCorpusIsLoading)
    VDSStore.removeListener('base_vds_corpus_change', this.baseCorpusDidChange)
    VDSStore.removeListener('makes_vds_corpus_change', this.makesCorpusDidChange)
  }

  baseCorpusIsLoading = () => {
    this.setState({ isLoadingBaseCorpus: true })
  }

  baseCorpusDidChange = () => {
    // FIXME: error handling
    this.setState({
      isLoadingBaseCorpus: false,
      baseCorpus: VDSStore.getBaseCorpus()
    })
  }

  loadMakesCorpus = (makes) => {
    this.setState({
      makesCorpus: null,
      isLoadingMakesCorpus: true
    })
    VDSActionCreators.loadMakesCorpus(makes)
  }

  makesCorpusDidChange = () => {
    // FIXME: error handling
    this.setState({
      isLoadingMakesCorpus: false,
      makesCorpus: VDSStore.makesCorpus
    })
  }

  // Candidate for centralization depending on format of selector options
  modelsDidChange = (selectedModelOptions) => {
    let newClassification = this.props.savedSearch.classification.slice()

    // Identify if a model was removed from selectedModelOptions and remove it from newClassification
    for (let classification of newClassification) {
      if (!selectedModelOptions.find((el) => el.value === `${classification.make}${MAKE_MODEL_VALUE_SEPARATOR}${classification.model}`)) {
        // We're not removing all the make / model matches, since that would clear out the make as well.
        // Instead, just null out all the models for the make and the sanitize function clean up dupes
        newClassification = newClassification.map((el) => {
          if (el.make === classification.make && el.model === classification.model) {
            return { make: classification.make }
          } else {
            return el
          }
        })
      }
    }

    // Identify if a model was added to selectedModelOptions and add it to newClassification
    for (let modelOption of selectedModelOptions) {
      const [make, model] = modelOption.value.split(MAKE_MODEL_VALUE_SEPARATOR)
      if (!newClassification.find((el) => el.make === make && el.model === model)) {
        newClassification.push({ make: make, model: model })
      }
    }

    newClassification = AuctionFunctions.sanitizeClassification(newClassification)
    this.props.onSearchChange('classification', newClassification )
  }

  addTrim = (make, model, trim) => {
    let newClassification = this.props.savedSearch.classification.slice()
    newClassification.push({ make: make, model: model, trim: trim })
    newClassification = AuctionFunctions.sanitizeClassification(newClassification)
    this.props.onSearchChange('classification', newClassification )
  }

  removeTrim = (make, model, trim) => {
    let newClassification = this.props.savedSearch.classification.slice()

    newClassification = newClassification.map((el) => {
      if (el.make === make && el.model === model && el.trim === trim) {
        return { make: make, model: model }
      } else {
        return el
      }
    })

    newClassification = AuctionFunctions.sanitizeClassification(newClassification)
    this.props.onSearchChange('classification', newClassification )
  }


  auctionsByStateDidChange = () => {
    const formattedAuctionsByState = AuctionFunctions.formattedAuctionsByState(AuctionStore.auctionsByState)
    var results = formattedAuctionsByState.results
    const auctionsSearchArray = formattedAuctionsByState.formattedAuctionsByState

    results = results.sort((a,b) => (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0))
    this.setState({
      isLoadingAuctionsByState: false,
      auctionsByState: results,
      auctionsSearchArray: auctionsSearchArray
    })
  }

  // Candidate for centralization depending on options formatting
  currentlySelectedAuctions = () => {
    const savedSearch = this.props.savedSearch
    var selectedAuctions = []

    if (savedSearch.physical_codes.length === 1 && savedSearch.physical_codes[0] === AppConstants.auctionsSearchEmptyLocationsSentinel) {
      return null
    } else {
      for (let auctionCode of savedSearch.physical_codes) {
        const fullAuction = AuctionStore.auctionDetailsForLocationCode(auctionCode)
        selectedAuctions.push({ 'label': fullAuction?.name, 'value': auctionCode})
      }
      return selectedAuctions
    }
  }

  onBeforeRadusChange = (distance) => {
    if (this.mapHideTimeout) { clearTimeout(this.mapHideTimeout) }
    this.setState({ isShowingDistanceMap: true })
  }

  onAfterRadiusChange = (distance) => {
    this.props.onSearchChange('location_radius', distance)

    if (this.mapHideTimeout) { clearTimeout(this.mapHideTimeout) }

    this.mapHideTimeout = setTimeout(() => {
      this.setState({ isShowingDistanceMap: false })
    }, 1000)
  }

  onRadiusChange = (distance) => {
    this.setState({ realtimeDistanceValue: distance })
  }

  arrayToSelectOptions = (array) => {
    if (!array) return null
    return array.map((item) => {
      return { value: item, label: item }
    })
  }

  formatOptionLabelColor = (value, label) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '12px', height: '12px', backgroundColor: value, marginRight: '5px', borderRadius: '4px' }}></div>
        <div>{label}</div>
      </div> 
    )
  }

  distanceSearchZipCodeChanged = (zipCode, isValid) => {
    const sanitizedZipCode = CommonFunctions.stripNonNumericNoDecimal(zipCode)

    this.setState({
      zipCode: sanitizedZipCode,
      modifiedZipCodeIsValid: isValid,
      searchHasChanged: true
    })

    if (isValid) {
      // AuctionsActionCreators.getCoordinatesForZipCode(sanitizedZipCode)
      this.props.onSearchChange('zip_code', zipCode)
      if (this.props.onZipCodeChange) {
        this.props.onZipCodeChange(zipCode)
        this.setState({
          isShowingDistanceMap: true
        })
        this.mapHideTimeout = setTimeout(() => {
          this.setState({ isShowingDistanceMap: false })
        }, 2000)
      }
    }
  }

  render() {
    const { savedSearch, shouldShowCollapsedSidebar, dynamicSidebarWidth, isPersisted, coordinatesForZip } = this.props
    const { isLoadingAuctionsByState, auctionsByState, isShowingDistanceMap, realtimeDistanceValue, isShowingEditChannelsModal, baseCorpus, makesCorpus, isVehicleDetailsCollapsed, searchByVINIsValid, isShowingUpsellModal } = this.state

    var digitalChannelsDisplayString = AuctionFunctions.digitalChannelsDisplayString(savedSearch)

    const possibleMakes = this.arrayToSelectOptions(baseCorpus?.makes)
    const possibleYears = this.arrayToSelectOptions(baseCorpus?.years)
    const possibleSegments = this.arrayToSelectOptions(baseCorpus?.segments)
    const possibleDriveTypes = this.arrayToSelectOptions(baseCorpus?.drive_types)
    const possibleTransmissions = this.arrayToSelectOptions(baseCorpus?.transmission_types)
    const possibleFuels = this.arrayToSelectOptions(baseCorpus?.fuel_types)
    const possibleExteriorColors = this.arrayToSelectOptions(baseCorpus?.exterior_colors)
    const possibleInteriorColors = this.arrayToSelectOptions(baseCorpus?.interior_colors)
    
    let uniqueSelectedClassificationMakesValues = [...new Set(savedSearch.classification?.map((c) => (c.make)))];
    const selectedClassificationMakes = this.arrayToSelectOptions(uniqueSelectedClassificationMakesValues)
    const makesAreSelected = selectedClassificationMakes?.length > 0

    // Create selectedClassificationModels from savedSearch.classification
    let selectedClassificationModels = []
    let uniqModels = []
    for (let classification of savedSearch.classification) {
      if (classification.model) {
        // Allow for same different make, same model
        const uniqString = `${classification.make}${MAKE_MODEL_VALUE_SEPARATOR}${classification.model}`
        if (uniqModels.indexOf(uniqString) === -1) {
          selectedClassificationModels.push({ label: classification.model, value: `${classification.make}${MAKE_MODEL_VALUE_SEPARATOR}${classification.model}` })
          uniqModels.push(uniqString)
        }
      }
    }

    let possibleModels = []
    if (makesAreSelected && makesCorpus) {
      for (let makeKey of Object.keys(makesCorpus)) {
        let makeOption = { label: makeKey, options: [] }
        const sortedModels = Object.keys(makesCorpus[makeKey]).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
        for (let model of sortedModels) {
          // We need special values on the front end to combine make and model
          makeOption.options.push({ label: model, value: `${makeKey}${MAKE_MODEL_VALUE_SEPARATOR}${model}` })
        }
        possibleModels.push(makeOption)
      }
    }

    const modelsAreSelected = selectedClassificationModels?.length > 0

    const selectedTrims = savedSearch.classification?.filter((c) => c.trim?.length > 0)
    let selectedTrimsString = ''
    if (selectedTrims?.length > TRIMS_DISPLAY_LIMIT) {
      selectedTrimsString = selectedTrims.slice(0, TRIMS_DISPLAY_LIMIT).map((c) => (c.trim)).join(', ')
      selectedTrimsString = `${selectedTrimsString} +${selectedTrims.length - TRIMS_DISPLAY_LIMIT} more`
    } else {
      selectedTrimsString = selectedTrims.map((c) => (c.trim)).join(', ')
    }
    
    const trimsAreSelected = selectedTrims?.length > 0
  
    return (
      <div style={{position: 'relative', width: `${dynamicSidebarWidth}px`}}>
        {
          isShowingDistanceMap && coordinatesForZip && !isShowingEditChannelsModal &&
          <div style={{position: 'absolute', left: `${dynamicSidebarWidth}px`, width: '480px', height: '480px', boxShadow: '0 3px 16px rgba(0,0,0,0.12)', zIndex: 999}} className='zoomIn'>
            <AuctionsMap scopeType={'distance'} distanceRadiusMiles={realtimeDistanceValue} centerForDistanceScopeType={coordinatesForZip} width='480px' height='480px' hideAuctions />
          </div>
        }
        <div className='speed-search-sidebar' style={{width: `${dynamicSidebarWidth}px`, padding: '0px !important', paddingBottom: '180px'}}>
          {
            !shouldShowCollapsedSidebar && baseCorpus &&
            <>
              {
                isPersisted &&
                <a href="#show-filters"
                  className='secondary-text split-row split-row-left hide-filters'
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.onToggleSidebar()
                  }}>
                  <SlidersIcon /> <div>Hide filters</div>
                </a>
              }


              {
                !isLoadingAuctionsByState &&
                <div style={{flex: 1, position: 'relative', minHeight: '600px'}}>
                  <TFSegmentedControl
                    segments={[{label: 'By Auctions', value: 'auctions'}, {label: 'By distance', value: 'distance'}]}
                    value={savedSearch.location_type}
                    onValueChange={(value) => {
                      this.props.onSearchChange('location_type', value)
                    }}
                    segmentStyle={{minWidth: '156px', width: '50%'}} />

                  <div style={{marginTop: '10px'}}>
                    {
                      savedSearch.location_type === 'auctions' &&
                      <TFSelect
                        name="auctions"
                        isMulti={true}
                        matchFrom='any'
                        isClearable={false}
                        value={this.currentlySelectedAuctions()}
                        onChange={(v) => { this.props.onAuctionsChange(v) }}
                        closeMenuOnSelect={false}
                        placeholder={'No physical auctions selected'}
                        controlStyle={{
                          paddingTop: '0px',
                          backgroundColor: '#fff',
                          border: '1px solid #ddd',
                          marginBottom: '20px',
                          borderRadius: '6px',
                          boxShadow: '0 3px 16px rgba(0,0,0,0.12)'
                        }}
                        options={
                          !auctionsByState ? null :
                          auctionsByState.map((s) => ({
                            label: s.title,
                            options: s.data.map((d) => {
                              return ( { label: d.name, value: d.code } )
                            })
                          }))
                        } />
                    }

                    {
                      savedSearch.location_type === 'distance' &&
                      <div style={{marginBottom: '20px'}}>
                        <DistanceFromZipcodeControl
                          realtimeDistanceValue={realtimeDistanceValue}
                          onDistanceChange={this.onRadiusChange}
                          onBeforeChange={this.onBeforeRadusChange}
                          onAfterChange={this.onAfterRadiusChange}
                          defaultDistance={savedSearch.location_radius}
                          defaultZipCode={savedSearch.zip_code ? savedSearch.zip_code : SessionStore.user?.zip_code}
                          onZipCodeChange={this.distanceSearchZipCodeChanged}
                        />
                      </div>
                    }
                  </div>

                  <section>
                    <div style={{fontSize: '0.75rem'}}>
                      {
                        digitalChannelsDisplayString ?
                        digitalChannelsDisplayString :
                        'No online sales selected'
                      }
                    </div>
                    <a href="#edit" onClick={(e) => {
                      e.preventDefault()
                      this.setState({ isShowingEditChannelsModal: true })
                    }} style={{fontSize: '0.75rem'}}>Change Online Sales</a>
                  </section>


                  <section>
                    <h3 className='split-row'>Estimated Auction Sale Price</h3>

                    <TFMinMaxSelectors
                      possibleValues={possibleSalePrices}
                      defaultMinValue={savedSearch.wholesale_min}
                      defaultMaxValue={savedSearch.wholesale_max}
                      minPlaceholder='None'
                      maxPlaceholder='None'
                      controlWidth={MIN_MAX_SELECTOR_WIDTH}
                      onChange={(val) => {
                        if (val.minValue !== savedSearch.wholesale_min) {
                          this.props.onSearchChange('wholesale_min', val.minValue)
                        } else if (val.maxValue !== savedSearch.wholesale_max) {
                          this.props.onSearchChange('wholesale_max', val.maxValue)
                        }
                      }} />
                  </section>

                  <section>
                    <h3>Estimated Retail Price</h3>

                    <TFMinMaxSelectors
                      possibleValues={possibleSalePrices}
                      defaultMinValue={savedSearch.retail_min}
                      defaultMaxValue={savedSearch.retail_max}
                      minPlaceholder='None'
                      maxPlaceholder='None'
                      controlWidth={MIN_MAX_SELECTOR_WIDTH}
                      onChange={(val) => {
                        if (val.minValue !== savedSearch.retail_min) {
                          this.props.onSearchChange('retail_min', val.minValue)
                        } else if (val.maxValue !== savedSearch.retail_max) {
                          this.props.onSearchChange('retail_max', val.maxValue)
                        }
                      }} />
                  </section>

                  <section>
                    <h3>Minimum Estimated Profit</h3>
                    <div>
                      <div className='secondary-text'>{Formatters.formatCurrency(this.state.profitMin)}</div>
                      <TFSelect
                        name="profit_min"
                        isMulti={false}
                        value={{label: savedSearch.profit_min ? Formatters.formatCurrency(savedSearch.profit_min) : 'None', value: savedSearch.profit_min || 'None'}}
                        onChange={(v) => {
                          this.props.onSearchChange('profit_min', v?.value !== 'None' ? v.value : null)
                        }}
                        closeMenuOnSelect={true}
                        placeholder={'Minimum estimated profit'}
                        options={this.arrayToSelectOptions(['None', '500', '1000', '1500', '2000', '3000', '5000', '7500', '10000']).map((item) => ({label: item.value === 'None' ? 'None' : Formatters.formatCurrency(item.label), value: item.value ? item.value : 'None'}))} />
                    </div>
                  </section>


                  <section>
                    <h3>Vehicle Types</h3>
                    <TFSelect
                      name="vehicle_type"
                      isMulti={true}
                      value={this.arrayToSelectOptions(savedSearch.vehicle_type)}
                      onChange={(v) => {
                        this.props.onSearchChange('vehicle_type', v?.length > 0 ? v.map((v) => v.value) : null)
                      }}
                      closeMenuOnSelect={true}
                      placeholder={'Select types'}
                      options={possibleSegments} />
                  </section>


                  <section>
                    <h3>Model Year</h3>
                    <TFMinMaxSelectors
                      possibleValues={possibleYears}
                      defaultMinValue={savedSearch.year_min}
                      defaultMaxValue={savedSearch.year_max}
                      minPlaceholder='None'
                      maxPlaceholder='None'
                      controlWidth={MIN_MAX_SELECTOR_WIDTH}
                      onChange={(val) => {
                        if (val.minValue !== savedSearch.year_min) {
                          this.props.onSearchChange('year_min', val.minValue)
                        } else if (val.maxValue !== savedSearch.year_max) {
                          this.props.onSearchChange('year_max', val.maxValue)
                        }
                      }} />
                  </section>

                  <section>
                    <h3>Makes</h3>
                    <TFSelect
                      name="makes"
                      isMulti={true}
                      value={selectedClassificationMakes}
                      onChange={(v) => {
                        let selectedMakeValues = v.map((v) => v.value)
                        let uniqueSelectedMakeValues = [...new Set(selectedMakeValues)]

                        if (uniqueSelectedMakeValues?.length > 0) {
                          this.loadMakesCorpus(uniqueSelectedMakeValues)
                        } else {
                          this.setState({ makesCorpus: null})
                        }
                        
                        let newClassification = savedSearch.classification ? Object.assign(savedSearch.classification) : []
                        const newClassificationUniqueMakes = [...new Set(newClassification.map((c) => c.make))]
                        if (uniqueSelectedMakeValues.length > newClassificationUniqueMakes.length) {
                          newClassification.push({ make: v[v.length - 1].value })
                        } else {
                          // Loop through newClassification and remove any that are not in uniqueSelectedMakeValues
                          newClassification = newClassification.filter((c) => {
                            return uniqueSelectedMakeValues.find((uv) => uv === c.make)
                          })
                        }
                        this.props.onSearchChange('classification', newClassification)
                      }}
                      closeMenuOnSelect={true}
                      placeholder={'Select makes'}
                      options={possibleMakes} />
                  </section>

                  <section>
                    <h3>Models</h3>
                    {
                      makesAreSelected && possibleModels &&
                      <TFSelect
                        name="models"
                        isMulti={true}
                        value={selectedClassificationModels}
                        onChange={(v) => {
                          this.modelsDidChange(v)
                        }}
                        closeMenuOnSelect={true}
                        placeholder={'Limit models'}
                        options={ possibleModels } />
                    }
                    {
                      !makesAreSelected &&
                      <div className='secondary-text'>Select makes to limit models</div>
                    }
                  </section>

                  <section>
                    <h3>Trims</h3>
                    {
                      !modelsAreSelected &&
                      <div className='secondary-text'>Select models to limit trims</div>
                    }
                    {
                      trimsAreSelected &&
                      <div className='secondary-text'>{selectedTrims.length} selected {pluralize('trim', selectedTrims.length, false)}: {selectedTrimsString}</div>
                    }
                    {
                      modelsAreSelected &&
                      <>
                        <a href="#model-trim" onClick={(e) => {
                          e.preventDefault()
                          this.setState({ isShowingModelsAndTrimsModal: true })
                        }}>{trimsAreSelected ? 'Edit' : 'Select'} trims</a>
                        {
                          this.state.isShowingModelsAndTrimsModal &&
                          <ModelsAndTrimsModal
                            makesCorpus={makesCorpus}
                            classification={savedSearch.classification}
                            onCancel={() => this.setState({ isShowingModelsAndTrimsModal: false })}
                            addTrim={this.addTrim}
                            removeTrim={this.removeTrim}
                            />
                        }
                      </>
                    }
                  </section>

                  <section>
                    <h3>Odometer</h3>

                    <TFMinMaxSelectors
                      possibleValues={possibleMiles}
                      defaultMinValue={savedSearch.mileage_min}
                      defaultMaxValue={savedSearch.mileage_max}
                      controlWidth={MIN_MAX_SELECTOR_WIDTH}
                      onChange={(val) => {
                        if (val.minValue !== savedSearch.mileage_min) {
                          this.props.onSearchChange('mileage_min', val.minValue)
                        } else if (val.maxValue !== savedSearch.mileage_max) {
                          this.props.onSearchChange('mileage_max', val.maxValue)
                        }
                      }} />
                  </section>

                  <section>
                    <a href="#toggle" className="expand" onClick={(e) => {
                      e.preventDefault()
                      SessionStore.setHiddenAuctionsSearchVehicleDetails(!isVehicleDetailsCollapsed)
                      this.setState({ isVehicleDetailsCollapsed: !isVehicleDetailsCollapsed })
                    }}>
                      <h3 className={`split-row ${isVehicleDetailsCollapsed ? 'contracted' : ''}`}>Vehicle Details
                          { isVehicleDetailsCollapsed ? <ExpandIcon color='#aaa' width={24} /> : <ContractIcon color='#aaa' width={24} /> }
                      </h3>
                    </a>

                    {
                      !isVehicleDetailsCollapsed &&
                      <div style={{marginTop: '10px'}}>
                        <TFSelect
                          name="drive_train"
                          isMulti={true}
                          value={this.arrayToSelectOptions(savedSearch.drive_train)}
                          onChange={(v) => {
                            this.props.onSearchChange('drive_train', v?.length > 0 ? v.map((v) => v.value) : null)
                          }}
                          closeMenuOnSelect={true}
                          placeholder={'Drivetrain'}
                          options={possibleDriveTypes} />

                        <TFSelect
                          name="transmission"
                          isMulti={true}
                          value={this.arrayToSelectOptions(savedSearch.transmission)}
                          onChange={(v) => {
                            this.props.onSearchChange('transmission', v?.length > 0 ? v.map((v) => v.value) : null)
                          }}
                          closeMenuOnSelect={true}
                          placeholder={'Transmission'}
                          options={possibleTransmissions} />

                        <TFSelect
                          name="fuel_type"
                          isMulti={true}
                          value={this.arrayToSelectOptions(savedSearch.fuel_type)}
                          onChange={(v) => {
                            this.props.onSearchChange('fuel_type', v?.length > 0 ? v.map((v) => v.value) : null)
                          }}
                          closeMenuOnSelect={true}
                          placeholder={'Fuel'}
                          options={possibleFuels} />

                        <TFSelect
                          name="exterior_color"
                          isMulti={true}
                          value={this.arrayToSelectOptions(savedSearch.exterior_color)}
                          onChange={(v) => {
                            this.props.onSearchChange('exterior_color', v?.length > 0 ? v.map((v) => v.value) : null)
                          }}
                          formatOptionLabel={({ value, label }) => this.formatOptionLabelColor(value, label)}
                          closeMenuOnSelect={true}
                          placeholder={'Exterior Color'}
                          options={possibleExteriorColors} />

                        <TFSelect
                          name="interior_color"
                          isMulti={true}
                          value={this.arrayToSelectOptions(savedSearch.interior_color)}
                          onChange={(v) => {
                            this.props.onSearchChange('interior_color', v?.length > 0 ? v.map((v) => v.value) : null)
                          }}
                          formatOptionLabel={({ value, label }) => this.formatOptionLabelColor(value, label)}
                          closeMenuOnSelect={true}
                          placeholder={'Interior Color'}
                          options={possibleInteriorColors} />
                      </div>
                    }
                  </section>


                  <section>
                    <h3>Condition Report Score</h3>
                    <AuctionsSearchRangeSlider
                      defaultMin={savedSearch.condition_min}
                      defaultMax={savedSearch.condition_max}
                      marks={{
                        0: '0',
                        1: '1',
                        2: '2',
                        3: '3',
                        4: '4',
                        5: '5'
                      }}
                      step={1}
                      allowCross={false}
                      min={0}
                      max={5}
                      {...platformFunctions.sliderStyleProps()}
                      onAfterChange={(val) => {
                        if (val[0] !== savedSearch.condition_min) {
                          this.props.onSearchChange('condition_min', val[0])
                        } else if (val[1] !== savedSearch.condition_max) {
                          this.props.onSearchChange('condition_max', val[1])
                        }
                      }}
                      onChangeFormatter={(valMin, valMax) => (`${valMin} to ${valMax}`)} />

                    <div className='split-row split-row-left' style={{alignItems: 'center', marginTop: '30px'}}>
                      <input type="checkbox" name="include-no-condition" id="include-no-condition" checked={savedSearch.include_no_condition === true} onChange={(e) => {
                        this.props.onSearchChange('include_no_condition', e.target.checked)
                      }} style={{width: '30px', margin: 0}} />
                      <label htmlFor='include-no-condition' className='secondary-text' style={{textTransform: 'none', marginBottom: 0, fontWeight: 'normal'}}>Include vehicles with no CR Score</label>
                    </div>
                  </section>

                  <section>
                    <h3 className='split-row'>Buy It Now Price</h3>
                    <div>
                      <TFMinMaxSelectors
                        possibleValues={possibleSalePrices}
                        defaultMinValue={savedSearch.buy_now_min}
                        defaultMaxValue={savedSearch.buy_now_max}
                        minPlaceholder='None'
                        maxPlaceholder='None'
                        controlWidth={MIN_MAX_SELECTOR_WIDTH}
                        onChange={(val) => {
                          if (val.minValue !== savedSearch.buy_now_min) {
                            this.props.onSearchChange('buy_now_min', val.minValue)
                          } else if (val.maxValue !== savedSearch.buy_now_max) {
                            this.props.onSearchChange('buy_now_max', val.maxValue)
                          }
                        }} />
                    </div>
                    
                  </section>


                  <section>
                    <h3 className='split-row'>Search by VIN</h3>
                      <div>
                          
                        <VINEntryField ref='vinRef' 
                          style={{paddingTop: '6px', paddingBottom: '6px'}}
                          onVinChanged={(vin, isVINValid, vinFailedChecksum) => {
                            if (vin && isVINValid && !vinFailedChecksum) {
                              this.props.onSearchChange('vin', vin)
                              this.setState({ searchByVINIsValid: true })
                            } else if (searchByVINIsValid) {
                              this.props.onSearchChange('vin', null)
                              this.setState({ searchByVINIsValid: false })
                            }
                          }}
                          defaultVIN={savedSearch.vin || ''}
                          placeholder='Search VIN number' />
                        
                        {
                          Boolean(searchByVINIsValid || savedSearch.vin?.length > 0) &&
                          <div className='split-row split-row-left' style={{marginTop: '10px', alignItems: 'flex-start', color: 'red'}}><WarningCircleIcon size='28px' color='red' style={{marginRight: '5px'}} /><div>All other search fields are ignored when searching by VIN</div></div>
                        }

                      </div>

                  </section>
                </div>
              }
              
            </>
          }


          {
            shouldShowCollapsedSidebar &&
            <div className='speed-search-sidebar speed-search-sidebar-collapsed' style={{width: `${dynamicSidebarWidth}px`}}>
              <a href="#show-filters"
                onClick={(e) => {
                  e.preventDefault()
                  this.props.onToggleSidebar()
                }} style={{display: 'block'}}><SlidersIcon />
                <div style={{position: 'relative', width: '100%', height: '92px'}}>
                  <div className='secondary-text' style={{position: 'absolute', right: '18px', top: '-5px', transform: 'rotate(-90deg)', transformOrigin: 'center right'}}>Show&nbsp;filters</div>
                </div>
              </a>
            </div>
          }


          
        </div>



        {
          isShowingEditChannelsModal &&
          <AuctionsEditOnlineChannelsModal
            savedSearch={savedSearch}
            coordinatesForZip={coordinatesForZip}
            onDone={() => {
              this.setState({ isShowingEditChannelsModal: false })
            }}
            onMultipleSearchParamsChange={this.props.onMultipleSearchParamsChange}
          />
        }

        {
          isShowingUpsellModal &&
          <AuctionsPlusUpsellModal
             onClose={() => {
              this.setState({ isShowingUpsellModal: false })
            }}
            onDone={() => {
              this.setState({ isShowingUpsellModal: false })
            }}
            metricsValue={'filters-upsell'}
             />
        }
      </div>
    )
  }
}



// FIXME: corpus
const possibleMiles = [
  { label: '0', value: 0 },
  { label: '500', value: 500 },
  { label: '1,000', value: 1000 },
  { label: '5,000', value: 5000 },
  { label: '10,000', value: 10000 },
  { label: '20,000', value: 20000 },
  { label: '30,000', value: 30000 },
  { label: '40,000', value: 40000 },
  { label: '50,000', value: 50000 },
  { label: '75,000', value: 75000 },
  { label: '100,000', value: 100000 },
  { label: '150,000', value: 150000 },
  { label: '200,000', value: 200000 },
  { label: '250,000', value: 250000 },
  { label: '300,000', value: 300000 },
  { label: '350,000', value: 350000 },
  { label: '400,000', value: 400000 }
]

const possibleSalePrices = [
  { label: '$500', value: 500 },
  { label: '$1,000', value: 1000 },
  { label: '$2,000', value: 2000 },
  { label: '$5,000', value: 5000 },
  { label: '$10,000', value: 10000 },
  { label: '$15,000', value: 15000 },
  { label: '$20,000', value: 20000 },
  { label: '$25,000', value: 25000 },
  { label: '$30,000', value: 30000 },
  { label: '$35,000', value: 35000 },
  { label: '$40,000', value: 40000 },
  { label: '$45,000', value: 45000 },
  { label: '$50,000', value: 50000 },
  { label: '$55,000', value: 55000 },
  { label: '$60,000', value: 60000 },
  { label: '$65,000', value: 65000 },
  { label: '$70,000', value: 70000 },
  { label: '$80,000', value: 80000 },
  { label: '$90,000', value: 90000 },
  { label: '$100,000', value: 100000 },
  { label: '$125,000', value: 125000 },
  { label: '$150,000', value: 150000 },
  { label: '$175,000', value: 175000 },
  { label: '$200,000', value: 200000 },
  { label: '$250,000', value: 250000 },
  { label: '$300,000', value: 300000 },
  { label: '$500,000', value: 500000 }
]
