import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import AuctionsListingCell from './AuctionsListingCell'
import AuctionsListingRow from './AuctionsListingRow'
import Spinner from '../common/Spinner'
import moment from 'moment'
import Formatters from '../../api/constants/Formatters'


export default class AuctionsListingsList extends React.Component {
  renderListings = () => {
    const { isActivityList, auctionListingsDisplayType, listings, showRunNumber, showChannel, onWatchlistToggle, onClick, todayCount, rowStyle } = this.props

    const today = moment()
    const yesterday = moment().subtract(1, 'days')
    let cursorDate = null
    let results = []

    for (let listing of listings) {
      if (isActivityList) {
        const listingDate = moment(listing.created_at)
        if (!listingDate.isSame(cursorDate, 'day') ) {
          let dateString = ''
          if (listingDate?.isSame(today, 'day')) {
            dateString = `${todayCount > 0 ? Formatters.formatThousands(todayCount) : '' } Added Today`
          } else if (listingDate?.isSame(yesterday, 'day')) {
            dateString = 'Added Yesterday'
          } else {
            dateString = `Added ${listingDate.format('dddd, MMMM Do')}`
          }
          results.push(<h2 style={{width: '100%', marginTop: cursorDate ? '180px' : '0px', marginLeft: auctionListingsDisplayType === 'grid' ? '0px' : '10px', borderBottom: '2px solid #ddd'}} key={`al-sec-${listingDate.format('YYYYMD')}`}>{dateString}</h2>)
        }
        cursorDate = listingDate
      }

      results.push(
        auctionListingsDisplayType === 'grid' ? (
          <AuctionsListingCell
            key={`auv-${listing.id}`}
            listing={listing}
            showRunNumber={showRunNumber}
            showChannel={showChannel}
            onWatchlistToggle={onWatchlistToggle}
            onClick={(event, listingID) => {
              onClick(event, listingID)
            }} />
        ) : (
          <AuctionsListingRow
            style={{...rowStyle}}
            key={`auv-${listing.id}`}
            listing={listing}
            showRunNumber={showRunNumber}
            showChannel={showChannel}
            onWatchlistToggle={onWatchlistToggle}
            onClick={(event, listingID) => {
              onClick(event, listingID)
            }} />
        )
      )
    }

    if (auctionListingsDisplayType === 'grid') {
      return (
        <div className='grid-list' style={{padding: '8px', borderTop: '1px solid #eee'}}>{results}</div>
      )
    } else {
      return results
    }
  }

  render() {
    const { onScrollEnd, hasMore } = this.props

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={onScrollEnd}
        useWindow={true}
        hasMore={hasMore}
        loader={<Spinner key="pagination-spinner" />} >
          {this.renderListings()}
      </InfiniteScroll>         
    )
  }
}