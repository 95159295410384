import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SessionStore from '../../api/stores/SessionStore'
import AuctionStore from '../../api/stores/AuctionStore'
import ValuationRowNotesPopover from '../valuations/ValuationRowNotesPopover'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'
import Avatar from '../user/Avatar'
import TargetIcon from '../common/icons/TargetIcon'
import WatchlistIcon from '../common/icons/WatchlistIcon'
import { Button } from 'reactstrap'


export default class AuctionsListingCell extends React.Component {
  state = {
    shouldBounce: false,
    watchlistingIsDisabled: false
  }

  onClick = (event, listing) => {
    if (event.target.className === 'watchlist-icon') {
      return
    }

    if (this.props.onClick) {
      this.props.onClick(event, listing.id)
    }

    // This allows us to intercept and pass context for prev / next
    if (this.props.onListingShow) {
      event.preventDefault()
      this.props.onListingShow(listing)
    }
  }

  toggleWatchlist = () => {
    if (this.state.watchlistingIsDisabled) { return }
    const listing = this.props.listing
    this.setState({
      shouldBounce: listing.watchlist !== true,
      watchlistingIsDisabled: true
    })

    AuctionStore.toggleListingWatchlist(listing, false)
    if (this.props.onWatchlistToggle) { this.props.onWatchlistToggle(listing) }

    // Disable watchlist for a bit to prevent double clicks
    setTimeout(() => {
      this.setState({watchlistingIsDisabled: false})
    }, 1000)
  }

  render() {
    const listing = this.props.listing

    const showRunNumber = this.props.showRunNumber === true
    const showLaneNumber = this.props.showLaneNumber === true
    const showChannel = this.props.showChannel === true
    const showWatchlistToggle = this.props.showWatchlistToggle !== false
    const showValues = SessionStore.hasAuctionsPlus()

    const auctionDate = listing.start_time ? moment(listing.start_time).format('M/D -') : ''

    var channelDisplay = ''
    if (showChannel && listing.auction && AppConstants.auctionOnlineSourceNames[listing.auction.code]) {
      channelDisplay = AppConstants.auctionOnlineSourceNames[listing.auction.code]
    } else if (showChannel) {
      channelDisplay = auctionDate + ' ' + listing.auction.name
    }

    return (
      <div className={`grid-list-cell auction-listing-cell ${this.props.className}`}>
        <Link to={`/auctions/listing/${listing.id}`} onClick={(event) => { this.onClick(event, listing) }} className='listing-link'>
          <div className="thumbnail">
            <img src={listing.thumbnail ? listing.thumbnail : '/images/missing-photo-placeholder.png'} alt="Listing" loading="lazy" />

            {
              showRunNumber && listing['auction_run_number'] !== null &&
              <div className='overlay run-number'>Run {listing['auction_run_number']}</div>
            }

            {
              showLaneNumber && listing['auction_lane_number'] !== null &&
              <div className='overlay lane-number'>Lane {listing['auction_lane_number']}</div>
            }

            {
              listing.team_watches && listing.team_watches.length > 0 &&
              <div className="overlay team-watchers">
                {
                  listing.team_watches.map((user, index) => {
                    return (
                      <Avatar user={user} size={AppConstants.avatarSizes.xsmall} key={`watcher-${index}`} />
                    )
                  })
                }
              </div>
            }
          </div>

          <div className='listing-body-c'>
            <div className="listing-body">

              <div className='split-row' style={{alignItems: 'flex-start'}}>
                <div>
                  <h3 className='year'>{listing.year} {listing.make}</h3>
                  <h3>{listing.model}</h3>
                </div>
                {
                  showWatchlistToggle === true &&
                  <Button className={`watchlist${listing.watchlist === true ? ' watchlist-active' : ''}${this.state.shouldBounce ? ' watchlist-bounce' : ''}`} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.toggleWatchlist()
                  }}>
                    <WatchlistIcon width={24} height={24} />

                  </Button>
                }
              </div>


              {
                listing.trim &&
                <div className="secondary-text">{listing.trim}</div>
              }
              {
                showChannel &&
                <div className="secondary-text channel">{channelDisplay}</div>
              }

              <div className='split-row'>
                <div>
                  {
                    listing.mileage > 0 &&
                    <div>
                      <strong>{Formatters.formatThousands(listing.mileage)}</strong> <span className='secondary-text'>miles</span>
                    </div>
                  }
                </div>
                {
                  listing.condition_grade !== null &&
                  <div title="Condition"><span className="secondary-text">CR</span> {listing.condition_grade}</div>
                }
              </div>


              {
                listing.vehicle && (listing.vehicle.has_vehicle_notes || listing.vehicle.structured_data?.target_bid_price) &&
                <div className='split-row' style={{margin: '5px 0 0 0'}}>
                  {
                    listing.vehicle && listing.vehicle.has_vehicle_notes &&
                    <ValuationRowNotesPopover valuation={listing.vehicle} />
                  }
                  {
                    listing.vehicle.structured_data?.target_bid_price &&
                    <div title="Target price" className='split-row split-row-right'>
                      <TargetIcon color='#aaa' style={{marginRight: '5px'}} />
                      {Formatters.formatCurrency(listing.vehicle.structured_data.target_bid_price)}</div>
                  }
                </div>
              }
            </div>
          </div>

          {
            showValues && (listing.wholesale_value || listing.retail_value || listing.profit_value) &&
            <div className='listing-pricing'>
              {
                Boolean(listing.wholesale_value !== null) &&
                <div>
                  {Formatters.formatCurrency(listing.wholesale_value)} <span className='secondary-text'>est. wholesale</span>
                </div>
              }
              {
                Boolean(listing.retail_value !== null) &&
                <div>
                  {Formatters.formatCurrency(listing.retail_value)} <span className='secondary-text'>est. retail</span>
                </div>
              }
              {
                Boolean(listing.profit_value !== null) &&
                <div style={{color: listing.profit_value >= 0 ? 'green' : 'red', borderTop: '1px solid #ddd'}}>
                  {Formatters.formatCurrency(listing.profit_value)} <span className='secondary-text' style={{color: listing.profit_value >= 0 ? 'green' : 'red'}}>est. profit</span>
                </div>
              }
            </div>
          }
        </Link>



      </div>
    )
  }

}
