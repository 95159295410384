import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import SessionStore from '../../api/stores/SessionStore'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'


export default class ValuationSummary extends React.Component {
  state = {
    isLoadingCarfax: ValuationStore.carfaxIsLoadingForVehicle(this.props.vehicleID),
    carfaxSummary: null,
    carfaxSnapshotStatus: null,
    isLoadingAutoCheck: ValuationStore.autoCheckIsLoadingForVehicle(this.props.vehicleID),
    autoCheckSummary: null,
    isLoadingVintel: ValuationStore.vintelIsLoadingForVehicle(this.props.vehicleID),
    vintelReport: ValuationStore.vintelReportWithID(this.props.vehicleID),
  }

  componentDidMount() {
    ValuationStore.on(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxSummaryDidChange)
    ValuationStore.on(`carfax_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingCarfaxDidChange)
    ValuationStore.on(`autocheck_summary_changed_${this.props.vehicleID}`, this.autoCheckSummaryDidChange)
    ValuationStore.on(`autocheck_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingAutoCheckDidChange)
    ValuationStore.on(`vintel_report_changed_${this.props.vehicleID}`, this.vintelReportDidChange)
    ValuationStore.on(`vintel_report_is_loading_changed_${this.props.vehicleID}`, this.isLoadingVintelDidChange)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxSummaryDidChange)
    ValuationStore.removeListener(`carfax_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingCarfaxDidChange)
    ValuationStore.removeListener(`autocheck_summary_changed_${this.props.vehicleID}`, this.autoCheckSummaryDidChange)
    ValuationStore.removeListener(`autocheck_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingAutoCheckDidChange)
    ValuationStore.removeListener(`vintel_report_changed_${this.props.vehicleID}`, this.vintelReportDidChange)
    ValuationStore.removeListener(`vintel_report_is_loading_changed_${this.props.vehicleID}`, this.isLoadingVintelDidChange)
  }

  componentDidUpdate(prevProps) {
    if (this.props.vehicleID !== prevProps.vehicleID) {
      const prevVehicleID = prevProps.vehicleID
      ValuationStore.removeListener(`carfax_summary_changed_${prevVehicleID}`, this.carfaxSummaryDidChange)
      ValuationStore.removeListener(`carfax_summary_is_loading_changed_${prevVehicleID}`, this.isLoadingCarfaxDidChange)
      ValuationStore.removeListener(`autocheck_summary_changed_${prevVehicleID}`, this.autoCheckSummaryDidChange)
      ValuationStore.removeListener(`autocheck_summary_is_loading_changed_${prevVehicleID}`, this.isLoadingAutoCheckDidChange)
      ValuationStore.removeListener(`vintel_report_changed_${prevVehicleID}`, this.vintelReportDidChange)
      ValuationStore.removeListener(`vintel_report_is_loading_changed_${prevVehicleID}`, this.isLoadingVintelDidChange)

      this.setState({
        carfaxSummary: null,
        carfaxSnapshotStatus: null,
        autoCheckSummary: null,
        vintelReport: null,
      })
      ValuationStore.on(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxSummaryDidChange)
      ValuationStore.on(`carfax_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingCarfaxDidChange)
      ValuationStore.on(`autocheck_summary_changed_${this.props.vehicleID}`, this.autoCheckSummaryDidChange)
      ValuationStore.on(`autocheck_summary_is_loading_changed_${this.props.vehicleID}`, this.isLoadingAutoCheckDidChange)
      ValuationStore.on(`vintel_report_changed_${this.props.vehicleID}`, this.vintelReportDidChange)
      ValuationStore.on(`vintel_report_is_loading_changed_${this.props.vehicleID}`, this.isLoadingVintelDidChange)
    }
  }

  vintelReportDidChange = () => {
    this.setState({
      vintelReport: ValuationStore.vintelReportWithID(this.props.vehicleID)
    })
  }

  isLoadingVintelDidChange = () => {
    this.setState({
      isLoadingVintel: ValuationStore.vintelIsLoadingForVehicle(this.props.vehicleID),
    })
  }

  carfaxSummaryDidChange = () => {
    const vehicleID = this.props.vehicleID
    const carfaxSummary = ValuationStore.carfaxSummaryWithID(vehicleID)
    this.setState({
      carfaxSummary,
      carfaxSnapshotStatus: ValuationFunctions.carfaxSnapshotStatus(carfaxSummary)
    })
  }

  isLoadingCarfaxDidChange = () => {
    this.setState({
      isLoadingCarfax: ValuationStore.carfaxIsLoadingForVehicle(this.props.vehicleID)
    })
  }

  isLoadingAutoCheckDidChange = () => {
    this.setState({
      isLoadingAutoCheck: Boolean(ValuationStore.autoCheckIsLoadingForVehicle(this.props.vehicleID))
    })
  }

  autoCheckSummaryDidChange = () => {
    const vehicleID = this.props.vehicleID
    const autoCheckSummary = ValuationStore.autoCheckSummaryWithID(vehicleID)
    this.setState({
      autoCheckSummary: autoCheckSummary
    })
  }

  getMessages = () => {
    const carblyData = this.props.providerData
    const { isLoadingCarfax, carfaxSummary, isLoadingAutoCheck, autoCheckSummary, vintelReport, isLoadingVintel } = this.state
    var newMessages = Object.assign(carblyData.messages)
    if (!newMessages) {
      newMessages = []
    }

    // RM the existing VHR messages, as they'll get recreated
    newMessages = newMessages.filter((m) => m['isVHR'] !== true)

    let carfaxMessage = null
    if (carfaxSummary) {
      const snapshotStatus = this.state.carfaxSnapshotStatus
      if (snapshotStatus.type !== 'ignore') {
        carfaxMessage = snapshotStatus
        carfaxMessage.isVHR = true
        
      }
    } else if (isLoadingCarfax) {
      carfaxMessage = {
        isVHR: true,
        type: 'good',
        message: `Loading ${AppConstants.providerNames.carfax}`
      }
    }

    let autoCheckMessage = null
    if (ValuationFunctions.autoCheckSummaryIsComplete(autoCheckSummary)) {
      const isInRange = autoCheckSummary.score >= autoCheckSummary.typical_low_score
      autoCheckMessage = {
        isVHR: true,
        type: isInRange ? 'good' : 'warn',
        message: isInRange ? `${AppConstants.providerNames.autocheck} within or above expected range` : `${AppConstants.providerNames.autocheck} score is below expected range`
      }
    } else if (isLoadingAutoCheck) {
      autoCheckMessage = {
        isVHR: true,
        type: 'good',
        message: `Loading ${AppConstants.providerNames.autocheck}`
      }
    }

    let vintelMessage = null
    if (vintelReport) {
      const snapshotStatus = ValuationFunctions.vintelSnapshotStatus(vintelReport)
      if (snapshotStatus !== 'error') {
        vintelMessage = {}
        switch (snapshotStatus) {
          case 'good':
            vintelMessage.type = 'good'
            vintelMessage.message = `${AppConstants.providerNames.vintel} is clean`
            break;
          case 'warn':
            vintelMessage.type = 'warn'
            vintelMessage.message = `${AppConstants.providerNames.vintel} reports issues with this vehicle`
            break;
          default:
            vintelMessage.type = 'ignore'
            vintelMessage.message = `Error loading ${AppConstants.providerNames.vintel} report`
        }
      }
    }

    if (autoCheckMessage !== null) {
      newMessages.splice(0, 0, autoCheckMessage)
    }

    if (vintelMessage !== null) {
      newMessages.splice(0, 0, vintelMessage)
    }

    if (carfaxMessage !== null) {
      newMessages.splice(0, 0, carfaxMessage)
    }

    return newMessages
  }


  render() {
    const carblyData = this.props.providerData
    const { wholesale, retail } = carblyData.summary
    const { no_appraisal: noAppraisalWholesale } = wholesale
    const { no_appraisal: noAppraisalRetail } = retail
    var missingDataMoreBooks = []
    if (noAppraisalWholesale === AppConstants.noAppraisalMoreBooksNeeded) {
      missingDataMoreBooks.push('wholesale')
    }
    if (noAppraisalRetail === AppConstants.noAppraisalMoreBooksNeeded) {
      missingDataMoreBooks.push('retail')
    }
    const messages = this.getMessages()

    return (
      <div>
        <div className="card">
          <div className='summary-c'>
            <h2>Appraisal Assistant</h2>
            <div className="summary-data">
              {
                !missingDataMoreBooks.includes('wholesale') &&
                <div className='big-c big-wholesale-c'>
                  <div className='big-type-label'>WHOLESALE</div>
                  {
                    noAppraisalWholesale ?
                    <div>{ValuationFunctions.noSummaryText(noAppraisalWholesale)}</div> :
                    <>
                      <div className='big-value'>{Formatters.formatCurrency(wholesale.appraisal)}</div>
                      <div className='big-range'>{Formatters.formatCurrency(wholesale.range.low)} to {Formatters.formatCurrency(wholesale.range.high)}</div>
                    </>
                  }
                </div>
              }
              {
                !missingDataMoreBooks.includes('retail') &&
                <div className='big-c big-retail-c'>
                  <div className='big-type-label'>RETAIL</div>
                  {
                    noAppraisalRetail ?
                    <div>{ValuationFunctions.noSummaryText(noAppraisalRetail)}</div> :
                    <>
                      <div className='big-value'>{Formatters.formatCurrency(retail.appraisal)}</div>
                      <div className='big-range'>{Formatters.formatCurrency(retail.range.low)} to {Formatters.formatCurrency(retail.range.high)}</div>
                    </>
                  }
                </div>
              }
            </div>


            <div className="summary-messages">
              {
                (!messages || messages.length === 0) &&
                <div>No vehicle alerts</div>
              }
              {
                messages && messages.length > 0 &&
                <div>
                {
                  messages.map((message) => {

                    return (
                      <div style={{display: 'flex', marginBottom: '5px'}} key={`app-msg-${message['message'].slice(10)}`}>
                        <img src={`/images/appraisal-message-${message.type === 'warn' || message.type === 'error' ? 'warning' : 'ok'}.png`} alt="" style={{flexGrow: 0, flexShrink: 0, width: '20px', height: '20px', marginRight: '5px'}} />
                        <div style={{flexGrow: 1, flexShrink: 1}}>{message['message']}</div>
                      </div>
                    )
                  })
                }
                {
                  global.isPartnerPreview &&
                  <div style={{fontWeight: 'bold'}}>{AppConstants.providerNames['carfax']} and {AppConstants.providerNames['autocheck']} account integration is free with a Carbly account.</div>
                }
                </div>
              }
            </div>
          </div>
        </div>

        {
          missingDataMoreBooks.map((dataType) => {
            return (
              <div className="secondary-text" style={{marginTop: '-35px', marginBottom: '40px', textAlign: 'center'}}>Not enough books to provide {dataType} Appraisal Assistant data.</div>
            )
          })
        }

      </div>
    )

  }
}
