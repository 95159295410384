import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import ExtensionIcon from './icons/ExtensionIcon'

export default class ExtensionPrompt extends React.Component {

  render() {
    if (!global.isExtensionMode && SessionStore.shouldShowExtensionPrompt())  {
      return (
        <a href="https://getcarbly.com/browser-extension/" target="_blank" className="upsell-c">
          <ExtensionIcon width={50} height={50} />
          <div>
            <h2>Install the Carbly Browser Extension</h2>
            <div>See Carbly vehicle valuation data directly on web pages as you browse the web.  Great for viewing values on your favorite auction websites or inventory tools.</div>
          </div>

        </a>
      )
    }

    return null
  }
}