import { EventEmitter } from 'events'
import Dispatcher from '../actions/Dispatcher'
import AuctionsActionCreators from '../actions/AuctionsActionCreators'
import LimitedCollection from '../constants/LimitedCollection'
import RemoteConstants from '../constants/RemoteConstants'
import ValuationActionCreators from '../actions/ValuationActionCreators'
import DotAuctionEventsCollection from '../constants/DotAuctionEventsCollection'
var ActionTypes = RemoteConstants.ActionTypes


// Registry of listing index pages for dynamic update
const listingIndexCollections = [
  'userAuctionUpcomingVehicles',
  'auctionsWatchlist',
  'auctionsWatchlistArchive',
  'auctionLaneListings',
  'dotAuctionEvents'
]

class AuctionStore extends EventEmitter {
  constructor() {
    super()

    this.auctionListingsSearchResults = new LimitedCollection()
    this.auctionListingsSearchResultsTotalCount = new LimitedCollection()
    this.auctionListingsSearchResultsTodayCount = new LimitedCollection()
    this.auctionListingsSearchResultsEndOfListReached = new LimitedCollection()
    this.auctionListingsSearchResultsPageNumber = new LimitedCollection()
    this.auctionListingsSearchResultsLastFreshDataTime = new LimitedCollection()
    this.auctionUpcomingLastFreshDataTime = null

    this.similarVehiclesSearchResults = null
    this.similarVehiclesSearchResultsTotalCount = 0

    this.fullListings = new LimitedCollection()
    this.nextAuctionLanes = null

    this.auctionsWatchlist = null
    this.auctionsWatchlistCount = null
    this.auctionsRooftopWatchlistCount = null

    this.auctionsWatchlistArchive = null

    this.auctionLiveSalesStatus = null

    this.auctionsForVIN = new LimitedCollection()

    this.auctionLaneListings = new LimitedCollection()
    this.auctionLaneListingsEndOfListReached =  new LimitedCollection()
    this.auctionLaneListingsLastRunNumber =  new LimitedCollection()
    this.auctionLaneListingsLocations = new LimitedCollection()
    this.auctionLaneListingsDates = new LimitedCollection()

    this.auctionSaleListings = new LimitedCollection()
    this.auctionSaleListingsEndOfListReached =  new LimitedCollection()
    this.auctionSaleListingsLastOffset =  new LimitedCollection()
    this.auctionSaleListingsLocations = new LimitedCollection()
    this.auctionSaleListingsDates = new LimitedCollection()

    this.savedSearches = null
    this.savedSearchItems = new LimitedCollection()

    this.dotAuctionEvents = new DotAuctionEventsCollection()
    this.openListingIndexURL = null
  }

  fetchListingWithID(listingID, storeWithSourceID = false) {
    const cachedListing = this.listingWithID(listingID)
    if (cachedListing) {
      console.log("CACHED LISTING: ", listingID);
      this.emit('auction_listing_received')
      return
    }

    // Tapped notifications EDGE dotAuction only have the listing's source_id, not the listing ID.  We need to pass this info through the
    // request cycle to ensure it's keyed properly in the store upon response
    AuctionsActionCreators.loadAuctionListing(listingID, storeWithSourceID)
  }

  preFetchListingWithID(listingID) {
    console.log("PREFETCH ID: ", listingID);
    const cachedListing = this.listingWithID(listingID)
    if (cachedListing) {
      console.log("CACHED LISTING in prefetch: ", listingID);
      return
    }

    AuctionsActionCreators.prefetchAuctionListing(listingID)
  }

  listingWithID(listingID) {
    return this.fullListings.dataWithID(listingID)
  }

  clearCachedListings() {
    this.fullListings = new LimitedCollection()
  }

  auctionLaneListingsWithClientID(clientID) {
    return this.auctionLaneListings.dataWithID(clientID)
  }

  auctionLaneListingsLocationWithClientID(clientID) {
    return this.auctionLaneListingsLocations.dataWithID(clientID)
  }

  auctionLaneListingsDateWithClientID(clientID) {
    return this.auctionLaneListingsDates.dataWithID(clientID)
  }

  auctionSaleListingsWithClientID(clientID) {
    return this.auctionSaleListings.dataWithID(clientID)
  }

  auctionSaleListingsLocationWithClientID(clientID) {
    return this.auctionSaleListingsLocations.dataWithID(clientID)
  }

  auctionSaleListingsDateWithClientID(clientID) {
    return this.auctionSaleListingsDates.dataWithID(clientID)
  }

  auctionsForVINWithClientID(clientID) {
    return this.auctionsForVIN.dataWithID(clientID)
  }

  savedSearchWithClientID(clientID) {
    return this.savedSearchItems.dataWithID(clientID)
  }

  auctionDetailsForLocationCode(locationCode) {
    if (!this.allAuctions) { return null }
    return this.allAuctions.find(a => a.code === locationCode)
  }

  listingWasUpdated(listing, isFullListing) {
    // The detail listing screen needs to know if someone else modified it
    // Example is setting target price on a nested vehicle

    if (isFullListing) {
      // FIXME: probably not needed
      // However, if coming from a partial listing (index view) we don't want the central
      // store of full listings to get overwritten with partial data
      this.fullListings.addToCollection(listing.id, listing)
      this.emit('auction_listing_received')
    }

    if (this.auctionListingsSearchResults?.collection) {
      for (let c of this.auctionListingsSearchResults.collection) {
        if (c?.data?.length > 0) {
          for (let l of c.data) {
            if (l.id === listing.id) {
              l.watchlist = listing.watchlist
              if (listing.vehicle) {
                l.vehicle = listing.vehicle
              }
            }
          }
        }
      }
    }


    // Handle dynamic watchlist remove / re-add / update from watchlist view
    if (this.auctionsWatchlist === null) { this.auctionsWatchlist = {} }
    var newAuctionsWatchlist = {}
    if (listing.auction_in_past !== true) {

      if (this.auctionsWatchlist?.original_or_relistings) {
        newAuctionsWatchlist.original_or_relistings = this.updateWatchlistSection(this.auctionsWatchlist.original_or_relistings, listing)
      }

      if (this.auctionsWatchlist?.running_elsewhere) {
        newAuctionsWatchlist.running_elsewhere = this.updateWatchlistSection(this.auctionsWatchlist.running_elsewhere, listing)
      }
      

      
    }
    this.auctionsWatchlist = newAuctionsWatchlist
    this.emit("global_auction_listing_update")
  }

  updateWatchlistSection = (listingsList, modifiedListing) => {
    if (!listingsList || listingsList.length === 0) { return null }
    let newListingsList = [...listingsList]

    for (let sale of newListingsList) {
      if (sale.lanes) {
        for (let lane of sale.lanes) {
          for (let searchListing of lane.listings) {
            if (searchListing.id === modifiedListing.id) {
              searchListing.watchlist = modifiedListing.watchlist
              searchListing.vehicle = modifiedListing.vehicle
              break
            }
          }
        }
      } else if (sale.listings) {
        for (let searchListing of sale.listings) {
          if (searchListing.id === modifiedListing.id) {
            searchListing.watchlist = modifiedListing.watchlist
            searchListing.vehicle = modifiedListing.vehicle
            break
          }
        }
      }
    }
    return newListingsList
  }

  updateListingInArray(listing, array) {
    if (!listing || !array) { return }
    return array.map((l) => {
      return l.id === listing.id ? listing : l
    })
  }

  toggleListingWatchlist(listing, isFullListing = true) {
    let newListing = {...listing}
    newListing.watchlist = listing.watchlist === true ? false : true

    if (newListing.watchlist === true) {
      this.auctionsWatchlistCount = this.auctionsWatchlistCount ? this.auctionsWatchlistCount + 1 : 1
    } else {
      this.auctionsWatchlistCount = this.auctionsWatchlistCount && this.auctionsWatchlistCount > 0 ? this.auctionsWatchlistCount - 1 : 0
    }

    this.listingWasUpdated(newListing, isFullListing)

    AuctionsActionCreators.watchlistVIN(listing.vin, listing.id, newListing.watchlist === true, listing.vin)
  }

  listingOpenedInModal(listingID) {
    this.openListingIndexURL = window.location.href
    window.history.replaceState(null, "Carbly", `/auctions/listing/${listingID}`)
  }

  listingClosedInModal() {
    if (this.openListingIndexURL) {
      window.history.replaceState(null, "Carbly", this.openListingIndexURL)
      this.openListingIndexURL = null
    }
  }

  handleActions(action) {
    switch(action.type) {
      case ActionTypes.AUCTION_VEHICLES_SEARCH_RECEIVED: {
        const response = action.vehicles
        let newListings = null
        const clientID = action.clientID

        if (response && response.data && clientID === '__similar_vehicles') {
          this.similarVehiclesSearchResults = response.data
          this.similarVehiclesSearchResultsTotalCount = response?.full_collection_count
          this.emit('auction_similar_vehicles_change')
          break
        } else {
          if (response && response.data) {
            const pageNumber = action.page
            const existingPageNumber = this.auctionListingsSearchResultsPageNumber.dataWithID(clientID) || 1
            const isFreshList = existingPageNumber >= pageNumber

            if (isFreshList) {
              newListings = response.data
              this.auctionListingsSearchResultsPageNumber.addToCollection(clientID, 1)
            } else {
              newListings = [...this.auctionListingsSearchResults.dataWithID(clientID), ...response.data]
              this.auctionListingsSearchResultsPageNumber.addToCollection(clientID, pageNumber)
            }
  
            this.auctionListingsSearchResultsEndOfListReached.addToCollection(clientID, Boolean(response.data.length < response.pagination_count))
          } else {
            this.auctionListingsSearchResultsEndOfListReached.addToCollection(clientID, true)
          }

          this.auctionListingsSearchResults.addToCollection(clientID, newListings)
          this.auctionListingsSearchResultsTotalCount.addToCollection(clientID, response?.full_collection_count)
          this.auctionListingsSearchResultsTodayCount.addToCollection(clientID, response?.added_today_count)
          
          // The query ID is passed separately so that we can ensure that we only accept the latest response for a given query
          // Multiple queries can be in flight at once
          this.emit(`auction_vehicles_search_change-${clientID}${action.queryID}`)
          break
        }
      }

      case ActionTypes.LOAD_AUCTIONS_BY_STATE: {
        this.emit('load_auctions_by_state')
        break
      }

      case ActionTypes.AUCTIONS_BY_STATE_RECEIVED: {
        const auctionsByState = action.auctions

        if (auctionsByState && !auctionsByState.errors) {
          this.auctionsByState = auctionsByState
          this.allAuctions = []
          for (let stateKey of Object.keys(auctionsByState)) {
            const stateAuctions = auctionsByState[stateKey]
            this.allAuctions.push(...stateAuctions)
          }

          this.emit('auctions_by_state_change')
        }

        break
      }

      case ActionTypes.DOT_AUCTION_EVENTS_RECEIVED: {
        const { page, events, errors } = action
        if (page === 1) {
          this.dotAuctionEvents = new DotAuctionEventsCollection(events, errors)
        } else {
          this.dotAuctionEvents.addToCollection(events, errors)
        }
        this.emit('dot_auction_events_change')

        break
      }

      case ActionTypes.AUCTION_LIVE_SALES_STATUS_RECEIVED: {
        this.auctionLiveSalesStatus = action.status
        this.emit('auction_live_sales_status_change')

        break
      }

      case ActionTypes.AUCTION_LISTING_UPDATED: {
        // Auction only cares about this for sending an update for the nested vehicle activity
        this.emit('auction_listing_change')

        setTimeout(() => {
          // FIXME: need to re-run the auction home saved search?

          // Need to update activity
          ValuationActionCreators.loadVehiclesHome()
        }, 200)

        break
      }

      case ActionTypes.LOAD_AUCTION_LISTING: {
        this.emit('auction_listing_load')
        break
      }

      case ActionTypes.AUCTION_LISTING_RECEIVED: {
        const listing = action.auctionListing

        if (listing) {
          var collectionKey = listing.id
          if (action.storeWithSourceID === true && listing?.source_id) {
            collectionKey = listing.source_id
          }
          this.fullListings.addToCollection(collectionKey, listing)
        }

        this.emit('auction_listing_received')
        break
      }

      case ActionTypes.AUCTION_LISTING_PREFETCH_RECEIVED: {
        const listing = action.auctionListing
        if (listing) {
          this.fullListings.addToCollection(listing.id, listing)
        }

        // Do not emit
        break
      }

      case ActionTypes.NEXT_AUCTION_SALES_RECEIVED: {
        this.nextAuctionLanes = action.locations

        this.emit('auction_next_sales_change')
        break
      }

      case ActionTypes.AUCTION_LISTINGS_FOR_LANE_RECEIVED: {
        const clientID = action.clientID

        this.auctionLaneListingsLocations.addToCollection(clientID, action?.listings?.auction)
        this.auctionLaneListingsDates.addToCollection(clientID, action?.listings?.start_time)

        const response = action.listings
        let newListings = null
        const existingListings = this.auctionLaneListings.dataWithID(clientID)

        if (response && response.data) {
          if (!existingListings || existingListings.length === 0 || action.afterRunNumber === null || action.afterRunNumber === undefined) {
            newListings = response.data
          } else {
            newListings = [...existingListings, ...response.data]
          }

          if (response.pagination_count && response.data.length < response.pagination_count) {
            this.auctionLaneListingsEndOfListReached.addToCollection(clientID, true)
          } else {
            this.auctionLaneListingsEndOfListReached.addToCollection(clientID, false)
          }

          const lastListing = newListings[newListings.length - 1]
          if (lastListing) {
            this.auctionLaneListingsLastRunNumber.addToCollection(clientID, lastListing.auction_run_number)
          }
        } else {
          this.auctionLaneListingsEndOfListReached.addToCollection(clientID, true)
        }


        this.auctionLaneListings.addToCollection(clientID, newListings)

        this.emit(`auction_lane_listings_change_${clientID}`)
        break
      }

      case ActionTypes.AUCTION_LISTINGS_FOR_SALE_RECEIVED: {
        const clientID = action.clientID

        this.auctionSaleListingsLocations.addToCollection(clientID, action?.listings?.auction)
        this.auctionSaleListingsDates.addToCollection(clientID, action?.listings?.start_time)

        const response = action.listings
        let newListings = null
        const existingListings = this.auctionSaleListings.dataWithID(clientID)
        const offset = action.offset

        if (response && response.data) {
          if (!existingListings || existingListings.length === 0 || offset === 0) {
            newListings = response.data
          } else {
            newListings = [...existingListings, ...response.data]
          }

          if (response.pagination_count && response.data.length < response.pagination_count) {
            this.auctionSaleListingsEndOfListReached.addToCollection(clientID, true)
          } else {
            this.auctionSaleListingsEndOfListReached.addToCollection(clientID, false)
          }

            this.auctionSaleListingsLastOffset.addToCollection(clientID, offset)

        } else {
          this.auctionLaneListingsEndOfListReached.addToCollection(clientID, true)
        }


        this.auctionSaleListings.addToCollection(clientID, newListings)

        this.emit(`auction_sale_listings_change_${clientID}`)
        break
      }

      case ActionTypes.AUCTION_WATCHLIST_RECEIVED: {
        // Server does not honor pagination
        this.auctionsWatchlist = action.vehicles?.data
        this.emit('auctions_watchlist_received')
        break
      }

      case ActionTypes.AUCTION_WATCHLIST_ARCHIVE_RECEIVED: {
        // Server does not honor pagination
        this.auctionsWatchlistArchive = action.vehicles?.data
        this.emit('auctions_watchlist_archive_received')
        break
      }

      case ActionTypes.AUCTION_WATCHLIST_VEHICLE_COUNTS_RECEIVED: {
        this.auctionsWatchlistCounts = action.counts
        this.emit("auctions_watchlist_count_change")
        break
      }

      case ActionTypes.VIN_WATCHLISTED: {
        AuctionsActionCreators.loadAuctionsWatchlistVehicleCounts()
        break
      }

      case ActionTypes.AUCTIONS_FOR_VIN_RECEIVED: {
        const auction = action.auction
        const clientID = action.clientID

        this.auctionsForVIN.addToCollection(clientID, auction)

        this.emit(`auctions_for_vin_change_${clientID}`)
        break
      }

      case ActionTypes.SAVED_SEARCHES_RECEIVED: {
        this.savedSearches = action.savedSearches
        this.emit('saved_searches_received')
        break
      }

      case ActionTypes.SAVED_SEARCH_RECEIVED: {
        const clientID = action.clientID
        this.savedSearchItems.addToCollection(clientID, action.savedSearch)

        this.emit('saved_search_change')
        break
      }

      case ActionTypes.GLOBAL_SAVED_SEARCH_UPDATED: {
        this.emit('global_saved_search_updated')
        AuctionsActionCreators.loadSavedSearches()
        break
      }

      case ActionTypes.SAVED_SEARCH_DELETED: {
        this.emit('saved_search_deleted')
        break
      }

      case ActionTypes.COORDINATES_FOR_ZIPCODE_RECEIVED: {
        this.coordinatesForZip = action.coordinates
        this.emit('coordinates_for_zip_change')
        break
      }


      default:
        break

    }
  }

}

const auctionStore = new AuctionStore()
Dispatcher.register(auctionStore.handleActions.bind(auctionStore))

export default auctionStore
