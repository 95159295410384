import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import SessionStore from '../../api/stores/SessionStore'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import Spinner from '../common/Spinner'
import ClipboardIcon from '../common/icons/ClipboardIcon'
import AppConstants from '../../api/constants/AppConstants'


export default class ValuationShareModal extends React.Component {
  state = {
    providers: null
  }

  componentDidMount() {
    ValuationStore.on(`valuation_share_created_${this.props.clientID}`, this.valuationShareCreated)

    var providers = ValuationFunctions.formattedProvidersForShare(SessionStore.user.appraisal_providers, this.props.hasCarblyData === true)

    this.setState({
      isCreatingShare: false,
      createdShare: null,
      shareNotes: false,
      sharePhotos: true,
      shareCarfax: true,
      providers: providers
    })
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_share_created_${this.props.clientID}`, this.valuationShareCreated)
  }

  toggleProvider = (providerName) => {
    var newProviders = Object.assign(this.state.providers)
    var targ = newProviders.find(p => p.name === providerName)
    if (targ) {
      targ.selected = !targ.selected
    }
    this.setState({ providers: newProviders })
  }

  createShare = () => {
    const vehicleUUID = this.props.vehicleUUID
    const { providers, shareNotes, sharePhotos, shareCarfax } = this.state
    var selectedProviders = providers.map((p) => p.selected === true ? p.name : null)
    selectedProviders = selectedProviders.filter((p) => p !== null)

    const params = {
      providers: selectedProviders,
      shareNotes: shareNotes,
      sharePhotos: sharePhotos,
      shareCarfax: shareCarfax === true && this.props.hasCarfaxPreview === true
    }

    ValuationActionCreators.createValuationShare(vehicleUUID, {options: params}, this.props.clientID)
    this.setState({
      isCreatingShare: true
    })
  }

  valuationShareCreated = () => {
    this.setState({
      createdShare: ValuationStore.valuationShareWithClientID(this.props.clientID),
      isCreatingShare: false
    })
  }


  render() {
    const { providers, shareNotes, sharePhotos, shareCarfax, isCreatingShare, createdShare } = this.state
    if (!providers) {
      return null
    }

    var shareURL = ''
    if (createdShare) {
      const location = window.location
      var baseURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')
      shareURL = `${baseURL}/shared/${createdShare.uuid}`
    }

    var isSubmittable = false
    for (let p of providers) {
      if (p.selected === true) {
        isSubmittable = true
        break
      }
    }

    isSubmittable = shareNotes || sharePhotos || shareCarfax ? true : isSubmittable

    return (
      <Modal size="lg" centered={true} isOpen={true} contentClassName='modal-c'>
        <ModalHeader className='modal-header'>Share</ModalHeader>
        <ModalBody>

        {
          !isCreatingShare && !createdShare &&
          <div>
            <div>Select the data you'd like to share</div>
            <div className="valuation-share-providers">
              {
                providers.map((provider) => {
                  return (
                    <div key={`share-provider-${provider.name}`}>
                      <input type="checkbox" checked={provider.selected} onChange={(e) => {
                          this.toggleProvider(provider.name)
                        }} />
                      <a href="#toggle" onClick={(e) => {
                        e.preventDefault()
                        this.toggleProvider(provider.name)
                      }}>{AppConstants.providerNames[provider.name]}</a>
                    </div>
                  )
                })
              }

              {
                this.props.hasCarfaxPreview === true &&
                <div>
                  <input type="checkbox" name="share-notes" checked={shareCarfax} onChange={(e) => {
                    this.setState({ shareCarfax: !shareCarfax})
                  }} />
                  <a href="#toggle" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ shareCarfax: !shareCarfax})
                  }}>Share {AppConstants.providerNames['carfax']} Report</a>
                </div>
              }

              <div style={{marginTop: '10px'}}>
                <input type="checkbox" name="share-notes" checked={shareNotes} onChange={(e) => {
                  this.setState({ shareNotes: !shareNotes})
                }} />
                <a href="#toggle" onClick={(e) => {
                  e.preventDefault()
                  this.setState({ shareNotes: !shareNotes})
                }}>Share notes?</a>
              </div>

              <div>
                <input type="checkbox" name="share-notes" checked={sharePhotos} onChange={(e) => {
                  this.setState({ sharePhotos: !sharePhotos})
                }} />
                <a href="#toggle" onClick={(e) => {
                  e.preventDefault()
                  this.setState({ sharePhotos: !sharePhotos})
                }}>Share photos?</a>
              </div>
            </div>


          </div>
        }


        {
          !isCreatingShare && createdShare &&
          <div>
            <div>Shared report link:</div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
              <Input type="text" value={shareURL} readOnly onFocus={(e) => {
                e.target.select();
              }} />
              <CopyToClipboard
                text={shareURL}
                onCopy={() => {
                  toast.success("Link Copied to Clipboard")
                }}
                >
                <div className="label" style={{marginLeft: '8px', color: '#2f323e', cursor: 'pointer', fontSize: 30, marginTop: '-8px'}}>
                  <ClipboardIcon />
                </div>
              </CopyToClipboard>
            </div>

            <div style={{margin: '10px 0'}}>Copy the above link, and you can then send it to the desired recipient.</div>
            <a href={shareURL} target="_blank" rel="noopener noreferrer">View shared report</a>

          </div>
        }

        {
          isCreatingShare &&
          <Spinner />
        }



        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.onCancel}>Close</Button>
          {
            !createdShare &&
            <Button color="primary" disabled={!isSubmittable || isCreatingShare} onClick={this.createShare}>Generate Shared Report</Button>
          }

        </ModalFooter>
      </Modal>

    )

  }

}
