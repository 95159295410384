import { GET_USER } from '../queries'

const getBiddingToken = async (client, queryOpts) => {
  let token
  try {
    const resp = await client.query({
      query: GET_USER,
      ...queryOpts,
      context: {
        endpointName: 'pipeline',
      },
    })
    token = resp.data.me.bidEngineUserInfo.token
  } catch(e) {
    // FIXME: Handle error?
    console.log('appsync bidding token fetch err', e)
  }
  return token
}

export default getBiddingToken
