import React, { memo, useState, useMemo, useCallback, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import UserActionCreators from '../../api/actions/UserActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import Avatar from './Avatar'
import platformFunctions from '../../platformFunctions'
import { ReactComponent as UploadIcon } from 'bootstrap-icons/icons/camera.svg'

const photoURLBase = platformFunctions.photoURLBase()
const PHOTO_SIZE = 116

const EditProfileModal = ({ user, onCancel }) => {
  const { first_name, last_name, avatar_url } = user
  const [textInputs, setTextInputs] = useState({ first_name, last_name })
  const [upload, setUpload] = useState(null)
  const [avatarIsRemoved, setAvatarIsRemoved] = useState(false)
  const [errors, setErrors] = useState([])
  const [isValid, setIsValid] = useState(true)
  const [hasSuccess, setHasSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onTextFieldChange = useCallback((e) => {
    const { name, value } = e.target
    setTextInputs(prev => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const onSubmit = useCallback(() => {
    setIsSubmitting(true)
    setHasSuccess(false)
    const params = { ...textInputs }
    if (upload) {
      params.avatar = upload.file
    } else if (avatarIsRemoved) {
      params.avatar = null
    }
    UserActionCreators.updateUser(params)
  }, [textInputs, upload, avatarIsRemoved])

  const onFileSelect = useCallback(([file]) => {
    if (file) {
      setUpload({ file, tempUrl: URL.createObjectURL(file) })
    }
  }, [])

  const onRemoveClick = useCallback(() => {
    if (upload) {
      setUpload(null)
    } else if (avatar_url) {
      setAvatarIsRemoved(true)
    }
  }, [upload, avatar_url])

  const onUserUpdated = useCallback(() => {
    const errors = SessionStore.userErrors || []
    const hasErrors = Boolean(errors.length)
    setIsSubmitting(false)
    setErrors(errors)
    setHasSuccess(!hasErrors)
    if (!hasErrors) {
      // Reset
      const { first_name, last_name } = SessionStore.user
      setTextInputs({ first_name, last_name })
      setUpload(null)
      setAvatarIsRemoved(false)
    }
  }, [])

  useEffect(() => {
    // Validate text not blank
    setIsValid(Object.values(textInputs).every(v => v?.length > 0))
  }, [textInputs])

  useEffect(()=> {
    SessionStore.on('user_updated', onUserUpdated)
    return () => {
      SessionStore.removeListener('user_updated', onUserUpdated)
    }
  }, [onUserUpdated])

  useEffect(()=> {
    let timeout
    if (hasSuccess) {
      timeout = setTimeout(onCancel, 1000)
    }
    return () => clearTimeout(timeout)
  }, [hasSuccess, onCancel])

  useEffect(()=> {
    return () => {
      URL.revokeObjectURL(upload?.tempUrl);
    }
  }, [upload])

  const {
    getRootProps,
    getInputProps
  } = useDropzone({ accept: 'image/*', multiple: false, onDrop: onFileSelect })

  const image = useMemo(() => {
    if (upload) return upload.tempUrl
    if (avatar_url && !avatarIsRemoved) return `${photoURLBase}${avatar_url}`
    return null
  }, [avatar_url, upload, avatarIsRemoved])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'>
      <ModalHeader className='modal-header'>
        Edit Profile
      </ModalHeader>
      <ModalBody>
        <div className='edit-user-profile'>
          <div className='flex-row-and-cols ai-c'>
            <div>
              <div className='edit-user-profile__photo-wrapper'>
                <div {...getRootProps({ className: 'edit-user-profile__upload-photo cursor-pointer' })}>
                  <input { ...getInputProps() } />
                  <Avatar
                    user={user}
                    firstName={textInputs.first_name}
                    lastName={textInputs.last_name}
                    image={image}
                    size={PHOTO_SIZE}
                  />
                  <div className='edit-user-profile__overlay'>
                    <UploadIcon />
                  </div>
                </div>
                {
                  image &&
                  <button className='edit-user-profile__remove link' onClick={onRemoveClick}>Remove</button>
                }
              </div>
            </div>
            <div>
              <input type='text' className='field edit-user-profile__field' name='first_name' onChange={onTextFieldChange} value={textInputs.first_name} placeholder='First name' maxLength='128' />
              <input type='text' className='field edit-user-profile__field' name='last_name' onChange={onTextFieldChange} value={textInputs.last_name} placeholder='Last name' maxLength='128' />
            </div>
          </div>
          <div className='edit-user-profile__messages'>
            {
              errors.map((error, idx) => <div key={idx} className='error-color'>{error}</div>)
            }
            {
              hasSuccess &&
                <div className='success-color'>Profile updated successfully</div>
            }
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onCancel}>Close</Button>
        <Button color='primary' disabled={isSubmitting || !isValid} onClick={onSubmit}>Save</Button>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EditProfileModal)
