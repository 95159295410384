import React from 'react'
import { Modal, ModalBody, Tooltip } from 'reactstrap'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsListingShow from './AuctionsListingShow'
import CloseIcon from '../common/icons/CloseIcon'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import ValuationHUD from '../valuations/ValuationHUD'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'

const PREV_NEXT_STRING_LENGTH = 34
const NAVIGATION_THROTTLE_TIME = 1000

export default class AuctionsListingShowModal extends React.Component {
  state = {
    listingID: this.props.listingID,
    listing: null,
    isNavigationThrottled: false,
    previousTooltipIsOpen: false,
    nextTooltipIsOpen: false,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown)
    const listingID = this.props.listingID
    if (listingID) {
      AuctionStore.listingOpenedInModal(listingID)
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  shortenedPrevNextString = (string) => {
    return string?.length > PREV_NEXT_STRING_LENGTH ? string.slice(0, PREV_NEXT_STRING_LENGTH-1) + '...' : string
  }

  previousListingInContext = () => {
    const { listingID } = this.state
    const { listingsContext } = this.props
    return listingID && listingsContext ? AuctionFunctions.previousListingInListingsContext({id: listingID}, listingsContext) : null
  }

  nextListingInContext = () => {
    const { listingID } = this.state
    const { listingsContext } = this.props
    const nextListingInContext = AuctionFunctions.nextListingInListingsContext({id: listingID}, listingsContext)

    // If we're at the end of the list, call the onEndOfListReached callback to fetch more from parent
    setTimeout(() => {
      if (!nextListingInContext && this.props.onEndOfListReached) {
        this.props.onEndOfListReached()
      }
    }, 2000);

    return listingID && listingsContext ? nextListingInContext : null
  }

  handleKeyDown = (e) => {
    // Don't navigate if the user is typing in an input or textarea
    const formElements = ['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'];
    if (formElements.includes(e.target.tagName)) {
      return
    }

    // Left and right arrow keys
    if (e.keyCode === 37) {
      this.navigateToPreviousListing()
    } else if (e.keyCode === 39) {
      this.navigateToNextListing()
    }
  }

  navigateToPreviousListing = () => {
    const previousListingInContext = this.previousListingInContext()
    if (previousListingInContext) { this.navigateToListing(previousListingInContext.id) }
  }

  navigateToNextListing = () => {
    const nextListingInContext = this.nextListingInContext()
    if (nextListingInContext) { this.navigateToListing(nextListingInContext.id) }
  }

  navigateToListing = (listingID) => {
    if (this.state.isNavigationThrottled) { return }
    this.setState({
      isNavigationThrottled: true,
      listingID: listingID
    })
    setTimeout(() => {
      this.setState({isNavigationThrottled: false})
    }, NAVIGATION_THROTTLE_TIME)
  }

  onCloseModal = () => {
    if (this.props.listingID) {
      AuctionStore.listingClosedInModal()
    }
    this.props.onDone()
  }

  listingDidChange = (listing) => {
    this.setState({ listing: listing })
  }


  render() {
    const { listingsContext } = this.props
    const { listingID, isNavigationThrottled, previousTooltipIsOpen, nextTooltipIsOpen, listing } = this.state
    
    const previousListingInContext = this.previousListingInContext()
    const nextListingInContext = this.nextListingInContext()

    const previousString = previousListingInContext ? `Previous: ${ previousListingInContext.year } ${ previousListingInContext.make } ${ previousListingInContext.model }` : null
    const shortenedPreviousString = this.shortenedPrevNextString(previousString)
    

    const nextString = nextListingInContext ? `Next: ${ nextListingInContext.year } ${ nextListingInContext.make } ${ nextListingInContext.model }` : null
    const shortenedNextString = this.shortenedPrevNextString(nextString)


    return (
      <Modal className={global.isExtensionMode ? '' : 'listing-modal'} isOpen={true} centered={true} backdrop={true} toggle={this.onCloseModal} backdropClassName='modal-bg' contentClassName='modal-c'>
        <div className='auction-listing-modal-controls'>
          <a href={`/auctions/listing/${listingID}`} className='listing-new-tab' target="_blank" rel="noopener noreferrer"><ExternalLinkIcon style={{width: '18px', height: '18px'}} /> Open in new tab</a>
            
            <div className='split-row listing-prev-next' style={{opacity: isNavigationThrottled ? 0.1 : 1}}>
              {
                previousListingInContext ?
                <a
                  id='previous-listing'
                  href="#previous"
                  onClick={(e) => {
                    e.preventDefault()
                    this.navigateToPreviousListing()
                  }}
                  style={{marginRight: '20px'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" style={{marginRight: '8px'}}>
                    <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                  </svg>
                  {shortenedPreviousString}
                  </a> : <a />
              }
              {
                nextListingInContext ?
                <a
                  id='next-listing'
                  href="#next"
                  onClick={(e) => {
                    e.preventDefault()
                    this.navigateToNextListing()
                  }}
                  style={{marginLeft: '20px'}}>
                  {shortenedNextString}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" style={{marginLeft: '8px'}}>
                    <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                  </svg>
                </a> : <a />
              }
            </div>

            {
              previousListingInContext &&
              <Tooltip delay={{ "show": 1000, "hide": 0 }} placement="bottom" isOpen={previousTooltipIsOpen} target="previous-listing" toggle={() => this.setState({ previousTooltipIsOpen: !previousTooltipIsOpen})}>Open previous vehicle in this list.  You can also use the left arrow &#8592; key on your keyboard.</Tooltip>
            }

            {
              nextListingInContext &&
              <Tooltip delay={{ "show": 1000, "hide": 0 }} placement="bottom" isOpen={nextTooltipIsOpen} target="next-listing" toggle={() => this.setState({ nextTooltipIsOpen: !nextTooltipIsOpen})}>Open next vehicle in this list.  You can also use the right arrow &#8594; key on your keyboard.</Tooltip>
            }

            <a href="#close" className='listing-close' onClick={(e) => {
              e.preventDefault()
              this.onCloseModal()
            }}><CloseIcon /> Close</a>
          </div>

          {
            listing &&
            <ValuationHUD persistent showScrollToTop={false} items={[`${listing.year} ${listing.make} ${listing.model}`, listing.mileage ? `${Formatters.formatThousands(listing.mileage)} ${pluralize('mile', listing.mileage, false)}` : '']} style={{left: 0, bottom: 0, top: 'inherit'}} />
          }
        <ModalBody>
          <AuctionsListingShow listingID={listingID} history={this.props.history} isEmbedded isModal listingsContext={listingsContext} showValuation={this.props.showValuation} onListingChange={this.listingDidChange} />
        </ModalBody>
      </Modal>
    )
  }
}