import React from 'react'
import Formatters from '../../api/constants/Formatters'

const rowHeight = 44
const conditionTickHeight = 16
const conditionTickWidth = 10
const conditionLabelHeight = 16
const conditionLabelWidth = 66
const conditionTooltipWidth = 70
const conditionTooltipHeight = 16
const conditionRangeLineHeight = 16
const scaleTicksCount = 8
const scaleTicksCountExtension = 3
const scaleTickBuffer = 5
const scaleGridWidth = 2
const scaleValueWidth = 80
const cursorValueHeight = 26
const cursorValueOffsetY = 6
const cursorValueWidth = 80
const cursorSpreadBoxHeight = 10
const cursorSpreadBoxPriceHeight = 20

const conditionColors = {
  rough: '#cb2828',
  average: '#ffa600',
  clean: '#0062cc',
  xclean: '#038a71'
}

// TODO: vertical optimization

export default class RangeGraph extends React.Component {
  state = {
    isMousedOver: false,
    hasDisplayedValue: false,
    conditionMouseOverKey: null,
    initialMarkerPrice: this.props.initialMarkerPrice,
    markerPrice: this.props.initialMarkerPrice,
    forceHideSpread: false
  }

  componentDidUpdate(prevProps, prevState) {
    const newInitialMarkerPrice = this.props.initialMarkerPrice
    if (this.state.initialMarkerPrice !== newInitialMarkerPrice) {
      this.setState({
        markerPrice: newInitialMarkerPrice,
        initialMarkerPrice: newInitialMarkerPrice
      })
    }
  }

  xPercentageForValue = (val, lowVal, highVal) => {
    let xPosMult = (val - lowVal) / (highVal - lowVal)
    return 100 * xPosMult
  }

  scaleValues = (min, max) => {
    var scales = []
    const adjustedScaleTicksCount = global.isExtensionMode ? scaleTicksCountExtension : scaleTicksCount
    var diff = (max - min) / adjustedScaleTicksCount
    var cursor = min
    do {
      scales.push(cursor)
      cursor = cursor + diff
    }
    while (cursor < max);

    return scales
  }

  onMouseMove = (e) => {
    const { markerPrice } = this.state
    const position = this.dataForGraphXPosition(e.clientX)

    var cursor = this.refs.cursor
    if (!cursor) return
    cursor.style.left = `${position.x}px`

    var cursorPrice = this.refs.cursorPrice
    const cursorPosition = position.x - (cursorValueWidth / 2)
    cursorPrice.style.left = `${cursorPosition}px`
    cursorPrice.style.opacity = 1.0
    cursorPrice.textContent = Formatters.formatCurrency(Math.round(position.value))

    const markerXPercent = this.xPercentageForValue(markerPrice, this.scaleMin, this.scaleMax)

    if (markerXPercent) {
      var isPositive = true
      if (markerXPercent < position.xPercent) {
        this.refs.cursorSpreadBox.style.left = `${markerXPercent}%`
        this.refs.cursorSpreadBox.style.width = `${position.xPercent - markerXPercent}%`
      } else {
        isPositive = false
        this.refs.cursorSpreadBox.style.left = `${position.xPercent}%`
        this.refs.cursorSpreadBox.style.width = `${markerXPercent - position.xPercent}%`
      }
      const diffPrice = position.value - markerPrice
      const diffPercentage = Math.round((diffPrice / markerPrice) * 100)
      // this.refs.cursorSpreadBoxPrice.style.left = `${this.refs.cursorSpreadBox.style.left - 20}%`
      // this.refs.cursorSpreadBoxPrice.style.width = `${this.refs.cursorSpreadBox.style.width + 20}%`
      this.refs.cursorSpreadBoxPrice.style.width = '100%'
      if (isPositive) {
        this.refs.cursorSpreadBoxPrice.style.paddingLeft = 0
        this.refs.cursorSpreadBoxPrice.style.paddingRight = `${100 - position.xPercent}%`
        this.refs.cursorSpreadBoxPrice.style.textAlign = 'right'

      } else {
        this.refs.cursorSpreadBoxPrice.style.paddingRight = 0
        this.refs.cursorSpreadBoxPrice.style.paddingLeft = this.refs.cursorSpreadBox.style.left
        this.refs.cursorSpreadBoxPrice.style.textAlign = 'left'
      }

      this.refs.cursorSpreadBoxPrice.innerHTML = `<span style="padding-right: 10px;">${Formatters.formatCurrency(Math.round(diffPrice))}</span> ${diffPercentage}%`
    }

    if (this.state.hasDisplayedValue === false) {
      this.setState({ hasDisplayedValue: true })
    }

    if (this.state.forceHideSpread) {
      this.setState({ forceHideSpread: false })
    }

  }

  setTarget = (e) => {
    const position = this.dataForGraphXPosition(e.clientX)
    this.setState({
      markerPrice: position.value,
      forceHideSpread: true
    })
  }

  renderConditionLabels = (row, conditionXOffset) => {
    // We need to convert to a pixel system
    const target = this.refs.graphContainer
    if (!target) { return }
    var rect = target.getBoundingClientRect()

    var conditionLabels = []

    var index = 0
    for (let value of row.values) {
      let xPercentage = this.xPercentageForValue(value.price, this.scaleMin, this.scaleMax)
      index++

      let negativeMarginLeft = ((conditionLabelWidth / 2) + conditionXOffset)
      let lPos = parseInt((xPercentage / 100) * rect.width)  - negativeMarginLeft

      if (value.price === this.state.markerPrice) {
        continue
      }

      conditionLabels.push({
        lPos: lPos,
        rPos: lPos + conditionLabelWidth,
        xPercentage: `${xPercentage}%`,
        marginLeft: negativeMarginLeft * -1,
        condition: value.condition,
        price: value.price
      })
    }

    // Go through again, and hide inner labels that collide
    var results = []
    index = 0
    for (let conditionLabel of conditionLabels) {
      if (index === 0 || index === conditionLabels.length - 1 || (conditionLabel.lPos >= conditionLabels[index-1].rPos && conditionLabel.rPos <= conditionLabels[index+1].lPos)) {
        results.push(
          <div className="condition-marker-label" style={{height: `${conditionLabelHeight}px`, width: `${conditionLabelWidth}px`, marginLeft: `${conditionLabel.marginLeft}px`, left: conditionLabel.xPercentage, zIndex: 99}} key={`match-point-label-${row.providerKey}${conditionLabel.condition}`}>{Formatters.formatCurrency(conditionLabel.price)}</div>
        )
      }
      index++
    }

    return results
  }

  dataForGraphXPosition = (xPosition) => {
    const target = this.refs.graphContainer
    var rect = target.getBoundingClientRect()
    var position = {}
    position['x'] = xPosition - rect.left
    const positionFraction = position['x'] / rect.width
    position['xPercent'] = positionFraction * 100
    position['value'] = ((this.scaleMax - this.scaleMin) * positionFraction) + this.scaleMin
    return position
  }

  canShowProvider = (providerKey) => {
    if (global.isExtensionMode && providerKey === 'nada') { return false }
    return true
  }

  render() {
    const data = this.props.data
    const { minPrice, maxPrice } = data

    let filteredRows = data?.rows?.filter((row) => { return this.canShowProvider(row.providerKey) })

    // Start with 15% above/below the price range and then clean do some math to get the x-axis to always be multiples of 100
    var scaleMin = minPrice - ((maxPrice - minPrice) * 0.15)
    var scaleMax = maxPrice + ((maxPrice - minPrice) * 0.15)

    // If the range is too small, then just round things to the 10 place
    var roundTo = 100
    if ((scaleMax - scaleMin) < 1000) {
      roundTo = 10
    }

    scaleMin = Math.floor(scaleMin / roundTo) * roundTo
    const adjustedScaleTicksCount = global.isExtensionMode ? scaleTicksCountExtension : scaleTicksCount
    var spacing = (scaleMax - scaleMin) / adjustedScaleTicksCount
    var ceiledSpacing = Math.ceil(spacing / roundTo) * roundTo
    scaleMax = scaleMin + (ceiledSpacing * adjustedScaleTicksCount)

    this.scaleMin = scaleMin
    this.scaleMax = scaleMax


    const { isMousedOver, conditionMouseOverKey, markerPrice, forceHideSpread, hasDisplayedValue } = this.state

    const totalHeight = rowHeight * filteredRows.length
    const scaleValues = this.scaleValues(this.scaleMin, this.scaleMax)
    const markerXPercent = this.xPercentageForValue(markerPrice, this.scaleMin, this.scaleMax)
    const conditionXOffset = conditionTickWidth / 2

    return (
      <div style={{display: 'flex', flexDirection: 'row', align: 'center', marginTop: '54px'}}>
        <div style={{width: '80px', flexGrow: 0, flexShrink: 0}}>
          {
            filteredRows.map((row) => {
              return (
                <div className={`matchupRowLabel${row.providerKey === 'carbly' ? ' carbly-row' : ''}`} style={{height: `${rowHeight}px`}} key={`match-lab-${row.providerKey}`} title={row.providerFullName}><div>{row.providerName}</div></div>
              )
            })
          }
        </div>

        <div ref="graphContainer" className="graph-container" style={{flexGrow: 1, flexShrink: 1, position: 'relative', cursor: 'crosshair'}} onMouseMove={this.onMouseMove} onMouseEnter={() => { this.setState({isMousedOver: true}) }} onMouseLeave={() => { this.setState({isMousedOver: false, hasDisplayedValue: false}) }} onClick={this.setTarget}>
          {
            filteredRows.map((row) => {
              if (row?.values && row.values.length > 0) {
                const hasSingleValue = row.values.length === 1
                const rangeStart = this.xPercentageForValue(row.values[0].price, this.scaleMin, this.scaleMax)
                const rangeWidth = this.xPercentageForValue(row.values[row.values.length - 1].price, this.scaleMin, this.scaleMax) - rangeStart
                return (
                  <div className={`matchupRowValues${row.providerKey === 'carbly' ? ' carbly-row' : ''}`} style={{height: `${rowHeight}px`}} key={`match-vals-${row.providerKey}`}>
                    {
                      row.values.length > 1 &&
                      <div className="graph-condition-range" style={{height: `${conditionRangeLineHeight}px`, left: `${rangeStart}%`, width: `${rangeWidth}%`, top: `${conditionLabelHeight + (conditionTickHeight / 2) - (conditionRangeLineHeight / 2)}px`, zIndex: 90}} key={`match-point-range-${row.providerKey}`}/>
                    }
                    {
                      row.values.length > 1 &&
                      <div className="graph-x-axis-grid" style={{top: `${conditionLabelHeight + (conditionTickHeight / 2)}px`, zIndex: 40}} key={`x-axis-${row.providerKey}`}/>
                    }
                    { this.renderConditionLabels(row, conditionXOffset) }
                    {
                      row.values.map((value, index) => {
                        const xPos = `${this.xPercentageForValue(value.price, this.scaleMin, this.scaleMax)}%`

                        var normalizedColor = value['condition']
                        normalizedColor = normalizedColor === 'below' ? 'rough' : normalizedColor
                        normalizedColor = normalizedColor === 'above' ? 'clean' : normalizedColor
                        normalizedColor = normalizedColor === 'fair' ? 'rough' : normalizedColor
                        normalizedColor = normalizedColor === 'good' ? 'average' : normalizedColor
                        normalizedColor = normalizedColor === 'excellent' ? 'clean' : normalizedColor

                        var color = conditionColors[normalizedColor] ? conditionColors[normalizedColor] : '#222'
                        var additionalClass = ''
                        if (index === 0) {
                          additionalClass = ' graph-condition-start'
                        } else if (index === row.values.length - 1) {
                          additionalClass = ' graph-condition-end'
                        }
                        if (value.condition === 'all' || hasSingleValue) {
                          additionalClass = ' graph-condition-single'
                        }
                        return (
                          <div className={`graph-condition${additionalClass}`} style={{height: `${conditionTickHeight}px`, width: `${conditionTickWidth}px`, marginLeft: `-${conditionXOffset}px`, left: xPos, top: `${conditionLabelHeight}px`, zIndex: 99, backgroundColor: color}} key={`match-point-${row.providerKey}${value.condition}`} onMouseEnter={() => { this.setState({ conditionMouseOverKey: `match-point-cond-${row.providerKey}${value.condition}`})}} onMouseLeave={() => {this.setState({ conditionMouseOverKey: null })}}></div>
                        )
                      })
                    }
                    {
                      row.values.map((value, index) => {
                        // if (conditionMouseOverKey !== `match-point-cond-${row.providerKey}${value.condition}`) { return null }
                        const visible = conditionMouseOverKey === `match-point-cond-${row.providerKey}${value.condition}`
                        const xPos = `${this.xPercentageForValue(value.price, this.scaleMin, this.scaleMax)}%`
                        return (
                          <div className={`graph-marker-condition`} style={{height: `${conditionTooltipHeight}px`, width: `${conditionTooltipWidth}px`, marginLeft: `-${conditionTooltipWidth / 2}px`, left: xPos, top: `${conditionLabelHeight + conditionTickHeight + 10}px`, zIndex: 109, opacity: visible ? 1 : 0}} key={`match-point-cond-${row.providerKey}${value.condition}`}>{value.condition}</div>
                        )
                      })
                    }

                  </div>
                )
              } else {
                // Empty placeholder
                return (
                  <div style={{height: `${rowHeight}px`}} key={`match-vals-${row.providerKey}`}></div>
                )
              }


            })
          }
          {
            scaleValues.map((scale) => {
              const xPos = `${this.xPercentageForValue(scale, this.scaleMin, this.scaleMax)}%`
              var scaleYPos = conditionLabelHeight + (conditionTickHeight / 2)
              var scaleHeight = (rowHeight * filteredRows.length + scaleTickBuffer) - scaleYPos

              return (
                <div className="scale-grid" style={{position: 'absolute', top: `${scaleYPos}px`, height: `${scaleHeight}px`, width: `${scaleGridWidth}px`, marginLeft: `-${scaleGridWidth / 2}px`, left: xPos, zIndex: 88}} key={`match-scale-${xPos}`}></div>
              )
            })
          }
          {
            scaleValues.map((scale) => {
              const xPos = `${this.xPercentageForValue(scale, this.scaleMin, this.scaleMax)}%`
              return (
                <div style={{position: 'absolute', width: `${scaleValueWidth}px`, top: `${totalHeight + scaleTickBuffer}px`, left: xPos, marginLeft: `-${(scaleValueWidth / 2) - (scaleGridWidth / 2)}px`, textAlign: 'center'}} key={`match-scale-v-${xPos}`}>{Formatters.formatCurrency(Math.round(scale))}</div>
              )
            })
          }
          {
            markerPrice && markerXPercent &&
            <div className="marker" style={{top: 0, left: `${markerXPercent}%`, height: `${totalHeight}px`, width: '2px', zIndex: 89, opacity: isMousedOver ? 0.5 : 1.0}} />
          }
          {
            markerPrice && markerXPercent &&
            <div className="marker-price" style={{top: `${-cursorValueHeight - cursorValueOffsetY}px`, left: `${markerXPercent}%`, height: `${cursorValueHeight}px`, width: `${cursorValueWidth}px`, marginLeft: `-${cursorValueWidth / 2}px`, zIndex: 90, opacity: isMousedOver ? 0.5 : 1.0}}>{Formatters.formatCurrency(Math.round(markerPrice))}</div>
          }
          {
            isMousedOver &&
            <div ref="cursor" className="cursor" style={{top: 0, left: '0px', height: `${totalHeight}px`, width: '2px', zIndex: 89, opacity: hasDisplayedValue ? 1 : 0}} />
          }
          {
            isMousedOver &&
            <div ref="cursorPrice" className="cursor-price" style={{top: `${-cursorValueHeight - cursorValueOffsetY}px`, left: '0px', height: `${cursorValueHeight}px`, width: `${cursorValueWidth}px`, zIndex: 91, opacity: hasDisplayedValue ? 1 : 0}}></div>
          }
          {
            isMousedOver &&
            <div ref="cursorSpreadBox" className="cursor-spread-box" style={{top: `-${cursorValueHeight + cursorSpreadBoxHeight}px`, height: `${cursorSpreadBoxHeight}px`, opacity: forceHideSpread ? 0 : 1.0}}/>
          }
          {
            isMousedOver &&
            <div ref="cursorSpreadBoxPrice" className="cursor-spread-box-price" style={{top: `-${cursorValueHeight + cursorSpreadBoxHeight + cursorSpreadBoxPriceHeight}px`, height: `${cursorSpreadBoxPriceHeight}px`, opacity: forceHideSpread ? 0 : 1.0}}></div>
          }
        </div>
      </div>

    )
  }
}
