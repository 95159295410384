import React, { useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import AuctionsListingRow from './AuctionsListingRow'
import AccountBug from '../user/AccountBug'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import useHasLinkedAuctionEdge from '../../api/bidding/hooks/useHasLinkedAuctionEdge'
import useLoadDotAuctionItems from '../../api/bidding/hooks/useLoadDotAuctionItems'
import CalendarIcon from '../common/icons/CalendarIcon'
import AuctionEdgeAuth from '../common/AuctionEdgeAuth'
import { Link } from 'react-router-dom'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import useNetworkConnectivity from '../hooks/useNetworkConnectivity'
import CalculatorBug from '../common/CalculatorBug'
import UserActionCreators from '../../api/actions/UserActionCreators'

const useNotifyNetworkConnectivity = () => {
  // NOTE: Proof of concept for notifying network connection lost. Display toast message when lost.
  // FIXME:
  //  - Test reliability + feasibility.
  //  - Figure out how to handle in UI.
  //  - Should behavior be app-wide?
  //  - Handle connection restored? If user dismisses toast, will not know when restored.
  const isOnline = useNetworkConnectivity()
  const networkConnectivityToastId = useRef(null)

  useEffect(() => {
    toast.dismiss(networkConnectivityToastId.current)
    if (!isOnline) {
      networkConnectivityToastId.current = toast.error('Network connection lost!', { autoClose: false })
    }
  }, [isOnline])
}

export default () => {
  const hasLinkedAuctionEdge = useHasLinkedAuctionEdge()

  const storeDataSourceFunc = useCallback(() => (
    AuctionStore.dotAuctionEvents
  ), [])

  const actionCreatorFunction = useCallback((page, count) => (
    AuctionsActionCreators.loadDotAuctionEvents(page, count)
  ), [])

  const {
    data: events,
    isLoading,
    isEmptyData,
    fetchNextPage,
    isFetchingPage,
    hasLastPage,
  } = useLoadDotAuctionItems(hasLinkedAuctionEdge, storeDataSourceFunc, AuctionStore, 'dot_auction_events_change', actionCreatorFunction)

  const onWatchlistToggle = useCallback(() => {
    UserActionCreators.createMetric(AppConstants.metricNames.auctions_watchlist_dot_auction)
  }, [])

  const onScrollEnd = useCallback(() => {
    if (!isFetchingPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, isFetchingPage])

  useNotifyNetworkConnectivity()

  return (
    <div className="body-content">
      <div className="content-header">
        <h1>{AppConstants.auctionOnlineSourceNames['EDGE_DOT_AUCTION']}</h1>

        <div className="global-tools">
          <AccountBug />
          <CalculatorBug />
        </div>
      </div>

      {
        !hasLinkedAuctionEdge &&
        <div style={{marginTop: '40px', border: '1px solid #ddd', textAlign: 'center', padding: '20px', borderRadius: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
            <img src="/images/edge-cube-logo-large-dark.png" style={{width: '60px', height: '61px', marginRight: '20px'}} />
            <div>Link your {AppConstants.providerNames.auction_edge} account to enable bidding on {AppConstants.auctionOnlineSourceNames.EDGE_DOT_AUCTION} sales.</div>
          </div>
          <AuctionEdgeAuth />
        </div>
      }

      {
        hasLinkedAuctionEdge &&
        <div className="content-context-actions">
          <Link to='/auctions/my-bids'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
            </svg>
          My bids</Link>
        </div>
      }

      {
        hasLinkedAuctionEdge &&
        <div>Powered by Auction Edge, these sale events are available for bidding directly within Carbly.</div>
      }

      {
        hasLinkedAuctionEdge &&
        <div>
          <div className="card" style={{marginTop: '20px'}}>
            {
              events && !isLoading && !isEmptyData &&
              <div className="card-list" style={{ overflow: 'auto'}}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow
                  loadMore={onScrollEnd}
                  hasMore={!hasLastPage}
                  loader={<Spinner key="pagination-spinner" />}
                >
                  {
                    events.map(event => {
                      return (
                        <div key={event.id} style={{ paddingTop: 20 }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 12px', borderBottom: '2px solid #111' }}>
                            <h2 style={{ fontWeight: 'bold' }}>{event.name}</h2>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarIcon />
                              <div style={{ paddingLeft: 6 }}>
                                {moment(event.start_time).format('MMM Do, h:mm A')}
                                {
                                  Boolean(event.end_time) &&
                                  <span> until {moment(event.end_time).format('MMM Do, h:mm A')}</span>
                                }
                              </div>
                            </div>
                          </div>

                          {
                            Boolean(event.description?.length > 0) &&
                            <div>
                              {
                                event.description.map((description, index) => {
                                  if (description.name.length > 0) {
                                    return (
                                      <div key={index} style={{margin: '5px 15px'}}>{description.name}</div>
                                    )
                                  }
                                })
                              }
                            </div>
                          }

                          <div>
                            {
                              event.listings.map(listing => {
                                return (
                                  <AuctionsListingRow
                                    hasLinkedAuctionEdge
                                    listing={listing}
                                    showRunNumber={false}
                                    showChannel
                                    key={listing.id}
                                    onWatchlistToggle={onWatchlistToggle}
                                  />
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </InfiniteScroll>
              </div>
            }

            {
              isEmptyData && !isLoading &&
              <div style={{textAlign: 'center', marginBottom: '20px'}}>
                <div style={{marginTop: '20px'}}>There does not appear to be any active sales at the moment</div>
              </div>
            }

            {
              isLoading &&
              <Spinner />
            }
          </div>

          {
            events && !isLoading && !isEmptyData &&
            <div style={{margin: '20px 0'}}>
              <p>All events follow the <a href="https://www.naaa.com/Policy/policy.html" target="_blank"  rel="noopener noreferrer">NAAA Marketplace Arbitration Policy</a>.</p>

              <p>For more details, see the <a href="https://www.edgepipeline.com/policies/dotauction" target="_blank"  rel="noopener noreferrer">EDGE.Auction Policies</a>.</p>
            </div>
          }
        </div>
      }
    </div>
  )
}
