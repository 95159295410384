import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import OfferingsPicker from './OfferingsPicker'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Spinner from '../common/Spinner'

export default class OfferingsUpsellModal extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    isLoadingAvailableOfferings: true,
    availableOfferings: null,
    accountDetails: null
  }

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadAvailableOfferings()
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  offeringsAdded = () => {
    if (this.props.offeringsAdded) {
      this.props.offeringsAdded()
    }
  }
  
  render() {
    const { availableOfferings, accountDetails } = this.state
    const hasAccountOfferings = SessionStore.hasAccountOfferings()
  
    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '640px'}}>
        <ModalBody>

          {
            Boolean(!availableOfferings || !accountDetails) &&
            <Spinner />
          }

          {
            Boolean(availableOfferings && accountDetails) &&
            <OfferingsPicker
              offerings={availableOfferings}
              accountDetails={accountDetails}
              onOfferingsAdded={this.offeringsAdded}
              useTotalPrice={!hasAccountOfferings} />
          }

          {
            this.props.onCancel &&
            <div style={{textAlign: 'center'}}>
              <a href="#cancel" style={{textAlign: 'center'}} onClick={(e) => {e.preventDefault(); this.props.onCancel()}}>Cancel</a>
            </div>
          }
        </ModalBody>
      </Modal>
    )

  }

}
