import React from 'react'
import Dropzone from 'react-dropzone'
import Lightbox from 'react-image-lightbox'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import CommonFunctions from '../../api/constants/CommonFunctions'
import platformFunctions from '../../platformFunctions'

const photoURLBase = platformFunctions.photoURLBase()

export default class ValuationPhotos extends React.Component {

  state = {
    isUploadingPhotos: false,
    newPhotosReadCount: 0,
    newPhotosReadProgress: 0,
    newPhotosData: [],
    vehiclePhotos: this.props.vehiclePhotos && this.props.vehiclePhotos.length > 0 ? [...this.props.vehiclePhotos] : [],
    currentPhotoIndex: 0,
    isShowingLightbox: false,
  }

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientID !== this.props.clientID) {
      const vehiclePhotos = this.props.vehiclePhotos
      this.setState({
        vehiclePhotos: vehiclePhotos && vehiclePhotos.length > 0 ? [...vehiclePhotos] : [],
      })
    }
  }

  addPhotos = (files) => {
    var filteredPhotos = files.filter((f) => f.path.includes('.png') || f.path.includes('.jpg') || f.path.includes('.jpeg') || f.path.includes('.gif'))
    if (filteredPhotos && filteredPhotos.length > 0) {
      this.setState({
        isUploadingPhotos: true,
        newPhotosReadCount: filteredPhotos.length,
        newPhotosReadProgress: 0
      })
      for (let f of filteredPhotos) {
        CommonFunctions.getBase64(f, this.photoRead)
      }
    }
  }

  toggleLightbox = (isVisible) => {
    this.setState({ isShowingLightbox: isVisible })
    this.props.onLightboxToggle(isVisible)
  }

  deletePhoto = () => {
    const {vehiclePhotos, currentPhotoIndex} = this.state
    var doomedPhoto = vehiclePhotos[currentPhotoIndex]
    const newPhotos = this.state.vehiclePhotos.filter((p) => p['id'] !== doomedPhoto['id'])
    var newPhotoIndex = currentPhotoIndex

    ValuationActionCreators.deletePhoto(doomedPhoto['id'], this.props.clientID)

    const isLast = vehiclePhotos.length === 1
    if (isLast) {
      setTimeout(() => {
        this.toggleLightbox(false)
      }, 200)
      setTimeout(() => {
        this.setState({
          vehiclePhotos: []
        })
      }, 300)
    } else if (currentPhotoIndex === vehiclePhotos.length - 1) {
      newPhotoIndex = currentPhotoIndex - 1
    }

    if (!isLast) {
      if (doomedPhoto.primary === true) {
        setTimeout(() => {
          this.makePrimaryPhoto(newPhotos[newPhotoIndex])
        }, 3000)
      }

      this.setState({
        vehiclePhotos: newPhotos,
        currentPhotoIndex: newPhotoIndex
      })
    }

    if (this.props.onPhotosChange) {
      this.props.onPhotosChange(newPhotos.length)
    }
  }

  makePrimaryPhoto = () => {
    const {vehiclePhotos, currentPhotoIndex} = this.state
    var targetPhoto = vehiclePhotos[currentPhotoIndex]
    if (targetPhoto) {
      ValuationActionCreators.updatePhoto(targetPhoto.id, {primary: true}, this.props.clientID)
      var newPhotos = [...this.state.vehiclePhotos]
      for (let p of newPhotos) {
        p.primary = p.id === targetPhoto.id
      }
      this.setState({ vehiclePhotos: newPhotos })
    }
  }

  photoRead = (results) => {
    const { newPhotosReadCount, newPhotosReadProgress, vehiclePhotos } = this.state
    var newData = [...this.state.newPhotosData]
    newData.push(results)

    if (newPhotosReadProgress === newPhotosReadCount - 1) {
      let structuredPhotos = newData.map((p) => {return {data: {data: p}, primary: false}})
      let hasExistingPrimary = false
      for (let existingPhoto of vehiclePhotos) {
        if (existingPhoto.primary === true) {
          hasExistingPrimary = true
          break
        }
      }

      if (hasExistingPrimary === false) {
        structuredPhotos[0].primary = true
      }


      CommonFunctions.uploadVehiclePhotos(
        structuredPhotos,
        this.props.vehicleUUID,

        (response) => {
          this.photosAdded(response)
          this.setState({
            isUploadingPhotos: false,
          })
        },
        (error) => {
          // FIXME: handle error state
          console.log("ERROR SAVING DRAFT", error)
          this.setState({
            isUploadingPhotos: false
          })
        },
        this.props.clientID
      )


      this.setState({
        newPhotosReadProgress: 0,
        newPhotosReadCount: 0,
        newPhotosData: []
      })
    } else {
      this.setState({
        newPhotosReadProgress: newPhotosReadProgress + 1,
        newPhotosData: newData
      })
    }
  }

  photosAdded = (photos) => {
    var newPhotos = [...this.state.vehiclePhotos]
    if (photos && photos.length > 0) {
      newPhotos = newPhotos.concat(photos)
    }
    this.setState({
      vehiclePhotos: newPhotos
    })
    if (this.props.onPhotosChange) {
      this.props.onPhotosChange(newPhotos.length)
    }
  }


  render() {
    const { isUploadingPhotos, vehiclePhotos, currentPhotoIndex, isShowingLightbox } = this.state
    const lightboxSources = vehiclePhotos.map((p) => `${photoURLBase}${p.detail_url}`)
    var toolbarButtons = null
    if (!this.isShared && vehiclePhotos && vehiclePhotos.length > 0) {
      toolbarButtons = [(<a href="#delete" style={{fontSize: 13}} onClick={(e)=>{e.preventDefault();this.deletePhoto()}}>Delete</a>)]
      const currentPhoto = vehiclePhotos[currentPhotoIndex]
      if (currentPhoto['primary'] === false) {
        toolbarButtons.push((<a href="#primary" style={{fontSize: 13, paddingLeft: '16px', paddingRight: '16px'}} onClick={(e)=>{e.preventDefault();this.makePrimaryPhoto()}}>Make Primary Photo</a>))
      } else {
        toolbarButtons.push((<span style={{fontSize: 13, paddingLeft: '16px', paddingRight: '16px'}}>Primary Photo</span>))
      }
    }

    return (
      <div className="valuation-photos-c">
        {
          vehiclePhotos && vehiclePhotos.length > 0 &&
          <div className="valuation-photos">
            {
              vehiclePhotos.map((photo, index) => {
                if (photo.preview_url) {
                  return (
                    <a href="#show-photo" onClick={(e) => {
                      e.preventDefault()
                      this.setState({
                        currentPhotoIndex: index
                      })
                      setTimeout(() => {
                        this.toggleLightbox(true)
                      }, 50)
                    }} key={`v-photo-${photo.id}`}>
                      <img src={`${photoURLBase}${photo.preview_url}`} alt="Vehicle" />
                    </a>
                  )
                }
                return null
              })
            }
          </div>
        }

        {isShowingLightbox && (
          <Lightbox
            mainSrc={lightboxSources[currentPhotoIndex]}
            nextSrc={lightboxSources[(currentPhotoIndex + 1) % lightboxSources.length]}
            prevSrc={lightboxSources[(currentPhotoIndex + lightboxSources.length - 1) % lightboxSources.length]}
            onCloseRequest={() => this.toggleLightbox(false)}
            toolbarButtons={toolbarButtons}
            wrapperClassName={lightboxSources.length < 2 ? 'lightbox-single-photo' : ''}
            onMovePrevRequest={() =>
              this.setState({
                currentPhotoIndex: (currentPhotoIndex + lightboxSources.length - 1) % lightboxSources.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentPhotoIndex: (currentPhotoIndex + 1) % lightboxSources.length,
              })
            }
          />
        )}

        {
          !isUploadingPhotos && !this.isShared &&
          <Dropzone onDrop={acceptedFiles => this.addPhotos(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} style={{backgroundColor: '#eee', border: '1px dotted #ccc', borderRadius: '5px', padding: '10px', marginTop: '10px', textAlign: 'center', cursor: 'pointer'}}>
                <input {...getInputProps()} />
                <div style={{color: '#888'}}>Drag and drop photos here, or click to select files</div>
              </div>
            )}
          </Dropzone>
        }

        {
          isUploadingPhotos &&
          <div>Saving photos...</div>
        }

      </div>
    )
  }



}
