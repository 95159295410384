import React, { memo, useEffect, useState, useCallback } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ValuationStore from '../api/stores/ValuationStore'
import ValuationActionCreators from '../api/actions/ValuationActionCreators'
import LoginPage from './LoginPage'
import IFrameNoAccessPage from './IFrameNoAccessPage'
import AccountCannotBeReinstated from './AccountCannotBeReinstated'
import SessionExclusion from '../components/common/SessionExclusion'
import NewValuation from '../components/valuations/NewValuation'
import Valuation from '../components/valuations/Valuation'
import ExternalLinkIcon from '../components/common/icons/ExternalLinkIcon'
import Spinner from '../components/common/Spinner'
import UserVerificationModal from '../components/common/UserVerificationModal'
import CommonFunctions from '../api/constants/CommonFunctions'

const clientID = 'browser-iframe'

const IFrameLayout = ({ history, isLoadingUser, isAuthenticated, iFrameAccessEnabled, accountIsActive, accountStateMessage, forcePaymentUpdate }) => {
  const [vin, setVin] = useState(null)
  const [mileage, setMileage] = useState(null)
  const [condition, setCondition] = useState(null)
  const [zip, setZip] = useState(null)
  const [additionalNewVehicleParams, setAdditionalNewVehicleParams] = useState(null)
  const [newValuationIsShown, setNewValuationIsShown] = useState(false)
  const [valuation, setValuation] = useState(null)
  const [isUpdatingValuation, setIsUpdatingValuation] = useState(false)

  const vehicleUUID = valuation?.uuid

  const onNewValuationCancel = useCallback(() => {
    setNewValuationIsShown(false)
  }, [])

  const onValuationChange = useCallback(() => {
    setIsUpdatingValuation(false)
    setValuation(ValuationStore.valuationWithClientID(clientID))
  }, [])

  useEffect(() => {
    ValuationStore.on(`valuation_change_${clientID}`, onValuationChange)
    return () => {
      ValuationStore.removeListener(`valuation_change_${clientID}`, onValuationChange)
    }
  }, [onValuationChange])

  useEffect(() => {
    // parse VIN, mileage, condition, zip and partner id from url
    const queryString = require('query-string')
    const qs = queryString.parse(window.location.search)

    if (qs.vin && qs.mileage !== null) {
      setVin(qs.vin)

      var formattedMileage = qs.mileage
      const intMileage = parseInt(formattedMileage)
      if (intMileage > 0 && intMileage < 1000) {
        formattedMileage = `0${formattedMileage}`
      }
      setMileage(formattedMileage)

      if (qs.condition) { setCondition(qs.condition) }
      if (qs.zip) { setZip(qs.zip) }
      if (qs.pid) { setAdditionalNewVehicleParams({'partner_code': qs.pid}) }
      setNewValuationIsShown(true)
    } else {
      console.log("Not all Carbly query string parameters are set");
    }
  }, [])

  const onValuationCreated = useCallback(valuation => {
    setNewValuationIsShown(false)
    setValuation(valuation)
  }, [])

  const updateValuationWithReload = useCallback(params => {
    setIsUpdatingValuation(true)
    ValuationActionCreators.updateValuation(vehicleUUID, params, clientID)
  }, [vehicleUUID])

  if (isLoadingUser) {
    return null
  }

  if (!isAuthenticated) {
    return <LoginPage />
  }

  if (!iFrameAccessEnabled) {
    return <IFrameNoAccessPage />
  }

  if (accountIsActive === false && canBeReinstated === false) {
    return <AccountCannotBeReinstated message={accountStateMessage}/>
  }

  // Reactivation is handled at the page level to allow auctions to be accessible

  const renderBody = () => {
    if (newValuationIsShown) {
      return (
        <NewValuation
          onDone={onValuationCreated}
          onCancel={onNewValuationCancel}
          defaultVIN={vin}
          defaultMileage={mileage}
          defaultOverallCondition={condition}
          defaultZipCode={zip}
          additionalNewVehicleParams={additionalNewVehicleParams}
          history={history}
          submitIfMinimallyValid
        />
      )
    } else if (isUpdatingValuation) {
      return (
        <Spinner />
      )
    } else if (valuation) {
      return (
        <Valuation
          valuationData={valuation}
          persistChoices={false}
          onUpdateMileageAndCondition={updateValuationWithReload}
          clientID={clientID}
          hideNotes
          hidePhotos
        />
      )
    } else {
      return (<div style={{textAlign: 'center'}}>No vehicle selected</div>)
    }
  }

  const renderHeader = () => {
    return (
      <div className="iframe-header">
        <a href="https://web.getcarbly.com" target="_blank" rel="noopener noreferrer"><img src="/images/carbly-nav-logo-dark.png" alt="Carbly" /></a>
        {
          valuation && !global.isPartnerPreview &&
          <a href={CommonFunctions.valuationURL(valuation)} target="_blank" rel="noopener noreferrer"><ExternalLinkIcon /> Open in Carbly</a>
        }
      </div>
    )
  }

  return (
    <BrowserRouter history={history}>
      <div className='page-c extension-page-c iframe-page-c'>
        { renderHeader() }
        { renderBody() }
        <SessionExclusion />
        <UserVerificationModal />
      </div>
    </BrowserRouter>
  )
}

export default memo(IFrameLayout)
