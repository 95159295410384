import React from 'react'
import { Link } from 'react-router-dom'
import Formatters from '../../api/constants/Formatters'
import ConnectivityStore from '../../api/stores/ConnectivityStore'

const MyLotRow = ({ listing }) => {
  return (
    <div className="card-list-row auction-listing-row">
      <Link to={'#'} className="auction-listing-body">
        <div className="thumbnail">
          {
            listing.primary_photo_url &&
            <img src={`${ConnectivityStore.apolloApiRoot}${listing.primary_photo_url}`} alt="Listing" />
          }
        </div>
        <div className="listing-specs">
          <h3>{listing.year} {listing.make} {listing.model}</h3>
          <div className="listing-details">
            <div>
              {
                listing.trim &&
                <div className="secondary-text">{listing.trim}</div>
              }
            </div>

            {
              listing.price !== null &&
              <div className="" >{Formatters.formatCurrency(listing.price)}</div>
            }

            {
              listing.vin !== null &&
              <div className="" >{listing.vin}</div>
            }

            <div className="">{listing.mileage > 0 ? Formatters.formatThousands(listing.mileage) + ' miles' : ''}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default MyLotRow
