import React from 'react'
import { Link } from 'react-router-dom'
import BrandImage from '../common/BrandImage'
import CommonFunctions from '../../api/constants/CommonFunctions'
import ValuationRowNotesPopover from './ValuationRowNotesPopover'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'

const photoURLBase = platformFunctions.photoURLBase()

export default class ValuationRowMinimal extends React.Component {
  state = {

  }


  render() {
    const valuation = this.props.valuation

    const url = CommonFunctions.valuationURL(valuation)
    const titleString = valuation.year !== null || valuation.make !== null  || valuation.model !== null ? `${valuation.year} ${valuation.make} ${valuation.model}` : 'Unknown Vehicle'

    const photo = valuation.primary_vehicle_photo
    const showPhoto = photo && photo.preview_url

    return (
      <Link to={url} className="dashboard-vehicle" key={`recent-v-${valuation.uuid}`}>
        {
          showPhoto &&
          <div className="thumbnail">
            <img src={`${photoURLBase}${photo.preview_url}`} alt="Vehicle" />
          </div>
        }

        {
          !showPhoto && false &&
          <BrandImage make={valuation.make} />
        }

        <h3>{titleString}</h3>

        <div className="mileage">{valuation.mileage ? `${Formatters.formatThousands(valuation.mileage)} miles` : 'No mileage'} </div>

        <ValuationRowNotesPopover valuation={valuation} />
      </Link>
    )
  }
}
