import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

export default {
  loadDotAuctionEvents: function(page, count) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_DOT_AUCTION_EVENTS, () => {WebAPIUtils.loadDotAuctionEvents(page, count)} )
  },

  loadAuctionsByState: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTIONS_BY_STATE, () => {WebAPIUtils.loadAuctionsByState()} )
  },

  loadAuctionListing: function(listingID, storeWithSourceID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_LISTING, () => {WebAPIUtils.loadAuctionListing(listingID, storeWithSourceID)} )
  },

  prefetchAuctionListing: function(listingID) {
    TFDispatcher.dispatchAction(ActionTypes.PREFETCH_AUCTION_LISTING, () => {WebAPIUtils.prefetchAuctionListing(listingID)} )
  },

  loadNextAuctionSales: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_NEXT_AUCTION_SALES, () => {WebAPIUtils.loadNextAuctionSales()} )
  },

  loadAuctionListingsForLane: function(saleID, laneID, clientID, afterRunNumber) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_LISTINGS_FOR_LANE, () => {WebAPIUtils.loadAuctionListingsForLane(saleID, laneID, clientID, afterRunNumber)} )
  },

  loadAuctionListingsForSale: function(saleID, pageSize, clientID, offset = 0) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_LISTINGS_FOR_SALE, () => {WebAPIUtils.loadAuctionListingsForSale(saleID, pageSize, clientID, offset)} )
  },

  updateAuctionListing: function(listingID, params) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_AUCTION_LISTING, () => {WebAPIUtils.updateAuctionListing(listingID, params)} )
  },

  loadAuctionsWatchlistVehicleCounts: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_WATCHLIST_VEHICLE_COUNTS, () => {WebAPIUtils.loadAuctionsWatchlistVehicleCounts()} )
  },

  loadAuctionsWatchlistVehicles: function(scope) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_WATCHLIST, () => {WebAPIUtils.loadAuctionsWatchlistVehicles(scope)} )
  },

  loadAuctionsWatchlistArchiveVehicles: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_WATCHLIST_ARCHIVE, () => {WebAPIUtils.loadAuctionsWatchlistArchiveVehicles()} )
  },

  watchlistVIN: function(vin, listingID, addWatchlist, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.WATCHLIST_VIN, () => {WebAPIUtils.watchlistVIN(vin, listingID, addWatchlist, clientID)} )
  },

  checkAuctionsForVIN: function(vin, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.CHECK_AUCTIONS_FOR_VIN, () => {WebAPIUtils.checkAuctionsForVIN(vin, clientID)} )
  },

  loadSavedSearches: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_SAVED_SEARCHES, () => {WebAPIUtils.loadSavedSearches()} )
  },

  loadSavedSearch: function(searchUUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_SAVED_SEARCH, () => {WebAPIUtils.loadSavedSearch(searchUUID, clientID)} )
  },

  updateSavedSearch: function(searchUUID, params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_SAVED_SEARCH, () => {WebAPIUtils.updateSavedSearch(searchUUID, params, clientID)} )
  },

  updateGlobalSavedSearch: function(searchUUID, params) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_GLOBAL_SAVED_SEARCH, () => {WebAPIUtils.updateGlobalSavedSearch(searchUUID, params)} )
  },

  createSavedSearch: function(params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_SAVED_SEARCH, () => {WebAPIUtils.createSavedSearch(params, clientID)} )
  },

  deleteSavedSearch: function(searchUUID) {
    TFDispatcher.dispatchAction(ActionTypes.DELETE_SAVED_SEARCH, () => {WebAPIUtils.deleteSavedSearch(searchUUID)} )
  },

  searchAuctionListings: function(page, params = {}, clientID, queryID = '') {
    TFDispatcher.dispatchAction(ActionTypes.SEARCH_AUCTION_VEHICLES, () => {WebAPIUtils.searchAuctionVehicles(page, params, clientID, queryID)} )
  },

  getCoordinatesForZipCode: function(zipCode) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_COORDINATES_FOR_ZIPCODE, () => {WebAPIUtils.getCoordinatesForZipCode(zipCode)} )
  },

  loadAuctionLiveSalesStatus: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUCTION_LIVE_SALES_STATUS, () => {WebAPIUtils.loadAuctionLiveSalesStatus()} )
  },
}
