import React, { memo, useState, useCallback, useEffect } from 'react'
import SessionStore from '../../api/stores/SessionStore'
import FunnelIcon from '../common/icons/FunnelIcon'
import AuctionListingsGlobalFilterModal from './AuctionListingsGlobalFilterModal'


const AuctionsGlobalFiltersControl = () => {
  const [isShowingGlobalFiltersModal, setIsShowingGlobalFiltersModal] = useState(false)
  

  useEffect(() => {
    // FIXME: subscribe to changes to global filter enable / disable
  }, [])
  
  if (!SessionStore.hasGlobalAuctionFilterAccess()) {
    return null
  }

  return (
    <>
      <a href="#global-filters" style={{alignItems: 'center'}} onClick={(e) => {
        e.preventDefault()
        setIsShowingGlobalFiltersModal(true)
      }}>
        <div>
        <FunnelIcon className="icon" color="currentColor" />Global Filters</div>
        <div className={`global-filters-indicator ${SessionStore.globalAuctionFilterEnabled() ? 'global-filters-indicator-on' : ''}`}>{SessionStore.globalAuctionFilterEnabled() ? 'ON' : 'OFF'}</div>
      </a>

      {
        isShowingGlobalFiltersModal &&
        <AuctionListingsGlobalFilterModal onCancel={() => setIsShowingGlobalFiltersModal(false)} />
      }
    </>
  )
}
export default AuctionsGlobalFiltersControl
