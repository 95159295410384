import { useQuery } from '@apollo/client'
import { GET_USER } from '../queries'

export default () => {
  const { loading, error, data } = useQuery(GET_USER, {
    context: {
      endpointName: 'pipeline',
    },
  })

  return {
    loading,
    data: data?.me,
    bidders: data?.me.bidEngineUserInfo.bidders,
    token: data?.me.bidEngineUserInfo.token,
    error,
  }
}
