import React from 'react'
import { Input, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'

export default class NewFolderModal extends React.Component {
  state = {
    folders: ValuationStore.folders,
    newFolderName: '',
    newFolderNameHasConflict: false,
    isSubmitting: false
  }

  componentDidMount() {
    ValuationStore.on("folder_created", this.folderUpdated)
    ValuationStore.on("folder_updated", this.folderUpdated)
    ValuationStore.on("folder_deleted", this.folderUpdated)
  }

  componentWillUnmount() {
    ValuationStore.removeListener("folder_created", this.folderUpdated)
    ValuationStore.removeListener("folder_updated", this.folderUpdated)
    ValuationStore.removeListener("folder_deleted", this.folderUpdated)
  }

  folderUpdated = () => {
    this.props.onCancel()
  }

  onNewFolderNameChange = (e) => {
    const { folders } = this.state
    var newName = e.target.value

    let hasConflict = false
    if (folders) {
      for (let folder of folders) {
        if (folder.name.toLowerCase() === newName.trim().toLowerCase()) {
          hasConflict = true
          break
        }
      }
    }

    this.setState({
      newFolderName: newName,
      newFolderNameHasConflict: hasConflict
    })
  }

  createFolder = () => {
    const { newFolderName } = this.state
    this.setState({ isSubmitting: true })
    ValuationActionCreators.createFolder(newFolderName)
  }

  updateFolder = () => {
    const { newFolderName } = this.state
    const { currentFolderID } = this.props
    this.setState({ isSubmitting: true })
    ValuationActionCreators.updateFolder(currentFolderID, newFolderName)
  }



  render() {
    const { currentFolderID } = this.props
    const { newFolderName, newFolderNameHasConflict, isSubmitting } = this.state
    const submitDisabled = newFolderName.length === 0 || newFolderNameHasConflict
    return (


      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
        <ModalHeader className='modal-header'>{currentFolderID ? 'Edit' : 'New'} Folder</ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => {
            e.preventDefault()
            if (!submitDisabled) {
              if (currentFolderID) {
                this.updateFolder()
              } else {
                this.createFolder()
              }
            }}}>
            {
              !isSubmitting &&
              <Input type="text" onChange={this.onNewFolderNameChange} value={newFolderName} placeholder="Enter folder name" autoFocus={true} maxLength="255" style={{marginBottom: 0}} autoCapitalize="words"/>
            }
            {
              newFolderNameHasConflict === true && !isSubmitting &&
              <div style={{color: 'red', margin: '5px 0'}}>A folder with this name already exists</div>
            }

            {
              !isSubmitting &&
              <div className="modal-controls" style={{marginTop: '20px'}}>
                <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                <Button color="primary" disabled={submitDisabled} onClick={currentFolderID ? this.updateFolder : this.createFolder}>{ currentFolderID ? 'Update' : 'Create'}</Button>
              </div>
            }
          </form>

          {
            isSubmitting &&
            <Spinner />
          }

        </ModalBody>
      </Modal>
    )
  }
}
