import React, { memo, useMemo, useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ValuationStore from '../api/stores/ValuationStore'
import ValuationActionCreators from '../api/actions/ValuationActionCreators'
import LoginPage from './LoginPage'
import ExtensionNoAccessPage from './ExtensionNoAccessPage'
import AccountCannotBeReinstated from './AccountCannotBeReinstated'
import SessionExclusion from '../components/common/SessionExclusion'
import NewValuation from '../components/valuations/NewValuation'
import Valuation from '../components/valuations/Valuation'
import VersionChecker from '../components/common/VersionChecker'
import ExternalLinkIcon from '../components/common/icons/ExternalLinkIcon'
import Spinner from '../components/common/Spinner'
import platformFunctions from '../platformFunctions'
import UserVerificationModal from '../components/common/UserVerificationModal'

const clientID = 'browser-extension'

const ExtensionLayout = ({ history, isLoadingUser, isAuthenticated, extensionAccessEnabled, accountIsActive, isAccountOwner, accountStateMessage, canBeReinstated, forcePaymentUpdate, accountStatusRestricted }) => {
  const [vin, setVin] = useState(null)
  const [mileage, setMileage] = useState(null)
  const [newValuationIsShown, setNewValuationIsShown] = useState(false)
  const [vinSelectedNonce, setVinSelectedNonce] = useState(0)
  const [valuation, setValuation] = useState(null)
  const [sourceUrl, setSourceUrl] = useState(null)
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false)

  const onNewValuationCancel = useCallback(() => {
    setNewValuationIsShown(false)
  }, [])

  const existingValuationLoaded = useCallback(() => {
    const valuation = ValuationStore.valuationWithClientID(clientID)
    const errors = ValuationStore.errors
    if (errors) {
      setValuation(null)
    } else if (valuation) {
      setNewValuationIsShown(false)
      setValuation(valuation)
    }
    setIsLoadingVehicle(false)
  }, [])

  useEffect(()=> {
    ValuationStore.on(`valuation_change_${clientID}`, existingValuationLoaded)
    return () => {
      ValuationStore.removeListener(`valuation_change_${clientID}`, existingValuationLoaded)
    }
  }, [existingValuationLoaded])

  useEffect(() => {
    const handleMessage = e => {
      try {
        const data = JSON.parse(e.data)
        const { senderIsCarblyExtension } = data
        if (!senderIsCarblyExtension) return
        const { vin, mileage, vinSelectedNonce: nonce } = data
        ReactDOM.unstable_batchedUpdates(() => {
          setValuation(null)
          setVin(vin)
          setMileage(mileage)
          setVinSelectedNonce(nonce)
          setNewValuationIsShown(true)
          setSourceUrl(e.origin)
        })
      } catch (err) {
        // data not json
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const onValuationCreated = useCallback(valuation => {
    setNewValuationIsShown(false)
    setValuation(valuation)
  }, [])

  const onExistingVehicleFound = useCallback(preview => {
    ValuationActionCreators.loadExistingVehicle(preview.uuid, clientID)
  }, [])  

  const valuationUpdated = useCallback(valuation => {
    setNewValuationIsShown(false)
    setValuation(valuation)
    setIsLoadingVehicle(false)
  }, [])

  const updateValuationWithReload = useCallback((params, valuation) => {
    setIsLoadingVehicle(true)
    ValuationActionCreators.updateValuation(valuation.uuid, params, clientID)
  }, [])

  const reloadValuation = useCallback((uuid) => {
    setIsLoadingVehicle(true)
    ValuationActionCreators.loadExistingVehicle(uuid, clientID)
  }, [])

  const additionalNewVehicleParams = useMemo(() => {
    return { source_url: sourceUrl }
  }, [sourceUrl])

  if (isLoadingUser) {
    return null
  }

  if (isLoadingVehicle) {
    return <Spinner />
  }

  if (!isAuthenticated) {
    return <LoginPage newWindowLinks />
  }

  if (accountIsActive === false && canBeReinstated === false) {
    return <AccountCannotBeReinstated message={accountStateMessage}/>
  }

  // Reactivation is handled at the page level to allow auctions to be accessible

  if (!extensionAccessEnabled) {
    platformFunctions.logAnalyticsEvent("User", "Extension Used No Access")
    return <ExtensionNoAccessPage isAccountOwner={isAccountOwner} />
  }

  const renderBody = () => {
    if (accountStatusRestricted) {
      return (
        <div style={{margin: '10px'}}>
          <div>Your account is currently not active.</div>
          <div>Please <a href='/' target="_blank" rel="noopener noreferrer">open Carbly Desktop</a> to manage your account.</div>
        </div>
      )
    }
    if (newValuationIsShown) {
      return (
        <NewValuation
          key={vinSelectedNonce}
          onDone={onValuationCreated}
          onCancel={onNewValuationCancel}
          onExistingVehicleFound={onExistingVehicleFound}
          defaultVIN={vin}
          defaultMileage={mileage}
          additionalNewVehicleParams={additionalNewVehicleParams}
          history={history}
          submitIfMinimallyValid
        />
      )
    } else if (valuation) {
      return (
        <Valuation
          valuationData={valuation}
          persistChoices={false}
          onUpdateMileageAndCondition={(params) => {
            updateValuationWithReload(params, valuation)
          }}
          onReloadValuation={reloadValuation}
          valuationUpdated={valuationUpdated}
          clientID={clientID}
          hideNotes
          hidePhotos
        />
      )
    } else {
      // QUESTION: Want something here?
      return null
    }
  }

  const renderHeader = () => {
    return (
      <div className="extension-header">
        <img src="/images/carbly-nav-logo-dark.png" alt="Carbly" />
        <a href="/" target="_blank" rel="noopener noreferrer"><ExternalLinkIcon /> Open Carbly</a>
      </div>
    )
  }

  // FIXME: Don't need a router here but AccountBug in Valuation uses useHistory and it will break
  // Maybe we want to hide the AccountBug
  // JB: AccountBug is removed, but not 100% on it yet, so leaving router for now
  // FIXME: JB: VersionChecker might not be needed here

  return (
    <BrowserRouter history={history}>
      <div className='page-c extension-page-c'>
        { renderHeader() }
        { renderBody() }
        <VersionChecker />
        <SessionExclusion />
        <UserVerificationModal />
      </div>
    </BrowserRouter>
  )
}

export default memo(ExtensionLayout)
