import React from 'react'
import Lottie from "lottie-react"
import checkmark from "./checkmark.json"

export default class DoneAnimation extends React.Component {
  render() {
    return (
      <Lottie animationData={checkmark} loop={false} style={{...this.props.style}} />
    )
  }
}
