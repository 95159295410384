import React from 'react'

export default class AuctionLocationIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 32
    const height = this.props.width || 32
    const color = this.props.color || '#fff'

    return (
      // <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 195 195" style={{...this.props.style}}>
      //   <path fillRule="evenodd" d="M180.500,190.000 L27.500,190.000 C19.492,190.000 13.000,183.508 13.000,175.500 C13.000,167.492 19.492,161.000 27.500,161.000 L180.500,161.000 C188.508,161.000 195.000,167.492 195.000,175.500 C195.000,183.508 188.508,190.000 180.500,190.000 ZM180.915,99.777 L123.570,131.976 C118.521,134.829 112.115,133.050 109.262,128.002 L108.760,127.115 C105.907,122.067 107.686,115.661 112.734,112.807 L170.080,80.609 C175.128,77.755 181.534,79.534 184.388,84.583 L184.889,85.470 C187.743,90.518 185.964,96.924 180.915,99.777 ZM88.000,55.000 L135.000,29.000 L163.000,79.000 L116.000,105.000 L88.000,55.000 ZM136.915,20.777 L79.570,52.976 C74.521,55.829 68.115,54.050 65.262,49.002 L64.760,48.115 C61.907,43.067 63.686,36.661 68.734,33.807 L126.080,1.609 C131.128,-1.245 137.534,0.534 140.388,5.583 L140.889,6.470 C143.743,11.518 141.964,17.924 136.915,20.777 ZM98.081,90.974 L13.032,138.269 C9.066,140.511 4.033,139.113 1.792,135.147 L1.398,134.451 C-0.844,130.485 0.554,125.452 4.520,123.211 L89.570,75.916 L98.081,90.974 Z"/>
      // </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 360 512" style={{...this.props.style}}>
        <path fillRule="evenodd" d="M170.000,-0.000 C263.888,-0.000 340.000,76.112 340.000,170.000 C340.000,306.930 170.000,507.000 170.000,507.000 C170.000,507.000 -0.000,307.137 -0.000,170.000 C-0.000,76.112 76.112,-0.000 170.000,-0.000 ZM170.000,90.000 C210.317,90.000 243.000,122.683 243.000,163.000 C243.000,203.317 210.317,236.000 170.000,236.000 C129.683,236.000 97.000,203.317 97.000,163.000 C97.000,122.683 129.683,90.000 170.000,90.000 Z"/>
      </svg>
    )
  }
}
