import React from 'react'
import RangeGraph from './RangeGraph'
import AppConstants from '../../api/constants/AppConstants'
import ValuationFunctions from '../../api/constants/ValuationFunctions'

export default class ValuationBooksMatchup extends React.Component {

  renderProviders = () => {
    const data = ValuationFunctions.formatMatchupData(this.props.comparison, this.props.overallCondition)
    return (
      <RangeGraph data={data} initialMarkerPrice={data.initialMarkerPrice} />
    )
  }

  render() {
    return ( <div style={{}}><h3>{this.props.subTitle}</h3>{ this.renderProviders() }</div> )
  }


}