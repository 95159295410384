import React from 'react'
import { Svg, Path, Rect, G } from '@react-pdf/renderer'

export default class ProviderBlackBookLogoPDF extends React.PureComponent {
  render() {
    const width = this.props.width || 100
    const height = this.props.height || 60
    const color = this.props.color || "#111"

    return (
      <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 248 130" style={{...this.props.style}}>
        <G id="b2d0bfd3-2ae0-44f1-8829-c8ae5a81a587" data-name="Layer 2">
          <G id="b72a91b4-7255-4438-9c24-8bc5dc50539d" data-name="Layer 1">
            <Rect x="37.27" y="24.78" width="169.53" height="81.49" fill='#fff' />
            <Path fillRule='evenodd' fill={color} d="M147,78c5.47,0,9,4.56,9,10s-3.53,10-9,10-9-4.56-9-10S141.53,78,147,78ZM70,81c0,2.13-1.46,3-4,3H62V78h4C68.23,78,70,78.87,70,81Zm-2.75,9C70,90,72,91.16,72,94s-2,4-4.75,4H62V90ZM112,46l4-10,4,10ZM52,37c0,2.13-1.46,3-4,3H44V34h4C50.23,34,52,34.87,52,37Zm53,41c5.47,0,9,4.56,9,10s-3.53,10-9,10-9-4.56-9-10S99.53,78,105,78Zm91.58-21.4H203L190.61,42.34l11-13.79h-6l-10,12.58V28.55h-4.81V56.6h4.81V43.94ZM191,101.5h6.37L185,87.23l11-13.78h-6L180,86V73.45h-4.81V101.5H180V88.84ZM155.67,57.08a14.17,14.17,0,0,0,10.79-4.76l-3.21-3.17a10,10,0,0,1-7.58,3.45c-5.53,0-9.33-4.61-9.33-10s3.8-10,9.33-10a9.67,9.67,0,0,1,7.14,3.08l3.25-3.16a14.07,14.07,0,0,0-10.39-4.41c-8.09,0-14.22,6.21-14.22,14.51S147.58,57.08,155.67,57.08ZM146.89,102c8.17,0,14.19-6.21,14.19-14.51S155.06,73,146.89,73,132.7,79.18,132.7,87.47,138.71,102,146.89,102ZM124.71,56.6h4.85l-10.91-28h-4.89L102.86,56.6h4.85L110.28,50h11.86ZM104.44,102c8.18,0,14.19-6.21,14.19-14.51S112.62,73,104.44,73,90.26,79.18,90.26,87.47,96.27,102,104.44,102ZM72.82,56.6H89.57L91.06,52H77.63V28.55H72.82Zm-6,44.9c5.29,0,9.38-3.36,9.38-8.29a6.54,6.54,0,0,0-4.37-6.58,5.76,5.76,0,0,0,2.93-5.17c0-5.89-4.37-8-8.94-8h-9.1V101.5ZM39.63,56.6h10.1c5.29,0,9.38-3.36,9.38-8.29a6.57,6.57,0,0,0-4.37-6.58,5.75,5.75,0,0,0,2.92-5.17c0-5.89-4.37-8-8.94-8H39.63ZM216,0l32,130H32L0,0ZM49.25,44C52,44,54,45.16,54,48s-2,4-4.75,4H44V44Z" />
          </G>
        </G>
      </Svg>
    )
  }
}
