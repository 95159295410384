import React from 'react'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'
import { Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import OfferingUpsellModal from '../../components/common/OfferingUpsellModal'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Formatters from '../../api/constants/Formatters'

const chromeStoreURL = 'https://chrome.google.com/webstore/detail/carbly-vin-scanner-extens/hpodldbdjhamlalomidlimbfbaimofmh/'

export default class Upsell extends React.Component {
  state = {
    isShowingUpsellModal: false,
    costForSelected: null,
    accountDetails: null,
    pricingForOfferingsErrors: null
  }

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadPricingForOfferings([AppConstants.skuMappings.marketTracker])
    
    platformFunctions.logAnalyticsEvent("User", `Opened ${AppConstants.providerNames.regional_report} Upsell Page`)
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_upsell'])
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
  }

  accountDetailsReceived = () => {
    this.setState({
      accountDetails: SessionStore.accountDetails
    })
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })
  }

  render() {
    const { isShowingUpsellModal, accountDetails, costForSelected } = this.state

    // const isDivergent = accountDetails?.divergent_offerings === true
    const isDivergent = false
    const priceString = costForSelected ? ` for ${Formatters.formatCurrencyCents(costForSelected?.incremental_price)} per month` : ''

    let nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    return (
      <div style={{height: '100%'}}>
        <p>Add Carbly's {AppConstants.providerNames.regional_report} to your plan to get valuable local used car retail market insights.</p>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <ul>
              <li><strong>Improve your inventory</strong> selection by finding vehicles that are under stocked locally.</li>
              <li><strong>Avoid low-demand vehicles</strong> by seeing which vehicles are current over supplied.</li>
              <li>See which vehicles are selling the fastest in your area to <strong>improve your turn</strong>.</li>
              <li><strong>Compare your dealership to other similar dealers</strong> in the area.  Compare average days on lot, total vehicles sold, number of vehicles in inventory, and total lot value.</li>
              <li><strong>Browse specific make / model combinations</strong> to see how well they are performing in your region.</li>
              <li><strong>Stay ahead of trends</strong> by following region-wide supply, pricing, and sales data.</li>
              <li>Show all dealers, or limit to independents or franchise dealers to find your competitors.</li>
              <li>Regional data is updated every 2 days.</li>
            </ul>

            {
              SessionStore.isAccountOwner() && accountDetails && !isDivergent &&
              <div>
              <Button color="primary" onClick={() => {this.setState({ isShowingUpsellModal: true })}} style={{marginTop: '5px', minWidth: '320px'}}>Add {AppConstants.providerNames.regional_report}{priceString}</Button>
              {
                nonAuxUsersCount > 1 &&
                <div>Includes access for {nonAuxUsersCount} users on your account</div>
              }
              </div>
            }

            {
              isDivergent &&
              <div><strong>Contact us at {AppConstants.phoneNumber}</strong> to add {AppConstants.providerNames.regional_report} to your plan.</div>
            }

            {
              !SessionStore.isAccountOwner() &&
              <div style={{fontWeight: 'bold', backgroundColor: '#eee', padding: '5px 10px', borderRadius: '4px'}}>Contact your account owner to add {AppConstants.providerNames.regional_report}</div>
            }
            

            <h2 style={{marginTop: '40px'}}>Regional Market Trends</h2>
            <p>Understand which way the used car market is trending in your area, and stay ahead of the game. Carbly tracks inventory levels, average days on lot, average list price, and average list price by model year for all known dealers and vehicles.</p>


            <h2 style={{marginTop: '40px'}}>Inventory Levels</h2>
            <p>It's critical to understand if the market in your area is either saturated or under supplied with a particular make and model.  Carbly {AppConstants.providerNames.regional_report} shows you the most over and under supplied models, and shows you what the usual inventory is for each.</p>



            <h2 style={{marginTop: '40px'}}>Inventory by Popular Makes</h2>
            <p>Look at brand-level inventory over time to gain further insight into whether or not supply is high or low.</p>

            <h2 style={{marginTop: '40px'}}>Sales by Model</h2>
            <p>What are the highest selling and lowest days on lot vehicles in your area?  Carbly will let you know.  Make sure you have the hottest vehicles in your inventory and stay ahead of the trends.</p>

            <p>{AppConstants.providerNames.regional_report} will give you a Demand Score for each make and model.  The Demand Score represents a grade based on how many vehicles are selling and how quickly it takes dealers to sell them.</p>


            <h2 style={{marginTop: '40px'}}>Dealer Performance</h2>
            <p>How does your dealership stack up against others in your area?  Get a ranked list of dealers by number of vehicles sold, lowest days on lot, inventory value, and cars on lot.</p>

            <p>Carbly will let you find dealers who are outperforming your dealership, which will allow you to gain an understand about how you can improve your business.</p>


            <h2 style={{marginTop: '40px'}}>Data by Model</h2>
            <p>Select a make and model (and even year) to see the performance metrics for a vehicle.  This invaluable insight will let you understand if a vehicle is right for your region and lot.</p>



            <div style={{margin: "40px 0"}}>Need help getting started?  We're happy to help, just give us a call at {AppConstants.phoneNumber}.</div>

          </div>


          

          <div style={{width: '500px', flexGrow: 0, flexShrink: 0, marginLeft: '40px'}}>
            <a href="/images/regional-reports-plus-screenshot.png" target="_blank" rel="noopener noreferrer">
              <div style={{textAlign: 'center'}}>Click to view a sample report</div>
              <img src="/images/regional-reports-plus-screenshot.png"
              alt={`Carbly ${AppConstants.providerNames.regional_report}`}
              style={{
                width: '100%',
                boxShadow: '0 5px 16px rgba(0,0,0,0.2)',
              }} /></a>
          </div>

          
        </div>

        {
          isShowingUpsellModal &&
          <OfferingUpsellModal
            logoWidth={300}
            logoHeight={60}
            offering={{sku: AppConstants.skuMappings['marketTracker'], provider_key: 'market_tracker'}}
            onDone={() => this.setState({ isShowingUpsellModal: false})}
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              window.location.reload()
            }} />
        }
      </div>
    )
  }
}
