// FIXME: util placed alongside Page for dev. Move to better directories, shareable to src/api
import usePagination from './util/usePagination'
import componentProps from './util/componentProps'
import useReportData from './util/useReportData'
import useModelStatsProvider from './util/useModelStatsProvider'

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, TileLayer, Marker, LayerGroup, Circle } from 'react-leaflet'
import Select from 'react-select'
import { ResponsiveBar } from '@nivo/bar'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Tooltip } from 'reactstrap'
import platformFunctions from '../../platformFunctions'
import AccountBug from '../../components/user/AccountBug'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import Spinner from '../../components/common/Spinner'
import ZipCodeEntryField from '../../components/common/ZipCodeEntryField'
import moment from 'moment'
import HelpIcon from '../../components/common/icons/HelpIcon'
import AppConstants from '../../api/constants/AppConstants'
import Upsell from './Upsell'
import SessionStore from '../../api/stores/SessionStore'
import ProviderMarketTrackerLogo from '../../components/common/icons/providers/ProviderMarketTrackerLogo'
import OfferingUpsellModal from '../../components/common/OfferingUpsellModal'
import AccountNeedsAction from '../../components/user/AccountNeedsAction'
import UserActionCreators from '../../api/actions/UserActionCreators'
import RetailModelStats from '../../components/retail/ModelStats'
import LineChart from '../../components/retail/LineChart'
import ListingsModal from '../../components/retail/ListingsModal'
import ModelStatsModal from './ModelStatsModal'

const reportSections = [
  { id: 'regional-trends', name: 'Regional Trends' },
  { id: 'inventory-levels', name: 'Inventory Levels' },
  { id: 'sales-by-model', name: 'Sales by Model' },
  { id: 'dealer-performance', name: 'Dealer Performance' },
  { id: 'browse-models', name: 'Data by Model' },
]

const reportOptions = {
  dealerType: [
    { value: 'all', label: 'All dealers' },
    { value: 'indy', label: 'Only independent dealers' },
    { value: 'franchise', label: 'Only franchise dealers' },
  ],
  radius: [10, 25, 50, 100].map(value => {
    return { value, label: `${value} miles` }
  }),
}

const withReportData = (Component) => {
  const MemoizedComponent = memo(Component)
  return ({ data, ...rest }) => {
    const { dataKey } = rest
    return <MemoizedComponent {...rest} data={data[dataKey]} />
  }
}

const rooftopLocationMarker = new L.Icon({
  iconUrl: '/images/map-rooftop-marker.svg',
  iconSize: [12,12],
  iconAnchor: [6, 6],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
})

const reactSelectStyles = {
  option: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: '10px'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

const BarChart = ({ data, valueFormat, valueAxisFormat, tooltip, tickValues }) => {
  return (
    <ResponsiveBar
      data={data}
      colors={['#2c6e8e']}
      margin={{ top: 10, right: 26, bottom: 26, left: 35 }}
      axisLeft={{
        format: valueAxisFormat,
        tickSize: 0,
        legend: null,
        tickValues,
      }}
      gridYValues={tickValues}
      valueFormat={valueFormat}
      enableLabel={false}
      tooltip={tooltip}
      axisBottom={{
        tickSize: 0,
      }}
    />
  )
}

const Pagination = ({ onMoreClick: propsOnMoreClick, onLessClick: propsOnLessClick, numPages, page }) => {
  const onMoreClick = useCallback(e => {
    e.preventDefault()
    propsOnMoreClick()
  }, [propsOnMoreClick])

  const onLessClick = useCallback(e => {
    e.preventDefault()
    propsOnLessClick()
  }, [propsOnLessClick])

  const downArrow = <svg width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 9.5L1.5 5L2.91421 3.58579L6 6.67157L9.08579 3.58579L10.5 5L6 9.5Z" fill="currentColor"/></svg>
  const upArrow = <svg width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 2.5L10.5 7L9.08579 8.41421L6 5.32843L2.91421 8.41421L1.5 7L6 2.5Z" fill="currentColor"/></svg>

  return (
    <div className='pagination'>
      {
        (numPages > 0 && numPages !== page) &&
        <a href="#more" onClick={onMoreClick}>
          {downArrow} More {downArrow}</a>
      }
      {
        page > 1 &&
        <a href="#less" onClick={onLessClick}>{upArrow} Less {upArrow}</a>
      }
    </div>
  )
}

const ReportList = withReportData(({ title, subtitle, data, dataKey, pageSize, helpText, renderActions }) => {
  const { addPage, removePage, numPages, page, data: dataSlice } = usePagination(data, pageSize)
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  return (
    <div>
      <div className='split-row'>
        <h2>{title}</h2>
        {
          Boolean(helpText) &&
          <div>
            <div id={`help-${dataKey}`}>
              <HelpIcon width={20} height={20} color='#aaa' />
            </div>
            <Tooltip
              placement="right"
              isOpen={isHelpOpen}
              target={`help-${dataKey}`}
              toggle={() => setIsHelpOpen(!isHelpOpen)}>
              {helpText}
            </Tooltip>
          </div>
        }
      </div>

      <div className='secondary-text'>{subtitle}</div>
      {
        dataSlice.length === 0 ?
        <div className='secondary-text'>No data available</div> :
        <div className='report-list'>
          {
            dataSlice.map((item, index) => {
              const { primary, secondary, value } = componentProps(item, dataKey)
              return (
                <div className="report-list-row" key={index}>
                  <div className='split-row' style={{alignItems: 'flex-start' }}>
                    <div className="list-order-number">{index+1}</div>
                    <div style={{flexGrow: 1, flexShrink: 1, overflow: 'hidden'}}>
                      <div style={{display: 'flex'}}>
                        <div className="list-title">
                          <div className="list-emphasis" title={primary}>{primary}</div>
                          <div className="secondary-text">{secondary}</div>
                        </div>
                        <div className='list-value'>{value}</div>
                      </div>
                      {
                        renderActions &&
                        <div className='retail-model-action-row'>
                          {renderActions(item)}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
          <Pagination onLessClick={removePage} onMoreClick={addPage} numPages={numPages} page={page} />
        </div>
      }
    </div>
  )
})

const TrendChart = ({ title, data, dataKey, subtitle, accessoryView }) => {
  const { chartProps } = componentProps(data[dataKey], dataKey)
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '10px'}}>
        <h2>{title}</h2>
        { accessoryView }
      </div>

      <div className='secondary-text'>{subtitle}</div>
      <div style={{width: '100%', height: '130px'}}>
        <LineChart {...chartProps} />
      </div>
    </div>
  )
}

const TrendBarChart = ({ title, data, dataKey, subtitle }) => {
  const { chartProps } = componentProps(data[dataKey], dataKey)
  return (
    <div>
      <h2>{title}</h2>
      <div className='secondary-text'>{subtitle}</div>
      <div style={{width: '100%', height: '140px'}}>
        <BarChart {...chartProps} />
      </div>
    </div>
  )
}

const InventoryLevelsChild = withReportData(({ title, subtitle, data, dataKey, onShowClick, onShowModelStatsClick }) => {
  const { addPage, removePage, numPages, page, data: dataSlice } = usePagination(data, 10)

  return (
    <div>
      <h2>{title}</h2>
      <div className='secondary-text'>{subtitle}</div>
      {
        dataSlice.map((item, index) => {
          const { summaryProps, chartProps } = componentProps(item, dataKey)
          return (
            <div className="split-row report-list-row" style={{alignItems: 'flex-start'}} key={index}>
              <div className="list-order-number">{index+1}</div>
              <div style={{flexGrow: 1, flexShrink: 1}}>
                <div className="list-title">
                  <div className="list-emphasis">
                    {`${summaryProps.make} ${summaryProps.model}`}
                  </div>
                </div>
                <div className="secondary-text">Currently {summaryProps.currentInventory}, usually {summaryProps.averageInventory}</div>
                {
                  summaryProps.mostDealerName &&
                  <div className="secondary-text">{`Most: ${summaryProps.mostDealerName}`}</div>
                }
                <div className='retail-model-action-row'>
                  <a href={platformFunctions.similarVehiclesSearchLink(null, summaryProps.make, summaryProps.model)} target='_blank' rel='noopener noreferrer' className='row-action' onClick={() => {
                    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_model_show_at_auction'])
                  }}>At auction</a>
                  <a href="#show-listings" onClick={onShowClick.bind(null, item)} className='row-action'>At retail</a>
                  <a href="#show-model-stats" onClick={onShowModelStatsClick.bind(null, item)} className='row-action'>Details</a>
                </div>
              </div>
              <div style={{width: '320px', flexShrink: 0, height: '80px'}}>
                <LineChart {...chartProps} />
              </div>
            </div>
          )
        })
      }

      <Pagination onLessClick={removePage} onMoreClick={addPage} numPages={numPages} page={page} />
    </div>
  )
})

const SelectMakeFilter = ({ name, onChange, value, options }) => {
  const formatOption = useCallback(make => {
    return {
      value: make,
      label: make,
    }
  }, [])

  const selectedOption = useMemo(() => {
    return value ? formatOption(value) : null
  }, [value, formatOption])

  const selectOptions = useMemo(() => {
    return options.map(formatOption)
  }, [options])

  return (
    <Select
      styles={reactSelectStyles}
      className='react-select'
      placeholder='Filter by make'
      name={name}
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
      isClearable
    />
  )
}

const SalesByModel = ({ data, selectedMake, onSelectMake, onShowClick, onShowModelStatsClick }) => {
  const renderActions = useCallback(item => {
    return (
      <>
        <a href={platformFunctions.similarVehiclesSearchLink(null, item.make, item.model)} target='_blank' rel='noopener noreferrer' className='row-action'>At auction</a>
        <a href="#show-listings" onClick={onShowClick.bind(null, item)} className='row-action'>At retail</a>
        <a href="#show-model-stats" onClick={onShowModelStatsClick.bind(null, item)} className='row-action'>Details</a>
      </>
    )
  }, [onShowClick])

  return (
    <div>
      <div style={{width: '300px', marginTop: 10 }}>
        <SelectMakeFilter
          options={data['sales_by_model_make_filter_options']}
          value={selectedMake}
          onChange={onSelectMake}
        />
      </div>

      <div className="split-row" style={{marginTop: '10px', gap: '10px', alignItems: 'flex-start'}}>
        <div className="report-cell" style={{ width: `${100/3}%` }}>
          <ReportList
            title="Highest Selling"
            subtitle="Most sold in the past 60 days"
            dataKey='highest_selling_vehicles'
            data={data}
            pageSize={20}
            renderActions={renderActions}
          />
        </div>

        <div className="report-cell" style={{ width: `${100/3}%` }}>
          <ReportList
            title="Lowest Days on Lot"
            subtitle="Fastest turn in the past 60 days"
            dataKey='lowest_days_on_lot_vehicles'
            data={data}
            pageSize={20}
            renderActions={renderActions}
          />
        </div>

        <div className="report-cell" style={{ width: `${100/3}%` }}>
          <ReportList
            title="Demand Score"
            subtitle="Combined number of vehicles sold and average days on lot"
            dataKey='highest_demand_vehicles'
            data={data}
            pageSize={20}
            renderActions={renderActions}
            helpText="Demand score is a combination of the number of vehicles sold and the average days on lot. The higher the score, the more in demand the vehicle is.  100 is the highest possible score."
          />
        </div>
      </div>
    </div>
  )
}

const InventoryLevels = ({ data, onListingsModalShowClick, selectedMake, onSelectMake, onModelStatsModalShowClick }) => {
  return (
    <div>
      <div style={{width: '300px', marginTop: 10 }}>
        <SelectMakeFilter
          options={data['inventory_levels_make_filter_options']}
          value={selectedMake}
          onChange={onSelectMake}
        />
      </div>
      <div className="split-row" style={{marginTop: '10px', gap: '10px', alignItems: 'flex-start'}}>
        <div className="report-cell" style={{width: '50%'}}>
          <InventoryLevelsChild
            title="Current Low Supply"
            subtitle="Current used inventory levels that are lower than usual"
            dataKey='inventory_levels_low'
            data={data}
            onShowClick={onListingsModalShowClick}
            onShowModelStatsClick={onModelStatsModalShowClick}
          />
        </div>

        <div className="report-cell" style={{width: '50%'}}>
          <InventoryLevelsChild
            title="Current High Supply"
            subtitle="Current used inventory levels that are higher than usual"
            dataKey='inventory_levels_high'
            data={data}
            onShowClick={onListingsModalShowClick}
            onShowModelStatsClick={onModelStatsModalShowClick}
          />
        </div>
      </div>
    </div>
  )
}

const BrowseModels = withReportData(({ data, regionParams, onListingsShowClick }) => {
  const {
    onSelectedMakeModelChange,
    onSelectedYearChange,
    onSelectedTrimChange,
    isLoadingDrilldownStats,
    drilldownStats,
    selectedMakeModelOption,
    selectedYearOption,
    selectedTrimOption,
  } = useModelStatsProvider({ regionParams })

  return (
    <RetailModelStats
      makeModelStats={data}
      drilldownStats={drilldownStats}
      selectedMakeModelOption={selectedMakeModelOption}
      selectedYearOption={selectedYearOption}
      selectedTrimOption={selectedTrimOption}
      onListingsShowClick={onListingsShowClick}
      onSelectedMakeModelChange={onSelectedMakeModelChange}
      onSelectedYearChange={onSelectedYearChange}
      onSelectedTrimChange={onSelectedTrimChange}
      isLoadingDrilldownStats={isLoadingDrilldownStats}

    />
  )
})

const ReportOptionsModal = ({ radius, zip, onClose, onSave }) => {
  const [selectedRadius, setSelectedRadius] = useState(() => reportOptions.radius.find(item => item.value === radius))
  const [selectedZip, setSelectedZip] = useState(zip)
  const [selectedZipIsValid, setSelectedZipIsValid] = useState(false)

  const onSaveClick = useCallback(() => {
    SessionStore.setRegionalReportZipCode(selectedZip)
    SessionStore.setRegionalReportRadius(selectedRadius.value)

    if (zip !== selectedZip) {
      UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_zip_change'], selectedZip)
    }

    if (radius !== selectedRadius.value) {
      UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_radius_change'], selectedRadius.value)
    }

    onSave({
      radius: selectedRadius.value,
      zip: selectedZip,
    })
  }, [onSave, selectedRadius, selectedZip])

  const onZipChanged = useCallback((value, isValid) => {
    if (isValid) {
      setSelectedZip(value)
      setSelectedZipIsValid(true)
    } else {
      setSelectedZipIsValid(false)
    }
  }, [])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (selectedZipIsValid) {
          onSaveClick()
        } }}>
        <ModalHeader className='modal-header split-modal-header'>
          {AppConstants.providerNames.regional_report} Options
        </ModalHeader>
        <ModalBody>
            <div className="split-row split-row-left">
              <div style={{marginRight: '10px'}}>Includes dealers within</div>
              <div style={{ width: 200 }}>
                <Select
                  styles={reactSelectStyles}
                  name='radius'
                  className='react-select'
                  isSearchable={false}
                  onChange={setSelectedRadius}
                  value={selectedRadius}
                  options={reportOptions.radius}
                />
              </div>
            </div>

            <div className="split-row split-row-left" style={{marginTop: '20px'}}>
              <div style={{marginRight: '10px'}}>Zip code</div>
              <div style={{ width: 250 }}>
                <ZipCodeEntryField
                  style={{ marginTop: 0, padding: '2px 8px', height: 38, borderRadius: 4 }}
                  defaultZipCode={zip}
                  onZipCodeChange={onZipChanged}
                />
              </div>
            </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' onClick={onSaveClick} disabled={!selectedZipIsValid}>Save Options</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

const Report = ({ rawReportData, onSaveOptions: propsOnSaveOptions }) => {
  const [
    {
      isShowingReportOptionsModal,
      isShowingListingsModal,
      isShowingUpsellModal,
      isShowingModelStatsModal,
      activeSectionId,
      selectedModelStat,
      dealerType,
      inventoryLevelsMake,
      salesByModelMake,
    },
    setState,
  ] = useState({
    isShowingReportOptionsModal: false,
    isShowingListingsModal: false,
    isShowingUpsellModal: false,
    isShowingModelStatsModal: false,
    selectedModelStat: null,
    activeSectionId: reportSections[0].id,
    dealerType: SessionStore.getRegionalReportDealerType(),
    // FIXME: get from session store?
    inventoryLevelsMake: null,
    salesByModelMake: null,
  })

  const {
    lat_long: latLong,
    zip,
    radius,
    end_date: endDate,
  } = rawReportData.meta

  const reportData = useReportData(rawReportData.report, { dealerType, inventoryLevelsMake, salesByModelMake })

  const selectedRadiusText = useMemo(() => {
    return reportOptions.radius.find(item => item.value === radius).label
  }, [radius])

  const selectedDealerTypeText = useMemo(() => {
   return reportOptions.dealerType.find(item => item.value === dealerType).label.toLocaleLowerCase()
  }, [dealerType])

  const onSelectDealerTypeClick = useCallback((value, e) => {
    e.preventDefault()
    SessionStore.setRegionalReportDealerType(value)
    setState(prev => ({ ...prev, dealerType: value }))
  }, [])

  const onSelectInventoryLevelsMake = useCallback(item => {
    // FIXME: persist value in session store? createMetric?
    setState(prev => ({ ...prev, inventoryLevelsMake: item?.value }))
  }, [])

  const onSelectSalesByModelMake = useCallback(item => {
    // FIXME: persist value in session store? createMetric?
    setState(prev => ({ ...prev, salesByModelMake: item?.value }))
  }, [])

  const onListingsModalShowClick = useCallback((item, e) => {
    e.preventDefault()
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_listings_show'])
    setState(prev => ({ ...prev, isShowingListingsModal: true, selectedModelStat: item }))
  }, [])

  const onUpsellModalShowClick = useCallback((e) => {
    e.preventDefault()
    setState(prev => ({ ...prev, isShowingUpsellModal: true }))
  }, [])

  const onUpsellModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingUpsellModal: false }))
  }, [])

  const onListingsModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingListingsModal: false, selectedModelStat: null }))
  }, [])

  const onReportOptionsModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingReportOptionsModal: false }))
  }, [])

  const onReportOptionClick = useCallback(e => {
    e.preventDefault()
    setState(prev => ({ ...prev, isShowingReportOptionsModal: true }))
  }, [])

  const onSaveOptions = useCallback(options => {
    setState(prev => ({ ...prev, isShowingReportOptionsModal: false }))
    propsOnSaveOptions(options)
  }, [propsOnSaveOptions])

  const onModelStatsModalShowClick = useCallback((item, e) => {
    e.preventDefault()
    UserActionCreators.createMetric(AppConstants.metricNames['market_tracker_model_show_details'])
    setState(prev => ({ ...prev, isShowingModelStatsModal: true, selectedModelStat: item }))
  }, [])

  const onModelStatsModalCloseClick = useCallback(() => {
    setState(prev => ({ ...prev, isShowingModelStatsModal: false, selectedModelStat: null }))
  }, [])

  const subnavRef = useRef()
  const regionalTrendsRef = useRef()
  const inventoryLevelsRef = useRef()
  const salesByModelRef = useRef()
  const dealerPerformanceRef = useRef()
  const browseModelsRef = useRef()

  const prevScrollTop = useRef(0)
  const scrollDirection = useRef('up')

  const mapCircleRef = useRef()

  const onMapCreated = useCallback(map => {
    const displayBounds = mapCircleRef.current.getBounds()
    const paddingOptions = {}
    map.fitBounds(displayBounds, paddingOptions)
  }, [])

  const onSubnavClick = useCallback(e => {
    e.preventDefault()
    const id = e.target.getAttribute('data-section-id')
    const sectionEls = [
      regionalTrendsRef.current,
      inventoryLevelsRef.current,
      salesByModelRef.current,
      dealerPerformanceRef.current,
      browseModelsRef.current,
    ]

    const section = sectionEls.find(el => el && el.id === id)
    if (section) {
      const yOffset = e.target.parentElement.offsetHeight
      const top = section.getBoundingClientRect().top + window.scrollY - yOffset
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }, [])

  const regionParams = useMemo(() => {
    return {
      zip,
      radius,
      endDate,
    }
  }, [zip, radius, endDate])

  const regionalListingsParams = useMemo(() => {
    if (!selectedModelStat) return null
    const { year, make, model, trim } = selectedModelStat
    const { zip, radius } = regionParams
    return {
      zip,
      radius,
      year,
      make,
      model,
      trim,
    }
  }, [selectedModelStat, regionParams])

  useEffect(() => {
    const options = {
      root: null,
      threshold: 1,
    }

    const handleObserver = ([entry]) => {
      entry.target.classList.toggle('stuck', entry.intersectionRatio < 1)
    }

    const el = subnavRef.current
    const observer = new IntersectionObserver(handleObserver, options)
    observer.observe(el)

    return () => {
      observer.unobserve(el);
    }
  }, [])

  useEffect(() => {
    // NOTE: rootMargin may need to be adjusted if section heights change
    const options = {
      root: null,
      threshold: 0,
      rootMargin: '-250px',
    }

    const sectionEls = [
      regionalTrendsRef.current,
      inventoryLevelsRef.current,
      salesByModelRef.current,
      dealerPerformanceRef.current,
      browseModelsRef.current,
    ]

    const scrollRoot = document.documentElement
    const handleObserver = (entries) => {
      const shouldUpdate = entry => {
        if (scrollDirection.current === 'down' && !entry.isIntersecting) return true
        if (scrollDirection.current === 'up' && entry.isIntersecting) return true
        return false
      }

      const setScrollDirection = () => {
        if (scrollRoot.scrollTop > prevScrollTop.current) {
          scrollDirection.current = 'down'
        } else {
          scrollDirection.current = 'up'
        }
        prevScrollTop.current = scrollRoot.scrollTop
      }

      const getTargetSection = target => {
        if (scrollDirection.current === 'up') {
          return target
        } else if (target.nextElementSibling) {
          return target.nextElementSibling
        } else {
          return target
        }
      }

      entries.forEach(entry => {
        setScrollDirection()
        if (shouldUpdate(entry)) {
          const target = getTargetSection(entry.target)
          setState(prev => ({ ...prev, activeSectionId: target.id }))
        }
      })
    }

    const observer = new IntersectionObserver(handleObserver, options);
    sectionEls.forEach(el => observer.observe(el))

    return () => {
      sectionEls.forEach(el => observer.unobserve(el))
    }
  }, [])

  const regionSummaryProps = useMemo(() => {
    return componentProps(reportData, 'region_summary')
  }, [reportData])


  const hasPurchased = SessionStore.hasPurchasedSKU(AppConstants.skuMappings.marketTracker)
  const isTrialing = SessionStore.featureAccess().includes('market_tracker') && !hasPurchased

  return (
    <>
      <div>
        <div className="secondary-text">A look at used car retail activity and trends in your area. Last updated {moment(endDate).format('M/DD/YYYY')}. </div>

        {
          isTrialing &&
          <div style={{border: '1px solid #ddd', borderRadius: '6px', padding: '5px 10px'}}>Access to {AppConstants.providerNames['regional_report']} is part of your free trial.
            {
              SessionStore.isAccountOwner() ?
              <a href="#add" onClick={onUpsellModalShowClick}> Add {AppConstants.providerNames['regional_report']} to your account</a> :
              <div>Ask your account owner to add {AppConstants.providerNames['regional_report']} to your account</div>
            }

          </div>
        }

        <div className='split-row' style={{alignItems: 'stretch', marginTop: '10px'}}>
          <MapContainer
            scrollWheelZoom={false}
            zoom={8}
            zoomSnap={0.25}
            center={latLong}
            style={{width: '450px', height: '200px', borderRadius: '10px', zIndex: 100}}
            attributionControl={false}
            whenCreated={onMapCreated}
          >
            <TileLayer
              attribution=''
              url={`https://tileserver.getcarbly.com/osm/{z}/{x}/{y}.png`}
            />
            <Marker
              position={latLong}
              icon={ rooftopLocationMarker }
              key='roof-mrkr'
            />
            <LayerGroup>
              <Circle
                center={latLong}
                radius={radius * 1609.34}
                pathOptions={{ color: '#2c6e8e', fillColor: '#2c6e8e', opacity: 0.4, fillOpacity: 0.15 }}
                ref={mapCircleRef}
              />
            </LayerGroup>
          </MapContainer>

          <div style={{border: '1px solid #aaa', flexGrow: 1, flexShrink: 1, borderRadius: '10px', marginLeft: '10px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>

            <h2 style={{marginTop: 0}}>Your Region</h2>

            <div>Includes all dealers within <a href="#options" onClick={onReportOptionClick}
            >{selectedRadiusText}</a> of zipcode <a href="#options" onClick={onReportOptionClick}
            >{zip}</a>. <a href="#options" onClick={onReportOptionClick}
            >Edit region</a></div>

            <div className='split-row top-level-stats'>
              <div>
                <h3>{regionSummaryProps.dealers}</h3>
                <div>Dealers</div>
              </div>

              <div>
                <h3>{regionSummaryProps.avgCarsOnLot}</h3>
                <div>Avg. cars on lot</div>
              </div>

              <div>
                <h3>{regionSummaryProps.avgListPrice}</h3>
                <div>Avg. list price</div>
              </div>

              <div>
                <h3>{regionSummaryProps.avgDaysOnLot}</h3>
                <div>Avg. days on lot</div>
              </div>
            </div>

          </div>
        </div>


        <div ref={subnavRef} className="split-row split-row-left regional-subnav" style={{ gap: '10px', alignItems: 'flex-start' }}>
          {
            reportSections.map((item, index) => (
              <a href={`#${item.id}`} data-section-id={item.id} onClick={onSubnavClick} key={index} className={item.id === activeSectionId ? 'active' : ''}>{item.name}</a>
            ))
          }
        </div>


        <section id='regional-trends' ref={regionalTrendsRef}>
          <h1>Regional Trends</h1>

          <div className="split-row" style={{ marginTop: '10px', gap: '10px', alignItems: 'flex-start'}}>
            <div className="report-cell" style={{ width: `${100/3}%` }}>
              <TrendChart
                title="Inventory Levels"
                subtitle="Number of used cars on lot and sold in your region"
                dataKey='inventory_levels_trend'
                data={reportData}
                accessoryView={
                  <div style={{display: 'flex', alignItems: 'center', fontSize: '0.9rem'}}>
                    <div style={{backgroundColor: '#e95656', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px'}}></div>
                    <div style={{marginRight: '16px'}}>For sale</div>

                    <div style={{backgroundColor: '#2c6e8e', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px'}}></div>
                    <div>Sold</div>
                  </div>
                }
              />
            </div>

            <div className="report-cell" style={{ width: `${100/3}%` }}>
              <TrendChart
                title="Average Days on Lot"
                subtitle="Average turn time for dealers in your region"
                dataKey='avg_days_on_lot_trend'
                data={reportData}
              />
            </div>

            <div className="report-cell" style={{ width: `${100/3}%` }}>
              <TrendChart
                title="Average List Price"
                subtitle="Average asking price for used vehicles in your region"
                dataKey='avg_list_price_trend'
                data={reportData}
              />
            </div>
          </div>

          <div className="split-row" style={{ marginTop: '10px', gap: '10px', alignItems: 'flex-start'}}>
            <div className="report-cell" style={{ width: '100%' }}>
              <TrendBarChart
                title="Average List Price By Model Year"
                subtitle="Average asking price by year for used vehicles in your region"
                dataKey='avg_price_by_year'
                data={reportData}
              />
            </div>
          </div>
        </section>



        <section id='inventory-levels' ref={inventoryLevelsRef}>
          <h1>Inventory Levels</h1>
          <InventoryLevels
            data={reportData}
            onSelectMake={onSelectInventoryLevelsMake}
            selectedMake={inventoryLevelsMake}
            onListingsModalShowClick={onListingsModalShowClick}
            onModelStatsModalShowClick={onModelStatsModalShowClick}
          />
        </section>


        <section id='sales-by-model' ref={salesByModelRef}>
          <h1>Sales by Model</h1>
          <SalesByModel
            data={reportData}
            onSelectMake={onSelectSalesByModelMake}
            selectedMake={salesByModelMake}
            onShowClick={onListingsModalShowClick}
            onShowModelStatsClick={onModelStatsModalShowClick}
          />
        </section>


        <section id='dealer-performance' ref={dealerPerformanceRef}>
          <h1>Dealer Performance</h1>
          <div className='secondary-text'>Regional dealer performance for {selectedDealerTypeText} </div>

          <div style={{marginTop: '10px', gap: '10px', display: 'flex' }}>
            {
              reportOptions.dealerType.map((item, index) => (
                <a href="#filter"
                  key={index}
                  className={`dealers-filter ${dealerType === item.value ? 'active' : ''}`}
                  onClick={onSelectDealerTypeClick.bind(null, item.value)}
                >
                  {item.label}
                </a>
              ))
            }
          </div>
          <div className="split-row" style={{marginTop: '20px', gap: '10px', alignItems: 'flex-start'}}>
            <div className="report-cell" style={{ width: `${100/4}%` }}>
              <ReportList
                title="Most Active Dealers"
                subtitle="Dealers by vehicles sold in the last 60 days"
                dataKey='most_active_dealers'
                data={reportData}
                pageSize={30}
              />
            </div>

            <div className="report-cell" style={{ width: `${100/4}%` }}>
              <ReportList
                title="Lowest Days on Lot"
                subtitle="Fastest average turn over the last 60 days"
                dataKey='lowest_days_on_lot_dealers'
                data={reportData}
                pageSize={30}
              />
            </div>

            <div className="report-cell" style={{ width: `${100/4}%` }}>
              <ReportList
                title="Inventory Value"
                subtitle="Total value of vehicles currently on the lot"
                dataKey='current_highest_inventory_value_dealers'
                data={reportData}
                pageSize={30}
              />
            </div>

            <div className="report-cell" style={{ width: `${100/4}%` }}>
              <ReportList
                title="Cars on Lot"
                subtitle="Number of vehicles currenly on the lot"
                dataKey='current_highest_inventory_dealers'
                data={reportData}
                pageSize={30}
              />
            </div>
          </div>
        </section>


        <section id='browse-models' ref={browseModelsRef} style={{ minHeight: 540 }}>
          <h1>Data by Model</h1>
          <div className="report-cell" style={{marginTop: '20px', marginBottom: '240px'}}>
            <BrowseModels
              data={reportData}
              dataKey='model_stats'
              regionParams={regionParams}
              onListingsShowClick={onListingsModalShowClick}
            />
          </div>
        </section>
      </div>

      {
        isShowingReportOptionsModal &&
        <ReportOptionsModal
          radius={radius}
          onClose={onReportOptionsModalCloseClick}
          onSave={onSaveOptions}
          zip={zip}
        />
      }

      {
        isShowingListingsModal &&
        <ListingsModal
          onClose={onListingsModalCloseClick}
          regionalListingsParams={regionalListingsParams}
        />
      }

      {
        isShowingModelStatsModal &&
        <ModelStatsModal
          onClose={onModelStatsModalCloseClick}
          regionParams={regionParams}
          selectedModelStat={selectedModelStat}
        />
      }

      {
          isShowingUpsellModal &&
          <OfferingUpsellModal
            logoWidth={300}
            logoHeight={60}
            offering={{sku: AppConstants.skuMappings['marketTracker'], provider_key: 'market_tracker'}}
            onDone={onUpsellModalCloseClick}
            onPurchase={() => {
              onUpsellModalCloseClick()
              window.location.reload()
            }} />
        }
    </>
  )
}

const RegionalReportsPage = ({ navMatch, accountStatusRestricted }) => {
  const defaultRadius = SessionStore.getRegionalReportRadius()
  const defaultZip = SessionStore.getRegionalReportZipCode()
  const marketTrackerIsAccountRestricted = accountStatusRestricted

  const [
    {
      rawReportData,
      isLoadingReport,
    },
    setState,
  ] = useState({
    isLoadingReport: false,
    rawReportData: null,
  })

  const onReportDataChange = useCallback(() => {
    setState(prev => ({ ...prev, rawReportData: ValuationStore.regionalReport, isLoadingReport: false }))
  }, [])

  useEffect(() => {
    const ev = 'regional_report_change'
    ValuationStore.on(ev, onReportDataChange)

    return () => {
      ValuationStore.removeListener(ev, onReportDataChange)
    }
  }, [onReportDataChange])

  const loadReport = useCallback(({ zip = defaultZip, radius = defaultRadius } = {}) => {
    setState(prev => ({ ...prev, isLoadingReport: true }))
    ValuationActionCreators.loadRegionalReport(zip, radius)
  }, [])

  useEffect(() => {
    if (SessionStore.hasMarketTrackerAccess()) {
      loadReport()
    }
  }, [loadReport])

  useEffect(() => {
    platformFunctions.logAnalyticsEvent("User", "Opened Regional Reports Lander")
  }, [])

  const renderReport = () => {
    if (isLoadingReport) return <Spinner className="spinner-page" />
    if (!rawReportData) return null
    return (
      <Report
        rawReportData={rawReportData}
        onSaveOptions={loadReport}
      />
    )
  }

  return (
    <div style={{height: '100%', display: navMatch ? 'inherit' : 'none'}}>
      <div className="page-body-c regional-reports-plus-body-c">
        <div className="content-header split-row">
          <div className='split-row split-row-left' >
            <ProviderMarketTrackerLogo width={300} height={60} />
          </div>
          <AccountBug />
        </div>
        {
          marketTrackerIsAccountRestricted &&
          <AccountNeedsAction className='account-action-page-header' />
        }
        {
          !marketTrackerIsAccountRestricted &&
          <>
            {
              SessionStore.hasMarketTrackerAccess() ? renderReport() : <Upsell />
            }
          </>
        }
      </div>
    </div>
  )
}

export default RegionalReportsPage
