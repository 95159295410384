import React from 'react'

export default class BrandImage extends React.PureComponent {
  render() {
    const makeName = this.props.make.toLowerCase().replace(/\s/g, '');

    return (
      <img src={`/images/brands/${makeName}.png`} alt={this.props.make} className="brand-image" />
    )
  }
}
