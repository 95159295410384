import React from 'react'
import PageVisibility from 'react-page-visibility'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import * as Sentry from "@sentry/react"
import SessionStore from '../api/stores/SessionStore'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import NoLocalStorageSupport from '../pages/NoLocalStorageSupport'
import AppConstants from '../api/constants/AppConstants'
import platformFunctions from '../platformFunctions'
import packageJson from '../../package.json'
import Spinner from '../components/common/Spinner'

const history = createBrowserHistory()

global.appVersion = packageJson.version

export default function withUserAuth(WrappedComponent) {
  return class extends React.Component {
    state = {
      isLoadingUser: true,
      isWindowVisible: true,
      accountStateMessage: null,
      accountUpdateStatus: null,
      accountIsActive: true,
      canBeReinstated: false,
      needsCarblySubscription: false,
      accountStatusRestricted: false,
      forcePaymentUpdate: false,
      localStorageNotSupported: false,
      isAwaitingOauthAuthentication: false
    }

    componentDidMount() {
      SessionStore.on('auth_state_changed', this.authStateDidChange)
      SessionStore.on('user_did_load', this.userDidLoad)
      SessionStore.on("user_data_changed", this.userWasRefreshed)
      SessionStore.on("settings_page_viewed", this.settingsPageViewed)

      if (platformFunctions.isProduction()) {
        ReactGA.initialize(platformFunctions.googleAnalyticsTrackingID())

        history.listen(location => {
          ReactGA.set({ page: location.pathname })
          ReactGA.pageview(location.pathname)
        })
      }

      // Treading lightly here, and only doing the check for these 2 modes
      if (global.isExtensionMode || global.isIFrameMode) {
        if (platformFunctions.localStorageIsSupported()) {
          SessionStore.loadData()
        } else {
          platformFunctions.logAnalyticsEvent("User", "Local storage not supported")
          this.setState({ localStorageNotSupported: true })
        }
      } else {
        SessionStore.loadData()
      }

      // Edge oauth
      const query = new URLSearchParams(window.location.search)
      if (query.get('token')) {
        this.setState({
          isAwaitingOauthAuthentication: true
        })
        SessionStore.authenticateWithJWTToken(query.get('token'))
      }
    }

    componentWillUnmount() {
      SessionStore.removeListener('auth_state_changed', this.authStateDidChange)
      SessionStore.removeListener('user_did_load', this.userDidLoad)
      SessionStore.removeListener("user_data_changed", this.userWasRefreshed)
      SessionStore.removeListener("settings_page_viewed", this.settingsPageViewed)
    }

    userDidLoad = () => {
      setTimeout(() => { this.refreshUser() }, AppConstants.initialUserRefreshDelay)
      setTimeout(() => { this.heartbeat() }, AppConstants.initialHeartbeatDelay)
      setTimeout(() => { this.periodicUserRefresh() }, AppConstants.periodicUserRefreshPeriod)

      this.setState({
        isAuthenticated: SessionStore.isAuthenticated(),
        isLoadingUser: false,
        shouldShowSettingsBadge: SessionStore.shouldShowSettingsBadge(),
        isAccountOwner: SessionStore.user?.account?.owner === true
      })
    }

    userWasRefreshed = () => {
      this.checkForAccountStateHandling()
      
      if (SessionStore.isAuthenticated()) {
        this.updateSentryUser(SessionStore.user)
        this.updateIntercomUser(SessionStore.user)
        this.updateClarityUser(SessionStore.user)
        
        if (this.state.isAwaitingOauthAuthentication) {
          this.setState({
            isAwaitingOauthAuthentication: false
          })

          let queryParams = new URLSearchParams(location.search)
          queryParams.delete('edge_oauth_authorize_status')
          queryParams.delete('token')
          history.replace({
            search: queryParams.toString(),
          })
        }
      }
    }

    authStateDidChange = () => {
      const isAuthenticated = SessionStore.isAuthenticated()

      const user = SessionStore.user
      if (platformFunctions.isProduction() && isAuthenticated) {
        ReactGA.set({
          userId: user.uuid,
        })
      }

      if (isAuthenticated) {
        this.checkForAccountStateHandling()
        this.updateSentryUser(user)
        this.updateClarityUser(user)
      } else {
        Sentry.configureScope(scope => scope.setUser(null))
      }

      this.setState({ isAuthenticated: isAuthenticated })
      this.refreshUser()
    }

    updateIntercomUser = (user) => {
      if (!user) { return }
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user.uuid,
        name: user.full_name,
        email: user.email
      });
      
    }

    updateSentryUser = (user) => {
      if (!user) { return }
      Sentry.setUser({ id: user.uuid })
      
      var platformTag = 'full-desktop'
      if (global.isExtensionMode) {
        platformTag = 'extension'
      } else if (global.isIFrameMode) {
        platformTag = 'iframe'
      }
      
      Sentry.setTag("platform", platformTag)
    }

    updateClarityUser = (user) => {
      if (!user) { return }
      window.clarity("identify", user.uuid, '0', '0', user.full_name)
    }

    periodicUserRefresh = () => {
      if (SessionStore.isAuthenticated()) {
        SessionActionCreators.refreshUser()
      }

      setTimeout(() => {
        this.periodicUserRefresh()
      }, AppConstants.periodicUserRefreshPeriod)
    }

    checkForAccountStateHandling = () => {
      const user = SessionStore.user
      const account = user?.account
      const accountState = account?.state
      const accountStateMessage = account?.state_message

      var accountIsActive = true
      var forcePaymentUpdate = false
      var canBeReinstated = false
      var needsCarblySubscription = false

      // LEGACY STATE: if (accountState === 'trialing_invalid_payment_method' || accountState === 'past_due_past_grace_period') {
      if (SessionStore.shouldForcePaymentUpdate()) {
        // Overdue or invalid payment, force update to CC
        forcePaymentUpdate = true
      } else if (SessionStore.shouldShowAccountReactivation()) {
        // Give option to ininstate
        // FIXME: new states mean the old behavior of reactivation should only be show if payment_method !== none
        canBeReinstated = true
      } else if (accountState === 'canceled' && !SessionStore.hasAccountOfferings()) {
        needsCarblySubscription = true
      } else if (accountState === 'invalid') {
        // Cannot reinstate, call us
        accountIsActive = false
      }

      const accountStatusRestricted = canBeReinstated || needsCarblySubscription
      
      var accountUpdateStatus = null
      if (forcePaymentUpdate === false && this.state.forcePaymentUpdate === true) {
        accountUpdateStatus = 200
      }

      this.setState({
        accountUpdateStatus: accountUpdateStatus,
        isAccountOwner: account?.owner,
        accountIsActive: accountIsActive,
        accountStateMessage: accountStateMessage,
        forcePaymentUpdate: forcePaymentUpdate,
        canBeReinstated: canBeReinstated,
        needsCarblySubscription: needsCarblySubscription,
        accountStatusRestricted: accountStatusRestricted
      })
    }

    refreshUser = () => {
      if (SessionStore.isAuthenticated()) {
        SessionActionCreators.refreshUser()
      }
    }

    heartbeat = () => {
      if (SessionStore.isAuthenticated()) {
        if (this.state.isWindowVisible) {
          SessionActionCreators.heartbeat('web', global.appVersion)
        }
        setTimeout(() => this.heartbeat(), AppConstants.heartbeatPeriod)
      } else {
        // Call more agressively to check initial heartbeat after user loads
        setTimeout(() => this.heartbeat(), 2000)
      }
    }

    handleWindowVisibilityChange = (isVisible) => {
      this.setState({ isWindowVisible: isVisible })
    }

    settingsPageViewed = () => {
      if (this.state.shouldShowSettingsBadge === true) {
        this.setState({ shouldShowSettingsBadge: false })
      }
    }

    render() {
      const { isLoadingUser, isAuthenticated, accountStateMessage, accountUpdateStatus, accountIsActive, forcePaymentUpdate, canBeReinstated, needsCarblySubscription, accountStatusRestricted, isAccountOwner, localStorageNotSupported, isAwaitingOauthAuthentication } = this.state
      const iFrameAccessEnabled = SessionStore.iFrameAccessEnabled()
      // const extensionAccessEnabled = SessionStore.extensionAccessEnabled()
      const extensionAccessEnabled = true

      const injectedProps = {
        accountStateMessage,
        accountUpdateStatus,
        accountIsActive,
        canBeReinstated,
        needsCarblySubscription,
        accountStatusRestricted,
        forcePaymentUpdate,
        isAccountOwner,
        extensionAccessEnabled,
        iFrameAccessEnabled,
        isLoadingUser,
        isAuthenticated,
        history,
      }

      if (localStorageNotSupported) {
        return (
          <NoLocalStorageSupport />
        )
      }

      if (isAwaitingOauthAuthentication) {
        return (
          <Spinner />
        )
      }

      return (
        <PageVisibility onChange={this.handleWindowVisibilityChange}>
          <WrappedComponent {...injectedProps} />
        </PageVisibility>
      )
    }
  }
}
