import { useCallback, Fragment, useMemo, useState, memo } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { ResponsiveBar } from '@nivo/bar'
import { ticks } from 'd3-array' // dependency of nivo
import LineChart from './LineChart'
import platformFunctions from '../../platformFunctions'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'

const statsDataKey = 'model_stats'
const distributionDataKey = 'distribution_data'
const dolSegmentsDataKey = 'dol_segments'
const dolTableGap = '20px'
const dolTableCols = 5
const distributionDataAttr = 'current_inventory'

const makeCalcFlexWidth = (cols, gap) => {
  // https://wiryawanadipa.com/blog/calculate-percentage-width-of-flex-items-when-using-gap
  return size => `calc((100% * (${size / 12})) - (((${cols} - 1) / ${cols}) * ${gap}))`
}

const getMakeModelOptionValue = (opt => opt.id)
const getMakeModelOptionLabel = (opt => `${opt.make} ${opt.model}`)

const getYearOptionValue = (opt => opt.year)
const getYearOptionLabel = (opt => opt.year)

const getTrimOptionValue = (opt => opt.trim)
const getTrimOptionLabel = (opt => opt.trim)

const distributionTypes = {
  year: 'year',
  trim: 'trim',
}

const sorts = {
  year: (a, b) => a.year - b.year,
  trim: (a, b) => a.trim.toLowerCase().localeCompare(b.trim.toLowerCase()),
}

const dolColWidth = makeCalcFlexWidth(dolTableCols, dolTableGap)
const onLotLabel = 'For sale'
const soldLabel = 'Sold'

const BarChart = memo(({
  data,
  valueFormat,
  tooltip,
  tickValues,
}) => {
  return (
    <div style={{width: '100%', height: '220px' }}>
      {
        data?.length ?
        <ResponsiveBar
          data={data}
          animate={false}
          tickValues={tickValues}
          colors={['#2c6e8e']}
          margin={{ top: 26, right: 26, bottom: 68, left: 38 }}
          valueFormat={valueFormat}
          enableLabel={false}
          tooltip={tooltip}
          gridYValues={tickValues}
          axisBottom={{
            tickSize: 0,
            tickRotation: 40,
            truncateTickAt: 16,
          }}
          axisLeft={{
            tickSize: 0,
            legend: null,
            tickValues,
            format: valueFormat,
          }}
        /> :
        <div className='secondary-text'>No data available</div>
      }
    </div>
  )
})

const componentProps = ((data, type, opts = {}) => {
  switch (type) {
    case distributionDataKey:
      const chartData = data
        .filter(d => d[distributionDataAttr] > 0)
        .sort(sorts[opts.attr])
        .map(d => {
          return {
            id: d[opts.attr],
            value: d[distributionDataAttr],
          }
        })

      const distributionTickValues = (() => {
        const valuesSet = new Set(chartData.map(d => d.value))
        const min = 0
        const max = Math.max(...Array.from(valuesSet))
        // Prevent decimal ticks
        const numTicks = Math.max(2, Math.min(8, valuesSet.size))
        return ticks(min, max, numTicks)
      })()

      return {
        title: opts.title,
        tickValues: distributionTickValues,
        valueFormat: Formatters.formatThousands,
        data: chartData,
        tooltip: el => {
          return (
            <div className="graph-tooltip">
              <div>{el.indexValue}</div>
              <div>{el.formattedValue} in inventory</div>
            </div>
          )
        },
      }

    case statsDataKey:
      return {
        ...data,
        summaryProps: {
          currentInventory: Formatters.formatThousands(data.current_inventory),
          averageInventory: Formatters.formatThousands(data.average_inventory),
          mostDealerName: data.most_dealer_name,
          numSold: Formatters.formatThousands(data.num_sold),
          avgDaysOnLot: data.avg_days_on_lot?.toFixed(1),
          demandScore: data.demand_score?.toFixed(1),
        },
        dol_segments: data.dol_segments.map(d => ({ ...d, portionOfSold: data.num_sold ? d.num_sold / data.num_sold : 0 })),
        chartProps: {
          yMin: Math.min(...data.historic_inventory.map(d => Math.min(d.num_on_lot, d.num_sold))) * 0.75,
        },
        distributionChartProps: opts.distributionChart ? componentProps(opts.distributionChart.data, distributionDataKey, opts.distributionChart) : undefined,
        inventoryLevelsTrendChartProps: {
          yFormatFunction: Formatters.formatThousands,
          enableSlices: 'x',
          sliceTooltip: el => {
            return (
              <div className="graph-tooltip">
                <div>{el.slice.points[0].data.xFormatted}</div>
                {
                  el.slice.points.map((point) => {
                    return <div key={point.id}>{`${point.data.yFormatted} ${point.serieId}`}</div>
                  })
                }
              </div>
            )
          },
          legends: [
            {
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: -6,
              translateY: 40,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              symbolSize: 12,
              symbolShape: 'circle',
            },
          ],
          margin: { top: 30, right: 26, bottom: 40, left: 26 },
          data: [
            {
              id: soldLabel,
              color: '#2c6e8e',
              data: data.historic_inventory.map(d => {
                return {
                  x: moment(d.date).format('M/DD'),
                  y: d.num_sold,
                }
              }),
            },
            {
              id: onLotLabel,
              color: "#e95656",
              data: data.historic_inventory.map(d => {
                return {
                  x: moment(d.date).format('M/DD'),
                  y: d.num_on_lot,
                }
              }),
            },
          ],
        },
      }

    case dolSegmentsDataKey:
      return {
        ...data,
        dolRangeValue: [
          data.dol_end === null ? `Over ${data.dol_start - 1}` : `${data.dol_start} to`,
          data.dol_end,
          'days',
        ].filter(Boolean).join(' '),
        numSoldValue: Formatters.formatThousands(data.num_sold),
        avgNumPriceChangesValue: `${data.avg_num_price_changes}`,
        avgPriceChangePercentValue: (() => {
          if (data.avg_price_change_ratio === 0) {
            return '0%'
          }

          return (
            <div>
              <span style={{ color: data.avg_price_change_ratio > 0 ? 'green' : 'darkred' }}>
                {data.avg_price_change_ratio > 0 ? '▲' : '▼'}
              </span>
              {Math.abs(data.avg_price_change_ratio * 100).toFixed(1)}%
            </div>
          )
        })(),
      }

    default:
      return data
  }
})

const reactSelectStyles = {
  option: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: '10px'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

const Section = ({
  data,
  selectProps,
  onListingsShowClick,
  showDemandScore,
  emptyHelperText,
}) => {
  return (
    <div>
      <div style={{display: 'flex', gap: 10}}>
        {
          selectProps?.map((props, index) => {
            return (
              <div key={index} style={{ width: props.width ?? 400 }}>
                <Select
                  styles={reactSelectStyles}
                  name='select'
                  className='react-select'
                  value={props.value}
                  onChange={props.onChange}
                  placeholder={props.placeholder}
                  options={props.options}
                  isClearable={props.isClearable}
                  getOptionLabel={props.getOptionLabel}
                  getOptionValue={props.getOptionValue}
                />
              </div>
            )
          })
        }
      </div>
      {
        data ?
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 20, rowGap: 10, flexWrap: 'wrap'}}>
            <div className='retail-model-action-row' style={{marginBottom: 0}}>
              <a href={platformFunctions.similarVehiclesSearchLink(data.year, data.make, data.model, data.trim)} target='_blank' rel='noopener noreferrer' className='row-action'>At auction</a>
              {
                onListingsShowClick &&
                <a href="#show-listings" onClick={onListingsShowClick.bind(null, data)} className='row-action'>At retail</a>
              }
            </div>

            {
              data.summaryProps?.mostDealerName &&
              <div className='secondary-text'>Highest inventory: {data.summaryProps.mostDealerName}</div>
            }
          </div>

          <div className='split-row' style={{flexGrow: 1, flexShrink: 1, justifyContent: 'space-between', gap: '10px'}}>
            <div className='retail-model-stats-summary-item'>
              <h2>{data.summaryProps.currentInventory}</h2>
              <div className='secondary-text'>Currently available</div>
            </div>

            <div className='retail-model-stats-summary-item'>
              <h2>{data.summaryProps.averageInventory}</h2>
              <div className='secondary-text'>Usually available</div>
            </div>

            <div className='retail-model-stats-summary-item'>
              <h2>{data.summaryProps.avgDaysOnLot || 'N/A'}</h2>
              <div className='secondary-text'>Avg days on lot</div>
            </div>

            <div className='retail-model-stats-summary-item'>
              <h2>{data.summaryProps.numSold}</h2>
              <div className='secondary-text'>Sold in the last 60 days</div>
            </div>

            {
              showDemandScore !== false &&
              <div className='retail-model-stats-summary-item'>
                <h2>{data.summaryProps.demandScore || 'N/A'}</h2>
                <div className='secondary-text'>Demand score</div>
              </div>
            }
          </div>

          {
            data.distributionChartProps &&
            <div>
              <div style={{ fontWeight: 'bold' }}>{data.distributionChartProps.title}</div>
              <BarChart {...data.distributionChartProps} />
            </div>
          }

          <div>
            <div style={{ fontWeight: 'bold', borderBottom: '1px solid #aaa' }}>Inventory and Sales Levels Trends</div>
            <div style={{ height: '180px' }}>
              <LineChart {...data.inventoryLevelsTrendChartProps} />
            </div>
          </div>

          <div>
            <div style={{ fontWeight: 'bold', borderBottom: '1px solid #aaa', marginTop: '20px'}}>Sold Vehicles by Days on Lot</div>
            <div className="split-row" style={{ gap: dolTableGap, borderBottom: '1px solid #ddd', padding: '4px 0' }}>
              <div style={{ width: dolColWidth(3), fontWeight: 'bold' }}>Days on lot</div>
              <div style={{ width: dolColWidth(2), textAlign: 'right', fontWeight: 'bold' }}>Sold</div>
              <div style={{ width: dolColWidth(3), textAlign: 'center', fontWeight: 'bold' }}/>
              <div style={{ width: dolColWidth(2), fontWeight: 'bold' }}>Avg price changes</div>
              <div style={{ width: dolColWidth(2), fontWeight: 'bold' }}>Avg price change %</div>
            </div>
            {
              data?.dolSegments.map((segment, index) => {
                const { dolRangeValue, numSoldValue, avgNumPriceChangesValue, portionOfSold, avgPriceChangePercentValue } = segment
                return (
                  <div className="split-row" style={{ gap: dolTableGap, borderBottom: '1px solid #ddd', padding: '4px 0' }} key={index}>
                    <div style={{ width: dolColWidth(3) }}>{dolRangeValue}</div>
                    <div style={{ width: dolColWidth(2), textAlign: 'right' }}>{numSoldValue}</div>
                    <div style={{ width: dolColWidth(3), border: '1px solid #e6e8ed', height: `calc(2px + 1rem)`, background: `linear-gradient(to right, #e95656 ${portionOfSold * 100}%, white 0%)`, borderRadius: '4px' }} />
                    <div style={{ width: dolColWidth(2) }}>{avgNumPriceChangesValue}</div>
                    <div style={{ width: dolColWidth(2) }}>{avgPriceChangePercentValue}</div>
                  </div>
                )
              })
            }
          </div>
        </div> :
        <div style={{ marginTop: 8 }}>
          {emptyHelperText}
        </div>
      }
    </div>
  )
}

const ModelStats = ({
  makeModelStats,
  drilldownStats,
  isLoadingDrilldownStats,
  selectedMakeModelOption,
  selectedYearOption,
  selectedTrimOption,
  onSelectedMakeModelChange,
  onSelectedYearChange,
  onSelectedTrimChange,
  onListingsShowClick,
  onToggleShowMakeModelData,
  isDisabled = false,
  isDrilldownFirst = false,
  isLoadingMakeModelStats = false,
  isShowingMakeModelData = true,
  isEnabledMakeModelData = true,
  yearOptions: yearOptionsProp,
  trimOptions: trimOptionsProp,
  disabledMakeModelDataMessage,
}) => {
  const drilldownStatsByYear = drilldownStats?.by_year
  const drilldownStatsByTrim = drilldownStats?.by_trim
  const drilldownStatsByYearAndTrim = drilldownStats?.by_year_and_trim

  const makeModelOptions = makeModelStats

  const [selectedDistributionChartType, setSelectedDistributionChartType] = useState(distributionTypes.year)

  const nextSelectedDistributionChartType = useMemo(() => {
    return Object.keys(distributionTypes).find(key => key !== selectedDistributionChartType)
  }, [selectedDistributionChartType])

  const onSelectNextDistributionChartTypeClick = useCallback((e) => {
    e.preventDefault()
    setSelectedDistributionChartType(nextSelectedDistributionChartType)
  }, [nextSelectedDistributionChartType])

  const yearOptions = useMemo(() => {
    return (yearOptionsProp ?? drilldownStatsByYear)?.sort(sorts.year)?.reverse()
  }, [drilldownStatsByYear, yearOptionsProp])

  const trimOptions = useMemo(() => {
    return (trimOptionsProp ?? drilldownStatsByTrim)?.sort(sorts.trim)
  }, [drilldownStatsByTrim, trimOptionsProp])

  const getSelectedData = useCallback((item, opts) => {
    if (!item) return null
    const itemProps = componentProps(item, statsDataKey, opts)
    return {
      ...itemProps,
      dolSegments: itemProps.dol_segments.map(segment => componentProps(segment, dolSegmentsDataKey)),
    }
  }, [])

  const makeModelData = useMemo(() => {
    return getSelectedData(selectedMakeModelOption)
  }, [getSelectedData, selectedMakeModelOption])

  const distributionByYearChartProps = useMemo(() => {
    if (!drilldownStatsByYear) return null
    const propsOpts = { title: 'Inventory Levels by Year', attr: distributionTypes.year }
    return componentProps(drilldownStatsByYear, distributionDataKey, propsOpts)
  }, [drilldownStatsByYear])

  const distributionByYearTrimProps = useMemo(() => {
    if (!drilldownStatsByTrim) return null
    const propsOpts = { title: 'Inventory Levels by Trim', attr: distributionTypes.trim }
    return componentProps(drilldownStatsByTrim, distributionDataKey, propsOpts)
  }, [drilldownStatsByTrim])

  const selectedChartProps = {
    [distributionTypes.year]: distributionByYearChartProps,
    [distributionTypes.trim]: distributionByYearTrimProps,
  }[selectedDistributionChartType]

  const drilldownData = useMemo(() => {
    let item = null
    let propsOpts
    if (selectedYearOption && selectedTrimOption) {
      item = drilldownStatsByYearAndTrim?.find(s => s.year === selectedYearOption.year && s.trim === selectedTrimOption.trim)
    } else if (selectedYearOption) {
      item = drilldownStatsByYear?.find(s => s.year === selectedYearOption.year)
      propsOpts = {
        distributionChart: {
          title: `Inventory Levels by Trim for ${selectedYearOption.year}`,
          attr: distributionTypes.trim,
          data: drilldownStatsByYearAndTrim.filter(s => s.year === selectedYearOption.year),
        },
      }
    } else if (selectedTrimOption) {
      item = drilldownStatsByTrim?.find(s => s.trim === selectedTrimOption.trim)
      propsOpts = {
        distributionChart: {
          title: `Inventory Levels by Year for ${selectedTrimOption.trim}`,
          attr: distributionTypes.year,
          data: drilldownStatsByYearAndTrim.filter(s => s.trim === selectedTrimOption.trim),
        },
      }
    }
    return getSelectedData(item, propsOpts)
  }, [getSelectedData, drilldownStatsByYear, drilldownStatsByTrim, drilldownStatsByYearAndTrim, selectedTrimOption, selectedYearOption])

  const drilldownEmptyHelperText = useMemo(() => {
    return selectedYearOption || selectedTrimOption ? 'No data available for this year and trim combination' : 'Choose a year and/or trim'
  }, [selectedYearOption, selectedTrimOption])

  const sections = [
    <div>
      {
        (!isDrilldownFirst || isShowingMakeModelData) ?
        <>
          {
            isDrilldownFirst && isEnabledMakeModelData && (
              <div className='split-row' style={{ borderBottom: '1px solid #ddd', marginBottom: '0.5rem' }}>
                <h4 style={{ marginBottom: 0 }}>All Years and Trims</h4>
                <a
                  href='#show-make-model-data'
                  onClick={onToggleShowMakeModelData}
                >
                  Hide
                </a>
              </div>
            )
          }
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {
              isLoadingMakeModelStats ?
              <Spinner /> :
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                {
                  (isDrilldownFirst && selectedMakeModelOption) &&
                  <div className='secondary-text' style={{ marginBottom: '0.25rem' }}>
                    {`Data for all ${getMakeModelOptionLabel(selectedMakeModelOption)} years and trims`}
                  </div>
                }
                <Section
                  selectProps={isDisabled ? undefined : [
                    {
                      options: makeModelOptions,
                      getOptionValue: getMakeModelOptionValue,
                      getOptionLabel: getMakeModelOptionLabel,
                      value: selectedMakeModelOption,
                      placeholder: 'Select a model',
                      isDisabled,
                      onChange: onSelectedMakeModelChange,
                    },
                  ]}
                  data={makeModelData}
                  emptyHelperText='Choose a model to see supply, days on lot, and price change data.  You can type in the select field to quickly filter make and model options.'
                  onListingsShowClick={onListingsShowClick}
                />
              </div>
            }
            {
              isLoadingDrilldownStats ?
              <Spinner /> :
              (
                selectedChartProps &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: '20px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10, borderBottom: '1px solid #aaa' }}>
                    <div style={{ fontWeight: 'bold'}}>{selectedChartProps.title}</div>
                    <div style={{color: '#ddd'}}>|</div>
                    <div>
                      <a href='#toggle-chart' onClick={onSelectNextDistributionChartTypeClick}>
                        {`Show by ${nextSelectedDistributionChartType}`}
                      </a>
                    </div>
                  </div>
                  <BarChart {...selectedChartProps} />
                </div>
              )
            }
          </div>
        </> :
        isEnabledMakeModelData ?
        <a
          href='#show-make-model-data'
          onClick={onToggleShowMakeModelData}
        >
          Show data for all years and trims
        </a>:
        <div>
          {disabledMakeModelDataMessage}
        </div>
      }
    </div>,
    (isDrilldownFirst || selectedMakeModelOption) &&
    <div>
      {
        !isDrilldownFirst &&
        <h4 style={{borderBottom: '1px solid #ddd' }}>Year and Trim Specific Data</h4>
      }
      {
        isLoadingDrilldownStats ?
        <Spinner /> :
        <div>
          {
            !isDrilldownFirst &&
            <div className='secondary-text' style={{ marginBottom: '0.25rem' }}>{getMakeModelOptionLabel(selectedMakeModelOption)}</div>
          }
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10}}>
            <Section
              selectProps={[
                {
                  options: yearOptions,
                  getOptionValue: getYearOptionValue,
                  getOptionLabel: getYearOptionLabel,
                  value: selectedYearOption,
                  placeholder: 'Select year',
                  onChange: onSelectedYearChange,
                  isClearable: true,
                  width: 200,
                },
                {
                  options: trimOptions,
                  getOptionValue: getTrimOptionValue,
                  getOptionLabel: getTrimOptionLabel,
                  value: selectedTrimOption,
                  placeholder: 'Select trim',
                  onChange: onSelectedTrimChange,
                  isClearable: true,
                }
              ]}
              data={drilldownData}
              emptyHelperText={drilldownEmptyHelperText}
              onListingsShowClick={onListingsShowClick}
              showDemandScore={false}
            />
          </div>
        </div>
      }
    </div>
  ]

  if (isDrilldownFirst) sections.reverse()

  return (
    <div style={{ display: 'flex', gap: '40px', flexDirection: 'column' }}>
      {sections.map((section, index) => <Fragment key={index}>{section}</Fragment>)}
    </div>
  )
}

export default ModelStats
