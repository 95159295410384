import React from 'react'

export default class PlusIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '1em'
    const height = this.props.height || '1em'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-plus-circle" fill={color} xmlns="http://www.w3.org/2000/svg" style={{...this.props.style}}>
        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    )
  }

}
